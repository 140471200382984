import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPlusSquare, faFilePdf, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import * as documentsAPIs from '../../../../shared/documentsApis';

export const DocTable = (props) => {
    return (<div className='mt-3'>
        {
            props.stateAttributes.length > 0 &&
            <div class="table-responsive doc_upload_table">
                <table class="table table-sm table-bordered" style={{height:'fit-content'}}>
                    <thead>
                        <th scope="col" style={{ width: '150px' }}>Category</th>
                        <th scope="col" style={{ width: '120px' }}>Relevance</th>
                        <th scope="col" style={{ width: '220px' }}><div>Document</div>Type</th>
                        <th scope="col" style={{ width: '250px' }}><div>Document</div>Description</th>                        
                        <th scope="col" style={{ width: '85px' }}>Document Source Type</th>
                        <th scope="col" style={{ width: '85px' }}>Document Source</th>
                        <th scope="col" style={{ width: '85px' }}>Document Tag</th>
                        <th scope="col">Doc Req</th>
                        {props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME && <>                       
                            <th scope="col" style={{ width: '45px' }}>Doc Seq</th>
                            
                        </>
                        }
                        <th scope="col" style={{ width: '650px' }}>Relevant Seller Name</th>
                        <th scope="col" style={{ width: '650px' }}>File</th>
                        <th scope="col" style={{ width: '70px' }}>Action</th>

                        <th style={{ width: '150px' }}>Notes</th>
                    </thead>
                    <tbody >
                        {
                            props.stateAttributes.map((row, i) => {
                                return <tr className={`${(row.SubCategory === documentsAPIs.OPP_CLMNT_INFO || row.SubCategory === documentsAPIs.CLAIM_PKT || !row.RelevanceTypeName || !row.DocumentTypeName) ? 'disabledRow' : ''}`}>
                                    <td style={{ width: '150px' }}>{row.SubCategory}</td>
                                    <td style={{ width: '120px' }}>{row.RelevanceTypeName}</td>
                                    <td style={{ width: '220px' }}>{row.DocumentTypeName}</td>
                                    <td style={{ width: '250px' }}>{row.DocumentDescription}</td>                                    
                                    <td style={{ width: '85px' }}>{row.documentSourceTypeName && row.documentSourceTypeName.length > 0 && row.documentSourceTypeName.map((e) => <div>{e}</div>)}</td>
                                    <td style={{ width: '85px' }}>{row.documentSourceName && row.documentSourceName.length > 0 && row.documentSourceName.map((e) => <div>{e}</div>)}</td>
                                    <td>{row.documentTagName && row.documentTagName.length > 0 && row.documentTagName.map((e) => <div>{e}</div>)}</td>
                                    <td >{row.AttributeValue}</td>
                                    {props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME && <>


                                        <td style={{ width: '45px' }}>{row.ValueSequence}</td>
                                    </>
                                    }
                                    <td className='p-0'>
                                        {row.scope && row.scope.includes(documentsAPIs.DOC_SCOPE_REL_SELLER) &&
                                        <>{
                                            row.documentNumber && row.documentNumber.length > 0 && <div className='h-100 d-flex flex-column'>
                                                {row.documentNumber.map((e, i) => <div className={`${i + 1 !== row.documentNumber.length && 'border-bottom-filename'}`} style={{ height: `${100 / row.documentNumber.length}%` }}>
                                                    {row.relSellerNames[e] && row.relSellerNames[e].length > 0 && <>
                                                        {row.relSellerNames[e].map((name, j) => <div className={`flex-grow-1 pl-1 ${j + 1 !== row.relSellerNames[e].length && 'border-bottom'}`}>{name}</div>)}
                                                    </>}
                                                </div>)}
                                            </div>
                                        }</>
                                        }
                                    </td>
                                        <td style={{ width: '650px' }} className='p-0'> <div className='h-100 d-flex flex-column' > {row.documentNames && row.documentNames.length > 0 && row.documentNames.map((name, i) =>
                                            <div className={`${i + 1 !== row.documentNames.length && 'border-bottom-filename'} flex-grow-1 pl-1 justify-self-center`} style={{ height: `${100 / row.documentNames.length}%` }}>
                                                <span className={`cursor-pointer ${row.multiDocument[i] ? 'multiRepFile' : 'text-info'}`} onClick={() => { props.redirectToPDFEditor(row, i) }}>{name}</span>
                                            </div>

                                        )}
                                        </div>
                                        </td>
                                        <td style={{ width: '70px' }} className='p-0'>
                                        {props.preSalePermission === "Edit" ? <div className="h-100 w-100 d-flex ">
                                            <div className='h-100 border-right d-flex flex-column w-100' >{row.docId && row.docId.length > 0 &&
                                                <>
                                                    {row.docId.map(((id, index) => <div style={{ height: `${100 / row.docId.length}%` }} className={`${index + 1 !== row.docId.length ? 'border-bottom-filename' : ''} documents-upload-icon`}><FontAwesomeIcon
                                                        className={`${(row.SubCategory === documentsAPIs.OPP_CLMNT_INFO || row.SubCategory === documentsAPIs.CLAIM_PKT || !row.RelevanceTypeName || !row.DocumentTypeName) ? 'disableClick' : 'cursor-pointer'}`}
                                                        fontSize={'16px'}
                                                        icon={faPencilAlt}
                                                        onClick={() => { props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME ? props.onClickEdit(id, row, row.documentNumber[index]) : props.redirectToUpload(row, 'edit', index) }}
                                                        style={{ fontSize: '16px', cursor: 'pointer' }}
                                                    /></div>))}

                                                </>
                                            }
                                            </div>
                                            <div className='documents-upload-icon mx-1'>
                                                <FontAwesomeIcon
                                                    className={`${(row.SubCategory === 'OPP CLMNT INFO' || !row.RelevanceTypeName || !row.DocumentTypeName) ? 'disableClick' : 'cursor-pointer'}`}
                                                    fontSize={'16px'}
                                                    icon={faPlusSquare}
                                                    onClick={() => { props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME ? props.onClickUpload(row, 'add') : props.redirectToUpload(row) }}
                                                    style={{ fontSize: '16px' }}
                                                />

                                            </div>
                                        </div>
                                            : null}
                                        </td>
                                    
                                    
                                    <td style={{ width: '150px' }} className='p-0'>{row.documentNotes && row.documentNotes.length > 0 && <div className='d-flex flex-column h-100'> {row.documentNotes.map((note,i) => <div className={`text-ellipsis pl-1 ${i+1 !== row.documentNotes.length ? 'border-bottom-filename' : ''}` } style={{ width: '150px',height:`${100/row.documentNotes.length}%` }} data-toggle="tooltip" data-placement="left" title={note}>{note}</div>)} </div>}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

        }
    </div>)
} 