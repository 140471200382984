import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import Loader from '../../App/Loader';
import fetchMethodRequest from '../../../config/service'

class ExcelModeModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      presetName: "",
      progress: 0,
      isLoading: false,
      update_active_preset: false,
      errorResponse: false,
      mode: "Internal Use Excluding Test",
      allPresetForAddress: "Original Grid",
      modeSelection: [{ label: "Internal Use (Including Test Deals)", value: "Internal Use" }, { label: "Internal Use (Excluding Test Deals)", value: "Internal Use Excluding Test" }, { label: "External Use", value: "External Use" }],
      allPreset: "Original Grid",
    };
  }
  componentDidMount = async () => {
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    let roleName = loginCredentials.roleName
    this.setState({ roleName: roleName })


    await this.loadAllPresetsForNames()
    await this.loadAllPresetsForAddress()
    // await this.loadAllPresetsForClaims()
    if (this.props.activeTab === "Names") {
      await this.setState({ allPreset: this.props.ActivePreset, allPresetForAddress: "Original Grid" })
    } else if (this.props.activeTab === "Addresses") {
      await this.setState({ allPreset: "Original Grid", allPresetForAddress: this.props.ActivePreset })
    } else {
      await this.setState({
        // allPresetForClaims: this.props.ActivePreset,  
        allPresetsForClaims: this.props.ActivePreset
      })

    }
    this.setState({ isLoading: false, progress: 100 })

  }
  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }
  componentDidUpdate() {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorResponse: '' }), 5000);
    }
    if (this.state.progress === 100) {
      setTimeout(() => this.setState({ progress: 0 }), 200);
    }

    // this.getTab2Body()
  }

  /**
   * 
   * @returns loading all presets for names
   */
  loadAllPresetsForNames = async () => {
    let filterCriteria = {}
    let allPresets = []
    filterCriteria['criteria'] = [{ key: 'Screen', value: "Names", type: 'eq' }];
    this.setState({ isLoading: true })
    this.handlePercentage("1")
    await fetchMethodRequest('GET', `preset?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["presets"] && response["presets"].length > 0) {
        for (let resp of response["presets"]) {
          let preferences = []
          for (let preference of resp.preferences) {
            if (preference.show) {
              preferences.push(preference)
            }
          }
          allPresets.push({ label: resp.name, value: resp.name, preferences: preferences })

        }
        await this.setState({ allPresets: allPresets })
      }
    })
    return this.state.allPresets;
  }


  /**
   * 
   * @returns loading all presets for address
   */
  loadAllPresetsForAddress = async () => {
    let filterCriteria = {}
    let allPresets = []
    filterCriteria['criteria'] = [{ key: 'Screen', value: "Addresses", type: 'eq' }];
    await fetchMethodRequest('GET', `preset?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["presets"] && response["presets"].length > 0) {
        for (let resp of response["presets"]) {
          let preferences = []
          for (let preference of resp.preferences) {
            if (preference.show) {
              preferences.push(preference)
            }
          }
          allPresets.push({ label: resp.name, value: resp.name, preferences: preferences })
        }
        await this.setState({ allPresetsForAddress: allPresets })
      }
    })
    return this.state.allPresets;
  }
  /**
     * 
     * @returns loading all presets for Claims
     */
  loadAllPresetsForClaims = async () => {
    let filterCriteria = {}
    let allPresets = []
    filterCriteria['criteria'] = [{ key: 'Screen', value: "ClaimsManagement", type: 'eq' }];
    await fetchMethodRequest('GET', `preset?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["presets"] && response["presets"].length > 0) {
        for (let resp of response["presets"]) {
          let preferences = []
          for (let preference of resp.preferences) {
            if (preference.show) {
              preferences.push(preference)
            }
          }
          allPresets.push({ label: resp.name, value: resp.name, preferences: preferences })
        }
        await this.setState({ allPresetsForClaims: allPresets })
      }
    })
    return this.state.allPresets;
  }

  /**
   * 
   * @param {e} String
   * Setting the Preset to state for names
   */
  onChangePreset = async (e) => {
    let preferences = this.state.allPresets.find(elem => elem.value === e.value)
    await this.setState({
      allPreset: e.value,
      selectedPreferences: preferences
    })

  }
  /**
   * Export Button Functionality
   */

  exportData = async () => {
    let externalPref
    let namePreferences = await this.state.allPresets.find(elem => elem.value === this.state.allPreset)
    let addressPreferences = await this.state.allPresetsForAddress.find(elem => elem.value === this.state.allPresetForAddress)
    let claimPreferences = this.state.allPresetsForClaims;
    // await this.state.allPresetsForClaims.find(elem => elem.value === this.state.allPresetForClaims)
    if (this.state.mode === "External Use") {
      externalPref = [
        {
          "field": "stateClaimID", "show": true, "header": "Claim ID", "width": 120
        },
        {
          "field": "dealType", "show": true, "header": "Acq Type", "width": 120
        },
        {
          "field": "estateName", "show": true, "header": "OPP Acquisition", "width": 140
        },
        {
          "field": "sourceState", "show": true, "header": "Source", "width": 70
        },
        {
          "field": "outTheDoor_DateTime_UTC", "show": true, "header": "Claim Filed Date", "width": 150
        },
        {
          "field": "hasH__Claims", "show": true, "header": "Property Count", "width": 70
        },
        {
          "field": "claimLatestEstimatedValue", "show": true, "header": "Estimated Value", "width": 120
        },
        {
          "field": "claimAmountPaid", "show": true, "header": "Claim Amount Paid", "width": 120
        },
        {
          "field": "claimValueComputeColor", "show": true, "header": "Claim Value Type", "width": 150
        },
        {
          "field": "claimStatus", "show": true, "header": "Claim Status", "width": 150
        },
        {
          "field": "claimReason", "show": true, "header": "Claim Reason", "width": 150
        },
      ]
    } else if (this.state.mode === "Internal Use" || this.state.mode === "Internal Use Excluding Test") {
      // claimPreferences.preferences.push({
      //   "field": "claimNotes", "show": true, "header": "Claim Notes", "width": 150
      // },)
      // claimPreferences.splice(18, 0, {
      //   "field": "claimNotes", "show": true, "header": "Claim Notes", "width": 150
      // });
    }
    this.props.getExcelFile(namePreferences.preferences, addressPreferences.preferences, this.props.strArray, externalPref ? externalPref : claimPreferences ? claimPreferences : [], this.props.activeTab, this.state.mode)
  }


  /**
  * 
  * @param {e} String
  * Setting the Preset to state for address
  */
  onChangePresetForAddress = async (e) => {
    let preferences = this.state.allPresetsForAddress.find(elem => elem.value === e.value)
    await this.setState({
      allPresetForAddress: e.value,
      selectedPreferencesforAddress: preferences
    })
  }

  /**
 * 
 * @param {e} String
 * Setting the Preset to state for claims
 */
  onChangePresetForClaims = async (e) => {
    let preferences = this.state.allPresetsForClaims.find(elem => elem.value === e.value)
    await this.setState({
      allPresetForClaims: e.value,
      selectedPreferencesforClaims: preferences
    })
  }

  /**
* 
* @param {e} String
* Setting the Preset to state for claims
*/
  onChangeMode = async (e) => {
    await this.setState({
      mode: e.value

    })
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Modal isOpen={this.props.saveOrderModal} fade={false} className='deleteModalHeight'>
          <ModalBody className='deleteModalBody' style={{ display: "grid" }}>
            <Loader loader={this.state.isLoading} progress={this.state.progress} />
            <div style={{ marginBottom: 10 }}><h4 style={{ fontWeight: "bold" }}> {"Export View"}</h4>
              <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "#354f6e", right: 40 }}
                onClick={this.props.closeExcelModel} />
            </div>
            {this.props.activeTab === "ClaimsManagement" ? <div>
              <div className='row mt-2'>
                <div className="col-sm-11 ml-4">
                  <label className='ml-4'>Mode </label>


                  <Dropdown
                    value={this.state.mode}
                    appendTo={document.body}
                    style={{ height: 32, width: "56%" }}
                    // onFocus={this.changeCursorPos}
                    className='ml-3'
                    placeholder={"Select Mode"}
                    onChange={(e) => this.onChangeMode(e)}
                    options={this.state.modeSelection} />

                </div>
              </div>
              {/* {this.state.mode === "Internal Use" || this.state.mode === "Internal Use Excluding Test" ? <div className='row mt-2'>
                <div className="col-sm-11 ml-4">
                  <label className='ml-4'>Claims </label>


                  <Dropdown
                    value={this.state.allPresetForClaims}
                    appendTo={document.body}
                    style={{ height: 32, width: "56%" }}
                    // onFocus={this.changeCursorPos}
                    className='ml-3'
                    placeholder={"Select Preset For Claims"}
                    onChange={(e) => this.onChangePresetForClaims(e)}
                    options={this.state.allPresetsForClaims} />

                </div>
              </div> : null} */}

              <div className='d-flex my-2'>

              </div>
              <div className='d-flex my-2'>

              </div>
            </div>

              :
              <div>
                <div className='row mt-2'>
                  <div className="col-sm-11 ml-4">
                    <label className='ml-4'>Name </label>


                    <Dropdown
                      value={this.state.allPreset}
                      appendTo={document.body}
                      style={{ height: 32, width: "56%" }}
                      // onFocus={this.changeCursorPos}
                      className='ml-3'
                      placeholder={"Select Preset For Name"}
                      onChange={(e) => this.onChangePreset(e)}
                      options={this.state.allPresets} />

                  </div>
                </div>
                <div className='row mt-4'>
                  <div className="col-sm-11 ml-4">
                    <label className='ml-4'> Address </label>


                    <Dropdown
                      value={this.state.allPresetForAddress}
                      appendTo={document.body}
                      style={{ height: 32, width: "56%" }}
                      className='ml-2'
                      // onFocus={this.changeCursorPos}
                      placeholder={"Select Preset For Addres"}
                      onChange={(e) => this.onChangePresetForAddress(e)}
                      options={this.state.allPresetsForAddress} />

                  </div>
                </div>
                <div className='d-flex my-2'>

                </div>
                <div className='d-flex my-2'>

                </div>
                <span className="topbar__centerresponse topBarImageAlignment" style={{ color: "red" }}>{this.state.errorResponse ? this.state.errorMessage : null}</span>

              </div>}
            <Button color="primary" outline size="sm" onClick={() => this.exportData()}
              disabled={this.state.allPreset && this.state.allPreset.length > 0 && this.state.allPresetForAddress && this.state.allPresetForAddress.length > 0 ? false : true}
              className='deleteModalBtn m-auto'>{t('Export To Excel')}</Button>
          </ModalBody>
        </Modal>
      </div >
    );
  }
}
export default withTranslation('common')(ExcelModeModel);
