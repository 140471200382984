import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import { Button } from 'reactstrap';
import { InputText } from 'primereact/inputtext';
import fetchMethodRequest from '../../../../config/service';
import { MultiSelect } from 'primereact/multiselect';
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import Loader from '../../../App/Loader';

// config file
export default class NextActionUserReportConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Property And Claim Status',
      claimStatusArray: [],
      claimReasonArrayNew: [],
      filters: {
        state: null,
        status: null,
        reasons: null,
        nextActionUserRole: null,
        followUpDays: "",
        nextActionUserName: null,
        activeIndicator: true,
        dealType: null
      },
    };
    this.claimStatusArray = []
    this.claimReasonArray = []
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  componentDidMount = async () => {
    await this.getClaimStatusReason()
    await this.getMctSource()
    await this.getClaimStatusReasonforReasonArray()
    await this.getAnalysts()
    await this.getIntegrityUsers()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }
  getAnalysts = async () => {
    let filterCriteria = {}
    filterCriteria['criteria'] = [{
      key: "role", value: ["CoreOps Manager"], type: 'in'
    }];
    return fetchMethodRequest('GET', `${apiCalls.Users}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          let data = response.users, tempData = [];
          for (let i = 0; i < data.length; i++) {
            tempData.push({ 'label': data[i]['display_name'], "value": data[i]['display_name'], userId: data[i]['_id'] })
          }
          await this.setState({
            filteredAnalysts: tempData
          })
        } else if (response && response['errorMessage']) {
        }
      }).catch((err) => {
        return err;
      })
  }
  getIntegrityUsers = async () => {
    let filterCriteria = {}
    filterCriteria['criteria'] = [
      { "key": "display_name", "value": "Integrity", "type": "regexOr", "add_Filter": false }
    ];
    return fetchMethodRequest('GET', `${apiCalls.Users}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          let data = response.users, tempData = [];
          for (let i = 0; i < data.length; i++) {
            tempData.push({ 'label': data[i]['display_name'], "value": data[i]['display_name'], userId: data[i]['_id'] })
          }
          await this.setState({
            filteredIntegrity: tempData
          })
        } else if (response && response['errorMessage']) {
        }
      }).catch((err) => {
        return err;
      })
  }
  closeCustomConfigForNextActionUserReport = async () => {
    await this.datatableref.closeCustomConfigForNextActionUserReport()
    await this.setState({ clickSaved: false, selectedRowsForConfig: null, })
    await this.emptyFilters()
  }
  checkValidation = async (body) => {
    return Object.values(body).some(value => value === null || value.length === 0);

  }
  saveRecord = async () => {
    let nextActionDetails
    await this.setState({ isLoading: true, clickSaved: true })
    let body = this.state.filters
    if (body.nextActionUserRole === "Claim Initiator") {
      delete body.nextActionUserName
    }
    let validated = await this.checkValidation(body)
    if (validated) {
      await this.setState({ isLoading: false })
      return
    }
    if (body && body.nextActionUserName) {
      if (body.nextActionUserRole === "Integrity") {
        nextActionDetails = this.state.filteredIntegrity.find(elem => elem.value === body.nextActionUserName)
      }
      else if (body.nextActionUserRole === "CoreOps Manager") {
        nextActionDetails = this.state.filteredAnalysts.find(elem => elem.value === body.nextActionUserName)
      }
      if (nextActionDetails) {
        body.nextActionUserId = nextActionDetails.userId
      }
    }
    await fetchMethodRequest('POST', `${apiCalls.nextActionReportConfiguration}`, body).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        await this.datatableref.closeCustomConfigForNextActionUserReport()
        await this.setState({ clickSaved: false })
        await this.datatableref.getDataFromServer(this.datatableref.getFilterCriteria());
      }
      await this.setState({ isLoading: false })
    })
  }
  updateRecords = async () => {
    let body = [], nextActionDetails, filterObj = { nextActionUserName: this.state.filters.nextActionUserName, nextActionUserRole: this.state.filters.nextActionUserRole, followUpDays: this.state.filters.followUpDays, activeIndicator: this.state.filters.activeIndicator }, validated
    await this.setState({ isLoading: true, clickSaved: true })
    let filters = this.state.filters

    let selectedRows = this.state.selectedRowsForConfig
    // body._id = this.state.selectedRowsForConfig[0]._id
    if (this.state.selectedRowsForConfig.length > 1) {
      if (filterObj.nextActionUserRole === "Claim Initiator") {
        delete filterObj.nextActionUserName
      }
      validated = await this.checkValidation(filterObj)
    } else {
      if (filters.nextActionUserRole === "Claim Initiator") {
        delete filters.nextActionUserName
      }
      validated = await this.checkValidation(filters)

    }
    if (validated) {
      await this.setState({ isLoading: false })
      return
    }
    for (let selectedRow of selectedRows) {
      if (this.state.selectedRowsForConfig.length > 1) {
        selectedRow = { ...selectedRow, ...filterObj }
      } else {
        selectedRow = { ...selectedRow, ...filters }
      }
      if (selectedRow && selectedRow.nextActionUserName) {
        if (selectedRow.nextActionUserRole === "Integrity") {
          nextActionDetails = this.state.filteredIntegrity.find(elem => elem.value === selectedRow.nextActionUserName)
        }
        else if (selectedRow.nextActionUserRole === "CoreOps Manager") {
          nextActionDetails = this.state.filteredAnalysts.find(elem => elem.value === selectedRow.nextActionUserName)
        } else if (selectedRow.nextActionUserRole === "Claim Initiator") {
          selectedRow.nextActionUserId = ""
          selectedRow.nextActionUserName = ""
        }
        if (nextActionDetails) {
          selectedRow.nextActionUserId = nextActionDetails.userId
        }
      }
      body.push(selectedRow)
    }
    // body = { ...this.state.selectedRowsForConfig[0], ...body };

    await fetchMethodRequest('PUT', `${apiCalls.nextActionReportConfiguration}/multiUpdate`, body).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        await this.datatableref.closeCustomConfigForNextActionUserReport()
        await this.setState({ clickSaved: false, selectedRowsForConfig: null })
        await this.emptyFilters()
        await this.datatableref.getDataFromServer(this.datatableref.getFilterCriteria());
      }
      await this.setState({ isLoading: false })
    })
  }
  /**
      * 
      * @returns the mct source array for claims filter dropdown
      */
  getMctSource = async () => {
    let mctSourceArray = [], dataArray = [];
    let filterCriteria = {}
    filterCriteria.sortfield = "SourceInfo_Sort_Code, SourceInfo_Source"
    filterCriteria.direction = 'asc'
    await fetchMethodRequest('GET', `${apiCalls.mctSources}?filter=${JSON.stringify(filterCriteria)}&isDropdown=true`).then(async (response) => {
      if (response && response["claimSources"] && response["claimSources"].length > 0) {
        let mctSources = response["claimSources"]
        for (let mctSource of mctSources) {
          mctSourceArray.push({ label: mctSource.sourceInfo_Source, value: mctSource.sourceInfo_Source })
        }

        await this.setState({ mctSourceArray: mctSourceArray })
      }
    })
    return this.state.mctSourceArray;
  }
  /**
 *
 * @returns the claim status reason array for claims filter dropdown
 */
  getClaimStatusReasonforReasonArray = async () => {
    let claimStatusArray = [],
      claimReasonArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq", add_Filter: true },
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence";
    filterCriteria.direction = "asc";
    await fetchMethodRequest(
      "GET",
      `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(
        filterCriteria
      )}`
    ).then(async (response) => {
      if (
        response &&
        response["propertyStatusReasons"] &&
        response["propertyStatusReasons"].length > 0
      ) {
        let propertyStatusReasons = response["propertyStatusReasons"];
        for (let propertyStatusReason of propertyStatusReasons) {
          // if (propertyStatusReason.claimSequence) {

          await claimReasonArray.push({
            label: propertyStatusReason.reason,
            value: propertyStatusReason.reason,
          });
          // }
        }

        await this.setState({ claimReasonArrayNew: claimReasonArray });
      } else if (response && response["errorMessage"]) {
        this.getErrorResponsefromServer(response);
      }
    });
    return this.state.claimReasonArray;
  };
  /**
      * 
      * @returns the claim status reason array for claims filter dropdown
      */

  getClaimStatusReason = async () => {
    let claimStatusArray = [], claimReasonArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }, { key: 'claimStatusFlag', value: true, type: 'eq', add_Filter: true },
    { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    await fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["propertyStatusReasons"] && response["propertyStatusReasons"].length > 0) {
        let propertyStatusReasons = response["propertyStatusReasons"]
        for (let propertyStatusReason of propertyStatusReasons) {
          if (claimStatusArray.findIndex((obj) => obj.value == propertyStatusReason.status) == -1) {
            claimStatusArray.push({ label: propertyStatusReason.status, value: propertyStatusReason.status })
          }
        }
        this.claimStatusArray = claimStatusArray
        this.claimReasonArray = claimReasonArray
        this.setState({ claimStatusArray: claimStatusArray, claimReasonArray: claimReasonArray, propertyStatusReasons: propertyStatusReasons })
      }
    })
    return this.state.claimStatusArray;
  }
  //  Table Column Fields
  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 5,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        frozen: true,
        show: true,
        mobile: true,
        displayInSettings: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'status',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Claim Status',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 40,
        header: "Claim Reason",
        mobile: true,
        filterType: "multiSelect",
        field: "reasons",
        "fieldType": 'StrArray',
        // editField: "text",
        // options: [],
        filterType: "multiSelect",
        filterOptions: this.state.claimReasonArray
          ? this.state.claimReasonArray
          : [],
        displayInSettings: true,
        filter: true,
        sortable: true,
        // fieldType: "ClaimReason",
      },
      {
        show: true,
        textAlign: "left",
        width: 40,
        header: "Source",
        mobile: true,
        filterType: "multiSelect",
        field: "state",
        "fieldType": 'StrArray',
        // editField: "text",
        // options: [],
        filterType: "multiSelect",
        filterOptions: this.state.mctSourceArray
          ? this.state.mctSourceArray
          : [],
        displayInSettings: true,
        filter: true,
        sortable: true,
        // fieldType: "ClaimReason",
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'dealType',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Deal Type',
        "fieldType": 'StrArray',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'followUpDays',
        "fieldType": 'StatesArray1',
        filterType: "num",
        "allowInt": true,
        "mobile": true,
        header: 'Next Action Date (Configured Days)',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'nextActionUserRole',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Next Action User - Role (Default)',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'nextActionUserName',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Next Action User (Default)',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },]

    return data;
  };

  onFilterChanges = async (key, value) => {

    let enteredValue = value
    this.setState(prevState => ({
      filters: { ...prevState.filters, [key]: enteredValue }
    }));
    if (key === 'status') {
      await this.onFilterChanges("reasons", "")
      if (value && value.length > 0) {
        value = [value]
        let selClaimStatus = {};
        for (let i = 0; i < value.length; i++) {
          selClaimStatus[value[i]] = value[i];
        }
        const claimReason = this.state.propertyStatusReasons.filter((obj) => selClaimStatus[obj.status]).map((res) => ({ label: res.reason, value: res.reason }));
        this.setState({ claimReasonArray: claimReason });
      } else {
        this.setState({ reasons: "", claimReasonArray: [] });
      }
    } else if (key === "nextActionUserRole") {
      await this.onFilterChanges("nextActionUserName", "")
    }
  }
  setRowsEmpty = async () => {
    await this.setState({ selectedRowsForConfig: null })
    await this.emptyFilters()

  }
  setValuesForEdit = async (selectedRows) => {
    await this.setState({ selectedRowsForConfig: selectedRows })
    if (selectedRows.length === 1) {
      let sel = selectedRows[0]
      // 
      let obj = { status: sel.status, reasons: sel.reasons, dealType: sel.dealType, followUpDays: sel.followUpDays, nextActionUserName: sel.nextActionUserName, nextActionUserRole: sel.nextActionUserRole, state: sel.state, activeIndicator: sel.activeIndicator }
      await this.setState({ filters: obj })
      let value = selectedRows[0].status
      if (value) {
        value = [value]
        let selClaimStatus = {};
        for (let i = 0; i < value.length; i++) {
          selClaimStatus[value[i]] = value[i];
        }
        const claimReason = this.state.propertyStatusReasons.filter((obj) => selClaimStatus[obj.status]).map((res) => ({ label: res.reason, value: res.reason }));
        this.setState({ claimReasonArray: claimReason });
      } else {
        this.setState({ reasons: "", claimReasonArray: [] });
      }
    } else {
      await this.setState({
        filters: {
          state: null,
          status: null,
          reasons: null,
          nextActionUserRole: null,
          nextActionUserName: null,
          followUpDays: null,
          followUpDays: "",
          activeIndicator: true,
          dealType: null
        }
      })
    }
  }
  emptyFilters = async () => {
    await this.setState({
      filters: {
        state: null,
        status: null,
        reasons: null,
        nextActionUserRole: null,
        nextActionUserName: null,
        followUpDays: null,
        followUpDays: "",
        activeIndicator: true,
        dealType: null
      }
    })
  }
  getNextActionConfig = () => {
    const { filters: { reasons, status, state, activeIndicator, followUpDays, dealType, nextActionUserRole, nextActionUserName } } = this.state;
    return <div style={{ border: "1px solid" }}>

      <div className='mt-2' style={{ fontWeight: "800", borderBottom: "1px solid", textAlign: 'center' }}>{this.state.selectedRowsForConfig ? "Edit -  Next Action Date (Days) and Next Action User – Role Configuration" : "Add -  Projected Next Action Date and Next Action User"}</div>
      <div className='col-sm-12 d-flex col-12 pl-0 pr-0  mb-3 mt-1'>
        {this.state.selectedRowsForConfig && this.state.selectedRowsForConfig.length > 1 ? <div className='row col-lg-9'>

          <div className='col-sm-6 d-flex'>
            <div className='col-sm-3 mt-2'>
              {'Next Action Date'}
              <span >
                <FontAwesomeIcon icon={faStarOfLife}
                  className='pl-1'
                  color='red'
                  data-toggle="tool-tip"
                  title="Mandotary Field"
                  style={{ width: 11 }}
                /></span>
              <div className='configureColor'>
                {"(Configured Days)"}
              </div>
            </div>
            <div className='col-sm-9'>
              <InputText
                type="search"
                name='followUpDays'
                id='followUpDays'
                keyfilter={"pint"}
                style={{ width: "100%" }}
                value={followUpDays}
                onChange={(e) => this.onFilterChanges("followUpDays", e.target.value)}
                placeholder={''}
              // size="1"
              />
              {(followUpDays == null || followUpDays == "") && this.state.clickSaved ?
                <div className="mb-1" style={{ height: '10px' }}>
                  {<span className="form__form-group-error">{"Please fill the field"}</span>}
                </div>
                : null}
            </div>
          </div>
          <div className='col-sm-6 d-flex p-0'>
            <div className='col-sm-3 pr-0'>
              {'Next Action User - Role'}
              <span >
                <FontAwesomeIcon icon={faStarOfLife}
                  className='pl-1'
                  color='red'
                  data-toggle="tool-tip"
                  title="Mandotary Field"
                  style={{ width: 11 }}
                /></span>
              <div className='configureColor'>
                {"(Default)"}
              </div>
            </div>
            <div className='col-sm-9'>
              <Dropdown className='NextActionDate'
                type={'text'}
                id="nextActionUserRole"
                value={nextActionUserRole}
                placeholder={""}
                name="nextActionUserRole"
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  height: 32
                }}
                options={[{ label: "Claim Initiator", value: "Claim Initiator" }, { label: "Integrity", value: "Integrity" }, { label: "CoreOps Manager", value: "CoreOps Manager" }]}
                onChange={(e) => this.onFilterChanges("nextActionUserRole", e.value)}
              />
              {(nextActionUserRole == null || nextActionUserRole == "") && this.state.clickSaved ?
                <div className="mb-1" style={{ height: '10px' }}>
                  {<span className="form__form-group-error">{"Please fill the field"}</span>}
                </div>
                : null}
            </div>
          </div>
          {this.state.filters.nextActionUserRole === "Integrity" || this.state.filters.nextActionUserRole === "CoreOps Manager" ? <div className='col-sm-6 d-flex'>
          </div> : null}
          {this.state.filters.nextActionUserRole === "Integrity" || this.state.filters.nextActionUserRole === "CoreOps Manager" ?
            <div className='col-sm-6 d-flex p-0'>
              <div className='col-sm-3 pr-0'>
                {'Next Action User'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
                <div className='configureColor'>
                  {"(Default)"}
                </div>
              </div>
              <div className='col-sm-9'>
                <Dropdown className='NextActionDate'
                  type={'text'}
                  id="NextActionUserName"
                  value={nextActionUserName}
                  placeholder={""}
                  name="nextActionUserName"
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    height: 32
                  }}
                  options={this.state.filters.nextActionUserRole === "Integrity" ? this.state.filteredIntegrity : this.state.filteredAnalysts}
                  onChange={(e) => this.onFilterChanges("nextActionUserName", e.value)}
                />
                {(nextActionUserName == null || nextActionUserName == "") && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            : null}
        </div> :
          <div className='row col-lg-9'>
            <div className='col-sm-6 mb-1 d-flex'>
              <div className='col-sm-3 mt-2'>
                {'Claim Status'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
              </div>
              <div className='claimFilterForm col-sm-9'>
                <Dropdown className='NextActionDate'
                  type={'text'}
                  id="claimStatusFilter"
                  value={status}
                  placeholder={""}
                  name="claimStatusFilter"
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    height: 32
                  }}
                  options={this.claimStatusArray}
                  onChange={(e) => this.onFilterChanges('status', e.value)}
                />
                {(status == null || status == "") && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-sm-6 mb-1 d-flex p-0'>
              <div className='col-sm-3  mt-2'>
                {'Claim Reason'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
              </div>
              <div className=' col-sm-9'>
                <MultiSelect
                  name={"Claim Status Filter"}
                  filter={true}
                  value={reasons}
                  style={{ width: "100%", height: 32 }}
                  maxSelectedLabels={1}
                  options={this.state.claimReasonArray}
                  onChange={(e) => this.onFilterChanges('reasons', e.value)}
                />

                {(reasons == null || reasons.length === 0) && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-sm-6 mb-1 d-flex'>
              <div className='col-sm-3  mt-2'>
                {'Source/State'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
              </div>
              <div className='col-sm-9'>
                <MultiSelect
                  name={"Claim Reason Filter"}
                  filter={true}
                  value={state}
                  style={{ width: "100%", height: 32 }}
                  maxSelectedLabels={1}
                  options={this.state.mctSourceArray}
                  onChange={(e) => this.onFilterChanges('state', e.value)}
                />
                {(state == null || state.length === 0) && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-lg-6 d-flex  p-0'>
              <div className='col-sm-3  mt-2'>
                {'Deal Type'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
              </div>
              <div className='col-sm-9'>
                <MultiSelect
                  name={"Deal Type Filter"}
                  filter={true}
                  value={dealType}
                  style={{ width: "100%", height: 32 }}
                  maxSelectedLabels={1}
                  options={[{ label: "BK", value: "BK" }, { label: "CR", value: "CR" },]}
                  onChange={(e) => this.onFilterChanges('dealType', e.value)}
                />
                {(dealType == null || dealType.length === 0) && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-sm-6 d-flex'>
              <div className='col-sm-3 mt-2'>
                {'Next Action Date'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
                <div className='configureColor'>
                  {"(Configured Days)"}
                </div>
              </div>
              <div className='col-sm-9'>
                <InputText
                  type="search"
                  name='followUpDays'
                  id='followUpDays'
                  keyfilter={"pint"}
                  style={{ width: "100%" }}
                  value={followUpDays}
                  onChange={(e) => this.onFilterChanges("followUpDays", e.target.value)}
                  placeholder={''}
                // size="1"
                />
                {(followUpDays == null || followUpDays == "") && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-sm-6 d-flex p-0'>
              <div className='col-sm-3 pr-0'>
                {'Next Action User - Role'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
                <div className='configureColor'>
                  {"(Default)"}
                </div>
              </div>
              <div className='col-sm-9'>
                <Dropdown className='NextActionDate'
                  type={'text'}
                  id="nextActionUserRole"
                  value={nextActionUserRole}
                  placeholder={""}
                  name="nextActionUserRole"
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    height: 32
                  }}
                  options={[{ label: "Claim Initiator", value: "Claim Initiator" }, { label: "Integrity", value: "Integrity" }, { label: "CoreOps Manager", value: "CoreOps Manager" }]}
                  onChange={(e) => this.onFilterChanges("nextActionUserRole", e.value)}
                />
                {(nextActionUserRole == null || nextActionUserRole == "") && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            {this.state.filters.nextActionUserRole === "Integrity" || this.state.filters.nextActionUserRole === "CoreOps Manager" ? <div className='col-sm-6 d-flex'>
            </div> : null}
            {this.state.filters.nextActionUserRole === "Integrity" || this.state.filters.nextActionUserRole === "CoreOps Manager" ?
              <div className='col-sm-6 d-flex p-0'>
                <div className='col-sm-3 pr-0'>
                  {'Next Action User'}
                  <span >
                    <FontAwesomeIcon icon={faStarOfLife}
                      className='pl-1'
                      color='red'
                      data-toggle="tool-tip"
                      title="Mandotary Field"
                      style={{ width: 11 }}
                    /></span>
                  <div className='configureColor'>
                    {"(Default)"}
                  </div>
                </div>
                <div className='col-sm-9'>
                  <Dropdown className='NextActionDate'
                    type={'text'}
                    id="NextActionUserName"
                    value={nextActionUserName}
                    placeholder={""}
                    name="nextActionUserName"
                    style={{
                      minWidth: "100%",
                      maxWidth: "100%",
                      height: 32
                    }}
                    options={this.state.filters.nextActionUserRole === "Integrity" ? this.state.filteredIntegrity : this.state.filteredAnalysts}
                    onChange={(e) => this.onFilterChanges("nextActionUserName", e.value)}
                  />
                  {this.state.filters.nextActionUserRole === "Claim Initiator" ? null : (nextActionUserName == null || nextActionUserName == "") && this.state.clickSaved ?
                    <div className="mb-1" style={{ height: '10px' }}>
                      {<span className="form__form-group-error">{"Please fill the field"}</span>}
                    </div>
                    : null}
                </div>
              </div>
              : null}
          </div>
        }

        <div className='col-sm-3 ' style={{ textAlign: "-webkit-center" }}>
          <div className='customSwitch col-sm-6 d-flex ml-4 ' style={{
            justifyContent: "space-between",
          }}>
            <div className='mt-1'>
              {'Active/Inactive Status'}
            </div>
            <InputSwitch
              checked={activeIndicator}
              onChange={(e) => this.onFilterChanges("activeIndicator", e.value)}
            />
          </div>
          {this.state.selectedRowsForConfig ? <Button style={{ fontSize: 18, width: "37%", "background-color": "green" }}
            onClick={() => this.updateRecords()}
            className={` text-white p-2 p-button-rounded claim_detail_sub_navbar_buttons mt-3 ml-3 d-block`}
          >{"Update"}</Button> : <Button style={{ fontSize: 18, width: "37%", "background-color": "green" }}
            onClick={() => this.saveRecord()}
            className={` text-white p-2 p-button-rounded claim_detail_sub_navbar_buttons mt-3 ml-3 d-block`}
          >{"Save"}</Button>}

          <Button style={{ fontSize: 18, width: "37%" }}
            // disabled={this.state.isDisableSavebtn}
            onClick={() => this.closeCustomConfigForNextActionUserReport()}
            className={`bg-primary text-white p-2 p-button-rounded claim_detail_sub_navbar_buttons mt-1 ml-3`}
          >{"Cancel"}</Button>

        </div>
      </div >


    </div>
  }
  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          // additionalFlag={"&hideInOpraUIFlagRecords=true"}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"createdAtUtc"}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          excelDownload={true}
          settingsRequired={false}
          claimReasonArray={
            this.state.claimReasonArrayNew ? this.state.claimReasonArrayNew : []
          }
          mctSourceArray={
            this.state.mctSourceArray ? this.state.mctSourceArray : []
          }
          filterRequired={true}
          gridRequried={false}
          setRowsEmpty={this.setRowsEmpty}
          setValuesForEdit={this.setValuesForEdit}
          nextActionReport={true}
          sample={false}
          customForm={true}
          getNextActionConfig={this.getNextActionConfig}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          topDisplayTitle={"Next Action User Report – Configuration Page"}
          type='NextActionUserReportConfig'
          noPadding={true}
          displayright={true}
          // icon='user'
          routeTo='nextactionuserreportconfig'
          displayViewOfForm='modal'
          screenPermissionsRoute="Next Action User Report Configuration"
          apiResponseKey="nextActionReportConfigurations"
          apiUrl={apiCalls.nextActionReportConfiguration}
          entityType='employee'
        />
      </span>
    );
  }
}