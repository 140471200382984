import React, { useEffect, useState } from "react";
import {
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'reactstrap';
import { Accordion, AccordionTab } from 'primereact/accordion';
import DataTables from '../CommonDataTable/DataTable';
import fetchMethodRequest from "../../../config/service";
import TableFields from './MailForwardsAssignmentFields.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf,faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import Loader from '../../App/Loader';
import * as MailFwdService from '../../../shared/mailForwardsService';

const ErrorMailedStatus="SUBMISSION STATUS"
const ErrorMailedReason="ERROR ON USPS"

const MailForwardsAssignment = () => {
    const [activeTab, setActiveTab] = useState<number>(1);
    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
    const [mftStatuses, setMftStatuses] = useState<Array<any>>([]);
    const [mftReasons, setMftReasons] = useState<Array<any>>([]);
    const [updateData, setUpdateData] = useState<any>({});
    const [users, setUsers] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [doRefresh, setDoRefresh] = useState<boolean>(false);
    const [respMessage, setRespMessage] = useState<any>({});
    const [tableFilterMftReasons, setTableFilterMftReasons] = useState<Array<any>>([]);
    const [tableFilterMftStatus, setTableFilterMftStatus] = useState<Array<any>>([]);
    const [loadDataTable, setLoadDataTable] = useState<boolean>(false);
    const [showPDFDialog, setShowPDFDialog] = useState<boolean>(false);
    const [pdfURL, setPdfURL] = useState<any>('');

    useEffect(() => {
        const [navEntry] = performance.getEntriesByType("navigation");
        if (navEntry && (navEntry as PerformanceNavigationTiming).type === "reload") {
            let storedTabData:any = localStorage.getItem("MailForwardsIntegrityCurrentTab");
            if(storedTabData){
                storedTabData=JSON.parse(storedTabData)
                setActiveTab(parseInt(storedTabData.activeTab))
                setLoadDataTable(true);
                localStorage.removeItem("MailForwardsIntegrityCurrentTab")
            }else{
                if(localStorage.getItem("MailForwardsIntegrityCurrentTab"))
                    localStorage.removeItem("MailForwardsIntegrityCurrentTab")
            }
        } 
        getMailFwdStatuses();
        getUsers();
    }, []);

    useEffect(() => {
        setSelectedRows([]);
        let tabData={ activeTab: activeTab}
        if(activeTab!==null){
        localStorage.setItem("MailForwardsIntegrityCurrentTab", JSON.stringify(tabData));
        }}, [activeTab]);

    useEffect(() => {
        if (selectedRows.length === 0) {
            setUpdateData({ notes: '' });
        }
    }, [selectedRows]);

    const showPDFScreenshot = (row: any) => {
        return <div className="text-center">
            {(row.USPSScreenshot && row.USPSScreenshot.data) ? <FontAwesomeIcon icon={faFilePdf} className="cursor-pointer" onClick={() => onClickShowPDF(row.USPSScreenshot.data)} /> : ''}
        </div>

    }

    const onClickShowPDF = (pdfBuffer: any) => {
        const pdfURL = URL.createObjectURL(new Blob([new Uint8Array(pdfBuffer).buffer], { type: "application/pdf" }));
        setShowPDFDialog(true);
        setPdfURL(pdfURL)
    }

    const getTableFields = () => {
        let tableFields = JSON.parse(JSON.stringify(TableFields));
        if(activeTab === 5){
             tableFields = tableFields.filter((obj:any) => obj.selectionMode !== "multiple"); 
             const reqIndex = tableFields.findIndex((e:any) => e.field === 'USPSMailFwdConfmCode');
             tableFields.splice(reqIndex,0,{
                "show": true,
                "textAlign": "left",
                "width": 120,
                "field": "USPSScreenshot",
                "mobile": true,
                "header": "USPS Screenshot",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
                "doNotExport": true
            })
        }
        if (activeTab === 2 || activeTab === 4|| activeTab === 5) {            
            tableFields.push({
                "show": true,
                "textAlign": "left",
                "width": 200,
                "field": "AssignedTo",
                "mobile": true,
                "header": "Assigned To",
                "displayInSettings": true,
                "filter": false,
                "sortable": true
            })
        }
        tableFields.splice(1, 0, {
            "show": true,
            "textAlign": "left",
            "width": 90,
            "field": "MailFwdTrackingID",
            "mobile": true,
            "header": "Mail Forward ID",
            "displayInSettings": true,
            "filter": true,
            "sortable": true,
            "fieldType": "customActions",
            "getCustomActions": redirectToMailForwardDetails
        });
        for (let i = 0; i < tableFields.length; i++) {
            if (tableFields[i].field === 'MailFwdAtUSPSExpiredFlag') {
                tableFields[i].fieldType = "customActions";
                tableFields[i].getCustomActions = showUSPSEpiredColumn;
            }
            if (tableFields[i].field === 'USPSScreenshot') {
                tableFields[i].fieldType = "customActions";
                tableFields[i].getCustomActions = showPDFScreenshot;
            }
            for (let i = 0; i < tableFields.length; i++) {
                if (tableFields[i].field === 'MailForwardStatus') {
                    tableFields[i].filterOptions = tableFilterMftStatus;
                } else if (tableFields[i].field === 'MailForwardReason') {
                    tableFields[i].filterOptions = tableFilterMftReasons;
                }
            }

        }
        if(activeTab === 5){
            let removingIndex = tableFields.findIndex((e:any) => e.field === 'USPSMailFwdConfmCode');
            if(removingIndex > -1){
                tableFields.splice(removingIndex,1);
            }
        }
        return tableFields;
    }

    const showUSPSEpiredColumn = (row: any) => {
        return <span>{row.MailFwdAtUSPSExpiredFlag ? 'Yes' : 'No'}</span>
    }

    const redirectToMailForwardDetails = (col: any) => {
        let reqData = {
            MailFwdTrackingID: col.MailFwdTrackingID,
            CompanyID: col.CompanyID,
            CompanyName: col.CompanyName,
            Line1: col.Line1,
            City: col.City,
            Zip: col.Zip,
            BusinessLocationID: col.BusinessLocationID,
            LocationNameLabel: col.LocationNameLabel,
            StateProvince: col.StateProvince
        }
        return <div className="cursor-pointer text-info" onClick={() => window.open(`/mailForwardsDetails?mailFwdtrackDetails=${JSON.stringify(reqData)}`, '_blank', 'noopener=true')} >
            {col.MailFwdTrackingID}
        </div>
    }

    const onSelectRows = (selectedRows: Array<any>) => {
        setSelectedRows(selectedRows);
    }


    /**
     * Get the integrity users
     */
    const getUsers = async () => {
        const res = await fetchMethodRequest('GET', 'users?filter={"limit":1000,"page":1,"criteria":[{"key":"role","value":["Search Manager","Search Analyst","Search Manager - Oakpoint"],"type":"in","add_Filter":false}],"sortfield":"created","direction":"desc"}', '', '', '', '');
        if (res && res.users && res.users.length > 0) {
            // setUsers(res.users.filter((e: any) => e.display_name.includes('Integrity')));
            setUsers(res.users);

        }
    }

    const getMailFwdStatuses = async () => {
        setIsLoading(true);
        const [mailFwdStatusesResult, mailFwdStatusesFilteresResult]: any = await MailFwdService.getMailFwdStatusandReasons(MailFwdService.MAIL_FORWARD_ASSIGNMENT);
        if (mailFwdStatusesResult && mailFwdStatusesResult.length > 0) {
            const [statuses, reasons] = await MailFwdService.getMailFwdStatusandReasonsForTableFilters(MailFwdService.MAIL_FORWARD_ASSIGNMENT, mailFwdStatusesResult);
            setTableFilterMftStatus(statuses);
            setTableFilterMftReasons(reasons);
        }
        if (mailFwdStatusesFilteresResult && mailFwdStatusesFilteresResult.length > 0) {
            setMftStatuses(mailFwdStatusesFilteresResult);
        }
        setIsLoading(false);
        setLoadDataTable(true);
    }

    /**
     * on change user input values, updating user entered values into state  
     */
    const onChangeInputValues = (field: string, value: any) => {
        setUpdateData((prevDetails: any) => ({ ...prevDetails, [field]: value }));
        if (field === 'mailFwdStatus' && value && value.mailFwdReasons) {
            const filteredReasons = MailFwdService.getMailForwardReasons(value.mailFwdReasons, MailFwdService.MAIL_FORWARD_ASSIGNMENT);
            setMftReasons(filteredReasons);
            if (updateData.mailFwdReason || filteredReasons.length === 1) {
                onChangeInputValues('mailFwdReason', filteredReasons.length === 1 ? filteredReasons[0] : '');
            }
        }
    }

    /**
     * Update functionality
     *      * 
     */
    const doBulkUpdate = async () => {
        setIsLoading(true);
        setDoRefresh(false);       
        let body: any = {
            mftIDs: selectedRows.map((e: any) => e.MailFwdTrackingID),
            mftStatusID: updateData.mailFwdStatus && updateData.mailFwdStatus.mailFwdStatusID ? updateData.mailFwdStatus.mailFwdStatusID : null,
            mftReasonID: updateData.mailFwdReason && updateData.mailFwdReason.mailFwdReasonID ? updateData.mailFwdReason.mailFwdReasonID : null,
            mftNote: updateData.notes,
            assignedTo: updateData.selUser && updateData.selUser.azureUserId ? updateData.selUser.azureUserId : '',
            userid:localStorage.getItem("loginCredentials") ? JSON.parse((localStorage.getItem("loginCredentials") || '{}' ))?.azureAdUserId : undefined
        }
        for (let prop in body) {
            if (!body[prop]) {
                delete body[prop];
            }
        }
        const res = await fetchMethodRequest('POST', `mailForwardsDetails/bulkUpdateMFTs`, body, '', '', '');
        setIsLoading(false);
        setRespMessage({ type: res.success ? 'success' : 'danger', message: res.success ? `${selectedRows.length} records updated successfully` : res.message });
        if (res.success) {
            setSelectedRows([]);
            setDoRefresh(true);
        }
        setTimeout(() => {
            setRespMessage({ type: '', message: '' });
        }, 1000 * 5);
    }

    const getAction = () => {
        return <Accordion className="mt-2">
            <AccordionTab header={<div className="d-flex justify-content-between w-100"><div>Action</div> <div style={{ paddingLeft: '50vw' }} >{respMessage.message && <span className={respMessage.type === 'success' ? 'text-success' : respMessage.type === 'danger' ? "text-danger" : ''}>{respMessage.message}</span>} </div> </div>}>
                <div className="card-body mt-2 p-1">
                    <div className="row">
                        <div className="col-md-11">
                            <div className="row">
                                {(activeTab === 1 || activeTab === 3) && <>
                                    <div className="col-md-2">
                                        <label className="mb-0">Mail Fwd Status</label>
                                        <Dropdown value={updateData.mailFwdStatus} disabled={selectedRows.length === 0} options={mftStatuses} optionLabel="mailFwdStatus" onChange={(e) => { onChangeInputValues('mailFwdStatus', e.value) }}
                                            style={{ minWidth: '100%' }} filter={true} />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="mb-0">Mail Fwd Reason</label>
                                        <Dropdown value={updateData.mailFwdReason} disabled={selectedRows.length === 0 || !updateData.mailFwdStatus} options={mftReasons} optionLabel="mailFwdReason" dataKey="_id" onChange={(e) => { onChangeInputValues('mailFwdReason', e.value) }}
                                            style={{ minWidth: '100%' }} filter={true} />
                                    </div>
                                </>}

                                <div className="col-md-2">
                                    <label className="mb-0">{activeTab === 1 || activeTab === 3 ? 'Assign To' : 'Re-Assign To'}</label>
                                    <Dropdown value={updateData.selUser} disabled={selectedRows.length === 0} options={users} optionLabel="display_name" dataKey="_id" onChange={(e) => { onChangeInputValues('selUser', e.value) }}
                                        style={{ minWidth: '100%' }} filter={true} />
                                </div>

                                <div className="col-md-3 ">
                                    <label className="mb-0">Note</label>
                                    <InputTextarea disabled={selectedRows.length === 0} value={updateData.notes} onChange={(e) => onChangeInputValues('notes', (e.target as HTMLInputElement).value)} className="w-100" rows={3} />
                                </div>
                                <div className="col-md-3 mb-2">
                                    <div className="d-flex flex-column justify-content-center h-100">
                                        <div>
                                            <Button disabled={selectedRows.length === 0} color="primary" type='submit' onClick={doBulkUpdate}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AccordionTab>

        </Accordion>
    }


    const getDataGrid = () => {
        const tabType: string = activeTab === 5?"":(activeTab === 1 || activeTab === 2) ? 'CR' : 'BK';
        const todayDate = new Date();
        const status=tableFilterMftStatus.filter(status => status.label === ErrorMailedStatus);
        const reason=tableFilterMftReasons.filter(status => status.label === ErrorMailedReason);
        const exportFileName = `${tabType} ${(activeTab === 1 || activeTab === 3) ? 'Due' : 'History' }_Mail Fwd Assignment_${todayDate.getMonth() + 1}_${todayDate.getDate()}_${todayDate.getFullYear()}`;
        const api: string =  activeTab === 5 ? "mailForwardAssignments/getMFTAssignmentsErrorsFiltered" :(activeTab === 1 || activeTab === 3) ? 'mailForwardAssignments/getMFTAssignmentsFiltered' : (activeTab === 2 || activeTab === 4) ? 'mailForwardAssignments/getMFTAssignmentsHistoryFiltered' : '';
        return <DataTables            
            getTableFields={getTableFields}
            formFields={getTableFields}
            exportRequried={false}
            selectedTab={activeTab}
            sendCriteriaInBody={activeTab===5?[{ key: 'MailForwardStatus', value:status.map((item)=>item.value), type: 'in', add_Filter: true },{ key: 'MailForwardReason', value: reason.map((item)=>item.value), type: 'in', add_Filter: true }]:[]}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            sortField={"LastFwdRequestDate"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            filterRequired={true}
            gridRequried={false}
            isSelectMode={""}
            sample={false}
            scrollHeight={"580px"}
            dataKey={"MailFwdTrackingID"}
            globalSearch={'Search'}
            type={"Mail Forwards Assignment"}
            displayright={true}
            icon='faCity'
            fontAwesome={true}
            MFTab={tabType}
            displayViewOfForm='modal'
            hideHeader={true}
            className={true}
            apiUrl={api}
            entityType='employee'
            hideProgress={true}
            paginatorPosition={'top'}
            style={{ marginTop: 10 }}
            donotIncludeParamsInFilter={true}
            showFilterElements={true}
            showTopActions={true}
            onSelectRows={onSelectRows}
            screenPermissionsRoute={'Mail Forward Assignments'}
            doRefresh={doRefresh}
            removePadding={true}
            exportFileName={exportFileName}
        />
    }



    return <div className="card-body mail-fwd-assignment p-2 mt-1">
        <Nav tabs >
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 1, h6: true }}
                    onClick={() => { setActiveTab(1) }}
                >
                    CR Due
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 2, h6: true }}
                    onClick={() => { setActiveTab(2) }}
                >
                    CR History
                </NavLink>
            </NavItem>
            <NavItem >
                <NavLink
                    className={{ active: activeTab === 3, h6: true }}
                    onClick={() => { setActiveTab(3) }}
                >
                    BK Due
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 4, h6: true }}
                    onClick={() => { setActiveTab(4) }}
                >
                    BK History
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 5, h6: true }}
                    onClick={() => { setActiveTab(5) }}
                >
                   Errored Mail Forwards
                </NavLink>
            </NavItem>

            <NavItem className="d-flex flex-grow-1 justify-content-end pr-1">
                {/* <div>
                    <Button color="primary"  outline className="mb-1" onClick={() => { alert('Save Details') }}>
                    <FontAwesomeIcon icon={faDownload}/>
                    </Button>
                </div> */}

            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            {loadDataTable && <>
            <TabPane tabId={1} >
                {activeTab === 1 && <>{getAction()}</>}
                {activeTab === 1 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={2}>
                {activeTab === 2 && <>{getAction()}</>}
                {activeTab === 2 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={3}>
                {activeTab === 3 && <>{getAction()}</>}
                {activeTab === 3 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={4}>
                {activeTab === 4 && <>{getAction()}</>}
                {activeTab === 4 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={5}>
                {/* {activeTab === 5 && <>{getAction()}</>} */}
                {activeTab === 5&& <>{getDataGrid()}</>}
            </TabPane>
            </>}


        </TabContent>
        <Dialog showHeader={false} visible={showPDFDialog} style={{ width: '85vw' }} modal={true} onHide={() => setShowPDFDialog(false)}>
        
                    <div className="p-1 text-right">
                        <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => { setShowPDFDialog(false) }} />
                    </div>
                    <div className="p-2">
                        <iframe src={pdfURL} style={{ 'width': '100%', height: '90vh' }} />
                    </div>
                </Dialog>
        <Loader loader={isLoading} />
    </div>

}

export default MailForwardsAssignment;