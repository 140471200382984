import React, { useEffect, useState, useCallback } from "react";
import {
    TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'reactstrap';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFilePdf, faCheck } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../CommonDataTable/DataTable';
import fetchMethodRequest from "../../../config/service";
import TableFields from './MailForwardsIntegrityFields.json';
import TableFieldsForHistory from './MailFwdIntegrityHistoryFields.json';
import { Dialog } from 'primereact/dialog';
import Loader from '../../App/Loader';
import config from '../../../config/config';
import '../../../scss/component/pdf-editor.scss';
import * as MailFwdService from '../../../shared/mailForwardsService';


const MailForwardsIntegrity = () => {
    const [activeTab, setActiveTab] = useState<number>(2);
    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
    const [mftStatuses, setMftStatuses] = useState<Array<any>>([]);
    const [mftReasons, setMftReasons] = useState<Array<any>>([]);
    const [updateData, setUpdateData] = useState<any>({});
    const [users, setUsers] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [doRefresh, setDoRefresh] = useState<boolean>(false);
    const [respMessage, setRespMessage] = useState<any>('');
    const [recordCount, setRecordCount] = useState<any>({ CR: 0, BK: 0 });
    const [showUSPSDialog, setShowUSPSDialog] = useState<any>(false);
    const [selMailFwdID, setSelMailFwdID] = useState<any>('');
    const [fileName, setFileName] = useState<string>('');
    const [fileError, setFileError] = useState<string>('');
    const [integrityMember, setIntegrityMember] = useState<string>('');
    const [isauthorizedIntegrityMember, setisAuthorizedIntegrityMember] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any>('');
    const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
    const [showPDFDialog, setShowPDFDialog] = useState<boolean>(false);
    const [pdfURL, setPdfURL] = useState<any>('');
    const [tableFilterMftReasons, setTableFilterMftReasons] = useState<Array<any>>([]);
    const [tableFilterMftStatus, setTableFilterMftStatus] = useState<Array<any>>([]);
    const [loadDataTable, setLoadDataTable] = useState<boolean>(false);
    const [assignmentData, setAssignmentData] = useState<any>({});
    const [reAssignResp, setReAssignResp] = useState<any>({});
    const [showEditField, setShowEditField] = useState<boolean>(false);
    const [uspsCode, setUspsCode] = useState<string>('');

    useEffect(() => {

        const [navEntry] = performance.getEntriesByType("navigation");
        if (navEntry && (navEntry as PerformanceNavigationTiming).type === "reload") {
            var storedTabData = localStorage.getItem("MailForwardsIntegrityCurrentTab");
            if(storedTabData){
                storedTabData=JSON.parse(storedTabData)
                setActiveTab(parseInt(storedTabData.activeTab))
                setIntegrityMember(storedTabData.integrityMember)
                setLoadDataTable(true);
                getTheOtherTabCount(storedTabData.integrityMember,parseInt(storedTabData.activeTab))
                localStorage.removeItem("MailForwardsIntegrityCurrentTab")
            }else{
                if(localStorage.getItem("MailForwardsIntegrityCurrentTab"))
                    localStorage.removeItem("MailForwardsIntegrityCurrentTab")
              
            }
        }
        if(navEntry && (navEntry as PerformanceNavigationTiming).type !== "reload"){
           getTheOtherTabCount("",activeTab);
        }
        getMailFwdStatuses();
        getUsers();
        getAuthorizedIntegrityMember()
    }, []);

    useEffect(() => {
        setSelectedRows([]);
        let tabData={ activeTab: activeTab,integrityMember:integrityMember}
        if(activeTab!==null){
        localStorage.setItem("MailForwardsIntegrityCurrentTab", JSON.stringify(tabData));
        }
    }, [activeTab]);

    useEffect(() => {
        if (selectedRows.length === 0) {
            setAssignmentData({ notes: '' ,selUser:''});
        }
    }, [selectedRows]);

    const getActionItems = (row: any) => {
        return <div className="text-center p-1">
            {(row.LastFwdRequestDate && row.MailFwdAtUSPSExpiredFlag) ? <Button color="primary" size='sm' className='mb-0' onClick={() => { onClickNeworRenew(row) }}>
                <small> Renew </small>
            </Button> : !row.LastFwdRequestDate && <Button color="primary" className='mb-0' size='sm' onClick={() => { onClickNeworRenew(row) }}>
                <small> New </small>
            </Button>}
        </div>
    }

    const showPDFScreenshot = (row: any) => {
        return <div className="text-center">
            {(row.USPSScreenshot && row.USPSScreenshot.data) ? <FontAwesomeIcon icon={faFilePdf} className="cursor-pointer" onClick={() => onClickShowPDF(row.USPSScreenshot.data)} /> : ''}
        </div>

    }

   

    const getTableFields = () => {
        let tableFields = JSON.parse(JSON.stringify(TableFields));
        if (activeTab === 2 || activeTab === 4) {
            tableFields = JSON.parse(JSON.stringify(TableFieldsForHistory));
        } else {
            tableFields.unshift({
                "textAlign": "center",
                "width": 47,
                "field": "multi",
                "header": "",
                "selectionMode": "multiple",
                "frozen": true,
                "show": true,
                "mobile": true,
                "displayInSettings": false
            }, {
                show: true,
                mobile: true,
                textAlign: 'left',
                width: 100,
                field: '',
                header: 'Action',
                fieldType: "customActions",
                getCustomActions: getActionItems,
                "filter": false,
                "sortable": false,

            })
        }
        for (let i = 0; i < tableFields.length; i++) {
            if (tableFields[i].field === 'USPSScreenshot') {
                tableFields[i].fieldType = "customActions";
                tableFields[i].getCustomActions = showPDFScreenshot;
            } else if (tableFields[i].field === 'MailForwardStatus') {
                tableFields[i].filterOptions = tableFilterMftStatus;
            } else if (tableFields[i].field === 'MailForwardReason') {
                tableFields[i].filterOptions = tableFilterMftReasons;
            } 
        }
        return tableFields;
    }



    const onClickShowPDF = (pdfBuffer: any) => {
        const pdfURL = URL.createObjectURL(new Blob([new Uint8Array(pdfBuffer).buffer], { type: "application/pdf" }));
        setShowPDFDialog(true);
        setPdfURL(pdfURL)
    }

    const onClickNeworRenew = (row: any) => {
        setUpdateData({ MailForwardNote: '', USPSMailFwdConfmCode: '', USPSErrorMessage: '' })
        setRespMessage('');
        setShowUSPSDialog(true);
        setSelMailFwdID(row.MailFwdTrackingID);

    }

   

    const onSelectRows = (selectedRows: Array<any>) => {        
        setSelectedRows(selectedRows);
    }

    const getUsers = async () => {
        const res = await fetchMethodRequest('GET', 'users?filters: {"limit":1000,"page":1,"criteria":[{"key":"role","value":["Search Manager","Search Analyst"],"type":"in","add_Filter":false}],"sortfield":"created","direction":"desc"}', '', '', '', '');
        if (res && res.users && res.users.length > 0) {
            setUsers(res.users.filter((e: any) => e.display_name.includes('Integrity')));
        }
    }

    const getAuthorizedIntegrityMember = async () => {
        const loginCredentials = localStorage.getItem("loginCredentials") ? JSON.parse(localStorage.getItem("loginCredentials") || '') : {};
        let body = { userid: loginCredentials.azureAdUserId }
        const res = await fetchMethodRequest('POST', 'mailForwardIntegrity/isAuthorizedUser', body, '', '', '');
        if (res && res.isAuthorized) {
            setisAuthorizedIntegrityMember(true)
        }
    }

    const getMailFwdStatuses = async () => {
        setIsLoading(true);
        const [mailFwdStatusesResult, mailFwdStatusesFilteresResult]: any = await MailFwdService.getMailFwdStatusandReasons(MailFwdService.MAIL_FORWARD_INTEGRITY);
        if (mailFwdStatusesResult && mailFwdStatusesResult.length > 0) {
            const [statuses, reasons] = await MailFwdService.getMailFwdStatusandReasonsForTableFilters(MailFwdService.MAIL_FORWARD_INTEGRITY, mailFwdStatusesResult);
            setTableFilterMftStatus(statuses);
            setTableFilterMftReasons(reasons);
        }
        if (mailFwdStatusesFilteresResult && mailFwdStatusesFilteresResult.length > 0) {
            setMftStatuses(mailFwdStatusesFilteresResult);
        }
        setIsLoading(false);
        setLoadDataTable(true);
    }

    const onChangeAssignmentValues = (field: string, value: any) => {
        setAssignmentData((prevDetails: any) => ({ ...prevDetails, [field]: value }));
    }
    const onChangeInputValues = (field: string, value: any) => {
        setUpdateData((prevDetails: any) => ({ ...prevDetails, [field]: value }));
    }

    /**
     * To hide the dialog box and reset values to empty after close
     */
    const hideDialogAndResetValues = () => {
        setUpdateData({ MailForwardNote: '', USPSMailFwdConfmCode: '', USPSErrorMessage: '' })
        setShowUSPSDialog(false);
    }
    /**
     * Update functionality
     */
    const doUpdate = async () => {
        setRespMessage('');
        setIsLoading(true);
        setDoRefresh(false);
        let data: any = {
            MailForwardNote: updateData.MailForwardNote
        }
        if (updateData.USPSErrorMessage) {
            data.USPSErrorMessage = updateData.USPSErrorMessage;
            let errorReason = tableFilterMftReasons.find(elem => elem.label === "ERROR ON USPS")
            if (errorReason) {
                data.MailForwardReason = errorReason.label;
                data.MailForwardReasonID = errorReason.value
            }
        }
        if (updateData.USPSMailFwdConfmCode) data.USPSMailFwdConfmCode = updateData.USPSMailFwdConfmCode;
        let formData = new FormData();
        formData.append('data', JSON.stringify(data));
        formData.append('viaIntegrity', JSON.stringify(true));
        formData.append('mailFwdID', selMailFwdID);
        formData.append('USPSScreenshot', selectedFile);
        const loginCredentials = localStorage.getItem("loginCredentials") ? JSON.parse(localStorage.getItem("loginCredentials") || '') : {};
        formData.append('userid', loginCredentials.azureAdUserId);
        try {
            const resPromise = await fetch(`${config.mailForwardsURL}mailForwardsDetails/updateMFTIntegrity`, {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': `Bearer ${loginCredentials.accessToken}`
                },
            });
            const res = await resPromise.json();
            setIsLoading(false);
            if (res && res.success) {
                setDoRefresh(true);
                //  reset values to empty after close of dialog
                setUpdateData({ MailForwardNote: '', USPSMailFwdConfmCode: '', USPSErrorMessage: '' })
                setShowUSPSDialog(false);
                setShowSuccessDialog(true);
            }
            if (res && res.message) {
                setRespMessage(res.message);
            }
        } catch (err) {
            setRespMessage('Something went wrong! contact OPRA Admin');
        }

    }

    const setRecordsCount = (count: any, tabType: string) => {
        setRecordCount((prevCount: any) => ({ ...prevCount, [tabType]: count }));
    }

    const convertPdfToBase64 = (file: File): Promise<any> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                if (reader.result) {
                    resolve(reader.result);
                } else {
                    reject(null);
                }
            };

            reader.onerror = () => {
                reject(null);
            };
        });
    }

    // Handle file change
    const handleFileChange = async (event: any) => {
        const selectedFile = event.target.files && event.target.files[0] ? event.target.files[0] : null;
        if (selectedFile) {
            // Check if the file is a .pdf            
            const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
            const fileBase64String = await convertPdfToBase64(selectedFile);
            if (fileExtension === 'pdf' && fileBase64String) {
                setFileName(selectedFile.name);
                setSelectedFile(selectedFile);
                onChangeInputValues('USPSScreenshot', fileBase64String);
            } else {
                setFileName('');
                onChangeInputValues('USPSScreenshot', '');
                setFileError('Please select PDF file')
            }
        }
    };
    
    /**
     * Reassignment functionality
     */
    const doBulkUpdate = async () => {
        setIsLoading(true);
        setDoRefresh(false);        
        let body: any = {
            mftIDs: selectedRows.map((e: any) => e.MailFwdTrackingID),
            mftNote: assignmentData.notes,
            assignedTo: assignmentData.selUser && assignmentData.selUser.azureUserId ? assignmentData.selUser.azureUserId : '',
            userid:localStorage.getItem("loginCredentials") ? JSON.parse((localStorage.getItem("loginCredentials") || '{}' ))?.azureAdUserId : undefined
        }
        for (let prop in body) {
            if (!body[prop]) {
                delete body[prop];
            }
        }
        const res = await fetchMethodRequest('POST', `mailForwardsDetails/bulkUpdateMFTs`, body, '', '', '');
        setIsLoading(false);
        setReAssignResp({ type: res.success ? 'success' : 'danger', message: res.success ? `${selectedRows.length} records updated successfully` : res.message });
        if (res.success) {
            setSelectedRows([]);
            setDoRefresh(true);
        }
        setTimeout(() => {
            setReAssignResp({ type: '', message: '' });
        }, 1000 * 5);
    }

    const getTheOtherTabCount = async (value?:any,tab?:any) => {
        const loginCredentials = value?value:localStorage.getItem("loginCredentials") ? JSON.parse(localStorage.getItem("loginCredentials") || '') : {};
        const body = {
            dealType: tab === 1 ? 'BK' : 'CR',
            userid: loginCredentials.azureAdUserId?loginCredentials.azureAdUserId:loginCredentials.azureUserId,
            filter: {limit: 10, page: 1, criteria: [], sortfield: "LastFwdRequestDate", direction: "DESC"}
        }
        const res = await fetchMethodRequest('POST', 'mailForwardIntegrity/getMFTIntegrityFiltered', body, '', '', '');
        if (res && res.details && (res.details.totalCount || res.details.totalCount === 0)) {
            setRecordsCount(res.details.totalCount, tab === 1 ? 'BK' : 'CR');
        }
    }

    const getDataGrid = () => {
        const tabType: string = (activeTab === 1 || activeTab === 2) ? 'CR' : 'BK';
        const api: string = (activeTab === 1 || activeTab === 3) ? 'mailForwardIntegrity/getMFTIntegrityFiltered' : (activeTab === 2 || activeTab === 4) ? 'mailForwardIntegrity/getMFTIntegrityHistoryFiltered' : '';
        return <DataTables
            getTableFields={getTableFields}
            formFields={getTableFields}
            exportRequried={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            sortField={"LastFwdRequestDate"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            filterRequired={true}
            gridRequried={false}
            isSelectMode={""}
            sample={false}
            scrollHeight={"580px"}
            dataKey={"MailFwdTrackingID"}
            globalSearch={'Search'}
            type={"Mail Forwards Integrity"}
            screenPermissionsRoute={'Integrity - Mail Forwards'}
            displayright={true}
            selectedIntegrityMember={integrityMember}
            icon='faCity'
            fontAwesome={true}
            MFTab={tabType}
            displayViewOfForm='modal'
            hideHeader={true}
            // apiResponseKey='details'
            className={true}
            // apiUrl={"mailForwardAssignments/getMFTAssignmentsFiltered"}
            apiUrl={api}
            entityType='employee'
            hideProgress={true}
            // removeTopFilter={removeTopFilter}
            // doSearch={doSearch}
            paginatorPosition={'top'}
            style={{ marginTop: 10 }}
            donotIncludeParamsInFilter={true}
            showFilterElements={false}
            showTopActions={true}
            onSelectRows={onSelectRows}
            doRefresh={doRefresh}
            removePadding={true}
            setRecordsCount={setRecordsCount}
            activeTab={activeTab}
            updateUSPSConfirmationCode={updateUSPSConfirmationCode}
        />
    }


    const getDataforFilteredUser = async (e: any) => {
        setIntegrityMember(e.value)
        let details={ activeTab: activeTab.toString(),integrityMember:e.value}
        localStorage.setItem("MailForwardsIntegrityCurrentTab",JSON.stringify(details));
        getTheOtherTabCount(e.value,activeTab)
        await setDoRefresh(doRefresh ? false : true)
    }

    const updateUSPSConfirmationCode = async (mailFwdID:string,code: string) => {
        setRespMessage('');
        setIsLoading(true);
        setDoRefresh(false);
        let data = {
            USPSMailFwdConfmCode: code
        }
        const body = {
            data: JSON.stringify(data),
            mailFwdID:mailFwdID,
            userid: localStorage.getItem("loginCredentials") ? JSON.parse((localStorage.getItem("loginCredentials") || '{}'))?.azureAdUserId : undefined
        }
        const res = await fetchMethodRequest('POST', `mailForwardsDetails/updateMFTIntegrity`, body, '', '', '');
        setIsLoading(false);
        if (res && res.success) {
            setDoRefresh(true);            
            setShowSuccessDialog(true);
        }
        if (res && res.message) {
            setRespMessage(res.message);
        }
    }



    return <div className="card-body mail-fwd-integrity p-2 mt-1">
        {isauthorizedIntegrityMember ? <div className="row mb-2">
            <label className="d-flex pl-3">Integrity Member</label>
            <div className="col-sm-2">
                <Dropdown className="w-100" options={users} optionLabel="display_name" value={integrityMember} onChange={(e) => getDataforFilteredUser(e)} />
            </div>
        </div> : null}
        <Nav tabs className="border-line">
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 1, h6: true, dueHeader: true }}
                    onClick={() => { setActiveTab(1) }}
                >
                    CR Due({recordCount.CR})
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 2, h6: true }}
                    onClick={() => { setActiveTab(2) }}
                >
                    CR History
                </NavLink>
            </NavItem>
            <NavItem >
                <NavLink
                    className={{ active: activeTab === 3, h6: true, dueHeader: true }}
                    onClick={() => { setActiveTab(3) }}
                >
                    BK Due({recordCount.BK})
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 4, h6: true }}
                    onClick={() => { setActiveTab(4) }}
                >
                    BK History
                </NavLink>
            </NavItem>

            <NavItem className="d-flex flex-grow-1 justify-content-end pr-1">
                {/* <div>
                    <Button color="primary"  outline className="mb-1" onClick={() => { alert('Save Details') }}>
                    <FontAwesomeIcon icon={faDownload}/>
                    </Button>
                </div> */}

            </NavItem>
        </Nav>
        {activeTab === 1 || activeTab === 3 ? <Accordion className="mt-2">
            <AccordionTab header={<div className="d-flex justify-content-between w-100"><div>Action</div> <div style={{ paddingLeft: '50vw' }} >{reAssignResp.message && <span className={reAssignResp.type === 'success' ? 'text-success' : reAssignResp.type === 'danger' ? "text-danger" : ''}>{reAssignResp.message}</span>} </div> </div>}>
                <div className="card-body mt-2 p-1">
                    <div className="row">
                        <div className="col-md-11">
                            <div className="row">
                                <div className="col-md-3 mb-2">
                                    <div className="d-flex flex-column justify-content-between h-100">
                                        <div>
                                            <label className="mb-0">Re-Assign To</label>
                                            <Dropdown
                                                value={assignmentData.selUser}
                                                disabled={selectedRows.length === 0} options={users} optionLabel="display_name" onChange={(e) => { onChangeAssignmentValues('selUser', e.value) }}
                                                style={{ minWidth: '100%' }} filter={true} />
                                        </div>

                                    </div>

                                </div>

                                <div className="col-md-3 ">
                                    <label className="mb-0">Note</label>
                                    <InputTextarea disabled={selectedRows.length === 0}
                                        value={assignmentData.notes}
                                        className="w-100" rows={5} onChange={(e) => onChangeAssignmentValues('notes', (e.target as HTMLInputElement).value)} />
                                </div>
                                <div className="col-md-3 mb-2">
                                    <div className="d-flex flex-column justify-content-center h-100">
                                        <div>
                                            <Button
                                                disabled={selectedRows.length === 0 || (!assignmentData.selUser && !assignmentData.notes)}
                                                color="primary" type='submit'
                                                onClick={doBulkUpdate}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    {/* {respMessage.message && <Alerts type={respMessage.type} message={respMessage.message} changeStatus={() => { setRespMessage({ type: '', message: '' }) }} />} */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </AccordionTab>

        </Accordion> : null}

        <TabContent activeTab={activeTab}>
            {loadDataTable && <>
                <TabPane tabId={1} >
                    {/* {activeTab === 1 && <>{getAction()}</>} */}
                    {activeTab === 1 && <>{getDataGrid()}</>}
                </TabPane>
                <TabPane tabId={2}>
                    {activeTab === 2 && <>{getDataGrid()}</>}
                </TabPane>
                <TabPane tabId={3}>
                    {/* {activeTab === 3 && <>{getAction()}</>} */}
                    {activeTab === 3 && <>{getDataGrid()}</>}
                </TabPane>
                <TabPane tabId={4}>
                    {activeTab === 4 && <>{getDataGrid()}</>}
                </TabPane>
            </>
            }


        </TabContent>

        <Dialog header="Update" showHeader={false} visible={showUSPSDialog} style={{ width: '50vw' }} modal={true} onHide={() => hideDialogAndResetValues()}>
            <div className="p-1 text-right">
                <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => { setShowUSPSDialog(false) }} />
            </div>
            <div className="p-3">
                <div className="row mb-2">
                    <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">USPS Confirmation Number</label>
                    <div className="col-sm-8">
                        <InputText className="w-100" value={updateData.USPSMailFwdConfmCode} onChange={(e) => onChangeInputValues('USPSMailFwdConfmCode', (e.target as HTMLInputElement).value)} disabled={updateData.USPSErrorMessage} />
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">USPS Error Message (If any)</label>
                    <div className="col-sm-8">
                        <InputTextarea rows={5} className="w-100" value={updateData.USPSErrorMessage} onChange={(e) => onChangeInputValues('USPSErrorMessage', (e.target as HTMLInputElement).value)} disabled={updateData.USPSMailFwdConfmCode} />
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">USPS Screenshots (.pdf only)</label>
                    <div className="col-sm-8">
                        <div className="d-flex">
                            <div>
                                <label htmlFor={"custom-editor-uploader"} className="custom-upload-button">
                                    Upload
                                </label>
                                <input type='file' id="custom-editor-uploader" accept=".pdf" onChange={handleFileChange}></input>
                            </div>
                            <div className="ml-2">
                                {fileName && <span>{fileName}</span>}
                                {fileError && <span className="text-danger">{fileError}</span>}
                            </div>
                        </div>


                    </div>
                </div>
                <div className="row mb-2">
                    <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end ">Note</label>
                    <div className="col-sm-8">
                        <InputTextarea rows={5} className="w-100" value={updateData.MailForwardNote} onChange={(e) => onChangeInputValues('MailForwardNote', (e.target as HTMLInputElement).value)} />
                    </div>
                </div>
                {respMessage && <div className="text-center text-danger mt-2">
                    {respMessage}
                </div>}
                <div className="text-center mt-2">
                    <Button color="primary" onClick={doUpdate} disabled={!updateData.USPSErrorMessage && !updateData.USPSMailFwdConfmCode}>
                        Update
                    </Button>
                </div>
            </div>
            <Loader loader={isLoading} />
        </Dialog>
        <Dialog showHeader={false} visible={showSuccessDialog} onHide={() => setShowSuccessDialog(false)} style={{ width: '30vw' }}>
            <div className="p-3  text-center">
                <h5 className="text-success">{respMessage}</h5>
                <div className="text-center mt-3">
                    <Button color="primary" onClick={() => { setShowSuccessDialog(false) }} >
                        OK
                    </Button>
                </div>
            </div>
        </Dialog>

        <Dialog showHeader={false} visible={showPDFDialog} style={{ width: '85vw' }} modal={true} onHide={() => setShowPDFDialog(false)}>

            <div className="p-1 text-right">
                <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => { setShowPDFDialog(false) }} />
            </div>
            <div className="p-2">
                <iframe src={pdfURL} style={{ 'width': '100%', height: '90vh' }} />
            </div>
        </Dialog>


        <Loader loader={isLoading} />
    </div>

}

export default MailForwardsIntegrity;