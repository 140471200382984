import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
import fetchRequest from '../../../../config/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import FollowUpFilters from '../../../FollowUpFilters';
import TableModal from '../../CommonDataTable/FollowUpHistoryModal';
import apiCalls from '../../../../config/apiCalls';
const filter = {
  criteria: [],
  sortfield: "next_Action_Date",
  direction: "asc",
  // limit: 30
};
// config file
export default class NextActionUserReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // tabType: "",
      type: 'NextActionUserReport',
      apiUrl: ""
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    // let DepartmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      let DepartmentArray = user.secondaryDepartments ? user.secondaryDepartments : [], departMentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      DepartmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
      await this.setState({ Department: DepartmentArray, departMentIdArray: departMentIdArray })
    }
    await this.getClaimStatusReason();
    await this.getClaimStatusReasonforReasonArray();
  }


  getSettings = async () => {
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    // let apiUrl=''
    this.setState({
      isLoading: true
    });
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.settings) {
        let settingsData = response.settings[0]
        this.setState({
          isLoading: false,
          settingsData: settingsData,
        });
      }
    })
  }

  //Call for row color change
  customizedRowHighlights = (item) => {
    if (item && item.claimValueComputeColor && item.claimValueComputeColor === "Green") {
      return {
        'p-greenhighlightedRow': item.claimValueComputeColor === "Green"
      }
    } if (item && item.claimValueComputeColor && item.claimValueComputeColor === "Light Brown") {
      return {
        'p-lightBrownhighlightedRow': item.claimValueComputeColor === "Light Brown"
      }
    } if (item && item.claimValueComputeColor && item.claimValueComputeColor === "Brown") {
      return {
        'p-brownhighlightedRow': item.claimValueComputeColor === "Brown"
      }
    }
  }
  /**
   *
   * @returns the claim status reason array for claims filter dropdown
   */
  getClaimStatusReason = async () => {
    let claimStatusArray = [],
      claimReasonArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq", add_Filter: true },
      { key: "claimStatusFlag", value: true, type: "eq", add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence";
    filterCriteria.direction = "asc";
    await fetchRequest(
      "GET",
      `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(
        filterCriteria
      )}`
    ).then(async (response) => {
      if (
        response &&
        response["propertyStatusReasons"] &&
        response["propertyStatusReasons"].length > 0
      ) {
        let propertyStatusReasons = response["propertyStatusReasons"];
        for (let propertyStatusReason of propertyStatusReasons) {
          // if (propertyStatusReason.claimSequence) {
          if (
            !claimStatusArray.some(
              (obj) => obj.value == propertyStatusReason.status
            )
          ) {
            await claimStatusArray.push({
              label: propertyStatusReason.status,
              value: propertyStatusReason.status,
            });
          }
          await claimReasonArray.push({
            label: propertyStatusReason.reason,
            value: propertyStatusReason.reason,
          });
          // }
        }

        await this.setState({
          claimStatusArray: claimStatusArray,
          claimReasonArray: claimReasonArray,
          propertyStatusReasons: propertyStatusReasons,
        });
      } else if (response && response["errorMessage"]) {
        // this.getErrorResponsefromServer(response);
      }
    });
    return this.state.claimStatusArray;
  };

  /**
   *
   * @returns the claim status reason array for claims filter dropdown
   */
  getClaimStatusReasonforReasonArray = async () => {
    let claimStatusArray = [],
      claimReasonArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq", add_Filter: true },
      { key: "claimStatusFlag", value: true, type: "eq", add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence";
    filterCriteria.direction = "asc";
    await fetchRequest(
      "GET",
      `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(
        filterCriteria
      )}`
    ).then(async (response) => {
      if (
        response &&
        response["propertyStatusReasons"] &&
        response["propertyStatusReasons"].length > 0
      ) {
        let propertyStatusReasons = response["propertyStatusReasons"];
        for (let propertyStatusReason of propertyStatusReasons) {
          // if (propertyStatusReason.claimSequence) {

          await claimReasonArray.push({
            label: propertyStatusReason.reason,
            value: propertyStatusReason.reason,
          });
          // }
        }

        await this.setState({ claimReasonArray: claimReasonArray });
      } else if (response && response["errorMessage"]) {
        // this.getErrorResponsefromServer(response);
      }
    });
    return this.state.claimReasonArray;
  };
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  cancelTable = async () => {
    await this.setState({
      isOpenTable: false
    })
    let criteria = await this.datatableref.getFilterCriteria()
    await this.datatableref.getDataFromServer(criteria)
  }

  getTableModal() {
    return (
      <TableModal
        onRef={(ref) => this.tableModalRef = ref}
        openTableModal={this.state.isOpenTable}
        cancelReset={this.cancelTable}
        rowData={this.state.upOnlineData}
        viewType={this.state.viewType}
        screenType={"NextActionFollowUp"}
        getTableFieldsForHistory={this.getTableFieldsForHistory}
        type={this.state.hitType}
        bgColor={this.state.hitType === "followUpNumber" ? "lightblue" : "rgb(244,201,207)"}
        header={this.state.hitType === "followUpNumber" && this.state.viewType === "Edit" ? "Edit - Next Action Follow Ups & History" : this.state.hitType === "followUpNumber" ? "Next Action Follow Ups & History" : "State Up Online History"}
      />
    )
  }
  /**
      * Shows action buttons in table.
      * @param {Object} col 
      * @returns {HTMLElement} template with buttons.
      */
  getActions = (col, tableItem) => {
    col.latestFollow = (col && col.followUps && col.followUps.length === 0) || col.followUps === null ? [] : [col.followUps[0]]
    if (col.latestFollow && col.latestFollow.length > 0) {
      return <div className='dm-action-buttons'>
        <FontAwesomeIcon
          className={'tableheadericon'}
          color="#354f6e"
          style={{ marginTop: "13px", marginRight: "6px", cursor: "pointer" }}
          onClick={(e) => this.openDialog(col, "followUpNumber", "Edit")}
          icon={faPencilAlt}
        />
      </div>
    } else {
      return null
    }

  }
  setLimit = async (rows) => {
    await this.setState({ limitRows: rows })
  }
  openDialog = async (item, type, viewType) => {
    await this.setState({ isOpenTable: true, upOnlineData: item, hitType: type, viewType: viewType })
  }
  setAssetTrackingStatus = async (value) => {
    await this.setState({ setAssetTrackingStatus: value });
    this.datatableref.setAssetTrackingStatus(value)
  };

  //get call with API 
  getBasicCall = (criteria, obj, clear) => {
    const assetStatus = criteria.criteria.find(
      (elem) => elem.key === "ASSET_TRACKING_STATUS"
    );
    // && this.state.setAssetTrackingStatus !== "Empty"
    if (!assetStatus) {
      criteria.criteria.push({
        key: "ASSET_TRACKING_STATUS",
        value: ["Active"],
        type: "in",
        add_Filter: true,
      });
    }
    if (this.state.setAssetTrackingStatus === "Empty") {
      let index = criteria["criteria"].findIndex(obj => obj.key === "ASSET_TRACKING_STATUS")
      if (index !== -1) criteria["criteria"].splice(index, 1)
    }
    if (this.state.limitRows) {
      criteria.limit = this.state.limitRows
    }
    if (this.datatableref) {
      this.datatableref.setDataCriteriaforOpportunity(criteria, obj, clear)
    }
  }

  //get criteria from datatable
  getFiltercriteria = () => {
    if (this.datatableref) {
      return this.datatableref.getFilterCriteria()
    }
  }

  onChangeDepartment = (e, type, departmentIdArray) => {
    if (this.datatableref) {
      return this.datatableref.onChangeDepartment(e, 'selectedDepartment', departmentIdArray)

    }
  }
  setAssetTrackingStatus = async (value) => {
    await this.setState({ setAssetTrackingStatus: value });
    await this.datatableref.setAssetTrackingStatus(value)
  };

  getTaskFilters = () => {
    return (
      <FollowUpFilters
        getFiltercriteria={this.getFiltercriteria}
        getBasicCall={this.getBasicCall}
        filterCriteria={filter}
        setSortFilters={this.setSortFilters}
        setAssetTrackingStatus={this.setAssetTrackingStatus}
        onChangeDepartment={this.onChangeDepartment}

      />
    )
  }

  setSortFilters = async (key) => {
    if (key === "selectedDepartment") {
      await this.setState({ setDepartmentForFilter: null })
      this.datatableref.clearDepartment()
    } else if (key === "nextActionDateFilter") {
      await this.setState({ setNextActionDateForFilter: null })
    } else {
      await this.setState({ setNextActionDateForFilter: null, setDepartmentForFilter: null })
    }
  }

  getFormFields = () => {
    return ([
    ]);
  }

  //settingRedirectedUrl
  setRedirectUrl = async (item, clickedField, postBodyFile, screen) => {
    let url = `${window.location.protocol}//${window.location.host}/${screen}?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item.CompanyID}&FileFor=${encodeURIComponent(item.FileFor)}&state=${item.SourceState}&selectedPropertyStatus=MAPPING-READY FOR REVIEW&bucket=All&reportType=ReadyToReview`
    return url
  }
  getTableFieldsForHistory = () => {
    let data
    if (this.state.hitType === "followUpNumber" && this.state.viewType === "View") {
      data = [
        {
          "show": true,
          "textAlign": "center",
          "width": 50,
          "field": "expander",
          "header": "",
          "filter": false,
          "sortable": false,
          "placeholder": "Search"
        },
        {
          show: true,
          textAlign: "center",
          width: 70,
          header: "Follow-up #",
          mobile: true,
          field: "followUpNumber",
          fieldColor: true,
          filterType: "num",
          // "fieldType": "claimHistory",
          ////editField: "text",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 140,
          header: "Claim Status",
          mobile: true,
          field: "claimStatus",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 140,
          header: "Claim Reason",
          mobile: true,
          field: "claimReason",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 140,
          header: "Last Status Updated By",
          mobile: true,
          field: "updatedBy",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 110,
          header: "Next Action Date",
          mobile: true,
          fieldType: "dateTime",
          fieldDate: "Date",
          dateFormat: "MM-DD-yyyy",
          field: "followUpDate",
          //editField: "text",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 120,
          header: "Next Action User",
          mobile: true,
          field: "followUpAnalyst",
          editField: "dropdown",
          options: this.state.userArray,
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 90,
          header: "Follow-up Completion Date",
          mobile: true,
          fieldType: "dateTime",
          fieldColor: true,
          fieldDate: "Date",
          dateFormat: "MM-DD-yyyy",
          field: "followUpCompletedDate",
          //editField: "text",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 90,
          header: "No. of Days Follow-up Past Due",
          mobile: true,
          fieldColor: true,
          filterType: "num",
          field: "daysPastDue",
          //editField: "text",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "left",
          width: 500,
          header: "Follow-up Notes",
          mobile: true,
          field: "followUpNotes",
          fieldType: "NoteArray",
          // editField: "text",
          displayInSettings: true,
          // filter: this.state.viewType === "Edit" ? false : true,
          // sortable: this.state.viewType === "Edit" ? false : true,
        },

      ]
    } else if (this.state.hitType === "followUpNumber" && this.state.viewType === "Edit") {
      data = [
        {
          show: true,
          textAlign: "center",
          width: 70,
          header: "Follow-up #",
          mobile: true,
          field: "followUpNumber",
          fieldColor: true,
          filterType: "num",
          // "fieldType": "claimHistory",
          ////editField: "text",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 140,
          header: "Claim Status",
          mobile: true,
          field: "claimStatus",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 140,
          header: "Claim Reason",
          mobile: true,
          field: "claimReason",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 140,
          header: "Last Status Updated By",
          mobile: true,
          field: "updatedBy",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 110,
          header: "Next Action Date",
          mobile: true,
          fieldType: "dateTime",
          fieldDate: "Date",
          dateFormat: "MM-DD-yyyy",
          field: "followUpDate",
          //editField: "text",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 120,
          header: "Next Action User",
          mobile: true,
          field: "followUpAnalyst",
          // editField: "dropdown",
          options: this.state.userArray,
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 90,
          header: "Follow-up Completion Date",
          mobile: true,
          fieldType: "dateTime",
          fieldColor: true,
          fieldDate: "Date",
          dateFormat: "MM-DD-yyyy",
          field: "followUpCompletedDate",
          //editField: "text",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },
        {
          show: true,
          textAlign: "center",
          width: 90,
          header: "No. of Days Follow-up Past Due",
          mobile: true,
          fieldColor: true,
          filterType: "num",
          field: "daysPastDue",
          //editField: "text",
          displayInSettings: true,
          filter: this.state.viewType === "Edit" ? false : true,
          sortable: this.state.viewType === "Edit" ? false : true,
        },

        {
          "show": true,
          "textAlign": "left",
          "width": 500,
          "allowInt": true,
          "header": "Follow-up Notes",
          "mobile": true,
          "fieldType": "ArrayForNotes",
          filterType: "num",
          "openNotes": true,
          "field": "followUpNotes",
          "displayInSettings": true,

        },


      ]
    } else {
      data = [{
        show: true,
        textAlign: "center",
        width: 150,
        allowInt: true,
        filterType: "date",
        header: "State UP Online Claim Status - Last Checked Date",
        mobile: true,
        // "//editField": "date",
        fieldType: "dateTime",
        fieldDate: "Date",
        dateFormat: "MM-DD-yyyy",
        field: "claimStatus_LastCheckedDate",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 210,
        header: "State UP Online - Claim Status Language",
        mobile: true,
        field: "claimStatus_Language",
        //editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 260,
        header: "OPP Mapped/Summarized Online State UP Claim Status",
        mobile: true,
        field: "oppMappedSummarizedOnlineStateUPClaimStatus",
        //editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 120,
        header: "OPP Action Required?",
        mobile: true,
        field: "oppFollowupRequired",
        filterType: "multiSelect",
        "fieldType": "Boolean",
        //editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 220,
        allowInt: true,
        filterType: "date",
        header: "State UP Online Claim Status - Last Activity Date",
        mobile: true,
        fieldDate: "Date",
        filterType: "num",
        // "//editField": "date",
        fieldType: "dateTime",
        field: "claimStatus_LastActivityDate",
        dateFormat: "MM-DD-yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 220,
        header: "UP State Online - Additional Claim Details",
        mobile: true,
        field: "upStateOnline_AdditionalClaimDetails",
        //editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 260,
        fieldType: "redirectToUrldirectly",
        header: "UP State Online Claim Status - URL",
        mobile: true,
        field: "claimStatus_URL",
        //editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      ]
    }
    return data;
  };

  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 50,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      },
      {
        show: true,
        textAlign: "right",
        width: 90,
        field: "stateClaimID",
        mobile: true,
        editField: "text",
        header: "St. Claim ID",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 100,
        field: "oppClaimID",
        mobile: true,
        editField: "text",
        "fieldType": "redirect",
        header: "Opp Claim ID",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 90,
        field: "companyID",
        mobile: true,
        allowInt: true,
        editField: "text",
        filterType: "num",
        header: "Deal ID ",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 100,
        header: "Deal",
        mobile: true,
        field: "estateName",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      {
        show: true,
        textAlign: "right",
        width: 90,
        header: "Src. State",
        mobile: true,
        field: "sourceState",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 90,
        header: "Source",
        mobile: true,
        field: "source",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 90,
        field: "hasH__Claims",
        mobile: true,
        allowInt: true,
        editField: "text",
        "fieldType": "redirect",
        filterType: "num",
        header: "# of Properties",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        header: "Claim Status",
        mobile: true,
        field: "claimStatus",
        filterType: "multiSelect",
        editField: "text",
        filterOptions: this.state.claimStatusArray
          ? this.state.claimStatusArray
          : [],
        displayInSettings: true,
        options: this.state.claimStatus,
        filter: true,
        sortable: true,
        // fieldType: "ClaimStatus",
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        header: "Claim Reason",
        mobile: true,
        field: "claimReason",
        editField: "text",
        options: [],
        filterType: "multiSelect",
        filterOptions: this.state.claimReasonArray
          ? this.state.claimReasonArray
          : [],
        displayInSettings: true,
        filter: true,
        sortable: true,
        // fieldType: "ClaimReason",
      },
      {
        show: true,
        textAlign: "left",
        width: 100,
        header: "Asset Tracking Status",
        mobile: true,
        field: "asseT_TRACKING_STATUS",
        editField: "text",
        displayInSettings: true,
        // filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        editField: "text",
        field: "claimValueComputeColor",
        mobile: true,
        filterType: "multiSelect",
        fieldType: "MultiColors",
        filterOptions: [{ label: "Actual", value: "Green" }, { label: "Actual + Estimated", value: "Brown" }, { label: "Estimated", value: "Light Brown" }, { label: "No Connected Properties", value: null }],
        header: "Claim Value Type",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        allowInt: true,
        // "filterType": "date",
        header: "Next Action Date",
        mobile: true,
        editField: "date",
        // fieldType: "dateTime",
        fieldType: "Date",
        filterType: "num",
        field: "next_Action_Date",
        dateFormat: "MM-DD-yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Next Action User",
        mobile: true,
        field: "analyst",
        editField: "dropDown",
        displayInSettings: true,
        options: this.state.userArray,
        filter: false,
        sortable: true,
        fieldType: "Analyst",
      },
      {
        show: true,
        textAlign: "right",
        width: 110,
        header: "Claim Est. Value At Filing",
        mobile: true,
        // allowInt: true,
        field: "claimEstimatedValueAtFiling",
        // "editField": "dropDown",
        filterType: "num",
        fieldType: "formatCurrencywithdec",
        editField: "text",
        inputType: "number",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 110,
        header: "Claim Latest Est. Value",
        mobile: true,
        // allowInt: true,
        field: "claimLatestEstimatedValue",
        editField: "text",
        filterType: "num",
        fieldType: "formatCurrencywithdec",
        displayInSettings: true,
        filter: true,
        sortable: true,
        // "fieldType": "Analyst"
      },
      {
        show: true,
        textAlign: "right",
        width: 100,
        allowInt: true,
        // filterType: "date",
        header: "Updated Date",
        mobile: true,
        filterType: "num",
        // "editField": "date",
        field: "updatedAtUtc",
        fieldType: "Date",
        dateFormat: "MM/DD/yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 100,
        header: "Updated By",
        editField: "text",
        mobile: true,
        field: "updatedBy",
        // "editField": "dropDown",
        displayInSettings: true,
        // filter: true,
        sortable: true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 90,
        "field": "team",
        "filterType": "text",
        "mobile": false,
        "header": "Team",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },

      {
        show: true,
        textAlign: "right",
        width: 100,
        allowInt: true,
        // filterType: "date",
        header: "Claim Creation Date",
        mobile: true,
        filterType: "num",
        // "editField": "date",
        field: "created",
        fieldType: "Date",
        dateFormat: "MM/DD/yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        allowInt: true,
        // filterType: "date",
        header: "Previous Claim Paid Date",
        mobile: true,
        filterType: "num",
        // "editField": "date",
        fieldType: "Date",
        field: "previousClaimPaidDate",
        dateFormat: "MM/DD/yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        allowInt: true,
        // filterType: "date",
        header: "Most Recent Claim Paid Date",
        mobile: true,
        filterType: "num",
        // "editField": "date",
        fieldType: "Date",
        field: "mostRecentClaimPaidDate",
        dateFormat: "MM/DD/yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 100,
        field: "outTheDoor_DateTime_UTC",
        mobile: true,
        editField: "text",
        filterType: "num",
        fieldType: "Date",
        dateFormat: "MM/DD/yyyy",
        header: "Claim Out The Door Date",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 90,
        header: "State UP Site-Claim History",
        mobile: true,
        field: "onlineStatusCount",
        headerBackgroundColor: "#FDEFE7",
        "fieldType": "claimHistory",
        editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        allowInt: true,
        filterType: "date",
        header: "State UP Online Claim Status - Last Checked Date",
        mobile: true,
        filterType: "num",
        headerBackgroundColor: "#FDEFE7",
        // "editField": "date",
        field: "claimStatus_LastCheckedDate",
        fieldType: "Date",
        dateFormat: "MM/DD/yyyy",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        header: "State UP Online - Claim Status Language",
        mobile: true,
        field: "claimStatus_Language",
        headerBackgroundColor: "#FDEFE7",
        editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        header: "OPP Mapped/Summarized Online State UP Claim Status",
        mobile: true,
        field: "oppMappedSummarizedOnlineStateUPClaimStatus",
        editField: "text",
        headerBackgroundColor: "#FDEFE7",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        header: "OPP Action Required?",
        mobile: true,
        field: "oppFollowupRequired",
        headerBackgroundColor: "#FDEFE7",
        displayOppRequired: true,
        "fieldType": "Boolean",
        //editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        allowInt: true,
        filterType: "date",
        header: "State UP Online Claim Status - Last Activity Date",
        mobile: true,
        filterType: "num",
        headerBackgroundColor: "#FDEFE7",
        // "editField": "date",

        field: "claimStatus_LastActivityDate",
        fieldType: "Date",
        dateFormat: "MM/DD/yyyy",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        header: "UP State Online - Additional Claim Details",
        headerBackgroundColor: "#FDEFE7",
        mobile: true,
        field: "upStateOnline_AdditionalClaimDetails",
        editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        headerBackgroundColor: "#FDEFE7",
        header: "UP State Online Claim Status - URL",
        mobile: true,
        fieldType: "redirectToUrldirectly",
        field: "claimStatus_URL",
        editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },

      {
        show: true,
        textAlign: "center",
        width: 90,
        header: "Follow-up # and History",
        mobile: true,
        field: "followUpNumber",
        "fieldType": "claimHistory",
        headerBackgroundColor: "#DCEAF7",
        editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "right",
        width: 150,
        header: "Scheduled No. of Days Between Follow-ups",
        mobile: true,
        field: "daysForNextFollowUp",
        headerBackgroundColor: "#DCEAF7",
        editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        header: "Follow-up Scheduled Date",
        mobile: true,
        fieldType: "Date",
        dateFormat: "MM/DD/yyyy",
        headerBackgroundColor: "#DCEAF7",
        field: "followUpDate",
        editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        header: "Follow-up Completion Date",
        mobile: true,
        field: "followUpCompletedDate",
        headerBackgroundColor: "#DCEAF7",
        fieldType: "Date",
        dateFormat: "MM/DD/yyyy",
        editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "right",
        width: 150,
        header: "No. of Days Follow-up Past Due",
        mobile: true,
        field: "daysPastDue",
        editField: "text",
        headerBackgroundColor: "#DCEAF7",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "right",
        width: 90,
        header: "Follow-up Status",
        mobile: true,
        field: "followUpStatus",
        headerBackgroundColor: "#DCEAF7",
        editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },
      {
        show: true,
        textAlign: "right",
        width: 150,
        headerBackgroundColor: "#DCEAF7",
        header: "Follow-up Notes",
        mobile: true,
        field: "followUpNotes",
        "fieldType": "Array",
        // editField: "text",
        displayInSettings: true,
        filter: false,
        sortable: false,
      },

    ];
    return data;
  };

  submit = async (item) => {
  }

  render() {
    let tabData = JSON.parse(localStorage.getItem("ruleGroups"))
    return (
      <>

        <span>
          {/* <Loader loader={this.state.isLoading} /> */}
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            exportRequried={false}
            excelDownload={true}
            printRequried={false}
            addRequried={false}
            getActions={this.getActions}
            editRequired={false}
            deleteRequired={false}
            viewRequired={true}
            dontShowTitle={true}
            customisedExport={true}
            settingsRequired={false}
            claimStatusArray={
              this.state.claimStatusArray ? this.state.claimStatusArray : []
            }
            colorValues={[{ label: "Actual", value: "Green" }, { label: "Estimated", value: "Brown" }, { label: "Actual + Estimated", value: "Light Brown" }, { label: "No Connected Properties", value: "" }]}
            claimReasonArray={
              this.state.claimReasonArray ? this.state.claimReasonArray : []
            }
            filterRequired={true}
            gridRequried={false}
            sample={false}
            getUpOnlineTable={this.getUpOnlineTable}
            followUpStatus={this.followUpStatus}
            gettaskFilters={this.getTaskFilters}
            isClearFilters={true}
            setRedirectUrl={this.setRedirectUrl}
            globalSearchFieldName='user'
            globalSearch={""}
            openDialog={this.openDialog}
            redirecteddUrl={true}
            ascDirection={true}
            setLimit={this.setLimit}
            screenPermissionsRoute={"Next Action User Report"}
            type={"NextActionUserReport"}
            similarReportType={"followUpReport"}
            teamSelected={true}
            custumisedRowHighlights={this.customizedRowHighlights}
            displayright={true}
            icon='menu-circle'
            sortField={"next_Action_Date"}
            routeTo='availableToFollowUp'
            displayViewOfForm='modal'
            apiResponseKey={"claims"}
            className={true}
            onlyCoreopsTeam={true}
            apiUrl={apiCalls.followUpReport}
            entityType='employee'
          />
        </span>
        {this.state.isOpenTable ? this.getTableModal() : null}
      </>
    );
  }
}