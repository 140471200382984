import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, Input } from 'reactstrap';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from "redux-form";
import apiCalls from '../../../config/apiCalls';
import AutoComplete from '../../Form/components/AutoComplete';
import {faPlusSquare ,faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validate from "../../Validations/validate";
import { Rnd } from "react-rnd";
import { Card, CardBody, CardHeader } from 'reactstrap';
import MultiSelectDropDown from '../../Form/components/MultiSelect';
import configMessages from '../../../config/configMessages';
import { Dropdown } from 'primereact/dropdown';
import Loader from '../../App/Loader';
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import dateFormats from '../../UI/FormatDate/formatDate';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';



const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid gray"
};

class NameSheetBulkUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 1200,
            height: 650,
            x: 885,
            y: -950,

            tableFields: [],
            originalTableFields: [],
            originalAttributeOptions: [],
            updatedAttributeOptions: [],
            attributeValue: [],
            allTableValue: [],
            isAttributeSetSelected: false,
            isLoading: false,
            validationErrors: {},
            errors: {},
            disableKeys: ['CompanyID', 'BusinessNamesID'],
            requiredFields: this.props.requiredFields,
            anyoneRequiredField: this.props.anyOneField,
            isValue: true,
            connectedNames: {},
            showUpdatedRows: true,
            rowsCount: 0

        };
    }

    componentDidMount = async () => {

        let tableFields = this.props.tableFieldsToShow;
        let totalTableFields = await this.props.getTableFields();
        const disableKeys = this.getDisableKeys(tableFields);
        let optionLabels = {};
        let attributeOptions = await tableFields.reduce((arr, item) => {
            let { header, field } = item;
            if (field && header && !disableKeys.includes(field)) {
                optionLabels[field] = header;
                arr.push({ label: header, value: field });
            }
            return arr;
        }, []);


        this.setState({
            tableFields,
            optionLabels,
            originalAttributeOptions: attributeOptions,
            updatedAttributeOptions: attributeOptions,
            allTableValue: this.props.allTableValue,
            originalTableFields: totalTableFields,
            isLoading: true,
            rowsCount: this.props.selectedRows.length
        });

        await this.centerElement()
        await this.getAttributeSet()

    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedRows.length !== this.props.selectedRows.length && this.props.selectedRows.length > 0) {
            this.setState({ showUpdatedRows: true });
        }
    }

    centerElement = async (element) => {
        // Get the dimensions of the viewport
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.screen.height
        // Get the dimensions of the element
        const elementWidth = 1200;
        const elementHeight = 600;
        const leftPosition = (viewportWidth - elementWidth) / 2;
        const topPosition = (viewportHeight - elementHeight) / 2;
        await this.setState({ x: leftPosition, y: topPosition - 300 })

    }

    getDisableKeys = (tableFields) => {
        let isDisable = this.state.disableKeys;
        const fieldsArray = tableFields.reduce((acc, item) => {
            if (!item.hasOwnProperty('editField') && item.field) {
                acc.push(item.field);
            }
            return acc;
        }, []);
        const disableKeys = [...isDisable, ...fieldsArray];
        return disableKeys;
    }


    filterAndMatchAttributeNameSet = (attributeSet, tableFieldsToShow) => {
            const isDisableKeys = this.getDisableKeys(tableFieldsToShow);
            return attributeSet.attributeNameSet.filter(
              field => 
                !isDisableKeys.includes(field) && 
                tableFieldsToShow.some(fieldObj => fieldObj.field === field) 
            );
      };

    /*
     Get  Attribute Sets Data
    */
    getAttributeSet = () => {
        fetchMethodRequest('GET', apiCalls.attributeSet)
            .then(async (response) => {
                this.setState({ isLoading: false })
                if (response && response.configNameSheetBulkUpdateAttributeSets.length > 0) {
                    const attributeSet = response.configNameSheetBulkUpdateAttributeSets[0];

                    if (attributeSet) {
                        this.props.change('attributeSet', attributeSet);
                        this.handleAutocomplte(attributeSet);
                    }

                }
            }).catch((err) => {
                return err;
            });
    }

    /*
    Error Validations for Fields
    */
    setValidations = async (showErrorMsg) => {
        let errors = {};
        let validate = false;

        await this.state.attributeValue.forEach(async (item, index) => {
            const requiredFields = this.state.requiredFields ? this.state.requiredFields : []
            const isRequired = item.columnData && item.columnData.required ? item.columnData.required : false
            const anyOneRequired = this.state.anyoneRequiredField;

            if (!item.field) {
                errors.field = item.field;
                validate = true
            }
            const isMulti = item.columnData && item.columnData.editField === "multiDropdown" && Array.isArray(item.newValue) ? item.newValue.length == 0 : true;
            let anyOne = anyOneRequired.includes(item.field);

            let displayError = anyOne ? !(await anyOneRequired.some(key => {
                let data = this.state.attributeValue.find(obj => obj.field == key);
                if (data && data.newValue) {
                    if (data.columnData && data.columnData.editField === "multiDropdown") {
                        return Array.isArray(data.newValue) && data.newValue.length > 0
                    }
                    return true;
                }
                return false;

            })) : anyOne;


            if (((!item.newValue && !anyOne) || (displayError)) && isRequired && item.field && isMulti) {
                errors[item.field] = true;
                validate = true
            }
        });
        let validations = { errors };
        if (showErrorMsg) {
            validations.showErrorMsg = true
        }
        this.setState(validations);
        return validate
    }

   /**
   * @param {object} e 
   * Changing the Attribute Set
   */
    handleAutocomplte = async (e) => {

        if(e.attributeNameSet && e.attributeNameSet.length > 0){
            e.attributeNameSet = this.filterAndMatchAttributeNameSet(e, this.props.tableFieldsToShow);
        }
        this.setState({ isLoading: true })
        let keys = e.attributeNameSet ? e.attributeNameSet : []
        let updatedOptions = await this.state.originalAttributeOptions.filter((option) => !keys.some(key => key == option.value));
        let attributeValue = await keys.map(key => {
            const field = key;
            const currentValue = this.getTableDataByFieldName(key);
            const columnData = this.getColumnByKey(key);
            const newValue = this.getNewValueByField(currentValue, columnData);
            return { field, currentValue, columnData, newValue };
        });

        this.setState({

            isAttributeSetSelected: true,
            attributeValue,
            updatedAttributeOptions: updatedOptions,
            isLoading: false
        });
        if (this.state.showErrorMsg) {
            this.setValidations();
        }
        this.isCurrentValuePresent();
    }

    getColumnByKey = (key) => {
        let column = this.state.tableFields && this.state.tableFields.find((item) => item.field === key);
        return column ? column : {};
    }

    isCurrentValuePresent = () => {
        const { attributeValue } = this.state;
        const isCurrentValue = attributeValue.some(item => (!item.currentValue && item.columnData && item.columnData.required));
        this.setState({ isValue: isCurrentValue });
    }

    getTableDataByFieldName = (key) => {

        let useData = this.state.allTableValue && this.state.allTableValue.reduce((acc, item) => {
            const value = item[key];
            if (value && !acc.includes(value)) {
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        if (!acc.includes(item)) {
                            acc.push(item);
                        }
                    })
                } else {
                    acc.push(value);
                }
            }
            return acc;
        }, [])

        return useData
    }

      /**
    * @param {array} currentValue 
    * @param {object} columnData 
    * Prepopulating the newValue Based on Attribute
    */
      getNewValueByField = (currentValue, columnData) => {

        if (Object.keys(columnData).length > 0) {

            if ((columnData.field === "LegacyConnectedNameLabel" || columnData.field === "PostSaleConnectedNameLabel" || columnData.field === "PreSaleConnectedNameLabel") && columnData.options.length > 0) {
                let connectedName = columnData.options.find((item) => item.value === currentValue[0]);
                  if (!connectedName) {
                    connectedName =  columnData.options[0]
                  }
                  let connectedNames = this.state.connectedNames ? this.state.connectedNames : {};
                  connectedNames[columnData.field] = connectedName;
                this.setState({connectedNames});
                return connectedName;
            } else if (columnData.editField === 'multiDropdown') {
                if (columnData.options.length > 0) {
                    let defaultMultiOptions = currentValue.filter((item) => columnData.options.some((option) => option.value === item));
                    if(defaultMultiOptions.length === 0 ){
                        defaultMultiOptions.push(columnData.options[0].value)
                    }
                    return defaultMultiOptions;
                } else {
                    return [];
                }
            } else if (columnData.editField === 'dropdown') {
                if (columnData.options.length > 0) {
                    const defaultOption = columnData.options.find((item) => item.value === currentValue[0]);
                    return defaultOption ? defaultOption.value : columnData.options[0].value;
                } else {
                    return undefined;
                }

            } else if (columnData.editField === 'date' && currentValue[0]) {
                return new Date(currentValue[0]);
            } else {
                return currentValue[0];
            }
        }

    }

    /**
    * @param {object} e 
    * @param {object} data 
    * Changing the Attribute And New Value
    */
    handleDropdownChange = async (e, data) => {
        this.setState({ isLoading: true })

        let arr = this.state.attributeValue;
        let updatedAttributeOptions = await this.state.updatedAttributeOptions;

        if (data.key == 'field') {
            const field = e.value
            const currentValue = await this.getTableDataByFieldName(field);
            const columnData = await this.getColumnByKey(field);
            const newValue = await this.getNewValueByField(currentValue, columnData)
            arr[data.index] = { field, currentValue, columnData, newValue };
            updatedAttributeOptions = await this.state.originalAttributeOptions.filter((option) => !arr.some(data => data.field == option.value));

        } else {
            if (data && data.item && data.item.field) {
                if ( data.item.field === "LegacyConnectedNameLabel" || data.item.field === "PostSaleConnectedNameLabel" || data.item.field === "PreSaleConnectedNameLabel") {
                    let getBusinessNameID = data.item.options.find(elem => elem.BusinessName === e.BusinessName)
                     let connectedNames = this.state.connectedNames ? this.state.connectedNames : {};
                  connectedNames[data.item.field] = getBusinessNameID;
                  
                this.setState({connectedNames});
                }
            }
            arr[data.index][data.key] = e
        }

        this.props.change("attributeValue", arr);
        await this.setState({
            attributeValue: arr, updatedAttributeOptions,

        });
        this.isCurrentValuePresent();
        if (this.state.showErrorMsg) {
            this.setValidations();
        }
        this.setState({ isLoading: false })
    }

    /*
     Add new Row
    */
    addRow = () => {
        this.setValidations(true).then(isvalid => {
            if (!isvalid) {
                this.setState((prevState) => ({
                    attributeValue: [...prevState.attributeValue, { field: null, currentValue: null, newValue: null }]
                }));
            }
        });


    };

    formatDate = (dateStr) => {
        if (dateStr) {
            const date = new Date(dateStr);
            const formattedDate = [
                String(date.getMonth() + 1).padStart(2, '0'),
                String(date.getDate()).padStart(2, '0'),
                date.getFullYear()
            ].join('-');
            return formattedDate;
        }
        return null;
    }


    /**
    * @param {object} Data 
    * @param {Number} index
    * Removing the Selected Row 
    */
    deleteRow = (Data, index) => {
        if (Data && (this.state.attributeValue && this.state.attributeValue.length > 0)) {
            const filteredAttributes = this.state.attributeValue.filter((item, i) => item.field !== Data.field);
            this.setState(prevState => ({
                attributeValue: filteredAttributes,
                updatedAttributeOptions:prevState.originalAttributeOptions.filter((option) => !filteredAttributes.some(data => data.field === option.value))
            }))
        }
    }

    /*
    Send Data To Server
    */
    submit = async (data) => {
        this.setValidations(true).then(async isSubmit => {
            if (!isSubmit) {
                this.setState({ isLoading: true })

                const updatedTable = await this.state.attributeValue.reduce((result, item) => {
                    if (item.field) {
                        if (item.columnData && item.columnData.editField === "multiDropdown" && Array.isArray(item.newValue)) {
                            result[item.field] = item.newValue.join(',')
                        } else if (item.columnData && item.columnData.editField === "date") {
                            let value = dateFormats.formatDate(
                                item.newValue,
                                config.dbDateFormat, "nhg")
                            const formatedDate = this.formatDate(value)
                            result[item.field] = formatedDate

                        } else if (item.field === "LegacyConnectedNameLabel" || item.field === "PostSaleConnectedNameLabel" || item.field === "PreSaleConnectedNameLabel") {
                            const { connectedNames ={}} = this.state;
                            let connectedName = connectedNames[item.field] 
                                let updatedField = item.field.replace("Label", "")
                                result[updatedField] = connectedName.BusinessName;
                                result[updatedField + "ID"] =  connectedName.BusinessNamesID;
                                result[updatedField + "Label"] = item.newValue.value;         
                        }
                        else {
                            result[item.field] = item.newValue
                        }
                    }
                    return result
                }, {})
                const existNames = await this.state.allTableValue.map(item => {
                    if (item && item.IssueName) {
                        item.IssueName = Array.isArray(item.IssueName) ? item.IssueName.join(',') : item.IssueName;
                    }
                    return (Object.assign(item, updatedTable))
                });
                let body = { existNames, UndoNames: [], newNames: [] };
                const params = this.props.type === "Names V2" ? 'multiV2' :  'multi'
                const apiUrl = `${apiCalls.addNames}/${params}?dealID=${this.props.dimSellerId}`

                fetchMethodRequest('PUT', apiUrl, body)
                    .then(async (response) => {

                        if (response && response.respCode && response.respCode === 200) {
                            this.setState({ isLoading: false })
                            this.props.getCloseData()
                            this.props.getResponseMsgfromServer(response.respMessage, 'success')
                            this.props.getRefresh()

                        } else {
                            this.props.getCloseData()
                            this.props.getResponseMsgfromServer(response.title, 'errorView')
                        }
                    }).catch((err) => {
                        return err;
                    });
            }
        });
    }

    /*
    Displaying The Error Messages
    */
    getErrorMsg = (data) => {

        if ((this.state.errors[data.field] || (data.newValue && Array.isArray(data.newValue) && data.newValue.length === 0)) && this.state.showErrorMsg && this.state.errors[data.field]) {
            return <small className='text-danger'>Please Fill Fields</small>;
        }
        return null;
    }

    getDropDown = (name, item, i, data, uniqueKey, onChange) => {
        return (<>
            <div key={uniqueKey} className="form__form-group col-md-4">
                <Dropdown
                    value={data.newValue ? data.newValue : ''}
                    filter={true}
                    appendTo={document.body}
                    name={name}
                    id={name}
                    optionLabel={item.dataKey ? 'label' : null}
                    dataKey={item.dataKey ? item.dataKey : null}
                    optionValue={item.dataKey ? "value" : null}
                    style={{ width: "100%", height: "31px" }}
                    options={item.options ? item.options : []}
                    placeholder={item.placeholder ? item.placeholder : item.header ? item.header : null}
                    onChange={(e) =>  this.handleDropdownChange( e.value, { index: i, key: 'newValue' , item:item })}
                    disabled={this.props.type === "Names V2" && (this.props.nonEditable || item.nonEditable  ) ? true : false }
                />
                {this.getErrorMsg(data)}

            </div>
        </>
        )
    }

    getDropDownMultiSelect = (name, item, i, data, uniqueKey, onChange) => {
        return (
            <div key={uniqueKey} className="multiselectLabelStyles form__form-group col-md-4">

                <MultiSelect
                    value={data.newValue}
                    appendTo={document.body}
                    className={"tableMultiDropdown"}
                    placeholder={item.placeholder ? item.placeholder : item.header ? item.header : null}
                    name={name}
                    id={name}
                    options={item.options ? item.options : []}
                    style={{ width: "100%", height: "31px" }}
                    onChange={(e) =>  this.handleDropdownChange( e.value, { index: i, key: 'newValue' })}
                    disabled={this.props.type === "Names V2" && (this.props.nonEditable || item.nonEditable  ) ? true : false }

                />
                {this.getErrorMsg(data)}
            </div>
        )
    }

    getDateField = (name, item, i, data, uniqueKey, onChange) => {
        return (
            <div key={uniqueKey} className="form__form-group col-md-4">
                <div className="form__form-group-field">
                    <Calendar
                        style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        readOnlyInput={true}
                        name={name}
                        placeholder='mm/dd/yyyy'
                        dateFormat="mm/dd/yy"
                        value={data.newValue ? data.newValue : null}
                        onSelect={(e) =>  this.handleDropdownChange(new Date(e.value), { index: i, key: 'newValue' })}
                        disabled={this.props.type === "Names V2" && (this.props.nonEditable || item.nonEditable  ) ? true : false }
                    />
                </div>
                {this.getErrorMsg(data)}
            </div>
        )
    }

    getDefaultText = (name, item, i, data, uniqueKey, onChange) => {
        return (
            <div key={uniqueKey} className='col-md-4'>
                <InputText
                    value={data.newValue ? data.newValue : ''}
                    type={"text"}
                    ref={(ref) => { this.inputRef = ref }}
                    name={name}
                    id={name}
                    keyfilter={item.keyfilter ? item.keyfilter : null}
                    placeholder={item.placeholder ? item.placeholder : item.header}
                    onChange={(e) =>  this.handleDropdownChange( e.target.value, { index: i, key: 'newValue' })}
                    disabled={this.props.type === "Names V2" && (this.props.nonEditable || item.nonEditable  ) ? true : false }

                    />
                {this.getErrorMsg(data)}
            </div>
        )
    }

    /**
    * @param {String} name
    * @param {Number} i 
    * @param {object} item 
    * @param {object} data 
    * @param {string} uniqueKey 
    * Assigning All New VAlue Form Fields Based on Their Types
    */
    getField = (name, item, i, data, uniqueKey) => {
        if (item) {
            if (item.editField === "dropdown") {
                return this.getDropDown(name, item, i, data, uniqueKey);
            } else if (item.editField === "multiDropdown") {
                return this.getDropDownMultiSelect(name, item, i, data, uniqueKey);
            } else if (item.editField === "date") {
                return this.getDateField(name, item, i, data, uniqueKey);
            } else {
                return this.getDefaultText(name, item, i, data, uniqueKey)
            }
        }
    }

    /**
    * @param {object} data
    * @param {Number} i 
    * @param {string} uniqueKey 
    * Getting  All The Form Fields 
    */
    getFields = (data, i, uniqueKey) => {
        const { field, currentValue } = data;
        const options = field ? [{ label: this.state.optionLabels[field], value: field }, ...this.state.updatedAttributeOptions] : this.state.updatedAttributeOptions;

        let column = this.state.tableFields && this.state.tableFields.find((item) => item.field === field);
        // data.columnData;
        if (!column) {
            column = {}
        }

        return <div key={uniqueKey} className='d-flex justify-content-around'>

            <div className='d-flex align-items-center' style={{ height: '32px' }}>
                <FontAwesomeIcon
                    icon={faMinusSquare}
                    color='#354f6e'
                    data-toggle="tool-tip"
                    id="Delete Row"
                    onClick={() => this.deleteRow(data, i)}
                    title="Delete Row"
                    size='2x'
                    type='button'
                    className='add-row-icon'
                />

            </div>


            <div className="form__form-group col-md-4 mb-3" >
                <Dropdown
                    value={field ? field : ''}
                    filter={true}
                    appendTo={document.body}
                    name={`attributeValue[${i}].field`}
                    style={{ width: "100%", height: "31px" }}
                    options={options}
                    placeholder={column.placeholder ? column.placeholder : column.header ? column.header : 'Select Attribute'}
                    onChange={(e) => this.handleDropdownChange(e, { index: i, key: 'field' })}
                />

                {!field && this.state.showErrorMsg && <small className='text-danger'>Please Select </small>}

            </div>

            <div className='bulk-update-current-value form__form-group col-md-4'
                data-text={currentValue}
                onMouseOver={(e) => e.target.setAttribute("title", e.target.getAttribute("data-text"))}
            >
                {currentValue && currentValue.join()}
            </div>

            {this.getField(`attributeValue[${i}].newValue`, column, i, data, uniqueKey)}

        </div>
    }



    render() {
        const { handleSubmit, t } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });

        return (
            <div className="rnd_Modal nameSheetLoader" style={{ all: "unset" }}>
                <Loader loader={this.state.isLoading} className="loadPageProp" />
                <Rnd
                    className={` p-0 claim_creation_modal ${modalClass} rnd_modal`}
                    style={{ style, ...{ 'zIndex': 1045 } }}
                    size={{ width: this.state.width, height: this.state.height }}
                    position={{ x: this.state.x, y: this.state.y }}
                    onDragStop={(e, d) => {
                        this.setState({ x: d.x, y: d.y });
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        this.setState({
                            width: ref.style.width,
                            height: ref.style.height,
                            ...position
                        });
                    }}
                // enableResizing={false}
                >
                    <Card className='pb-0 '>
                        <CardHeader className={"modal__header"}>
                            <div className="d-flex" style={{ textAlign: "center" }}>
                                <button className="lnr lnr-cross modal__close-btn" type="button" style={{ right: 40 }}
                                    onClick={() => this.props.getCloseData()}
                                />
                                <span />
                                <h4 className='rangebuttontextstyle' style={{ textAlign: "center" }}>Name Sheet Bulk Update</h4>
                            </div>
                        </CardHeader>
                        <CardBody className=' p-0'
                        >
                            <form onSubmit={handleSubmit(this.submit)}>

                                {this.state.showUpdatedRows && this.props.selectedRows.length > 0 ? (
                                    <div className=" selected-rows col-md-6 text-center mt-2">
                                        <b>{`No.of Rows Selected - ${this.state.rowsCount}`}</b>
                                    </div>
                                ) : null}

                                <div className="d-flex col-md-12 mt-4 mb-3 align-items-center">
                                    <div className="attributeSetNamePannel d-flex flex-column">
                                        <label className="form__form-group-label attributeSetHeader mb-1" >
                                            Attribute Set
                                        </label>
                                        <Field
                                            name="attributeSet"
                                            component={AutoComplete}
                                            type="text"
                                            placeholder={t('Attribute Set')}
                                            onChange={(e) => { this.handleAutocomplte(e) }}
                                            searchApi={apiCalls.attributeSet}
                                            searchField="attributeSetName"
                                            id="attributeSet"
                                            style={{ height: 33 }}
                                            isEditedShow={false}
                                        />
                                    </div>
                                </div>

                                {this.state.isAttributeSetSelected || this.state.attributeValue.length > 0 &&  <div className='attributes-header col-md-12 mb-1'>
                                    <div className='col-md-4 pl-4'> <span className='ml-3'><b>Attribute</b></span> </div>
                                    <div className='col-md-4 pl-4'> <b>Current Value</b></div>
                                    <div className='col-md-4 pl-2'> <b>New Value</b></div>
                                </div>}
                                <div>{this.state.tableFields ? <div>
                                    {this.state.attributeValue && this.state.attributeValue.length > 0 ?
                                        this.state.attributeValue.map((item, i) => this.getFields(item, i, `${item.field || 'field'}_${i}`))
                                        : null}
                                    {this.state.attributeValue.length > 0  && (
                                        <div className="nameSheet-add-row">
                                            <FontAwesomeIcon
                                                icon={faPlusSquare}
                                                color='#354f6e'
                                                data-toggle="tool-tip"
                                                id="Add Row"
                                                onClick={this.addRow}
                                                title="Add Row"
                                                size='2x'
                                                className="add-row-icon"
                                                type='button'
                                            />
                                        </div>
                                    )}

                                </div> : "No Recoeds"}</div>
                                {this.state.attributeValue.length > 0  && <div className="mt-3 ml-3 d-flex justify-content-center">
                                    <Button color="primary">Bulk Update</Button>
                                    <Button color="secondary" onClick={() => this.props.getCloseData()}>Cancel</Button>
                                </div>}
                            </form>
                        </CardBody>
                    </Card>
                </Rnd>
                <div class="modal-backdrop show"></div>
            </div>

        );
    }
}


NameSheetBulkUpdateModal = reduxForm({
    form: 'NameSheetBulkUpdateModal',
    validate,
    enableReinitialize: true,
})(NameSheetBulkUpdateModal);


export default withTranslation('common')(NameSheetBulkUpdateModal);
