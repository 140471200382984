import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete'
import { RadioButton } from 'primereact/radiobutton';
import Loader from '../App/Loader';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import apiCalls from '../../config/apiCalls';
import fetch from '../../config/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../redux/actions/userActions';
import { Accordion, AccordionTab } from "primereact/accordion";
import { faStarOfLife,faTimes } from '@fortawesome/free-solid-svg-icons';
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class PropertySearchv3Filters extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      click: true,
      resultSet: 0,
      SQLCount: 0,
      MongoCount: 0,
      searchableProperties: true,
      confOnSite: true,
      userArray: [],
      sourceState: [],
      relativeSearch: true,
      setSearchType: "Relative",
      setPropertySearchType: "Raw Properties",
      confOnSiteDateFrom: new Date(new Date().setMonth(new Date().getMonth() - 6)),
      DataSource: ["State UP Site", "State File", "Missing Money Site"],
      dataArray: [
        {
          "label": "State File",
          "value": "State File",
        },
        {
          "label": "Royalties",
          "value": "Royalties",
        },
        {
          "label": "Missing Money Site",
          "value": "Missing Money Site",
        },
        {
          "label": "Co-op",
          "value": "Co-op",
        },
        {
          "label": "Australia",
          "value": "Australia",
        },
        {
          "label": "IPv4",
          "value": "IPv4",
        },
        {
          "label": "Miscellaneous",
          "value": "Miscellaneous",
        },
        {
          "label": "Bankruptcy Courts",
          "value": "Bankruptcy Courts",
        },
        {
          "label": "Bankruptcy Courts - Northern",
          "value": "Bankruptcy Courts - Northern",
        },
        {
          "label": "County",
          "value": "County",
        },
        {
          "label": "State Warrant",
          "value": "State Warrant",
        },
        {
          "label": "Bankruptcy Courts - Eastern",
          "value": "Bankruptcy Courts - Eastern",
        },
        {
          "label": "Bankruptcy Courts - Southern",
          "value": "Bankruptcy Courts - Southern",
        },
        {
          "label": "Bankruptcy Courts - Western",
          "value": "Bankruptcy Courts - Western",
        },
        {
          "label": "State UP Site",
          "value": "State UP Site",
        },
        {
          "label": "Bankruptcy Courts - Central",
          "value": "Bankruptcy Courts - Central",
        },
        {
          "label": "Bankruptcy Courts - Middle",
          "value": "Bankruptcy Courts - Middle",
        },
        {
          "label": "Restitution",
          "value": "Restitution",
        },
        {
          "label": "Singapore",
          "value": "Singapore",
        },
        {
          "label": "SEC (U.S. Securities and Exchange Commission)",
          "value": "SEC (U.S. Securities and Exchange Commission)",
        },
        {
          "label": "New Jersey Superior Court",
          "value": "New Jersey Superior Court",
        },
        {
          "label": "City Site",
          "value": "City Site",
        },
        {
          "label": "State UP Site - CA-N",
          "value": "State UP Site - CA-N",
        },
        {
          "label": "From State",
          "value": "From State",
        },
        {
          "label": "County Site",
          "value": "County Site",
        },
        {
          "label": "Rough Claims - From Data",
          "value": "Rough Claims - From Data",
        },
        {
          "label": "Co-Op Site",
          "value": "Co-Op Site",
        },
        {
          "label": "State UP Site - NY-ROCDOC",
          "value": "State UP Site - NY-ROCDOC",
        },
        {
          "label": "Excess Proceeds",
          "value": "Excess Proceeds",
        },
        {
          "label": "Long Lost Money",
          "value": "Long Lost Money",
        },
        {
          "label": "Outstanding Checks",
          "value": "Outstanding Checks",
        },
        {
          "label": "Test 40",
          "value": "Test 40",
        },
        {
          "label": "Test 1",
          "value": "Test 1",
        },
        {
          "label": "OPAM TESTING",
          "value": "OPAM TESTING",
        },
        {
          "label": "Test 2",
          "value": "Test 2",
        },
        {
          "label": "Test 5",
          "value": "Test 5",
        },
        {
          "label": "Bankruptcy Court Site",
          "value": "Bankruptcy Court Site",
        },
        {
          "label": "Test 18",
          "value": "Test 18",
        },
        {
          "label": "Test 23",
          "value": "Test 23",
        },
        {
          "label": "Test 29",
          "value": "Test 29",
        },
        {
          "label": "Test 30",
          "value": "Test 30",
        },
        {
          "label": "Test 8",
          "value": "Test 8",
        }
      ]
    };
  }
  getUsers = async () => {
    let userArray = []
    let filterCriteria = {};
    filterCriteria['criteria'] = [];
    return fetch('GET', `${apiCalls.Users}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          for (let user of response.users) {
            if (user.display_name !== null) {
              userArray.push({ label: user.display_name, value: user.azureUserId })
            }
          }
          await this.setState({ userArray: userArray })
        }
      }).catch((err) => {
        return err;
      });
  }

  getDataSource = async () => {
    await fetch('GET', `seller/dataSourceList?type=test`)
      .then(async (response) => {
        if (response) {
          if (response.respCode) {

            let dataSources = []
            for (let source of response.dataSources) {
              dataSources.push({ label: source.Name, value: source.Name })
            }
            await this.setState({
              Sources: dataSources
            })
          }
        }
      })
  }
  getDataSourceDetails = async () => {
    await fetch('GET', `seller/dataSourceDetailList?type=test`)
      .then(async (response) => {
        if (response) {
          if (response.respCode) {

            let dataSources = []
            for (let source of response.dataSourceDetailList) {
              dataSources.push({ label: source.Name, value: source.Name })
            }
            await this.setState({
              sourceDetailList: dataSources
            })
          }
        }
      })
  }
  getDataSourceChannelList = async () => {
    await fetch('GET', `seller/dataSourceChannelList?type=test`)
      .then(async (response) => {
        if (response) {
          if (response.respCode) {

            let dataSources = []
            for (let source of response.dataSourceChannelList) {
              dataSources.push({ label: source.DataSourceChannelSetName, value: source.DataSourceChannelSetName })
            }
            await this.setState({
              dataSourceChannelList: dataSources
            })
          }
        }
      })
  }
  getStates = async () => {

    await fetch('GET', `seller/StateList?type=test`)
      .then(async (response) => {
        if (response) {
          if (response.respCode) {
            // showToasterMessage(response.respMessage, 'success');
            let states = []
            for (let state of response.states) {
              states.push({ label: state.Abbreviation, value: state.Abbreviation })
            }
            await this.setState({
              States: states
            })
          } else if (response.errorMessage) {
            // display message
            // showToasterMessage(response.errorMessage, 'error');
          }
        }
      })
  }
  /**
 * 
 * @param {Object} e 
 * @param {String} key 
 * @param {String} selectedtype 
 * setting the 
 */
  async onChange(e, key, selectedtype) {
    var val
    if (e && e.persist) {
      e.persist();
    }
    if (e && e.target) {
      val = e.target.value
      if (key) {
        await this.setState({
          [key]: key == "MinPropValue" || key == "MaxPropValue" ? val.replace(/[$,]/g, "") : val,
        })
      }
      let filtercriteria = {
        "key": key,
        "value": key === "status" || key === "department" ? val : encodeURIComponent(val),
        "type": selectedtype,
        "add_Filter": true
      }
      // filter: { "limit": 100, "page": 1, "criteria": [{ "Criteria": "propertyOwnerName", "Type": "fuzzy", "Value": "ex", "index": 0 }, { "Criteria": "holderName", "Type": "fuzzy", "Value": "ex", "index": 1 }], "sortfield": "sequence", "direction": "desc" }
      // site: Opra2

    }
  }

  async handleDataSource(e) {

    await this.setState({
      DataSource: e.value ? e.value : e
    })

  }
  getLabel = (label, field) => {
    return <label className='d-flex justify-content-between mb-0'>
      <span>{label}</span>
      {/* {this.getCloseIcon(field)} */}
    </label>
  }

  onFilterChanges = async (key, value) => {
    if (key === "statePropertyId" || key === "propertyId") {
      // value = value.replace(/[a-zA-Z]/g, '')
      value = value.replace(/[^0-9\s]/g, '')
      await this.setState({ [key]: value })
    }
  }
  //Remove special characters,  'and' (case-insensitive) and trim whitespace;
  formatString = async (value) => {
    value = value.replace(/[^a-zA-Z0-9\s]/g, '');
    return value.replace(/\band\b/gi, '').trim();
  }

  setResultSet = async (count) => {
    await this.setState({ resultSet: count })
  }
  setSQLCount = async (count) => {
    await this.setState({ SQLCount: count })
  }
  setMongoCount = async (count) => {
    await this.setState({ MongoCount: count })
  }
  setPropertySearchType = async (type) => {
    await this.setState({ setPropertySearchType: type })
  }
  getDataFromServer = async () => {
    let sort = "", fields = ["propertyOwnerName", "propertyAddressLine1", "holderName", "sourceState"]
    for (let field of fields) {
      if (this.state[field]) {
        sort = sort + field + ", "
      }
    }
    await this.setState({ showMessage: true })
    let filterCriteria = { "limit": 1000, "page": 1, criteria: [], "sortfield": sort, "direction": "desc" }
    if (this.state.propertyOwnerName&& this.state.propertyOwnerName !="") {
      filterCriteria["criteria"].push({ "Criteria": "propertyOwnerName", "Type": this.state.setSearchType === "Exact" ? "nestedWord" : "nestedFuzzy", "Value": await this.formatString(this.state.propertyOwnerName), "index": 0 })
    }
    if (this.state.propertyDescription&& this.state.propertyDescription !="") {
      filterCriteria["criteria"].push({ "Criteria": "propertyDescription", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": await this.formatString(this.state.propertyDescription), "index": 0 })
    }
    if (this.state.holderCounty && this.state.holderCounty !="") {
      filterCriteria["criteria"].push({ "Criteria": "holderCounty", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": await this.formatString(this.state.holderCounty), "index": 0 })
    }
    if (this.state.holderExtraInfo && this.state.holderExtraInfo !="") {
      filterCriteria["criteria"].push({ "Criteria": "holderExtraInfo", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": await this.formatString(this.state.holderExtraInfo), "index": 0 })
    }

    if (this.state.holderName && this.state.holderName != "") {
      filterCriteria["criteria"].push({ "Criteria": "holderName", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": await this.formatString(this.state.holderName), "index": 1 })
    }
    if (this.state.sourceState && this.state.sourceState.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "sourceState", "Type": "termIncludes", "Value": this.state.sourceState, "index": 2 })
    }
    if (this.state.dataSourceChannel && this.state.dataSourceChannel.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "dataSourceChannel", "Type": "nestedInclude", "Value": this.state.dataSourceChannel, "index": 2 })
    }
    if (this.state.dataSource && this.state.dataSource.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "dataSource", "Type": "nestedInclude", "Value": this.state.dataSource, "index": 2 })
    }
    if (this.state.sourceDetail && this.state.sourceDetail.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "sourceDetail", "Type": "nestedInclude", "Value": this.state.sourceDetail, "index": 2 })
    }
    if (this.state.createdByUser && this.state.createdByUser.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "createdByUser", "Type": "termIncludes", "Value": this.state.createdByUser, "index": 2 })
    }
    if (this.state.updatedByUser && this.state.updatedByUser.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "updatedByUser", "Type": "termIncludes", "Value": this.state.updatedByUser, "index": 2 })
    }
    if (this.state.confOnsiteByUser && this.state.confOnsiteByUser.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "confOnsiteByUser", "Type": "termIncludes", "Value": this.state.confOnsiteByUser, "index": 2 })
    }
    if (this.state.propertyStateAbbreviation && this.state.propertyStateAbbreviation.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "propertyStateAbbreviation", "Type": "nestedInclude", "Value": this.state.propertyStateAbbreviation, "index": 2 })
    }
    if (this.state.holderState && this.state.holderState.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "holderState", "Type": "termIncludes", "Value": this.state.holderState, "index": 2 })
    }
    if (this.state.propertyAddressLine1 && this.state.propertyAddressLine1 != "") {
      // filterCriteria["criteria"].push({ "Criteria": "propertyAddressLine2", "Type": this.state.setSearchType === "Exact" ? "nestedWord" : "nestedFuzzy", "Value": encodeURIComponent(this.state.propertyAddressLine1), "index": 3 })
      filterCriteria["criteria"].push({ "Criteria": "propertyAddressLine1",Condition:"or", Fields:["propertyAddressLine2","propertyAddressLine1"], "Type": this.state.setSearchType === "Exact" ? "nestedWord" : "nestedFuzzy", "Value": await this.formatString(this.state.propertyAddressLine1), "index": 3 })
    }
    if (this.state.holderAddressLine1 && this.state.holderAddressLine1 != "") {
      filterCriteria["criteria"].push({ "Criteria": "holderAddressLine1", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": await this.formatString(this.state.holderAddressLine1), "index": 3 })
    }
    if (this.state.propertyCity && this.state.propertyCity != "") {
      filterCriteria["criteria"].push({ "Criteria": "propertyCity", "Type": this.state.setSearchType === "Exact" ? "nestedWord" : "nestedFuzzy", "Value": this.state.propertyCity, "index": 4 })
    }
    if (this.state.holderCity&& this.state.holderCity != "") {
      filterCriteria["criteria"].push({ "Criteria": "holderCity", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.holderCity, "index": 4 })
    }
    if (this.state.propertyZip&& this.state.propertyZip != "") {
      filterCriteria["criteria"].push({ "Criteria": "propertyZip", "Type": this.state.setSearchType === "Exact" ? "nestedWord" : "nestedFuzzy", "Value": this.state.propertyZip, "index": 4 })
    }
    if (this.state.MinPropValue) {
      filterCriteria["criteria"].push({ "Criteria": "propertyValueMin", "Type": "gte", "Value": this.state.MinPropValue, "index": 5 })
    }
    if (this.state.MaxPropValue) {
      filterCriteria["criteria"].push({ "Criteria": "propertyValueMax", "Type": "gte", "Value": this.state.MaxPropValue, "index": 5 })
    }
    // if (this.state.DataSource.length > 0) {
    //   filterCriteria["criteria"].push({ "Criteria": "propertyDataSource", "Type": "Includes", "Value": this.state.DataSource, "index": 5 })
    // }
    if (this.state.propertyId != null && this.state.propertyId != "" && this.state.propertyId.length) {
      filterCriteria["criteria"].push({ "Criteria": "propertyId", "Type": "NumIncludes", "Value": this.state.propertyId.split((/\r?\n/)).map(obj => parseInt(obj.trim())).filter(u => u != "" && u > 0), "index": 5 })
    }
    if (this.state.statePropertyId != null && this.state.statePropertyId != "" && this.state.statePropertyId.length) {
      filterCriteria["criteria"].push({ "Criteria": "statePropertyId", "Type": "NumIncludes", "Value": this.state.statePropertyId.split((/\r?\n/)).map(obj => parseInt(obj.trim())).filter(u => u != "" && u > 0), "index": 5 })
    }
    var escheatmentDateCriteria = { "Criteria": "escheatmentDate", "Type": "MinMaxDate", "index": 5 };
    var dateOfLastContactCriteria = { "Criteria": "dateOfLastContact", "Type": "MinMaxDate", "index": 5 };
    var opra2CreatedDateCriteria = { "Criteria": "opra2CreatedDate", "Type": "MinMaxDate", "index": 5 };
    var lastUpdatedDateCriteria = { "Criteria": "lastUpdatedDate", "Type": "MinMaxDate", "index": 5 };
    var confOnSiteDateCriteria = { "Criteria": "confOnSiteDate", "Type": "MinMaxDate", "index": 5 };
    var dataFlag = false;

    // "Value": this.state.DataSource,
    // "minDateValue": 
    // "maxDateValue":
    if (this.state.escheatmentDateFrom) {
      escheatmentDateCriteria.Value = escheatmentDateCriteria.minDateValue = this.convertDate(this.state.escheatmentDateFrom);
      dataFlag = true;
    }

    if (this.state.escheatmentDateTo) {
      escheatmentDateCriteria.Value = escheatmentDateCriteria.maxDateValue = this.convertDate(this.state.escheatmentDateTo);
      dataFlag = true;
    }
    if (dataFlag) {
      filterCriteria["criteria"].push({ ...escheatmentDateCriteria });
      dataFlag = false;
    }

    if (this.state.dateOfLastContactFrom) {
      dateOfLastContactCriteria.Value = dateOfLastContactCriteria.minDateValue = this.convertDate(this.state.dateOfLastContactFrom);
      dataFlag = true;
    }

    if (this.state.dateOfLastContactTo) {
      dateOfLastContactCriteria.Value = dateOfLastContactCriteria.maxDateValue = this.convertDate(this.state.dateOfLastContactTo);
      dataFlag = true;
    }
    if (dataFlag) {
      filterCriteria["criteria"].push({ ...dateOfLastContactCriteria });
      dataFlag = false;
    }
    if (this.state.opra2CreatedDateFrom) {
      opra2CreatedDateCriteria.Value = opra2CreatedDateCriteria.minDateValue = this.convertDate(this.state.opra2CreatedDateFrom);
      dataFlag = true;
    }

    if (this.state.opra2CreatedDateTo) {
      opra2CreatedDateCriteria.Value = opra2CreatedDateCriteria.maxDateValue = this.convertDate(this.state.opra2CreatedDateTo);
      dataFlag = true;
    }
    if (dataFlag) {
      filterCriteria["criteria"].push({ ...opra2CreatedDateCriteria });
      dataFlag = false;
    }

    if (this.state.lastUpdatedDateFrom) {
      lastUpdatedDateCriteria.Value = lastUpdatedDateCriteria.minDateValue = this.convertDate(this.state.lastUpdatedDateFrom);
      dataFlag = true;
    }

    if (this.state.lastUpdatedDateTo) {
      lastUpdatedDateCriteria.Value = lastUpdatedDateCriteria.maxDateValue = this.convertDate(this.state.lastUpdatedDateTo);
      dataFlag = true;
    }
    if (dataFlag) {
      filterCriteria["criteria"].push({ ...lastUpdatedDateCriteria });
      dataFlag = false;
    }
    if (this.state.confOnSiteDateFrom) {
      confOnSiteDateCriteria.Value = confOnSiteDateCriteria.minDateValue = this.convertDate(this.state.confOnSiteDateFrom);
      dataFlag = true;
    }

    if (this.state.confOnSiteDateTo) {
      confOnSiteDateCriteria.Value = confOnSiteDateCriteria.maxDateValue = this.convertDate(this.state.confOnSiteDateTo);
      dataFlag = true;
    }
    if (dataFlag) {
      filterCriteria["criteria"].push({ ...confOnSiteDateCriteria });
      dataFlag = false;
    }

    if (this.state.searchableProperties) {
      filterCriteria["criteria"].push({ "Criteria": "propertyStatus", "Type": "word", "Value": "Searchable", "index": 0 })
    }
    //commenting below code as per new changes in OP2-2672. we might use the below code in future
    //  else {
    //   filterCriteria["criteria"].push({ "Criteria": "propertyStatus", "Type": "ne", "Value": "Searchable", "index": 0 })
    // }

    let ExtraFilters = {}

    //condition for confirm onsite flag confOnSite
    if (this.state.confOnSite != null ){
       ExtraFilters.confOnSite = this.state.confOnSite;
       filterCriteria["criteria"].push({ "Criteria": "confOnSite", "Type": "eq", "Value": this.state.confOnSite, "index": 0 })
    }

    if (this.state.mappedRangeFrom) ExtraFilters.mappedRangeFrom = this.convertDate(this.state.mappedRangeFrom);

    if (this.state.mappedRangeTo) ExtraFilters.mappedRangeTo = this.convertDate(this.state.mappedRangeTo);
   {/* Commenting the below code as OP2-2815 changes need these changes in Part-2 */}
    // if (this.state.confOnSiteDateFrom) ExtraFilters.confOnSiteDateFrom = this.convertDate(this.state.confOnSiteDateFrom);

    // if (this.state.confOnSiteDateTo) ExtraFilters.confOnSiteDateTo = this.convertDate(this.state.confOnSiteDateTo);

    if (this.state.Deal) ExtraFilters.Deal = this.state.Deal.EstateID;

    if (this.state.positivelyReviewedProperties) ExtraFilters.positivelyReviewedProperties = this.state.positivelyReviewedProperties;

    this.props.setDataCriteriaforOpportunity(filterCriteria, ExtraFilters);

  }
  convertDate = (date) => {
    const utcYear = date.getUTCFullYear();
    const utcMonth = date.getUTCMonth();
    const utcDay = date.getUTCDate();

    // Set the time to midnight in UTC
    const utcDate = new Date(Date.UTC(utcYear, utcMonth, utcDay + 1, 0, 0, 0));
    return utcDate;
  }
  clearField = async (field, type) =>{

    if(type == "string"){
      await this.setState({
        [field]: ""      
      })
    }else if(type == "array"){
      await this.setState({
        [field]: []      
      })
    }else if(type == "bool"){
      await this.setState({
        [field]: false      
      })
    }else if(type == "num"){
      await this.setState({
        [field]: 0      
      })
    }else if(type == "date"){
      await this.setState({
        [field+"From"]: "" , 
        [field+"To"]:""      
      })
    }

  }

  clearData = async () => {
    await this.setState({
      holderName: "",
      propertyAddressLine1: "",
      holderAddressLine1: "",
      propertyOwnerName: "",
      propertyDescription: "",
      holderCounty: "",
      holderExtraInfo: "",
      resultSet: 0,
      statePropertyId: "",
      propertyId: "",
      SQLCount: 0,
      MongoCount: 0,
      showMessage: false,
      sourceState: [],
      dataSourceChannel: [],
      createdByUser: [],
      updatedByUser: [],
      sourceDetail: [],
      confOnsiteByUser: [],
      dataSource: [],
      propertyStateAbbreviation: [],
      holderState: [],
      setSearchType: "Relative",
      setPropertySearchType: "Raw Properties",
      propertyCity: "",
      holderCity: "",
      propertyZip: "",
      mappedRangeFrom: "",
      escheatmentDateFrom: "",
      dateOfLastContactFrom: "",
      dateOfLastContactTo: "",
      opra2CreatedDateFrom: "",
      opra2CreatedDateTo: "",
      escheatmentDateTo: "",
      mappedRangeTo: "",
      confOnSiteDateFrom: new Date(new Date().setMonth(new Date().getMonth() - 6)),
      confOnSiteDateTo: "",
      lastUpdatedDateTo: "",
      lastUpdatedDateFrom: "",
      MinPropValue: 0,
      MaxPropValue: 0,
      DataSource: ["State UP Site", "State File", "Missing Money Site"],
      Deal: "",
      positivelyReviewedProperties: false,
      searchableProperties: true,
      confOnSite: true,
      relativeSearch: true
    })
    let filterCriteria = { "limit": 200, "page": 1, criteria: [], "sortfield": "sequence", "direction": "desc" }
    this.props.closeDataTable()
    // this.props.setDataCriteriaforOpportunity(filterCriteria, {})
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    await this.setState({ loginCredentials: loginCredentials });
    this.getStates();
    this.getUsers();
    this.getDataSourceDetails();
    this.getDataSource();
    this.getDataSourceChannelList();
  }


  onChangeRelativeSearch = async (e) => {
    await this.setState(
      {
        relativeSearch: e.value,
        setSearchType: e.value ? "Relative" : "Exact"
      });

  }

  onChangeSearchableProperties = async (e) => {
    await this.setState(prevState => (
      {
        searchableProperties: !prevState.searchableProperties,
      }));
  }
  onChangeConfOnSiteProperties = async (e) => {
    await this.setState(prevState => (
      {
        confOnSite: !prevState.confOnSite,
      }));
  }

  onDateChange = async (e, key, type) => {
    if (type) {
      await this.setState({
        [key]: "",
      })
    } else {
      await this.setState({
        [key]: e.value,
      })
    }
  }

  searchsellerItems = async (event, criteria) => {
    let apiUrl

    if (event) {

      if (event.query) {
        apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query)}`
      } else {
        apiUrl = `${apiCalls.propertysellers}`
      }

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }
          await this.setState({
            filteredSuggestions: dropdownData
          });

        }
      }).catch((err) => {
        return err;
      });
  }

  handleChange = async (e) => {
    this.setState({
      Deal: e.value
    })
  }

  render() {
    return (
      <Card className="p-0" >
        {/* <CardHeader >
          Missing Money Results File Upload
        </CardHeader> */}
        <Loader loader={this.state.isLoading} />
        <CardBody className="p-0">
          <div className='row'>

            <Accordion multiple={true} activeIndex={[0]}>
              <AccordionTab header={"Filters"}>
                {/* New GRID */}


                <div className='pv_v2_header col-lg-12 mb-2' style={{ display: "flex", alignItems: "center", fontSize: "medium" }}>
                  <div className='col-lg-6' style={{ display: "flex", whiteSpace: "pre", borderStyle: "solid", backgroundColor: '#dcdfe3', padding: "0.25rem" }} >
                    <p style={
                      { color:"#354f6e", padding: "0.2rem" }
                    }><b>{"Raw Property Search"}</b></p>
                    {/* Commenting the below code as OP2-2815 changes need these changes in Part-2 */}
                   {/* <div className="ps_v2_radio_button field-radiobutton mr-2">
                      <RadioButton inputId="Raw Properties" style={{ color: "green" }} className="ml-2 mr-2" name="rawProperties" value="Raw Properties" onChange={() => this.setPropertySearchType("Raw Properties")}
                        checked={this.state.setPropertySearchType === 'Raw Properties'}
                      />
                      <label htmlFor="city1" className='mb-0'>Raw Properties </label>
                    </div> */}
                    {/* Commenting the below code as OP2-2815 changes need these changes in Part-2 */}
                    {/* <div className="ps_v2_radio_button field-radiobutton mr-2">
                      <RadioButton inputId="Scored Properties" className="ml-2 mr-2" disabled={true} name="scoredProperties" value="Scored Properties" onChange={() => this.setPropertySearchType("Scored Properties")}
                        checked={this.state.setPropertySearchType === 'Scored Properties'}
                      />
                      <label htmlFor="city2" className='mb-0'>Scored Properties </label>
                    </div> */}
                  </div>
                  <div className='col-lg-2' style={{ whiteSpace: "pre", borderStyle: "solid", backgroundColor: 'lightgray',  padding: "0.1rem" }} >
                    <div className='alignCenter row col-lg-12' >
                      <div className='col-lg-4' style={{ textAlign: "right" ,paddingTop: "0.3rem"}}>
                        <InputSwitch checked={this.state.relativeSearch}
                          style={{ marginBottom: 0 }} onChange={(e) => this.onChangeRelativeSearch(e)} />
                      </div>
                      <div className='col-lg-8' style={{ textAlign: "left" }}>
                        <label style={{ marginBottom: 2 }}>Relative Search</label>
                      </div>

                    </div>
                  </div>

                  <div className='col-lg-2' style={{ whiteSpace: "pre", borderStyle: "solid", backgroundColor: 'lightgray', padding: "0.1rem" }} >
                    <div className='alignCenter row col-lg-12' >
                      <div className='col-lg-4' style={{ textAlign: "right",paddingTop: "0.3rem"}}>
                        <InputSwitch checked={this.state.searchableProperties}
                          style={{ marginBottom: 0 }} onChange={(e) => this.onChangeSearchableProperties()} />
                      </div>
                      <div className='col-lg-8' style={{ textAlign: "left" }}>
                        <label style={{ marginBottom: 2 }}>Only Searchable Prop.</label>
                      </div>

                    </div>
                  </div>

                  <div className='col-lg-2' style={{ whiteSpace: "pre", borderStyle: "solid", backgroundColor: 'lightgray', padding: "0.1rem" }} >
                    <div className='alignCenter row col-lg-12' >
                      <div className='col-lg-4' style={{ textAlign: "right",paddingTop: "0.3rem" }}>
                        <InputSwitch checked={this.state.confOnSite}
                          style={{ marginBottom: 0 }} onChange={(e) => this.onChangeConfOnSiteProperties(e)} />
                      </div>
                      <div className='col-lg-8' style={{ textAlign: "left" }}>
                        <label style={{ marginBottom: 2 }}> Conf. Onsite Prop.</label>
                      </div>

                    </div>
                  </div>



                </div>


                <div className='alignCenter pv_v2_header  col-lg-12' >
                  {/*Section 1*/}
                  <div className='col-lg-4' style={{ alignItems: "center" }}>


                    {/* <div className='col-lg-12' style={{ color: "#f08e5d" }}>
                      Either Reported Owner Name or Property Address Line 1 must be provided
                    </div> */}

                    {/* Commenting the below code as OP2-2815 changes need these changes in Part-2 */}
                    {/* <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4 mt-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Deal Name</label>
                  </div>
                  <div className='col-lg-8' style={{ color: "#f08e5d" }}>
                    Either Reported Owner Name or Property Address Line 1 must be provided
                    <AutoComplete
                      value={this.state.Deal}
                      id="Deal"
                      suggestions={this.state.filteredSuggestions}
                      completeMethod={this.searchsellerItems}
                      minLength={1}
                      field={"DealName"}
                      style={{ height: 33, width: "100%" }}
                      dropdown={true}
                      // itemTemplate={this.editorTemplateForDeal}
                      onChange={(e) => this.onChange(e, 'Deal', 'regexOr')}
                    // onChange={(e) => this.handleChange(e, 'Seller')}
                    // appendTo={document.body}
                    />

                  </div>
                </div> */}
                    <div className='alignCenter row col-lg-12 mb-3' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Data Source Channel</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <MultiSelect
                          name={"dataSourceChannel"}
                          id="dataSourceChannel"
                          filter={true}
                          value={this.state.dataSourceChannel}
                          style={{ height: 33, width: "100%", paddingRight: "0.1rem" }}
                          appendTo={document.body}                           
                          maxSelectedLabels={1}
                          options={this.state.dataSourceChannelList}
                          onChange={(e) => this.onChange(e, 'dataSourceChannel', 'regexOr')}
                        />
                       {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'                                
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("dataSourceChannel", "array")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Data Source</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <MultiSelect
                          name={"dataSource"}
                          id="dataSource"
                          filter={true}
                          value={this.state.dataSource}
                          style={{ height: 33, width: "100%" }}
                          appendTo={document.body}                           
                          maxSelectedLabels={1}
                          options={this.state.Sources}
                          onChange={(e) => this.onChange(e, 'dataSource', 'regexOr')}
                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("dataSource", "array")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Source Detail</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <MultiSelect
                          name={"sourceDetail"}
                          id="sourceDetail"
                          filter={true}
                          value={this.state.sourceDetail}
                          style={{ height: 33, width: "100%" }}
                          appendTo={document.body}                           
                          maxSelectedLabels={1}
                          options={this.state.sourceDetailList}
                          onChange={(e) => this.onChange(e, 'sourceDetail', 'regexOr')}
                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("sourceDetail", "array")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Source State{this.state.statePropertyId != null && this.state.statePropertyId != "" && <FontAwesomeIcon icon={faStarOfLife}
                          className='pl-1'
                          color='red'
                          data-toggle="tool-tip"
                          title="Mandotary Field"
                          style={{ width: 11 }}
                        />}</label>
                      </div>
                      <div className={this.state.statePropertyId != null && this.state.statePropertyId != "" && this.state.sourceState.length == 0 ?'custom_border col-lg-8 pr-0 d-flex':'col-lg-8 pr-0 d-flex'}>
                        <MultiSelect
                          name={"sourceState"}
                          id="sourceState"
                          filter={true}
                          value={this.state.sourceState}
                          style={{ height: 33, width: "100%" }}
                          appendTo={document.body}                           
                          maxSelectedLabels={1}
                          options={this.state.States}
                          onChange={(e) => this.onChange(e, 'sourceState', 'regexOr')}
                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("sourceState", "array")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-1' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Reported Owner Name</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex' >

                        <InputText
                          value={this.state.propertyOwnerName}
                          name='propertyOwnerName'
                          id="propertyOwnerName"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'propertyOwnerName', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'propertyOwnerName', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("propertyOwnerName", "string")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2'>
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0, }}>Property Address Line1 or 2</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <InputText
                          value={this.state.propertyAddressLine1}
                          name='propertyAddressLine1'
                          id="propertyAddressLine1"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'propertyAddressLine1', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'propertyAddressLine1', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("propertyAddressLine1", "string")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Property City</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <InputText
                          value={this.state.propertyCity}
                          name='propertyCity'
                          id="propertyCity"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'propertyCity', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'propertyCity', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("propertyCity", "string")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Property State</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <MultiSelect
                          name={"propertyStateAbbreviation"}
                          id="propertyStateAbbreviation"
                          filter={true}
                          value={this.state.propertyStateAbbreviation}
                          style={{ height: 33, width: "100%" }}
                          appendTo={document.body}
                           
                          maxSelectedLabels={1}
                          options={this.state.States}
                          onChange={(e) => this.onChange(e, 'propertyStateAbbreviation', 'regexOr')}

                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("propertyStateAbbreviation", "array")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2'>
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Property Zip Code</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex' >

                        <InputText
                          value={this.state.propertyZip}
                          name='propertyZip'
                          id="propertyZip"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'propertyZip', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'propertyZip', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("propertyZip", "string")} />}
                      </div>
                    
                    </div>

                    <div className='alignCenter row col-lg-12 mb-3'>
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Property Description</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex' >

                        <InputText
                          value={this.state.propertyDescription}
                          name='propertyDescription'
                          id="propertyDescription"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'propertyDescription', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'propertyDescription', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("propertyDescription", "string")} />}
                      </div>
                    </div>

                  </div>

                  {/*Section 2*/}
                  <div className='col-lg-4' style={{ alignItems: "center" }} >

                    <div className='row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>State Property ID(s)</label>
                      </div>
                      {/* {this.getLabel('State Property ID(s)', 'statePropertyId')} */}
                      <div className='col-lg-8 pr-0 d-flex'>
                        <textarea
                          name='stClaimId'
                          id="stClaimId"
                          onChange={(e) =>
                            this.onFilterChanges('statePropertyId', e.target.value)
                          }
                          style={{ width: "100%", height: "90px" }}
                          value={this.state.statePropertyId}
                          placeholder={'State Property ID(s)'}
                          rows="8"
                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "4.7em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("statePropertyId", "string")} />}
                      </div>
                    </div>

                    <div className='row col-lg-12 mb-2' >
                      {/* {this.getLabel('OPRA Property ID(s)', 'propertyId')} */}
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>OPRA Property ID(s)</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <textarea
                          name='OpraStId'
                          id='OpraStId'
                          value={this.state.propertyId}
                          style={{ width: "100%", height: "90px" }}
                          onChange={(e) => this.onFilterChanges('propertyId', e.target.value)}
                          placeholder={'OPRA Property ID(s)'}
                          rows="8"
                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "4.7em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("propertyId", "string")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Holder Name</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <InputText
                          value={this.state.holderName}
                          name='holderName'
                          id="holderName"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'holderName', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'holderName', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("holderName", "string")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Holder Address Line1</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <InputText
                          value={this.state.holderAddressLine1}
                          name='holderAddressLine1'
                          id="holderAddressLine1"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'holderAddressLine1', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'holderAddressLine1', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("holderAddressLine1", "string")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Holder City</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <InputText
                          value={this.state.holderCity}
                          name='holderCity'
                          id="holderCity"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'holderCity', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'holderCity', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("holderCity", "string")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Holder State</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <MultiSelect
                          name={"holderState"}
                          id="holderState"
                          filter={true}
                          value={this.state.holderState}
                          style={{ height: 33, width: "100%" }}
                          appendTo={document.body}                           
                          maxSelectedLabels={1}
                          options={this.state.States}
                          onChange={(e) => this.onChange(e, 'holderState', 'regexOr')}
                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("holderState", "array")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Holder County</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <InputText
                          value={this.state.holderCounty}
                          name='holderCounty'
                          id="holderCounty"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'holderCounty', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'holderCounty', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("holderCounty", "string")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Holder Extra Info.</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <InputText
                          value={this.state.holderExtraInfo}
                          name='holderExtraInfo'
                          id="holderExtraInfo"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'holderExtraInfo', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'holderExtraInfo', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("holderExtraInfo", "string")} />}
                      </div>
                    </div>

                  </div>

                  {/* Section-3 */}
                  <div className='col-lg-4' style={{ alignItems: "center" }}>

                    {/* Commenting the below code as OP2-2815 changes need these changes in Part-2 */}
                    {/* Show Only Positively Mapped Properties */}
                    {/* <div className='row col-lg-12' style={{ display: "flex", alignItems: "center" }}>
                      <div className='col-lg-9' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Show Only Positively Mapped Properties</label>
                      </div>
                      <div className='col-lg-3' style={{ textAlign: "right" }}>
                        <InputSwitch checked={this.state.positivelyReviewedProperties}
                          className='ml-2' onChange={(e) => this.onChangePositivelyReviewedProperties()} />
                      </div>
                    </div> */}
                    {/* Show Only Searchable Properties */}
                    {/* <div className='row col-lg-12 mt-2' style={{ display: "flex", alignItems: "center" }}>
                      <div className='col-lg-9' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Show Only Searchable Properties</label>
                      </div>
                      <div className='col-lg-3' style={{ textAlign: "right" }}>
                        <InputSwitch checked={this.state.searchableProperties}
                          className='ml-2' onChange={(e) => this.onChangeSearchableProperties()} />
                      </div>
                    </div>

                    <div className='row col-lg-12 mt-2' style={{ display: "flex", alignItems: "center" }}>
                      <div className='col-lg-9' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Show Only Conf. On Site Properties</label>
                      </div>
                      <div className='col-lg-3' style={{ textAlign: "right" }}>
                        <InputSwitch checked={this.state.confOnSite}
                          className='ml-2' onChange={(e) => this.onChangeConfOnSiteProperties()} />
                      </div>

                    </div> */}

                    {/* <div className='row col-lg-12 mb-1' style={{ display: "flex", alignItems: "center" }}>
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Data Source</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <MultiSelect
                          value={this.state.DataSource}
                          name={"DataSource"}
                          filter={true}
                          style={{ width: "100%", height: 32 }}
                          appendTo={document.body}
                          maxSelectedLabels={1}
                          options={this.state.dataArray ? this.state.dataArray : []}
                          onChange={(e) => this.handleDataSource(e)}
                        />
                      </div>
                    </div> */}

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>{"Min Prop. Value >="}</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <InputText
                          value={this.state.MinPropValue}
                          name='MinPropValue'
                          id="MinPropValue"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'MinPropValue', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'MinPropValue', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("MinPropValue", "num")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4 pl-1' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>{"Max Prop. Value >="}</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <InputText
                          value={this.state.MaxPropValue}
                          name='MaxPropValue'
                          id="MaxPropValue"
                          style={{ height: 33, width: "100%" }}
                          onKeyPress={(e) => this.onChange(e, 'MaxPropValue', 'regexOr')}
                          onChange={(e) => this.onChange(e, 'MaxPropValue', 'regexOr')}
                          size={"20"} />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("MaxPropValue", "num")} />}
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-2'>
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Created By User</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <MultiSelect
                          name={"createdByUser"}
                          id="createdByUser"
                          filter={true}
                          value={this.state.createdByUser}
                          style={{ height: 33, width: "100%" }}
                          appendTo={document.body}                           
                          maxSelectedLabels={1}
                          options={this.state.userArray}
                          onChange={(e) => this.onChange(e, 'createdByUser', 'regexOr')}
                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("createdByUser", "array")} />}
                      </div>
                    </div>
                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Updated By User</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <MultiSelect
                          name={"updatedByUser"}
                          id="updatedByUser"
                          filter={true}
                          value={this.state.updatedByUser}
                          style={{ height: 33, width: "100%" }}
                          appendTo={document.body}                           
                          maxSelectedLabels={1}
                          options={this.state.userArray}
                          onChange={(e) => this.onChange(e, 'updatedByUser', 'regexOr')}
                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("updatedByUser", "array")} />}
                      </div>
                    </div>
                    <div className='alignCenter row col-lg-12 mb-2' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Conf. Onsite By User</label>
                      </div>
                      <div className='col-lg-8 pr-0 d-flex'>
                        <MultiSelect
                          name={"confOnsiteByUser"}
                          id="confOnsiteByUser"
                          filter={true}
                          value={this.state.confOnsiteByUser}
                          style={{ height: 33, width: "100%" }}
                          appendTo={document.body}                           
                          maxSelectedLabels={1}
                          options={this.state.userArray}
                          onChange={(e) => this.onChange(e, 'confOnsiteByUser', 'regexOr')}
                        />
                          {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("confOnsiteByUser", "array")} />}
                      </div>
                    </div>
                    <div className='alignCenter row col-lg-12 mb-3'>
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>OPRA2 Created Date</label>
                      </div>
                      <div className='alignCenter col-lg-8 pr-0' >
                        <div className='col-lg-6 pl-0'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            value={this.state.opra2CreatedDateFrom}
                            onChange={(e) => this.onDateChange(e, "opra2CreatedDateFrom", true)}
                            onSelect={(e) => this.onDateChange(e, "opra2CreatedDateFrom")}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            name={"opra2CreatedDateFrom"}
                            placeholder='From - mm/dd/yyyy'
                          />
                        </div>
                        <div className='col-lg-6 text-right pr-0 d-flex'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            minDate={this.state.opra2CreatedDateFrom ? this.state.opra2CreatedDateFrom : ""}
                            name={"opra2CreatedDateTo"}
                            placeholder='To - mm/dd/yyyy'
                            dateFormat="mm/dd/yy"
                            value={this.state.opra2CreatedDateTo}
                            onChange={(e) => this.onDateChange(e, "opra2CreatedDateTo", true)}
                            onSelect={(e) => this.onDateChange(e, "opra2CreatedDateTo")}
                          />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("opra2CreatedDate", "date")} />}
                        </div>
                 
                      </div>

                    </div>

                    <div className='alignCenter row col-lg-12 mb-3' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Last Updated Date</label>
                      </div>
                      <div className='alignCenter col-lg-8 pr-0'>
                        <div className='col-lg-6 pl-0'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            value={this.state.lastUpdatedDateFrom}
                            onChange={(e) => this.onDateChange(e, "lastUpdatedDateFrom", true)}
                            onSelect={(e) => this.onDateChange(e, "lastUpdatedDateFrom")}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            name={"lastUpdatedDateFrom"}
                            placeholder='From - mm/dd/yyyy'
                          />

                        </div>

                        <div className='col-lg-6 text-right pr-0 d-flex'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            minDate={this.state.lastUpdatedDateFrom ? this.state.lastUpdatedDateFrom : ""}
                            name={"lastUpdatedDateTo"}
                            placeholder='To - mm/dd/yyyy'
                            dateFormat="mm/dd/yy"
                            value={this.state.lastUpdatedDateTo}
                            onChange={(e) => this.onDateChange(e, "lastUpdatedDateTo", true)}
                            onSelect={(e) => this.onDateChange(e, "lastUpdatedDateTo")}
                          />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("lastUpdatedDate", "date")} />}
                        </div>                      
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-3' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Conf. Onsite Date</label>
                      </div>
                      <div className='alignCenter col-lg-8 pr-0' >
                        <div className='col-lg-6 pl-0'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            value={this.state.confOnSiteDateFrom}
                            onChange={(e) => this.onDateChange(e, "confOnSiteDateFrom", true)}
                            onSelect={(e) => this.onDateChange(e, "confOnSiteDateFrom")}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            name={"confOnSiteDateFrom"}
                            placeholder='From - mm/dd/yyyy'
                          />

                        </div>

                        <div className='col-lg-6 text-right pr-0 d-flex'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            minDate={this.state.confOnSiteDateFrom ? this.state.confOnSiteDateFrom : ""}
                            name={"confOnSiteDateTo"}
                            placeholder='To - mm/dd/yyyy'
                            dateFormat="mm/dd/yy"
                            value={this.state.confOnSiteDateTo}
                            onChange={(e) => this.onDateChange(e, "confOnSiteDateTo", true)}
                            onSelect={(e) => this.onDateChange(e, "confOnSiteDateTo")}
                          />
                           {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("confOnSiteDate", "date")} />}
                        </div>
                       
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-3' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Escheatment Date</label>
                      </div>
                      <div className='alignCenter col-lg-8 pr-0'>
                        <div className='col-lg-6 pl-0'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            value={this.state.escheatmentDateFrom}
                            onChange={(e) => this.onDateChange(e, "escheatmentDateFrom", true)}
                            onSelect={(e) => this.onDateChange(e, "escheatmentDateFrom")}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            name={"escheatmentDateFrom"}
                            placeholder='From - mm/dd/yyyy'
                          />
                        </div>

                        <div className='col-lg-6 pr-0 d-flex text-right'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            minDate={this.state.escheatmentDateFrom ? this.state.escheatmentDateFrom : ""}
                            name={"escheatmentDateTo"}
                            placeholder='To - mm/dd/yyyy'
                            dateFormat="mm/dd/yy"
                            value={this.state.escheatmentDateTo}
                            onChange={(e) => this.onDateChange(e, "escheatmentDateTo", true)}
                            onSelect={(e) => this.onDateChange(e, "escheatmentDateTo")}
                          />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("escheatmentDate", "date")} />}
                        </div>
                      
                      </div>
                    </div>

                    <div className='alignCenter row col-lg-12 mb-3' >
                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Date of Last Contact</label>
                      </div>
                      <div className='alignCenter col-lg-8 pr-0' >
                        <div className='col-lg-6 pl-0'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            //  minDate={this.props.type.includes("Scheduler") ? new Date() : ""}
                            name={"dateOfLastContactFrom"}
                            placeholder='From - mm/dd/yyyy'
                            dateFormat="mm/dd/yy"
                            value={this.state.dateOfLastContactFrom}
                            onChange={(e) => this.onDateChange(e, 'dateOfLastContactFrom', true)}
                            onSelect={(e) => this.onDateChange(e, 'dateOfLastContactFrom')}
                          />
                        </div>

                        <div className='col-lg-6 pr-0 d-flex text-right'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            minDate={this.state.dateOfLastContactFrom ? this.state.dateOfLastContactFrom : ""}
                            name={"dateOfLastContactTo"}
                            placeholder='To - mm/dd/yyyy'
                            dateFormat="mm/dd/yy"
                            value={this.state.dateOfLastContactTo}
                            onChange={(e) => this.onDateChange(e, 'dateOfLastContactTo', true)}
                            onSelect={(e) => this.onDateChange(e, 'dateOfLastContactTo')}
                          />
                            {<FontAwesomeIcon
                                color='#d44e4e' 
                                className=""
                                size='lg'
                                style={{ cursor: "pointer", height: "1.6em", paddingLeft:"3px" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.clearField("dateOfLastContact", "date")} />}
                        </div>
                      
                      </div>
                    </div>

                    {/* Commenting the below code as OP2-2815 changes need these changes in Part-2 */}
                    {/* <div className='row col-lg-12 mb-3' style={{ display: "flex", alignItems: "center" }}>

                      <div className='col-lg-4' style={{ textAlign: "right" }}>
                        <label style={{ marginBottom: 0 }}>Property Mapped</label>
                      </div>
                      <div className='row col-lg-8 pr-0' style={{ display: "flex", alignItems: "center" }}>

                        <div className='col-lg-6 pr-0'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            minDate={this.props.type.includes("Scheduler") ? new Date() : ""}
                            name={"mappedRangeFrom"}
                            placeholder='From - mm/dd/yyyy'
                            dateFormat="mm/dd/yy"
                            value={this.state.mappedRangeFrom}
                            onChange={(e) => this.onDateChange(e, 'mappedRangeFrom', true)}
                            onSelect={(e) => this.onDateChange(e, 'mappedRangeFrom')}
                          />
                        </div>

                        <div className='col-lg-6 text-right pr-0'>
                          <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                            appendTo={document.body}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1940:2530"
                            minDate={this.state.mappedRangeFrom ? this.state.mappedRangeFrom : ""}
                            name={"mappedRangeTo"}
                            placeholder='To - mm/dd/yyyy'
                            dateFormat="mm/dd/yy"
                            value={this.state.mappedRangeTo}
                            onChange={(e) => this.onDateChange(e, 'mappedRangeTo', true)}
                            onSelect={(e) => this.onDateChange(e, 'mappedRangeTo')}
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className='alignCenter row col-lg-12 mb-2 mt-4' >
                      <div className='col-lg-6' style={{ textAlign: "right" }}>
                        <Button
                          outline
                          disabled={this.state.statePropertyId != null && this.state.statePropertyId != "" && this.state.sourceState && this.state.sourceState.length == 0 ? true : false}
                          // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                          style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#4EA250", color: "white", width: "90%" }}
                          onClick={(e) => this.getDataFromServer()}
                        >
                          Search
                        </Button>
                      </div>

                      <div className='col-lg-6' style={{ textAlign: "right" }}>
                        <Button
                          outline
                          // disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                          // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                          style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white", width: "90%" }}
                          onClick={(e) => this.clearData()}
                        >
                          {"Clear All Filters"}
                        </Button>
                      </div>
                    </div>

                  </div>

                </div>
              </AccordionTab>
            </Accordion>
            <div className='alignCenter row col-lg-12 pr-0' >
              {this.state.showMessage ?
                // <div className='col-lg-5 mt-3 mb-2 p-0 ml-4' style={{ fontWeight: "bold", color: "green" }}>
                <div className='alignCenter row col-lg-12 mt-2  pr-0'>
                  <div className='col-lg-2 pr-1'>
                    <p style={{ whiteSpace: "pre", backgroundColor: 'lightgray', padding: "0.5rem" }} >
                      {`Elastic Results: ${this.state.resultSet}`}</p>
                  </div>
                  {/* Commenting the below code as per requirements in op2-2486 and we might use in future*/}
                  <div className='col-lg-3 pr-1 pl-0'>
                    {/* <p style={{ whiteSpace: "pre", backgroundColor: 'lightgray', padding: "0.5rem" }} >
                      {`Conf. On Site Results: ${this.state.SQLCount} out of sampled 1000 records`}</p> */}
                  </div>
                  {/* <div className='col-lg-1 pr-1 pl-0'> */}
                    {/* <p style={{ whiteSpace: "pre", backgroundColor: 'lightgray', padding: "0.5rem" }} >
                      {`OPRA2 Match Results: ${this.state.MongoCount}`}</p> */}
                  {/* </div> */}
                  <div className='col-lg-6  pr-0 pl-0'>
                    <p style={{ whiteSpace: "pre", backgroundColor: 'lightgray', padding: "0.5rem", textAlign:"right" }}>
                      {`Displaying a maximum of 1,000 results in the grid below. Additional records, up to 10,000, can be viewed in the exported file.`}</p>
                    {/* {configMessages.PropertySearchv3Message} */}
                  </div>
                  <div className='col-lg-1 pl-0' style={{ textAlignLast: "right", paddingRight: "3.0rem" }}>
                    <span  ><Button color="primary"
                      className='p-1 py-0 mb-0 ml-3' style={{ height: "38px" }}
                      size={'sm'} onClick={this.props.handleSelectedAction}
                      outline
                      disabled={this.props.allUsersData.length > 0 ? false : true}
                    >
                      <FontAwesomeIcon
                        icon='download'
                        data-toggle="tool-tip" title={"Export To excel"}
                        className='pl-1' size='lg' />
                    </Button>
                      {this.props.getExcelDownload()}</span>
                  </div>
                </div> : null}

            </div>
          </div>

        </CardBody>
      </Card >
    )
  }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PropertySearchv3Filters = reduxForm({
  form: "PropertySearchv3Filters", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(PropertySearchv3Filters);

// You have to connect() to any reducers that you wish to connect to yourself
PropertySearchv3Filters = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(PropertySearchv3Filters);

export default withTranslation('common')(PropertySearchv3Filters);