import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service';

// config file
export default class ClaimsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Claims Management',
      loading: false,
      sessionWarning: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getSettingsData = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' },
      // { key: 'screenType', value: ["Claims"], type: 'in', add_Filter: true }
    ];
    fetchMethodRequest('GET', `PropertyStatus?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["propertyStatuses"] && response["propertyStatuses"].length > 0) {
        let propertyStatuses = response["propertyStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        this.setState({ settingsData: StatusArray })
      }
    })
    return this.state.settingsData;
  }
  getClaimStatus = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    fetchMethodRequest('GET', `${apiCalls.ClaimStatuses}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["claimStatuses"] && response["claimStatuses"].length > 0) {
        let propertyStatuses = response["claimStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        this.setState({ claimStatus: StatusArray })
      }
    })
    return this.state.settingsData;
  }
  getAnalyst = () => {
    let userArray = []
    return fetchMethodRequest('GET', `${apiCalls.Users}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          for (let user of response.users) {
            userArray.push({ label: user.display_name, value: user.display_name })
          }
          await this.setState({ userArray: userArray })
        }
      }).catch((err) => {
        return err;
      });
  }


  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    this.getSettingsData()
    await this.getClaimStatus()
    await this.getAnalyst()
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   "show": true,
      //   "mobile": true,
      //   "textAlign": 'left',
      //   "field": "expander",
      //   "width": 60,
      //   "fieldType": 'expander',
      //   "header": "expander"
      // },
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "",
      //   // fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   frozen: true,
      //   show: true,
      //   mobile: true,
      //   displayInSettings: false,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "OppClaimID",
        "mobile": true,
        "header": "Opp Claim ID",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "StateClaimID",
        "mobile": true,
        "editField": "text",
        "header": "St.Claim ID",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "CompanyID",
        "mobile": true,
        "allowInt": true,
        filterType: "num",
        "header": "Company ID ",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Deal",
        "mobile": true,
        "field": "estateName",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "header": "Src. State",
        "mobile": true,
        "field": "source",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 120,
        "allowInt": true,
        // "filterType": "date",
        "header": "Date Initiated",
        "mobile": true,
        "editField": "date",
        "fieldType": "dateTime",
        filterType: "num",
        "field": "date__Initiated",
        "dateFormat": 'MM-DD-yyyy',
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "field": "connectedProperties",
        "mobile": true,
        "allowInt": true,
        filterType: "num",
        "header": "# of Properties",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "allowInt": true,
        "filterType": "num",
        "header": "Claim Amount",
        "mobile": true,
        "fieldType": "formatCurrency",
        "field": "ClaimAmount",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Claim Status",
        "mobile": true,
        "field": "ClaimStatus",
        "editField": "dropDown",
        "displayInSettings": true,
        options: this.state.claimStatus,
        filter: false,
        "sortable": false,
        "fieldType": "ClaimStatus"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Claim Reason",
        "mobile": true,
        "field": "ClaimReason",
        "editField": "dropDown",
        options: [],
        "displayInSettings": true,
        filter: false,
        "sortable": false,
        "fieldType": "ClaimReason"
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 120,
        "allowInt": true,
        // "filterType": "date",
        "header": "Last Status Update Date",
        "mobile": true,
        "editField": "date",
        "fieldType": "dateTime",
        filterType: "num",
        "field": "last_Edit_Date",
        "dateFormat": 'MM-DD-yyyy',
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 120,
        "allowInt": true,
        // "filterType": "date",
        "header": "Next Action Date",
        "mobile": true,
        "editField": "date",
        "fieldType": "dateTime",
        filterType: "num",
        "field": "next_Action_Date",
        "dateFormat": 'MM-DD-yyyy',
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Next Action User",
        "mobile": true,
        "field": "next__Action__Person",
        "editField": "dropDown",
        "displayInSettings": true,
        options: this.state.userArray,
        filter: false,
        "sortable": false,
        "fieldType": "Analyst"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "field": "AssetType",
        "mobile": true,
        "allowInt": true,
        filterType: "num",
        "header": "Asset Type",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },


      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "PropertyID",
        "mobile": true,
        filterType: "num",
        "header": "Prop. ID",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "StatePropertyID",
        "mobile": true,
        filterType: "num",
        "header": "St. Prop. ID",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "header": "Src. State",
        "mobile": true,
        "field": "SourceState",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Biz Name",
        "mobile": true,
        "field": "BusinessName",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },



      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "header": "Payments",
      //   "mobile": true,
      //   "field": "paymentsdata",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": false,
      //   "fieldType": "sumArray"
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Prop. Status",
        "mobile": true,
        "field": "PropertyStatus",
        // "editField": "dropDown",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "PropertyReason",
        "mobile": true,
        // "editField": "dropDown",
        "header": "Prop. Reason",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "header": "Payments",
      //   "mobile": true,
      //   "field": "Payments",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": false,
      //   "fieldType": "text"
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Prop. Note",
        "mobile": true,
        "field": "PropertyNote",
        "displayInSettings": true,
        filter: true,
        "sortable": false,
        "openNotes": true,
        "fieldType": "Array"
      },

      {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "header": "Total Payment",
        "mobile": true,
        "field": "payment",
        "displayInSettings": true,
        filter: false,
        "sortable": false,
        fieldType: "SumAmount"
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "header": "Add Payment(+)",
        "mobile": true,
        "field": "PaymentBtn",
        "fieldType": "PaymentBtn",
        "displayInSettings": true,
        filter: false,
        "sortable": false,
        // "fieldType": "ClaimReason"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "FileFor",
        "mobile": true,
        "header": "Mapped Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "right",
        "width": 120,
        "allowInt": true,
        "filterType": "date",
        "header": "Claim Creation Date",
        "mobile": true,
        filterType: "num",
        // "editField": "date",
        "fieldType": "dateTime",
        "field": "created",
        "dateFormat": 'MM-DD-yyyy',
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 120,
        "allowInt": true,
        "filterType": "date",
        "header": "Claim Updation Date",
        "mobile": true,
        filterType: "num",
        // "editField": "date",
        "fieldType": "dateTime",
        "field": "UpdatedAtUtc",
        "dateFormat": 'MM-DD-yyyy',
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "allowInt": true,
        "filterType": "text",
        "header": "Claim Notes",
        "mobile": true,
        // "editField": "text",
        "fieldType": "ArrayNote",
        "openNotes1": true,
        "field": "ClaimNotes",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "header": "Claim Status",
      //   "mobile": true,
      //   "field": "ClaimStatus",
      //   "editField": "dropDown",
      //   "displayInSettings": true,
      //   options: this.state.claimStatus,
      //   filter: false,
      //   "sortable": false,
      //   "fieldType": "ClaimStatus"
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Claim Reason",
        "mobile": true,
        "field": "ClaimReason",
        "editField": "dropDown",
        options: [],
        "displayInSettings": true,
        filter: false,
        "sortable": false,
        "fieldType": "ClaimReason"
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Analyst",
        "mobile": true,
        "field": "Analyst",
        "editField": "dropDown",
        "displayInSettings": true,
        options: this.state.userArray,
        filter: false,
        "sortable": false,
        "fieldType": "Analyst"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Seller Proof",
        "mobile": true,
        "field": "sellerProof",
        // "editField": "dropDown",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Name Proof",
        "mobile": true,
        "field": "nameProof",
        // "editField": "dropDown",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Address Proof",
        "mobile": true,
        "field": "addressProof",
        // "editField": "dropDown",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "FEIN Proof",
        "mobile": true,
        "field": "feinProof",
        // "editField": "dropDown",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Holder Proof",
        "mobile": true,
        "field": "holderProof",
        // "editField": "dropDown",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },


      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "scoringDateTime",
      //   "mobile": true,
      //   "fieldType": "Date",
      //   "dateFormat": 'MM-DD-yyyy',
      //   "header": "Start Date",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": true,
      // },



    ]
    return data;
  };


  getFormFields = () => {
    return ([
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "EstateName",
        "label": "Deal",
        "id": "EstateName",
        "placeholder": "Deal",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "SourceState",
        "label": "Source State",
        "id": "SourceState",
        "placeholder": "Source State",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "BusinessName",
        "label": "Business Name",
        "id": "BusinessName",
        "placeholder": "Business Name",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "checkForm",
        "label": "Check From",
        "id": "checkForm",
        "placeholder": "Check From",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "checkTo",
        "label": "Check To",
        "id": "checkTo",
        "placeholder": "Check To",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "textArea",
        "name": "note",
        "label": "Note",
        "id": "note",
        "placeholder": "Note",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "checkNumber",
        "label": "Check Number",
        "id": "checkNumber",
        "placeholder": "Check Number",
        "required": true
      },
      // {
      //   //'show': false,
      //   "value": "",
      //   "type": "text",
      //   "name": "checkAmount",
      //   "label": "Check Amount",
      //   "formatType": 'Number',
      //   "id": "checkAmount",
      //   "placeholder": "Check Amount",
      //   "required": true
      // },
      {
        //'show': false,
        "value": "",
        "type": "date",
        "name": "checkDate",
        "label": "Date on Check",
        "id": "checkDate",
        "placeholder": "Date on Check",
        "required": true
      },
    ]);
  }

  getClaimStatusFormFields = () => {
    return ([{
      "value": "",
      "type": "text",
      "name": "ClaimStatus",
      "label": "Claim Status",
      "id": "ClaimStatus",
      "placeholder": "Claim Status",
      "required": false,
      // "disable": true
    },])
  }

  submit = async (item) => {

  }

  render() {
    return (
      <>{null}</>
    );
  }
}