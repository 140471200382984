import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import configImages from '../../../config/configImages';
import fetch from '../../../config/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../../config/apiCalls';
//session expiry modal
import SessionExpiryModal from '../../Cruds/CommonModals/SessionexpiryModal';

import ConfirmaionModelorSave from '../../Cruds/CommonModals/ConfirmationModal';
import { InputSwitch } from 'primereact/inputswitch';
import configMessages from '../../../config/configMessages';



class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      settingsInfo: {},
      sessionExpiryModal: false,
      isExpand: false,
      value: false,
      timer: false,
      topList: [
        {
          label: "Reports", route: "reports", subMenus:
            [
            ]
        },
        {
          label: "Deal Managements", route: "sellers", subMenus:
            [{ label: "Names", route: "/sellersEdit/name" },
            { label: "Address", route: "/sellersEdit/Address" },
            ]
        },

      ],
      recentTask: {},
      isActive: true,
      closeonlyModal: null
    }
    this.inactivityTimeout = null;
    this.hideRecentTasksInURLs = ['/mailForwards', '/mailForwardsDetails', '/mailForwardsAssignment', '/mailFwdSubmissionReport']

  }


  componentDidMount() {
    // this.getRecentTaskStarted()
    this.getSettings()
    // this.setupEventListeners()
  }

  /**
   * start shared worker
   */
  setupSharedWorker() {
    if (this.worker) {
      this.worker.port.close();
    }
    this.worker = new SharedWorker('testWorker.js');
  }


  componentWillUnmount() {
    // Cleanup and terminate the worker when the component unmounts
    document.removeEventListener('mousemove', this.handleActivity);
    document.removeEventListener('keydown', this.handleActivity);

  }

  // /**
  //  * setting event listeners
  //  */
  // setupEventListeners() {
  //   document.addEventListener('mousemove', this.handleActivity);
  //   document.addEventListener('keydown', this.handleActivity);
  //   document.addEventListener("visibilitychange", () => {
  //     if (document.visibilityState === "visible") {
  //       this.getRecentTaskStarted("checkvisibility")
  //     }
  //   })
  //   window.addEventListener("storage", (event) => {
  //     if (localStorage.getItem("isTaskUpdated")) {
  //       localStorage.removeItem("isTaskUpdated")
  //       this.getRecentTaskStarted()

  //     }
  //   });


  // }

  // /**
  //  * get the task status whenever the window visibility changes
  //  */
  // handleVisibilityChange() {
  //   this.getRecentTaskStarted()
  // }


  // handleActivity = (type) => {
  //   var selectedType = localStorage.getItem("selectedType")
  //   let diff = parseInt(localStorage.getItem("taskTimeoutInMin")) - parseInt(localStorage.getItem("taskTimeoutWarningInMin"));
  //   if (this.state.timer) {
  //     if (this.worker) {
  //       this.worker.port.postMessage({
  //         type: 'active', data: diff
  //       });
  //       this.worker.port.onmessage = async (e) => {
  //         if (e.data === "InActive") {
  //           await this.setState({ isopenConfirmaionModelforTaskupdate: true });
  //           if (this.state.isopenConfirmaionModelforTaskupdate) {
  //             this.worker.port.postMessage({ type: 'closeModal', data: parseInt(localStorage.getItem("taskTimeoutWarningInMin")) });
  //           }
  //         } else if (e.data === "Session Logout") {
  //           await this.setState({ expiredSessionModal: true, });

  //         }
  //         else if (e.data === "ClearPopUp") {
  //           this.setState({ openConfirmationModal: false })
  //         } else if (e.data === "closeonlyModal") {
  //           let type = localStorage.getItem("selectedType") ? localStorage.getItem("selectedType") : "OFF";
  //           await this.setState({ isopenConfirmaionModelforTaskupdate: false, selectedType: null });
  //           this.worker.port.postMessage({ type: 'removeTimeout' });
  //           if (type === "EXTEND") {
  //             this.setupSharedWorker()
  //             this.worker.port.postMessage({
  //               type: 'active', data: diff
  //             });

  //           } else {
  //           }
  //           this.autoTurnOffPopUp(type, "AUTOOFF");
  //           // 
  //         } else if (e.data === "closeModal") {
  //           let type = selectedType ? selectedType : "OFF"
  //           await this.setState({ isopenConfirmaionModelforTaskupdate: false, selectedType: null });
  //           localStorage.removeItem("selectedType")
  //           localStorage.removeItem("ManuallyOFF")
  //           this.autoTurnOffPopUp("OFF", "AUTOOFF closemodal")
  //         }
  //       }
  //       localStorage.removeItem("selectedType")
  //     }
  //   } else {
  //     if (this.worker) {
  //       this.worker.port.postMessage({
  //         type: 'active', data: diff
  //       });
  //       this.worker.port.onmessage = async (e) => {
  //         if (e.data === "activeAgain" && !localStorage.getItem("ManuallyOFF")) {
  //           localStorage.removeItem("ManuallyOFF")
  //           this.autoTurnOffPopUp("ON", "AUT ON after Turnoff")
  //           this.worker.port.close();

  //         }

  //       }
  //     }
  //   }
  // }

  /**
   * Settings Call
   */
  getSettings = () => {
    var responseWarningTimeout
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    let filterCriteria = {};
    if (!responseWarningTimeout) {
      filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
      fetch('GET', `settings`).then(async (response) => {
        if (response && response.settings) {
          responseWarningTimeout = response.settings[0].taskTimeoutWarningInMin
          localStorage.setItem("taskTimeoutWarningInMin", responseWarningTimeout)
          localStorage.setItem("taskTimeoutInMin", response.settings[0].taskTimeoutInMin)
          localStorage.setItem("forceLogoutTimeInMin", parseInt(response.settings[0].forceLogoutTimeInMin) * 60 * 1000)
          localStorage.setItem("taskTimeoutInactive", parseInt(response.settings[0].taskTimeoutInMin - responseWarningTimeout))

        } else {
          return ""
        }
      })
    }
  }


  // /**
  //  * 
  //  * @param {String} type 
  //  * @param {*} fromlogin 
  //  * @returns fetch for AutoUpdatetask
  //  */
  // autoTurnOffPopUp = (type, fromlogin) => {
  //   let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
  //   if (userDetails) {
  //     var url = `tasks/AutoUpdateTask?userId=${userDetails['_id']}&action=${type ? type : "OFF"}`;
  //     return fetch('GET', url)
  //       .then(async (res) => {
  //         await this.getRecentTaskStarted()
  //         localStorage.setItem("isTaskUpdatedfromTopbar", true)
  //         window.localStorage.setItem("isTaskUpdatedfromTopbar", true)
  //         window.dispatchEvent(new Event('storage'))
  //       })
  //   }
  // }

  /**
   * 
   * @returns Confirmation for task to continue
   */
  getConfirmaionModelforSave() {
    return (
      <ConfirmaionModelorSave
        openConfirmationModal={this.state.isopenConfirmaionModelforTaskupdate}
        closeConfirmationModal={this.closeConfirmationModalforTaskupdate}
        shownText={`Do you want to Continue the task - ${this.state.recentTask.taskID}?`}
        checkForAutoturnoff={this.checkForAutoturnoff}
      // confirm={this.handleCheckValidations}
      />
    )
  }

  // /**
  //  * 
  //  * @param {String} type 
  //  * close model
  //  */
  // closeConfirmationModalforTaskupdate = async (type) => {
  //   localStorage.setItem("selectedType", type === "close" ? "EXTEND" : "OFF")
  //   if (type !== "close") {
  //     localStorage.setItem("ManuallyOFF", true)
  //   }
  //   this.worker.port.postMessage({ type: "closeonlyModal" });
  // }

  // /**
  // * 
  // * @returns get the task that is used by the current User
  // */
  // getRecentTaskStarted = async (type) => {
  //   var url = `${apiCalls.getMostRecentTask}`;
  //   // this.handleClickOutside()
  //   return fetch('GET', url)
  //     .then(async (res) => {
  //       if (res && res.from && this.state.timer && !res.details) {
  //         this.worker.port.postMessage({ type: 'stopSharedWorker' });
  //         if (this.worker) {
  //           this.worker.port.close();
  //         }
  //       }
  //       if (res && res.details) {
  //         localStorage.setItem("recentTaskID", res.details.taskID)
  //         await this.setState({
  //           recentTask: res.details,
  //           timer: res.details.timer === "start" ? true : false
  //         })
  //         if (this.state.timer && !type) {
  //           this.setupSharedWorker()
  //           this.handleActivity()
  //         }

  //       } else if (res && res.errorMessage === configMessages.warningMessage) {
  //         await this.setState({
  //           sessionExpiryModal: true
  //         })
  //       } else {
  //         await this.setState({
  //           recentTask: {},
  //         })
  //         return ""
  //       }
  //     })
  // }
  /**
  * 
  * @returns get the list from the menus api
  */
  getMenuListFromServer = async () => {
    let filterCriteria = {}, url;
    filterCriteria.sortfield = 'sequenceNo';
    filterCriteria.direction = 'asc';
    // url = `${apiCalls.MenuList}?filter=${JSON.stringify(filterCriteria)}`;
    let neWmenuList = [], neWsubmenuList = [];
    return fetch('GET', url)
      .then(async (res) => {
        if (res && res.menulists && res.menulists.length > 0) {

          this.setState({
            topList: neWmenuList,
          })
        } else {
          this.setState({
            topList: [],
          })
        }
      })
  }

  /**
   * click on expand icon
   */
  toggleFullScreen = async (e) => {
    var elem = document.documentElement;
    if (document.fullscreenElement === null) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        await this.setState({
          isExpand: true
        })
      }
    }
    else {
      if (document.exitFullscreen && document.fullscreenElement !== null) {
        document.exitFullscreen();

      }
      await this.setState({
        isExpand: false
      })
    }
  }

  // /**
  //  * 
  //  * @param {Object} item 
  //  * redirect to listView and open the task
  //  */
  // gotoListScreen = async (item) => {
  //   await this.setState({
  //     redirectedId: item._id,
  //     redirected: true
  //   })
  //   await document.getElementById("taskredirect").click()
  //   await this.setState({
  //     redirected: false
  //   })
  // }

  // /**
  //  * 
  //  * @param {Object} e 
  //  * @returns fetch call when the toggle changes
  //  */
  // onChangetaskStatus = async (e) => {
  //   const { recentTask } = this.state
  //   await this.setState(prevState => (
  //     {
  //       timer: !prevState.timer,
  //     }));

  //   recentTask.timer = recentTask.timer === "start" ? "stop" : "start"
  //   localStorage.setItem("isTaskStarted", recentTask.timer === "start" ? "ON" : "OFF")
  //   return fetch('PUT', `tasks/${recentTask._id}`, recentTask)
  //     .then(async (response) => {
  //       if (!this.state.timer) {
  //         localStorage.setItem("ManuallyOFF", true)
  //       }
  //       if (response && response.respCode) {
  //         await this.getRecentTaskStarted()
  //         localStorage.setItem("isTaskUpdatedfromTopbar", true)
  //         window.localStorage.setItem("isTaskUpdatedfromTopbar", true)
  //         window.dispatchEvent(new Event('storage'))
  //       } else if (response && response.errorMessage) {
  //       }
  //     }).catch((err) => {
  //       return err;
  //     });
  // }

  /**
   * 
   * @param {string} urlPath 
   * @returns screenName
   */
  getScreenName(urlPath) {
    return (
      <div className="topbar__center_sitename my-auto mx-2" style={{ display: "block", fontSize: 18 }}>
        {this.state.testSite && urlPath === "/nsproperties" ? "Property Editor" :
          this.state.testSite && urlPath === "/propertyEditorV2" ? "Property Editor V2" :
            this.state.testSite && urlPath === "/dealSizingPropertyEditorV2" ? "Deal Sizing Property Editor" :
              this.state.testSite && urlPath === "/dealproperties" ? "Deal Sizing Property Editor" :
                !this.state.testSite && urlPath === "/names-addressImport" ? "Name/Address Import" :
                  !this.state.testSite && urlPath === "/claimsummary" ? "Claim Summary" :
                    !this.state.testSite && urlPath === "/dealStatistics" ? "Deal Statistics" :
                      !this.state.testSite && urlPath === "/claimBuilder" ? "Claim Builder" :
                        !this.state.testSite && urlPath === "/availableToMapV2" ? "Available To Map V2" :
                          urlPath === "/pdfEditor" ? "PDF Editor" :
                            !this.state.testSite && urlPath === "/stateupfiling" ? "State Attributes" :
                              !this.state.testSite && urlPath === "/upOpportunity2" ? "UP Opportunity – V2" :
                                !this.state.testSite && urlPath === "/availableToMap" ? "Available To Map" :
                                  !this.state.testSite && urlPath === "/availableToReMap" ? "Available To ReMap" :
                                    !this.state.testSite && urlPath === "/propertySearchv2" ? "Property Search V2" :
                                      !this.state.testSite && urlPath === "/searchTermOnSite" ? "UP Site Search" :
                                        !this.state.testSite && urlPath === "/nsproperties" ? "Property Editor" : !this.state.testSite && urlPath === "/propertyEditorV2" ? "Property Editor V2" : this.state.testSite && urlPath === "/availabletoFile" ? "Available To File" : !this.state.testSite && urlPath === "/dealproperties" ? "Deal Sizing Property Editor" : !this.state.testSite && urlPath === "/dealSizingPropertyEditorV2" ? "Deal Sizing Property Editor" :
                                          !this.state.testSite && urlPath === "/availabletoFile" ? "Available To File" :
                                            this.state.testSite && urlPath === "/availabletoConfirmonSite" ? "Available To Confirm Onsite" :
                                              !this.state.testSite && urlPath === "/availabletoConfirmonSite" ? "Available To Confirm Onsite" :
                                                this.state.testSite && urlPath === "/claims" ? "Claims Manager" : !this.state.testSite && urlPath === "/searchTerm" ? "Search Terms" :
                                                  !this.state.testSite && urlPath === "/claims" ? "Claims Manager" : this.state.testSite && urlPath === "/sellerSummaryReport" ? "Seller Report" :
                                                    !this.state.testSite && urlPath === "/sellerNegativeNotice" ? "Negative Notice List Viewer" : this.state.testSite && urlPath === "/sellerNegativeNotice" ? "Negative Notice List Viewer" :
                                                      !this.state.testSite && urlPath === "/siteConfirmationEditor" ? "Site Confirmation Editor" : this.state.testSite && urlPath === "/siteConfirmationEditor" ? "Site Confirmation Editor" :
                                                        !this.state.testSite && urlPath === "/namesReport" ? "Change Report" : this.state.testSite && urlPath !== "/namesReport" ? "TEST" :
                                                          !this.state.testSite && urlPath === "/sellerSummaryReport" ? "Seller Report" :
                                                            !this.state.testSite && urlPath === "/userRequest" ? "User Request" :
                                                              !this.state.testSite && urlPath === "/outstandingclaimsreport" ? "Outstanding Claims Report" :
                                                                !this.state.testSite && urlPath === "/nextactionuserreport" ? "Next Action User Report" :
                                                                  !this.state.testSite && urlPath === "/availableToFollowUp" ? "Available To Follow - Up" :!this.state.testSite && urlPath === "/highValueProperties" ? "High Value Properties" :
                                                                    urlPath === '/mailForwards' ? "Mail Forwards" : urlPath === '/mailForwardsAssignment' ? "Mail Forwards Assignments" : urlPath === '/mailFwdSubmissionReport' ? "Integrity - Mail Forwards" : urlPath === '/mailForwardsDetails' ? "Mail Forward Details" :
                                                                      !this.state.testSite && urlPath === "/availableToFollowUp" ? "Available To Follow - Up" : urlPath === '/mappedpropertyreport' ? 'Mapped Property Search' :
                                                                        !this.state.testSite && urlPath === "/readytoreview" ? "Ready To Review" : !this.state.testSite && urlPath === "/availableToFileV2" ? "Available To File V2" : this.state.testSite && urlPath !== "/nsproperties" ? "TEST" : ""}
      </div>
    )
  }

  // /**
  //  * 
  //  * @param {String} type 
  //  * @returns 
  //  */
  // getstartorstopRecentTask(type) {
  //   let recentTask
  //   if (type) {
  //     recentTask = type
  //   } else {
  //     recentTask = this.state.recentTask
  //   }
  //   const id = recentTask && recentTask.opraEstateID ? recentTask.opraEstateID : ""
  //   if (!type) {
  //     localStorage.setItem("recentTask", JSON.stringify(recentTask))
  //   }
  //   const company = recentTask && recentTask.company ? recentTask.company : ""
  //   return (
  //     recentTask ? <div className='d-block recentTask-topbar'>
  //       <p className='d-flex mt-1 '>
  //         <span className='mb-1 mr-2'>Most Recent Task :</span><span className={recentTask && recentTask.title ? "d-block" : "d-none"}>{id ? "( " + id + " ) " : ""}{company ? company : ""}
  //           <FontAwesomeIcon
  //             className='ml-2'
  //             color='#354f6e'
  //             style={{ cursor: "pointer", height: "1em" }}
  //             icon={faExternalLinkAlt}
  //             data-toggle="tool-tip"
  //             onClick={() => this.gotoListScreen(recentTask)}
  //           /></span>

  //       </p>
  //       <div className='d-flex' style={{
  //         justifyContent: "space-between"
  //       }}>
  //         <div>Status : <span className="px-2" style={recentTask && recentTask.status === "In Progress" ? { backgroundColor: "#7aae7a" } : { backgroundColor: "#f3f711" }}>{recentTask && recentTask.title ? recentTask.status === "Paused" ? "Paused-No Active Tasks" : recentTask.status : "No Recent Task for the user"}</span>
  //         </div> <span className={recentTask && recentTask.title ? "d-block" : "d-none"} >

  //           <InputSwitch checked={this.state.timer}
  //             className='ml-2' onChange={(e) => this.onChangetaskStatus()} />

  //         </span>
  //       </div>
  //     </div > : null
  //   )
  // }

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility, urlPath } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left topbar_Text ml-4">
            {/* <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            /> */}
            {/* <Link className='topbarLogo ml-3' to="/employees" > */}
            {/* {configImages.defaultImg} */}
            <img src={configImages.projectTitle} style={{ width: 127, height: 50, marginTop: 7, marginBottom: 5, borderRadius: 0, marginRight: 20 }} alt="" />
            {/* </Link> */}
            {/* {!this.hideRecentTasksInURLs.includes(urlPath) && this.getstartorstopRecentTask()} */}
            {this.getScreenName(urlPath)}

          </div>

          <div className="topbar__right">
            {/* <TopbarNotifications /> */}
            <TopbarProfile />
            <a href="#" className={"lnr lnr-frame-expand expandIcon ml-5"} onClick={this.toggleFullScreen} style={{ fontSize: 20 }}></a>
            <a className={"lnr lnr-bug expandIcon"} href="/reportBug" style={{ fontSize: 20 }}></a>
            <Link to={"/userRequest"} className={"px-2"}> <img src={configImages.userRequest}
              style={{ height: '26px', width: '26px', marginTop: "12px", borderRadius: 0 }} /></Link>
            <a className={"lnr lnr-code expandIcon"} href="/sendRequest" style={{ fontSize: 20 }}></a>
            {/* <TopbarLanguage /> */}
          </div>
          {this.state.sessionExpiryModal ?
            <SessionExpiryModal
              SOpen={this.state.sessionExpiryModal}

            />
            : null}
          {this.state.isopenConfirmaionModelforTaskupdate && this.state.timer ?
            this.getConfirmaionModelforSave()
            : null}
          {this.state.expiredSessionModal ? this.expiredSessionModal() : null}

          {this.state.redirected ?
            <a id="taskredirect" target="_blank" href={`/listView?id=${this.state.redirectedId}`} style={{ fontSize: 20 }}
            ></a>
            : null}
        </div>
      </div >

    );
  }
}

export default Topbar;



















