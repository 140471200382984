import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import config from '../../../config/config';
import apiCalls from '../../../config/apiCalls';
import fetch from '../../../config/service'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Menubar } from 'primereact/menubar';

import { setUserAction } from '../../../redux/actions/userActions';

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
      userData: {},
      items: [
        {
          label: 'Settings',
          items: [
            {
              label: 'Log Out',
              url: "/logout",
            }
          ]
        },

      ],
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  componentDidMount = async () => {
    this.updateTopbarProfileData()
    this.getMenuItems()
  }

  updateTopbarProfileData = async () => {
    let userData = await localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    if (userData) {
      const matches = userData && userData.displayName && userData.displayName.match(/\b(\w)/g); // ['J','S','O','N']
      const acronym = matches.join(''); // JSON
      await this.props.setUserdata(userData)
      await this.setState(({
        userData: userData,
        acronym: acronym.substring(0, 2)
      }));
    }
  }

  getMenus() {
    let filterCriteria = {}, url;
    filterCriteria.sortfield = 'sequence';
    filterCriteria.direction = 'asc';
    filterCriteria.criteria = [{ key: "activeIndicator", value: true, type: "eq" }]
    url = `${apiCalls.topBarMenulists}?filter=${JSON.stringify(filterCriteria)}`;
    let neWmenuList = [];
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials) {
      let depthe2rr = [], depthe1rr = [];
      return fetch('GET', url)
        .then(async (res) => {
          if (res && res.groupedResult && res.groupedResult.length > 0) {

            loginCredentials.rolePermissions["Logout"] = "Edit"
            // loginCredentials.rolePermissions["My Profile"] = "Edit"
            res.groupedResult.forEach((item, index) => {
              if (item.items && item.items.length > 0) {
                depthe1rr = []
                item.items.forEach((data, index2) => {
                  if (data && data.items) {
                    depthe2rr = []

                    data.items.forEach((record, index3) => {
                      if (data && loginCredentials.rolePermissions[data.label] !== "NoView") {
                        if (record.url && loginCredentials.rolePermissions[record.label] && loginCredentials.rolePermissions[record.label] !== "NoView" || !record.url)
                          depthe2rr.push(record)
                      }
                    })

                    data.items = depthe2rr
                  }
                  if (data && loginCredentials.rolePermissions[data.label] !== "NoView") {
                    if (data.url !== null || data.items.length > 0) {
                      if (data.url && loginCredentials.rolePermissions[data.label] && loginCredentials.rolePermissions[data.label] && loginCredentials.rolePermissions[data.label] !== "NoView" || !data.url)
                        depthe1rr.push(data)
                    }
                  }
                })
                item.items = depthe1rr
              } else {
                item.items = null
              }
            })
            let finalData = []
            res.groupedResult.forEach((menu, menuindex) => {
              if (menu.items && menu.items.length > 0) {
                finalData.push(menu)
              } else if (menu && menu.items && menu.items.length === 0 && menu.url) {
                menu.items = null
                if (loginCredentials.rolePermissions[menu.label]) {
                  finalData.push(menu)
                }
              } else if (menu && menu.items === null && menu.url) {
                if (loginCredentials.rolePermissions[menu.label] && loginCredentials.rolePermissions[menu.label] !== "NoView") {
                  if (menu.url && loginCredentials.rolePermissions[menu.label] || !menu.url)

                    finalData.push(menu)
                }

              }

            })
            res.groupedResult = finalData
            const sorted = res.groupedResult.sort((a, b) => {
              let fa = a.sequence,
                fb = b.sequence;
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            localStorage.setItem('topBarMenulist', JSON.stringify(sorted));
            this.setState({
              items: sorted
            })
          } else {
            this.setState({
              items: []
            })
          }
        })
    }
  }

  getMenuItems = async () => {
    let menuListItems = await localStorage.getItem('topBarMenulist');
    menuListItems = JSON.parse(menuListItems);
    if (menuListItems) {
      this.setState({
        items: menuListItems ? menuListItems : []
      })
    } else {
      this.getMenus()
    }

  }


  render() {
    const { collapse, acronym } = this.state;
    const profileImage = this.props.userData && this.props.userData.photo ? this.props.userData.photo : null;

    const { items } = this.state
    return (
      <>
        <div className="topbar__profile">
          <Menubar
            model={items}
          // start={<InputText placeholder="Search" type="text" />}
          // end={<Button label="Logout" icon="pi pi-power-off" />}
          />
        </div>



      </>
    );
  }
}


TopbarProfile = connect(
  state => (
    {
      userData: state.userReducer.userData
    }),
  { setUserdata: setUserAction },
)(TopbarProfile);
export default withTranslation('common')(TopbarProfile);

