
import React from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import { faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import fetchMethodRequest from '../../config/service';
import fetch from '../../config/service';
import dateFormats from '../UI/FormatDate/formatDate';
import { withTranslation } from 'react-i18next';
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import apiCalls from '../../config/apiCalls';
import { AutoComplete } from 'primereact/autocomplete'
import { Dropdown } from 'primereact/dropdown';
import config from '../../config/config';
import { MultiSelect } from 'primereact/multiselect';
import configMessages from '../../config/configMessages';

// import task from 'containers/UserView/components/task';


//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class FollowUpFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sellers: [],
      filterCriteria: {
        criteria: [{
          key: "ASSET_TRACKING_STATUS",
          value: ["Active"],
          type: "in",
          add_Filter: true
        }]
      },
      lastUpdatedDate: "",
      isChangeHit: false,
      filterednameSuggestions: [],
      sellerName: "",
      setGoButton: false,
      isSharing: false,
      modifiedAddress: [],
      selectedViewType: "Default",
      isexcludeProperties: "",
      SessionWarningModal: false,
      selectedAnalyst: [],
      TrackingStatus: ["Active"],

      // mctSourceName: [],
      isexcludeRoyalties: true,
      // taskProperties: {},
      isredirtRanges: false,
      selectedState: [],
      selectedDate: null,
      searchmodifiedState: [
        { label: "AL (Alabama)", value: "AL" },
        { label: "AK (Alaska)", value: "AK" },
        { label: "AZ (Arizona)", value: "AZ" },
        { label: "AR (Arkansas)", value: "AR" },
        { label: "CO (Colorado)", value: "CO" },
        { label: "CT (Connecticut)", value: "CT" },
        { label: "DE (Delaware)", value: "DE" },
        { label: "ID (Idaho)", value: "ID" },
        { label: "IL (Illinois)", value: "IL" },
        { label: "IN (Indiana)", value: "IN" },
        { label: "IA (Iowa)", value: "IA" },
        { label: "LA (Louisiana)", value: "LA" },
        { label: "ME (Maine)", value: "ME" },
        { label: "MA (Massachusetts)", value: "MA" },
        { label: "MI (Michigan)", value: "MI" },
        { label: "MN (Minnesota)", value: "MN" },
        { label: "MS (Mississippi)", value: "MS" },
        { label: "NE (Nebraska)", value: "NE" },
        { label: "NH (New Hampshire)", value: "NH" },
        { label: "NJ (New Jersey)", value: "NJ" },
        { label: "NC (North Carolina)", value: "NC" },
        { label: "ND (North Dakota)", value: "ND" },
        { label: "OH (Ohio)", value: "OH" },
        { label: "OR (Oregon)", value: "OR" },
        { label: "PR (Puerto Rico)", value: "PR" },
        { label: "RI (Rhode Island)", value: "RI" },
        { label: "SC (South Carolina)", value: "SC" },
        { label: "SD (South Dakota)", value: "SD" },
        { label: "TX (Texas)", value: "TX" },
        { label: "UT (Utah)", value: "UT" },
        { label: "VA (Virginia)", value: "VA" },
        { label: "DC (Washington - DC)", value: "DC" },
        { label: "WA (Washington - WA)", value: "WA" },
        { label: "WV (West Virginia)", value: "WV" },
        { label: "WY (Wyoming)", value: "WY" },
      ],
      upmodifiedState: [
        { label: "AL (Alabama)", value: "AL" },
        { label: "AK (Alaska)", value: "AK" },
        { label: "AZ (Arizona)", value: "AZ" },
        { label: "AR (Arkansas)", value: "AR" },
        { label: "CA (California)", value: "CA" },
        { label: "CA-N (California N)", value: "CA-N" },
        { label: "CO (Colorado)", value: "CO" },
        { label: "CT (Connecticut)", value: "CT" },
        { label: "DE (Delaware)", value: "DE" },
        { label: "FL (Florida)", value: "FL" },
        { label: "GA (Georgia)", value: "GA" },
        { label: "HI (Hawaii)", value: "HI" },
        { label: "ID (Idaho)", value: "ID" },
        { label: "IL (Illinois)", value: "IL" },
        { label: "IN (Indiana)", value: "IN" },
        { label: "IA (Iowa)", value: "IA" },
        { label: "KS (Kansas)", value: "KS" },
        { label: "KY (Kentucky)", value: "KY" },
        { label: "LA (Louisiana)", value: "LA" },
        { label: "ME (Maine)", value: "ME" },
        { label: "MD (Maryland)", value: "MD" },
        { label: "MA (Massachusetts)", value: "MA" },
        { label: "MI (Michigan)", value: "MI" },
        { label: "MN (Minnesota)", value: "MN" },
        { label: "MS (Mississippi)", value: "MS" },
        { label: "MO (Missouri)", value: "MO" },
        { label: "MT (Montana)", value: "MT" },
        { label: "NE (Nebraska)", value: "NE" },
        { label: "NV (Nevada)", value: "NV" },
        { label: "NH (New Hampshire)", value: "NH" },
        { label: "NJ (New Jersey)", value: "NJ" },
        { label: "NM (New Mexico)", value: "NM" },
        { label: "NY (New York)", value: "NY" },
        { label: "NC (North Carolina)", value: "NC" },
        { label: "ND (North Dakota)", value: "ND" },
        { label: "OH (Ohio)", value: "OH" },
        { label: "OK (Oklahoma)", value: "OK" },
        { label: "OR (Oregon)", value: "OR" },
        { label: "PA (Pennsylvania)", value: "PA" },
        { label: "PR (Puerto Rico)", value: "PR" },
        { label: "RI (Rhode Island)", value: "RI" },
        { label: "SC (South Carolina)", value: "SC" },
        { label: "SD (South Dakota)", value: "SD" },
        { label: "TN (Tennessee)", value: "TN" },
        { label: "TX (Texas)", value: "TX" },
        { label: "UT (Utah)", value: "UT" },
        { label: "VT (Vermont)", value: "VT" },
        { label: "VA (Virginia)", value: "VA" },
        { label: "DC (Washington - DC)", value: "DC" },
        { label: "WA (Washington - WA)", value: "WA" },
        { label: "WV (West Virginia)", value: "WV" },
        { label: "WI (Wisconsin)", value: "WI" },
        { label: "WY (Wyoming)", value: "WY" },
      ],
      page: 0,
      modifiedStates: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      isValidate: false,
      excludeCoOwners: false,
      excludeNotConfirmedOnSite: true,
      isexcludePossiblyPaid: false,
      modifiedStatus: [
        { label: "Possible Confirmation", value: "Possible Confirmation" },
        { label: "Waiting for Validation", value: "Waiting for Validation" },
      ],
      matchBuckets: [
        { label: "Overall Near Exact", value: "Overall Near Exact" },
        { label: "Overall Tier-1", value: "Overall Tier-1" },
        { label: "Overall Tier-2", value: "Overall Tier-2" },
        { label: "Overall Tier-3", value: "Overall Tier-3" },
        { label: "Name Near Exact", value: "Name Near Exact" },
        { label: "Name Tier-1", value: "Name Tier-1" },
        { label: "Name Tier-2", value: "Name Tier-2" },
        { label: "Name Tier-3", value: "Name Tier-3" },
        { label: "Address Near Exact", value: "Address Near Exact" },
        { label: "Address Tier-1", value: "Address Tier-1" },
        { label: "Address Tier-2", value: "Address Tier-2" },
        { label: "Address Tier-3", value: "Address Tier-3" },
      ],
      dropdownOpen: false,
      filters: {
        Source: null,
        ASSET_TRACKING_STATUS: ["Active"],
        Analyst: null,
        ClaimStatus: null,
        ClaimReason: null,
        CompanyID: null,
        StateClaimID: null,
        OppClaimID: null,
        StatePropertyID: null,
        PropertyID: null,
        nextActionDateFilter: null,
        selectedDepartment: null
      },
      selectedDeal: null


    };
  }
  getClaimStatus = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    fetchMethodRequest('GET', `${apiCalls.ClaimStatuses}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["claimStatuses"] && response["claimStatuses"].length > 0) {
        let propertyStatuses = response["claimStatuses"]
        for (let status of propertyStatuses) {
          if (status.status !== null) {
            StatusArray.push({ label: status.status, value: status.status })
          }
        }
        this.setState({ claimStatus: StatusArray })
      }
    })
    return this.state.settingsData;
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  getDepartments = () => {
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }, { key: 'activeIndicator', value: true, type: 'eq' }]

    return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          if (response && response.departments) {
            let labels = response.departments;
            let modifiedLabels = [];
            for (let label of labels) {
              modifiedLabels.push({
                label: label.departmentName,
                value: label._id,
                departmentId: label._id

              })
            } const sorted = modifiedLabels.sort((a, b) => {
              let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            await this.setState({
              taskDepartmentItems: sorted
            })
            let user = JSON.parse(localStorage.getItem('loginCredentials'));
            // let DepartmentArray = [], DepartmentIdArray = []
            // let user = JSON.parse(localStorage.getItem('loginCredentials'));
            if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
              let DepartmentArray = user.secondaryDepartments ? user.secondaryDepartments : [], DepartmentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
              DepartmentArray.push(user.department)
              DepartmentIdArray.push(user.departmentId)
              DepartmentArray = this.state.taskDepartmentItems.filter(obj => DepartmentArray.includes(obj.label)).map(obj => obj.label)
              DepartmentIdArray = this.state.taskDepartmentItems.filter(obj => DepartmentIdArray.includes(obj.departmentId)).map(obj => obj.departmentId)
              let filters = this.state.filters;
              // filters.selectedDepartment = [user.departmentId];
              filters.selectedDepartment = DepartmentIdArray
              this.setState({ selectedDepartment: DepartmentArray, DepartmentIdArray: DepartmentIdArray, filters: filters })


              // await this.datatableref.setDepartement(DepartmentArray)
            }
          }
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  getDataGroupSources = async () => {
    let StatusArray = [], dataArray = [];
    let filterCriteria = {}
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    let loginRole = loginCredentials.roleName
    // filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: "roles", value: loginRole, type: "eq" }];
    await fetchMethodRequest('GET', `${apiCalls.DataSource}?type=group&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["dataSources"] && response["dataSources"].length > 0) {
        let propertyStatuses = response["dataSources"]
        for (let status of propertyStatuses) {
          if (status.dataSourceNames !== null && status.group !== null) {
            StatusArray.push({ label: status.group, value: status.dataSourceNames })
            dataArray.push({ label: status.group, value: status.group })
          }

          if (status.group === "State UP Data") {

            await this.setState({ setDefaultDataGroup: true })
          }
        }
        this.setState({ dataSourceGroups: StatusArray, dataArray: dataArray })
      }
    })
    return this.state.settingsData;
  }
  /**
     * 
     * @returns the mct source array for claims filter dropdown
     */
  getMctSource = async () => {
    let mctSourceArray = [], dataArray = [];
    let filterCriteria = {}
    filterCriteria.sortfield = "SourceInfo_Sort_Code, SourceInfo_Source"
    filterCriteria.direction = 'asc'
    await fetchMethodRequest('GET', `${apiCalls.mctSources}?filter=${JSON.stringify(filterCriteria)}&isDropdown=true`).then(async (response) => {
      if (response && response["claimSources"] && response["claimSources"].length > 0) {
        let mctSources = response["claimSources"]
        for (let mctSource of mctSources) {
          mctSourceArray.push({ label: mctSource.sourceInfo_Source, value: mctSource.sourceInfo_Source })
        }

        await this.setState({ mctSourceArray: mctSourceArray })
      }
    })
    return this.state.mctSourceArray;
  }
  /**
      * 
      * @returns the claim status reason array for claims filter dropdown
      */

  getClaimStatusReason = async () => {
    let claimStatusArray = [], claimReasonArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }, { key: 'claimStatusFlag', value: true, type: 'eq', add_Filter: true },
    { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    // await fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?dropdown=status`).then(async (response) => {
    await fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&isOutTheDoorDate=true`).then(async (response) => {
      if (response && response["propertyStatusReasons"] && response["propertyStatusReasons"].length > 0) {
        let propertyStatusReasons = response["propertyStatusReasons"]
        for (let propertyStatusReason of propertyStatusReasons) {
          // claimStatusArray.push({ label: propertyStatusReason.status, value: propertyStatusReason.status })
          // claimReasonArray.push({ label: propertyStatusReason.reason, value: propertyStatusReason.reason })
          // if (propertyStatusReason.claimSequence) {
          if (claimStatusArray.findIndex((obj) => obj.value == propertyStatusReason.status) == -1) {
            claimStatusArray.push({ label: propertyStatusReason.status, value: propertyStatusReason.status })
          }
          // }
          // if(claimReasonArray.findIndex((obj)=>obj.value==propertyStatusReason.reason)==-1){
          //   claimReasonArray.push({ label: propertyStatusReason.reason, value: propertyStatusReason.reason })
          // }
        }

        this.setState({ claimStatusArray: claimStatusArray, claimReasonArray: claimReasonArray, propertyStatusReasons: propertyStatusReasons })
      }
    })
    return this.state.claimStatusArray;
  }
  componentDidMount = async () => {
    let loginData = localStorage.getItem("loginCredentials");
    loginData = JSON.parse(loginData);
    let defanalyst = []
    defanalyst.push(loginData.displayName)
    this.searchsellerItems();
    this.getClaimStatus()
    this.getDepartments()
    await this.getDataGroupSources()
    await this.getMctSource()
    await this.getClaimStatusReason()
    // this.getReasonForEditor()

    let hitback = localStorage.getItem('hitback');
    let claimSummaryId = localStorage.getItem('claimSummaryID')
    let claimSummaryName = localStorage.getItem('claimSummaryName')
    if (this.props.type === "ClaimsManagement" && hitback === "yes" && claimSummaryId) {
      let estateObj = { EstateName: claimSummaryName, EstateID: claimSummaryId }
      let filterCriteria = {}
      filterCriteria.criteria = []
      filterCriteria.page = "1"; filterCriteria.limit = 10; filterCriteria.direction = "desc"; filterCriteria.sortfield = "OppClaimID"
      filterCriteria.criteria.push({ key: 'CompanyID', value: claimSummaryId, type: 'eq', add_Filter: true })
      await this.setState({ EstateID: claimSummaryId, filterCriteria: filterCriteria, selectedSeller: estateObj })
      this.submit()
    }
    if (this.props.params && this.props.params.companyID) {
      this.onFilterChanges('OppClaimID', this.props.params.oppClaimID)
      this.onFilterChanges('ASSET_TRACKING_STATUS', [])
      await this.getSellerName(this.props.params.companyID)

    }
    let taskProperties = JSON.parse(localStorage.getItem("TaskProperties"))
    if (taskProperties) {
      if (taskProperties && taskProperties.PropertiesSellerName && taskProperties.PropertiesSellerName.EstateID) {
        await this.getSellerName(taskProperties.PropertiesSellerName.EstateID)
        taskProperties.PropertiesSellerName.EstateName = this.state.PropertiesSellerEstateName
        taskProperties.PropertiesSellerName.DealType = this.state.EstateDetails.DealType
        taskProperties.PropertiesSellerName.DealYear = this.state.EstateDetails.DealYear
        taskProperties.PropertiesSellerName.SellerNickName = this.state.EstateDetails.SellerNickName
      }
      await this.setState({ dataSource: [] })
      let selectedStates = [], selectedProperties = []
      this.handleChange(taskProperties.PropertiesSellerName, 'Seller')
      if (taskProperties.States && taskProperties.States.length > 0) {
        this.handleChange(taskProperties.States, 'State')
        for (var sine of taskProperties.States) {
          selectedStates.push(
            sine.slice(-2),
          )
        }

      }

      if (taskProperties.Property_Id && taskProperties.Property_Id.length > 0) {
        for (var sine of taskProperties.Property_Id) {
          selectedProperties.push(
            sine
          )
        }
      }
      this.handleChange(selectedStates, "State")
      await this.setState({
        // selectedDeal: taskProperties.PropertiesSellerName,
        selectedDeal: `${taskProperties.PropertiesSellerName.DealType} - ${taskProperties.PropertiesSellerName.SellerNickName} (${taskProperties.PropertiesSellerName.DealYear})`,
        // selectedState: selectedStates,
        taskProperties: taskProperties,
        Property_Ids: selectedProperties,
        isexcludeRoyalties: false,
        excludeNotConfirmedOnSite: false,
        selectedTaskId: taskProperties.taskID.taskID,
        selectedtask: taskProperties.taskID
      })
      await this.searchnameItems()
      this.submit()
      localStorage.removeItem("TaskProperties")
    }
    if (this.props.type === "Properties") {
      await this.setState({
        selectedBucket: "Overall Near Exact"
      })
    }

    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.state.setDefaultDataGroup && this.props.type === "Property Editor" && !taskProperties) {
      this.handleChange({ value: ["State UP Data"] }, 'dataSource')
    }

    let userArray = []
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: "role", value: ["Search Analyst", "Search Manager"], type: "nin" }];
    return fetch('GET', `${apiCalls.Users}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          for (let user of response.users) {
            if (user.display_name !== null) {
              userArray.push({ label: user.display_name, value: user.display_name })
            }
          }
          this.setState({ userArray: userArray })
        }
      }).catch((err) => {
        return err;
      });

    this.setState({ isLoading: false, selectedAnalyst: defanalyst, defanalyst: defanalyst });

  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  groupTemplate(option) {
    if (!option.value) {
      return option.label;
    }
    else {
      return (
        <div className=""
          data-toggle="tool-tip"
          title={option.value.description}>{option.label}
        </div>
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
    }
  }

  getData = async (obj) => {
    if ((obj && obj.selectedRanges || obj && obj.groupedflag) && this.props.type === "Property Editor") {
      await this.setState({
        isredirtRanges: true,
        Group: obj.groupedflag ? obj.groupedflag : null,
        groupMessage: obj.groupMessage ? obj.groupMessage : "",
        fiftyk: obj.fiftyk ? "Filtered On Properties Max Amount Greater than $50k" : "",
        unknown: obj.unknown ? "Filtered On Unknown Properties In Single State For A Single Name" : "",
        Count10kTo50k: obj.Count10kTo50k ? "Filtered On Properties Max Amount Between $10k and $50k" : ""

      })
    }
    // if (obj.groupFlag) {
    //   await this.setState({

    //     selectedGroup: obj.groupFlag ? [obj.groupFlag] : ["Group 2"],

    //   })
    // }

    if (obj.isValidate) {
      await this.setState({
        isValidate: true,
      })
    }
    if (obj.redirectedGroup) {
      this.handleChangeSelectedGroup(obj.redirectedGroup)
    }
    if (obj.taskID) {
      this.getTaskIDs(obj.taskID)
    }
    await this.setState({
      isSharing: true,
      sharedobj: obj
    })
    if (obj.selectedProperty_Id) {
      await this.setState({
        Property_Id: obj.selectedProperty_Id
      })
    }
    if (obj.selectedOpraStId) {
      await this.setState({
        OpraStId: obj.selectedOpraStId
      })
    }
    if (obj.isexcludeValidated && obj.isexcludeValidated === "true") {
      await this.setState({
        isexcludeValidated: true,
      })
    } else {
      await this.setState({
        isexcludeValidated: false,
      })
    }
    if (obj.excludeNotConfirmedOnSite && obj.excludeNotConfirmedOnSite === "true") {
      await this.setState({
        excludeNotConfirmedOnSite: true,
      })
    } else {
      await this.setState({
        excludeNotConfirmedOnSite: false,
      })
    }
    if (obj.isexcludeProperties) {
      await this.setState({
        isexcludeProperties: obj.isexcludeProperties,
      })
    }
    if (obj.isexcludePossiblyPaid && obj.isexcludePossiblyPaid === "true") {
      await this.setState({
        isexcludePossiblyPaid: true,
      })
    } else {
      await this.setState({
        isexcludePossiblyPaid: false,
      })
    }
    if (obj.isexcludeRoyalties) {
      await this.setState({
        isexcludeRoyalties: true
      })
    } else {
      await this.setState({
        isexcludeRoyalties: false
      })
    }
    if (obj.excludeCoOwners && obj.excludeCoOwners === "true") {
      await this.setState({
        excludeCoOwners: true,
      })
    } else {
      await this.setState({
        excludeCoOwners: false,
      })
    }

    if (obj.states && obj.states !== "null") {
      let decodedStates = obj.states.split(",")
      await this.setState({
        selectedStates: decodedStates
      })
    }
    if (obj.selectedPropertyStatus) {
      let decodedStatus = obj.selectedPropertyStatus.split(",")
      let eventt = {}
      eventt.value = decodedStatus
      // await this.setState({ selectedPropertyStatus: decodedStatus })
      this.handleChange(eventt, "selectedPropertyStatus")
    }
    if (obj.selectedPropertyReasons) {
      let decodedStatus = obj.selectedPropertyReasons.split(",")
      let eventt = {}
      eventt.value = decodedStatus
      // await this.setState({ selectedPropertyStatus: decodedStatus })
      this.handleChange(eventt, "selectedPropertyReasons")
    }
    if (obj.dataSourceRedirect && obj.dataSourceRedirect.length > 0) {
      await this.setState({
        dataSource: obj.dataSourceRedirect.split(",")
      })
    } else {
      await this.setState({ dataSource: [] })
    }

    if (obj.selectedPropertyReasonsForResource) {
      let eventt = {}
      eventt.value = obj.selectedPropertyReasonsForResource.split(",")
      // await this.setState({ selectedPropertyStatus: decodedStatus })
      this.handleChange(eventt, "selectedPropertyReasons")
    }
    // if (!obj.selectedProperty_Id) {
    this.handleChange(this.state.selectedStates, "State")
    this.searchsellerItems(obj.selectedSeller.EstateID, obj)
    // }
  }



  setGoButton = () => {
    this.setState({ setGoButton: false })
  }

  redircttope = () => {
    this.props.getUrlbyParams()
  }
  getReasons = async (val) => {
    let ReasonsArray = [];
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'Status', value: val, type: 'in', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
    return fetchMethodRequest('GET', `${apiCalls.PropertyReasons}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyReasons"].length > 0) {
          let propertyReasons = response["propertyReasons"]
          for (let reason of propertyReasons) {
            ReasonsArray.push({ label: reason.reason, value: reason.reason })
          }
          await this.setState({ shownreasons: ReasonsArray })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  lastUpdatedDate = (e) => {
    this.setState({ lastUpdatedDate: dateFormats.formatDate(e, "MM/DD/YYYY") })
  }
  getSellerName = async (val) => {
    return fetch('GET', `${apiCalls.propertysellers}?dealID=${val}`)
      .then(async (response) => {
        if (response && response["sellers"].length > 0) {
          let sellers = `${response["sellers"][0].DealType} - ${response["sellers"][0].SellerNickName} (${response["sellers"][0].DealYear ? response["sellers"][0].DealYear : ""})`
          this.onFilterChanges('CompanyID', response["sellers"][0])
          await this.setState({
            PropertiesSellerEstateName: sellers,
            // selectedDeal: response["sellers"][0], 
            selectedDeal: sellers,
            EstateDetails: response["sellers"][0]
          })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  getReasonForEditor = async (val) => {
    let ReasonsArray = [];
    return fetch('GET', `${apiCalls.P}`)
      .then(async (response) => {
        if (response && response["propertyReasons"].length > 0) {
          let propertyReasons = response["propertyReasons"]
          for (let reason of propertyReasons) {
            ReasonsArray.push({ label: reason.reason, value: reason.reason })
          }
          await this.setState({ ReasonsArray: ReasonsArray })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  ClearValues = () => {
    this.setState({ coutntValue: "", propCount: 0, selectedState: "", ClaimName: "", selectedName: "", selectedSeller: "", })
    this.props.clearData()
  }

  handleChangeSelectedGroup = async (e, type) => {
    await this.setState({
      selectedGroup: type == "Delete" ? "" : e.value
    })
  }

  submitValues = (values) => {
    let Object = {
      // selectedSeller: {},
      selectedName: this.state.selectedNamearr ? this.state.selectedNamearr : "",
      selectedStatus: this.state.selectedStatus,
      selectedAddress: this.state.selectedAddress,
      selectedState: this.state.selectedState,
      selectedBucket: this.state.selectedBucket,
      // selectedProperty_Id: this.state.Property_Id,
    }
    if (this.props.type === "Up Prop Confirmation") {
      let data = {
        EstateName: this.state.selectedSeller.EstateName,
        EstateID: this.state.selectedSeller.EstateID
      }

      Object.selectedSeller = data
    }
    if (this.state.coutntValue) {
      // let propertyId = this.state.serverCount.replaceAll("\n", ",");
      let propertyId = this.state.serverCount.map(Number);

      Object.coutntValue = propertyId
    }
    if (this.state.selectedState) {
      Object.selectedState = this.state.selectedState
    }

    this.props.sendUpPropConfirmation(Object)
  }

  showRecordsCount = (type, field) => {
    let newNameIssueCodes = this.props.IssueCodes.newNameIssueCodes
    let nameIssueCodes = this.props.IssueCodes.nameIssueCodes
    if (type === "show" && field === "nameIssueCodes" && nameIssueCodes && nameIssueCodes.length === 0) {
      this.setState({
        isShownameIssueCodes: true
      })
    } else if (type === "hide" && field === "nameIssueCodes") {
      this.setState({
        isShownameIssueCodes: false
      })
    } else if (type === "show" && field === "newNameIssueCodes" && newNameIssueCodes && newNameIssueCodes.length === 0) {
      this.setState({
        isShownewNameIssueCodes: true
      })
    } else if (type === "hide" && field === "newNameIssueCodes") {
      this.setState({
        isShownewNameIssueCodes: false
      })
    }
  }
  submit = (values) => {
    if (this.state.groupMessage && this.state.filterCriteria) {
      let filtercriteria = this.state.filterCriteria
      let index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyMaxValueAmount")
      if (index !== -1) {
        filtercriteria["criteria"].splice(index, 1)
      }
      this.setState({ filterCriteria: filtercriteria })
    }

    this.setState({ isredirtRanges: false, groupMessage: "", fiftyk: "", unknown: "", Count10kTo50k: "" })
    // var newArray = this.props.allUsersData.filter(function (el) {
    //   return el.edited === "yes"
    // });

    if (this.state.selectedAnalyst && this.props.type === "ClaimsManagement") {
      let filtercriteria = this.state.filterCriteria
      if (filtercriteria["criteria"].length > 0) {
        let index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst" && obj.type === "in")
        if (index === -1)
          this.handleChange(this.state.selectedAnalyst, "Analyst")
      } else {
        this.handleChange(this.state.selectedAnalyst, "Analyst")
      }
    }
    if (!this.state.nameIssueCode && this.props.IssueCodes && this.props.IssueCodes.defaultIssueNames) {
      this.handleChange(this.props.IssueCodes.defaultIssueNames, "nameIssueCode")
    }
    const str = this.state.OpraStId ? this.state.OpraStId : null;
    var result = str ? str.split(/\r?\n/) : null;
    const str2 = this.state.StatePropertyID ? this.state.StatePropertyID : null;
    var result2 = str2 ? str2.split(/\r?\n/) : null;
    const opraClaimIdsstr = this.state.OpraClaimId ? this.state.OpraClaimId : null;
    var OpraclaimIdsresult = opraClaimIdsstr ? opraClaimIdsstr.split(/\r?\n/) : null;
    const opraStClaimIdsstr = this.state.stClaimId ? this.state.stClaimId : null;
    var OprastclaimIdsresult = opraStClaimIdsstr ? opraStClaimIdsstr.split(/\r?\n/) : null;
    const str1 = this.state.Property_Id ? this.state.Property_Id : null;
    var result1
    // if ((this.state.taskProperties && this.state.taskProperties.Property_Id) || this.state.selectedTaskId) {
    //   result1 = typeof str1;
    // } else {
    result1 = typeof str1 === "string" ? str1.split(/\r?\n/) : str1;

    // }

    let Object = {
      // selectedSeller: {},
      selectedName: this.state.selectedNamearr ? this.state.selectedNamearr : "",
      selectedStatus: this.state.selectedStatus,
      selectedAddress: this.state.selectedAddress,
      selectedState: this.state.selectedState,
      selectedBucket: this.state.selectedBucket,
      groupFlag: this.state.selectedGroup ? this.state.selectedGroup.flagName : null,
      // selectedProperty_Id: this.state.Property_Id,
      selectedProperty_Id: result1 ? result1 : null,
      // selectedOpraStId: this.state.OpraStId
      selectedOpraStId: result ? result : null,
      setectedStOpraId: result2 ? result2 : null,
      nameIssueCode: this.state.isDeleteNameIssue ? "delete" : this.state.nameIssueCode
    }
    if (this.state.selectedTaskId) {
      Object.taskID = this.state.selectedTaskId
    }

    this.setState({ setGoButton: true, isredirtRanges: false })
    if (this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies") {
      let data = {
        EstateName: this.state.selectedSeller.EntityName,
        EstateID: this.state.selectedSeller.DealID
      }
      Object.selectedSeller = data
    }
    else if (this.props.type === "Claims" || this.props.type === "ClaimsManagement") {
      let data = {
        EstateID: this.state.EstateID,
        selectedClaimStatus: this.state.selectedClaimStatus,
        selectedOpraStClaimId: OprastclaimIdsresult ? OprastclaimIdsresult : null,
        EstateName: this.state.selectedSeller ? this.state.selectedSeller.EstateName : null,
        selectedOpraClaimIds: OpraclaimIdsresult ? OpraclaimIdsresult : null,
        selectedOpraStId: result ? result : null,
        setectedStOpraId: result2 ? result2 : null
      }
      if (this.state.ClaimName && this.state.ClaimName.nameID) {
        data.BusinessNameID = this.state.ClaimName.nameID
      }
      Object.selectedClaims = data
    }
    else if (this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
      let data = {
        EstateID: this.state.selectedSeller.CompanyID
      }
      Object.selectedSeller = data
    }
    else {
      Object.selectedSeller = this.state.taskProperties ? this.state.taskProperties.PropertiesSellerName : this.state.selectedSeller
    }
    if (this.state.isexcludeProperties) {
      Object.isexcludeProperties = this.state.isexcludeProperties
    }
    if (this.state.isexcludePossiblyPaid) {
      Object.isexcludePossiblyPaid = true
    }
    if (this.state.isexcludeRoyalties) {
      Object.isexcludeRoyalties = true
    }
    if (this.state.excludeCoOwners) {
      Object.excludeCoOwners = true
    }
    if (this.state.excludeNotConfirmedOnSite) {
      Object.excludeNotConfirmedOnSite = true
    }
    if (this.state.isexcludeValidated) {
      Object.isexcludeValidated = true
    }
    if (this.state.isValidate) {
      Object.isValidate = true
    }
    if (this.props.type === "Negative Notice List Viewer") {
      Object.excludeNotConfirmedOnSite = false
    }
    if (this.state.NextActionDate) {
      Object.NextActionDate = this.state.NextActionDate
    }
    if (this.props.type === "Property Editor") {
      let filtercriteria = this.state.filterCriteria
      let index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.dataSourceArrayNew && this.state.dataSourceArrayNew.length > 0 && this.state.dataSource && this.state.dataSource.length > 0) {

        filtercriteria["criteria"].push({
          key: "PropertyDataSource",
          value: this.state.dataSourceArrayNew,
          type: "in",
          add_Filter: true
        })
      }
      this.setState({ filterCriteria: filtercriteria })
    } else {
      let filtercriteria = this.state.filterCriteria
      let index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      this.setState({ filterCriteria: filtercriteria })
    }
    if ((this.props.type === "Negative Notice List Viewer" && this.state.selectedtask) || this.props.type !== "Negative Notice List Viewer") {
      this.props.getDataFromServer(Object, this.state.filterCriteria, "this.state.isChangeHit", "setGoButton", this.props.type === "ClaimsManagement" ? this.state.filteredclaimSuggestions : this.state.filterednameSuggestions)
    } else {
      this.props.getErrorMessage("Please select Notice list")
    }

  }

  clearFilters = async () => {
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    let criteriaData = this.props.filterCriteria
    await this.setState({
      selectedSeller: "", StatePropertyID: "", OpraStId: "", stClaimId: "", OpraClaimId: "", mctSourceName: [], TrackingStatus: ["Active"], selectedDepartment: [], NextActionUser: [], NextActionDate: [], ClaimStatusFilter: [], ClaimReasonFilter: [],
      filterCriteria: {
        criteria: [{ "key": "ASSET_TRACKING_STATUS", "value": ["Active"], "type": "in", "add_Filter": true }], "sortfield": criteriaData.sortfield,
        "direction": criteriaData.direction
      },
      filters: {
        Source: null,
        ASSET_TRACKING_STATUS: ["Active"],
        Analyst: null,
        ClaimStatus: null,
        ClaimReason: null,
        CompanyID: null,
        StateClaimID: '',
        OppClaimID: '',
        StatePropertyID: '',
        PropertyID: '',
        nextActionDateFilter: null
      },
      selectedDeal: null

    })
    let Object = {}
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let DepartmentArray = [], DepartmentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      DepartmentArray = user.secondaryDepartments ? user.secondaryDepartments : []
      DepartmentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      DepartmentArray.push(user.department)
      DepartmentIdArray.push(user.departmentId)
      DepartmentArray = this.state.taskDepartmentItems.filter(obj => DepartmentArray.includes(obj.label)).map(obj => obj.label)
      DepartmentIdArray = this.state.taskDepartmentItems.filter(obj => DepartmentIdArray.includes(obj.departmentId)).map(obj => obj.departmentId)
      let filters = this.state.filters;
      // filters.selectedDepartment = [user.departmentId];
      filters.selectedDepartment = DepartmentIdArray
      Object.selectedDepartment = DepartmentIdArray
      // Object.selectedDepartment = [user.departmentId]
      // await this.datatableref.setDepartement(DepartmentArray)
    }



    this.setState({ selectedDepartment: DepartmentArray.length > 0 ? DepartmentArray : null, departMentIdArray: DepartmentIdArray.length > 0 ? DepartmentIdArray : null })

    // this.props.setDepartment(DepartmentArray, DepartmentIdArray)
    this.props.setSortFilters()
    this.props.setAssetTrackingStatus("Selected")
    let limit = loginCredentials.pageLimit ? loginCredentials.pageLimit : 15

    this.props.getBasicCall({ limit: limit, page: 1, criteria: [{ "key": "ASSET_TRACKING_STATUS", "value": ["Active"], "type": "in", "add_Filter": true }], sortfield: this.props.filterCriteria.sortfield, direction: this.props.filterCriteria.direction }, { pastDueFilter: this.state.filters.nextActionDateFilter, selectedDepartment: this.state.filters.selectedDepartment }, "setGoButton")


    // await this.props.getDataFromServer(Object, this.state.filterCriteria, "Preset", "setGoButton")
    // this.props.getremoveFiltersForClaims()
    // await this.props.setFilterCriteriaOnClear()

  }

  handlePEFirmChange = async (e, type, key) => {
    let index;
    let filtercriteria = this.props.filterCriteria
    if (type === "pefirm") {
      await this.setState({
        selectedSeller: e.value,
        // EstateID: e.value.EstateID,
        isChangeHit: true
      })
    }
    index = filtercriteria["criteria"].findIndex(obj => obj.key === key && obj.type === type)
    if (index !== -1) {
      filtercriteria["criteria"].splice(index, 1)
    }
    this.setGoButton()
    await this.setState({
      filterCriteria: filtercriteria
    })
  }
  showCOunt = async (e, type) => {
    var val = JSON.stringify(e.target.value)
    val = val.replace(/\\n/g, '-')
    val = val.replace(/\,/g, '-')
    val = val.replace(/\"/g, '')
    let count = val.split("-")
    // let count = val.split("-").length
    // if (count2[count - 1].length === 0) {
    //   count2.pop()
    // }
    count = count.filter(u => u != "")


    await this.setState({ coutntValue: e.target.value, propCount: count.length, serverCount: count })
  }
  selectedViewType = async (e) => {

    await this.setState({
      selectedViewType: e
    })
    await this.props.selectedViewType(e)
  }

  copyToClipboard = async (data) => {
    navigator.clipboard.writeText(data)
  }
  selectionDeal = async (e) => {
    if (e.value && e.value.SellerNickName) {
      this.setState({ selectedDeal: `${e.value.DealType} - ${e.value.SellerNickName} (${e.value.DealYear ? e.value.DealYear : ""})` })
    } else {
      this.setState({ selectedDeal: e.value })
    }
  }

  onFilterChanges = async (key, value) => {
    if (key === "StatePropertyID" || key === "PropertyID") {
      // value = value.replace(/[a-zA-Z]/g, '')
      value = value.replace(/[^0-9\s]/g, '')
    }
    if (key === "nextActionDateFilter") {
      this.props.setSortFilters(key)

    }
    let enteredValue = key === 'CompanyID' || key === "nextActionDateFilter" ? value : value && value.length > 0 ? value : '';
    this.setState(prevState => ({
      filters: { ...prevState.filters, [key]: enteredValue }
    }));
    if (key === 'ClaimStatus') {
      await this.onFilterChanges("ClaimReason", "")
      if (value && value.length > 0) {
        let selClaimStatus = {};
        for (let i = 0; i < value.length; i++) {
          selClaimStatus[value[i]] = value[i];
        }
        const claimReason = this.state.propertyStatusReasons.filter((obj) => selClaimStatus[obj.status]).map((res) => ({ label: res.reason, value: res.reason }));
        this.setState({ claimReasonArray: claimReason });
      } else {
        this.setState({ ClaimReason: "", claimReasonArray: [] });
      }
    } else if (key === 'CompanyID' && !value) {
      this.setState({ selectedDeal: null });
    } else if (key === "ASSET_TRACKING_STATUS") {
      if (value && value.length > 0) {
        this.props.setAssetTrackingStatus("Selected")
      } else {
        this.props.setAssetTrackingStatus("Empty")
      }

    }
  }

  onClickFilter = () => {
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    let filterData = {
      Source: this.state.filters.Source,
      ASSET_TRACKING_STATUS: this.state.filters.ASSET_TRACKING_STATUS,
      Analyst: this.state.filters.Analyst,
      ClaimStatus: this.state.filters.ClaimStatus,
      ClaimReason: this.state.filters.ClaimReason,
      CompanyID: this.state.filters.CompanyID && this.state.filters.CompanyID.EstateID ? this.state.filters.CompanyID.EstateID : null,
      StateClaimID: this.state.filters.StateClaimID ? this.state.filters.StateClaimID.split((/\r?\n/)).map(obj => encodeURIComponent(obj.trim())) : null,
      OppClaimID: this.state.filters.OppClaimID ? this.state.filters.OppClaimID.split((/\r?\n/)).map(obj => encodeURIComponent(obj.trim())) : null,
      StatePropertyID: this.state.filters.StatePropertyID ? this.state.filters.StatePropertyID.split((/\r?\n/)).map(obj => obj.trim()).filter(u => u != "") : null,
      PropertyID: this.state.filters.PropertyID ? this.state.filters.PropertyID.split((/\r?\n/)).map(obj => obj.trim()).filter(u => u != "") : null
    }
    let criteriaData = this.props.filterCriteria
    let filterCriteria = [];
    for (const prop in filterData) {
      if (filterData[prop]) {
        const filterKey = (prop === 'PropertyID' || prop === 'StatePropertyID') ? `ConnectedProperties.${prop}` : prop;
        filterCriteria.push({ key: filterKey, value: filterData[prop], add_Filter: true, type: prop === 'CompanyID' ? 'eq' : prop === 'StateClaimID' ? 'orin' : 'in' });
      }
    }
    let limit = loginCredentials.pageLimit ? loginCredentials.pageLimit : 15
    this.props.getBasicCall({ criteria: filterCriteria, sortfield: criteriaData.sortfield, direction: criteriaData.direction, limit: limit, page: 1 }, { pastDueFilter: this.state.filters.nextActionDateFilter, selectedDepartment: this.state.filters.selectedDepartment }, "setGoButton")
    // this.props.getBasicCall({ selectedClaims: this.state.filters.CompanyID, NextActionDate: this.state.filters.nextActionDateFilter, selectedDepartment: this.state.filters.selectedDepartment }, { criteria: filterCriteria, sortfield: criteriaData.sortfield, direction: criteriaData.direction }, "Preset", "setGoButton");
  }

  getCloseIcon = (field) => {
    return <FontAwesomeIcon icon={faTimes}
      className='pl-1 mr-2 clearIcon'
      color={this.state.filters[field] ? 'red' : 'lightgrey'}
      data-toggle="tool-tip"
      title="Clear Filter"
      id="clear stClaimId"
      fontSize={'18'}
      onClick={() => this.onFilterChanges(field, '')}
      style={{ width: 'unset', cursor: "pointer", float: "right", pointerEvents: `${this.state.filters[field] ? 'auto' : 'none'}` }}
    />
  }

  getLabel = (label, field) => {
    return <label className='d-flex justify-content-between mb-0'>
      <span>{label}</span>
      {this.getCloseIcon(field)}
    </label>
  }

  handleChange = async (e, type, key) => {

    await this.setState({
      isDeleteNameIssue: false
    })
    let index;
    let filtercriteria = this.props.filterCriteria
    if (type === "Seller") {
      await this.setState({
        // selectedSeller: e.value,
        // EstateID: e.value.EstateID,
        selectedSeller: e.EstateName && e.EstateID ? e : e.value,
        EstateID: e && e.value && e.value.EstateID ? e.value.EstateID : e.EstateID ? e.EstateID : "",
        // selectedSeller: e.value ? e.value : e.EstateName,
        // EstateID: e.value && e.value.EstateID ? e.value.EstateID : e.EstateID,
        selectedName: "",
        selectedStatus: "",

        selectedAddress: "",
        setGoButtonClaims: false,
        isChangeHit: true,
        selectedGroup: this.props.tabDescriptionArray && this.props.tabDescriptionArray[0] && this.props.tabDescriptionArray[0].value ? this.props.tabDescriptionArray[0].value : ""

      })
      if (this.props.type === "SearchTerms") {
        await this.setState({
          // search
          selectedSearchTerm: "",
          showSearchTable: false,
          errorResponse: false,
          errorMessage: ''
        })
      }
      if (this.props.type === 'Negative Notice List Viewer' || this.props.type === "Site Confirmation Editor") {
        if (this.state.EstateID !== null && this.state.EstateID !== "") {
          this.getNegativeNoticeList(this.state.EstateID)

        }
      }
      if (this.props.type === "ClaimsManagement" && this.state.EstateID) {
        this.searchClaimNamesItems()
      }
      this.props.getErrorMessage("Please Select Seller", "selectedSeller")
      if (this.props.type === "Properties") {
        await this.setState({
          selectedBucket: "Overall Near Exact"
        })
      }
      this.searchnameItems()
      this.setGoButton()
      // filtercriteria["criteria"] = []
      this.props.change('Name', '')
    } if (type === "ClaimName") {
      await this.setState({
        ClaimName: e.value,
        selectedName: "",
        selectedStatus: "",
        selectedAddress: "",
        selectedState: "",
        isChangeHit: true
      })
      this.setGoButton()
      this.props.change('Name', '')
    }


    else if (type === "MctSource") {
      if (e && e.value) {
        await this.setState({
          mctSourceName: e.value
        })
      } else {
        await this.setState({
          mctSourceName: e,
        })
      }
      this.setGoButton()
      if (this.props.type === "Claims" || this.props.type === "ClaimsManagement") {
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "Source" && obj.type === "in")
      }
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.mctSourceName && this.state.mctSourceName.length > 0)
        filtercriteria["criteria"].push({
          key: "Source",
          value: this.state.mctSourceName,
          type: "in",
          add_Filter: true
        })      // this.submit()
    }
    else if (type === "ClaimReasonFilter") {
      if (e && e.value) {
        await this.setState({
          ClaimReasonFilter: e.value
        })
      } else {
        await this.setState({
          ClaimReasonFilter: e,
        })
      }
      this.setGoButton()
      if (this.props.type === "Claims" || this.props.type === "ClaimsManagement") {
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimReason" && obj.type === "in")
      }
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.ClaimReasonFilter && this.state.ClaimReasonFilter.length > 0)
        filtercriteria["criteria"].push({
          key: "ClaimReason",
          value: this.state.ClaimReasonFilter,
          type: "in",
          // add_Filter: true
        })      // this.submit()
    }
    else if (type === "ClaimStatusFilter") {
      if (e && e.value) {
        await this.setState({
          ClaimStatusFilter: e.value
        })
      } else {
        await this.setState({
          ClaimStatusFilter: e,
        })
      }
      // if (!this.state.selectedSeller && this.state.selectedState.length !== 0) {
      //   this.props.getErrorMessage("Please Select Seller", "selectedSeller")
      // }

      // Claim Status Dependent Claim Status
      let claimReasonObj = this.state.propertyStatusReasons.filter((obj => this.state.ClaimStatusFilter.includes(obj.status)));
      this.setState({ claimReasonArray: claimReasonObj.map(obj => ({ label: obj.reason, value: obj.reason })) });

      this.setGoButton()
      if (this.props.type === "Claims" || this.props.type === "ClaimsManagement") {
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimStatus" && obj.type === "in")
      }
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.ClaimStatusFilter && this.state.ClaimStatusFilter.length > 0)
        filtercriteria["criteria"].push({
          key: "ClaimStatus",
          value: this.state.ClaimStatusFilter,
          type: "in",
          // add_Filter: true
        })      // this.submit()
    }


    else if (type === "NextActionDate") {
      if (e && e.value) {
        await this.setState({
          NextActionDate: e.value
        })
      } else {
        await this.setState({
          NextActionDate: e,
        })
      }
      // if (!this.state.selectedSeller && this.state.selectedState.length !== 0) {
      //   this.props.getErrorMessage("Please Select Seller", "selectedSeller")
      // }
      this.setGoButton()
      // if (this.props.type === "Claims" || this.props.type === "ClaimsManagement") {
      //   index = filtercriteria["criteria"].findIndex(obj => obj.key === "Next_Action_Date" && obj.type === "in")
      // }
      // if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      // if (this.state.NextActionDate && this.state.NextActionDate.length > 0)
      //   filtercriteria["criteria"].push({
      //     key: "Next_Action_Date",
      //     value: this.state.NextActionDate,
      //     type: "dategt",
      //     add_Filter: true
      //   })      // this.submit()
    }

    else if (type === "NextActionUser") {
      if (e && e.value) {
        await this.setState({
          NextActionUser: e.value,
        })
      } else {
        await this.setState({
          NextActionUser: e,
          setGoButton: true
        })
      }
      // if (this.state.selectedSeller !== "" && this.state.selectedState.length !== 0) {
      //   this.setGoButton()
      // }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst" && obj.type === "in")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.NextActionUser && this.state.NextActionUser.length > 0)
        filtercriteria["criteria"].push({
          key: "Analyst",
          value: this.state.NextActionUser,
          type: "in",
          add_Filter: true
        })      // this.submit()
    }
    else if (type === "TrackingStatus") {
      if (e && e.value) {
        await this.setState({
          TrackingStatus: e.value,
        })
      } else {
        await this.setState({
          TrackingStatus: e,
          setGoButton: true
        })
      }
      // if (this.state.selectedSeller !== "" && this.state.selectedState.length !== 0) {
      //   this.setGoButton()
      // }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "ASSET_TRACKING_STATUS" && obj.type === "in")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.TrackingStatus && this.state.TrackingStatus.length > 0)
        filtercriteria["criteria"].push({
          key: "ASSET_TRACKING_STATUS",
          value: this.state.TrackingStatus,
          type: "in",
          add_Filter: true
        })      // this.submit()
    }
    else if (type === "department") {
      if (e && e.value) {
        await this.setState({
          selectedDepartment: e.value,
        })
        let DepartmentArray = [], departmentIdArray = []
        // let user = JSON.parse(localStorage.getItem('loginCredentials'));
        for (let id of e.value) {
          // if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
          let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
          if (z) {
            DepartmentArray.push(z.department)
            departmentIdArray.push(z.departmentId)
          }
          // }
        }
        this.props.setDepartment(DepartmentArray, departmentIdArray)
      } else {
        await this.setState({
          selectedDepartment: e,
          setGoButton: true
        })
        let DepartmentArray = [], departmentIdArray = []
        // let user = JSON.parse(localStorage.getItem('loginCredentials'));
        for (let id of e) {
          // if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
          let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
          if (z) {
            DepartmentArray.push(z.department)
            departmentIdArray.push(z.departmentId)
          }
          // }
        }
        this.props.setDepartment(DepartmentArray, departmentIdArray)

      }
      // if (this.state.selectedSeller !== "" && this.state.selectedState.length !== 0) {
      //   this.setGoButton()
      // }
      // index = filtercriteria["criteria"].findIndex(obj => obj.key === "department" && obj.type === "in")
      // if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      // if (this.state.selectedDepartment && this.state.selectedDepartment.length > 0)
      //   filtercriteria["criteria"].push({
      //     key: "department",
      //     value: this.state.selectedDepartment,
      //     type: "in",
      //     add_Filter: true
      //   })      // this.submit()
    }

    else if (type === "ClaimStatus") {
      if (e && e.value) {
        await this.setState({
          selectedClaimStatus: e.value
        })
      } else {
        await this.setState({
          selectedClaimStatus: e
        })
      }
      this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimStatus" && obj.type === "in")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedClaimStatus && this.state.selectedClaimStatus.length > 0)
        filtercriteria["criteria"].push({
          key: "ClaimStatus",
          value: this.state.selectedClaimStatus,
          type: "in",
          add_Filter: true
        })      // this.submit()

    }

    else if (type === "Property_Id") {
      await this.setState({
        Property_Id: e ? e : null
      })
      this.setGoButton()
    } else if (type === "OpraStId") {
      await this.setState({
        OpraStId: e ? e : ""
      })
      this.setGoButton()
    } else if (type === "StatePropertyID") {
      await this.setState({
        StatePropertyID: e ? e : null
      })
      this.setGoButton()
    } else if (type === "OpraClaimId") {
      await this.setState({
        OpraClaimId: e ? e : null
      })
      this.setGoButton()
    } else if (type === "stClaimId") {
      await this.setState({
        stClaimId: e ? e : null
      })
      this.setGoButton()
    } else if (type === "Delete") {
      if (e === "selectedSeller") {
        await this.setState({
          // selectedName: "",
          // selectedStatus: "",
          // nameIssueCode: [],
          // // dataSource: [],
          // selectedAddress: "",
          // selectedNamearr: "",
          // selectedState: this.props.type === "ClaimsManagement" ? this.state.selectedState : [],
          // selectedState: this.props.type === "ClaimsManagement" ? this.state.selectedState : "",
          selectedSeller: "",
          // modifiedAddress: [],
          // setGoButtonClaims: true,
          // selectedBucket: "",
          EstateID: "",
          // stClaimId: "",
          // selectedPropertyStatus: "",
          // selectedPropertyReasons: "",
          // OpraClaimId: "",
          // filterednameSuggestions: "",
          // StatePropertyID: "",
          // ClaimName: "",
          // selectedtask: "",
          // filterednameSuggestions: "",
          // OpraStId: "",
          // Property_Id: "",
          // isexcludeRoyalties: false,
          // isexcludePossiblyPaid: false,
          // isexcludeRoyalties: true,
          // excludeCoOwners: false,
          // isexcludeProperties: "",
          // // excludeNotConfirmedOnSite: false,
          // excludeCoOwners: false,
          // selectedClaimStatus: "",
          // newNameIssueCode: "",
          // nameIssueCode: "",
          // filterednameSuggestions: []
          // dataSource: ""
        })
        // filtercriteria["criteria"] = []

        if (this.props.type === "ClaimsManagement" && this.state.selectedState.length !== 0) {
          this.handleChange(this.state.selectedState, "State")
        }
        // this.submit()
        if (this.props.type === "ClaimsManagement" && this.state.selectedState.length === 0) {
          this.props.getErrorMessage("Please Select Seller or State", "removingSeller", "error")
        } else if (this.props.type !== "ClaimsManagement") {
          this.props.getErrorMessage("Please Select Seller", "removingSeller", "error")
        }
        // this.props.getFileOptions([])
      } else if (e === "selectedName") {
        await this.setState({
          selectedName: "",
          selectedNamearr: "",
        })
      }
      // else if (e === "ClaimName") {
      //   await this.setState({
      //     ClaimName: "",
      //   })
      // } 
      else {
        await this.setState({
          [e]: "",
        })
        if (e === "dataSource") {
          await this.setState({
            dataSource: [],
            // isDeleteNameIssue: true
          })
        } else
          if (!this.state.selectedSeller && this.state.selectedState.length === 0 && this.props.type === "ClaimsManagement") {
            this.props.getErrorMessage("Please Select Seller or State", "removingSeller", "error")
          }
        let type;
        if (e === "OpraClaimId" || e === "OpraStId" || e === "stClaimId" || e === "PropertyID" || e === "selectedState" || e === "selectedStatus" || e === "StatePropertyID" || e === "selectedAnalyst" || e === "selectedClaimStatus" || e === "newNameIssueCode" || e === "nameIssueCode") {
          type = "in"
        } else {
          type = "eq"
        }
        if ((this.props.type === "Property Editor" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor") && key === "property_StateId") {
          let kindex = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState" && obj.type === "in")
          if (kindex !== -1) {
            filtercriteria["criteria"].splice(kindex, 1)
          }
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
        } else if (e === "ClaimStatusFilter") {
          this.handleChange('ClaimReasonFilter', "Delete", "ClaimReason", "ClaimReason")
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimStatus")
        } else if (e === "ClaimReasonFilter") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimReason")
        } else if (this.props.type === "Deal Sizing-Properties" || this.props.type === "ClaimsManagement" && key === "property_StateId") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
        } else if (key === "newNameIssueCode") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "newNameIssueCode")
        } else if (key === "nameIssueCode") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "nameIssueCode")
        } else if (key === "dataSource") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        } else if (key === "selectedPropertyStatus") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyStatus")
        } else if (key === "selectedPropertyReasons") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyStatusReason")
        } else if (e === "NextActionUser") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst")
        } else if (e === "TrackingStatus") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "ASSET_TRACKING_STATUS")
        }
        else if (e === "department") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "department")
        } else if (key === "NextActionDate") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "NextActionDate")
        } else if (key === "Source") {
          this.setState({ mctSourceName: [] });
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "Source")
        } else {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === key && obj.type === type)
        }
        if (index !== -1) {
          filtercriteria["criteria"].splice(index, 1)
        }
      }

      this.setGoButton()
    }

    await this.setState({
      isChangeHit: true,
      ClaimReasonFilter: this.state.ClaimStatusFilter && this.state.ClaimStatusFilter.length != 0 ? this.state.ClaimReasonFilter : null,
      filterCriteria: filtercriteria
    })

  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  getSessionWraningModal() {

    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.submit}
      />
    )
  }
  CompanyItems = async (event, criteria) => {

    let apiUrl
    let filterCriteria = this.state.filterCriteria
    filterCriteria["criteria"] = [{
      key: "CompanyName",
      value: event && event.query ? event.query : "",
      type: "sw",
      add_Filter: true
    }]
    // filterCriteria.globalSearch = { value: event && event.query ? event.query : "", "type": "user" }
    filterCriteria.sortfield = "CompanyName"
    filterCriteria.direction = "asc"
    if (event) {
      apiUrl = `${apiCalls.companiesdropdown}?filter=${JSON.stringify(filterCriteria)}`
    } else {
      apiUrl = `${apiCalls.companiesdropdown}?filter=${JSON.stringify(filterCriteria)}`

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["companies"] && response["companies"].length && response["companies"].length > 0) {
            dropdownData = response["companies"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)

          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownData;
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
            // this.searchnameItems(criteria)
          }

        }
      }).catch((err) => {
        return err;
      });
  }

  searchsellerItems = async (event, criteria) => {

    let apiUrl
    let filterCriteria = this.state.filterCriteria
    if (event) {
      if (this.props.type !== "Deal Sizing-Properties" && this.props.type !== "OPRA2 Property Editor" && this.props.type !== "Deal Sizing Report For Companies") {
        if (event.query) {
          apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query)}`
        } else if (typeof event === "string" && event.length > 0) {
          apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event)}`
        } else {
          apiUrl = `${apiCalls.propertysellers}`
        }
      } else {
        filterCriteria["criteria"] = [{
          key: "EntityName",
          value: event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : "",
          type: "regexOr"
        }]
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "EntityName")
        if (this.props.type === "Deal Sizing Report For Companies") {
          filterCriteria.sortfield = "EntityName"
          filterCriteria.page = "1"
        }
        if (filterCriteria["criteria"][index].value.length > 0) {
          apiUrl = `${apiCalls.Seller}?filter=${JSON.stringify(filterCriteria)}`
        } else {
          apiUrl = `${apiCalls.Seller}`
        }
      }
    } else {
      if (this.props.type !== "Deal Sizing-Properties" && this.props.type !== "OPRA2 Property Editor" && event) {
        apiUrl = `${apiCalls.propertysellers}?str = ${encodeURIComponent(event.query ? event.query : event)} `
      } else {
        apiUrl = `${apiCalls.Seller} `
      }
    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })

            this.searchnameItems(criteria)
          }
          if (this.props.type === "ClaimsManagement") {
            this.searchClaimNamesItems()
          }

        }
      }).catch((err) => {
        return err;
      });
  }

  searchClaimNamesItems = async (event, criteria) => {

    let apiUrl

    // businessNames?searchCompany=435&str="erwin"  
    if (this.state.EstateID) {
      apiUrl = `${apiCalls.Claims}/businessNames?searchCompany=${this.state.EstateID}&str=${event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : ""} `
    }
    if (apiUrl) {
      return fetch('GET', apiUrl)
        .then(async (response) => {
          if (response) {
            let dropdownData = [];
            if (response["sellernames"] && response["sellernames"].length && response["sellernames"].length > 0) {
              dropdownData = response["sellernames"]
            } else if (response && response.errorMessage) {
              this.props.getErrorResponseFromServer(response)
            }
            if (dropdownData && dropdownData.length === 0) {
              this.setState({
                filteredclaimSuggestions: [],
                noData: true
              });
            } else {
              dropdownData = dropdownData;
            }
            await this.setState({
              filteredclaimSuggestions: dropdownData
            });
            if (criteria) {
              await this.setState({
                ClaimName: dropdownData[0]
              })
              this.props.getFileOptions(dropdownData)
              // this.searchnameItems(criteria)
            }
          }
        }).catch((err) => {
          return err;
        });
    }
  }

  searchnameItems = async (event) => {

    let apiUrl
    if (this.state.selectedSeller && this.state.selectedSeller.EstateID && (this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "SearchTerms")) {
      apiUrl = `${apiCalls.propertynames}?searchCompany=${this.state.selectedSeller.EstateID} `
    } else if (this.state.selectedSeller && this.state.selectedSeller.DealID && (this.props.type === "Deal Sizing-Properties")) {
      apiUrl = `${apiCalls.dealSizingNames}?searchCompany=${this.state.selectedSeller.DealID} `
    }
    // }
    if (apiUrl) {
      return fetch('GET', apiUrl)
        .then(async (response) => {
          if (response) {
            let namedropdownData = [];
            if (response["sellernames"] && response["sellernames"].length && response["sellernames"].length > 0) {
              namedropdownData = response["sellernames"]

            } else if (response && response.errorMessage) {
              this.props.getErrorResponseFromServer(response)

            }
            if (namedropdownData && namedropdownData.length === 0) {
              this.setState({
                filterednameSuggestions: [],
                noData: true
              });
            } else {
              let modifiedLabels = []
              for (let name of namedropdownData) {
                if (name.companyName !== null || name.companyName.length > 0 || name.nameID !== null || name.nameID.length > 0) {
                  modifiedLabels.push({
                    label: name.companyName,
                    value: {
                      labelName: name.companyName,
                      id: name.nameID,
                    },
                  })
                }
              }
              namedropdownData = modifiedLabels;
            }
            if (this.state.taskProperties) {
              let data = this.state.taskProperties.PropertiesSellerName.Names // for (var name of this.state.taskProperties.PropertiesSellerName.Names) {
              var newArray = [];
              for (var item of data) {
                for (var name of namedropdownData) {
                  if (name.label === item) {
                    newArray.push(name)
                  }
                }
              }
              let ids = []
              for (var itemData of newArray) {
                ids.push(itemData.value)
              }
              this.handleChange(ids, "Name")
            }
            if (event && this.state.isSharing && this.state.sharedobj.nameId) {
              let selectedValues = []
              const arr = event.nameId.split(',');
              for (var itemArr of arr) {
                for (var named of namedropdownData) {
                  if (named.value && named.value.id === itemArr) {
                    selectedValues.push(named.value)
                  }
                }
              }
              this.handleChange(selectedValues, "Name")
            }
            this.props.getFileOptions(namedropdownData)
            await this.setState({
              filterednameSuggestions: namedropdownData
            });
          }
        }).catch((err) => {
          return err;
        });
    }
  }

  getNegativeNoticeList = async (e) => {

    let tasksArray = [];
    let body = {
      label: this.props.type === "Site Confirmation Editor" ? "Site Confirmation" : "Seller Negative Notice",
      estateID: e
    }
    return fetch('POST', `tasks/ConnectedTasks`, body)
      .then(async (response) => {
        if (response && response["tasks"].length > 0) {
          let propertyReasons = response["tasks"]
          for (let reason of propertyReasons) {
            if (this.state.selectedTaskId) {
              if (reason.taskID === this.state.selectedTaskId) {
                await this.setState({
                  reason: reason
                })
              }
            }
            tasksArray.push({ label: `${reason.taskID} - ${dateFormats.formatDate(reason.created, "MM/DD/YYYY")}`, value: reason })
          }
          await this.setState({ tasksArray: tasksArray, selectedtask: this.state.reason })
          // await this.getTaskIDs(this.state.selectedTaskId)
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  getTaskIDs = async (e, type) => {
    let filter = {}
    filter.criteria = [{ key: "taskID", value: e, type: "eq" }]
    return fetch('GET', `tasks?filter=${JSON.stringify(filter)}&ignoreUnassigned=true`)
      .then(async (response) => {
        if (response && response["tasks"].length > 0) {
          let propertyReasons = response["tasks"][0].connectedProperties
          propertyReasons = propertyReasons.join("\n");
          if (type) {
            await this.setState({
              excludeNotConfirmedOnSite: false,
            })
            this.props.openFormModal(response["tasks"][0], 'edit')
          }
          await this.setState({ selectedTaskId: e })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        } else {
          this.props.getErrorMessage("Something went wrong", "", "error")
        }
      }).catch((err) => {
        return err;
      })
  }
  handleTaskChange = async (e, type) => {
    if (type) {
      await this.setState({
        selectedtask: '',
        selectedTaskId: "",
        setGoButton: false
      })
      this.props.getErrorMessage("Please Select Notice List", "removingSeller", "error")

    } else {
      await this.setState({
        selectedtask: e,
        selectedTaskId: e.taskID,
        Property_Ids: e.connectedProperties,
        setGoButton: false
      })
      this.props.getErrorMessage("", "selectedSeller")

    }
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }
  render() {
    const { handleSubmit } = this.props;
    const { filters: { ClaimReason, ClaimStatus, Source, ASSET_TRACKING_STATUS, Analyst, selectedDepartment } } = this.state;
    let isEditted = localStorage.getItem("ISEdited") ? true : false
    return (

      <div>
        <Col md={12} lg={12} style={{ border: "1px solid lightgrey", marginBottom: 10 }}>
          <Card className="py-2 ">
            <CardBody className="p-0 row">
              {/* <form className="form" onSubmit={handleSubmit(this.submit)}> */}
              <div className='col-sm-8'>

                <div className='row'>
                  <div className='col-sm-4 mb-1'>
                    {this.getLabel('Deal', 'CompanyID')}
                    <div className='claimFilterForm'>
                      <AutoComplete
                        value={this.state.selectedDeal}
                        suggestions={this.state.filteredSuggestions}
                        completeMethod={this.searchsellerItems}
                        minLength={1}
                        id="Deal"
                        inputStyle={{ height: "32px" }}
                        field={this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies" ? "EntityName" : "EstateName"}
                        style={{ width: "100%" }}
                        dropdown={true}
                        itemTemplate={this.editorTemplateForDeal}
                        onChange={(e) => this.selectionDeal(e)}
                        onSelect={(e) => this.onFilterChanges('CompanyID', e.value)}
                      />
                    </div>
                  </div>
                  <div className='col-sm-3 mb-1'>
                    {this.getLabel('Source', 'Source')}
                    <MultiSelect
                      name={"MctSource"}
                      filter={true}
                      value={Source}
                      style={{ width: "100%", height: 32 }}
                      // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                      maxSelectedLabels={1}
                      // options={this.state.TrackingStatusArray}
                      options={this.state.mctSourceArray}
                      onChange={(e) => this.onFilterChanges('Source', e.value)}
                    />
                  </div>
                  <div className='col-sm-3 mb-1'>
                    {this.getLabel('Tracking Status', 'ASSET_TRACKING_STATUS')}
                    <MultiSelect
                      name={"TrackingStatus"}
                      filter={true}
                      value={ASSET_TRACKING_STATUS}
                      style={{ width: "100%", height: 32 }}
                      // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                      maxSelectedLabels={1}
                      // options={this.state.TrackingStatusArray}
                      options={config.assetTrackingStatusOptions}
                      onChange={(e) => this.onFilterChanges('ASSET_TRACKING_STATUS', e.value)}
                    />

                  </div>
                  <div className='col-sm-2 mb-1'>
                    {this.getLabel('Team', 'selectedDepartment')}
                    <MultiSelect
                      name={"department"}
                      filter={true}
                      value={selectedDepartment}
                      style={{ width: "100%", height: 32 }}
                      maxSelectedLabels={1}
                      options={this.state.taskDepartmentItems}
                      onChange={(e) => this.onFilterChanges('selectedDepartment', e.value)}

                    />
                  </div>

                  <div className='col-sm-4'>
                    {this.getLabel('Claim Status', 'ClaimStatus')}
                    <MultiSelect
                      name={"Claim Status Filter"}
                      filter={true}
                      value={ClaimStatus}
                      style={{ width: "100%", height: 32 }}
                      // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                      maxSelectedLabels={1}
                      options={this.state.claimStatusArray}
                      onChange={(e) => this.onFilterChanges('ClaimStatus', e.value)}

                    />
                  </div>
                  <div className='col-sm-3'>
                    {this.getLabel('Claim Reason', 'ClaimReason')}
                    <MultiSelect
                      name={"Claim Reason Filter"}
                      filter={true}
                      value={this.state.claimReasonArray && this.state.claimReasonArray.length > 0 ? ClaimReason : ""}
                      style={{ width: "100%", height: 32 }}
                      // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                      disabled={!this.state.filters.ClaimStatus}
                      maxSelectedLabels={1}
                      options={this.state.claimReasonArray}
                      onChange={(e) => this.onFilterChanges('ClaimReason', e.value)}

                    />
                  </div>
                  <div className='col-sm-3'>
                    {this.getLabel('Past Due', 'nextActionDateFilter')}
                    <Dropdown className='NextActionDate'
                      type={'text'}
                      id="NextActionDate"
                      // filter={modifiedStates.length > 6 ? true : false}
                      value={this.state.filters.nextActionDateFilter}
                      placeholder={""}
                      name="NextActionDate"
                      style={{
                        minWidth: "100%",
                        maxWidth: "100%",
                        height: 32
                      }}
                      options={[{ label: "Overdue up to 7 days", value: "0to7" }, { label: "Overdue between 8 to 14 days", value: "8to14" }, { label: "Overdue between 15 to 30 days", value: "15to30" }, { label: "Overdue more than 30 days", value: "30plus" }, { label: "Upcoming Overdue in Next 7 Days", value: "next_0_7" }]}
                      onChange={(e) => this.onFilterChanges("nextActionDateFilter", e.value)}
                    />
                  </div>

                  <div className='col-sm-2'>
                    {this.getLabel('Next Action User', 'Analyst')}
                    <MultiSelect
                      name={"NextActionUser"}
                      filter={true}
                      value={Analyst}
                      style={{ width: "100%", height: 32 }}
                      appendTo={document.body}
                      // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                      maxSelectedLabels={1}
                      options={this.state.userArray}
                      onChange={(e) => this.onFilterChanges('Analyst', e.value)}

                    />
                  </div>

                </div>
              </div>



              <div className='col-sm-3'>
                <div className='row'>
                  <div className='col-sm-6'>
                    {this.getLabel('St Claim ID', 'StateClaimID')}

                    <textarea
                      // type="search"
                      name='stClaimId'
                      id="stClaimId"
                      disabled={isEditted}
                      onChange={(e) =>
                        this.onFilterChanges('StateClaimID', e.target.value)
                      }
                      style={{ width: "100%", height: "100px" }}

                      // id='estateIdsValue'
                      value={this.state.filters.StateClaimID}
                      // onChange={(e) => this.handleChange(e.target.value, "stClaimId")}
                      placeholder={'State Claim ID'}
                      // size="10"
                      // height="100px"
                      rows="8"
                    />

                  </div>
                  <div className='col-sm-6'>
                    {this.getLabel('OPP Claim ID', 'OppClaimID')}

                    <textarea
                      // type="search"
                      name='OpraClaimId'
                      id="OpraClaimId"
                      disabled={isEditted}
                      onChange={(e) =>
                        // this.handleChange(e.target.value, "OpraStId")
                        this.onFilterChanges('OppClaimID', e.target.value)
                      }
                      style={{ width: "100%", height: "100px" }}

                      // id='estateIdsValue'
                      value={this.state.filters.OppClaimID}
                      // onChange={(e) => this.handleChange(e.target.value, "OpraClaimId")}
                      placeholder={'OPP Claim ID'}
                      // size="10"
                      // height="100px"
                      rows="8"
                    />

                  </div>
                </div>

              </div>

              <div className='col-sm-1 mt-4'>
                <Button color="primary" outline
                  className='p-1 ml-2 mt-2 mb-1'
                  size={'sm'}
                  style={{ height: 30 }}
                  // disabled={this.state.selectedSeller ? false : true}
                  onClick={(e) => this.onClickFilter()}
                >
                  <FontAwesomeIcon
                    icon={faSearch}
                    data-toggle="tool-tip" title={"Search"}
                    className='pl-1' size='lg' />
                </Button>
                <Button color="primary" outline
                  className='p-1 ml-2 mt-2 mb-1'
                  size={'sm'}
                  style={{ height: 30, width: "62px" }}
                  onClick={() => this.clearFilters()}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    data-toggle="tool-tip" title={"Clear Search Filters"}
                    className='pl-1' size='lg' />
                </Button>
              </div>
              {/* </form> */}

            </CardBody>

          </Card >
        </Col >
      </div >

    );
  }
}

export default withTranslation('common')(FollowUpFilters);