import React, { useEffect, useRef, useState } from "react";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Row
} from 'reactstrap';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
// import TableFields from './MailForwardReceivedTableFields.json';
import MailForwardsReceivedMailHistory from "../MailForwardsReceivedMailHistory/MailForwardsReceivedMailHistory";
import { Button } from 'reactstrap';
import fetchMethodRequest from "../../../config/service";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DatePicker from 'react-datepicker';
import Datatables from "../CommonDataTable/DataTable"
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import dateFormats from '../../UI/FormatDate/formatDate';
import Loader from '../../App/Loader';
import Alerts from '../DocumentUpload/components/Alerts';
import * as MailFwdService from '../../../shared/mailForwardsService';


const MailForwardsDetails = () => {
    const [activeTab, setActiveTab] = useState<number>(1);
    const [activeNotesTab, setNotesActiveTab] = useState<number>(1);
    const [mailForwardsDetails, setMailForwardsDetails] = useState<any>({});
    const [newReceivedMailData, setnewReceivedMailData] = useState<any>({});
    const [selectedNoteType,setSelectedNoteType]= useState<any>("MailForwardNote");
    const [selectedCategoryType,setselectedCategoryType]=useState<any>("MailForwardNote");
    const [notesDetails, setNotesDetails] = useState<Array<any>>([]);
    const [mftStatuses, setMftStatuses] = useState<Array<any>>([]);
    const [mftReasons, setMftReasons] = useState<Array<any>>([]);
    const [filteredReasons, setFilteredReasons] = useState<Array<any>>([]);
    const [noteValue, setNoteValue] = useState<string>("")
    const [noteCategoryTypes, setnoteCategoryTypes] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOpenNewMailPopUp, openNewMailPopUp] = useState<boolean>(false);
    const [isScrollDown, setisScrollDown] = useState<boolean>(false);
    const [respMessage, setRespMessage] = useState<any>({});    
    const mftStatusReasons = useRef<Array<any>>([]);
    const mailFwdDetailsBeforeChanges = useRef<any>([]);


    useEffect(() => {
        getMailFwdStatuses();
        getMailFwdCategories();
        let params: any = new URLSearchParams(window.location.search);
        const trackDetails:any = params.get('mailFwdtrackDetails') ? JSON.parse(decodeURIComponent(params.get('mailFwdtrackDetails'))) : {};
        if (trackDetails.MailFwdTrackingID) {
            setIsLoading(true);
           
            setTimeout(() => {
                getMailFwdDetails(trackDetails.MailFwdTrackingID, trackDetails);
            }, 100)

        } else {
            let mailDetails: any = trackDetails;

           
            if (trackDetails.CompanyID) {
                getDealDetails(trackDetails.CompanyID)
                getTemplateDetails(trackDetails.CompanyID);

            }
            setMailForwardsDetails(mailDetails);
        }

        return () => {
            setMailForwardsDetails('');
        }
    }, []);

    const getMailFwdCategories = async () => {
        const res = await fetchMethodRequest('GET', `noteCategories/getNoteCategories`, '', '', '', '');
        if (res && res.mftNoteCategories) {
            setnoteCategoryTypes(res.mftNoteCategories.map((e: any) => { return { label: e.categoryName, value: e.fieldNameInDB } }))
        }
    }

    const getTemplateDetails = async (dealID: string) => {
        setIsLoading(true);
        const govTemplateID = await MailFwdService.getGoverningTemplateID(dealID);
        if (govTemplateID) {
            const govTemplateDetails = await MailFwdService.getGoverningTemplateDetails(govTemplateID);
            setMailForwardsDetails((prevDetails: any) => ({ ...prevDetails, MailForwardsGoverningTemplate: govTemplateDetails, MailForwardsGoverningTemplateNumber: govTemplateDetails.GoverningTemplateVersion, MailForwardsGoverningTemplateText: govTemplateDetails.GoverningTemplateMessage, MailForwardsGoverningTemplateSummary: govTemplateDetails.GoverningTemplateSummary }));
        }
        setIsLoading(false);
    }


    /**
     * Get mail forward details in case of rdirect from on click mail forward id, get the data from api & setting values in fields.      
     */
    const getMailFwdDetails = async (trackID: string, details: any) => {
        const res = await fetchMethodRequest('POST', `mailForwardsDetails/getDetailsWithDealFromID`, { mailFwdID: parseInt(trackID) }, '', '', '');
        const trackDetails: any = res && res.details && res.details[0] ? res.details[0] : details;
        mailFwdDetailsBeforeChanges.current = { ...trackDetails };
        let mailDetails: any = { ...trackDetails };
        mailDetails.MailFwdExpiredDays = trackDetails.MailFwdExpiredDays;
        if (trackDetails.MailForwardStatusID) {
            mailDetails.mailFwdStatus = mftStatusReasons.current.find((e) => e.mailFwdStatusID.toString() === trackDetails.MailForwardStatusID.toString());
            if (mailDetails.mailFwdStatus && trackDetails.MailForwardReasonID) {
                setMftReasons(MailFwdService.getMailForwardReasons(mailDetails.mailFwdStatus.mailFwdReasons, MailFwdService.MAIL_FORWARD_DETAILS));
                // setMftReasons(mailDetails.mailFwdStatus.mailFwdReasons);
                mailDetails.mailFwdReason = mailDetails.mailFwdStatus.mailFwdReasons.find((e: any) => e.mailFwdReasonID.toString() === trackDetails.MailForwardReasonID.toString());
            }
        }

        let selGovTemplateDetails: any;


        if (trackDetails.Deal_GoverningTemplateID) {
            selGovTemplateDetails = await MailFwdService.getGoverningTemplateDetails(trackDetails.Deal_GoverningTemplateID);
        }

        if (selGovTemplateDetails) {
            mailDetails.MailForwardsGoverningTemplate = selGovTemplateDetails;
            mailDetails.MailForwardsGoverningTemplateNumber = selGovTemplateDetails.GoverningTemplateVersion;
            mailDetails.MailForwardsGoverningTemplateText = selGovTemplateDetails.GoverningTemplateMessage;
            mailDetails.MailForwardsGoverningTemplateSummary = selGovTemplateDetails.GoverningTemplateSummary;
        } else {
            getTemplateDetails(mailDetails.CompanyID);
        }

        if (mailDetails.LastFwdRequestDate) mailDetails.LastFwdRequestDate = new Date(mailDetails.LastFwdRequestDate);
        if (mailDetails.InitialFwdRequestDate) mailDetails.InitialFwdRequestDate = new Date(mailDetails.InitialFwdRequestDate);
        if (mailDetails.NextFwdRequestDueDate) mailDetails.NextFwdRequestDueDate = new Date(mailDetails.NextFwdRequestDueDate);
        if (mailDetails.TestEnvelope1SentDate) mailDetails.TestEnvelope1SentDate = new Date(mailDetails.TestEnvelope1SentDate);
        if (mailDetails.TestEnvelope1RecvdDate) mailDetails.TestEnvelope1RecvdDate = new Date(mailDetails.TestEnvelope1RecvdDate);
        if (mailDetails.APAPredecessor1CorpStatusInactiveDate) mailDetails.APAPredecessor1CorpStatusInactiveDate = new Date(mailDetails.APAPredecessor1CorpStatusInactiveDate);
        if (mailDetails.APAPredecessor2CorpStatusInactiveDate) mailDetails.APAPredecessor2CorpStatusInactiveDate = new Date(mailDetails.APAPredecessor2CorpStatusInactiveDate);
        if (mailDetails.APAPredecessor3CorpStatusInactiveDate) mailDetails.APAPredecessor3CorpStatusInactiveDate = new Date(mailDetails.APAPredecessor3CorpStatusInactiveDate);
        if (mailDetails.TestEnvelope2SentDate) mailDetails.TestEnvelope2SentDate = new Date(mailDetails.TestEnvelope2SentDate);
        if (mailDetails.TestEnvelope2RecvdDate) mailDetails.TestEnvelope2RecvdDate = new Date(mailDetails.TestEnvelope2RecvdDate);
        if (mailDetails.LastMailRecvdDate) mailDetails.LastMailRecvdDate = new Date(mailDetails.LastMailRecvdDate);
        setMailForwardsDetails(mailDetails);
        
        
        if (res && res.trackingRecord) {
            setMailForwardsDetails(res.trackingRecord);
        }
        setIsLoading(false);
    }

    const getMailFwdStatuses = async () => {
        const [status, filteredStatuses] = await MailFwdService.getMailFwdStatusandReasons(MailFwdService.MAIL_FORWARD_DETAILS);
        mftStatusReasons.current = filteredStatuses;
        await setMftStatuses(filteredStatuses);
        const reasons = filteredStatuses.map((item: any) => item.mailFwdReasons).flat();
        setFilteredReasons(reasons)
    }

    const getDealDetails = async (dealID: string) => {
        const res = await fetchMethodRequest('GET', `seller/${dealID}`, '', '', '', '');
        if (res && res.details) {
            setMailForwardsDetails((prevDetails: any) => ({ ...prevDetails, DealType: res.details.DealType, SellerNickName: res.details.SellerNickName, CompanyID: res.details.CompanyID }));
        }

    }


    /**
     * on Click save create/update functionality
     */
    const onClickSave = async () => {
        setIsLoading(true);
        let data: any = {
            CompanyName: mailForwardsDetails.dealName,
            BusinessLocationID: mailForwardsDetails.BusinessLocationID,
            KeyAddressFlag: mailForwardsDetails.KeyAddressFlag,
            InitialFwdRequestDate: mailForwardsDetails.InitialFwdRequestDate ? mailForwardsDetails.InitialFwdRequestDate : null,
            LastFwdRequestDate: mailForwardsDetails.LastFwdRequestDate ? mailForwardsDetails.LastFwdRequestDate : null,
            ChainOfTitle: mailForwardsDetails.ChainOfTitle ? mailForwardsDetails.ChainOfTitle : null,
            ChainOfTitlePosition: mailForwardsDetails.ChainOfTitlePosition ? mailForwardsDetails.ChainOfTitlePosition : null,
            ChainOfTitleNotes: mailForwardsDetails.ChainOfTitleNotes ? mailForwardsDetails.ChainOfTitleNotes : null,
            AddressDormant: mailForwardsDetails.AddressDormant ? mailForwardsDetails.AddressDormant : null,
            AddressDormancyCheck1_Google: mailForwardsDetails.AddressDormancyCheck1_Google ? mailForwardsDetails.AddressDormancyCheck1_Google : null,
            AddressDormancyCheck2_DataAxle: mailForwardsDetails.AddressDormancyCheck2_DataAxle ? mailForwardsDetails.AddressDormancyCheck2_DataAxle : null,
            AddressDormancyCheck3_ArchiveOrg: mailForwardsDetails.AddressDormancyCheck3_ArchiveOrg ? mailForwardsDetails.AddressDormancyCheck3_ArchiveOrg : null,
            AddressDormancyCheck_Misc1: mailForwardsDetails.AddressDormancyCheck_Misc1 ? mailForwardsDetails.AddressDormancyCheck_Misc1 : null,
            AddressDormancyCheck_Misc2: mailForwardsDetails.AddressDormancyCheck_Misc2 ? mailForwardsDetails.AddressDormancyCheck_Misc2 : null,
            EstDormancyTimeframe: mailForwardsDetails.EstDormancyTimeframe ? mailForwardsDetails.EstDormancyTimeframe : null,
            NextFwdRequestDueDate: mailForwardsDetails.NextFwdRequestDueDate ? mailForwardsDetails.NextFwdRequestDueDate : null,
            APAPredecessor1CorpStatusInactiveDate: mailForwardsDetails.APAPredecessor1CorpStatusInactiveDate ? mailForwardsDetails.APAPredecessor1CorpStatusInactiveDate : null,
            APAPredecessor1CorpStatusInactiveLag: mailForwardsDetails.APAPredecessor1CorpStatusInactiveLag ? mailForwardsDetails.APAPredecessor1CorpStatusInactiveLag : null,
            APAPredecessor2CorpStatusInactiveDate: mailForwardsDetails.APAPredecessor2CorpStatusInactiveDate ? mailForwardsDetails.APAPredecessor2CorpStatusInactiveDate : null,
            APAPredecessor2CorpStatusInactiveLag: mailForwardsDetails.APAPredecessor2CorpStatusInactiveLag ? mailForwardsDetails.APAPredecessor2CorpStatusInactiveLag : null,
            APAPredecessor3CorpStatusInactiveDate: mailForwardsDetails.APAPredecessor3CorpStatusInactiveDate ? mailForwardsDetails.APAPredecessor3CorpStatusInactiveDate : null,
            APAPredecessor3CorpStatusInactiveLag: mailForwardsDetails.APAPredecessor3CorpStatusInactiveLag ? mailForwardsDetails.APAPredecessor3CorpStatusInactiveLag : null,
            ForwardedName: mailForwardsDetails.ForwardedName ? mailForwardsDetails.ForwardedName : null,
            TestEnvelopeName: mailForwardsDetails.TestEnvelopeName ? mailForwardsDetails.TestEnvelopeName : null,
            USPSMailFwdConfmCode: mailForwardsDetails.USPSMailFwdConfmCode,
            TestEnvelope1SentDate: mailForwardsDetails.TestEnvelope1SentDate ? mailForwardsDetails.TestEnvelope1SentDate : null,
            TestEnvelope1RecvdDate: mailForwardsDetails.TestEnvelope1RecvdDate ? mailForwardsDetails.TestEnvelope1RecvdDate : null,
            TestEnvelope2SentDate: mailForwardsDetails.TestEnvelope2SentDate ? mailForwardsDetails.TestEnvelope2SentDate : null,
            TestEnvelope2RecvdDate: mailForwardsDetails.TestEnvelope2RecvdDate ? mailForwardsDetails.TestEnvelope2RecvdDate : null,
            LastMailRecvdDate: mailForwardsDetails.LastMailRecvdDate ? mailForwardsDetails.LastMailRecvdDate : null,
            CompanyID: mailForwardsDetails.CompanyID,
            MailRecvdCount: mailForwardsDetails.MailRecvdCount,
            PaymentRecvdCount: mailForwardsDetails.PaymentRecvdCount,
            TotalPaymentValue: mailForwardsDetails.TotalPaymentValue,
            MailForwardStatusID: mailForwardsDetails.mailFwdStatus && mailForwardsDetails.mailFwdStatus.mailFwdStatusID ? mailForwardsDetails.mailFwdStatus.mailFwdStatusID : '',
            MailForwardReasonID: mailForwardsDetails.mailFwdReason && mailForwardsDetails.mailFwdReason.mailFwdReasonID ? mailForwardsDetails.mailFwdReason.mailFwdReasonID : '',
            RowIsCurrent: 1,
            MailForwardNote: mailForwardsDetails.MailForwardNote,
            BusinessLocation_GoverningTemplateID: mailForwardsDetails.MailForwardsGoverningTemplate ? mailForwardsDetails.MailForwardsGoverningTemplate.GoverningTemplateID : null
        };
        data[selectedNoteType] = noteValue;
        (Object.keys(data)).forEach((field) => {
            if (field !== 'KeyAddressFlag' && !data[field]) {
                delete data[field];
            }
        });
        const loginCredentials: any = JSON.parse(localStorage.getItem('loginCredentials') || '');
        let body: any = {
            data: data,
            userid: loginCredentials?.azureAdUserId
        }
        let api = `mailForwardsDetails/createMFT`;
        if (mailForwardsDetails.MailFwdTrackingID) {
            api = `mailForwardsDetails/updateMFT`;
            body.data = getOnlyChangedFields(data);
            body.mailFwdID = mailForwardsDetails.MailFwdTrackingID;
        }
        const res = await fetchMethodRequest('POST', api, JSON.parse(JSON.stringify(body)), '', '', '');
        if (res.success) {
            setIsLoading(true);
            getMailFwdDetails(res.mailFwdID ? res.mailFwdID : mailForwardsDetails.MailFwdTrackingID, mailForwardsDetails);
            setNoteValue("")
            setselectedCategoryType("MailForwardNote")
            setNotesActiveTab(0)

            getNotesDetails(1, "MailForwardNote")
            // getMailFwdNotes(res.mailFwdID ? res.mailFwdID : mailForwardsDetails.MailFwdTrackingID,"MailForwardNote");
        }
        setIsLoading(false);
        if (res && res.message) {
            setRespMessage({ type: res.success ? 'success' : 'danger', message: res.message });
            setTimeout(() => {
                setRespMessage({ type: '', message: '' });
            }, 1000 * 5)
        }
    }

    const getOnlyChangedFields = (currentFields: any) => {
        let changedFields: any = {};
        for (let prop in currentFields) {
            let value = currentFields[prop];
            if (value instanceof Date) {
                value = value.toISOString();
            }
            if (value != mailFwdDetailsBeforeChanges.current[prop] || prop === 'CompanyID' || prop === 'RowIsCurrent' || prop === 'BusinessLocationID') {
                changedFields[prop] = currentFields[prop];
            }
        }
        return changedFields;
    }

    /**
     * on change user input values, updating user entered values into state    
     */
    const onChangeInputValues = (field: string, value: any) => {
        setMailForwardsDetails((prevDetails: any) => ({ ...prevDetails, [field]: value }));
        if (field === 'mailFwdStatus' && value && value.mailFwdReasons) {
            setMftReasons(MailFwdService.getMailForwardReasons(value.mailFwdReasons, MailFwdService.MAIL_FORWARD_DETAILS));
            if (mailForwardsDetails.mailFwdReason) {
                onChangeInputValues('mailFwdReason', '');
            }
        } else if (field === 'MailForwardsGoverningTemplateNumber') {
            onChangeInputValues('MailForwardsGoverningTemplateText', value.GoverningTemplateMessage);
            onChangeInputValues('MailForwardsGoverningTemplateSummary', value.GoverningTemplateSummary);

        }
    }

    const getNotesDetails = async (tab: number, type?: string) => {
        await setisScrollDown(true)
        await setNotesActiveTab(tab)
        setSelectedNoteType(type ? type : "MailForwardNote")
        setIsLoading(false);
    }

    const isCustumFunctionality = () => {
        if (isScrollDown)
            window.scrollTo(0, document.body.scrollHeight);
        setisScrollDown(false)
    }

    const getColumns = () => {
        let data = [{
            textAlign: "left",
            width: 140,
            field: "DateInserted",
            mobile: true,
            header: "Date",
            filter: false,
            sortable: false,
            show: true,
        },

        {
            show: true,
            textAlign: "left",
            width: 140,
            mobile: true,
            field: "LastModifiedBy",
            header: "User",
            // fieldType: 'text',
            filter: false,
            sortable: true,
            textCapitalize: true,
        }, {
            show: true,
            textAlign: "left",
            width: 140,
            mobile: true,
            field: selectedNoteType ? selectedNoteType : "MailForwardNote",
            header: "Note",
            // fieldType: 'text',
            filter: true,
            sortable: true,
            textCapitalize: true,
        }]
        let newObjects = [{
            show: true,
            textAlign: "left",
            width: 350,
            mobile: true,
            field: "MailForwardStatus",
            filterType: "multiSelect",
            filterOptions: mftStatuses.map((e) => { return { label: e.mailFwdStatus, value: e.mailFwdStatusID } }),
            header: "Mail Forward Status",
            // fieldType: 'text',
            filter: true,
            sortable: true,
            textCapitalize: true,
        }, {
            textAlign: "left",
            width: 350,
            field: "MailForwardReason",
            mobile: true,
            filterType: "multiSelect",
            filterOptions: filteredReasons.map((e) => { return { label: e.mailFwdReason, value: e.mailFwdReasonID } }),
            header: "Mail Forward Reason",
            filter: true,
            sortable: true,
            show: true,
        },]
        if (selectedNoteType === "MailForwardNote" || !selectedNoteType) { data.splice(1, 0, ...newObjects) }
        return data
    }

    const getDataGrid = () => {
        return <Datatables
            getTableFields={getColumns}
            formFields={[]}
            donotIncludeParamsInFilter={true}
            isPostCall={true}
            postbody={{ id: mailForwardsDetails["MailFwdTrackingID"], noteField: selectedNoteType ? selectedNoteType : "MailForwardNote" }}
            sortField={selectedNoteType ? selectedNoteType : "MailForwardNote"}
            dontShowTitle={true}
            isCustumFunctionality={isCustumFunctionality}
            statusFilter={false}
            className={true}
            custumChangeFieldValues={custumChangeFieldValues}
            exportRequried={false}
            printRequried={false}
            addRequried={false}
            editRequired={false}
            deleteRequired={false}
            viewRequired={false}
            settingsRequired={false}
            filterRequired={false}
            gridRequried={false}
            sample={false}
            globalSearchFieldName='user'
            globalSearch={activeTab===1?'Search':""}
            type='Mail Forward Details Note History'
            isClearFilters={activeTab===1?true:false}
            displayright={true}
            icon='history'
            routeTo='activities'
            displayViewOfForm='modal'
            apiResponseKey='data'
            apiUrl={"mftHistory/getNotesHistoryFiltered"}
            entityType='employee'
        />
    }

    const custumChangeFieldValues=(rowData:any)=>{
        if (rowData.MailFwdActivityID) {
        return <div className={ 'mail-fwd-link text-center'}   onClick={(e) =>{ saveNewMailRec(e,rowData,"edit")}}>{rowData.MailFwdTrackingID}</div>
        }
    }

    const getNotesHistoryTabs = () => {
        return (
            <div>
                <h4>Notes & Status History</h4>
                <Nav tabs >
                    <NavItem>
                        <NavLink
                            className={{ active: activeNotesTab === 1 }}
                            onClick={() => { getNotesDetails(1, "MailForwardNote") }}
                        >
                            General Notes & Status
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={{ active: activeNotesTab === 2 }}
                            onClick={() => { getNotesDetails(2, "MailForwardHoldNote") }}
                        >
                            Hold Notes
                        </NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink
                            className={{ active: activeNotesTab === 3 }}
                            onClick={() => { getNotesDetails(3, "MailForwardResearchNote") }}
                        >
                            Research Notes
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={{ active: activeNotesTab === 4 }}
                            onClick={() => { getNotesDetails(4, "MailForwardSupplementalNote") }}
                        >
                            Supplement Notes
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={{ active: activeNotesTab === 5 }}
                            onClick={() => { getNotesDetails(5, "MailForwardDormancyNote") }}
                        >
                            Dormancy Notes
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeNotesTab}>
                    <TabPane tabId={activeNotesTab} >
                        <div className="p-1 border bg-white notes-history-tab">
                            <div className="mt-1">
                                <TabPane tabId={1} >
                                    {activeNotesTab === 1 && mailForwardsDetails.MailFwdTrackingID && <>{getDataGrid()}</>}
                                </TabPane>
                                <TabPane tabId={2}>
                                    {activeNotesTab === 2 && mailForwardsDetails.MailFwdTrackingID && <>{getDataGrid()}</>}
                                </TabPane>
                                <TabPane tabId={3}>
                                    {activeNotesTab === 3 && mailForwardsDetails.MailFwdTrackingID && <>{getDataGrid()}</>}
                                </TabPane>
                                <TabPane tabId={4}>
                                    {activeNotesTab === 4 && mailForwardsDetails.MailFwdTrackingID && <>{getDataGrid()}</>}
                                </TabPane>
                                <TabPane tabId={5}>
                                    {activeNotesTab === 5 && mailForwardsDetails.MailFwdTrackingID && <>{getDataGrid()}</>}
                                </TabPane>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        )
    }

    const storeCategoryField = (value: any) => {
        setSelectedNoteType(value)
        setselectedCategoryType(value)
        let activeNotesTab = value === "MailForwardDormancyNote" ? 5 :
            value === "MailForwardHoldNote" ? 2 :
                value === "MailForwardSupplementalNote" ? 4 :
                    value === "MailForwardResearchNote" ? 3 :
                        1
        setNotesActiveTab(activeNotesTab)
    }


    const saveNewMailRec=(type:any,value:any,recordType:string)=>{            
            openNewMailPopUp(true)
            setnewReceivedMailData(value)        

    }

    const closeNewMailPopUp=()=>{
        openNewMailPopUp(false)
    }


    return <>
        <div className="card-body mail-fwd-details p-2 mt-1">
            <Nav tabs >
                <NavItem>
                    <NavLink
                        className={{ active: activeTab === 1, h6: true }}
                        onClick={() => { setActiveTab(1) }}
                    >
                        Main
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={{ active: activeTab === 2, h6: true }}
                        onClick={() => { setActiveTab(2) }}
                    >
                        Research History
                    </NavLink>
                </NavItem>
                <NavItem >
                    <NavLink
                        className={{ active: activeTab === 3, h6: true }}
                        onClick={() => { setActiveTab(3) }}
                    >
                        Seller/Trustee Approvals History
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={{ active: activeTab === 4, h6: true }}
                        onClick={() => {if(mailForwardsDetails && mailForwardsDetails.MailFwdTrackingID) setActiveTab(4) }}
                    >
                        Received Mail History
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={{ active: activeTab === 5, h6: true }}
                        onClick={() => { setActiveTab(5) }}
                    >
                        USPS Forwarding History
                    </NavLink>
                </NavItem>
                <NavItem className="d-flex flex-grow-1 justify-content-center align-items-center pr-1">
                    {respMessage.message && <span className={respMessage.type === 'success' ? 'text-success' : respMessage.type === 'danger' ? "text-danger" : ''}>{respMessage.message}</span>}
                </NavItem>
                {activeTab===1?<NavItem className="d-flex flex-grow-1 justify-content-end pr-1">
                    <div>
                        <Button color="primary" type='submit' className="mb-1" onClick={() => { onClickSave() }}>
                            Save
                        </Button>
                    </div>

                </NavItem>:null}

            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1} >

                    <div className="p-4 mb-2 w-100 border">
                        <div className="row mail-fwd-details-fields">
                            <div className="col-sm-3 d-flex flex-column">
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Mail Fwd ID</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.MailFwdTrackingID} placeholder={mailForwardsDetails.MailFwdTrackingID ? '' : 'System will auto assign'} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Deal Type</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.DealType} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Deal Name</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.SellerNickName} />
                                    </div>
                                </div>

                                <div className="row mb-2 mt-4">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Company Name/DBAs</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.LocationName} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Test Envelope Name</label>
                                    <div className="col-sm-8">
                                        <InputTextarea className="w-100" rows={3} value={mailForwardsDetails.TestEnvelopeName} onChange={(e) => onChangeInputValues('TestEnvelopeName', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Forwarded Name</label>
                                    <div className="col-sm-8">
                                        <InputTextarea className="w-100" rows={3} value={mailForwardsDetails.ForwardedName} onChange={(e) => onChangeInputValues('ForwardedName', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Key Address</label>
                                    <div className="col-sm-8 d-flex">
                                        <div className="mr-2">
                                            <RadioButton inputId="rb1" name="city" value={true} onChange={(e) => onChangeInputValues('KeyAddressFlag', true)} checked={mailForwardsDetails.KeyAddressFlag === true} />
                                            <label htmlFor="rb1" className="p-radiobutton-label">Yes</label>
                                        </div>
                                        <div  >
                                            <RadioButton inputId="rb2" name="city" value={false} onChange={(e) => onChangeInputValues('KeyAddressFlag', false)} checked={mailForwardsDetails.KeyAddressFlag === false} />
                                            <label htmlFor="rb2" className="p-radiobutton-label">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Address Line1</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.Line1} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">City</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.City} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">State</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.StateProvince} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Zip</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.Zip} />
                                    </div>
                                </div>

                                <div className="row mb-2 mt-4">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Mail Fwd Status</label>
                                    <div className="col-sm-8">
                                        <Dropdown value={mailForwardsDetails.mailFwdStatus} options={mftStatuses} optionLabel="mailFwdStatus" onChange={(e) => { onChangeInputValues('mailFwdStatus', e.value) }}
                                            style={{ minWidth: '100%' }} filter={true} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Mail Fwd Reason</label>
                                    <div className="col-sm-8">
                                        <Dropdown value={mailForwardsDetails.mailFwdReason} disabled={!mailForwardsDetails.mailFwdStatus} options={mftReasons} optionLabel="mailFwdReason" dataKey="_id" onChange={(e) => { onChangeInputValues('mailFwdReason', e.value) }}
                                            style={{ minWidth: '100%' }} filter={true} />
                                    </div>
                                </div>


                            </div>
                            <div className="col-sm-3 d-flex flex-column">
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Initial Fwd Req Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker">
                                            <DatePicker selected={mailForwardsDetails.InitialFwdRequestDate} onChange={(date: Date) => onChangeInputValues('InitialFwdRequestDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>

                                </div>
                                <div className="row mb-2">

                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Last Fwd Req Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker ">
                                            <DatePicker selected={mailForwardsDetails.LastFwdRequestDate} onChange={(date: Date) => onChangeInputValues('LastFwdRequestDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>

                                    </div>

                                </div>
                                <div className="row mb-4">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Next Fwd Req Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker">
                                            <DatePicker selected={mailForwardsDetails.NextFwdRequestDueDate} onChange={(date: Date) => onChangeInputValues('NextFwdRequestDueDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>


                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Test Env1 Sent Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker">
                                            <DatePicker selected={mailForwardsDetails.TestEnvelope1SentDate} onChange={(date: Date) => onChangeInputValues('TestEnvelope1SentDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Test Env1 Received Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker ">
                                            <DatePicker selected={mailForwardsDetails.TestEnvelope1RecvdDate} onChange={(date: Date) => onChangeInputValues('TestEnvelope1RecvdDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Test Env2 Sent Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker">
                                            <DatePicker selected={mailForwardsDetails.TestEnvelope2SentDate} onChange={(date: Date) => onChangeInputValues('TestEnvelope2SentDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour  label-mailF-details d-flex justify-content-end align-items-center">Test Env2 Received Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker mb-2">
                                            <DatePicker selected={mailForwardsDetails.TestEnvelope2RecvdDate} onChange={(date: Date) => onChangeInputValues('TestEnvelope2RecvdDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour  label-mailF-details d-flex justify-content-end align-items-center">APA Predecessor1 CorpStatus Inactive Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker mb-2">
                                            <DatePicker selected={mailForwardsDetails.APAPredecessor1CorpStatusInactiveDate} onChange={(date: Date) => onChangeInputValues('APAPredecessor1CorpStatusInactiveDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">APA Predecessor1 CorpStatus Inactive Lag</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.APAPredecessor1CorpStatusInactiveLag} onChange={(e) => onChangeInputValues('APAPredecessor1CorpStatusInactiveLag', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour  label-mailF-details d-flex justify-content-end align-items-center">APA Predecessor2 CorpStatus Inactive Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker mb-2">
                                            <DatePicker selected={mailForwardsDetails.APAPredecessor2CorpStatusInactiveDate} onChange={(date: Date) => onChangeInputValues('APAPredecessor2CorpStatusInactiveDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">APA Predecessor2 CorpStatus Inactive Lag</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.APAPredecessor2CorpStatusInactiveLag} onChange={(e) => onChangeInputValues('APAPredecessor2CorpStatusInactiveLag', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour  label-mailF-details d-flex justify-content-end align-items-center">APA Predecessor3 CorpStatus Inactive Date</label>
                                    <div className="col-sm-8">
                                        <div className="date-picker mb-2">
                                            <DatePicker selected={mailForwardsDetails.APAPredecessor3CorpStatusInactiveDate} onChange={(date: Date) => onChangeInputValues('APAPredecessor3CorpStatusInactiveDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">APA Predecessor3 CorpStatus Inactive Lag</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.APAPredecessor3CorpStatusInactiveLag} onChange={(e) => onChangeInputValues('APAPredecessor3CorpStatusInactiveLag', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-3 d-flex flex-column">

                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Chain Of Title</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.ChainOfTitle} onChange={(e) => onChangeInputValues('ChainOfTitle', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Chain Of Title Position</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.ChainOfTitlePosition} onChange={(e) => onChangeInputValues('ChainOfTitlePosition', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Chain Of Title Notes</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.ChainOfTitleNotes} onChange={(e) => onChangeInputValues('ChainOfTitleNotes', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Address Dormant</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.AddressDormant} onChange={(e) => onChangeInputValues('AddressDormant', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Address Dormancy Check1 Google</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.AddressDormancyCheck1_Google} onChange={(e) => onChangeInputValues('AddressDormancyCheck1_Google', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Address Dormancy Check2 Data Axle</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.AddressDormancyCheck2_DataAxle} onChange={(e) => onChangeInputValues('AddressDormancyCheck2_DataAxle', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Address Dormancy Check3 Archive Org</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.AddressDormancyCheck3_ArchiveOrg} onChange={(e) => onChangeInputValues('AddressDormancyCheck3_ArchiveOrg', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Address Dormancy Check Misc1</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.AddressDormancyCheck_Misc1} onChange={(e) => onChangeInputValues('AddressDormancyCheck_Misc1', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Address Dormancy Check Misc2</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.AddressDormancyCheck_Misc2} onChange={(e) => onChangeInputValues('AddressDormancyCheck_Misc2', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Est Dormancy Timeframe</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.EstDormancyTimeframe} onChange={(e) => onChangeInputValues('EstDormancyTimeframe', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">USPS Mail Fwd Confrm. Code</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" value={mailForwardsDetails.USPSMailFwdConfmCode} onChange={(e) => onChangeInputValues('USPSMailFwdConfmCode', (e.target as HTMLInputElement).value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Mail Fwd Expired Days</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.MailFwdExpiredDays} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 d-flex flex-column">
                                {/* <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Last Mail Received Date </label>
                                    <div className="col-sm-8">
                                        <div className="date-picker ">
                                            <DatePicker selected={mailForwardsDetails.LastMailRecvdDate} onChange={(date: Date) => onChangeInputValues('LastMailRecvdDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="row mb-2 ">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Mail Received Count</label>
                                    <div className="col-sm-8">
                                        <InputText type="number" keyfilter="int" className="w-100" onChange={(e) => onChangeInputValues('MailRecvdCount', (e.target as HTMLInputElement).value)} value={mailForwardsDetails.MailRecvdCount} />
                                    </div>
                                </div>
                                <div className="row mb-2 ">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Payment Received Count</label>
                                    <div className="col-sm-8">
                                        <InputText type="number" keyfilter="int" className="w-100" onChange={(e) => onChangeInputValues('PaymentRecvdCount', (e.target as HTMLInputElement).value)} value={mailForwardsDetails.PaymentRecvdCount} />
                                    </div>
                                </div>
                                <div className="row mb-2 ">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Total Payment Value</label>
                                    <div className="col-sm-8">
                                        <InputText type="number" className="w-100" onChange={(e) => onChangeInputValues('TotalPaymentValue', (e.target as HTMLInputElement).value)} value={mailForwardsDetails.TotalPaymentValue} />
                                    </div>
                                </div> */}
                                <div className="row mb-2" style={mailForwardsDetails.MailForwardNote && mailForwardsDetails.MailForwardNote !== null ? { display: "flex" } : { display: "none" }}>
                                    <label className="col-sm-4 mb-0 pr-0  label-mailF-details d-flex justify-content-end ">{"Mail Forward Note"}</label>
                                    <div className="col-sm-8">
                                        <InputTextarea className="resize-text-area" disabled rows={2} value={mailForwardsDetails.MailForwardNote} />
                                    </div>
                                </div>
                                <div className="row mb-2" style={mailForwardsDetails.MailForwardHoldNote && mailForwardsDetails.MailForwardHoldNote !== null ? { display: "flex" } : { display: "none" }}>
                                    <label className="col-sm-4 mb-0 pr-0  label-mailF-details d-flex justify-content-end ">{"Mail Forward Hold Note"}</label>
                                    <div className="col-sm-8">
                                        <InputTextarea className="resize-text-area" disabled rows={2} value={mailForwardsDetails.MailForwardHoldNote} />
                                    </div>
                                </div>
                                <div className="row mb-2" style={mailForwardsDetails.MailForwardResearchNote && mailForwardsDetails.MailForwardResearchNote !== null ? { display: "flex" } : { display: "none" }}>
                                    <label className="col-sm-4 mb-0 pr-0  label-mailF-details d-flex justify-content-end ">{"Mail Forward Research Note"}</label>
                                    <div className="col-sm-8">
                                        <InputTextarea className="resize-text-area" disabled rows={2} value={mailForwardsDetails.MailForwardResearchNote} />
                                    </div>
                                </div>
                                <div className="row mb-2" style={mailForwardsDetails.MailForwardSupplementalNote && mailForwardsDetails.MailForwardSupplementalNote !== null ? { display: "flex" } : { display: "none" }}>
                                    <label className="col-sm-4 mb-0 pr-0  label-mailF-details d-flex justify-content-end ">{"Mail Forward Supplemental Note"}</label>
                                    <div className="col-sm-8">
                                        <InputTextarea className="resize-text-area" disabled rows={2} value={mailForwardsDetails.MailForwardSupplementalNote} />
                                    </div>
                                </div>
                                <div className="row mb-2" style={mailForwardsDetails.MailForwardDormancyNote && mailForwardsDetails.MailForwardDormancyNote !== null ? { display: "flex" } : { display: "none" }}>
                                    <label className="col-sm-4 mb-0 pr-0  label-mailF-details d-flex justify-content-end ">{"Mail Forward Dormancy Note"}</label>
                                    <div className="col-sm-8">
                                        <InputTextarea className="resize-text-area" disabled rows={2} value={mailForwardsDetails.MailForwardDormancyNote} />
                                    </div>
                                </div>
                                <div className="row mb-2 mt-4">
                                    <label className={`col-sm-4 mb-0 pr-0  label-mailF-details d-flex justify-content-end align-items-center`}>Governing Template Version</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled onChange={(e) => onChangeInputValues('MailForwardsGoverningTemplateNumber', (e.target as HTMLInputElement).value)} value={mailForwardsDetails.MailForwardsGoverningTemplateNumber} />

                                    </div>
                                </div>
                                <div className="row mb-2 ">
                                    <label className="col-sm-4 mb-0 pr-0  label-mailF-details d-flex justify-content-end ">Governing Template Summary</label>
                                    <div className="col-sm-8">
                                        <InputTextarea className="resize-text-area" disabled rows={2} value={mailForwardsDetails.MailForwardsGoverningTemplateSummary} />
                                    </div>
                                </div>
                                <div className="row mb-2 ">
                                    <label className="col-sm-4 mb-0 pr-0  label-mailF-details d-flex justify-content-end ">Governing Template Text</label>
                                    <div className="col-sm-8">
                                        <InputTextarea className="resize-text-area" disabled rows={5} value={mailForwardsDetails.MailForwardsGoverningTemplateText} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 d-flex flex-column">
                            <h4>Note</h4>
                            <div className="p-1 w-100 border mb-1">
                                <div className="p-1 bg-white col-sm-12 d-flex">
                                    <div className="col-sm-5">
                                        <label className="mb-0">Note Type</label>
                                        <Dropdown className="w-100" options={noteCategoryTypes} onChange={(e) => storeCategoryField(e.value)} value={selectedCategoryType} />
                                    </div>
                                    <div>
                                        <Button color="primary" type='submit' className="mb-1 mt-4" onClick={() => { onClickSave() }}>
                                            Save Note
                                        </Button>
                                    </div>
                                </div>
                                <div className="p-1 bg-white col-sm-12 d-flex">
                                    <div className="col-sm-11">
                                        <label className="mb-0">Add Note</label>
                                        <InputTextarea className="w-100" rows={3} onChange={(e) => setNoteValue((e.target as HTMLInputElement).value)} value={noteValue} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8 d-flex flex-column">{getNotesHistoryTabs()}</div>
                    </div>



                </TabPane>
                <TabPane tabId={4}>                
                    {activeTab === 4 && <MailForwardsReceivedMailHistory
                        paramsDetails={mailForwardsDetails}                        
                    />}
                </TabPane>
            </TabContent>
        </div>
        <Loader loader={isLoading} />
        
    </>
}

export default MailForwardsDetails;