import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// import { load as loadAccount } from '../../redux/reducers/commonReducer';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer'
import { Redirect } from 'react-router-dom';
// file upload
import { Row } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../../../config/apiCalls';
import fetch from '../../../../config/service'
import config from '../../../../config/config'
import dateFormats from '../../../UI/FormatDate/formatDate'
import statesList from '../../../../shared/states.json'
import { InputSwitch } from 'primereact/inputswitch';

// validate
// import validate from './validate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
// import { setUserAction } from '../../redux/actions/userActions';
import { setUserAction } from '../../../../redux/actions/userActions';
// import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import SessionWarningModal from '../../CommonModals/SessionWarningModal'
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import configMessages from '../../../../config/configMessages';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar'
import { InputTextarea } from 'primereact/inputtextarea';
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class HighValuePropertiesFilters extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,

  };

  constructor(props) {
    super(props);
    this.state = {
      isShowNote: true,
      statesArray: [...statesList.map((e) => { return { label: e.shortCode, value: e.shortCode } })],
      propertyValue: "50000",
      propertyNameScore: "90",
      addressScore: "",
      dealType: [],
      selectedSeller: "",
      propertyStatus: [],
      statuses: [],
      propertyReason: [],
      reasons: [],
      OPP_NAUPA_Categories: [],
      modifiedCategories: [],
      department: [],
      taskDepartmentItems: [],
      onSitePropOnly: true,
      unknownProp: false,
      getAllProp: false,
      propAddedSince: null,
      confirmedOnSiteSince: null,
      propertyDataSource: [],
      dataSourceDetail: [],
      modifiedSrcDetails: [],
      states: [],
      stateClaimID: null,
      OPPClaimID: null,
      statePropertyId: null,
      OPP_PropertyID: null
    };
  }

  componentDidMount = async () => {
    this.props.onRef(this);
    await this.getDepartments()
    this.getSourceDetails()
    this.getOppNaupaCategory()
    this.searchSellerItems()
    await this.setDefaultTeamOnInitialLoad()

  }

  componentWillUnmount = () => {
    this.props.onRef(null);
  }

  getDataGroupSources = async (dataSourceApiResponse) => {
    let dataSourceArr = [], dataArray = [], StateDataSource = [];
    if (dataSourceApiResponse && dataSourceApiResponse.length > 0) {
      for (let dataSource of dataSourceApiResponse) {
        dataSourceArr.push({ label: dataSource.group, value: dataSource.dataSourceNames })
        dataArray.push({ label: dataSource.group, value: dataSource.group })
        if (dataSource.group === "State UP Data" || dataSource.group === "CA-N" || dataSource.group === "SEC") {
          StateDataSource = [...StateDataSource, ...dataSource.dataSourceNames]
        }
        if (dataSource.group === "State UP Data") {
          await this.setState({ setDefaultDataGroup: true })
        }
      }
      await this.setState({ dataSourceGroups: dataSourceArr, dataArray: dataArray })
      await this.handleChange({ value: ["CA-N", "SEC", "State UP Data"] }, 'propertyDataSource')
    }
  }

  onChangeDepartment = async (e) => {
    let departmentArray = [], departmentIdArray = []
    for (let id of e.value) {
      let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
      if (z) {
        departmentArray.push(z.department)
        departmentIdArray.push(z.departmentId)
      }
    }
    await this.setState({ department: departmentArray, departmentIdArray: departmentIdArray, department: e.value, })
    // await this.getDataFromServer(this.state.filterCriteria)
  }
  /*
 Fetch all Departments from the api 
 */
  getDepartments = () => {
    let apiUrl;
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }, { key: 'activeIndicator', value: true, type: 'eq' }]
    return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          let labels = response.departments;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.departmentName,
              value: label.departmentName,
              departmentId: label._id
            })
          }
          /* Sorting the response based on the Alphabetical descending*/
          const sorted = modifiedLabels.sort((a, b) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          await this.setState({
            taskDepartmentItems: sorted
          })
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  getOppNaupaCategory = () => {
    fetch('GET', apiCalls.naupa_Categories)
      .then(async (response) => {
        if (response) {
          let modifiedCategories = [];
          for (let status of response) {
            modifiedCategories.push({
              label: status.OPP_NAUPA_Category,
              value: status.OPP_NAUPA_Category
            })
          }
          await this.setState({
            modifiedCategories: modifiedCategories
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }

  getSourceDetails = () => {
    return fetch('GET', `NsScoredProperties/getSourceDetailList?type=exportToCsv`)
      .then(async (response) => {
        if (response && response.sourceDetails) {
          let sourceDetails = response.sourceDetails;
          let modifiedSrcDetails = [];
          for (let sourceDetail of sourceDetails) {
            modifiedSrcDetails.push({
              label: sourceDetail,
              value: sourceDetail,
            })
          }
          await this.setState({
            modifiedSrcDetails: modifiedSrcDetails
          })
        } else {
          this.setState({
            modifiedSrcDetails: []
          });

        }
      }).catch((err) => {
        return err;
      });
  }


  setDefaultTeamOnInitialLoad = async () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      let departmentArray = user.secondaryDepartments ? user.secondaryDepartments : [], departmentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      departmentArray.push(user.department)
      departmentIdArray.push(user.departmentId)
      // Check to assign only teams that are  in dropdown values
      departmentArray = this.state.taskDepartmentItems.filter(obj => departmentArray.includes(obj.label)).map(obj => obj.label)
      departmentIdArray = this.state.taskDepartmentItems.filter(obj => departmentIdArray.includes(obj.departmentId)).map(obj => obj.departmentId)
      await this.setState({ department: departmentArray, departmentIdArray: departmentIdArray })
    }
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  getStatusOptions = async (response) => {
    let filterCriteria = {}
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    let statuses = [], defaultArray = []
    if (response && response["propertyStatusReasons"].length > 0) {
      let propertyStatusReasons = response["propertyStatusReasons"]
      for (let reason of propertyStatusReasons) {
        let statusIndex = statuses.findIndex(obj => obj.label === reason.status)
        if (statusIndex === -1) {
          if (reason.status === config.highValuePropReport.defaultStatus) {
            defaultArray.push({ status: reason.status, tooltip: reason.statusDefinition })
          }
          statuses.push({ label: reason.status, value: { status: reason.status, tooltip: reason.statusDefinition } })
        }
      }
      await this.setState({ statuses: statuses, propertyStatus: defaultArray, defaultArray: defaultArray, notDefaultArray: [], isShowNote: defaultArray.length > 0 ? true : false, statusReasonData: response.propertyStatusReasons })
      this.getReasonOptions(defaultArray.map((item) => item.status), "default")

    } else if (response && response["propertyStatusReasons"].length == 0) {

      await this.setState({ statuses: [], propertyReasonArray: [] })
    }
  }

  getReasonOptions = async (val, type) => {
    let statusReasonData = this.state.statusReasonData
    let defaultReasonArray = []
    let reasonsArray = [];
    if (val && val.length > 0) {
      reasonsArray = statusReasonData.filter((propStatus) => val.includes(propStatus.status))
        .map((item) => ({ label: item.reason, value: { reason: item.reason, tooltip: item.statusReasonDefinition } }));
    } else {
      reasonsArray = Array.from(
        new Set(
          statusReasonData.map((item) => item.reason)
        )
      ).map((reason) => ({ label: reason, value: { reason: reason.reason, tooltip: reason.statusReasonDefinition } }));
    }
    if (type) {
      defaultReasonArray = reasonsArray.filter((propStatus) => propStatus.label === config.highValuePropReport.defaultReason).map((item) => item.value)
    }
    await this.setState({ reasons: reasonsArray, propertyReason: defaultReasonArray })
  }

  handleChange = async (e, type) => {
    let filterCriteria = await this.props.filterCriteria()
    var ranges = this.state.ranges
    var index;
    if (type === "Seller") {
      await this.setState({
        selectedSeller: e.value,
      })
    } else if (type === "propertyValue") {
      await this.setState({
        propertyValue: e.value,
      })
    } else if (type === "propertyDataSource") {
      let dataSourceArray = []
      await this.setState({
        propertyDataSource: e.value ? e.value : e
      })
      for (let val of e.value) {
        let dataindex = this.state.dataSourceGroups.findIndex(obj => obj.label === val)
        if (dataindex !== -1) {
          let valueArr = this.state.dataSourceGroups[dataindex]
          dataSourceArray = [...dataSourceArray, ...valueArr.value]
          await this.setState({ dataSourceArrayNew: dataSourceArray })
        }
      }
    } else if (type === "Property Status") {
      await this.setState({
        propertyStatus: e,
        // notDefaultArray: notDefaultArray,
        isShowNote: false,
        propertyReason: []
      })
      this.getReasonOptions(e.map((item) => item.status))
    } else if (type === "Property Reason") {
      await this.setState({
        propertyReason: e,
      })
    } else {
      await this.setState({
        selectedStates: e
      })
    }
    await this.setState({
      ranges: ranges
    })
  }

  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }

  searchSellerItems = async (event, criteria) => {
    let apiUrl
    if (event && event.query.length > 0) {
      apiUrl = `${apiCalls.propertysellers}?str=${event.query}`
    } else {
      apiUrl = `${apiCalls.propertysellers}`

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [], dropdownDataVal = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownDataVal = response["sellers"]
          } else if (response && response.errorMessage && (response.errorMessage === "Session expired please login again." || response.errorMessage === "Token not provided")) {
            this.setState({
              sessionExpiryModal: true
            });
          } else if (response && response['errorMessage'] === configMessages.SESSION_EXPIRATION_WARNING_MESSAGE) {
            this.setState({
              sessionWarningModal: true
            })
          }
          if (dropdownDataVal && dropdownDataVal.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownDataVal;
          }
          await this.setState({
            filteredSuggestions: dropdownDataVal
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  handleNewLineData = (value) => {
    const str = value.split(/\r?\n/)
    return str.toString().replace(/,+/g, ',');
  }

  submit = async () => {
    let highValueBody = {}
    let filterCriteria = await this.props.filterCriteria()
    if (this.state.getAllProp) {
      filterCriteria["criteria"] = filterCriteria["criteria"].filter(function (el) {
        return el.key !== "PropertyMaxValueAmount"
      });
    }
    let companyIdIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "CompanyID"&&obj.add_Filter===true )
    if (companyIdIndex !== -1) {
      filterCriteria["criteria"].splice(companyIdIndex, 1)
    }
    if (this.state.selectedSeller) {
      filterCriteria.criteria.push({ key: "CompanyID", value: this.state.selectedSeller.EstateID, type: "eq",add_Filter:true  })
    }
    let propertyValueIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount" && obj.value !== null && obj.value !== 0&&obj.add_Filter===true)
    if (propertyValueIndex !== -1) {
      filterCriteria["criteria"].splice(propertyValueIndex, 1)
    }
    if (this.state.propertyValue && !this.state.getAllProp) {
      filterCriteria.criteria.push({ key: "PropertyMaxValueAmount", value: parseInt(this.state.propertyValue), type: "gte",add_Filter:true  })
    }
    let nullIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount" && obj.value === null&&obj.add_Filter===true)
    if (nullIndex !== -1 && !this.state.getAllProp) {
      filterCriteria["criteria"].splice(nullIndex, 1)

    }
    let zerothIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount" && obj.value === 0&&obj.add_Filter===true)
    if (zerothIndex !== -1 && !this.state.getAllProp) {
      filterCriteria["criteria"].splice(zerothIndex, 1)

    }
    if (this.state.unknownProp && !this.state.getAllProp) {
      propertyValueIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount" && obj.value !== null && obj.value !== 0&&obj.add_Filter===true)
      if (propertyValueIndex !== -1) {
        filterCriteria["criteria"].splice(propertyValueIndex, 1)
      }
      filterCriteria.criteria.push({ key: "PropertyMaxValueAmount", value: null, type: "nexists",add_Filter:true  }, { key: "PropertyMaxValueAmount", value: 0, type: "ne",add_Filter:true  })
    }
    if (this.state.propertyNameScore) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "NameMatchScore"&&obj.add_Filter===true)
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
      filterCriteria.criteria.push({ key: "NameMatchScore", value: parseInt(this.state.propertyNameScore), type: "gte" ,add_Filter:true })
    } else {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "NameMatchScore"&&obj.add_Filter===true)
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
    }

    let confirmedOnsiteIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "confirmedOnsite"&&obj.add_Filter===true)
    if (confirmedOnsiteIndex === -1 && this.state.onSitePropOnly) {
      filterCriteria.criteria.push({ key: "confirmedOnsite", value: this.state.onSitePropOnly, type: "eq",add_Filter:true  })
    } else {
      if (!this.state.onSitePropOnly) {
        filterCriteria.criteria = JSON.parse(JSON.stringify(filterCriteria.criteria.filter(item => item.key !== "confirmedOnsite")))
      }
    }
    if (this.state.propAddedSince) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "opraAddedDate"&&obj.add_Filter===true)
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
      filterCriteria.criteria.push({ key: "opraAddedDate", value: dateFormats.formatDate(this.state.propAddedSince, "MM-DD-yyyy"), type: "dategte" ,add_Filter:true })
    } else {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "opraAddedDate"&&obj.add_Filter===true)
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
    }

    let addressScoreIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "AddressLine1MatchScore"&&obj.add_Filter===true)
    if (addressScoreIndex !== -1) {
      filterCriteria["criteria"].splice(addressScoreIndex, 1)
    }
    if (this.state.addressScore) {
      filterCriteria.criteria.push({ key: "AddressLine1MatchScore", value: parseInt(this.state.addressScore), type: "gte" ,add_Filter:true })
    }
    if (this.state.confirmedOnSiteSince) {
      let onSiteSinceindex = filterCriteria["criteria"].findIndex(obj => obj.key === "confirmedOnsiteDate"&&obj.add_Filter===true)
      if (onSiteSinceindex !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
      filterCriteria.criteria.push({ key: "confirmedOnsiteDate", value: dateFormats.formatDate(this.state.confirmedOnSiteSince, "MM-DD-yyyy"), type: "dategte",add_Filter:true  })
    } else {
      let onSiteSinceindex = filterCriteria["criteria"].findIndex(obj => obj.key === "confirmedOnsiteDate"&&obj.add_Filter===true)
      if (onSiteSinceindex !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
    }


    let propStatusIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatus"&&obj.add_Filter===true)
    if (propStatusIndex !== -1) {
      filterCriteria["criteria"].splice(propStatusIndex, 1)
    }
    if (this.state.propertyStatus && this.state.propertyStatus.length > 0) {
      let data = []
      for (var obj of this.state.propertyStatus) {
        if (obj.status) {
          data.push(obj.status)
        }
      }
      filterCriteria.criteria.push({ key: "propertyStatus", value: data, type: "in",add_Filter:true })
    }

    if (this.state.propertyReason && this.state.propertyReason.length > 0) {
      let data = []
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason"&&obj.add_Filter===true)
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
      for (var obj of this.state.propertyReason) {
        if (obj.reason) {
          data.push(obj.reason)
        }
      }
      filterCriteria.criteria.push({ key: "propertyStatusReason", value: data, type: "in",add_Filter:true  })
    } else {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason"&&obj.add_Filter===true)
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)

      }
    }
    let index = filterCriteria["criteria"].findIndex(obj => obj.key === "sourceState"&&obj.add_Filter===true)
    if (index !== -1) {
      filterCriteria["criteria"].splice(index, 1)
    }
    if (this.state.selectedStates && this.state.selectedStates.length > 0) {
      filterCriteria.criteria.push({ key: "sourceState", value: this.state.selectedStates, type: "in" ,add_Filter:true })
    }
    filterCriteria.page = 1

    const keysArray = [
      "OPP_PropertyID",
      "statePropertyId",
      "stateClaimID",
      "OPPClaimID",
      "propertyDataSource",
      "dataSourceDetail",
      "dealType",
      "OPP_NAUPA_Categories",
      "states"
    ];


    keysArray.forEach((key) => {
      if (this.state[key] && this.state[key].length > 0) {
        highValueBody[key] = key === "stateClaimID" ? this.state[key].split(/\r?\n/).filter(item => item !== "") :
          key === "OPP_PropertyID" || key === "statePropertyId" || key === "OPPClaimID" ? this.handleNewLineData(this.state[key]) :
            key === "propertyDataSource" ? this.state.dataSourceArrayNew :
              this.state[key];
      }
    });
    let additionalFlagForQueryParams = ""
    if (this.state.departmentIdArray && this.state.departmentIdArray.length > 0 && this.state.department && this.state.department.length > 0) {
      additionalFlagForQueryParams = additionalFlagForQueryParams + `&selectedDepartment=${this.state.departmentIdArray}`
    }

    if (this.state.getAllProp) {
      additionalFlagForQueryParams = additionalFlagForQueryParams + `&allProperties=${this.state.propertyValue}`
    }
    if (additionalFlagForQueryParams && additionalFlagForQueryParams.length > 0) {
      highValueBody.additionalFlag = additionalFlagForQueryParams
    } else {
      highValueBody.additionalFlag = "empty"
    }
    this.props.getBasicCall((JSON.parse(JSON.stringify(filterCriteria))), highValueBody)
  };


  resetFilters = async () => {
    await this.setState({
      propertyValue: "50000",
      propertyNameScore: "90",
      addressScore: "",
      dealType: [],
      selectedSeller: "",
      OPP_NAUPA_Categories: [],
      department: [],
      onSitePropOnly: true,
      unknownProp: false,
      dataSourceArrayNew: [],
      getAllProp: false,
      propAddedSince: null,
      confirmedOnSiteSince: null,
      propertyDataSource: [],
      dataSourceDetail: [],
      states: [],
      stateClaimID: "",
      OPPClaimID: "",
      statePropertyId: "",
      OPP_PropertyID: "",
      departmentArray: [],
      departmentIdArray: [],
      propertyStatus: this.state.defaultArray,
    })
    this.setDefaultTeamOnInitialLoad()
    if (this.state.defaultArray && this.state.defaultArray.length > 0) {
      await this.getReasonOptions(this.state.defaultArray.map((item) => item.status), "default")
    } else {
      this.getReasonOptions()
    }
    await this.handleChange({ value: ["CA-N", "SEC", "State UP Data"] }, 'propertyDataSource')
    this.submit()
  }

  handleClearData = (field) => {
    this.setState({
      [field]: ""
    })
    if (field === "propertyStatus") {
      this.setState({
        propertyReason: []
      })
      this.getReasonOptions()
    }
  }

  showClearIcon = (field) => {
    return (
      <span className={'ml-auto'}>
        <FontAwesomeIcon icon={faTimes}
          className={`pl-1 mr-2 clearIcon `}
          color={this.state[field] && (this.state[field].length > 0 || Object.keys(this.state[field]).length > 0) ? '#f9042b91' : 'lightgrey'}
          data-toggle="tool-tip"
          id="clear "
          title="Clear Filter"
          disable={this.state[field] && (this.state[field].length > 0 || Object.keys(this.state[field]).length > 0) ? false : true}
          onClick={() => this.handleClearData(field)}
        />
      </span>
    )
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Col md={12} lg={12} style={{ border: "1px solid lightgrey" }}>
          <Card className="py-2 ">
            <CardBody className="p-0">
              <form className="form" onSubmit={handleSubmit(this.submit)}>
                <div className='w-100 row'>
                  <div class="col-sm-8 mx-0">
                    <Col >
                      <Row>
                        <div className="px-3" style={{ width: "15%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Property Value >="}</span>
                            <span >{this.showClearIcon("propertyValue")}</span>
                          </label>
                          <div >
                            <InputText
                              value={this.state.propertyValue}
                              size={25}
                              keyfilter={"pint"}
                              disabled={this.state.getAllProp}
                              onChange={(e) => this.setState({ propertyValue: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "15%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Name Score >="}</span>
                            <span >{this.showClearIcon("propertyNameScore")}</span>
                          </label>
                          <div >
                            <InputText
                              value={this.state.propertyNameScore}
                              size={25}
                              keyfilter={"pint"}
                              onChange={(e) => this.setState({ propertyNameScore: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "15%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Address Score >="}</span>
                            <span >{this.showClearIcon("addressScore")}</span>
                          </label>
                          <div >
                            <InputText
                              value={this.state.addressScore}
                              size={25}
                              keyfilter={"pint"}
                              onChange={(e) => this.setState({ addressScore: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "15%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Deal Type"}</span>
                            <span >{this.showClearIcon("dealType")}</span>
                          </label>
                          <div >
                            <MultiSelect
                              name={"dealType"}
                              component={MultiSelect}
                              maxSelectedLabels={2}
                              onChange={(e) => this.setState({ dealType: e.value })}
                              type={"dropDown"}
                              filter={true}
                              value={this.state.dealType}
                              style={{ width: "100%" }}
                              options={[{ label: "BK", value: "BK" }, { label: "CR", value: "CR" },]}
                              placeholder={""}
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "40%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Deal"}</span>
                            <span >{this.showClearIcon("selectedSeller")}</span>
                          </label>
                          <div >
                            <AutoComplete
                              value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` : this.state.selectedSeller}
                              suggestions={this.state.filteredSuggestions}
                              completeMethod={this.searchSellerItems}
                              minLength={1}
                              itemTemplate={this.editorTemplateForDeal}
                              field={"EstateName"}
                              style={{ width: "100%", display: "flex" }}
                              dropdown={true}
                              onChange={(e) => this.handleChange(e, 'Seller')}
                              appendTo={document.body} />
                          </div>
                        </div>
                      </Row>
                      <Row className="mt-1">
                        <div className="px-3" style={{ width: "30%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Property Status"}</span>
                            <span >{this.showClearIcon("propertyStatus")}</span>
                          </label>
                          <div >
                            <MultiSelect
                              name={"propertyStatus"}
                              component={MultiSelect}
                              maxSelectedLabels={1}
                              onChange={(e) => this.handleChange(e.value, "Property Status", "in")}
                              type={"dropDown"}
                              filter={true}
                              value={this.state.propertyStatus}
                              style={{ width: "100%" }}
                              options={this.state.statuses}
                              placeholder={""}
                            />
                          </div>
                        </div>
                        <div className="px-3" style={{ width: "30%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Property Reason"}</span>
                            <span >{this.showClearIcon("propertyReason")}</span>
                          </label>
                          <div >
                            <MultiSelect
                              name={"propertyReason"}
                              maxSelectedLabels={1}
                              component={MultiSelect}
                              onChange={(e) => this.handleChange(e.value, "Property Reason", "in")}
                              type={"dropDown"}
                              filter={true}
                              value={this.state.propertyReason}
                              style={{ width: "100%" }}
                              options={this.state.reasons}
                              placeholder={""}
                            />
                          </div>
                        </div>
                        <div className="px-3" style={{ width: "30%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"OPP Naupa Category"}</span>
                            <span >{this.showClearIcon("OPP_NAUPA_Categories")}</span>
                          </label>
                          <div >
                            <MultiSelect
                              name={"oppNaupaCategory"}
                              component={MultiSelect}
                              onChange={(e) => this.setState({ OPP_NAUPA_Categories: e.value })}
                              type={"dropDown"}
                              maxSelectedLabels={1}
                              filter={true}
                              value={this.state.OPP_NAUPA_Categories}
                              style={{ width: "100%" }}
                              options={this.state.modifiedCategories}
                              placeholder={""}
                            />
                          </div>
                        </div>
                        <div className="px-3" style={{ width: "10%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Team"}</span>
                            <span >{this.showClearIcon("department")}</span>
                          </label>
                          <div >
                            <MultiSelect
                              id="department"
                              className={"form__form-group-field "}
                              style={{ width: "100%", height: 32 }}
                              value={this.state.department}
                              filter={true}
                              maxSelectedLabels={1}
                              options={this.state.taskDepartmentItems}
                              onChange={(e) => this.onChangeDepartment(e, "department")}
                              placeholder=''
                            />
                          </div>
                        </div>
                      </Row>
                      <Row className="mt-1">
                        <div className="px-3 pv_v2_header" style={{ width: "20%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Conf. Onsite Properties Only"}</span>
                          </label>
                          <div >
                            <InputSwitch label='onSitePropOnly'
                              id='onSitePropOnly'
                              type='checkbox'
                              name={'onSitePropOnly'}
                              className=""
                              checked={this.state.onSitePropOnly}
                              onChange={(e) => this.setState({ onSitePropOnly: this.state.onSitePropOnly === true ? false : true })}
                            />

                          </div>
                        </div>
                        <div className="px-3 pv_v2_header" style={{ width: "20%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Unknown Properties Only"}</span>
                          </label>
                          <div >
                            <InputSwitch label='unknownProp'
                              id='unknownProp'
                              type='checkbox'
                              name={'unknownProp'}
                              className=""
                              checked={this.state.unknownProp}
                              onChange={(e) => this.setState({ unknownProp: this.state.unknownProp === true ? false : true })}
                            />

                          </div>
                        </div>
                        <div className="px-3 pv_v2_header" style={{ width: "20%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"All Properties (Known+Unknown)"}</span>
                          </label>
                          <div >
                            <InputSwitch label='getAllProp'
                              id='getAllProp'
                              type='checkbox'
                              name={'getAllProp'}
                              className=""
                              checked={this.state.getAllProp}
                              onChange={(e) => this.setState({ getAllProp: this.state.getAllProp === true ? false : true })} />

                          </div>
                        </div>
                        <div className="px-3" style={{ width: "20%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Prop. Added Dt.>="}</span>
                            <span >{this.showClearIcon("propAddedSince")}</span>
                          </label>
                          <div >
                            <Calendar
                              style={{ zindex: 1004, width: "100%", lineHeight: 1.15, height: '25px' }}
                              appendTo={document.body}
                              monthNavigator={true}
                              yearNavigator={true}
                              hourFormat="24"
                              yearRange="1940:2530"
                              name={"calendar1"}
                              placeholder={"Select Date"}
                              dateFormat="mm/dd/yy"
                              value={this.state.propAddedSince}
                              onChange={(e) => this.setState({ propAddedSince: e.value })}
                            />
                          </div>
                        </div>
                        <div className="px-3" style={{ width: "20%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Conf. Onsite Dt.>="}</span>
                            <span >{this.showClearIcon("confirmedOnSiteSince")}</span>
                          </label>
                          <div >
                            <Calendar
                              style={{ zindex: 1004, width: "100%", lineHeight: 1.15, height: '25px' }}
                              appendTo={document.body}
                              monthNavigator={true}
                              yearNavigator={true}
                              hourFormat="24"
                              yearRange="1940:2530"
                              name={"calendar1"}
                              placeholder={"Select Date"}
                              dateFormat="mm/dd/yy"
                              value={this.state.confirmedOnSiteSince}
                              onChange={(e) => this.setState({ confirmedOnSiteSince: e.value })}
                            />
                          </div>
                        </div>
                      </Row>

                    </Col>
                  </div>
                  <div class="col-sm-4 mx-0 px-0">
                    <Col className="px-0">
                      <Row className="mt-1 align-items-end">
                        <div className="px-1 mb-1" style={{ width: "25%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Data Source"}</span>
                            <span >{this.showClearIcon("propertyDataSource")}</span>
                          </label>
                          <div className="form__form-group-field" data-toggle="tool-tip" title={this.state.dataSourceGroups ? "" : "No options available"}>
                            <MultiSelect
                              value={this.state.propertyDataSource}
                              name={"dataSource"}
                              filter={true}
                              style={{ width: "100%" }}
                              appendTo={document.body}
                              maxSelectedLabels={1}
                              options={this.state.dataArray ? this.state.dataArray : []}
                              onChange={(e) => this.handleChange(e, "propertyDataSource")}
                            />
                          </div>
                        </div>
                        <div className="px-1" style={{ width: "25%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Source Detail"}</span>
                            <span >{this.showClearIcon("dataSourceDetail")}</span>
                          </label>
                          <div >
                            <MultiSelect
                              name={"sourceDetail"}
                              component={MultiSelect}
                              onChange={(e) => this.setState({ dataSourceDetail: e.value })}
                              type={"dropDown"}
                              filter={true}
                              maxSelectedLabels={1}
                              value={this.state.dataSourceDetail}
                              style={{ width: "100%" }}
                              options={this.state.modifiedSrcDetails}
                              placeholder={""}
                            />
                          </div>
                        </div>
                        <div className="px-1" style={{ width: "25%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"Source State"}</span>
                            <span >{this.showClearIcon("states")}</span>
                          </label>
                          <div className={this.state.statePropertyId && this.state.statePropertyId.length > 0 && this.state.states.length === 0 ? "custom_border" : ""}>
                            <MultiSelect
                              name={"State"}
                              component={MultiSelect}
                              onChange={(e) => this.setState({ states: e.value })}
                              type={"dropDown"}
                              filter={true}
                              maxSelectedLabels={1}
                              value={this.state.states}
                              style={{ width: "100%" }}
                              options={this.state.statesArray}
                              placeholder={""}
                            />
                          </div>
                        </div>
                        <div className="my-2 mb-1 d-flex " style={{ width: "25%" }}>

                          <Button className="mb-0" style={{ backgroundColor: "#4ea250", color: "white" }} type="submit"
                            disabled={(this.state.statePropertyId && this.state.statePropertyId.length > 0 && this.state.states.length > 0) || !this.state.statePropertyId ? false : true}
                          >Go</Button>
                          <Button className="mb-0" outline style={{ color: "white" }} color="primary" onClick={() => this.resetFilters()}><FontAwesomeIcon icon={faTimes}
                            className={`pl-1 mr-2 clearIcon `}
                            color={'red'}
                            data-toggle="tool-tip"
                            id="clear company"
                          /></Button>

                        </div>
                      </Row>
                      <Row className="mt-1">
                        <div className="px-1" style={{ width: "25%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"State Claim ID"}</span>
                            <span >{this.showClearIcon("stateClaimID")}</span>
                          </label>
                          <div >
                            <InputTextarea
                              value={this.state.stateClaimID}
                              size={25}
                              keyfilter={"pint"}
                              onChange={(e) => this.setState({ stateClaimID: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="px-1" style={{ width: "25%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"OPP Claim ID"}</span>
                            <span >{this.showClearIcon("OPPClaimID")}</span>
                          </label>
                          <div >
                            <InputTextarea
                              value={this.state.OPPClaimID}
                              size={25}
                              keyfilter={"pint"}
                              onChange={(e) => this.setState({ OPPClaimID: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="px-1" style={{ width: "25%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"State Prop. ID"}</span>
                            <span >{this.showClearIcon("statePropertyId")}</span>
                          </label>
                          <div >
                            <InputTextarea
                              value={this.state.statePropertyId}
                              size={25}
                              keyfilter={"pint"}
                              onChange={(e) => this.setState({ statePropertyId: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="px-1" style={{ width: "25%" }}>
                          <label className='d-flex' style={{ marginBottom: 4 }}>
                            <span className='mr-auto'>{"OPP Prop. ID"}</span>
                            <span >{this.showClearIcon("OPP_PropertyID")}</span>
                          </label>
                          <div >
                            <InputTextarea
                              value={this.state.OPP_PropertyID}
                              size={25}
                              keyfilter={"pint"}
                              onChange={(e) => this.setState({
                                OPP_PropertyID: e.target.value
                              })}
                            />
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </div>

                  <Row style={this.state.isShowNote ? { display: "block" } : { display: "none" }} className="mt-2">
                    <span className="mx-4">Default filter: </span>
                    Showing High Value Properties for Property Status as ‘MATCHING-READY FOR MAPPING’ and Property Reason as ‘CNFRMD ONSITE’.
                  </Row>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col >
        {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
        {this.state.sessionWarning ? this.getSessionWraningModal() : null}
      </div>



    );
  }
}




// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
HighValuePropertiesFilters = reduxForm({
  form: "SummaryForm", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(HighValuePropertiesFilters);

// You have to connect() to any reducers that you wish to connect to yourself
HighValuePropertiesFilters = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(HighValuePropertiesFilters);

export default withTranslation('common')(HighValuePropertiesFilters);