import React from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service';
import store from '../../../App/store';
import TableModal from '../../CommonDataTable/TableModal';
import configMessages from '../../../../config/configMessages';
import config from '../../../../config/config'
import HighValuePropertyFilters from '../../HighValuePropertyFilters/components/HighValuePropertyFilters'

// config file
export default class HighValueProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'HighValueProperties',
      defaultstatusArray: [],
      criteria: [{ "key": "confirmedOnsite", "value": true, "type": "eq", add_Filter: true },
      { "key": "PropertyMaxValueAmount", "value": 50000, "type": "gte", add_Filter: true },
      { "key": "NameMatchScore", "value": 90, "type": "gte", add_Filter: true },
      ]
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.getClaimStatusReason()
    await this.getNewStatusReasonsForEditor()
    await this.getDataGroupSources()
  }

  componentWillUnmount = () => {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  /**
*
* @returns the claim status reason array for claims filter dropdown
*/
  getClaimStatusReason = async () => {
    let claimStatusArray = [],
      claimReasonArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq", add_Filter: true },
      { key: "claimStatusFlag", value: true, type: "eq", add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence";
    filterCriteria.direction = "asc";
    await fetch(
      "GET",
      `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(
        filterCriteria
      )}`
    ).then(async (response) => {
      if (
        response &&
        response["propertyStatusReasons"] &&
        response["propertyStatusReasons"].length > 0
      ) {
        let propertyStatusReasons = response["propertyStatusReasons"];
        for (let propertyStatusReason of propertyStatusReasons) {
          if (
            !claimStatusArray.some(
              (obj) => obj.value == propertyStatusReason.status
            )
          ) {
            await claimStatusArray.push({
              label: propertyStatusReason.status,
              value: propertyStatusReason.status,
            });
          }
          if (
            !claimReasonArray.some(
              (obj) => obj.value == propertyStatusReason.reason
            )
          ) {
            await claimReasonArray.push({
              label: propertyStatusReason.reason,
              value: propertyStatusReason.reason,
            });
          }
        }

        await this.setState({
          claimStatusArray: claimStatusArray,
          claimReasonArray: claimReasonArray,
          propertyStatusReasons: propertyStatusReasons,
        });
      } else if (response && response["errorMessage"]) {
        this.dataTableRef.getErrorResponsefromServer(response);
      }
    });
    // return this.state.claimStatusArray;
  };

  getDataGroupSources = async () => {
    let statusArray = [], dataArray = [];
    let filterCriteria = {}
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    let loginRole = loginCredentials.roleName
    let stateDataSource = []
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: "roles", value: loginRole, type: "eq" }];
    await fetch('GET', `${apiCalls.DataSource}?type=group&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["dataSources"] && response["dataSources"].length > 0) {
        let resDataSources = response["dataSources"]
        this.highValuePropertyFiltersRef.getDataGroupSources(resDataSources)
        for (let status of resDataSources) {
          statusArray.push({ label: status.group, value: status.dataSourceNames })
          dataArray.push({ label: status.group, value: status.group })
          if (status.group === "State UP Data" || status.group === "CA-N" || status.group === "SEC") {
            stateDataSource = [...stateDataSource, ...status.dataSourceNames]
          }
        }
        this.setState({ dataSrcResponse: response["dataSources"], dataSourceGroups: statusArray, dataArray: dataArray, stateDataSource: stateDataSource, postbody: { propertyDataSource: stateDataSource } })
      }
    })
    return this.state.settingsData;
  }

  /**
  * 
  * @returns get reasons from propertyStatusandReasonField 
  */
  getNewStatusReasonsForEditor = async (val) => {
    var propertyStatusArray = [], filterCriteria = {}
    var propertyStatusReasonArray = [], defaultArray = [], defaultReasonArray = []
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
    { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          this.highValuePropertyFiltersRef.getStatusOptions(response)
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            let statusIndex = propertyStatusArray.findIndex(obj => obj.label === reason.status)
            if (statusIndex === -1) {
              if (reason.status === config.highValuePropReport.defaultStatus) {
                defaultArray.push(config.highValuePropReport.defaultStatus)
              }
              propertyStatusArray.push({ label: reason.status, value: reason.status })
            }
            let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
            if (reasonIndex === -1) {
              propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          let criteria = this.state.criteria
          if (defaultArray && defaultArray.length > 0) {
            criteria.push({ key: "propertyStatus", value: defaultArray, type: "in", add_Filter: true })
          }
          defaultReasonArray = propertyStatusReasons.filter((propStatus) => propStatus.reason === config.highValuePropReport.defaultReason).map((item) => item.reason)
          if (defaultReasonArray && defaultReasonArray.length > 0) {
            criteria.push({ key: "propertyStatusReason", value: defaultReasonArray, type: "in", add_Filter: true })
          }
          await this.setState({ criteria, criteria, propertyStatusArray: propertyStatusArray, propertyStatusReasonArray: propertyStatusReasonArray, defaultstatusArray: defaultArray, defaultReasonArray: defaultReasonArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [] })
        } else if (response && response['errorMessage'] === configMessages.SESSION_EXPIRATION_WARNING_MESSAGE) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }


  openTableModel = async (item, seller) => {
    await this.setState({
      connectedClaimID: item.connectedClaimID,
      selectedItemforTableShow: item,
      seller: seller,
      isOpenTable: true,
    })
  }

  cancelTable = async () => {
    await this.setState({
      isOpenTable: false
    })
    let criteria = await this.dataTableRef.getFilterCriteria()
    await this.dataTableRef.getDataFromServer(criteria)
  }

  getTableModal() {
    return (
      <TableModal
        onRef={(ref) => this.tableModalRef = ref}
        openTableModal={this.state.isOpenTable}
        cancelReset={this.cancelTable}
        rowData={this.state.selectedItemforTableShow}
        seller={this.state.seller}
        type={"Property Editor"}
        getClaimsTabDetails={this.getClaimsTabDetails}
        openFormModal={this.dataTableRef.openFormModal}
      />
    )
  }


  getDefaultArray = async () => {
    let obj = {
      statuses: this.state.defaultstatusArray,
      reasons: this.state.defaultReasonArray
    }
    return obj
  }


  //get call with API 
  getBasicCall = async (criteria, obj) => {
    await this.setState({
      additionalFilter: JSON.parse(JSON.stringify(criteria)),
      criteria: criteria.criteria,
      postbody: obj
    })
    await this.dataTableRef.setDataCriteriaforOpportunity(criteria, obj)
  }

  additionalCriteria = async (type) => {
    let filter = await this.getFilterCriteria()
    if (!type) {
      filter.criteria = this.state.criteria
    }
    return filter
  }

  getFilterCriteria = async () => {
    return await this.dataTableRef.getFilterCriteria()
  }

  getTaskFilters = () => {
    return (
      <HighValuePropertyFilters
        onRef={(ref) => (this.highValuePropertyFiltersRef = ref)}
        filterCriteria={this.getFilterCriteria}
        getBasicCall={this.getBasicCall}
        setDataCriteriaForOpportunity={this.getBasicCall}
        onChangeDepartment={this.onChangeDepartment}
        dataSourceApiResponse={this.state.dataSrcResponse}
      />
    )
  }


  getDataAfterCustomizations = (modifiedData, response) => {
    const updatedArray = modifiedData.map(item => {
      const match = response.createdByUserList.find(ref => ref.id === item.createdBy);
      if (match) {
        item.createdByUser = match.name
      }
      return item; // Return unchanged object if no match is found
    });
    return updatedArray
  }

  getTableFields = () => {
    let data = [{
      textAlign: "center",
      width: 47,
      field: "",
      header: "",
      selectionMode: "multiple",
      frozen: true,
      show: true,
      mobile: true,
      displayInSettings: false,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'estateName',
      "mobile": true,
      header: 'Deal Name',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    },{
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'dealType',
      "mobile": true,
      header: 'Deal Type',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    }, {
      "show": true,
      textAlign: 'left',
      width: 100,
      field: 'propertyDataSource',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Data Source',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 100,
      field: 'dataSourceDetail',
      "mobile": true,
      header: 'Source Detail',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'sourceState',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Property Source State',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'statePropertyId',
      //fieldType: 'BoolText',
      "mobile": true,
      "fieldType": 'redirect',
      header: 'State Property ID',
      "filterType": "num",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'propertyID',
      //fieldType: 'BoolText',
      "mobile": true,
      "fieldType": 'redirect',
      "filterType": "num",
      header: 'OPP Property ID',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'propertyStatus',
      "filterType": "multiSelect",
      "mobile": true,
      header: "Property Status",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'propertyStatusReason',
      "filterType": "multiSelect",
      "mobile": true,
      header: 'Property Reason',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 100,
      "filterType": "num",
      "field": "propertyMinValueAmount",
      "fieldType": "formatCurrencywithdec",
      "mobile": true,
      header: 'Property Min. Value',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    },
    {
      "show": true,
      "textAlign": "right",
      "width": 100,
      "field": "propertyMaxValueAmount",
      "mobile": true,
      "filterType": "num",
      header: "Property Max. Value",
      "fieldType": "formatCurrencywithdec",
      "displayInSettings": true,
      "filter": true,
      "sortable": true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'claimID',
      "getCustomActions": this.getCustomActions,
      "fieldType": "customActions",
      "mobile": true,
      header: 'State Claim ID',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'connectedClaimID',
      "getCustomActions": this.getCustomActions,
      "fieldType": "customActions",
      "mobile": true,
      header: 'OPP Claim ID',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'claimStatus',
      "filterType": "multiSelect",
      filterOptions: this.state.claimStatusArray,
      "mobile": true,
      header: "Claim Status",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'claimReason',
      "filterType": "multiSelect",
      filterOptions: this.state.claimReasonArray,
      "mobile": true,
      header: 'Claim Reason',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'nameMatchScore',
      "mobile": true,
      header: 'Name Score',
      filter: true,
      "filterType": "num",
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'addressLine1MatchScore',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Address Score',
      // "fieldType": 'redirect',
      filter: true,
      "filterType": "num",
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'businessName',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Scored Name',
      // "fieldType": 'redirect',
      filter: true,
      // "filterType": "num",
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'propertyName',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Reported Owner Name',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'OPP_NAUPA_Category',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'OPP Naupa Category',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'propertyDescription',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Property Description',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'propertyAddressLine1',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Property Address Line1',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'propertyState',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'State',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 100,
      "filterType": "num",
      "field": "escheatmentDate",
      "mobile": true,
      "fieldType": "Date",
      "dateFormat": 'MM/DD/yyyy',
      "header": "Dt. Escheated",
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 80,
      "filterType": "num",
      "field": "createdDate",
      "mobile": true,
      "fieldType": "Date",
      "dateFormat": 'MM/DD/YYYY',
      "header": "Dt. Created",
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 150,
      "field": "createdByUser",
      "mobile": true,
      "header": "Created By User",
      "filter": false,
      "sortable": false,
      "displayInSettings": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 150,
      "filterType": "num",
      "field": "opraAddedDate",
      "mobile": true,
      "fieldType": "Date",
      "dateFormat": 'MM/DD/YYYY',
      "header": "Dt. OPRA Added",
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 115,
      "filterType": "dropdown",
      // "allowInt": true,
      "field": "confirmedOnsite",
      "fieldType": "confirmedOnSite",
      "mobile": true,
      "header": "Confirmed Onsite",
      "displayInSettings": true,
      "filter": true,
      "sortable": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 150,
      "filterType": "num",
      "field": "confirmedOnsiteDate",
      "mobile": true,
      "fieldType": "Date",
      "dateFormat": 'MM/DD/YYYY',
      "header": "Confirmed Onsite Date",
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'confirmedOnsiteBy',
      "filterType": "dropdown",
      "mobile": true,
      header: 'Confirmed On Site By',
      filter: false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'holderName',
      "mobile": true,
      header: 'Holder Name',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      "filterType": "dropdown",
      field: 'hasPropertyCoOwner',
      "fieldType": "hasPropertyCoOwner",
      "mobile": true,
      header: 'Co owner',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    },

    {
      "show": true,
      "textAlign": "left",
      "width": 300,
      "field": "matchStatusNotes",
      "mobile": true,
      "fieldType": "Array",
      "filter": true,
      "openNotes": true,
      // "isFuzzy": true,
      "header": "Property Notes",
      "displayInSettings": true,
      // "filter": false,
      "sortable": true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      "field": "fileFor",
      // //fieldType: 'BoolText',
      "mobile": true,
      "header": "Mapped Name",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      "field": "sellerProof",
      // //fieldType: 'BoolText',
      "mobile": true,
      "header": "Seller Proof",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      "field": "nameProof",
      // //fieldType: 'BoolText',
      "mobile": true,
      "header": "Name Proof",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      "field": "addressProof",
      // //fieldType: 'BoolText',
      "mobile": true,
      "header": "Address Proof",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      "field": "feinProof",
      // //fieldType: 'BoolText',
      "mobile": true,
      "header": "Fein Proof",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      "field": "holderProof",
      // //fieldType: 'BoolText',
      "mobile": true,
      "header": "Holder Proof",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 100,
      "filterType": "num",
      "field": "validatedDate",
      "fieldType": "Date",
      "mobile": true,
      "header": "Dt. Validated",
      "dateFormat": 'MM/DD/yyyy',
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 100,
      "filterType": "num",
      "field": "updatedDate",
      "mobile": true,
      "fieldType": "Date",
      "dateFormat": 'MM/DD/yyyy',
      "header": "Dt. Updated in OPRA2",
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'team',
      "mobile": true,
      header: 'Team',
      filter: false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    },

    ]
    return data;
  };

  getFormFields = () => {
    return ([]);
  }


  //settingRedirectedUrl
  setRedirectUrl = async (item, clickedField, postBodyFile, screen) => {
    let url   
    this.state.postBodyFile = {}
    url = `${window.location.protocol}//${window.location.host}/propertyEditorV2?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item.companyID}${clickedField && clickedField === "propertyID" ? `&propertyId=` + encodeURIComponent(item.propertyID) : clickedField && clickedField === "statePropertyId" ? `&statePropertyId=` + encodeURIComponent(item.statePropertyId) : ""}&bucket=Green"&reportType=HighValueProperty`
    console.log("url", url)
    return url
  }

  submit = async (item) => {
  }


  getColorBasedOnProp = (item) => {
    if (item.connectedClaimID && item.connectedClaimID.length > 0) {
      return "#72261a"
    } else if (item.connectedTaskID && item.connectedTaskID.length > 0) {
      return "#354f6e"
    }
  }

  getCustomActions = (item, column) => {
    if (column.field === "connectedClaimID") {
      return (<>
        <a
          className="redirectToClaims"
          href={`/claims?companyID=${item.dealID
            }&oppClaimID=${item.connectedClaimID
            }&estateName=${encodeURIComponent(item.estateName)}`}
          target="_blank"
        >
          {item[column.field]}
        </a>
      </>
      )
    } else if (column.field === "claimID") {
      return (<>
        <a
          className="redirectToClaims"
          href={`/claims?companyID=${item.dealID
            }&StateClaimID=${item.claimID
            }&estateName=${encodeURIComponent(item.estateName)}`}
          target="_blank"
        >
          {item[column.field]}
        </a>
      </>
      )
    }
  }


  render() {

    return (
      <>
        <Card>
          <CardBody>
            <span>
              {this.state.isOpenTable ? this.getTableModal() : null}
              <div>{this.getTaskFilters()}</div>
              <DataTables
                onRef={(ref) => (this.dataTableRef = ref)}
                getTableFields={this.getTableFields}
                formFields={this.getFormFields}
                sortField={"PropertyMaxValueAmount"}
                customisedExport={true}
                postbody={this.state.postbody}
                exportRequried={false}
                excelDownload={true}
                printRequried={false}
                dontShowTitle={true}
                addRequried={false}
                editRequired={true}
                isClearFilters={true}
                deleteRequired={false}
                viewRequired={true}
                settingsRequired={true}
                filterRequired={true}
                redirecteddUrl={true}
                onlyCoreopsTeam={true}
                paginatorPosition={"top"}
                getColorbasedonProp={this.getColorBasedOnProp}
                criteria={this.state.criteria}
                gridRequried={false}
                sample={false}
                openTableModel={this.openTableModel}
                additionalCriteria={this.additionalCriteria}
                globalSearchFieldName='user'
                isPostCall={true}
                type='HighValueProperties'
                screenPermissionsRoute={"High Value Properties"}
                propertyStatusArray={this.state.propertyStatusArray}
                propertyStatusReasonArray={this.state.propertyStatusReasonArray}
                displayright={true}
                setRedirectUrl={this.setRedirectUrl}
                icon='tag'
                routeTo='reports'
                displayViewOfForm='modal'
                getDataAfterCustomizations={this.getDataAfterCustomizations}
                apiResponseKey={"ns_scored_propertys"}
                apiUrl={"NsScoredProperties/reports/PostListHighValueProperties"}
                entityType='employee'
              />

            </span>
          </CardBody>
        </Card>
      </>

    );
  }
}