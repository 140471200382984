import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import apiCalls from '../../../../config/apiCalls'
import configMessages from '../../../../config/configMessages'
import fetch from '../../../../config/service'
import statesList from '../../../../shared/states.json'
import COTModal from '../COTModal'
import * as documentsApis from "../../../../shared/documentsApis";


// config file
class NamesV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      namesArray: [],
      nameTypeArray: [],
      issueNameOptions: [],
      
      nameConnectionOptions: [],
      draftingCodes: [],
      legacyIssueNames: [],
      states: [...statesList.map((e) => { return { label: e.shortCode, value: e.shortCode } })],
      yesOrNoOptions: [{ label: 'Y', value: 'Y' },
      { label: 'N', value: 'N' },
      ],
      phaseOptions: [
        { label: 'Prospecting', value: 'Prospecting' },
        { label: 'Drafting', value: 'Drafting' },
        { label: 'New Deal Setup', value: 'New Deal Setup' },
        { label: 'SOS Expansion', value: 'SOS Expansion' },
        { label: 'Expansion', value: 'Expansion' },


      ],
      scoringTypeOptions: [
        { label: 'BLANK - Score to Existing Buckets (As Usual)', value: 'BLANK - Score to Existing Buckets (As Usual)' },
        { label: '1 - Score to Additional Buckets for Generic Names', value: '1 - Score to Additional Buckets for Generic Names' },
      ],
      corpStatusAtAPA: [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
        { label: 'Unknown', value: 'Unknown' },

      ],
      entityTreatment: [
        { label: "Seller", value: "Seller" },
        { label: "Predecessor", value: "Predecessor" },
      ]
    };
  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

  }

  //get Hierarchy Dropdown for
  getHierarchyData = async (dimSellerId) => {
    this.setState({ fileForOptions: [] })
    let namesArray = []
    let urlNames = `${apiCalls.HeirarchyCompany}?str=${dimSellerId}`
    fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.companyIDs) {
          for (let name of response.companyIDs) {
            if (name.CompanyName && name.CompanyName !== null && name.CompanyID === dimSellerId.toString()) {
              namesArray.push({ label: name.CompanyName, value: name.CompanyID, BusinessID: name.BusinessID })
            }
          }
          await this.setState({ namesArray: namesArray, dimSellerId: dimSellerId })
        } else {
          this.setState({ namesArray: [], })
        }

      }).catch((err) => {
        return err;
      });

    await this.getIssueNameCode()
    await this.getDraftingNameCodes()
    await this.getNameTypeArrayCode()
    await this.getNameConnectionArrays()
    await this.getLegacyConnectedName(dimSellerId)
    await this.getLegacyIssueNames()
    await this.getIssueNameCodeForLegacyIssues()
    return this.getNamesFields()
  }


  //Issue Names Dropdown
  getLegacyIssueNames = async () => {
    let legacyIssueNames = []
    let legacyIssueNamesQuery = `${apiCalls.getLegacyIssueNames}`;
    await fetch("GET", legacyIssueNamesQuery)
      .then(async (response) => {
        if (response && response.results) {
          for (let name of response.results) {
            if (name.Name && name.Name !== null) {
              legacyIssueNames.push({ label: name.Id, value: name.Id })
            }
          }

          await this.setState({ legacyIssueNames: legacyIssueNames })
        } else {
          this.setState({ legacyIssueNames: [] })
        }
      }).catch((err) => {
        return err;
      });
  }


  getDraftingNameCodes = async () => {
    let draftingCodes = []
    let filtercriteria = { criteria: [{ "key": "DwRowIsCurrent", "value": true, "type": "eq" }], "sortfield": "Sequence", "direction": "desc" }
    let draftingCodesUrl = `${apiCalls.draftingNameCode}?filter=${JSON.stringify(filtercriteria)}&type=exportToCSV`;
    await fetch("GET", draftingCodesUrl)
      .then(async (response) => {
        if (response && response.draftingNameCodeConfigurations) {
          for (let code of response.draftingNameCodeConfigurations) {
            if (code.NameCode && code.NameCode !== null) {
              draftingCodes.push({ label: code.NameCode, value: code.NameCode, title: code.NameCodeDescription, })
            }
          }

          await this.setState({ draftingCodes: draftingCodes })
        } else {
          this.setState({ draftingCodes: [] })
        }
      }).catch((err) => {
        return err;
      });
  }

  //Issue Names Dropdown
  getLegacyConnectedName = async (dimSellerId) => {
    let nameConnectionArray = [], fileForOptions = []
    return fetch('GET', `${apiCalls.sellerRelevanceNames}?estateId=${dimSellerId}&nameType=sellerRelevanceName&type=ConnectedName`)
      .then(async (response) => {
        if (response && response["sellerRelevanceNames"].length > 0) {
          let propertyReasons = response["sellerRelevanceNames"]
          for (let reason of propertyReasons) {
            nameConnectionArray.push({ label: reason.CombinedNameLabel, value: reason.CombinedNameLabel, BusinessName: reason.BusinessName, BusinessNamesID: reason.BusinessNamesID, });
            // mappedNames.push({ label: reason.CombinedName, value: reason.BusinessName, nameID: reason.BusinessNamesID })
          }
          await this.setState({ fileForOptions: nameConnectionArray });
          return fileForOptions()
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }

      }).catch((err) => {
        return err;
      })
  }

  //Issue Names Dropdown
  getIssueNameCode = async () => {
    let issueNameCodes = []
    let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }, { key: "BusinessNameIssueCode", value: "N/A", type: "ne" }] }
    let urlNames = `${apiCalls.businessNameIssueCodeConfiguration}?filter=${JSON.stringify(filter)}`
    await fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.businessNameIssueCodeConfigurations) {
          for (let name of response.businessNameIssueCodeConfigurations) {
            if (name.BusinessNameIssueCode && name.BusinessNameIssueCode !== null) {
              issueNameCodes.push({ label: name.BusinessNameIssueCode, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
            }
          }

          await this.setState({ issueNameOptions: issueNameCodes })
        } else {
          this.setState({ issueNameOptions: [] })
        }
      }).catch((err) => {
        return err;
      });
  }

  //Issue Names Dropdown
  getIssueNameCodeForLegacyIssues = async () => {
    let issueNameOptionsForLegacyIssues = []
    let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }, { key: "BusinessNameIssueCode", value: "N/A", type: "ne" }] }
    let urlNames = `seller/newAndLegacyIssueCodes?filter=${JSON.stringify(filter)}`
    await fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.results) {
          for (let name of response.results) {

            if (name.BusinessNameIssueCode && name.BusinessNameIssueCode !== null) {
              if (!issueNameOptionsForLegacyIssues.some(item => item.label === name.BusinessNameIssueCode)) 
              issueNameOptionsForLegacyIssues.push({ label: name.BusinessNameIssueCode, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
            }
          }

          await this.setState({ issueNameOptionsForLegacyIssues: issueNameOptionsForLegacyIssues })
        } else {
          this.setState({ issueNameOptionsForLegacyIssues: [] })
        }
      }).catch((err) => {
        return err;
      });
  }

  //get Name Type Array
  getNameTypeArrayCode = async () => {
    let nameTypeArray = []
    let url1 = `${apiCalls.distinctNameTypes}`;
    await fetch("GET", url1)
      .then(async (response) => {
        if (response && response.results) {
          for (var result of response.results) {
            if (result.NameType && result.NameType !== null) {
              nameTypeArray.push({ label: result.NameType, value: result.NameType })
            }
          }
          //after getting api response for dropdown options sending table Fields to Datatable
          await this.setState({ nameTypeArray: nameTypeArray })
        } else {
          this.setState({ nameTypeArray: [] })
        }
      }).catch((err) => {
        return err;
      });
    // this.sendColumnDataToServer()
  }

  //get Name Connection Type Array
  getNameConnectionArrays = async () => {
    let nameConnectionArray = []
    let url1 = `${apiCalls.getnameConnectionArrays}`;
    await fetch("GET", url1)
      .then(async (response) => {
        if (response && response.results) {
          for (var result of response.results) {
            if (result.NameConnection && result.NameConnection !== null) {
              nameConnectionArray.push({ label: result.NameConnection, value: result.NameConnection })
            }
          }
          //after getting api response for dropdown options sending table Fields to Datatable
          await this.setState({ nameConnectionArray: nameConnectionArray })
        } else {
          this.setState({ nameConnectionArray: [] })
        }
      }).catch((err) => {
        return err;
      });
    // this.sendColumnDataToServer()
  }

  /*
  For Getting table Fields
  */
  getNamesFields() {
    var data = [{
      textAlign: 'center',
      width: 47,
      field: '',
      header: '',
      selectionMode: 'multiple',
      show: true,
      mobile: true,
      displayInSettings: false
    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 50,
      nonEditField: true,
      fieldType: "customActions",
      getCustomActions: this.customActionsToRedirect,
      field: 'DocUpload',
      isCustum: true,
      header: 'Doc. Upload',
      filter: false,
      sortable: false
    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.scoringTypeOptions,
      width: 200,
      field: 'ScoringType',
      tooltip: 'Indicates whether a given name is generic or not. It will be BLANK (Default for Non-Generic Names) or 1 (For Generic Names).',
      header: 'Scoring Type',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'PreSaleNameCode',
      headerBackgroundColor: "#DDD9C3",
      fieldType: "Array",
      editField: "multiDropdown",
      tooltip: "Issue codes used by the PreSales team while the DealStatus='PreSales'. For PreSale - Name Code, values will be similar to Old Issue Name/Legacy Name Code -- Example: 1,2,3,7 etc.",
      options: this.state.legacyIssueNames,
      header: 'PreSales - Name Code',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      editField: "dropdown",
      tooltip: "Indicates the type of connection between the Name and Connected Name identified by the PreSales team.",
      options: this.state.nameConnectionArray,
      field: 'PreSaleConnectionType',
      headerBackgroundColor: "#DDD9C3",
      header: 'PreSales - Connection Type',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      dataKey: "BusinessNamesID",
      fieldType: "formatedDataKey",
      options: this.state.fileForOptions,
      tooltip: "Indicates the connected name tied to the Name identified by the PreSales team.",
      width: 200,
      field: 'PreSaleConnectedNameLabel',
      headerBackgroundColor: "#DDD9C3",
      header: 'PreSales - Connected Name',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      tooltip: "Unique ID assigned to an entity by Factset, updated by PreSales team.",
      field: 'PreSaleFactSetID',
      header: 'PreSales - FactSet ID',
      headerBackgroundColor: "#DDD9C3",
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      tooltip: "Unique ID assigned to an entity by PitchBook, updated by PreSales team.",
      field: 'PreSalePitchBookID',
      headerBackgroundColor: "#DDD9C3",
      header: 'PreSales - PitchBook ID',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'PreSaleOtherID',
      headerBackgroundColor: "#DDD9C3",
      tooltip: "Unique ID assigned to an entity by an outlier source, updated by PreSales team.",
      header: 'PreSales - Other ID',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      editField: "multiDropdown",
      fieldType: "Array",
      options: this.state.draftingCodes,
      field: 'DraftingNameCode',
      headerBackgroundColor: "#E6E0EC",
      tooltip: "Issue codes used by the Legal team while the DealStatus='APA Drafting'. For Drafting - Name Code, Values will be similar to Issues such as 1.1, 1.2, 1.3, 1.4, 2, 3, 7.1, 7.2, 7.3, 7.4, 9.1, 9.2, 9.3, 9.4 etc",
      header: 'Drafting Name Code',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      tooltip: "Indicates Name Source from which the Name for identified by the PreSales team. ",
      field: 'NameSource',
      header: 'Originating - Name Source',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      field: 'IssueName',
      editField: "multiDropdown",
      fieldType: "Array",
      options: this.state.issueNameOptions,
      header: 'PostSale - Name Code',
      headerBackgroundColor: "#FDEADA",
      tooltip: "Issue codes used by the CoreOps team while the DealStatus='Owned'. For PostSale - Name Code, Values will be similar to Issues such as 1.1, 1.2, 1.3, 1.4, 2, 3, 7.1, 7.2, 7.3, 7.4, 9.1, 9.2, 9.3, 9.4 etc",
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      field: 'LegacyIssues',
      editField: "dropdown",
      headerBackgroundColor: "#FDEADA",
      options: this.state.issueNameOptionsForLegacyIssues,
      header: 'Legacy Issues',
      filter: true,
      sortable: true,
      tooltip: 'Legacy issue code used in OPRA1 and OPRA2 for the deals that were signed prior to 2022/2023? and values will be similar to Old Issue Name -- Example: 1,2,3,7 etc.'
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.phaseOptions,
      required: true,
      width: 200,
      field: 'Phase',
      header: 'Phase',
      headerColor: "red",
      filter: true,
      sortable: true,
      tooltip: 'Indicates when the Name was added into the Name Sheet. Values are: Prospecting (PreSale Team), Drafting (Legal Team), New Deal Setup/Exhibit-B Development/Expansion (CoreOps Team) and should align with the business unit that added a name'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      tooltip: "This attribute will be populated with one or more documents (pdf files) that are available/loaded in the OPRA2 system. The evidence document should support the Name Connection/Connected Name",
      field: 'NameEvidence',
      header: 'Name Evidence',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      required: true,
      field: 'BusinessName',
      header: 'Business Name',
      filter: true,
      headerColor: "red",
      sortable: true,
      tooltip: 'Name associated with the Deal.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      required: true,
      editField: "dropdown",
      required: true,
      headerColor: "red",
      tooltip: "Indicates the type of connection between the Name and Connected Name identified by the CoreOps Team.",
      options: this.state.nameConnectionArray,
      field: 'NameConnection',
      header: 'PostSale - Connection Type',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      dataKey: "BusinessNamesID",
      width: 200,
      fieldType: "formatedDataKey",
      required: true,
      tooltip: "Indicates the connected name tied to the Name identified by the CoreOps Team.",
      options: this.state.fileForOptions,
      field: 'LegacyConnectedNameLabel',
      headerColor: "red",
      header: 'PostSale - Connected Name',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      nonEditField: true,
      width: 50,
      tooltip: "This attrbute is shown as a button in OPRA2 which users can click to view the configured Chain Of Titile associated with a Name. ",
      field: 'COT',
      fieldType: "customActions",
      getCustomActions: this.customActionsToRedirect,
      isCustum: true,
      header: 'Chain of Title',
      filter: false,
      sortable: false
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      keyfilter: "pint",
      filterType: "num",
      "allowInt": true,
      field: 'ConnectionYear',
      header: 'Connection Year',
      filter: true,
      sortable: true,
      tooltip: 'The corresponding year related to the transaction described by the name connection/connected name. For selling parties, this would be the year in which the status evidenced that will be provided was filed with the governing entity. For non-selling parties, this will be the year associated with the chain of title described in PostSale - Connection Type/PostSale - Connected Name, as evidenced by the PostSale - Name Evidence field'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'date',
      width: 200,
      tooltip: "If applicable, the date in which recoveries cannot precede",
      field: 'DateRestrictionStartDate',
      header: 'Date Restriction Start Date',
      filter: true,
      sortable: true,
      fieldType: 'dateTime',
      filterType: 'num'
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'date',
      width: 200,
      tooltip: "If applicable, the date in which recoveries cannot postdate",
      field: 'DateRestrictionEndDate',
      header: 'Date Restriction End Date',
      filter: true,
      sortable: true,
      fieldType: 'dateTime',
      filterType: 'num'
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      tooltip: "State in which a given legal entity is domiciled (i.e. registered to do business). ",
      options: this.state.states,
      width: 200,
      field: 'StateOfIncorporation',
      header: 'Domicile',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      tooltip: "Secretary Of State (SOS) database ID for a given legal entity for the respective domicile state. ",
      field: 'SOSId',
      header: 'Domicile/SOS ID',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'date',
      width: 200,
      tooltip: "Date in which an entity was incorported in its domicile state",
      field: 'DomicileNameEffectiveStartDate',
      header: 'Domicile Name Effective Start Date',
      filter: true,
      sortable: true,
      fieldType: 'dateTime',
      filterType: 'num'
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'date',
      width: 200,
      tooltip: "Date in which an entity went inactive in its domicile state, to the extent applicable",
      field: 'DomicileNameEffectiveEndDate',
      header: 'Domicile Name Effective End Date',
      filter: true,
      sortable: true,
      fieldType: 'dateTime',
      filterType: 'num'
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'LegacyEIN1',
      tooltip: 'FEIN/EIN for a legal entitty name.',
      header: 'EIN1',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'LegacyEIN1Source',
      header: 'EIN1 Source',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'date',
      width: 200,
      field: 'LegacyEIN1DateAdded',
      header: 'EIN1 Date Added',
      filter: true,
      sortable: true,
      fieldType: 'dateTime',
      filterType: 'num',
      tooltip: 'FEIN/EIN date when the FEIN/EIN value was added into OPRA1/OPRA2.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'date',
      width: 200,
      tooltip: "Date as of which the Corporate Status value was last known.",
      field: 'CorpStatusAtAPADateChecked',
      header: 'Corp Status At APA - Date Checked',
      filter: true,
      sortable: true,
      fieldType: 'dateTime',
      filterType: 'num'
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      width: 200,
      options: this.state.corpStatusAtAPA,
      fieldType: "showTextColor",
      highLightNo: "Inactive",
      field: 'CorpStatusAtAPA',
      header: 'Corp Status At APA',
      filter: true,
      sortable: true,
      tooltip: 'Corporate Status of a legal entity name at the APA date.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      fieldType: "customActions",
      getCustomActions: this.customActionsToRedirect,
      field: 'CorpStatusEvidenceList',
      header: 'Corp Status Evidence',
      headerBackgroundColor: "#FDEADA",
      filter: true,
      sortable: true,
      tooltip: 'This attribute will be populated with one or more documents (pdf files) that are available/loaded in the OPRA2 system.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      fieldType: "customActions",
      getCustomActions: this.customActionsToRedirect,
      field: 'SignatoryConnectionEvidenceList',
      header: 'Signatory Connection Evidence',
      headerBackgroundColor: "#FDEADA",
      filter: true,
      sortable: true,
      tooltip: 'This attribute will be populated with one or more documents (pdf files) that are available/loaded in the OPRA2 system.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      fieldType: "customActions",
      getCustomActions: this.customActionsToRedirect,
      field: 'UPDeclarationEvidenceList',
      header: 'UP Declaration Evidence',
      headerBackgroundColor: "#FDEADA",
      filter: true,
      sortable: true,
      tooltip: 'This attribute will be populated with one or more documents (pdf files) that are available/loaded in the OPRA2 system.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      fieldType: "customActions",
      getCustomActions: this.customActionsToRedirect,
      field: 'AffirmationFormEvidenceList',
      header: 'Affirmation Form Evidence',
      headerBackgroundColor: "#FDEADA",
      filter: true,
      sortable: true,
      tooltip: 'This attribute will be populated with one or more documents (pdf files) that are available/loaded in the OPRA2 system.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'LegacyGeneralNote',
      header: 'General Notes',
      filter: true,
      headerBackgroundColor: "#FDEADA",
      sortable: true,
      tooltip: 'Notes associated with a configured Name.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      tooltip: "Notes tied to why a configured name has a Category 1 Issue Code",
      field: 'LegacyCategory1Issues',
      header: 'Category1 Notes',
      filter: true,
      sortable: true
    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      tooltip: "Notes tied to why a configured name has a Category 2 Issue Code",
      field: 'LegacyCategory2Issues',
      header: 'Category2 Notes',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      tooltip: "Notes tied to why a configured name has a Category 7/9 Issue Code",
      field: 'LegacyCategory7Issues',
      header: 'Category7 Notes',
      filter: true,
      sortable: true
    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.entityTreatment,
      width: 200,
      field: 'EntityTreatment',
      header: 'Entity Treatment',
      headerBackgroundColor: "#D9D9D9",
      filter: true,
      sortable: true,
      tooltip: '[Legal team to update]'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      headerBackgroundColor: "#D9D9D9",
      width: 100,
      field: 'ExpresslyListedOnExB',
      header: 'Expressly Listed On Ex B',
      filter: true,
      sortable: true,
      tooltip: '[Legal team to update]'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      headerBackgroundColor: "#D9D9D9",
      field: 'SellerClassActionRights',
      header: 'Seller Class Action Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      field: 'PredecessorClassActionRights',
      header: 'Predecessor Class Action Rights',
      headerBackgroundColor: "#D9D9D9",
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      field: 'SellerRoyaltyRights',
      header: 'Seller Royalty Rights',
      headerBackgroundColor: "#D9D9D9",
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      field: 'PredecessorRoyaltyRights',
      headerBackgroundColor: "#D9D9D9",
      header: 'Predecessor Royalty Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      headerBackgroundColor: "#D9D9D9",
      field: 'SellerMineralRights',
      header: 'Seller Mineral Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      headerBackgroundColor: "#D9D9D9",
      field: 'PredecessorMineralRights',
      header: 'Predecessor Mineral Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      field: 'SellerCapitalCreditRights',
      headerBackgroundColor: "#D9D9D9",
      header: 'Seller Capital Credit Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      field: 'PredecessorCapitalCreditRights',
      headerBackgroundColor: "#D9D9D9",
      header: 'Predecessor Capital Credit Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      field: 'SellerIPv4Rights',
      header: 'Seller IPv4 Rights',
      headerBackgroundColor: "#D9D9D9",
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      headerBackgroundColor: "#D9D9D9",
      field: 'PredecessorIPv4Rights',
      header: 'Predecessor IPv4 Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      field: 'SellerPaymentRights',
      headerBackgroundColor: "#D9D9D9",
      header: 'Seller Payment Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      headerBackgroundColor: "#D9D9D9",
      field: 'PredecessorPaymentRights',
      header: 'Predecessor Payment Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      headerBackgroundColor: "#D9D9D9",
      field: 'SellerRegistryFundRights',
      header: 'Seller Registry Fund Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'dropdown',
      options: this.state.yesOrNoOptions,
      fieldType: "showTextColor",
      highLightNo: "N",
      width: 100,
      field: 'PredecessorRegistryFundRights',
      headerBackgroundColor: "#D9D9D9",
      header: 'Predecessor Registry Fund Rights',
      filter: true,
      sortable: true,
      tooltip: 'A Y or N value to indicate right associated with a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'ClassActionExclusionsAndRestrictions',
      header: 'Class Action Exclusions And Restrictions',
      filter: true,
      headerBackgroundColor: "#D9D9D9",
      sortable: true,
      tooltip: 'Restrictions note associated for the specified category for a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'RoyaltyExclusionsAndRestrictions',
      header: 'Royalty Exclusions And Restrictions',
      headerBackgroundColor: "#D9D9D9",
      filter: true,
      sortable: true,
      tooltip: 'Restrictions note associated for the specified category for a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'MineralExclusionsAndRestrictions',
      headerBackgroundColor: "#D9D9D9",
      header: 'Mineral Exclusions And Restrictions',
      filter: true,
      sortable: true,
      tooltip: 'Restrictions note associated for the specified category for a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'CapitalCreditExclusionsAndRestrictions',
      headerBackgroundColor: "#D9D9D9",
      header: 'Capital Credit Exclusions And Restrictions',
      filter: true,
      sortable: true,
      tooltip: 'Restrictions note associated for the specified category for a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'PaymentRightsExclusionsAndRestrictions',
      header: 'Payment Rights Exclusions And Restrictions',
      headerBackgroundColor: "#D9D9D9",
      filter: true,
      sortable: true,
      tooltip: 'Restrictions note associated for the specified category for a Name'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'RegistryFundRightsExclusionsAndRestrictions',
      header: 'Registry Fund Rights Exclusions And Restrictions',
      headerBackgroundColor: "#D9D9D9",
      filter: true,
      sortable: true,
      tooltip: 'Restrictions note associated for the specified category for a Name'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'CourtCaseKey',
      header: 'Court Case Key',
      filter: true,
      sortable: true,
      tooltip: 'Indicates the court case key for the Bankruptcy deal.'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      field: 'LeadCourtCaseKey',
      header: 'Lead Court Case Key',
      filter: true,
      sortable: true,
      tooltip: 'Indicates the lead court case key for the Bankruptcy deal.'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      fieldType: "customActions",
      getCustomActions: this.customActionsToRedirect,
      width: 200,
      field: 'Website',
      header: 'Website',
      filter: true,
      sortable: true,
      tooltip: 'Indicates any additional reference to the websites.'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      field: 'DWDateInserted',
      header: 'Created Date',
      filter: true,
      sortable: true,
      fieldType: 'dateTime',
      filterType: 'num',
      tooltip: 'Indicates the date on which a Name was last created.'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      field: 'DWDateLastModified',
      header: 'Last Updated Date',
      filter: true,
      sortable: true,
      fieldType: 'dateTime',
      filterType: 'num',
      tooltip: 'Indicates the date on which a Name was last updated.'

    },
    {
      show: true,
      mobile: true,
      textAlign: 'left',
      // editField: 'text',
      width: 200,
      field: 'UpdatedByUserName',
      header: 'Updated By User Name',
      filter: true,
      sortable: true,
      tooltip: 'Indicates name of the OPRA2 user who last updated a Name.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      // editField: 'text',
      width: 200,
      field: 'CreatedByUserName',
      header: 'Created By User Name',
      filter: true,
      sortable: true,
      tooltip: 'Indicates name of the OPRA2 user who added a Name.'

    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      tooltip: "This is the system generated unique ID for a Name that is configured within a deal or a prospective deal.",
      field: 'LegacyEstateNameID',
      header: 'Legacy Estate Name ID',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      editField: 'text',
      width: 200,
      editField: "dropdown",
      options: this.state.namesArray,
      field: 'CompanyID',
      header: 'Company ID',
      filter: true,
      sortable: true
    }, {
      show: true,
      mobile: true,
      textAlign: 'left',
      width: 200,
      field: 'BusinessNamesID',
      isRequired: true,
      header: 'Bussiness Name ID',
      "filter": true,
      "sortable": true,
      // sortable: true
    },]



    return data;
  }

  /*
   For Getting required Fields to save or edit record
   */
  getRequiredFieldsForScreen() {
    let array = ["Phase", "BusinessName"]
    return array
  }
  /*
For Getting required Fields to save or edit record
*/
  getAnyOneRequiredFieldsForScreen() {
    let array = []
    return array
  }

  redirectToPDFEditor = (e, data) => {
    e.stopPropagation()
    documentsApis.redirectToPDFEditor(data, '', data._id)
  }

  customActionsToRedirect = (item, tableItem, column, role) => {
    if (tableItem.field === "DocUpload") {
      return <span>
        <FontAwesomeIcon
          color='green'
          icon={faUpload}
          // disabled={this.props.preSalePermission!=="Edit"?true:false}
          data-toggle="tool-tip"
          className='my-0 mx-0'
          onClick={this.props.preSalePermission !== "Edit" ? null : (e) => this.redirectToDocUpload(e)}
        />
      </span>
    } else if (tableItem.field === "COT") {
      return <span style={{ fontSize: "12px" }}>
        <Button
          className="mb-0 py-0 paddingNone"
          color={"primary"}
          size="sm"
          onClick={() => this.setState({ isOpenCOTModal: true, selectedItem: item })}
        >COT
        </Button>
      </span>
    } else if (tableItem.field.includes("EvidenceList")) {
      let data = item[column.field] && item[column.field].Details && item[column.field].Details.length > 0 ? item[column.field].Details[0] : null
      return data ? <div className="cursor-pointer text-info fileNamestyled " data-toggle="tool-tip" title={data ? data.FileName : ""} onClick={(e) => this.redirectToPDFEditor(e, data)} data-placement="left">
        {data.IsFileCommonForMultiSubCategories ? <span className="multiRepFile">{data.FileName}</span> : <span>{data.FileName}</span>}
      </div> : ""


    } else if (tableItem.fieldType === "download") {
      return <span data-toggle="tool-tip" title={item[column.field]}>
        <a className="fileNamestyled p-0" href={item[column.field]} download target="_blank">{item[column.field]}</a>
      </span>
    } else if (tableItem.field === "Website") {
      return <span data-toggle="tool-tip" title={item[column.field]}>
        <a className="fileNamestyled p-0 d-block" style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`${item[column.field]}`}>{item[column.field]}</a>
      </span>
    }
  }

  /*
  For Adding new Record Setting Empty Obj
  */
  getNewRecord = () => {
    let newRec = {
      ScoringType: null,
      PresaleNameCode: null,
      PresaleConnectionType: null,
      PreSaleConnectedName: null,
      PreSaleFactSetID: null,
      PreSalePitchBookID: null,
      PreSaleOtherID: null,
      DraftingNameCode: null,
      OriginatingNameSource: null,
      PostSaleNameCode: null,
      LegacyIssues: null,
      Phase: null,
      NameEvidence: null,
      BusinessName: null,
      PostSaleConnectionType: null,
      LegacyConnectedName: null,
      ConnectionYear: null,
      DateRestrictionStartDate: null,
      DateRestrictionEndDate: null,
      StateOfIncorporation: null,
      SosId: null,
      DomicileNameEffectiveStartDate: null,
      DomicileNameEffectiveEndDate: null,
      LegacyEIN1: null,
      LegacyEIN1Source: null,
      LegacyEIN1DateAdded: null,
      CorpStatusAtAPADateChecked: null,
      CorpStatusAtAPA: null,
      CorpStatusEvidence: null,
      SignatoryConnectionEvidence: null,
      UpDeclarationEvidence: null,
      AffirmationFormEvidence: null,
      GeneralNote: null,
      LegacyCategory1Issues: null,
      LegacyCategory2Issues: null,
      LegacyCategory7Issues: null,
      EntityTreatment: null,
      ExpresslyListedOnExB: null,
      SellerClassActionRights: null,
      PredecessorClassActionRights: null,
      SellerRoyalityRights: null,
      PredecessorRoyaltyRights: null,
      SellerMineralRights: null,
      PredecessorMineralRights: null,
      SellerCapitalCreditRights: null,
      PredecessorCapitalCreditRights: null,
      SellerIPv4Rights: null,
      PredecessorIPv4Rights: null,
      SellerPaymentRights: null,
      PredecessorPaymentRights: null,
      SellerRegistryFundRights: null,
      PredecessorRegistryFundRights: null,
      ClassActionExclusionsAndRestrictions: null,
      RoyaltyExclusionsAndRestrictions: null,
      MineralExclusionsAndRestrictions: null,
      CapitalCreditExclusionsAndRestrictions: null,
      PaymentRightsExclusionsAndRestrictions: null,
      RegistryFundRightsExclusionsAndRestrictions: null,
      CourtCaseKey: null,
      LeadCourtCaseKey: null,
      Website: null,
      // DwDateLastModified: null,
      // DwLastModifiedBy: null,
      LegacyEstateNameID: null,
      CompanyID: null
    }
    return newRec
  }

  redirectToDocUpload = (e) => {
    e.stopPropagation();
    let dealId = window.location.pathname.split("/")[3]
    if (dealId)
      window.open(`${window.location.protocol}//${window.location.host}/sellersEdit/documentUpload/${dealId}`);

  }

  openCOTDetailsModal = (item) => {
    return (
      <COTModal
        isOpenCOTModal={this.state.isOpenCOTModal}
        closeCOTModal={this.closeCOTModal}
        selectedItem={this.state.selectedItem}
      />
    )
  }

  closeCOTModal = () => {
    this.setState({ isOpenCOTModal: false })
  }

  render() {
    return (
      <>{null}
        {this.state.isOpenCOTModal ? this.openCOTDetailsModal() : null}
      </>


    );
  }
}
export default withTranslation('common')(NamesV2);







// AccuralDateRestrictionStartDate
// AccuralDateRestrictionEndDate
// NameEffectiveStartDate
// NameEffectiveEndDate
// GeneralNote
// DWDateLastModified