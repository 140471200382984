import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import apiCalls from '../../../../config/apiCalls'
import configMessages from '../../../../config/configMessages'
import fetch from '../../../../config/service'

// config file
class NamesScreenProps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      namesArray: [],
      nameTypeArray: [],
      issueNameOptions: [],
      nameConnectionOptions: [],
      legacyIssueNames: []
    };
  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

  }

  //get Hierarchy Dropdown for
  getHierarchyData = async (dimSellerId) => {
    this.setState({ fileForOptions: [] })
    let namesArray = []
    let urlNames = `${apiCalls.HeirarchyCompany}?str=${dimSellerId}`
    fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.companyIDs) {
          for (let name of response.companyIDs) {
            if (name.CompanyName && name.CompanyName !== null) {
              namesArray.push({ label: name.CompanyName, value: name.CompanyID, BusinessID: name.BusinessID })
            }
          }

          await this.setState({ namesArray: namesArray, dimSellerId: dimSellerId })
        } else {
          this.setState({ namesArray: [], })
        }

      }).catch((err) => {
        return err;
      });

    await this.getIssueNameCode()
    await this.getnameTypeArrayCode()
    await this.getnameConnectionArrays()
    await this.getLegacyConnectedName(dimSellerId)
    await this.getLegacyIssueNames()
    await this.getIssueNameCodeForLegacyIssues()
    return this.getnamesFields()
  }


  //Issue Names Dropdown
  getLegacyIssueNames = async () => {
    let legacyIssueNames = []
    let legacyIssueNamesQuery = `${apiCalls.getLegacyIssueNames}`;
    await fetch("GET", legacyIssueNamesQuery)
      .then(async (response) => {
        if (response && response.results) {
          for (let name of response.results) {
            if (name.Name && name.Name !== null) {
              legacyIssueNames.push({ label: name.Id, value: name.Id })
            }
          }

          await this.setState({ legacyIssueNames: legacyIssueNames })
        } else {
          this.setState({ legacyIssueNames: [] })
        }
      }).catch((err) => {
        return err;
      });
  }

  //Issue Names Dropdown
  getIssueNameCodeForLegacyIssues = async () => {
    let issueNameOptionsForLegacyIssues = []
    let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }, { key: "BusinessNameIssueCode", value: "N/A", type: "ne" }] }
    let urlNames = `seller/newAndLegacyIssueCodes?filter=${JSON.stringify(filter)}`
    await fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.results) {
          for (let name of response.results) {
            if (name.BusinessNameIssueCode && name.BusinessNameIssueCode !== null) {
              if (!issueNameOptionsForLegacyIssues.some(item => item.label === name.BusinessNameIssueCode))
                issueNameOptionsForLegacyIssues.push({ label: name.BusinessNameIssueCode, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
            }
          }

          await this.setState({ issueNameOptionsForLegacyIssues: issueNameOptionsForLegacyIssues })
        } else {
          this.setState({ issueNameOptionsForLegacyIssues: [] })
        }
      }).catch((err) => {
        return err;
      });
  }

  //Issue Names Dropdown
  getLegacyConnectedName = async (dimSellerId) => {
    let nameConnectionArray = [], fileForOptions = []
    return fetch('GET', `${apiCalls.sellerRelevanceNames}?estateId=${dimSellerId}&nameType=sellerRelevanceName&type=ConnectedName`)
      .then(async (response) => {
        if (response && response["sellerRelevanceNames"].length > 0) {
          let propertyReasons = response["sellerRelevanceNames"]
          for (let reason of propertyReasons) {
            nameConnectionArray.push({ label: reason.CombinedNameLabel, value: reason.CombinedNameLabel, BusinessName: reason.BusinessName, BusinessNamesID: reason.BusinessNamesID });
            // mappedNames.push({ label: reason.CombinedName, value: reason.BusinessName, nameID: reason.BusinessNamesID })
          }
          await this.setState({ fileForOptions: nameConnectionArray });
          return fileForOptions()
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }

      }).catch((err) => {
        return err;
      })
  }

  //Issue Names Dropdown
  getIssueNameCode = async () => {
    let issueNameCodes = []
    let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }, { key: "BusinessNameIssueCode", value: "N/A", type: "ne" }] }
    let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
    await fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.businessNameIssueCodeConfigurations) {
          for (let name of response.businessNameIssueCodeConfigurations) {
            if (name.BusinessNameIssueCode && name.BusinessNameIssueCode !== null) {
              issueNameCodes.push({ label: name.BusinessNameIssueCode, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
            }
          }

          await this.setState({ issueNameOptions: issueNameCodes })
        } else {
          this.setState({ issueNameOptions: [] })
        }
      }).catch((err) => {
        return err;
      });
  }

  //get Name Type Array
  getnameTypeArrayCode = async () => {
    var tablefieldsToShow = this.state.tableFieldsfromParentComponents
    let nameTypeArray = []
    let url1 = `${apiCalls.distinctNameTypes}`;
    await fetch("GET", url1)
      .then(async (response) => {
        if (response && response.results) {
          for (var result of response.results) {
            if (result.NameType && result.NameType !== null) {
              nameTypeArray.push({ label: result.NameType, value: result.NameType })
            }
          }
          //after getting api response for dropdown options sending table Fields to Datatable
          await this.setState({ nameTypeArray: nameTypeArray })
        } else {
          this.setState({ nameTypeArray: [] })
        }
      }).catch((err) => {
        return err;
      });
    // this.sendColumnDataToServer()
  }

  //get Name Connection Type Array
  getnameConnectionArrays = async () => {
    var tablefieldsToShow = this.state.tableFieldsfromParentComponents
    let nameConnectionArray = []
    let url1 = `${apiCalls.getnameConnectionArrays}`;
    await fetch("GET", url1)
      .then(async (response) => {
        if (response && response.results) {
          for (var result of response.results) {
            if (result.NameConnection && result.NameConnection !== null) {
              nameConnectionArray.push({ label: result.NameConnection, value: result.NameConnection })
            }
          }
          //after getting api response for dropdown options sending table Fields to Datatable
          await this.setState({ nameConnectionArray: nameConnectionArray })
        } else {
          this.setState({ nameConnectionArray: [] })
        }
      }).catch((err) => {
        return err;
      });
    // this.sendColumnDataToServer()
  }

  /*
  For Getting table Fields
  */
  getnamesFields() {
    var data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //     "show": true,
      //     "textAlign": "left",
      //     "width": 90,
      //     "fieldType": 'Actions',
      //     "field": "Actions",
      //     "header": "Actions",
      //     "filter": false,
      //     "displayInSettings": true,
      //     "sortable": false
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'CompanyID',
        editField: "dropdown",
        options: this.state.namesArray,
        required: true,
        header: 'Deal ID',
        isAdd: true,
        "filter": true,
        "sortable": true,
        // filter: false,
        // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 130,
        editField: "text",
        keyfilter: "pint",
        field: 'DataSourceID',
        "filterType": "num",
        header: 'Data Source ID',
        "filter": true,
        "sortable": true,
        // filter: false,
        // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 350,
        //editField: "text",
        isRequired: true,
        required: true,
        field: 'BusinessName',
        header: 'Company Names/DBAs',
        "filter": true,
        "sortable": true,
        // filter: false,
        // sortable: true
      },
      // {
      //   show: true,
      //   mobile: true,
      //   textAlign: 'left',
      //   editField: "text",
      //   width: 200,
      //   //editField: "text",
      //   field: 'OldIssueName',
      //   isRequired: true,
      //   header: 'Old Issue Name',
      //   "filter": true,
      //   "sortable": true,
      //   // sortable: true
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "dropdown",
        options: this.state.issueNameOptionsForLegacyIssues,
        width: 200,
        //editField: "text",
        field: 'LegacyIssues',
        isRequired: true,
        header: 'Old Issue Name',
        required: true,
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "dropdown",
        options: this.state.nameTypeArray,
        width: 200,
        required: true,
        field: 'NameType',
        header: 'Name Type',
        "filter": true,
        "sortable": true,
        // filter: false,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "dropdown",
        options: this.state.nameConnectionArray,
        required: true,
        // [{ label: "Acquisition (Asset)", value: "Acquisition (Asset)" },
        // { label: "Acquisition (Non-Specified)", value: "Acquisition (Non-Specified)" },
        // { label: "Acquisition (Stock)", value: "Acquisition (Stock)" },
        // { label: "Conversion (Non-Survivor)", value: "Conversion (Non-Survivor)" },
        // { label: "Conversion (Survivor)", value: "Conversion (Survivor)" },
        // // { label: "Division", value: "Division" },
        // { label: "Former Name/SOS Name Change", value: "Former Name/SOS Name Change" },
        // { label: "Joint Debtor", value: "Joint Debtor" },
        // { label: "Judegement Debtor", value: "Judgement Debtor" },
        // { label: "Lead Debtor", value: "Lead Debtor" },
        // { label: "Merger (Non-Survivor)", value: "Merger (Non-Survivor)" },
        // { label: "Merger (Survivor)", value: "Merger (Survivor)" },
        // { label: "Name Variation/Truncation", value: "Name Variation/Truncation" },
        // { label: "Oak Point APA Lead Seller", value: "Oak Point APA Lead Seller" },
        // { label: "Oak Point APA Seller", value: "Oak Point APA Seller" },
        // { label: "Oak Point APA Seller Signatory's Firm", value: "Oak Point APA Seller Signatory's Firm" },
        // { label: "Oak Point APA Seller Signatory", value: "Oak Point APA Seller Signatory" },
        // { label: "Related Omitted", value: "Related Omitted" },
        // // { label: "OPP Sale Contactâ€™s Name", value: "OPP Sale Contactâ€™s Name" },
        // { label: "Subsidiary", value: "Subsidiary" },
        // { label: "Unrelated", value: "Unrelated" },
        // { label: "Trade name/Division/DBA/AKA/Assumed/Fictitious", value: "Trade name/Division/DBA/AKA/Assumed/Fictitious" },
        // { label: "Unconfirmed", value: "Unconfirmed" }],
        width: 200,
        field: 'NameConnection',
        isRequired: true,
        header: 'Name Connection',
        // filter: false,
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      // {
      //   show: true,
      //   mobile: true,
      //   textAlign: 'left',
      //   editField: "text",
      //   //editField: "text",

      //   width: 200,
      //   field: 'ConnectedName',
      //   header: 'Connected Name',
      //   "filter": true,
      //   "sortable": true,
      //   // filter: false,
      //   // sortable: true
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        isRequired: true,
        required: true,
        editField: "multiDropdown",
        options: this.state.issueNameOptions,

        width: 200,
        fieldType: "Array",
        field: 'IssueName',
        header: 'Issues',
        "filter": true,
        "sortable": true,
        // filter: false,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        editField: "text",
        field: 'NameSource',
        required: true,
        isRequired: true,
        header: 'Name Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        editField: "text",
        field: 'IssueNote',
        header: 'Issue Note',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        //editField: "text",
        width: 200,
        field: 'GeneralNote',
        header: 'General Notes',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'DetailsSource',
        isRequired: true,
        header: 'Details Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        editField: "dropdown",
        width: 200,
        options: [{ label: "Yes", value: true }, { label: "No", value: false }],
        field: 'IsKey',
        header: 'Is Key?',
        // "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        //editField: "text",
        width: 200,
        field: 'GeneralRestrictions',
        header: 'General Restrictions',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        //editField: "text",
        width: 200,
        field: 'ConnectionYear',
        header: 'Connection Year',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        //editField: "text",
        width: 200,
        field: 'PBConnectionType',
        header: 'PB Connection Type',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        keyfilter: "pint",
        //editField: "text",
        width: 200,
        field: 'PBConnectionYear',
        header: 'PB Connection Year',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        //editField: "text",
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'ProposedLegalNameSource',
        header: 'Proposed Legal Name Source',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        editField: "dropdown",
        width: 200,
        options: [{ label: "Yes", value: true }, { label: "No", value: false }],
        field: 'NameAIStatus',
        header: 'A/I',
        "filter": false,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        //editField: "text",
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'ActiveInactiveNotes',
        header: 'Active Inactive Notes',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'SOSId',
        header: 'SOS ID',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        //editField: "text",
        width: 200,
        field: 'SOSLastFilingDate',
        header: 'SOS Last Filing Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        //editField: "text",
        width: 200,
        field: 'DateCheckedforSOSLastFilingDate',
        header: 'Date Checked for SOS Last Filing Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'SOSOfficers',
        // fieldType: "dateTime",
        header: 'SOS Officers',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        //editField: "text",
        width: 200,
        field: 'DateCheckedforSOSOfficers',
        header: 'Date Checked for SOS Officers',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        //editField: "text",
        width: 200,
        keyfilter: "pint",
        field: 'SEC10Kex21StartYear',
        header: 'SEC 10 Kex 21 Start Year',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        keyfilter: "pint",
        //editField: "text",
        width: 200,
        field: 'SEC10Kex21LastYear',
        header: 'SEC 10 Kex 21 Last Year',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        //editField: "text",
        width: 200,
        keyfilter: "pint",

        field: 'SECRegistrationStartYear',
        header: 'SEC Registration Start Year',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        field: 'SECRegistrationLastYear',
        header: 'SEC Registration Last Year',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'DatePreSalesUPResearch',
        header: 'Date Pre Sales UP Research',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        field: 'DatePreSalesUPRefresh',
        fieldType: "dateTime",
        header: 'Date Pre Sales UP Refresh',
        "filter": true,
        "sortable": true,                    // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        field: 'DateIntegrityPreSaleResearch',
        fieldType: "dateTime",
        header: 'Date Integrity Pre Sale Research',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        field: 'DateIntegrityPostSaleResearch',
        header: 'Date Integrity Post Sale Research',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'IntegrityNotes',
        header: 'Integrity Notes',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'LegalNotes',
        header: 'Legal Notes',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'APAExhibitPlacement',
        header: 'APA Exhibit Placement',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'SellerComments',
        header: 'Seller Comments',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        field: 'UPCountTotal',
        header: 'UP Count Total',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        field: 'UPValueTotal',
        header: 'UP Value Total',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'PaidRecoveries',
        header: 'Paid Recoveries',
        filterType: "num",
        filterType: "num", keyfilter: "money",
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'JudgmentFees',
        header: 'Judgment Fees',
        filterType: "num",
        filterType: "num", keyfilter: "money",
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'JudgmentPreJudgmentInterest',
        header: 'Judgment Pre Judgment Interest',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        field: 'ConnectionTypeYear',
        header: 'Connection Type Year',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'IncorporationDate',
        header: 'Incorporation Date',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'InactivityDate',
        header: 'Inactivity Date',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'EntityStatus',
        header: 'Entity Status',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'EntityStatusSubType',
        header: 'Entity Status Sub Type',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      // {
      //     show: true,
      //     mobile: true,
      //     textAlign: 'left',
      // editField: "text",
      //     width: 200,
      //     field: 'GeneralNote',
      //     header: 'General Note',
      //     "filter": true,
      //     "sortable": true,                    // sortable: true
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'DateRestrictions',
        header: 'Date Restrictions',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        keyfilter: "pint",
        width: 200,
        editField: "text",
        field: 'BusinessID',
        isRequired: true,
        header: 'Business ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'DWDateInserted',
        header: 'DW Date Inserted',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'DWDateLastModified',
        header: 'DW Date Last Modified',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 200,
        //editField: "text",
        field: 'DWLastOperation',
        isRequired: true,
        header: 'DW Last Operation',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 200,
        //editField: "text",
        field: 'DWLastModifiedBy',
        isRequired: true,
        header: 'DW Last Modified By',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 200,
        //editField: "text",
        field: 'DWDelete',
        isRequired: true,
        header: 'DW Delete',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 200,
        //editField: "text",
        field: 'DWRowIsCurrent',
        isRequired: true,
        header: 'DW Row Is Current',
        "filter": true,
        "sortable": true,
        // sortable: true
      },


      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        editField: "dropdown",
        width: 200,
        options: [{ label: "Yes", value: "Y" }, { label: "No", value: "N" }],
        field: 'GenericName',
        header: 'Generic Name',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      // {
      //   show: true,
      //   mobile: true,
      //   textAlign: 'left',
      //   // editField: "text",
      //   width: 200,
      //   editField: "text",
      //   field: 'LegacyOPRAEstateID',
      //   keyfilter: "pint",
      //   isRequired: true,
      //   header: 'OPRA Estate ID',
      //   "filter": true,
      //   "sortable": true,
      //   // sortable: true
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 200,
        editField: "textDisable",
        field: 'BusinessNamesID',
        keyfilter: "pint",
        isRequired: true,
        // disabled:true,
        header: 'Name ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'LegacyOPRANameID',
        isRequired: true,
        header: 'Legacy OPRA Name ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'OwnershipTypeId',
        isRequired: true,
        header: 'Ownership Type ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'OwnershipSubTypeId',
        isRequired: true,
        header: 'Ownership Sub Type ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'NameTypeId',
        isRequired: true,
        header: 'Name Type ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyForExport',
        isRequired: true,
        header: 'Legacy For Export',
        "filter": true,
        "sortable": true,
        // sortable: true
      },


      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        keyfilter: "pint",
        width: 200,
        //editField: "text",
        field: 'LegacyOpamSearchType',
        isRequired: true,
        header: 'Legacy Opam Search Type',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "dropdown",
        dataKey: "BusinessNamesID",
        fieldType: "formatedDataKey",
        options: this.state.fileForOptions,
        width: 350,
        required: true,
        //editField: "text",
        field: 'LegacyConnectedNameLabel',
        isRequired: true,
        header: 'Connected Name',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'DateAdded',
        header: 'Date Name Added or Edited',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'ResearchPriority',
        isRequired: true,
        header: 'Research Priority',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'DateNameResearched',
        isRequired: true,
        header: 'Date Name Researched',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        // fieldType: "dateTime",
        field: 'LegacyOperatingDates',
        header: 'Legacy Operating Dates',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        // fieldType: "dateTime",
        field: 'LegacyDateRestrictionForClaims',
        header: 'Date Restriction for Claims',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyGeographicRestriction',
        isRequired: true,
        header: 'Geographic Restriction',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyPropertyTypeHolderRestriction',
        isRequired: true,
        header: 'Property Type/Holder Restriction',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'OtherRestriction',
        isRequired: true,
        header: 'Other Restriction',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'StateOfIncorporation',
        isRequired: true,
        header: 'State Of Incorporation',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN1',
        isRequired: true,
        header: 'EIN 1',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN1Source',
        isRequired: true,
        header: 'EIN 1 Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyEIN1DateAdded',
        header: 'Legacy EIN 1 Date Added',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        //editField: "text",
        field: 'LegacyEIN1ResearchedForStockHolding',
        isRequired: true,
        header: 'Legacy EIN 1 Researched For Stock Holding',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN1ResearchedForStockHoldingBy',
        isRequired: true,
        header: 'Legacy EIN 1 Researched For Stock Holding By',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN2',
        isRequired: true,
        header: 'EIN 2',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN2Source',
        isRequired: true,
        header: 'EIN 2 Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyEIN2DateAdded',
        header: 'Legacy EIN 2 Date Added',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        //editField: "text",
        field: 'LegacyEIN2ResearchedForStockHolding',
        isRequired: true,
        header: 'Legacy EIN 2 Researched For Stock Holding',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN2ResearchedForStockHoldingBy',
        isRequired: true,
        header: 'Legacy EIN 2 Researched For Stock Holding By',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN3',
        isRequired: true,
        header: 'EIN 3',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN3Source',
        isRequired: true,
        header: 'Legacy EIN 3 Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyEIN3DateAdded',
        header: 'Legacy EIN 3 Date Added',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        //editField: "text",
        field: 'LegacyEIN3ResearchedForStockHolding',
        isRequired: true,
        header: 'Legacy EIN 3 Researched For Stock Holding',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN3ResearchedForStockHoldingBy',
        isRequired: true,
        header: 'Legacy EIN 3 Researched For Stock Holding By',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN4',
        isRequired: true,
        header: 'Legacy EIN 4',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN4Source',
        isRequired: true,
        header: 'Legacy EIN 4 Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyEIN4DateAdded',
        header: 'Legacy EIN 4 Date Added',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        //editField: "text",
        field: 'LegacyEIN4ResearchedForStockHolding',
        isRequired: true,
        header: 'Legacy EIN 4 Researched For Stock Holding',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN4ResearchedForStockHoldingBy',
        isRequired: true,
        header: 'Legacy EIN 4 Researched For Stock Holding By',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN5',
        isRequired: true,
        header: 'Legacy EIN 5',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN5Source',
        isRequired: true,
        header: 'Legacy EIN 5 Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyEIN5DateAdded',
        header: 'Legacy EIN 5 Date Added',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        //editField: "text",
        field: 'LegacyEIN5ResearchedForStockHolding',
        isRequired: true,
        header: 'Legacy EIN 5 Researched For Stock Holding',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN5ResearchedForStockHoldingBy',
        isRequired: true,
        header: 'Legacy EIN 5 Researched For Stock Holding By',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN6',
        isRequired: true,
        header: 'Legacy EIN 6',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN6Source',
        isRequired: true,
        header: 'Legacy EIN 6 Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyEIN6DateAdded',
        header: 'Legacy EIN 6 Date Added',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        //editField: "text",
        field: 'LegacyEIN6ResearchedForStockHolding',
        isRequired: true,
        header: 'Legacy EIN 6 Researched For Stock Holding',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN6ResearchedForStockHoldingBy',
        isRequired: true,
        header: 'Legacy EIN 6 Researched For Stock Holding By',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN7',
        isRequired: true,
        header: 'Legacy EIN 7',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN7Source',
        isRequired: true,
        header: 'Legacy EIN 7 Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyEIN7DateAdded',
        header: 'Legacy EIN 7 Date Added',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        //editField: "text",
        field: 'LegacyEIN7ResearchedForStockHolding',
        isRequired: true,
        header: 'Legacy EIN 7 Researched For Stock Holding',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN7ResearchedForStockHoldingBy',
        isRequired: true,
        header: 'Legacy EIN 7 Researched For Stock Holding By',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN8',
        isRequired: true,
        header: 'Legacy EIN 8',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN8Source',
        isRequired: true,
        header: 'Legacy EIN 8 Source',
        "filter": true,
        "sortable": true,
        // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyEIN8DateAdded',
        header: 'Legacy EIN 8 Date Added',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        fieldType: "dateTime",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN8ResearchedForStockHolding',
        isRequired: true,
        header: 'Legacy EIN 8 Researched For Stock Holding',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyEIN8ResearchedForStockHoldingBy',
        isRequired: true,
        header: 'Legacy EIN 8 Researched For Stock Holding By',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'CIKCode',
        isRequired: true,
        header: 'CIK Code',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'SICCode',
        isRequired: true,
        header: 'SIC Code',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'NAICSCode',
        isRequired: true,
        header: 'NAICS Code',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'SECRequest',
        isRequired: true,
        header: 'SEC Request',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyAssociatedCaseNumber',
        isRequired: true,
        header: 'Legacy Associated Case Number',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentAmount',
        isRequired: true,
        filterType: "num", keyfilter: "money",
        header: 'Legacy Judgment Amount',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyGeneralNote',
        isRequired: true,
        header: 'Legacy General Note',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyCategory1Issues',
        isRequired: true,
        header: 'Category 1 Issues',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyCategory2Issues',
        isRequired: true,
        header: 'Category 2 Issues',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyCategory56Issues',
        isRequired: true,
        header: 'Legacy Category 56 Issues',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyCategory7Issues',
        isRequired: true,
        header: 'Category 7 Issues',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyExternalList',
        isRequired: true,
        header: 'Legacy External List',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'SECRequest',
        isRequired: true,
        header: 'SEC Request',
        "filter": true,
        "sortable": true,
        // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyJudgmentDateEntered',
        header: 'Legacy Judgment Date Entered',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyJudgmentInterestAccrualStartDate',
        header: 'Legacy Judgment Interest Accrual Start Date',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentFees',
        isRequired: true,
        filterType: "num", keyfilter: "money",
        header: 'Legacy Judgment Fees',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentPreJudgmentInterest',
        filterType: "num", keyfilter: "money",
        isRequired: true,
        header: 'Legacy Judgment Pre Judgment Interest',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        keyfilter: "pint",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentPreJudgmentInterestRate',
        isRequired: true,
        header: 'Legacy Judgment Pre Judgment Interest Rate',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 200,
        fieldType: "dateTime",
        field: 'LegacyJudgmentPreJudgmentInterestDate',
        header: 'Legacy Judgment Pre Judgment Interest Date',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        keyfilter: "pint",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentOtherInterestRate',
        isRequired: true,
        header: 'Legacy Judgment Other Interest Rate',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentOutstandingClaimValue',
        isRequired: true,
        filterType: "num",
        keyfilter: "money",
        header: 'Legacy Judgment Outstanding Claim Value',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num", keyfilter: "money",
        //editField: "text",
        field: 'LegacyJudgmentRemainingJudgmentValuetoClaim',
        isRequired: true,
        header: 'Legacy Judgment Remaining Judgment Value to Claim',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentCourt',
        isRequired: true,
        header: 'Legacy Judgment Court',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentInterestClaimed',
        isRequired: true,
        header: 'Legacy Judgment Interest Claimed',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        filterType: "num", keyfilter: "money",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentAccruedInterestatTimeofPayment',
        isRequired: true,
        header: 'Legacy Judgment Accrued Interest at Time of Payment',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentCoutType',
        isRequired: true,
        header: 'Legacy Judgment Court Type',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentDistrict',
        isRequired: true,
        header: 'Judgment-District',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentDistrictType',
        isRequired: true,
        header: 'Judgment-District Type',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentDistrictCounty',
        isRequired: true,
        header: 'Judgment-District County',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentPlaceholder',
        isRequired: true,
        header: 'Judgment-Placeholder',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'LegacyJudgmentCountofDebtorNames',
        isRequired: true,
        header: 'Legacy Judgment Count of Debtor Names',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentMiscCaseNumbers',
        isRequired: true,
        header: 'Judgment-Misc Case Numbers',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentRegistrationStates',
        isRequired: true,
        header: 'Judgment-Registration States',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentPaidJurisdictions',
        isRequired: true,
        header: 'Judgment-Paid Jurisdictions',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'LegacyJudgmentNumberofPayments',
        isRequired: true,
        header: 'Legacy Judgment Number of Payments',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num", keyfilter: "money",
        //editField: "text",
        field: 'LegacyJudgmentTotalAmountPaid',
        isRequired: true,
        header: 'Legacy Judgment Total Amount Paid',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'LegacyJudgmentOutstandingClaims',
        isRequired: true,
        header: 'Legacy Judgment Outstanding Claims',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num", keyfilter: "money",
        //editField: "text",
        field: 'LegacyJudgmentOutstandingValue',
        isRequired: true,
        header: 'Legacy Judgment Outstanding Value',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentSOLStatus',
        isRequired: true,
        header: 'Judgment-SOL Status',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num", keyfilter: "money",
        //editField: "text",
        field: 'LegacyJudgmentPredictedJudgmentValueAvailable',
        isRequired: true,
        header: 'Legacy Judgment Predicted Judgment Value Available',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num", keyfilter: "money",
        //editField: "text",
        field: 'LegacyJudgmentPostJudgmentInterest',
        isRequired: true,
        header: 'Legacy Judgment Post Judgment Interest',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        keyfilter: "pint",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentPostJudgmentInterestRate',
        isRequired: true,
        header: 'Legacy Judgment Post Judgment Interest Rate',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num", keyfilter: "money",
        //editField: "text",
        field: 'LegacyJudgmentInterest',
        isRequired: true,
        header: 'Legacy Judgment Interest',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num", keyfilter: "money",
        //editField: "text",
        field: 'LegacyJudgmentRemainingPrincipalOverpayment',
        isRequired: true,
        header: 'Legacy Judgment Remaining Principal Overpayment',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num", keyfilter: "money",
        //editField: "text",
        field: 'LegacyJudgmentRemainingFees',
        isRequired: true,
        header: 'Legacy Judgment Remaining Fees',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentRemainingInterest',
        isRequired: true,
        header: 'Legacy Judgment Remaining Interest',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        fieldType: "dateTime",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgementOverpaymentReconciledDate',
        isRequired: true,
        header: 'Legacy Judgement Over payment Reconciled Date',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num", keyfilter: "money",
        //editField: "text",
        field: 'LegacyJudgmentReconcilliationAmount',
        isRequired: true,
        header: 'Legacy Judgment Reconcilliation Amount',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        keyfilter: "pint",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentStatuteDaysRemaining',
        isRequired: true,
        header: 'Legacy Judgment Statute Days Remaining',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        keyfilter: "pint",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentRenewedStatuteDaysRemaining',
        isRequired: true,
        header: 'Legacy Judgment Renewed Statute Days Remaining',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        fieldType: "dateTime",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentRenewedDate',
        isRequired: true,
        header: 'Legacy Judgment Renewed Date',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        fieldType: "dateTime",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentOPPSOJSubmitted',
        isRequired: true,
        header: 'Legacy Judgment OPPSOJ Submitted',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        fieldType: "dateTime",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentPreJudgmentInterestEndDate',
        isRequired: true,
        header: 'Legacy Judgment Pre Judgment Interest End Date',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyJudgmentNoDocumentsAvailable',
        isRequired: true,
        header: 'Legacy Judgment No Documents Available',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyBNCNotices',
        isRequired: true,
        header: 'Legacy BNC Notices',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyIPV4Researched',
        isRequired: true,
        header: 'Legacy IPV4 Researched',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyNexisFilingSearch',
        isRequired: true,
        header: 'Legacy Nexis Filing Search',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyNexisFEINNuberSearch',
        isRequired: true,
        header: 'Legacy Nexis FEIN Number Search',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyNexisFEINNameSearch',
        isRequired: true,
        header: 'Legacy Nexis FEIN Name Search',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyDebtor',
        isRequired: true,
        header: 'Legacy Debtor',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        filterType: "num",
        //editField: "text",
        field: 'LegacyDebtorAcquisionYear',
        maxLength: 255,
        isRequired: true,
        header: 'Legacy Debtor Acquisition Year',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        filterType: "num",
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyDebtorDispositionYear',
        isRequired: true,
        maxLength: 255,
        header: 'Legacy Debtor Disposition Year',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'CourtCaseKey',
        isRequired: true,
        header: 'Court Case Key',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LeadCourtCaseKey',
        isRequired: true,
        header: 'Lead Court Case Key',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'AcquiredYear',
        isRequired: true,
        header: 'Acquired Year',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'Website',
        isRequired: true,
        header: 'Website',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'WebsiteStatusId',
        isRequired: true,
        header: 'Website Status ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'Employees',
        keyfilter: "pint",
        isRequired: true,
        header: 'Employees',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'AINotes',
        isRequired: true,
        header: 'AI Notes',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'LegacyEstateNameID',
        isRequired: true,
        header: 'Legacy Estate Name ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        keyfilter: "pint",
        //editField: "text",
        field: 'LegacyImportLogID',
        isRequired: true,
        header: 'Legacy Import Log ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'LegacyIsKey',
        isRequired: true,
        header: 'Legacy Is Key',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "dropdown",
        width: 200,
        options: [{ label: "Yes", value: "Y" }, { label: "No", value: "N" }],
        //editField: "text",
        field: 'SignatoryConnection',
        isRequired: true,
        header: 'Signatory Connection',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'SignatoryConnectionType',
        isRequired: true,
        header: 'Signatory Connection Type',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'SignatoryConnectionDocument',
        isRequired: true,
        header: 'Signatory Connection Document',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        fieldType: "dateTime",
        width: 200,
        //editField: "text",
        field: 'EntityStatusDate',
        isRequired: true,
        header: 'Entity Status Date',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        //editField: "text",
        field: 'EntityStatusDocument',
        isRequired: true,
        header: 'Entity Status Document',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 200,
        //editField: "text",
        field: 'CreatedByUserName',
        isRequired: true,
        header: 'Created By User',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 200,
        //editField: "text",
        field: 'UpdatedByUserName',
        isRequired: true,
        header: 'Updated By User',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
    ];
    return data;
  }

  /*
   For Getting required Fields to save or edit record
   */
  getrequiredFieldsforScreen() {
    let array = ["BusinessName", "NameSource", "NameConnection", "CompanyID", "NameType", "LegacyConnectedNameLabel"]
    return array
  }
  /*
For Getting required Fields to save or edit record
*/
  getanyOnerequiredFieldsforScreen() {
    let array = ["IssueName", "LegacyIssues"]
    return array
  }

  /*
  For Adding new Record Setting Empty Obj
  */
  getnewRecord = () => {
    let newRec = {
      APAExhibitPlacement: null,
      ActiveInactiveNotes: null,
      BusinessID: null,
      BusinessName: null,
      BusinessNamesID: "Auto generated",
      LegacyConnectedName: null,
      CompanyID: null,
      ConnectedName: null,
      ConnectionTypeYear: null,
      ConnectionYear: null,
      DataSourceID: 4,
      LegacyConnectedNameLabel: null,
      DateCheckedforSOSLastFilingDate: null,
      DateCheckedforSOSOfficers: null,
      DateIntegrityPostSaleResearch: null,
      DateIntegrityPreSaleResearch: null,
      DatePreSalesUPRefresh: null,
      DatePreSalesUPResearch: null,
      DateRestrictions: null,
      EntityStatus: null,
      EntityStatusSubType: null,
      GeneralNote: null,
      GeneralRestrictions: null,
      InactivityDate: null,
      IncorporationDate: null,
      IntegrityNotes: null,
      IsKey: false,
      LegacyIssues: null,
      IssueName: null,
      IssueNote: null,
      JudgmentFees: null,
      JudgmentPreJudgmentInterest: null,
      LegalNotes: null,
      NameAIStatus: null,
      NameConnection: null,
      NameSource: null,
      NameType: null,
      PBConnectionType: null,
      PBConnectionYear: null,
      PaidRecoveries: null,
      ProposedLegalNameSource: null,
      SEC10Kex21LastYear: null,
      SEC10Kex21StartYear: null,
      SECRegistrationLastYear: null,
      SECRegistrationStartYear: null,
      SOSId: null,
      SOSLastFilingDate: null,
      SOSOfficers: null,
      SellerComments: null,
      UPCountTotal: null,
      UPValueTotal: null,
      Evidences: [
      ],
      // uniqueID: tableValue.length + 1,


    }
    return newRec
  }

  render() {
    return (
      <>{null}</>
    );
  }
}
export default withTranslation('common')(NamesScreenProps);