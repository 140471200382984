import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Row,
} from "reactstrap";
import classnames from "classnames";
import { Container } from "reactstrap";
import Loader from '../../App/Loader';

import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

import { AutoComplete } from "primereact/autocomplete";

import { withTranslation } from "react-i18next";
import DealManagement from "./DealManagement";
import fetch from "../../../config/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faTimes } from "@fortawesome/free-solid-svg-icons";
import SellerModal from "../CommonModals/SellerModal";
import ConfirmationModal from "../CommonModals/ConfirmationModal";
import AddressFields from "./DealMgmtFields/Address";
import NamesFields from "./DealMgmtFields/Names";
import NotesFields from "./DealMgmtFields/Notes";
import apiCalls from "../../../config/apiCalls";
import NamesV2Fields from "./DealMgmtFields/NamesV2"

import DocumentMaintenance from "../DocumentMaintenance/components/DocumentMaintenance";
import DocumentUpload from "../DocumentUpload/components/DocumentUpload";
import AvailableDocuments from "../AvailableDocuments/components/AvailableDocuments";
import DocumentLibrary from "../DocumentLibrary/DocumentLibrary";
import * as documentsAPIs from "../../../shared/documentsApis";
import RolePermissions from "../CommonModals/Permissions";
import configMessages from "../../../config/configMessages";
import NoViewComponent from "../../Cruds/CommonDataTable/NoView";
// config file
class DealTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionExpiryModal: false,
      filteredSuggestions: [],
      routeName: "",
      selectedDealID: "",
      selectedDeal: "",
      dimSellerId: "",
      isRedirecttoDeal: false,
      names: [],
      relSellerNames: [],
      stateClaimIds: [],
      addresses: [],
      isLoading: false,
      screenPermissions: "",
      preSalePermission: "",
    };
    this.prevSelDealID = null;

  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  // Getting role Permissions
  getScreenPermissions = async () => {
    let role = await JSON.parse(localStorage.getItem('loginCredentials'))
    var screenPermissions
    let permissions = localStorage.getItem('rolePermissions');

    if (role && role.roleName) {
      await this.setState({
        role: role.roleName,
        screenPermissions: window.location.pathname.split("/")[2] === "claimDocumentation" ? "Edit" :
          window.location.pathname.split("/")[2] === "documentUpload" || window.location.pathname.split("/")[2] === "documentLibrary" ? "Edit" :
            window.location.pathname.split("/")[2] === "name" ? JSON.parse(permissions)["Name"] :
              window.location.pathname.split("/")[2] === "namesv2" ? JSON.parse(permissions)["Name V2"] :
                window.location.pathname.split("/")[2] === "business" ? JSON.parse(permissions)["Deal"] :
                  window.location.pathname.split("/")[2] === "Address" || window.location.pathname.split("/")[2] === "address" ? JSON.parse(permissions)["Address"] :
                    window.location.pathname.split("/")[2] === "deal" ? JSON.parse(permissions)["Deal"] : "NoView"
      })
    }
  }


  componentDidMount = async () => {

    window.addEventListener("beforeunload", this.clearStoredValues);

    if (this.props.onRef) {
      this.props.onRef(this);
    }

    if (window.location.pathname.split("/")[2] === "name" && this.state.activeTab !== "3") {
      this.setState({ activeTab: "3", selectedDeal: "", selectedDealID: "" });
    } else if (
      window.location.pathname.split("/")[2] === "Address" &&
      this.state.activeTab !== "4"
    ) {
      this.setState({ activeTab: "4", selectedDeal: "", selectedDealID: "" });
    } else if (
      window.location.pathname.split("/")[2] === "deal" &&
      this.state.activeTab !== "1"
    ) {
      this.setState({ activeTab: "1", selectedDeal: "", selectedDealID: "" });
    } else if (
      window.location.pathname.split("/")[2] === "business" &&
      this.state.activeTab !== "2"
    ) {
      this.setState({ activeTab: "2", selectedDeal: "", selectedDealID: "" });
    } else if (
      window.location.pathname.split("/")[2] === "documentUpload" &&
      this.state.activeTab !== "6"
    ) {
      this.setState({ activeTab: "6", selectedDeal: "", selectedDealID: "" });
    } else if (
      window.location.pathname.split("/")[2] === "claimDocumentation" &&
      this.state.activeTab !== "7"
    ) {
      this.onClickClaimDocumentationTab();
    }
    await this.getScreenPermissions();

    if (window.location.pathname.split("/").length === 4) {
      //get the deal id details when the url is copied or refreshed
      await this.getSellerDetailsfromRoute();
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (
      window.location.pathname.split("/")[2] === "name" &&
      state.activeTab !== "3"
    ) {
      return { activeTab: "3", selectedDeal: "", selectedDealID: "" };
    } else if (
      window.location.pathname.split("/")[2] === "Address" &&
      state.activeTab !== "4"
    ) {
      return { activeTab: "4", selectedDeal: "", selectedDealID: "" };
    } else if (
      window.location.pathname.split("/")[2] === "deal" &&
      state.activeTab !== "1"
    ) {
      return { activeTab: "1", selectedDeal: "", selectedDealID: "" };
    } else {
      return null;
    }
  }



  /**
   *  Calling when the tab is clicked
   * @param {String} tab
   * @param {String} urlName
   * @param {String} sideMenu
   */
  toggle = (tab, urlName, sideMenu, isRefresh) => {
    this.getScreenPermissions();

    this.setState({
      isLoading: (tab === "4" || tab === "3" || tab === "8") && this.state.selectedDealID ? true : false,
      activeTab: tab,
      routeName: urlName,
    });

    //Highlight the sidebar based on Side Selection
    var data = document.getElementsByClassName("categoryTitle");
    for (var i = 0; i < data.length; i++) {
      if (data[i].innerHTML === "Managed Assets") {
        data = document.getElementsByClassName("sidebar__submenu")[i];
        var submenus = data.getElementsByClassName("sidebar__link-title");
        for (var j = 0; j < submenus.length; j++) {
          if (submenus[j].innerHTML === sideMenu) {
            data.children[0].children[j].children[0].click();
          }
        }
      }
    }


    setTimeout(() => this.getDataBasedOnComponentSelected(tab, isRefresh), 150);
    if (this.state.selectedDealID !== "") {
      this.changingUrlBasedOnTabWithID(urlName, true)
    } else {
      this.changingUrlBasedOnTab(urlName)
    }
  };

  refreshData = async (tab) => {
    if (this.state.activeTab === "3") {
      this.toggle('3', 'name', 'Name', tab);
      // this.namesFieldsref.getNamesFields()
    } else if (this.state.activeTab === "4") {
      this.toggle('4', 'address', 'Address', tab);
      // this.addressFieldsref.getNamesFields()
    } else if (this.state.activeTab === "8") {
      this.toggle('8', 'namesv2', 'Name');
      // this.namesFieldsref.getNamesFields()
    }
  };

  /**
   *   handle inputs for DealManagement based on Selected tab(if it is table)
   * @param {String} tab
   * @returns
   */
  getInputForCruds = async (tab) => {
    var obj;
    if (tab === "3") {
      await this.namesFieldsref.getHierarchyData(this.state.selectedDealID);
      obj = {
        tableFields: this.namesFieldsref.getnamesFields(),
        multiSaveUrl: `${apiCalls.addNames}/multi?dealID=${this.state.selectedDealID}`,
        multiDeleteUrl: `${apiCalls.addNames}/MultiDelete?dealID=${this.state.selectedDealID}`,
        newRecordObj: this.namesFieldsref.getnewRecord(),
        requiredFields: this.namesFieldsref.getrequiredFieldsforScreen(),
        anyOneField: this.namesFieldsref.getanyOnerequiredFieldsforScreen(),
        type: "Names",
        refreshData: this.refreshData,
        deleteRestrictionField: "DataSourceID",
        deleteRestrictionFieldValues: [3, 4],
        uniqueDBField: "BusinessNamesID",
        validationType: "or",
        isEditableGrid: true,
        isPresets: true,

      }
    } else if (tab === "8") {
      await this.namesv2FieldsRef.getHierarchyData(this.state.selectedDealID)
      obj = {
        tableFields: this.namesv2FieldsRef.getNamesFields(),
        multiSaveUrl: `${apiCalls.addNames}/multiV2?dealID=${this.state.selectedDealID}`,
        multiDeleteUrl: `seller/names/MultiDeleteV2?dealID=${this.state.selectedDealID}`,
        newRecordObj: this.namesv2FieldsRef.getNewRecord(),
        requiredFields: this.namesv2FieldsRef.getRequiredFieldsForScreen(),
        anyOneField: this.namesv2FieldsRef.getAnyOneRequiredFieldsForScreen(),
        type: "Names V2",
        refreshData: this.refreshData,
        deleteRestrictionField: "DataSourceID",
        deleteRestrictionFieldValues: [3, 4],
        uniqueDBField: "BusinessNamesID",
        validationType: "and",
        isEditableGrid: true,
        isPresets: false,
      }
    } else if (tab === "4") {
      await this.addressFieldsref.getHierarchyData(this.state.selectedDealID);
      obj = {
        tableFields: this.addressFieldsref.getaddressFields(),
        multiSaveUrl: `${apiCalls.addAddress}/multi?dealID=${this.state.selectedDealID}`,
        multiDeleteUrl: `${apiCalls.addAddress}/MultiDelete?dealID=${this.state.selectedDealID}`,
        newRecordObj: this.addressFieldsref.getnewRecord(),
        refreshData: this.refreshData,
        requiredFields: this.addressFieldsref.getrequiredFieldsforScreen(),
        anyOneField: this.addressFieldsref.getanyOnerequiredFieldsforScreen(),
        type: "Address",
        deleteRestrictionField: "",
        deleteRestrictionFieldValues: ["3", "4"],
        uniqueDBField: "BusinessLocationID",
        validationType: "or",
        isEditableGrid: true,
        isPresets: true,

      }
    } else if (tab === "5") {
      obj = {
        tableFields: this.notesFieldsref.getNotesFields(),
        multiSaveUrl: `${apiCalls.SellerNotes}/multi`,
        multiDeleteUrl: `${apiCalls.SellerNotes}/MultiDelete`,
        newRecordObj: this.notesFieldsref.getnewRecord(),
        requiredFields: this.notesFieldsref.getrequiredFieldsforScreen(),
        type: "Notes",
        deleteRestrictionField: "DataSourceID",
        deleteRestrictionFieldValues: ["3", "4"],
        uniqueDBField: "BusinessNamesID",
        isEditableGrid: true,
        isPresets: true,

      }
    }
    return obj;
  };

  isLoadingFalse = () => {
    if (this.state.isLoading)
      this.setState({
        isLoading: false
      })
  }

  /**
   *  Calling the components when toggle is happened or deal is selected
   * @param {String} tab
   */
  getDataBasedOnComponentSelected = async (tab, isRefresh) => {
    if (this.state.selectedDealID) {
      let deal = {}
      deal.value = this.state.selectedDeal
      if ((tab === "3" || tab === "4" || tab === "5" || tab === "8") && this.dealManagementRef && !isRefresh) {
        let fieldsData = await this.getInputForCruds(tab)
        this.dealManagementRef.handleGetDeal(deal, this.state.activeTab, fieldsData, tab === "8" ? true : false)

      } else {
        if ((tab === "1" || tab === "2") && this.sellerModalRef) {
          this.sellerModalRef.handleGetDeal(deal, this.state.activeTab)
        }
      }
    } else {
      if ((tab === "1" || tab === "2") && this.sellerModalRef) {
        this.sellerModalRef.handleGetDeal({}, this.state.activeTab)
      }
    }
  };

  /**
   *   Changing the tab without DealID
   * @param {String} screen
   */
  changingUrlBasedOnTab(screen) {
    const params = new URLSearchParams(window.location.search);  //Need to get params at the time of function execution as params keep changing on tab changes 
    if (params && params.get('dealId')) {
      return;
    }
    let pathname2 = `/sellersEdit/`;
    window.history.pushState(null, null, pathname2);

    let pathname = `/sellersEdit/${screen}`;
    window.history.pushState(null, null, pathname);
  }

  /**
   * Route Change when click on the the tab with DealID
   * @param {String} urlName
   */
  changingUrlBasedOnTabWithID(urlName, tabClick) {
    const params = new URLSearchParams(window.location.search); //Need to get params at the time of function execution as params keep changing on tab changes 
    if (params && params.get('dealId')) {
      if (window.location.href.includes('claimDocumentation')) {
        sessionStorage.setItem('claimUrl', window.location.href);
      }
      if (!tabClick || urlName === "claimDocumentation") {
        return;
      }
    }
    let screen = urlName ? urlName : window.location.pathname.split("/")[2];
    let id = this.state.selectedDealID;
    let pathname = `/sellersEdit/${screen}/${id}`;
    window.history.pushState(null, null, pathname);
  }

  /**
   * 
   * @returns   Get the SEller details from the id get from the url
   */
  getSellerDetailsfromRoute = async (getpermission) => {
    let dealId = window.location.pathname.split("/")[3]
    let loginUser = JSON.parse(localStorage.getItem("loginCredentials"))
    let apiUrl = `seller/${getpermission ? getpermission : dealId}`;

    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response && response.details) {
            if (response && response.limitedPreferences) {
              this.setState({ limitedPreferences: response.limitedPreferences })
            }
            if (response.permission && response.permission != null) {
              this.setState({ preSalePermission: response.permission })
            } else {
              this.setState({
                preSalePermission: this.state.screenPermissions
              })
            }
            dropdownData = response.details
            let responseData = {}
            responseData.value = dropdownData
            if (!getpermission) {
              await this.handleGetDeal(responseData)
            }

          } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
            this.setState({
              sessionExpiryModal: true
            })

          } else if (localStorage.getItem('sessionexpired') === true) {
            let sessionexpired = await localStorage.getItem('sessionexpired')
            if (sessionexpired === "true") {
              await this.setState({ sessionExpiryModal: true })
            }
          }
          else if (response && response.errorMessage) {
            // this.props.getErrorResponseFromServer(response)
          }

        }
      })
      .catch((err) => {
        return err;
      });
  };

  /**
   *
   * @param {Object} e
   *   invoke when the Deal Name Estate is changes
   */
  handleGetDeal = async (e) => {
    await this.setState({
      selectedDeal: e.value
    })
    if (e && e.value && e.value.DealID) {
      await this.getSellerDetailsfromRoute(e.value.DealID)
    }
    let loginUser = JSON.parse(localStorage.getItem("loginCredentials"))
    let selectedDealStatus = e && e.value ? e.value.DealStatus : null

    let viewType
    if (loginUser.department) {
      if (selectedDealStatus === "Pre-Sales" && loginUser.department && loginUser.department.includes("Presales")) {
        viewType = "PreSales"
      } else if (selectedDealStatus === "Drafting" && loginUser.department && loginUser.department.includes("Legal")) {
        viewType = "DraftingAndLegal"
      } else if (selectedDealStatus === "Owned" && loginUser.department && loginUser.department.includes("CoreOps")) {
        viewType = "PostSaleAndCoreOps"
      } else {
        viewType = "AllColumns"
      }
    } else {
      viewType = "AllColumns"
    }

    await this.setState({
      selectedViewBasedOnLoggedUser: viewType,
      selectedDeal: e.value,
      selectedDealID: e.value.DealID,
      dimSellerId: e.value.DealID,
      activeTab: this.getActiveTab(),
      names: [],
      relSellerNames: [],
      stateClaimIds: [],
      addresses: []

    })
    await this.changingUrlBasedOnTabWithID()
    setTimeout(() => this.getDataBasedOnComponentSelected(this.state.activeTab), 250);

  }
  editorTemplateForDeal(option) {
    return `${option.DealType} - ${option.SellerNickName} (${option && option.APADate ? new Date(option.APADate).getFullYear() : ""
      })`;
  }

  getActiveTab() {
    if (window.location.pathname.includes("deal")) {
      return "1";
    } else if (window.location.pathname.includes("business")) {
      return "2";
    } else if (window.location.pathname.includes("name") && !window.location.pathname.includes("namesv2")) {
      return "3";
    } else if (window.location.pathname.includes("namesv2")) {
      return "8";
    } else if (window.location.pathname.includes("address")) {
      return "4";
    } else if (window.location.pathname.includes("documentLibrary")) {
      return "5";
    } else if (window.location.pathname.includes("documentUpload")) {
      return "6";
    } else if (window.location.pathname.includes("claimDocumentation")) {
      return "7";
    } else {
      return "1";
    }
  }

  /**
   *
   * @param {Object} event
   * @returns  Get the Estate names from the sellers
   */
  EstateItems = (event) => {
    let filterCriteria = { limit: 10, sortfield: "SellerNickName" };
    filterCriteria["criteria"] = [
      {
        key: "SellerNickName",
        value:
          event && event.query && event.query !== ""
            ? encodeURIComponent(event.query)
            : event && typeof event === "string"
              ? encodeURIComponent(event)
              : "",
        type: "sw",
      },
    ];
    let apiUrl = `seller?filter=${JSON.stringify(filterCriteria)}`;
    return fetch("GET", apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (
            response["sellers"] &&
            response["sellers"].length &&
            response["sellers"].length > 0
          ) {
            dropdownData = response["sellers"];
          } else if (response && response.errorMessage) {
            // this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true,
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues;
          }
          await this.setState({
            filteredSuggestions: dropdownData,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  cancelReset = async (type) => {
    await this.setState({ isopenColumnModal: true });
  };
  /**
   * Model to close show hide model
   * @param {String} type
   */
  closeShowHideColumnsModal = async (type) => {
    await this.setState({
      isopenColumnModal: false,
    });
    if (type === "close") {
      await this.setState({
        isRedirecttoDeal: true,
      });
    }
  };

  getConfirmaionModel() {
    return (
      <ConfirmationModal
        openConfirmationModal={this.state.isopenColumnModal}
        closeConfirmationModal={this.closeShowHideColumnsModal}
        shownText={"Are you sure you want to close before Saving?"}
      // confirm={this.submit}
      />
    );
  }

  setDocumentsDropdownsData = (field, data) => {
    this.setState({ [field]: data });
  };
  clearStoredValues = () => {
    if (sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME)) {
      sessionStorage.removeItem(documentsAPIs.DOC_UPLOAD_TAB_NAME);
    }
    if (sessionStorage.getItem(documentsAPIs.AVAIL_DOCS_TAB_NAME)) {
      sessionStorage.removeItem(documentsAPIs.AVAIL_DOCS_TAB_NAME);
    }
  };
  componentWillUnmount() {
    this.clearStoredValues();
    window.removeEventListener("beforeunload", this.clearStoredValues); // remove the event handler for normal unmounting
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDealID !== this.state.selectedDealID) {
      this.clearStoredValues();
    }
    if (prevState.selectedDealID) {
      this.prevSelDealID = prevState.selectedDealID;
    }
    const params = new URLSearchParams(window.location.search);
    if (
      window.location.pathname.includes("claimDocumentation") &&
      params.get("dealId") &&
      this.state.selectedDealID &&
      this.prevSelDealID &&
      this.prevSelDealID !== this.state.selectedDealID
    ) {
      window.history.replaceState(
        null,
        null,
        `${window.location.origin}/sellersEdit/deal/${this.state.selectedDealID ? this.state.selectedDealID : ""
        }`
      );
      window.location.reload();
    }
  }

  handleClearDeal = async () => {
    await this.setState({
      selectedDeal: null,
      selectedDealID: null,
      dimSellerId: null,
      activeTab: this.getActiveTab(),
      names: [],
      relSellerNames: [],
      stateClaimIds: [],
      addresses: []

    })
    await this.changingUrlBasedOnTabWithID()
    setTimeout(() => this.getDataBasedOnComponentSelected(this.state.activeTab), 250);

  }

  onClickClaimDocumentationTab = () => {
    if (sessionStorage.getItem("claimUrl")) {
      window.history.replaceState(
        null,
        null,
        sessionStorage.getItem("claimUrl")
      );
    }
    const params = new URLSearchParams(window.location.search);
    if (
      window.location.href.includes("claimDocumentation") &&
      params.get("dealId")
    ) {
      this.toggle("7", "claimDocumentation", "Claim Documentation");
    } else {
      let url = `/claims`;
      if (this.state.selectedDealID) {
        url = `${url}?companyID=${this.state.selectedDealID}`;
      }
      window.open(url);
    }
  };

  render() {
    const { t } = this.props;
    return this.state.screenPermissions === "Edit" ||
      this.state.screenPermissions === "View" ? (
      <Container className="container">
        <div>
          <Loader loader={this.state.isLoading} />
          <Card className="cardForListMargin m-0 card">
            <CardBody className="tableCardBody">
              <Row className="col-sm-12">
                <h4>
                  <span className="postionRelative pt-2 ml-1">
                    <FontAwesomeIcon
                      className={"tableheadericon"}
                      color="#354f6e"
                      icon={faUsers}
                    />
                    <b>
                      <Link
                        to={"#"}
                        onClick={this.cancelReset}
                        className="tableType pr-0"
                      >
                        {window.location.pathname.split("/").length === 4
                          ? `Edit Deal`
                          : window.location.href.includes("sellersView")
                            ? "View Deal"
                            : "Deal"}
                      </Link>
                      {this.state.selectedDealID
                        ? ` - ${this.state.selectedDeal.DealType} - ${this.state.selectedDeal.SellerNickName
                        } (${this.state.selectedDeal.APADate
                          ? new Date(
                            this.state.selectedDeal.APADate
                          ).getFullYear()
                          : ""
                        }) - ${this.state.selectedDealID}`
                        : null}
                    </b>
                  </span>
                </h4>
              </Row>
              <div>
                <div
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    display: "inline",
                  }}
                >
                  <Nav tabs className="">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggle("1", "deal", "Deal");
                        }}
                      >
                        Deal
                      </NavLink>
                    </NavItem>
                    {this.state.role && this.state.role === "Admin" ? (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2", "business", "Business");
                          }}
                          disabled={
                            this.state.isTabdisabled || this.state.isChildTrue
                          }
                        >
                          Business
                        </NavLink>
                      </NavItem>
                    ) : null}
                    <NavItem >
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '8' })}
                        onClick={() => { this.toggle('8', 'namesv2', 'Name'); }}
                      // disabled={true}
                      >
                        Name V2
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "4",
                        })}
                        onClick={() => {
                          this.toggle("4", "address", "Address");
                        }}
                      // disabled={true}
                      >
                        Address
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "5",
                        })}
                        onClick={() => {
                          this.toggle(
                            "5",
                            "documentLibrary",
                            "Document Library"
                          );
                        }}
                      // disabled={true}
                      >
                        Document Library
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "6",
                        })}
                        onClick={() => {
                          this.toggle("6", "documentUpload", "Document Upload");
                        }}
                      // disabled={true}
                      >
                        Document Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "7",
                        })}
                        onClick={() => {
                          this.onClickClaimDocumentationTab();
                        }}
                      // disabled={true}
                      >
                        Claim Documentation
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggle("3", "name", "Name");
                        }}
                      // disabled={true}
                      >
                        Name
                      </NavLink>
                    </NavItem>

                    {/* <NavItem >
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '5' })}
                    onClick={() => { this.toggle('5', 'notes'); }}
                    disabled={true}
                  >
                    Notes
                  </NavLink>
                </NavItem> */}
                    <span className="topbar__centerresponse topBarImageAlignment" >{this.state.successResponse ? this.state.successMessage : null}</span>
                    <div className='mr-5' >
                      <p style={{ width: "120%" }}>Deal Name  <FontAwesomeIcon icon={faTimes}
                        className={`pl-1 mr-2 clearIcon `}
                        color={this.state.selectedDeal && this.state.selectedDeal.SellerNickName ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        id="clear company"
                        title="Clear Filter"
                        disable={this.state.selectedDeal ? false : true}
                        onClick={() => this.handleClearDeal()}
                      /> </p>
                      <AutoComplete
                        value={this.state.selectedDeal && this.state.selectedDeal.SellerNickName ? `${this.state.selectedDeal.DealType} - ${this.state.selectedDeal.SellerNickName} (${this.state.selectedDeal.APADate ? new Date(this.state.selectedDeal.APADate).getFullYear() : ""})` : this.state.selectedDeal}
                        suggestions={this.state.filteredSuggestions ? this.state.filteredSuggestions : []}
                        completeMethod={this.EstateItems}
                        minLength={1}
                        id="Estate_Search"
                        field={"EntityName"}
                        placeholder={"Deal Name"}
                        style={{ width: "130%" }}
                        itemTemplate={this.editorTemplateForDeal}
                        dropdown={true}
                        onChange={(e) => this.handleGetDeal(e, 'sellectedDeak')}
                        appendTo={document.body} /></div>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    {this.state.activeTab === "1" || this.state.activeTab === "2" ?
                      <TabPane tabId={this.state.activeTab} >
                        <SellerModal
                          preSalePermission={this.state.preSalePermission}
                          onRef={(ref) => this.sellerModalRef = ref}
                          activeTab={this.state.activeTab}
                          type={window.location.href.includes("sellersEdit") ? "edit" : "add"}
                        />
                      </TabPane>
                      :
                      null
                    }
                    <TabPane tabId="2">
                      {/* {this.getTab2Body()} */}
                    </TabPane>
                    {(this.state.activeTab === "3" || this.state.activeTab === "8" || this.state.activeTab === "4") && this.state.selectedDealID ?
                      <TabPane tabId={this.state.activeTab} className="deal_managements" >
                        <DealManagement
                          onRef={(ref) => this.dealManagementRef = ref}
                          selectedViewBasedOnLoggedUser={this.state.selectedViewBasedOnLoggedUser}
                          preSalePermission={this.state.preSalePermission}
                          isLoadingFalse={this.isLoadingFalse}
                          limitedPreferences={this.state.limitedPreferences}
                          activeTab={this.state.activeTab}
                          toggle={this.toggle}
                        />
                        {this.state.selectedDealID ? <>
                          <AddressFields
                            onRef={(ref) => this.addressFieldsref = ref}

                          />
                          <NamesFields
                            onRef={(ref) => this.namesFieldsref = ref}
                            dimSellerId={this.state.selectedDealID}
                          />
                          <NotesFields
                            onRef={(ref) => this.notesFieldsref = ref}
                          /> <NamesV2Fields
                            onRef={(ref) => this.namesv2FieldsRef = ref}
                            preSalePermission={this.state.preSalePermission}
                            selectedViewBasedOnLoggedUser={this.state.selectedViewBasedOnLoggedUser}
                          />
                        </> : null}
                      </TabPane>
                      :
                      null
                    }
                    {
                      this.state.activeTab === "5" && this.state.selectedDealID ?
                        <DocumentLibrary
                          dealId={this.state.selectedDealID}
                          names={this.state.names}

                          addresses={this.state.addresses}
                          relSellerNames={this.state.relSellerNames}
                          preSalePermission={this.state.preSalePermission}
                          // screenPermissionsRoute="E"
                          setDocumentsDropdownsData={this.setDocumentsDropdownsData}
                        />
                        : null
                    }
                    {
                      this.state.activeTab === "6" && this.state.selectedDealID ?
                        <DocumentUpload
                          names={this.state.names}
                          sessionExpiryModal={this.state.sessionExpiryModal}
                          relSellerNames={this.state.relSellerNames}
                          preSalePermission={this.state.preSalePermission}
                          stateClaimIds={this.state.stateClaimIds}
                          setDocumentsDropdownsData={this.setDocumentsDropdownsData}
                          selectedDeal={this.state.selectedDeal}
                        /> : null
                    }
                    {
                      this.state.activeTab === "7" && this.state.selectedDealID ?
                        <AvailableDocuments
                          names={this.state.names}
                          relSellerNames={this.state.relSellerNames}
                          stateClaimIds={this.state.stateClaimIds}
                          preSalePermission={this.state.preSalePermission}
                          setDocumentsDropdownsData={this.setDocumentsDropdownsData}
                          selectedDeal={this.state.selectedDeal}
                        /> : null
                    }
                  </TabContent>

                </div>
                {this.state.isopenColumnModal
                  ? this.getConfirmaionModel()
                  : null}
                {
                  this.state.sessionExpiryModal ?
                    <Redirect to="/log_in" />
                    : null
                }
                {this.state.isRedirecttoDeal ? (
                  <Redirect to="/sellers" />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
    ) : this.state.screenPermissions === "" ? null : (
      <NoViewComponent />
    );
  }
}
export default withTranslation("common")(DealTabs);
