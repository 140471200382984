import React from 'react';
import {
  Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Row
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UploadHistory from '../UploadHistory';

import AddPropertiesLoader from './AddPropertyLoader';
import NoViewComponent from '../CommonDataTable/NoView';

class AddPropertyFromFileModel extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      options: [
        { label: 'OPRA Validated Property Updater Logs', value: 'OPRA Validated Property Updater Logs' },
      ],
      screenPermissions: "",
      isLoading: false,
      isUserUpdated: false,
      activeTab: '1'
    };
  }

  componentDidMount = async () => {
    let permissions = localStorage.getItem('rolePermissions')
    permissions = permissions ? JSON.parse(permissions)["Add Properties"] : "NoView"

    const [navigation] = performance.getEntriesByType("navigation");
    if (navigation && navigation.type === "reload") {
      var storedTabData = localStorage.getItem("AddPropertiesCurrentTab");
      if (storedTabData) {
        storedTabData = JSON.parse(storedTabData)
        this.toggle(storedTabData.activeTab)
      }
    }
    this.setState({
      isLoading: true,
      screenPermissions: permissions
    });
  }
  componentDidUpdate() {
    let tabData = { activeTab: this.state.activeTab }
    if (this.state.activeTab !== null) {
      localStorage.setItem("AddPropertiesCurrentTab", JSON.stringify(tabData));
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }

  }
  render() {
    const { handleSubmit } = this.props;
    const { options } = this.state
    let isSubmitting = false

    return (
      this.state.screenPermissions === "Edit" || this.state.screenPermissions === "View" ?

        <Card className="cardForListMargin m-0 card">

          <CardBody className="tableCardBody">
            <Row className="col-sm-12">

              <h4><span className='postionRelative pt-2 ml-1'>
                <b
                ><Link to={"#"} onClick={this.cancelReset} className="tableType pr-0" >
                    {"Add / Update Properties"}
                  </Link>{this.state.selectedDealID ? ` - ${this.state.selectedDeal.EntityName} - ${this.state.selectedDealID}` : null}</b>

              </span>
              </h4>

            </Row>
            <div style={{ width: "100%" }}>
              <Nav tabs >
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                  >
                    Add Properties From File
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                  >
                    File Upload History
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                {this.state.activeTab === "1" ?
                  <TabPane tabId={this.state.activeTab} >
                    <AddPropertiesLoader />
                  </TabPane>
                  :
                  null
                }
                {this.state.activeTab === "2" ?
                  <TabPane tabId={this.state.activeTab} >
                    <UploadHistory criteria={[{ key: "dataSource", value: "Excel", type: "eq" }]} />
                  </TabPane>
                  :
                  null
                }

              </TabContent>
            </div >
          </CardBody>
        </Card>
        : this.state.screenPermissions === "" ? null
          : <NoViewComponent />
    );
  }
}
export default AddPropertyFromFileModel;






