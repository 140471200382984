import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PropertySearchv3 from './components/PropertySearchv3';
const propertySearchv3 = ({ t }) => (
  <Container >
    <PropertySearchv3 />
  </Container>
);

propertySearchv3.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(propertySearchv3);
