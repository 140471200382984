import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import fetch from '../../../config/service'
import Loader from '../../App/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import configImages from '../../../config/configImages'
import { faTimes } from '@fortawesome/free-solid-svg-icons';



const headerStyle = {
    width: 150,
    textAlign: "center",
}

// config file
class COTModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedColumnOptionsData: [
                { field: 'businessNamesID', header: 'Business Names ID' },
                { field: 'businessName', header: 'Business Name' },
                { field: 'legacyConnectedName', header: 'legacy Connected Name' },
            ],
            isLoading: true,
            progress: 45,
            errorcolor: "",
            errorMessage: "",
            errorResponse: false
        };
        this.chainOfTitleHir = [];
    }

    componentDidMount = () => {

        let selectedName = this.props.selectedItem
        let body = {
            "DealId": selectedName.CompanyID.toString(),
            "relevanceType": "CHAIN OF TITLE",
            "DocumentType": "MAPPED NAME CHAIN OF TITLE",
            "Body": [
                {
                    "SubCategory": "SLR CONNCTN EVID",
                    "DocumentScope": [
                        "Deal",
                        "Mapped Name"
                    ],
                    "relevanceType": "CHAIN OF TITLE",
                    "DocumentType": "MAPPED NAME CHAIN OF TITLE,"
                }
            ],
            // "MappedNameID": "21248550"
        }
        const url = `getCOTNamesHierarchy?DealID=${selectedName.CompanyID}&BusinessNameID=${selectedName.BusinessNamesID}`;
        fetch("POST", url)
            .then(async (response) => {
                if (response && response.outResponse && response.outResponse.details) {
                    this.chainOfTitleHir = response.outResponse.details;
                    body.MappedNameID = this.chainOfTitleHir.map((elem) => elem.businessNamesID).join(',');
                    // this.setState({ cotData: response.outResponse.details })
                    fetch("POST", url2, body)
                        .then(async (response) => {
                            if (response && response.outResponse && response.outResponse.details) {
                                const filteredResult = response.outResponse.details.filter((e) => e.sharePointDetails && e.sharePointDetails.length > 0);
                                this.formatChainOfTitleData(filteredResult);
                                // this.setState({ cotData: response.outResponse.details })
                            }
                        }).catch((err) => {
                            return err;
                        });

                } else if (response && response.errorMessage) {
                    this.setState({
                        isLoading: false,
                        errorcolor: "red",
                        errorMessage: response.errorMessage,
                        errorResponse: true
                    })
                }
            }).catch((err) => {
                return err;
            });


        let url2 = `getDocuments?SpData=true`
        // body.MappedNameID = "21248550"


    }

    formatChainOfTitleData = (fileData) => {
        let cotData = [];
        let disableCOTAdd = false;
        this.chainOfTitleHir.forEach((hir, i) => {
            const files = fileData.filter((elem) => (elem.mappedNameID.toString() === hir.businessNamesID.toString() && elem.sharePointDetails && elem.sharePointDetails.length > 0));
            if (files.length > 0) {
                const fileNamesArr = files.map((file) => file.sharePointDetails && file.sharePointDetails.length > 0 ? file.fileName : null);
                if (fileNamesArr.length === 0) {
                    disableCOTAdd = true;
                }
                files.forEach((file, j) => {
                    cotData.push({
                        ...file,
                        ...hir,
                        fileName: fileNamesArr[j],
                        year: file.eventDateYear,
                        description: file.documentRelevanceNotes,
                        hideHir: (j > 0 ? true : false),
                        rowSpanCount: files.length,
                        colorCode: i,
                        docId: file.id,
                        docNumber: file.documentNumber
                    });
                    // console.log(cotData)


                })

            } else {
                disableCOTAdd = true;
                cotData.push({ ...hir, colorCode: i });
            }
        });


        this.setState({ chainOfTitleData: cotData, isLoading: false, cotHirCount: this.chainOfTitleHir.length, showChainOfTitle: true, disableCOTAdd: disableCOTAdd, disableLoadButton: true });


    }

    getBGColor = (num) => {
        let color = null
        switch (num) {
            case 1:
                color = '#bac0c2';
                break;
            case 2:
                color = '#a7c7d1';
                break;
            case 3:
                color = '#e6dbc8';
                break;
            case 4:
                color = '#edc9b9';
                break;
            case 5:
                color = '#e0f0c9';
                break;
            case 6:
                color = '#fcf7bb';
                break;
            case 7:
                color = '#ffeaea';
                break;
            case 8:
                color = '#decede';
                break;
            case 9:
                color = '#e7fff8';
                break;
            case 10:
                color = '#eef1f8';
                break;
            case 11:
                color = '#faf8e8';
                break;
            case 12:
                color = '#ffefda';
                break;


            default:
                color = null;
                break;
        }
        return color;
    }


    redirectToUpload = async (rowData, type) => {
        let selectedName = this.props.selectedItem
        let dealID = rowData.dealId ? rowData.dealId : selectedName.CompanyID.toString();
        rowData.SubCategory = 'SLR CONNCTN EVID';
        if (rowData && !rowData.documentTypeName) {
            rowData.documentTypeName = 'MAPPED NAME CHAIN OF TITLE'
        }
        let url = `/sellersEdit/documentUpload/${dealID}?dealId=${dealID}&mappedNameId=${rowData.businessNamesID ? rowData.businessNamesID : selectedName.BusinessNamesID}&relSellerNameId=${rowData.businessNameID}&stateCode=${rowData.docState}&stateClaimId=${rowData.docStateClaimID}&OPPClaimID=${rowData.docOPPClaimID}&rowData=${encodeURIComponent(JSON.stringify(rowData))}`;
        if (type === 'edit') {
            url = `${url}&type=${type}`
        }
        window.open(url, '_blank', 'noopener=true');
    }

    redirectToPDFEditorbySelectedID = (rowData, index, document_id) => {
        let id;
        if ((index || index === 0) && rowData && rowData.docId && rowData.docId[index]) {
            id = rowData.docId[index];
        } else if (document_id) {
            id = document_id;
        }
        if (id) {//        
            fetch('GET', `getDocumentById?Id=${id}`).then(async (res) => {
                let data = res && res.respCode && res.outResponse && res.outResponse.details ? res.outResponse.details : null;
                if (data) {
                    localStorage.setItem('pdfDocDetails', JSON.stringify(data));
                    let url = `/pdfEditor?details=loadDoc`;
                    window.open(url, '_blank', 'noopener=true');
                }
            })
        }
    }


    redirectToPDFEditor = (col) => {
        return <div className="cursor-pointer text-info" onClick={() => this.redirectToPDFEditorbySelectedID(col, '', col._id)} data-toggle="tooltip" data-placement="left" title={col.fileName}>
            {col.isFileCommonForMultiSubCategories ? <span className="multiRepFile">{col.fileName}</span> : <span>{col.fileName}</span>}
        </div>
    }


    render() {
        const { chainOfTitleData } = this.state
        return (
            <div>
                <Modal isOpen={this.props.isOpenCOTModal} fade={false} className='modal-dialog-centered modal-dialog--primary m-auto redirection_prop p-0 noModalPadding' style={{ maxWidth: "95%" }}>
                    <ModalBody className='p-0' style={{ height: 500, overflow: "auto" }}>
                        <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" />
                        <div className="align-items-center my-2"style={{ display:"flex"}}><h4 className="mx-auto" style={{ fontWeight: "bold" }}>Chain of Title </h4>
                            <FontAwesomeIcon
                                color='#354f6e'
                                className="mr-3"
                                style={{ cursor: "pointer", height: "1em" }}
                                icon={faTimes}
                                data-toggle="tool-tip"
                                onClick={() => this.props.closeCOTModal()} />
                           
                        </div>
                        {this.state.errorResponse ? <p className={`${this.state.errorcolor === "red" ? "errorbgColor errorTextColor" : this.state.errorcolor === "green" ? "successbgColor successTextColor" : "bg-white"} topbar__center topBarImageAlignment px-3`} style={{ display: "block" }} >
                            {this.state.errorResponse ? this.state.errorMessage : ""}
                        </p> : null}
                        <div className='d-flex flex-column' style={{ overflowX: "auto", display: "grid", alignItems: "center", justifyContent: "center" }}>
                            <table class="table table-bordered" style={{ width: "98%", tableLayout: "fixed" }}>
                                <thead style={{ backgroundColor: "lightgrey" }}>
                                    <tr>
                                        <th scope="col" style={{ width: "5%" }}><b>{'Connection Year'}</b></th>
                                        <th scope="col" style={{ width: "16%" }}><b>Name</b></th>
                                        <th scope="col" style={{ width: "16%" }}><b>Connected Name</b></th>
                                        <th scope="col" style={{ width: "9%" }}><b>PostSale - Connection Type</b></th>
                                        <th scope="col" style={{ width: "5%" }}><b>PostSale - Name Code</b></th>
                                        <th scope="col" style={{ width: "5%" }}><b>Legacy Name Code</b></th>
                                        <th scope="col" style={{ width: "4%" }}><b>Phase</b></th>
                                        <th scope="col" style={{ width: "4%" }}><b>Domicile</b></th>
                                        <th scope="col" style={{ width: "5%" }}><b>CorpStatus At APA</b></th>
                                        <th scope="col" style={{ width: "26%" }}>File Name</th>
                                        <th scope="col" style={{ width: "5%" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(chainOfTitleData && chainOfTitleData.length > 0) &&
                                        <>
                                            {chainOfTitleData.map((elem, i) => {
                                                return <tr>
                                                    <td style={{ maxWidth: '5%' }} className='fileNamestyled'>{elem.connectionYear}</td>
                                                    <td className='' style={{ wordBreak: "break-all", textAlign: "left", backgroundColor: `${this.getBGColor(elem.colorCode)}` }} data-toggle="tool-tip" title={elem.businessName}>{elem.businessName}</td>
                                                    <td className='' style={{ wordBreak: "break-all", textAlign: "left", backgroundColor: `${this.state.cotHirCount === elem.colorCode + 1 ? null : this.getBGColor(elem.colorCode + 1)}` }} data-toggle="tool-tip" title={elem.legacyConnectedName}>{elem.legacyConnectedName}</td>
                                                    <td className='fileNamestyled' style={{}} data-toggle="tool-tip" title={elem.postSaleConnectionType}> {elem.postSaleConnectionType}</td>
                                                    <td className='fileNamestyled' style={{}} data-toggle="tool-tip" title={elem.issueName}> {elem.issueName}</td>
                                                    <td className='fileNamestyled' style={{}} data-toggle="tool-tip" title={elem.legacyIssues}> {elem.legacyIssues}</td>
                                                    <td className='fileNamestyled' style={{}} data-toggle="tool-tip" title={elem.phase}>{elem.phase} </td>
                                                    <td className='fileNamestyled' style={{}} data-toggle="tool-tip" title={elem.stateOfIncorporation}> {elem.stateOfIncorporation}</td>
                                                    <td className='fileNamestyled' style={{}} data-toggle="tool-tip" title={elem.corpStatusAtAPA}> {elem.corpStatusAtAPA}</td>
                                                    <td className='' style={{ wordBreak: "break-all", textAlign: "left" }} data-toggle="tool-tip" title={elem.fileName ? elem.fileName : ""}>{elem.fileName ? this.redirectToPDFEditor(elem)
                                                        : 'No document available'}
                                                    </td>

                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <img
                                                                src={configImages.editIcon}
                                                                alt=""
                                                                className={`${elem.fileName ? 'cursor-pointer mt-0' : 'disable-edit mt-0'}`}
                                                                style={{ width: 22, height: 22, pointerEvents: !elem.fileName ? 'none' : true }}
                                                                onClick={(e) => this.redirectToUpload(elem, 'edit')}
                                                                data-toggle="tool-tip"
                                                                title={"Click to Edit Document"}
                                                            />
                                                            <img
                                                                src={configImages.addIcon}
                                                                alt=""
                                                                className="cursor-pointer names_icon"
                                                                // style={(imgData.iconName === "deleteIcon" || imgData.iconName === "duplicateIcon") && notSelectedRec ? { opacity: 0.5 } : { opacity: 1 }}
                                                                onClick={(e) => this.redirectToUpload(elem)}
                                                                data-toggle="tool-tip"
                                                                title={"Click to Add New Document"}
                                                            />
                                                        </div> </td>
                                                </tr>
                                            })}
                                        </>
                                    }

                                </tbody>
                            </table>
                        </div>

                    </ModalBody>
                </Modal>
            </div >
        );
    }
}
export default COTModal;
