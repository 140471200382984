import React, { useEffect, useState } from 'react';
import DataTables from "../CommonDataTable/DataTable";
import TableFields from './ReceivedMailHistoryTableFields.json';
import fetchMethodRequest from '../../../config/service';
import {NewMailPopUpModal} from './NewReceivedMailPopUp';
import { Button } from 'reactstrap';


interface props{
    paramsDetails:any    
}

const MailForwardsReceivedMailHistory = ({ paramsDetails }:props) => {
    const [totalPayment, setTotalPayment] = useState<any>();
    const [isOpenNewMailPopUp, setIsOpenNewMailPopUp] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [opType, setOpType] = useState<string>();
    const [mailReceivedCategories, setMailReceivedCategories] = useState<any>([]);
    const [userId,setUserId] = useState<any>();
    const [recvdMailData,setRecvdMailData] = useState<any>();
    const [doRefresh, setDoRefresh] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<any>();
    const [successMessage, setSuccessMessage] = useState<any>(); 
    const { MailFwdTrackingID, DealType, SellerNickName, LocationName, KeyAddressFlag, Line1, City, StateProvince, Zip } = paramsDetails;
    const newReceivedMailData = { MailFwdTrackingID, DealType,SellerNickName,LocationName,KeyAddressFlag ,Line1,City,StateProvince,Zip};

    useEffect(() => {
        getTotalPayment();
        getCategories();
        let loginDetails:any = localStorage.getItem('loginCredentials');
        if(loginDetails){
            loginDetails = JSON.parse(loginDetails);
            if(loginDetails.azureAdUserId){
                setUserId(loginDetails.azureAdUserId);
            }
        }               
    },[]);

    const getTableFields = () => {
        let tableFields = JSON.parse(JSON.stringify(TableFields));
        tableFields.unshift( {
            "show": true,
            "textAlign": "left",
            "width": 30,
            "field": "MailFwdActivityID",
            "mobile": true,
            "header": "ID",
            "displayInSettings": true,
            "filter": true,
            "sortable": true,
            "fieldType": "customActions",
            "getCustomActions": showMailRecvdRecId
        });        
        return tableFields;
    }

    const showMailRecvdRecId = (col: any) => {
        return <span className="cursor-pointer text-info"  onClick={() => setDataForEdit(col)}>
            {col.MailFwdActivityID}
        </span>
    }

    const setDataForEdit = (rowData:any) => {
        setOpType('edit');
        let data = {
            ...newReceivedMailData,
            MailRecvdDate:rowData.MailRecvdDate ? new Date(rowData.MailRecvdDate) : '',
            IsValueableFlag:rowData.IsValueableFlag,
            MailRecvdValue:rowData.MailRecvdValue,
            MailRecvdCategory:rowData.MailRecvdCategory,
            MailRecvdPayorSender:rowData.MailRecvdPayorSender,
            MailRecvdDescription:rowData.MailRecvdDescription,
            RecvdPiecesOfMail:rowData.RecvdPiecesOfMail,
            MailRecvdOwner:rowData.MailRecvdOwner,
            MailFwdActivityID:rowData.MailFwdActivityID           
        }
        setRecvdMailData(data);
        setIsOpenNewMailPopUp(true);
    }

    const getCategories = async () => {        
        const res = await fetchMethodRequest('GET','mfRecvdActivity/getMailReceivedCategories','','','','');
        if(res && res.data && res.data.length > 0){
            setMailReceivedCategories(res.data.map((e: string) => { return { label: e, value: e } }));
        }
    }

    const createOrUpdateMailRecdRecord = async (data: any, type: 'add' | 'edit') => {
        setShowLoader(true);
        setDoRefresh(false);        
        let body:any = {
            data: data,
            userid: userId            
        }
        if(type === 'edit'){
            body.mailFwdActivityID = recvdMailData.MailFwdActivityID;
        }

        const url = type === 'edit' ? 'mfRecvdActivity/updateMFActivityRecord' : 'mfRecvdActivity/createMFActivityRecord';
        const res = await fetchMethodRequest('POST',url,JSON.parse(JSON.stringify(body)),'','','');
        if((type === 'add' && res && res.record) || (type === 'edit' && res && res.success)){
            getTotalPayment();
            setDoRefresh(true);
            setIsOpenNewMailPopUp(false);
            setSuccessMessage(res.message);
            setTimeout(() => {
                setSuccessMessage(''); 
            },5000);
        }else {
            setErrorMessage(res.message);
            setTimeout(() => {
                setErrorMessage(''); 
            },5000);
        }
        setShowLoader(false);        
    }



    const getDataTable = () => {
        return <DataTables
                    getTableFields={getTableFields}
                    formFields={getTableFields}
                    exportRequried={false}
                    printRequried={false}
                    addRequried={false}
                    editRequired={true}
                    sortField={"MailFwdActivityID"}
                    deleteRequired={false}
                    viewRequired={true}
                    settingsRequired={false}
                    filterRequired={true}
                    gridRequried={false}
                    isSelectMode={""}                    
                    sample={false}
                    scrollHeight={"580px"}
                    dataKey={"MailFwdActivityID"}
                    globalSearch={'Search'}
                    type={"Mail Forwards Received History"}
                    displayright={true}
                    icon='faCity'
                    fontAwesome={true}                    
                    displayViewOfForm='modal'
                    hideHeader={true}                    
                    className={true}
                    apiUrl={"mfRecvdActivity/getMFActivityRecordsFiltered"}
                    entityType='employee'                    
                    hideProgress={true}                    
                    paginatorPosition={'top'}
                    style={{ marginTop: 0 }}
                    donotIncludeParamsInFilter={true}
                    showTopActions={true}                    
                    removePadding={true}
                    dateFilterAsIs={true}
                    mailFwdId= {paramsDetails.MailFwdTrackingID}
                    showFilterElements={true}
                    doRefresh={doRefresh}
                />
    }

    const getTotalPayment = async () => {
        const res = await fetchMethodRequest('GET', `mfRecvdActivity/getTotalMailReceivedValue/${paramsDetails.MailFwdTrackingID}`, '', '', '', '');
        if(res && res.success){
            setTotalPayment(res.value ? res.value.toLocaleString("en-US", {style:"currency", currency:"USD"}) : '$0')
        }
    }

    const onClickNewRecord = () => {
        setOpType('add');
        setRecvdMailData(newReceivedMailData);
        setIsOpenNewMailPopUp(true);
    }


    return <div className='px-4'>
        <div className='d-flex justify-content-between'>
            <Button color="primary" type='submit' className="mb-1"  onClick={() => onClickNewRecord()} >
                + New Record
            </Button>
            {successMessage && <div className="text-center "><h4 className='text-success'> {successMessage} </h4></div>}
            <div>
                <h4>Total Payment : {totalPayment}</h4>
            </div>
        </div>
        {getDataTable()}
        {isOpenNewMailPopUp && <NewMailPopUpModal
            isOpenNewMailPopUp={isOpenNewMailPopUp}
            mailForwardsData={recvdMailData}
            closeNewMailPopUp={() => setIsOpenNewMailPopUp(false)}
            newNotes={''}
            mailReceivedCategories={mailReceivedCategories}                       
            noteCategoryTypes={[]}
            showLoader={showLoader}
            opType={opType}            
            errorMessage={errorMessage}
            createOrUpdateMailRecdRecord={createOrUpdateMailRecdRecord}
        />}        
    </div>
}

export default MailForwardsReceivedMailHistory;