import React, { useEffect, useRef, useState } from 'react';
import stateData from './states.json';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as documentsAPIs from '../../../../shared/documentsApis';
import fetchMethodRequest from '../../../../config/service';
import Loader from '../../../App/Loader';



export const DocSelectFields = (props) => {    
    let rowData;
    const [formData, setFormData] = useState({});
    const [deals, setDeals] = useState([]);
    const [selectingDeal, setSelectingDeal] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [editType, setEditType] = useState();
    const [disbaleGoButton, setDisbaleGoButton] = useState(true);
    const [selDocScopeName, setSelDocSccopeName] = useState([]);
    const [selCategory, setSelCategory] = useState();
    const [stateClaimIds, setStateClaimIds] = useState([]);
    const [mappedNames, setMappedNames] = useState([]);
    const [sellerRelevanceNames, setsellerRelevanceNames] = useState([]);
    const [selInEffect, setSelInEffect] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const userSelect = useRef(false);
    const fromStore = useRef(false);
    const urlParams = new URLSearchParams(window.location.search);
    const docRelevences = useRef([]);

    useEffect(() => {
        getSubCategories();              
    }, []);

    useEffect(() => {
        checkValidations(formData);
        saveInputValuesToSessionStorage();

    }, [formData]);

    useEffect(() => {          
        if (props.selectedDeal && props.selectedDeal.DealID) {
            setFormValues('deal', props.selectedDeal);
            getNames(props.selectedDeal);
        }
    }, [props.selectedDeal]);

    useEffect(() => {  
        // On getting subCategories,user or auto selecting/changing categories handling cases edit, redirection & tab changes to get and persist data.   
        if (subCategories && subCategories.length > 0 && ((props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME && (window.location.href.includes('documentUpload') || sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME))) || (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && (window.location.href.includes('claimDocumentation') || sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME)))) && !selInEffect) {            
            let params = new URLSearchParams(window.location.search);// on redirecting assigning parameters
            //On tab changes get values from session storage.
            if ((props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME && !window.location.href.includes('documentUpload')) || (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && !window.location.href.includes('claimDocumentation'))) {
                params = new URLSearchParams('');
            }
            if (params && !params.get('dealId')) {
                const values = sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME) ? JSON.parse(sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME)) : {};
                for (const prop in values) {                    
                    params.append(prop, values[prop] instanceof Object ? JSON.stringify(values[prop]) : values[prop]);
                }
                fromStore.current = true;
            } else {
                fromStore.current = false;
            }
            //If document upload tab auto assigning categories(subCategories) & scope (determines which fields to show) for the scenarios - edit(redirect from available docs), tab changes
            if (params && params.get('dealId')) {
                if (props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME) {
                    rowData = params.get('rowData') ? JSON.parse(decodeURIComponentSafely(params.get('rowData'))) : '';                    
                    //On redirecting from available documents setting category & scope 
                    if (params.get('type') && params.get('type') === 'edit' && (!selCategory || (selCategory && selCategory.length === 0))) {
                        let selIndex = params.get('index');   
                        if (rowData.selectedId) { 
                            getDocumentData(rowData.selectedId, '', params);
                            return;                       
                        } else if (rowData._id) {                                             
                            getDocumentData(rowData._id, '', params);
                            return;
                        }
                    }                    
                    const subCategory = selCategory ? selCategory : params.get('SubCategory') ? JSON.parse(params.get('SubCategory')) : rowData.SubCategory ? subCategories.filter((e) => e.subCategoryName === rowData.SubCategory) : rowData.subCategoryName ? subCategories.filter((e) => e.subCategoryName === rowData.subCategoryName) : '';                                      
                                       
                    if (subCategory && subCategory.length > 0) {
                        // const selCat = subCategories.filter((e) => e.subCategoryName === subCategory);
                        if (!selCategory) {
                            setSelInEffect(true);
                            setSelCategory(subCategory);
                        } else {
                            setSelInEffect(false);
                        }                       
                        let scopeNames = [];
                        if (subCategory && subCategory.length > 0) {
                            subCategory.forEach((e) => {
                                scopeNames.push(e.documentScopeName);
                            });
                        }                       
                        const selScope = [...new Set(scopeNames.flat())]
                        setValuesFromParams(params, props.tab, selScope, subCategory);
                    }
                } else {
                    setValuesFromParams(params, props.tab, []);
                }
            }
        }
    }, [subCategories, selCategory]);

    const decodeURIComponentSafely = (uri) => {
        try {
            return decodeURIComponent(uri)
        } catch(e) {            
            return uri
        }
    }
    
    /**
     * Get selected/clicked document in available documents details. 
     * @param {String} id 
     * @param {String} fileId 
     * @param {URLSearchParams} params 
     */
    const getDocumentData = async (id, fileId,params) => {        
        let api = `getDocumentById?Id=${id}`;
        const docDetails = await fetchMethodRequest('GET', api);
        const docData = docDetails.outResponse && docDetails.outResponse.details  ? docDetails.outResponse.details : '';
        if(props.setDocumentData){
            const id = docData.sharePointDetails && docData.sharePointDetails[0] && docData.sharePointDetails[0].id ? docData.sharePointDetails[0].id : '';
            props.setDocumentData(id,docData);
        }        
        if( docData && docData.documentRelevances && docData.documentRelevances.length > 0){
            docRelevences.current = docData.documentRelevances;
            const selSubCat = subCategories.filter((e) => (docData.documentRelevances.findIndex((doc) => doc.subCategoryName === e.subCategoryName)) > -1);            
            setSelInEffect(true);
            setSelCategory(selSubCat);
            let scopeNames = [];
            if (selSubCat && selSubCat.length > 0) {
                selSubCat.forEach((e) => {
                    scopeNames.push(e.documentScopeName);
                });
            }                       
            const selScope = [...new Set(scopeNames.flat())];            
            setValuesFromParams(params, props.tab, selScope, selSubCat,true);
            
        }
    }

    /**
     * Saving input values into session storage for input value persistence on tab change
     */
    const saveInputValuesToSessionStorage = () => {        
        let obj = sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME) ? JSON.parse(sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME)) : {};
        obj.SubCategory = selCategory && selCategory.length > 0 ? selCategory : obj.SubCategory ? obj.SubCategory : [];        
        if (formData.deal && formData.deal.DealID) {
            obj.dealId = formData.deal.DealID;
        }
        if (formData.selMappedName && formData.selMappedName.BusinessNamesID) {
            obj.mappedNameId = formData.selMappedName.BusinessNamesID;
        } 
        if (formData.selRelSellerName && formData.selRelSellerName.BusinessNamesID) {
            obj.relSellerNameId = formData.selRelSellerName.BusinessNamesID;
        } 
        if (formData.selState && formData.selState.shortCode) {
            obj.stateCode = formData.selState.shortCode;
        } 
        if (formData.selStateClaimId && formData.selStateClaimId.stateClaimID) {
            obj.stateClaimId = formData.selStateClaimId.stateClaimID;
        } 
        if (formData.selStateClaimId && formData.selStateClaimId.oppClaimID) {
            obj.OPPClaimID = formData.selStateClaimId.oppClaimID;
        } 
        sessionStorage.setItem(documentsAPIs.DOC_UPLOAD_TAB_NAME, JSON.stringify(obj));
    }
    /**
     * Set top input values from params
     * @param {URLSearchParams} params 
     * @param {String} tab 
     * @param {Array} docScopeNames 
     * @param {Array} [selCat]     
     */
    const setValuesFromParams = async (params, tab, docScopeNames, selCat) => {             
        let selFormatDataFromParams = {};
        const dealId = params.get('dealId');
        setisLoading(true);
        let names = props.names;
        let relNames = props.relSellerNames;
        const deals = await documentsAPIs.getDeals(dealId, 'dealId');
        if (names && names.length === 0) {
            names = await documentsAPIs.getNames('sellerRelevanceName', dealId);
            props.setDocumentsDropdownsData('names', names);
        }
        if (relNames && relNames.length === 0) {
            relNames = await documentsAPIs.getNames('mappedName', dealId);
            props.setDocumentsDropdownsData('relSellerNames', names);
        }
        // const [deals, names, relNames] = await Promise.all([documentsAPIs.getDeals(dealId, 'dealId'), documentsAPIs.getNames('mappedName', dealId), documentsAPIs.getNames('sellerRelevanceName', dealId)]);
        setDeals(deals);
        if (deals && deals.length > 0) {
            selFormatDataFromParams.deal = deals[0];
            setSelectingDeal(deals[0]);
        }        
        if (names && names.length > 0 && params.get('mappedNameId') && (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || docScopeNames.includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME))) {
            const selElem = names.find((e) => e.BusinessNamesID.toString() === params.get('mappedNameId'));            
            selFormatDataFromParams.selMappedName = selElem;
        }
        if (relNames && relNames.length > 0 && params.get('relSellerNameId') && (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || docScopeNames.includes(documentsAPIs.DOC_SCOPE_REL_SELLER))) {
            const selElem = relNames.find((e) => e.BusinessNamesID.toString() === params.get('relSellerNameId'));
            selFormatDataFromParams.selRelSellerName = tab === documentsAPIs.AVAIL_DOCS_TAB_NAME ? selElem : [selElem];                     
            if(params.get('type') && params.get('type') === 'edit' && tab !== documentsAPIs.AVAIL_DOCS_TAB_NAME && docRelevences.current && docRelevences.current.length > 0){               
                const relBusinessIds = [...new Set(docRelevences.current.map((e) => e.businessNameId))];
                selFormatDataFromParams.selRelSellerName = relNames.filter((e) => relBusinessIds.includes(e.BusinessNamesID));
                
            }

        }
        if (params.get('stateCode') && (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || docScopeNames.includes(documentsAPIs.DOC_SCOPE_STATE))) {
            selFormatDataFromParams.selState = stateData.states.find((e) => e.shortCode === params.get('stateCode'));
            if (params.get('OPPClaimID') && (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || docScopeNames.includes(documentsAPIs.DOC_SCOPE_STATE))) {
                let stateClaimIdstemp = props.stateClaimIds;
                if (stateClaimIdstemp && stateClaimIdstemp.length === 0) {
                    props.setLoader(true);
                    stateClaimIdstemp = await documentsAPIs.getStateClaimIds(dealId, params.get('stateCode'));
                    props.setDocumentsDropdownsData('stateClaimIds', stateClaimIdstemp);
                }
                if (stateClaimIdstemp) {
                    selFormatDataFromParams.selStateClaimId = stateClaimIdstemp.find((e) => e.oppClaimID.toString() === params.get('OPPClaimID'));
                }
                setStateClaimIds(stateClaimIdstemp);

            } else {
                getStateClaimIds({ shortCode: params.get('stateCode') })
            }
        }        
        setMappedNames(names);
        setsellerRelevanceNames(relNames);
        setFormData(selFormatDataFromParams);
        setSelDocSccopeName(docScopeNames);        
        checkValidations(selFormatDataFromParams,docScopeNames); 
        setisLoading(false);                   
        if(userSelect.current || fromStore.current){                               
            return;
        }            
        if (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME) {
            let validated = checkValidations(selFormatDataFromParams);
            if (validated) {
                props.getAllAvailableDocuments(selFormatDataFromParams, JSON.parse(JSON.stringify(subCategories)));
            }
        } else {             
            if (window.location.href.includes('documentUpload') && checkValidations(selFormatDataFromParams,docScopeNames)) {                            
                props.getDocumentDetails(selFormatDataFromParams, selCat, names, rowData);
            }
        }
        
        
    }

    const setFormValues = (name, value) => {
        if (props.clearTableData) {
            props.clearTableData();
        }
        if (name === 'selCategory') {
            onSelCategory(value)
            return;
        }
        if (name === 'deal') {
            let formData = {
                [name]: value
            }
            setFormData(formData);
            if (!value) {
                setSelectingDeal(value)
                sessionStorage.setItem(documentsAPIs.DOC_UPLOAD_TAB_NAME, JSON.stringify({ SubCategory: selCategory && selCategory.subCategoryName ? selCategory.subCategoryNam : null }));
            }
        } else {
            setFormData((ps) => ({ ...ps, [name]: value }));
            if (name === 'selState') {
                setFormValues('selStateClaimId', '');
                setStateClaimIds([]);
            }
        }

    }

    const getDeals = async (e) => {
        const res = await documentsAPIs.getDeals(e.query, 'str');
        setDeals(res);
    }

    const getNames = async (deal) => {
        setisLoading(true);
        const names = await documentsAPIs.getNames('mappedName', deal.DealID);
        const relNames = await documentsAPIs.getNames('sellerRelevanceName', deal.DealID);
        setMappedNames(names);
        props.setDocumentsDropdownsData('names', names);
        setsellerRelevanceNames(relNames);
        props.setDocumentsDropdownsData('relSellerNames', relNames);
        setisLoading(false);
    }

    const onSelCategory = (value) => {  
        setSelInEffect(false);    
        setSelCategory(value ? value : []);
        setDisbaleGoButton(true);      
        setSelectingDeal(null);
        if (props.onSelCategory) {
            props.onSelCategory(value);
        }
        let scopeNames = [];
        if (value && value.length > 0) {
            value.forEach((e) => {
                scopeNames.push(e.documentScopeName);
            });
        }
        setSelDocSccopeName([...new Set(scopeNames.flat())]);       
    }
    const getSubCategories = async () => {
        setisLoading(true);
        const res = await documentsAPIs.getSubCategories();
        setSubCategories(res.filter((e) => e.subCategoryName !== documentsAPIs.CLAIM_PKT));
        setisLoading(false);
    }



    const checkValidations = (formData, docScopeNames) => {
        let disableGo = false;
        if (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME) {
            if (!formData.deal || !formData.selStateClaimId || !formData.selMappedName || !formData.selRelSellerName || !formData.selState) {
                disableGo = true;
            }
        } else if (props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME) {
            const scope = docScopeNames && docScopeNames.length > 0 ? docScopeNames : selDocScopeName && selDocScopeName.length > 0 ? selDocScopeName : [];            
            if (scope.length > 0) {
                scope.forEach((e) => {
                    if ((e === documentsAPIs.DOC_SCOPE_DEAL && !formData.deal) || (e === documentsAPIs.DOC_SCOPE_OPP_CLAIMID && !formData.selStateClaimId) || (e === documentsAPIs.DOC_SCOPE_CLAIMID && !formData.selStateClaimIdOnly) ||
                        (e === documentsAPIs.DOC_SCOPE_MAPPED_NAME && !formData.selMappedName) || (e === documentsAPIs.DOC_SCOPE_REL_SELLER && !formData.selRelSellerName) ||
                        (e === documentsAPIs.DOC_SCOPE_STATE && !formData.selState)) {
                        disableGo = true;
                    }

                });

            } 

        }
        setDisbaleGoButton(disableGo);
        return !disableGo;
    }

    const removeSearchParams = () => {
        const params = new URLSearchParams(window.location.search);
        if (params && params.get('dealId')) {
            let valueChanged = false;
            params.forEach((value, key) => {
                if (!valueChanged) {
                    valueChanged = checkValueChange(key, value);                    
                }
            });
            if (valueChanged) {
                window.history.replaceState(null, null, `${window.location.origin}/sellersEdit/documentUpload/${props.selectedDeal.DealID}`);
                saveInputValuesToSessionStorage();
            }
        }
    }

    const checkValueChange = (elem, value) => {
        let changed = false
        switch (elem) {
            case 'dealId':
                if (formData.deal && formData.deal.DealID && formData.deal.DealID.toString() !== value) {
                    changed = true;
                }
                break;
            case 'mappedNameId':
                if (formData.selMappedName && formData.selMappedName.BusinessNamesID && formData.selMappedName.BusinessNamesID.toString() !== value) {
                    changed = true;
                }
            case 'relSellerNameId':
                if (formData.selRelSellerName && formData.selRelSellerName.BusinessNamesID && formData.selRelSellerName.BusinessNamesID.toString() !== value) {
                    changed = true;
                }
                break;
            case 'stateCode':
                if (formData.selState && formData.selState.shortCode && formData.selState && formData.selState.shortCode !== value) {
                    changed = true;
                }
                break;           
            case 'stateClaimId':
                if (formData.selStateClaimId && formData.selStateClaimId.oppClaimID && formData.selStateClaimId.oppClaimID.toString() !== value) {
                    changed = true;
                }
        }
        return changed;
    }

    const namesDropDownTemplate = (col) => {
        return <span>{col.CombinedName}</span>
    }

    const getStateClaimIds = async (selState) => {
        props.setLoader(true);
        let filter = {
            "criteria": [
                { "key": "CompanyID", "value": formData.deal && formData.deal.DealID ? formData.deal.DealID : '', "type": "eq" },
                { "key": "SourceState", "value": selState.shortCode ? selState.shortCode : '', "type": "eq" },
                { "key": "ClaimStatus", "value": 'Paid', "type": "ne" },
                { "key": "ASSET_TRACKING_STATUS", "value": ['Active'], "type": "in" }
            ],
        };
        const result = await fetchMethodRequest('GET', `nsClaim?filter=${JSON.stringify(filter)}&type=exportToCsv&project=false`);
        if (result && result.claims && result.claims.length > 0) {
            const data = result.claims.filter((elem) => elem.oppClaimID)
            setStateClaimIds(data);
            props.setDocumentsDropdownsData('stateClaimIds', data);
        } else {
            setStateClaimIds([]);
        }
        props.setLoader(false);
    }

    const onClickGo = () => {
        // removeSearchParams();
        if (props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME) {
            props.getDocumentDetails(JSON.parse(JSON.stringify(formData)), selCategory, mappedNames);
        } else {
            props.getAllAvailableDocuments(JSON.parse(JSON.stringify(formData)), JSON.parse(JSON.stringify(subCategories)));
        }
    }

    /**
     * Show red star besides mandatory fields
     * @returns {HTMLElement}
     */
    const getMandatoryStar = () => {
        return <FontAwesomeIcon icon={faStarOfLife}
            className='pl-1'
            color='red'
            data-toggle="tool-tip"
            title="Mandotary Field"
            style={{ width: 8 }}
        />
    }

    const getCloseIcon = (field) => {
        return <FontAwesomeIcon icon={faTimes}
            className='pl-1 mr-2'
            color={formData[field] || (field === 'selCategory' && selCategory) ? 'red' : 'lightgrey'}
            data-toggle="tool-tip"
            title="Clear Filter"
            id="clear stClaimId"
            onClick={() => setFormValues(field, '')}
            style={{ width: 'unset', cursor: "pointer", float: "right", pointerEvents: `${(formData[field] || (field === 'selCategory' && selCategory)) ? 'auto' : 'none'}` }}
        />
    }

    const getLabel = (label, field) => {
        return <label className='d-flex justify-content-between mb-0'>
            <span>{label}{getMandatoryStar()}</span>
            {props.tab !== documentsAPIs.AVAIL_DOCS_TAB_NAME && getCloseIcon(field)}
        </label>
    }

    return (
        <div className='row mt-1 doc-select-fields'>
            {props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME &&

                <div className="form__form-group col-lg-2">
                    {getLabel('Category', 'selCategory')}
                    <div className='form__form-group-field doc-categories'>
                        <MultiSelect value={selCategory} optionLabel='subCategoryName' placeholder='Select Category' onChange={(e) => { userSelect.current = true ;onSelCategory(e.value) }} options={subCategories}
                            style={{ width: '100%' }} disabled={urlParams.get('dealId') && (!selDocScopeName || selDocScopeName.length === 0)} filter={true} filterPlaceholder="Search" dataKey="_id" maxSelectedLabels={1}
                            filterMatchMode='startsWith' filterInputAutoFocus={true} />
                    </div>
                </div>
            }
            {(props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || (selDocScopeName && selDocScopeName.length > 0)) && <>
                {/* {(props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || selDocScopeName.includes(documentsAPIs.DOC_SCOPE_DEAL)) &&
                    <div className="form__form-group col-lg-2">
                        {getLabel('Deal', 'deal')}
                        <div className=' form__form-group-field'>
                            <AutoComplete value={selectingDeal}
                                field='combName'
                                className='w-100'
                                onChange={(e) => setSelectingDeal(e.value)}
                                onSelect={(e) => { setFormValues('deal', e.value); getNames(e.value) }}
                                onClear={(e) => { setFormValues('deal', e.value) }}
                                minLength={1}
                                suggestions={deals}
                                dropdown={false}
                                completeMethod={getDeals}
                                placeholder='Search Deal Nickname'
                            
                            />
                        </div>
                    </div>
                } */}
                {(props.tab !== documentsAPIs.AVAIL_DOCS_TAB_NAME && selDocScopeName.includes(documentsAPIs.DOC_SCOPE_STATE)) &&
                    <div className="form__form-group col-lg-2">
                        {getLabel('State', 'selState')}
                        <div className=' form__form-group-field'>
                            <Dropdown value={formData.selState} optionLabel='label' placeholder='Select State' onChange={(e) => { setFormValues('selState', e.value); getStateClaimIds(e.value) }} options={stateData.states}
                                style={{ width: '100%' }} filter={true} filterPlaceholder="Search"
                                filterMatchMode='startsWith' filterInputAutoFocus={true} disabled={!formData.deal || editType === 'update' || (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && (window.location.href.includes('claimDocumentation'))&& urlParams.get('dealId'))} />
                        </div>
                    </div>
                }
                {(props.tab !== documentsAPIs.AVAIL_DOCS_TAB_NAME && selDocScopeName.includes(documentsAPIs.DOC_SCOPE_OPP_CLAIMID)) &&
                    <div className="form__form-group col-lg-2">
                        {getLabel('OPP Claim ID', 'selStateClaimId')}
                        <div className=' form__form-group-field'>
                            <Dropdown value={formData.selStateClaimId} placeholder='Select OPP Claim ID' optionLabel='oppClaimID' onChange={(e) => setFormValues('selStateClaimId', e.value)} options={stateClaimIds}
                                style={{ width: '100%' }} filterMatchMode='startsWith' dataKey='_id' filter={true} filterPlaceholder="Search" filterInputAutoFocus={true} disabled={!formData.deal || editType === 'update' || (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && (window.location.href.includes('claimDocumentation')) && urlParams.get('dealId'))} />
                        </div>
                    </div>
                }
                 {(props.tab !== documentsAPIs.AVAIL_DOCS_TAB_NAME && selDocScopeName.includes(documentsAPIs.DOC_SCOPE_CLAIMID)) &&
                    <div className="form__form-group col-lg-2">
                        {getLabel('State Claim ID', 'selStateClaimIdOnly')}
                        <div className=' form__form-group-field'>
                            <Dropdown value={formData.selStateClaimIdOnly} placeholder='Select State Claim ID' optionLabel='stateClaimID' onChange={(e) => setFormValues('selStateClaimIdOnly', e.value)} options={stateClaimIds}
                                style={{ width: '100%' }} filterMatchMode='startsWith' dataKey='_id' filter={true} filterPlaceholder="Search" filterInputAutoFocus={true} disabled={!formData.deal || editType === 'update' || (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && (window.location.href.includes('claimDocumentation')) && urlParams.get('dealId'))} />
                        </div>
                    </div>
                }

                {(props.tab !== documentsAPIs.AVAIL_DOCS_TAB_NAME && selDocScopeName.includes(documentsAPIs.DOC_SCOPE_OPP_CLAIMID) && !selDocScopeName.includes(documentsAPIs.DOC_SCOPE_CLAIMID)) && 
                    <div className="form__form-group col-md-1 unset-min-size"  data-toggle="tooltip" data-placement="left" title={formData.selStateClaimId && formData.selStateClaimId.stateClaimID ? formData.selStateClaimId.stateClaimID : null}>
                        <label className='d-flex justify-content-between mb-0'>
                            State Claim ID                         
                        </label>
                        <div className=' form__form-group-field'>
                            <Dropdown value={formData.selStateClaimId} placeholder='Select State Claim ID' optionLabel='stateClaimID' onChange={(e) => setFormValues('selStateClaimId', e.value)} options={stateClaimIds}
                                style={{ width: '100%' }} filterMatchMode='startsWith' dataKey='_id' filter={true} filterPlaceholder="Search" filterInputAutoFocus={true} disabled />
                        </div>
                    </div>
                }
                {(props.tab !== documentsAPIs.AVAIL_DOCS_TAB_NAME && selDocScopeName.includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME)) &&
                    <div className="form__form-group sellerCNNamesDropDown col-lg-2">
                        {getLabel('Mapped Name', 'selMappedName')}
                        <div className=' form__form-group-field'>
                            <Dropdown value={formData.selMappedName} dataKey='BusinessNamesID' placeholder='Select Mapped Name' optionLabel='BusinessName' onChange={(e) => setFormValues('selMappedName', e.value)} options={mappedNames}
                                filter={true} filterPlaceholder="Search" filterMatchMode='startsWith' filterInputAutoFocus={true} filterBy="BusinessName" disabled={!formData.deal || editType === 'update' || (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && (window.location.href.includes('claimDocumentation'))&& urlParams.get('dealId'))} itemTemplate={namesDropDownTemplate} />
                        </div>
                    </div>
                }
                {(props.tab !== documentsAPIs.AVAIL_DOCS_TAB_NAME && selDocScopeName.includes(documentsAPIs.DOC_SCOPE_REL_SELLER)) &&
                    <div className="form__form-group sellerCNNamesDropDown col-lg-2">
                        {getLabel('Relevant Seller Name', 'selRelSellerName')}
                        <div className=' form__form-group-field'>
                            <MultiSelect value={formData.selRelSellerName} dataKey='BusinessNamesID' placeholder='Select Relevant Seller Name.' optionLabel='BusinessName' onChange={(e) => setFormValues('selRelSellerName', e.value)} options={sellerRelevanceNames} className='w-100'
                                filter={true} filterPlaceholder="Search" maxSelectedLabels={1} filterMatchMode='startsWith' filterInputAutoFocus={true} filterBy="BusinessName" disabled={!formData.deal || editType === 'update' || (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && (window.location.href.includes('claimDocumentation')) && urlParams.get('dealId'))} itemTemplate={namesDropDownTemplate} />
                        </div>
                    </div>
                }

                {/* showing disabled text fields in claim documentation  */}
                {(props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME) &&
                    <>
                        <div className="form__form-group col-lg-2">
                            {getLabel('State', 'selState')}
                            <div className=' form__form-group-field'>
                                <InputText className='w-100' value={formData.selState && formData.selState.label ? formData.selState.label : ''} disabled={true} />
                            </div>
                        </div>


                        <div className="form__form-group col-lg-2">
                            {getLabel('OPP Claim ID', 'selStateClaimId')}
                            <div className=' form__form-group-field'>
                                <InputText className='w-100' value={formData.selStateClaimId && formData.selStateClaimId.oppClaimID ? formData.selStateClaimId.oppClaimID : ''} disabled={true} />
                            </div>
                        </div>



                        <div className="form__form-group col-md-1 unset-min-size" data-toggle="tooltip" data-placement="left" title={formData.selStateClaimId && formData.selStateClaimId.stateClaimID ? formData.selStateClaimId.stateClaimID : null}>
                            <label className='d-flex justify-content-between mb-0'>
                                State Claim ID
                            </label>
                            <div className=' form__form-group-field'>
                                <InputText className='w-100' value={formData.selStateClaimId && formData.selStateClaimId.stateClaimID ? formData.selStateClaimId.stateClaimID : ''} disabled={true} />
                            </div>
                        </div>



                        <div className="form__form-group sellerCNNamesDropDown col-lg-2">
                            {getLabel('Mapped Name', 'selMappedName')}
                            <div className=' form__form-group-field'>
                                <InputText className='w-100' value={formData.selMappedName && formData.selMappedName.BusinessName ? formData.selMappedName.BusinessName : ''} disabled={true} />
                            </div>
                        </div>


                        <div className="form__form-group sellerCNNamesDropDown col-lg-2">
                            {getLabel('Relevant Seller Name', 'selRelSellerName')}
                            <div className=' form__form-group-field'>
                                <InputText className='w-100' value={formData.selRelSellerName && formData.selRelSellerName.BusinessName ? formData.selRelSellerName.BusinessName : ''} disabled={true} />
                            </div>
                        </div>


                       
                    </>
                }
                {(props.tab !== documentsAPIs.AVAIL_DOCS_TAB_NAME) &&
                    <div className="col-md-1 mt-1 d-flex">
                        <Button
                            className='go-button' style={{ marginTop: 'auto', marginBottom: '3px' }} disabled={disbaleGoButton} onClick={() => { onClickGo() }}  >
                            Go
                        </Button>
                    </div>
                }
               
            </>}
            <Loader loader={isLoading} />

        </div>
    )
}