import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
// import RolePermissions from '../../CommonModals/Permissions';

import { InputText } from "primereact/inputtext";
import { Button } from 'reactstrap';
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fetchMethodRequest from '../../../../config/service';
import Loader from '../../../App/Loader';
import { createRef } from "react";





// config file
export default class DraftingNameCodeConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Drafting Name Code Configuration',
      formData: {
        NameCode: null,
        NameCodeDescription: null,
        NameCodeID: null,
        NameCodeType: null,
        Sequence: null,
        DWRowIsCurrent: false,
        NameCodeID:undefined

      },
      isLoading: false,
      selectedRow: {},
      formType: 'add',
      isModalOpen:false

    };
    this.formModalRef = createRef();
  }




  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
  } 


  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Sequence',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Drafting Name Code Sequence',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        textAlign: 'left',
        width: 130,
        field: 'NameCode',
        "mobile": true,
        fieldType: 'BoolText',
        header: 'Drafting Name Code',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 500,
        field: 'NameCodeDescription',
        "mobile": true,
        header: 'Drafting Name Code Description',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 130,
        field: 'NameCodeType',
        "mobile": true,
        header: 'Drafting Name Code Type',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 130,
        "field": "DWDateInserted",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Created Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 170,
        "field": "DWCreatedBy",
        "mobile": true,
        "header": "Created By",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        textAlign: "center",
        show: true,
        mobile: true,
        width: 130,
        field: "DWDateLastModified",
        fieldType: "Date",
        dateFormat: 'MM/DD/YYYY',
        header: "Updated Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 170,
        "field": "DWLastModifiedBy",
        "mobile": true,
        "header": "Last Updated By",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "number",
        "name": "Sequence",
        "label": "Drafting Name Code Sequence",
        "id": "name",
        "placeholder": "Drafting Name Code Sequence",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "NameCode",
        "label": "Drafting Name Code",
        "id": "name",
        "placeholder": "Drafting Name Code",
        "required": true
      },

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "NameCodeDescription",
        "label": "Drafting Name Code Description",
        "id": "",
        "placeholder": "Drafting Name Code Description",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "NameCodeType",
        "label": "Drafting Name Code Type",
        "id": "",
        "placeholder": "Drafting Name Code Type",
        // "required": true
      },

    ]);
  }


  handleInputChange = (key, value) => {
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [key]: value }
    }));
  };

  saveDataToServer = async (method, url, body) => {
    await fetchMethodRequest(method, url, body).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        await this.datatableref.closeCustomConfigForNextActionUserReport()
        await this.datatableref.getDataFromServer(this.datatableref.getFilterCriteria());
        this.emptyFields();
        await this.setState({ clickSaved: false , isModalOpen : false })
        this.datatableref.getErrorMessage(response.respMessage, "", "success")
      }else{
        this.datatableref.getErrorMessage(response.errorMessage, "", "error")
      }
      await this.setState({ isLoading: false })
    })
  }

  checkValidation = async (body) => {
    return Object.values(body).some(value => value === null || value === '' || value.length === 0);

  }

  saveRecord = async (type) => {
    let formField = await this.state.formData
    await this.setState({ isLoading: true, clickSaved: true })
    const method = type === 'edit' ? 'PUT' : 'POST';
    const url = type === 'edit' ? `${apiCalls.draftingNameCode}/${this.state.NameCodeID}` : apiCalls.draftingNameCode;
    let body = formField;
    delete body.NameCodeID;
    if (body.Sequence  &&  typeof body.Sequence !== 'number') {
      body.Sequence = body.Sequence ?  Number(body.Sequence) : null ;
    }
    let validated = await this.checkValidation(body)
    if (validated) {
      this.setState({ isLoading: false })
    } else {
      this.saveDataToServer(method, url, body);
    }
  }

  setValuesForEdit = async (rowData, type) => {
    if (type === "edit" && (rowData && Object.keys(rowData).length > 0)) {
      const formFields = await this.state.formData
      const keys = Object.keys(formFields);
      let selectedData = {}
      keys.map((field, i) => selectedData[field] = rowData[field])
      await this.setState({
        NameCodeID:rowData.NameCodeID,
        formData: selectedData,
        formType: type
      });
    }
  }

  closeCustomConfigForNextActionUserReport = async () => {
    this.setState({ isModalOpen: false });
    await this.datatableref.closeCustomConfigForNextActionUserReport()
    this.emptyFields();
    await this.setState({ clickSaved: false })
  }

  getErrors = () => {
    return <div className="mb-1" style={{ height: '10px' }}>
      {<span className="form__form-group-error">{"Please fill the field"}</span>}
    </div>
  }

  setRowsEmpty = async () => {
    await this.emptyFields()
  }

  emptyFields = () => {
    this.setState({
      formType: 'add',
      formData: {
        NameCode: '',
        NameCodeDescription: '',
        NameCodeID: '',
        NameCodeType: '',
        Sequence: '',
        DWRowIsCurrent: false,
      }
    });
  };

  getRequiredMark = () =>{
    return <span >
    <FontAwesomeIcon icon={faStarOfLife}
      className='pl-1'
      color='red'
      data-toggle="tool-tip"
      title="Mandotary Field"
      style={{ width: 11 }}
    /></span>
  }

   // Open the modal and scroll into view
   getOpenModal =  () => {
    if (!this.state.isModalOpen) {
       this.setState({ isModalOpen: true }, ()=>{
          if (this.formModalRef.current) {
            console.log('formModalRef' , this.formModalRef.current.scrollIntoView)
            this.formModalRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
    });
    }
  };

  getNextActionConfig = (formType) => {

    const { formData } = this.state;

    return (<div  ref={this.formModalRef} style={{ border: "1px solid" }}>

      <div className='d-flex col-sm-12 p-0 mb-1'>
      <span> <Loader loader={this.state.isLoading} /></span>
        <div className='d-flex col-sm-3 align-items-center'>
          <div className='col-sm-6 p-0 mr-3 text-primary'>
            {'Drafting Name Code Sequence'}
            {this.getRequiredMark()}
          </div>

          <div className='mt-2 col-sm-6 p-0' style={{ height: '42px' }}>
            <InputText
              type="text"
              name='Sequence'
              id='Sequence'
              style={{ width: "100%", height: '32px' }}
              value={formData.Sequence}
              onChange={(e) => this.handleInputChange("Sequence", e.target.value)}
              placeholder={''}
            // size="1"
            />
            {(formData.Sequence == null || formData.Sequence == "") && this.state.clickSaved ?
              this.getErrors()
              : null}
          </div>
        </div>

        <div className='d-flex justify-content-end align-items-center col-sm-3 p-0'>
          <div className='col-sm-6 text-right pr-2 p-0 text-primary'>
            {'Drafting Name Code'}
            {this.getRequiredMark()}
          </div>

          <div className='mt-2 col-sm-6 p-0' style={{ height: '42px' }}>
            <InputText
              type="text"
              name='NameCode'
              id='NameCode'
              style={{ width: "100%", height: '32px' }}
              value={formData.NameCode}
              onChange={(e) => this.handleInputChange("NameCode", e.target.value)}
              placeholder={''}
            // size="1"
            />
            {(formData.NameCode == null || formData.NameCode == "") && this.state.clickSaved ?
              this.getErrors()
              : null}
          </div>
        </div>

        <div className='d-flex align-items-center col-sm-6'>
          <div className='col-sm-3 p-0 text-primary'>
            {'Drafting Name Code Description'}
            {this.getRequiredMark()}
          </div>

          <div className='mt-2 col-sm-9 p-0' style={{ height: '42px' }}>
            <InputText
              type="text"
              name='NameCodeDescription'
              id='NameCodeDescription'
              style={{ width: "100%", height: '32px' }}
              value={formData.NameCodeDescription}
              onChange={(e) => this.handleInputChange("NameCodeDescription", e.target.value)}
              placeholder={''}
            // size="1"
            />
            {(formData.NameCodeDescription == null || formData.NameCodeDescription == "") && this.state.clickSaved ?
              this.getErrors()
              : null}
          </div>
        </div>

      </div>


      <div className='col-md-12  d-flex mt-2'>

        <div className='d-flex align-items-center col-sm-6 p-0'>
          <div className='col-sm-3 p-0 text-primary'>
            {'Drafting Name Code Type'}
            {this.getRequiredMark()}
          </div>

          <div className='mt-2 col-md-9 p-0' style={{ height: '42px' }}>
            <InputText
              type="text"
              name='NameCodeType'
              id='NameCodeType'
              style={{ width: "100%", height: '32px' }}
              value={formData.NameCodeType}
              onChange={(e) => this.handleInputChange("NameCodeType", e.target.value)}
              placeholder={''}
            // size="1"
            />
            {(formData.NameCodeType == null || formData.NameCodeType == "") && this.state.clickSaved ?
              this.getErrors()
              : null}
          </div>
        </div>

        <div className='d-flex align-items-center col-sm-6'>
          <div className='col-sm-3 p-0 text-primary'>
            {'Active/Inactive Status'}
          </div>

          <div className='mt-2 col-sm-6 p-0
           customSwitch col-sm-6 d-flex' style={{
              justifyContent: "space-between",
            }}
          >
            <InputSwitch
              checked={formData.DWRowIsCurrent}
              onChange={(e) => this.handleInputChange("DWRowIsCurrent", e.value)}
            />
          </div>

        </div>

      </div>

      <div className="flex justify-content-center align-items-center mt-2">

        <Button style={{ fontSize: 18, width: "10%", "background-color": "green" }}
          onClick={() => this.saveRecord(this.state.formType)}
          className={`text-white p-0 p-button-rounded mt-2 ml-3 d-block`}
        >
          {this.state.formType === "edit" ? "Update" : "Save"}
        </Button>
        <Button style={{ fontSize: 18, width: "10%", "background-color": "#354f6e" }}
          className={`text-white p-0 p-button-rounded mt-2 ml-3 d-block`}
          type='button'
          onClick={() => this.closeCustomConfigForNextActionUserReport()}
        > Cancel </Button>
      </div>

    </div>);
  }

  render() {
    return (
      <span>
        {this.state.isModalOpen && this.getNextActionConfig()}
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          sortField={"Sequence"}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          setRowsEmpty={this.setRowsEmpty}
          type='Drafting Name Code Configuration'
          displayright={true}
          icon={null}
          routeTo='dealconfigurations'
          displayViewOfForm='modal'
          apiResponseKey={"draftingNameCodeConfigurations"}
          apiUrl={apiCalls.draftingNameCode}
          getNextActionConfig={this.getOpenModal}
          setValuesForEdit={this.setValuesForEdit}
          nextActionReport={true}
          customForm={true}
          entityType='employee'
        />
      </span>
    );
  }
}