import envConfig from "./envConfig";

const config = {
  // employee
  // apiUrl: 'http://167.99.10.87:5200/api/',

  apiUrl: `${envConfig.REACT_APP_API_URI.trim().trimEnd("/")}/api/`,
  imgUrl: `${envConfig.REACT_APP_API_URI.trim()}/images/employee`,
  multipleimgUrl: `${envConfig.REACT_APP_API_URI.trim()}/images/`,
  dealUrl: `${envConfig.REACT_APP_NAME_SCORING_DEALS}/api/deals/sellerData/?MultiThreadingCount=5&ScoringThresholds={%22name%22:0,%22addressLine%22:0,%22CityStateZip%22:0}&useResidentialIndex=false&companySourceType=OPRA2&ScoringType=DealSizing&ElasticQueryComplexity=COMPLEX&typeOfIndex="com"`,
  nameUrl: `${envConfig.REACT_APP_NAME_SCORING}/`,
  openCorporateSearchUrl: `${envConfig.REACT_APP_OPEN_CORPORATES_SEARCH}/api/`,
  openCorporateSearchFileUrl: `${envConfig.REACT_APP_OPEN_CORPORATES_SEARCH}/files/`,
  missingMoneyUrl: `${envConfig.REACT_APP_PROPERTY_LOADER}/api/`,
  apiDealUrl: `${envConfig.REACT_APP_NAME_SCORING_DEALS}/api/`,
  opraPropertyCreationUrl: `${envConfig.REACT_APP_OPRA_PROPERTY_CREATION_URL}`,
  opraMultiPropertyCreationUrl: `${envConfig.REACT_APP_OPRA_MULTI_PROPERTY_CREATION_URL}`,
  negativeNoticeUrl: `${envConfig.REACT_APP_NEGATIVE_NOTICE_REDIRECTION_URL}`,
  openCorporatesApiToken: `${envConfig.REACT_APP_OPEN_CORPORATES_API_TOKEN}`,
  environment: `${envConfig.REACT_APP_ENVIRONMENT}`,
  mailForwardsURL: `${envConfig.REACT_APP_MAIL_FORWARDS_URL}/api/`,

  // apiUrl: '',
  // imgUrl: '',
  socketUrl: "http://api.appbroda.dosystemsinc.com/",
  janusUrl: "wss://janus.dosystemsinc.com:8989/janus",
  // janusUrl: 'wss://vyjanus.dosystemsinc.com:8989/janus',
  // janusUrl: 'ws://janus.dosystemsinc.com:8188/janus',
  serverErrMessage: "Could Not reach server",

  //regex
  borderValidation: false,
  messages: true,

  entityType: "user",

  appName: "Opra2",
  version: 7, //only positive integers
  loginName: "Employee",
  emailRegex:
    /^(?=.{1,50}$)[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
  passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/,
  aadharcardNumberRegex: /^([0-9]){12}$/,
  pancardNumberRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  // userPasswordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\?$₹&<>{}`+*~_();:/%-.]).{12,}$/,
  //phoneNumberRegex: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  phoneNumberRegex: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
  userNameRegex: /^[a-zA-Z\s]{1,30}$/,
  lastNameRegex: /^[a-zA-Z\s]{1,30}$/,
  subjectRegex: /^[a-zA-Z\s]{1,50}$/,
  companyRegex: /^([A-Za-z0-9\s@.,]){1,30}$/,
  roomIdRegex: /^([0-9]){4,10}$/,
  // server response codes
  updateResCode: 205,
  deleteResCode: 206,
  mandatoryDate: "12/01/2024",
  datePlaceholder: "--/--/----",
  dateFormat: "MM/DD/YYYY",
  dateTabularFormat: "MMM DD YYYY",
  dateDisplayModalFormat: "DD MMM YYYY",
  dateDBFormat: "MM-DD-YYYY",
  dateDayMonthFormat: "DD-MM-YYYY",
  dateYearMonthFormat: "YYYY-MM-DD",
  dayYearDateFormat: "YYYY-MM-DD",
  basicDateFromat: "MM/DD/YYYY HH:mm A",
  descDateFromat: "MMM DD YYYY HH:mm A",
  timeFormatfull: "HH:mm:ss.SS",
  timeFormat: "HH:mm",
  timeFormatin12hours: "HH:mm a",
  syncTimeFormat: "hh:mm A, MM-DD-YYYY",
  lastModifiedDateFormat: "MM/DD/YYYY HH:mm",
  dateTimeFormat: "MM/DD/YYYY hh:mm",
  fullDateFormat: "YYYY-MM-DD HH:mm:ss",
  fullDateTimeFormat: "YYYY-MM-DD[T]HH:mm:ss.SSZ",
  dbDateFormat: "YYYY-MM-DD[T]HH:mm:ss",
  dbOnlyDateFormat: "YYYY-MM-DD[T]00:00:00",
  dbcstOnlyDateFormat: "YYYY-MM-DD[T]07:00:00",
  dbOnlyCalenderDateFormat: "YYYY-MM-DD[T]12:00:00",

  // ESTTimezone: "America/New_York",
  ESTTimezone: "America/Chicago",
  currency: "USD",
  noView: "noView",
  edit: "edit",
  view: "view",
  //templateColor: '#0e4768',
  templateColor: "#fffff",
  whiteColor: "#ffffff",
  darkTemplateColor: "#00000045",
  avatarBgColor: "green",
  blackColor: "black",
  assetTrackingStatusOptions: [{ label: "Active", value: "Active" }, { label: "Paid", value: "Paid" }, { label: "Inactive", value: "Inactive" }],
  tabfieldsStatusTypes: [
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
    { label: "Inactive", value: "Inactive" },
  ],
  formFieldStatusTypes: [
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
    { label: "Inactive", value: "Inactive" },
  ],
  viewTypes: [
    { label: "Default", value: "Default" },
    { label: "Reviewer", value: "Reviewer" },
    { label: "Filer", value: "Filer" },
    { label: "Validation", value: "Validation" },
    { label: "All Validated", value: "All Validated" },
  ],
  dealViews: [
    { label: "All Columns", value: "AllColumns" },
    { label: "Presales", value: "PreSales" },
    { label: "Drafting/Legal", value: "DraftingAndLegal" },
    { label: "PostSale/CoreOps", value: "PostSaleAndCoreOps" },
  ],
  userOtpVerifiedOptions: [
    {
      value: true,
      color: "green",
      tooltip: "Verified",
      displayText: "Verified",
    },
    {
      value: false,
      color: "red",
      tooltip: "Not Verified",
      displayText: "Not Verified",
    },
  ],
  holdReasonOptions: [
    { label: "Co-owners", value: "Co-owners" },
    { label: "Escalate", value: "Escalate" },
    { label: "File Later", value: "File Later" },
    { label: "Seller Input Required", value: "Seller Input Required" },
    { label: "State Issue", value: "State Issue" },
  ],
  taskFilterItems: [
    { label: "Not Started", value: "Not Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Paused", value: "Paused" },
    { label: "Completed", value: "Completed" },
    { label: "Hold", value: "Hold" },
    { label: "Finished", value: "Finished" },
  ],
  biOptions: [
    { label: "Yes", value: "Yes", showError: true },
    { label: "No", value: "No" },
  ],
  DealTypes: [
    { label: "BK", value: "BK" },
    { label: "CR", value: "CR" },
  ],
  shifts: [
    // { label: 'None', value: 'None' },
    { label: "ASAP", value: "ASAP" },
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Bi-Annually", value: "Bi-Annually" },
    { label: "Annually", value: "Annually" },
  ],
  statesUnique: [
    {
      label: "Alabama - AL",
      value: "Alabama - AL",
      show: false,
      shortCode: "AL",
    },
    {
      label: "Alaska - AK",
      value: "Alaska - AK",
      show: false,
      shortCode: "AK",
    },
    {
      label: "Arizona - AZ",
      value: "Arizona - AZ",
      show: false,
      shortCode: "AZ",
    },
    {
      label: "Arkansas - AR",
      value: "Arkansas - AR",
      show: false,
      shortCode: "AR",
    },
    {
      label: "California - CA",
      value: "California - CA",
      show: false,
      shortCode: "CA",
    },
    {
      label: "Colorado - CO",
      value: "Colorado - CO",
      show: false,
      shortCode: "CO",
    },
    {
      label: "Connecticut - CT",
      value: "Connecticut - CT",
      show: false,
      shortCode: "CT",
    },
    {
      label: "Delaware - DE",
      value: "Delaware - DE",
      show: false,
      shortCode: "DE",
    },
    {
      label: "Florida - FL",
      value: "Florida - FL",
      show: false,
      shortCode: "FL",
    },
    {
      label: "Georgia - GA",
      value: "Georgia - GA",
      show: false,
      shortCode: "GA",
    },
    {
      label: "Hawaii - HI",
      value: "Hawaii - HI",
      show: false,
      shortCode: "HI",
    },
    {
      label: "Idaho - ID",
      value: "Idaho - ID",
      show: false,
      shortCode: "ID",
    },
    {
      label: "Illinois - IL",
      value: "Illinois - IL",
      show: false,
      shortCode: "IL",
    },
    {
      label: "Indiana - IN",
      value: "Indiana - IN",
      show: false,
      shortCode: "IN",
    },
    {
      label: "Iowa - IA",
      value: "Iowa - IA",
      show: false,
      shortCode: "IA",
    },
    {
      label: "Kansas - KS",
      value: "Kansas - KS",
      show: false,
      shortCode: "KS",
    },
    {
      label: "Kentucky - KY",
      value: "Kentucky - KY",
      show: false,
      shortCode: "KY",
    },
    {
      label: "Louisiana - LA",
      value: "Louisiana - LA",
      show: false,
      shortCode: "LA",
    },
    {
      label: "Maine - ME",
      value: "Maine - ME",
      show: false,
      shortCode: "ME",
    },
    {
      label: "Maryland - MD",
      value: "Maryland - MD",
      show: false,
      shortCode: "MD",
    },
    {
      label: "Massachusetts - MA",
      value: "Massachusetts - MA",
      show: false,
      shortCode: "MA",
    },
    {
      label: "Michigan - MI",
      value: "Michigan - MI",
      show: false,
      shortCode: "MI",
    },
    {
      label: "Minnesota - MN",
      value: "Minnesota - MN",
      show: false,
      shortCode: "MN",
    },
    {
      label: "Mississippi - MS",
      value: "Mississippi - MS",
      show: false,
      shortCode: "MS",
    },
    {
      label: "Missouri - MO",
      value: "Missouri - MO",
      show: false,
      shortCode: "MO",
    },
    {
      label: "Montana - MT",
      value: "Montana - MT",
      show: false,
      shortCode: "MT",
    },
    {
      label: "Nebraska - NE",
      value: "Nebraska - NE",
      show: false,
      shortCode: "NE",
    },
    {
      label: "Nevada - NV",
      value: "Nevada - NV",
      show: false,
      shortCode: "NV",
    },
    {
      label: "New Hampshire - NH",
      value: "New Hampshire - NH",
      show: false,
      shortCode: "NH",
    },
    {
      label: "New Jersey - NJ",
      value: "New Jersey - NJ",
      show: false,
      shortCode: "NJ",
    },
    {
      label: "New Mexico - NM",
      value: "New Mexico - NM",
      show: false,
      shortCode: "NM",
    },
    {
      label: "New York - NY",
      value: "New York - NY",
      show: false,
      shortCode: "NY",
    },
    {
      label: "North Carolina - NC",
      value: "North Carolina - NC",
      show: false,
      shortCode: "NC",
    },
    {
      label: "North Dakota - ND",
      value: "North Dakota - ND",
      show: false,
      shortCode: "ND",
    },
    {
      label: "Ohio - OH",
      value: "Ohio - OH",
      show: false,
      shortCode: "OH",
    },
    {
      label: "Oklahoma - OK",
      value: "Oklahoma - OK",
      show: false,
      shortCode: "OK",
    },
    {
      label: "Oregon - OR",
      value: "Oregon - OR",
      show: false,
      shortCode: "OR",
    },
    {
      label: "Pennsylvania - PA",
      value: "Pennsylvania - PA",
      show: false,
      shortCode: "PA",
    },
    {
      label: "Puerto Rico - PR",
      value: "Puerto Rico - PR",
      show: false,
      shortCode: "PR",
    },
    {
      label: "Rhode Island - RI",
      value: "Rhode Island - RI",
      show: false,
      shortCode: "RI",
    },
    {
      label: "South Carolina - SC",
      value: "South Carolina - SC",
      show: false,
      shortCode: "SC",
    },
    {
      label: "South Dakota - SD",
      value: "South Dakota - SD",
      show: false,
      shortCode: "SD",
    },
    {
      label: "Tennessee - TN",
      value: "Tennessee - TN",
      show: false,
      shortCode: "TN",
    },
    {
      label: "Texas - TX",
      value: "Texas - TX",
      show: false,
      shortCode: "TX",
    },
    {
      label: "Utah - UT",
      value: "Utah - UT",
      show: false,
      shortCode: "UT",
    },
    {
      label: "Vermont - VT",
      value: "Vermont - VT",
      show: false,
      shortCode: "VT",
    },
    {
      label: "Virginia - VA",
      value: "Virginia - VA",
      show: false,
      shortCode: "VA",
    },
    {
      label: "Washington - DC",
      value: "Washington - DC",
      show: false,
      shortCode: "DC",
    },
    {
      label: "Washington - WA",
      value: "Washington - WA",
      show: false,
      shortCode: "WA",
    },
    {
      label: "West Virginia - WV",
      value: "West Virginia - WV",
      show: false,
      shortCode: "WV",
    },
    {
      label: "Wisconsin - WI",
      value: "Wisconsin - WI",
      show: false,
      shortCode: "WI",
    },
    {
      label: "Wyoming - WY",
      value: "Wyoming - WY",
      show: false,
      shortCode: "WY",
    },
  ],

  highValuePropReport: {
    defaultStatus: "MATCHING-READY FOR MAPPING",
    defaultReason: "CNFRMD ONSITE",
  },
  roleNames: {
    coreOpsManager: "CoreOps Manager",
    coreOpsAnalyst: "CoreOps Analyst",
  },

  autoSelectStatus: "MAPPING-READY FOR REVIEW",
  autoSelectReason: "READY FOR REVIEW",
  claimManagerDefaultRows: 250,
  propertyDetailsDefaultRows: 50,
  successRespCode: 200,
};
export default config;
