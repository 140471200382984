import React from 'react';
// import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import { Container } from 'reactstrap';
import dateFormats from '../../../UI/FormatDate/formatDate';
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class PropertySearchv3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'PropertySearchv3',
      dealTypes: [{ "label": "BK", "value": "BK" }, { "label": "CR", "value": "CR" }],
    };
  }


  componentDidMount = () => {
     }


  getTableFields = () => {
    let data = [
   
      {
        "show": true,
        textAlign: 'left',
        width: 100,
        field: '_id',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Prop. ID',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'statePropertyId',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'St. Prop. ID',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      }, 
       {
        "show": true,
        textAlign: 'left',
        width: 120,
        field: 'dataSourceChannelName',
        fieldType: "custumChangeFieldValues",
        "mobile": true,
        header: 'Data Source Channel',
        "filter": false,
        "displayInSettings": false,
        "displayInFilter": false,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 120,
        field: 'dataSourceName',
        fieldType: "custumChangeFieldValues",
        "mobile": true,
        header: 'Data Source',
        "filter": false,
        "displayInSettings": false,
        "displayInFilter": false,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 120,
        field: 'sourceDetailName',
        fieldType: "custumChangeFieldValues",
        "mobile": true,
        header: 'Source Detail',
        "filter": false,
        "displayInSettings": false,
        "displayInFilter": false,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 90,
        field: 'sourceStateAbbreviation',
        "allowInt": true,
        "mobile": true,
        header: 'Source State',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 90,
        field: 'claimId',
        "allowInt": true,
        "mobile": true,
        header: 'State Claim ID',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      
      {
        "show": true,
        textAlign: 'left',
        width: 160,
        field: 'propertyOwnerName',
        fieldType: "custumChangeFieldValues",
        "mobile": true,
        header: 'Reported Owner',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 160,
        field: 'uniqueCoOwners',
        fieldType: "custumChangeFieldValues",
        "mobile": true,
        header: 'Co-Owners',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 120,
        field: 'propertyStatus',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Prop. Status',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },  
      {
        "show": true,
        textAlign: 'left',
        width: 120,
        field: 'propertyType',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Prop. Type',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 150,
        field: 'propertyAddressLine1',
        fieldType: "custumChangeFieldValues",
        "mobile": true,
        header: 'prop. Address Line1',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 150,
        field: 'propertyAddressLine2',
        fieldType: "custumChangeFieldValues",
        "mobile": true,
        header: 'prop. Address Line2',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'propertyCity',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'prop. City',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'propertyStateAbbreviation',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'prop. State',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'propertyZip',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'prop. Zip',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'propertyCounty',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'prop. County',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'propertyCountry',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'prop. Country',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 160,
      field: 'propertyDescription',     
      "mobile": true,
      header: 'Prop. Description',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'alternateId',
      "allowInt": true,
      "filterType": 'num',
      "mobile": true,
      header: 'Prop. Alternate ID',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
        {
      "show": true,
      textAlign: 'left',
      width: 70,
      field: 'propertyValueMin',
      "allowInt": true,
      fieldType: "formatCurrencywithdec",
      "filterType": 'num',
      "mobile": true,
      header: 'Prop. Value Min',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 70,
      field: 'propertyValueMax',
      "allowInt": true,
      "filterType": 'num',
      fieldType: "formatCurrencywithdec",
      "mobile": true,
      header: 'Prop. Value Max',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'checkNumber',
      "allowInt": true,
      "filterType": 'num',
      "mobile": true,
      header: 'Prop. Check no',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'holderName',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Name',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'holderAddressLine1',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Adrs line1',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'holderAddressLine2',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Adrs line2',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'holderCity',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder City',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'holderState',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder State',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'holderZipCode',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Zip',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 100,
      field: 'holderCounty',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder County',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
      {
      "show": true,
      textAlign: 'left',
      width: 100,
      field: 'holderCountry',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Country',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'holderExtraInfo',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Extra Info',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'holderTaxId',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Tax ID',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'holderReportId',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Report ID',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    }, {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'holderStateOfIncorporation',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder State Incorporation',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 100,
      field: 'holderPhone',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Phone',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'holderEmail',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Holder Email',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'numOfShares',
      "mobile": true,
      header: 'Prop. No. Of Shares',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'escheatedStockName',
      "mobile": true,
      header: 'Prop. Stock Name',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'stockSymbol',
      "mobile": true,
      header: 'Prop. Stock Symbol',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },


    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'estimatedPerShareValue',
      "mobile": true,
      header: 'Prop. Estimate Per Share Value',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'dateWhenShareValueEstimated',
      "mobile": true,
      header: 'Prop. Share Value Estimated Date',
      "dateFormat": "MM/DD/YYYY",
      "fieldType": 'Date',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'totalEstimatedSharesValue',
      "mobile": true,
      header: 'Prop. Estimated Total Shares Value',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 120,
      field: 'stockCUSIP',
      "mobile": true,
      header: 'Prop. Stock CUSIP',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    // {
    //   "show": true,
    //   textAlign: 'left',
    //   width: 120,
    //   field: 'userReportedMissing',
    //   "mobile": true,
    //   header: 'User Reported Missing',
    //   "filter": false,
    //   "displayInSettings": true,
    //   "displayInFilter": true,
    //   sortable: false,
    // },
    
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'confOnSiteDate',
      fieldType: "custumChangeFieldValues",
      fieldDataType:"Date",
      "mobile": true,
      header: 'Conf. Onsite Date',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'confOnSiteByUser',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Conf. Onsite By User',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'confirmedOnSiteFlag',
      "allowInt": true,
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Conf. Onsite',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
        {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'hasPropertyCoOwner',
      "allowInt": true,
      fieldType: "Boolean",
      "mobile": true,
      header: 'Co-owner Flag',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    // {
    //   "show": true,
    //   textAlign: 'left',
    //   width: 150,
    //   field: 'coOwners',
    //   "allowInt": true,
    //   fieldType: "custumChangeFieldValues",
    //   "mobile": true,
    //   header: ' Co-Owner Name',
    //   "filter": false,
    //   "displayInSettings": true,
    //   "displayInFilter": true,
    //   sortable: false,
    // },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'ownerTaxId',
      "allowInt": true, 
      "mobile": true,
      header: 'Owner Tax ID',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'escheatmentDate',
      "allowInt": true,
      "filterType": 'num',
      "mobile": true,
      header: 'Date Escheated',
      "dateFormat": "MM/DD/YYYY",
      "fieldType": 'Date',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'dateOfLastContact',
      "allowInt": true,
      "filterType": 'num',
      "mobile": true,
      header: 'Date of Last Contact',
      "filter": false,
      "dateFormat": "MM/DD/YYYY",
      "fieldType": 'Date',
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'datePropertyIndexedInElasticUTC',
      "allowInt": true,
      "filterType": 'num',
      "mobile": true,
      header: 'Prop. Indexed in Elastic',
      "filter": false,
      "dateFormat": "MM/DD/YYYY",
      "fieldType": 'Date',
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'createdByUser',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Created By User',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'lastUpdatedByUser',
      fieldType: "custumChangeFieldValues",
      "mobile": true,
      header: 'Updated By User',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
   
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'datePropertyCreatedUTC',
      "allowInt": true,
      "filterType": 'num',
      "mobile": true,
      "dateFormat": "MM/DD/YYYY",
      "fieldType": 'Date',
      header: 'Prop.Created Date',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 90,
      field: 'datePropertyUpdatedUTC',
      "allowInt": true,
      "filterType": 'num',
      "mobile": true,
      "dateFormat": "MM/DD/YYYY",
      "fieldType": 'Date',
      header: 'Prop.Updated Date',
      "filter": false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    },
 
  

    
    ]
    return data;
  };

  custumChangeFieldValues = (item, column, permissions) => {

    if (column.field == "dataSourceChannelName" && item["dataSourceChannelSet"].length > 0 &&
      item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["dataSourceChannelName"]) {
      return <span>
        {item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["dataSourceChannelName"]}
      </span>
    } else if (column.field == "dataSourceName" && item["dataSourceChannelSet"].length > 0 &&
      item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["dataSourceName"]) {
      return <span>
        {item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["dataSourceName"]}
      </span>
    } else if (column.field == "sourceDetailName" && item["dataSourceChannelSet"].length > 0 &&
      item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["sourceDetailName"]) {
      return <span>
        {item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["sourceDetailName"]}
      </span>
    } else if (column.field == "coOwners" && item["dataSourceChannelSet"].length > 0 &&
      item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["coOwners"]) {
      return <span>
        {item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["coOwners"]}
      </span>
    } else if (column.field == "propertyOwnerName" && item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"] &&
      item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"]["name"]) {
      return <span>
        {item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"]["name"]}
      </span>
    } else if (column.field == "propertyAddressLine1" && item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine1"] &&
      item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine1"] != null && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine1"] != "") {
      return <span>
        {item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine1"]}
      </span>
    } else if (column.field == "propertyAddressLine2" && item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine2"] &&
      item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine2"] != null && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine2"] != "") {
      return <span>
        {item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine2"]}
      </span>
    } else if (column.field == "propertyCity" && item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
      item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["city"]) {
      return <span>
        {item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["city"]}
      </span>
    } else if (column.field == "propertyStateAbbreviation" && item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
      item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["stateAbbreviation"]) {
      return <span>
        {item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["stateAbbreviation"]}
      </span>
    } else if (column.field == "propertyZip" && item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
      item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["zipcode"]) {
      return <span>
        {item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["zipcode"]}
      </span>
    } else if (column.field == "propertyCounty" && item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
      item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["county"]) {
      return <span>
        {item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["county"]}
      </span>
    } else if (column.field == "propertyCountry" && item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
      item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["country"]) {
      return <span>
        {item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["country"]}
      </span>
    } else if (column.field == "uniqueCoOwners" && item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"] &&
      item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"]["name"]) {     
      let coOwners = []; 
      let propertyOwnershipSet =  item["propertyOwnershipSet"];
      for( let i in propertyOwnershipSet){
        if(propertyOwnershipSet[i]["propertyOwnerName"] && propertyOwnershipSet[i]["propertyOwnerName"]["name"] && propertyOwnershipSet[i]["propertyOwnerName"]["name"] !=  item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"]["name"]){
          coOwners.push(propertyOwnershipSet[i]["propertyOwnerName"]["name"]);
        }
      }
      coOwners = [...new Set(coOwners)];
      return <span data-toggle="tool-tip"
      title={coOwners.join(" | ")}>
      {coOwners.join(" | ")}
    </span>
    }else if (column.field == "holderName" && item["holder"] && item["holder"]["holderName"]) {
      return <span>
        {item["holder"]["holderName"]}
      </span>
    } else if (column.field == "holderAddressLine1" && item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["addressLine1"]) {
      return <span>
        {item["holder"]["holderAddress"]["addressLine1"]}
      </span>
    } else if (column.field == "holderAddressLine2" && item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["addressLine2"]) {
      return <span>
        {item["holder"]["holderAddress"]["addressLine2"]}
      </span>
    } else if (column.field == "holderCity" && item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["city"]) {
      return <span>
        {item["holder"]["holderAddress"]["city"]}
      </span>
    } else if (column.field == "holderState" && item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["stateAbbreviation"]) {
      return <span>
        {item["holder"]["holderAddress"]["stateAbbreviation"]}
      </span>
    } else if (column.field == "holderZipCode" && item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["zipcode"]) {
      return <span>
        {item["holder"]["holderAddress"]["zipcode"]}
      </span>
    } else if (column.field == "holderCounty" && item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["county"]) {
      return <span>
        {item["holder"]["holderAddress"]["county"]}
      </span>
    } else if (column.field == "holderCountry" && item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["country"]) {
      return <span>
        {item["holder"]["holderAddress"]["country"]}
      </span>
    } else if (column.field == "holderExtraInfo" && item["holder"] && item["holder"]["holderExtraInfo"]) {
      return <span>
        {item["holder"]["holderExtraInfo"]}
      </span>
    } else if (column.field == "holderTaxId" && item["holder"] && item["holder"]["holderTaxId"]) {
      return <span>
        {item["holder"]["holderTaxId"]}
      </span>
    } else if (column.field == "holderReportId" && item["holder"] && item["holder"]["holderReportId"]) {
      return <span>
        {item["holder"]["holderReportId"]}
      </span>
    } else if (column.field == "holderStateOfIncorporation" && item["holder"] && item["holder"]["holderStateOfIncorporation"]) {
      return <span>
        {item["holder"]["holderStateOfIncorporation"]}
      </span>
    } else if (column.field == "holderPhone" && item["holder"] && item["holder"]["holderPhone"]) {
      return <span>
        {item["holder"]["holderPhone"]}
      </span>
    } else if (column.field == "holderEmail" && item["holder"] && item["holder"]["holderEmail"]) {
      return <span>
        {item["holder"]["holderEmail"]}
      </span>
    } else if (column.field == "createdByUser" && item["createdByUser"] && item["createdByUser"]["userName"]) {
      return <span>
        {item["createdByUser"]["userName"]}
      </span>
    } else if (column.field == "lastUpdatedByUser" && item["lastUpdatedByUser"] && item["lastUpdatedByUser"]["userName"]) {
      return <span>
        {item["lastUpdatedByUser"]["userName"]}
      </span>
    }
    else if (column.field == "confOnSiteByUser" && item["propertyConfirmation"] && item["propertyConfirmation"]["userConfirmed"] && item["propertyConfirmation"]["userConfirmed"]["userName"]) {
      return <span>
        {item["propertyConfirmation"]["userConfirmed"]["userName"]}
      </span>
    } else if (column.field == "confOnSiteDate" && item["propertyConfirmation"] && item["propertyConfirmation"]["confirmedOnSiteDateUTC"]) {
      let val = dateFormats.formatDate(item["propertyConfirmation"]["confirmedOnSiteDateUTC"], "MM/DD/YYYY");
      return <span>
        {val}
      </span>
    } else if (column.field == "confirmedOnSiteFlag" && item["propertyConfirmation"] && item["propertyConfirmation"]["confirmedOnSiteFlag"]) {

      return <span>
        {item["propertyConfirmation"]["confirmedOnSiteFlag"] == true ? "True" : "False"}
      </span >
    }

  }
  getDataAfterCustomizations = (modifiedData, response) => {
    const updatedArray = modifiedData.map(item => {
      if (item["dataSourceChannelSet"].length > 0 &&
        item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["dataSourceChannelName"]) {
        item["dataSourceChannelName"] = item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["dataSourceChannelName"];

      } if (item["dataSourceChannelSet"].length > 0 &&
        item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["dataSourceName"]) {

        item["dataSourceName"] = item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["dataSourceName"];

      } if (item["dataSourceChannelSet"].length > 0 &&
        item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["sourceDetailName"]) {

        item["sourceDetailName"] = item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["sourceDetailName"];

      } if (item["dataSourceChannelSet"].length > 0 &&
        item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["coOwners"]) {

        item["coOwners"] = item["dataSourceChannelSet"][item["dataSourceChannelSet"].length - 1]["coOwners"];

      } if (item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"] &&
        item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"]["name"]) {     
        let coOwners = []; 
        let propertyOwnershipSet =  item["propertyOwnershipSet"];
        for( let i in propertyOwnershipSet){
          if(propertyOwnershipSet[i]["propertyOwnerName"] && propertyOwnershipSet[i]["propertyOwnerName"]["name"] && propertyOwnershipSet[i]["propertyOwnerName"]["name"] !=  item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"]["name"]){
            coOwners.push(propertyOwnershipSet[i]["propertyOwnerName"]["name"]);
          }
        }
        coOwners = [...new Set(coOwners)];
        item["uniqueCoOwners"]= coOwners.join(" | ");
      }
       if (item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"] &&
        item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"]["name"]) {

        item["propertyOwnerName"] = item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerName"]["name"];

      } if (item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine1"] &&
        item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine1"] != null && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine1"] != "") {

        item["propertyAddressLine1"] = item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine1"];

      } if (item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine2"] &&
        item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine2"] != null && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine2"] != "") {

        item["propertyAddressLine2"] = item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["addressLine2"];

      } if (item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
        item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["city"]) {

        item["propertyCity"] = item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["city"];

      } if (item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
        item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["stateAbbreviation"]) {

        item["propertyStateAbbreviation"] = item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["stateAbbreviation"];

      } if (item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
        item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["zipcode"]) {

        item["propertyZip"] = item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["zipcode"];

      } if (item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
        item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["county"]) {

        item["propertyCounty"] = item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["county"];

      } if (item["propertyOwnershipSet"].length > 0 && item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"] &&
        item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["country"]) {

        item["propertyCountry"] = item["propertyOwnershipSet"][item["propertyOwnershipSet"].length - 1]["propertyOwnerAddress"]["country"];

      } if (item["holder"] && item["holder"]["holderName"]) {

        item["holderName"] = item["holder"]["holderName"];

      } if (item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["addressLine1"]) {

        item["holderAddressLine1"] = item["holder"]["holderAddress"]["addressLine1"];

      } if (item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["addressLine2"]) {

        item["holderAddressLine2"] = item["holder"]["holderAddress"]["addressLine2"];

      } if (item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["city"]) {

        item["holderCity"] = item["holder"]["holderAddress"]["city"];

      } if (item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["stateAbbreviation"]) {

        item["holderState"] = item["holder"]["holderAddress"]["stateAbbreviation"];

      } if (item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["zipcode"]) {

        item["holderZipCode"] = item["holder"]["holderAddress"]["zipcode"];

      } if (item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["county"]) {

        item["holderCounty"] = item["holder"]["holderAddress"]["county"];

      } if (item["holder"] && item["holder"]["holderAddress"] && item["holder"]["holderAddress"]["country"]) {

        item["holderCountry"] = item["holder"]["holderAddress"]["country"];

      } if (item["holder"] && item["holder"]["holderExtraInfo"]) {

        item["holderExtraInfo"] = item["holder"]["holderExtraInfo"]

      } if (item["holder"] && item["holder"]["holderTaxId"]) {

        item["holderTaxId"] = item["holder"]["holderTaxId"];

      } if (item["holder"] && item["holder"]["holderReportId"]) {

        item["holderReportId"] = item["holder"]["holderReportId"];

      } if (item["holder"] && item["holder"]["holderStateOfIncorporation"]) {

        item["holderStateOfIncorporation"] = item["holder"]["holderStateOfIncorporation"];

      } if (item["holder"] && item["holder"]["holderPhone"]) {

        item["holderPhone"] = item["holder"]["holderPhone"];

      } if (item["holder"] && item["holder"]["holderEmail"]) {

        item["holderEmail"] = item["holder"]["holderEmail"];

      } if (item["createdByUser"] && item["createdByUser"]["userName"]) {

        item["createdByUser"] = item["createdByUser"]["userName"];

      } if (item["lastUpdatedByUser"] && item["lastUpdatedByUser"]["userName"]) {

        item["lastUpdatedByUser"] = item["lastUpdatedByUser"]["userName"];

      }
      if (item["propertyConfirmation"] && item["propertyConfirmation"]["userConfirmed"] && item["propertyConfirmation"]["userConfirmed"]["userName"]) {

        item["confOnSiteByUser"] = item["propertyConfirmation"]["userConfirmed"]["userName"];

      } if (item["propertyConfirmation"] && item["propertyConfirmation"]["confirmedOnSiteDateUTC"]) {

        item["confOnSiteDate"] = dateFormats.formatDate(item["propertyConfirmation"]["confirmedOnSiteDateUTC"], "MM/DD/YYYY");

      } if (item["propertyConfirmation"] && item["propertyConfirmation"]["confirmedOnSiteFlag"]) {
        item["confirmedOnSiteFlag"] = item["propertyConfirmation"]["confirmedOnSiteFlag"] == true ? "True" : "False";

      }
      return item; // Return unchanged object if no match is found
    });
    return updatedArray
  }
  getFormFields = () => {
    return ([]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <Container>
        <span>
          <Loader loader={this.state.isLoading} />
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            Limit={1000}
            exportRequried={false}
            excelDownload={false}
            printRequried={false}
            frozen={true}
            custumChangeFieldValues ={this.custumChangeFieldValues}
            getDataAfterCustomizations = {this.getDataAfterCustomizations}
            sortField={"holderName"}
            criteria={this.props.criteria}
            addRequried={false}
            editRequired={false}
            deleteRequired={false}
            viewRequired={true}
            dontShowTitle={true}
            settingsRequired={false}
            filterRequired={true}
            gridRequried={false}
            sample={false}
            isClearFilters={false}
            expandTableFields={this.expandTableFields}
            screenPermissionsRoute={"Raw Property Search"}
            type='PropertySearchv3'
            tabType={true}
            displayright={true}
            routeTo='PropertySearchv3'
            displayViewOfForm='modal'
            apiResponseKey='propertySearches'
            apiUrl={apiCalls.SearchV3}
            entityType='property search'
          />
       </span >
      </Container>
    );
  }
}