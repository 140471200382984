import React, {useState,useEffect,Dispatch, SetStateAction  } from 'react';
import { StaticGrid } from '../../../../shared/StaticGrid';
import fetchMethodRequest from '../../../../config/service';
import { Button } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox';
import * as documentsAPIs from '../../../../shared/documentsApis';
import dateFormats from '../../../UI/FormatDate/formatDate';
import { Dialog } from 'primereact/dialog';
import AddressEvidSort from './AddressEvidSort';

interface props {
    documents:Array<any>,
    getDocumentsData:Function,
    showModal:any,
    closeModal:Function,
    loadedDocuments:any,
    onSelectUnselectBypassDoc:Function,
    selectionData:Array<any>,
    isSectionLoaded:boolean,
    onClickAddNote:Function,
    onClickNotes:Function,
    onClickLoadAddrEvid:Function,
    disableLoadButton:boolean,
    updateSelectedFiles:Function
    selectedFilesNumbers:Array<any>    
}


export const AddressEvid = ({ documents, getDocumentsData, showModal, closeModal, loadedDocuments, onSelectUnselectBypassDoc, selectionData, isSectionLoaded, onClickAddNote, onClickNotes, onClickLoadAddrEvid, disableLoadButton, updateSelectedFiles, selectedFilesNumbers }: props) => {
    const [disableButton, setDisableButton] = useState<boolean>(true); 
    const [disableSelection, setDisableSelection] = useState<boolean>(false);
    const [disableByPass, setDisableByPass] = useState(false); 
    const [selectedDocs, setSelectedDocs] = useState<Array<any>>([]); 
    const [showSortModal, setShowSortModal] = useState<boolean>(false) 

    

    useEffect(() => {
      if(selectionData && selectionData[0] ){
        setDisableSelection(selectionData[0].isBypassed);
      }
    
      return () => {
        setDisableSelection(false);
      }
    }, [selectionData]);

    useEffect(() => {       
        if (selectedFilesNumbers && selectedFilesNumbers.length > 0 && selectedDocs.length === 0 && selectionData && selectionData[0].files && selectionData[0].files.length > 0) {            
            const selFiles:Array<any> = selectionData[0].files.filter((row:any) => selectedFilesNumbers.includes(row.documentNumber) );            
            const uniqueDocs:Array<any> = [];
            selFiles.forEach((doc:any) => {
                let docIndex = uniqueDocs.findIndex((row: any) => row.documentNumber === doc.documentNumber);
                if (docIndex === -1) {
                    uniqueDocs.push({ ...doc, addresses: [{bsAddress:doc.bsAddress ? doc.bsAddress : '',bsCity:doc.bsCity ? doc.bsCity : '',bsState:doc.bsState ? doc.bsState : ''}] });
                } else {
                    uniqueDocs[docIndex].addresses.push({bsAddress:doc.bsAddress ? doc.bsAddress : '',bsCity:doc.bsCity ? doc.bsCity : '',bsState:doc.bsState ? doc.bsState : ''});
                }
            });
            let sortedUniqueDocs:Array<any> = [];
            selectedFilesNumbers.forEach((docNumber:any) => {
                let docIndex = uniqueDocs.findIndex((row: any) => row.documentNumber === docNumber);
                if (docIndex !== -1) {
                    sortedUniqueDocs.push(uniqueDocs[docIndex]);
                }
            });
            setSelectedDocs(sortedUniqueDocs);
        } 
    }, [selectedFilesNumbers]);
    
    

    /**
     * Redirecting to Pdf editor on click file name.
     * @param id {*} 
     */
    const redirectToPDFEditor = (id:any) => {
        fetchMethodRequest('GET', `getDocumentById?Id=${id}`,'','','','').then(async (res) => {
            let data = res && res.respCode && res.outResponse && res.outResponse.details ? res.outResponse.details : null;
            if (data) {                
                localStorage.setItem('pdfDocDetails',JSON.stringify(data));
                let url = `/pdfEditor?details=loadDoc`;
                window.open(url, '_blank', 'noopener=true');
            }            
        })
    }

    const fileNameBody = (row:any) => {
        return <><a onClick={() => redirectToPDFEditor(row._id)} className='text-info' style={{ cursor: 'pointer' }}>{row.fileName}</a>
            {loadedDocuments[row.documentNumber] && <div>(Document already loaded for {loadedDocuments[row.documentNumber].docType})</div>}</>
    }

    // Table columns of address evidence
    const columns = [
        {
            field: 'documentTypeName',
            header: 'Document Type',
            filter: false,
            filterPlaceHolder: '',
            sortable: false,
            style:{width:'13%',textAlign:'left',padding:'revert-layer'}
        },
        {
            field: 'bsAddress',
            header: 'Address',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: true,
            style:{width:'15%',textAlign:'left',padding:'revert-layer'}
        },
        {
            field: 'bsCity',
            header: 'City',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: true,
            style:{width:'7%',textAlign:'left',padding:'revert-layer'}
        },
        {
            field: 'bsState',
            header: 'State',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: true,
            style:{width:'5%',padding:'revert-layer'}
        },
        {
            field: 'fileName',
            header: 'File Name',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: false,
            style:{width:'57%',textAlign:'left',padding:'revert-layer'},
            formatter:fileNameBody
        }

    ]

    
    const setSelectedFilesInParent = (selecFiles:Array<any>) => {
        const selFiles:Array<any> = documents.filter((row:any) => selecFiles[row.documentNumber] );  
        const uniqueDocs:Array<any> = [];
        selFiles.forEach((doc:any) => {
            let docIndex = uniqueDocs.findIndex((row: any) => row.documentNumber === doc.documentNumber);
            if (docIndex === -1) {
                uniqueDocs.push({ ...doc, addresses: [{bsAddress:doc.bsAddress ? doc.bsAddress : '',bsCity:doc.bsCity ? doc.bsCity : '',bsState:doc.bsState ? doc.bsState : ''}] });
            } else {
                uniqueDocs[docIndex].addresses.push({bsAddress:doc.bsAddress ? doc.bsAddress : '',bsCity:doc.bsCity ? doc.bsCity : '',bsState:doc.bsState ? doc.bsState : ''});
            }
        });        
        setSelectedDocs(uniqueDocs);        
        updateSelectedFilesInParent(selFiles,false);
    }
     
    const updateSelectedFilesInParent = (selFiles:Array<any>,loadFiles:boolean) => {
        if(selFiles.length > 0){
            setDisableByPass(true); 
            setDisableButton(false);           
        }else{
            setDisableByPass(false); 
            setDisableButton(true); 
        }
        if(updateSelectedFiles){                     
            updateSelectedFiles(selFiles ? selFiles : []);                       
        }        
        if (loadFiles) {           
            onClickLoadAddrEvid(selFiles);
            setShowSortModal(false); 
        }
       
    }
    
    return (      
        <>{ showModal &&
            <StaticGrid
                data={documents}
                columns={columns}
                pagination={true}
                rows={10}
                removableSort={true}
                sortMode='multiple'
                rowsPerPageOption={[10,15,20,30, 50, 100,250]}
                emptyMessage='No Documents Found'
                getDocumentsData={getDocumentsData}
                closeModal={closeModal}
                loadedDocuments={loadedDocuments}
                selectedFilesNumbers={selectedFilesNumbers}
                disableLoadButton={disableLoadButton}
                disableEnableLoadButton= {(value:boolean) => {setDisableButton(value)}} 
                setSelectedFilesInParent={setSelectedFilesInParent}  
                disableSelection={disableSelection}             
            />}
            <table className="table table-bordered">
                
                <tbody>
                    <td className='text-center' style={{ width: '50%', verticalAlign: 'middle' }}>
                        {selectionData && selectionData[0] && !selectionData[0].isMandatory && selectionData[0].value && selectionData[0].value[0] === 'M' && <>
                            <Checkbox onChange={() => { onSelectUnselectBypassDoc(documentsAPIs.ADDRESS_EVIDENCE, 0); setDisableButton(!disableButton) }} checked={selectionData && selectionData[0] && selectionData[0].isBypassed} disabled={disableByPass || (selectionData && selectionData[0] && selectionData[0].isMandatory)} /> By Pass ADDR Evid
                        </>}
                    </td>
                    <td style={{ width: '50%',verticalAlign:'middle' }}>
                        {(selectionData && selectionData[0] && selectionData[0].notes && selectionData[0].notes.length > 0)  ?
                            <div onClick={() => {onClickNotes(documentsAPIs.ADDRESS_EVIDENCE,selectionData[0].notes) }}>
                                <div className="font-weight-bold">{selectionData[0].notes[selectionData[0].notes.length - 1].noteAddedOnDate ? dateFormats.formatDate(selectionData[0].notes[selectionData[0].notes.length - 1].noteAddedOnDate, "MM/DD/YYYY", "nhg") : `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`}:{selectionData[0].notes[selectionData[0].notes.length - 1].noteAddedBy}</div>
                                <span >{selectionData[0].notes[selectionData[0].notes.length - 1].notes}</span>
                            </div>
                            :
                            <Button color='primary' className='mb-0' outline onClick={() => {onClickAddNote(documentsAPIs.ADDRESS_EVIDENCE,false)}}>Add Notes</Button>
                        }
                    </td>
                </tbody>
            </table>
            <div className="pt-3 text-center">
                <Button color='primary' outline disabled={!isSectionLoaded && disableButton} onClick={() => setShowSortModal(true)}>Add and Sort ({selectedDocs.length})</Button>
                <Button color='primary' outline disabled={!isSectionLoaded && disableButton} onClick={() => onClickLoadAddrEvid()}>Load</Button>
                <Button color='primary' outline onClick={closeModal}> Close</Button>
            </div>  

            <Dialog  visible={showSortModal} style={{width: '95vw' }} onHide={() => setShowSortModal(false)} header="Sort Documents">
               {showSortModal && <AddressEvidSort
                    selectedDocs={selectedDocs}
                    showSortModal={showSortModal}
                    updateSelectedFilesInParent={updateSelectedFilesInParent}
                />}
            </Dialog>        
            
        </>
        
    )
}