
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    Button, Card, CardBody, ModalBody, CardFooter,
    TabContent, TabPane, Nav, NavItem, NavLink, Row, Table
} from 'reactstrap';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import CurrencyInput from 'react-currency-input-field';
import { connect } from 'react-redux';
import { Checkbox } from 'primereact/checkbox'
import { CSVLink } from "react-csv";
import { InputText } from 'primereact/inputtext'
import { AutoComplete } from 'primereact/autocomplete'
import fetchMethodRequest from '../../../config/service';
import ConfirmationModal from './ConfirmationModal';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ExcelReact from './../CommonDataTable/ExcelReact'
import ExpiredSessionModal from '../CommonModals/ExpiredSessionModal';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';

import { MultiSelect } from 'primereact/multiselect';
import DatePicker from '../../../shared/components/form/DatePicker'
// fecth method from service.js file
import fetch from '../../../config/service';
import configMessage from '../../../config/configMessages';
import configImages from '../../../config/configImages';
// show message 
import validate from '../../Form/validate';
import Loader from '../../App/Loader';
import { withTranslation } from 'react-i18next';
import { faArrowCircleLeft, faUsers, faTimes, faSave, faSync, faCross, faStarOfLife, faToggleOn, faClone, faArrowRight, faProjectDiagram, faPlus, faLink, faQuestion, faCopy, faFile, faArrowLeft, faUndo, faRedo, faCaretSquareDown, faCaretSquareUp, faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultInput from '../../../shared/components/form/DefaultInput';

import DefaultTextArea from '../../../shared/components/form/DefaultTextArea';
import PaginatorComponent from '../../Cruds/CommonDataTable/PaginatorComponent';


import Select from '../../../shared/components/form/Select';
import CheckBox from '../../../shared/components/form/CheckBox';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import dateFormats from '../../UI/FormatDate/formatDate';

import SessionWarningModal from './SessionWarningModal'
import RulesModal from './RulesModal';
import BulkUploadModal from './BulkUploadModal'
import AddressModal from './AddressModel'
import SellerSignatoryModal from './SellerSignatoryModal'
import renderRadioButtonField from '../../../shared/components/form/RadioButton'
import SaveOrder from './SavePreset'
import ShowPreset from './ShowPreset'
import { createTheme } from "@material-ui/core/styles";
//config
import apiCalls from '../../../config/apiCalls';
import configMessages from '../../../config/configMessages';
import config from '../../../config/config';
import { Dropdown } from 'primereact/dropdown';
import { async } from '@firebase/util';
import DeleteRowModal from './DeleteRowModal';
import { faCheckCircle, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
let timeout


const bodyStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 150,
    fontSize: 12,
    textAlign: "left",
    verticalAlign: "inherit"
}
const bodyStyleRight = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 150,
    fontSize: 12,
    textAlign: "right",
    verticalAlign: "inherit"
}


const headerStyle = {
    width: 150,
    textAlign: "center",
}
// import DefaultInput from 'shared/components/form/DefaultInput';


const required = value => (

    value || typeof value === 'string' ? undefined : configMessage.fillField

)

const theme = createTheme({
    overrides: {
        MuiSwitch: {
            thumb: {
                height: "17px!important",
                width: 17
            },
            track: {
                left: "51%!important"
            },
            root: {
                "&$checked": {
                    color: "white!important",
                    "& + $track": {
                        backgroundColor: "green !important",
                        color: "white!important",
                        height: 15,
                        left: "46%!important"
                    }
                }
            }
        },
    },
});
//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class SellerModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isDisableSubmitButton: false,
            isLoginSuccess: false,
            isLoading: false,
            colored: false,
            selectedDealID: "",
            header: true,
            stayInAdd: false,
            namesArray: "",
            textAreaValue: true,
            editedRowNumber: [],
            redoArray: [],
            undoArray: [],
            disableRedoButton: true,
            disableUndoButton: true,
            history: [],
            editedFieldoptions: null,
            isDownload: false,
            editedRowIndex: "",
            isExpanded: false,
            openDeleteModal: false,
            deleArray: [],
            nameTypeArray: "",
            keyNameChecked: false,
            editedField: "",
            isNotSuccess: false,
            keyNameCheckedState: false,
            rowData: {},
            sheets: [],
            isRedirecttoEdit: false,
            dataChangeOptionswithFilter: [
                { "label": "Unsort", "value": 'unsort' },
                { "label": "Sort by ASC", "value": 'asc' },
                { "label": "Sort by DSC", "value": 'desc' },
                { "label": "Filter", "value": 'filter' },
                { "label": "Clear Filter", "value": 'clear_filter' },

                // { "label": "Hide Column", "value": 'hide' },
                // { "label": "Show Column", "value": 'show' },
            ],
            loc: [
                { label: "Alabama - AL", value: "Alabama - AL", show: false },
                { label: "Alaska - AK", value: "Alaska - AK", show: false },
                { label: "Arizona - AZ", value: "Arizona - AZ", show: false },
                { label: "Arkansas - AR", value: "Arkansas - AR", show: false },
                { label: "California - CA", value: "California - CA", show: false },
                { label: "Colorado - CO", value: "Colorado - CO", show: false },
                { label: "Connecticut - CT", value: "Connecticut - CT", show: false },
                { label: "Delaware - DE", value: "Delaware - DE", show: false },
                { label: "Florida - FL", value: "Florida - FL", show: false },
                { label: "Georgia - GA", value: "Georgia - GA", show: false },
                { label: "Hawaii - HI", value: "Hawaii - HI", show: false },
                { label: "Idaho - ID", value: "Idaho - ID", show: false },
                { label: "Illinois - IL", value: "Illinois - IL", show: false },
                { label: "Indiana - IN", value: "Indiana - IN", show: false },
                { label: "Iowa - IA", value: "Iowa - IA", show: false },
                { label: "Kansas - KS", value: "Kansas - KS", show: false },
                { label: "Kentucky - KY", value: "Kentucky - KY", show: false },
                { label: "Louisiana - LA", value: "Louisiana - LA", show: false },
                { label: "Maine - ME", value: "Maine - ME", show: false },
                { label: "Maryland - MD", value: "Maryland - MD", show: false },
                { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false },
                { label: "Michigan - MI", value: "Michigan - MI", show: false },
                { label: "Minnesota - MN", value: "Minnesota - MN", show: false },
                { label: "Mississippi - MS", value: "Mississippi - MS", show: false },
                { label: "Missouri - MO", value: "Missouri - MO", show: false },
                { label: "Montana - MT", value: "Montana - MT", show: false },
                { label: "Nebraska - NE", value: "Nebraska - NE", show: false },
                { label: "Nevada - NV", value: "Nevada - NV", show: false },
                { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false },
                { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false },
                { label: "New Mexico - NM", value: "New Mexico - NM", show: false },
                { label: "New York - NY", value: "New York - NY", show: false },
                { label: "North Carolina - NC", value: "North Carolina - NC", show: false },
                { label: "North Dakota - ND", value: "North Dakota - ND", show: false },
                { label: "Ohio - OH", value: "Ohio - OH", show: false },
                { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false },
                { label: "Oregon - OR", value: "Oregon - OR", show: false },
                { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false },
                { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false },
                { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false },
                { label: "South Carolina - SC", value: "South Carolina - SC", show: false },
                { label: "South Dakota - SD", value: "South Dakota - SD", show: false },
                { label: "Tennessee - TN", value: "Tennessee - TN", show: false },
                { label: "Texas - TX", value: "Texas - TX", show: false },
                { label: "Utah - UT", value: "Utah - UT", show: false },
                { label: "Vermont - VT", value: "Vermont - VT", show: false },
                { label: "Virginia - VA", value: "Virginia - VA", show: false },
                { label: "Washington - DC", value: "Washington - DC", show: false },
                { label: "Washington - WA", value: "Washington - WA", show: false },
                { label: "West Virginia - WV", value: "West Virginia - WV", show: false },
                { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false },
                { label: "Wyoming - WY", value: "Wyoming - WY", show: false },
            ],

            strArray: [],
            sellerViewType: false,
            selectedRows: '',
            subCatg: [],
            progress: 0,
            first: 0,
            dataSourceId: null,
            rows: 20,
            issueName: [
                { label: "No specific guidelines yet - 0", value: "0" },
                { label: "Date guidelines - 1.1", value: "1.1" },
                { label: "Geographic guidelines - 1.2", value: "1.2" },
                { label: "Other guidelines - 1.3", value: "1.3" },
                { label: "Research needed - 1.4", value: "1.4" },
                { label: "Code 7 Contingent - 1.7", value: "1.7" },
                { label: "Do not search or pursue - 2", value: "2" },
                { label: "Company employee - 3", value: "3" },
                { label: "CR OPP APA Modification - 7.1", value: "7.1" },
                { label: "Legal review - 7.2", value: "7.2" },
                { label: "Legal &/or Seller Consult - Signatory - 7.3", value: "7.3" },
                { label: "Legal &/or Seller Consult - Entity - 7.4", value: "7.4" },
                { label: "Legal &/or Seller Consult - JV - 7.5", value: "7.5" },
                { label: "Legal &/or Seller Consult - Foreign - 7.6", value: "7.6" },
                { label: "Blank", value: null }
            ],
            // issueName: [{ label: "No Issues Identified - 0", value: "0" }, { label: "Issue Identified - 1", value: "1" }, { label: "Unable to Pursue - 2", value: "2" }, { label: "Escalate if Assets - 7", value: "7" }, { label: "Blank", value: null }],
            Apa: [{ "label": "10-Full Remnant,subject to Exclusions", "value": '10' },
            { "label": "14-Full Remnant,includes RCS,subject to Exclusions", "value": '14' },
            { "label": "20-Limited scope,see Inclusions,subject to Exclusions", "value": '20' },
            { "label": "24-Limited scope,includes RCS,see Inclusions", "value": '24' },
            { "label": "30-UP only", "value": '30' },
            { "label": "40-RCS only", "value": '40' },
            { "label": "50-Judgment only", "value": '50' },
            { "label": "60-Other,limited to named inclusion(s)", "value": '60' },
            { "label": "64-Other,limited to named inclusion(s) with RCS", "value": '64' },
            { "label": "70-Full Remnant,Unable to pursue recoveries", "value": '70' },
            { "label": "75-Full Remnant,PWC portfolio restriction", "value": '75' },
            { "label": "80-Corporate Remnants", "value": '80' },
            { "label": "82-Corporate Remnants,limited scope", "value": '82' },
            { "label": "85-Corporate Remnants,NDA", "value": '85' },
            { "label": "87-Corporate Remnants,Sold", "value": '87' },
            ],


            bkAPA: [{ "label": "10-Full Remnant,subject to Exclusions", "value": '10' },
            { "label": "14-Full Remnant,includes RCS,subject to Exclusions", "value": '14' },
            { "label": "20-Limited scope,see Inclusions,subject to Exclusions", "value": '20' },
            { "label": "24-Limited scope,includes RCS,see Inclusions", "value": '24' },
            { "label": "30-UP only", "value": '30' },
            { "label": "40-RCS only", "value": '40' },
            { "label": "50-Judgment only", "value": '50' },
            { "label": "60-Other,limited to named inclusion(s)", "value": '60' },
            { "label": "64-Other,limited to named inclusion(s) with RCS", "value": '64' },
            { "label": "70-Full Remnant,Unable to pursue recoveries", "value": '70' },
            { "label": "75-Full Remnant,PWC portfolio restriction", "value": '75' },],


            crAPA: [{ "label": "80-Corporate Remnants", "value": '80' },
            { "label": "82-Corporate Remnants,limited scope", "value": '82' },
            { "label": "85-Corporate Remnants,NDA", "value": '85' },
            { "label": "87-Corporate Remnants,Sold", "value": '87' }],

            isOpenFormModal: false,
            isOpenAddressModal: false,
            hideSplitBtn: false,
            showCaseClosedDate: false,
            APAAddendumDate1: false,
            CaseTransfer1: false,
            CaseConversion1: false,
            setExhibitType: "",
            timer: false,
            formType: "",
            copiedA: false,
            subcategory: { "Sourcing": [{ label: "Valuation", value: "Valuation" }], "Research": [{ label: "Corporate Tree", value: "Corporate Tree" }, { label: "Entity Details", value: "Entity Details" }, { label: "Property Details", value: "Property Details" }], "Legal": [{ label: "APA", value: "APA" }, { label: "Document", value: "Document" }, { label: "Information", value: "Information" }], "Transition": [{ label: "Entity", value: "Entity" }], "Recovery": [{ label: "General Note", value: "General Note" }, { label: "State", value: "State" }] },
            copiedB: false,
            category: [],
            subCategory: [],
            enteredBy: [],
            EntityName: null,
            SellerNickName: null,
            AzureStorageDirectory: null,
            OpraId: null,
            LeadSeller: null,
            LeadCaseNumber: null,
            filterSelect: "",
            CategoryFilter: false,
            SubCategoryFilter: false,
            filterCriteria: {
                limit: 10, page: 1, criteria: [],

            },
            EnteredByFilter: false,
            searchFilterCriteria: [],
            exhibit1Array: [],
            exhibit2Array: [],
            exhibit2String: [],
            exhibit1String: [],

            showErrorMessage: false,
            sessionExpiryModal: false,
            matrixQstns: [],
            checkedListPurpose: "",
            getMainTabFields: [
                {
                    name: 'DealType',
                    type: 'dropdownDeal',
                    placeholder: 'Deal Type',
                    options: [],
                    label: 'Deal Type',
                    required: true
                },
                {
                    name: 'EntityName',
                    type: 'text',
                    placeholder: 'Entity Name',
                    maxLength: 255,
                    label: 'Entity Name',
                    required: true
                },
                // {
                //     name: 'APAType',
                //     type: 'dropdown',
                //     placeholder: 'Deal Type',
                //     label: 'Deal Type',
                //     "options": [{ "label": "10-Full Remnant,subject to Exclusions", "value": '10' },
                //     { "label": "14-Full Remnant,includes RCS,subject to Exclusions", "value": '14' },
                //     { "label": "20-Limited scope,see Inclusions,subject to Exclusions", "value": '20' },
                //     { "label": "24-Limited scope,includes RCS,see Inclusions", "value": '24' },
                //     { "label": "30-UP only", "value": '30' },
                //     { "label": "40-RCS only", "value": '40' },
                //     { "label": "50-Judgment only", "value": '50' },
                //     { "label": "60-Other,limited to named inclusion(s)", "value": '60' },
                //     { "label": "64-Other,limited to named inclusion(s) with RCS", "value": '64' },
                //     { "label": "70-Full Remnant,Unable to pursue recoveries", "value": '70' },
                //     { "label": "75-Full Remnant,PWC portfolio restriction", "value": '75' },
                //     { "label": "80-Corporate Remnants", "value": '80' },
                //     { "label": "82-Corporate Remnants,limited scope", "value": '82' },
                //     { "label": "85-Corporate Remnants,NDA", "value": '85' },
                //     { "label": "87-Corporate Remnants,Sold", "value": '87' },
                //     ],
                //     // required: true
                // },
                {
                    name: 'SellerNickName',
                    type: 'text',
                    maxLength: 255,
                    placeholder: 'Deal Nick Name',
                    label: 'Deal Nick Name',
                    required: true
                },
                {
                    name: 'AzureStorageDirectory',
                    type: 'text',
                    maxLength: 100,
                    placeholder: 'Azure Storage Directory',
                    label: 'Azure Storage Directory',
                    required: true
                },
                {
                    name: 'OpraId',
                    type: 'number',
                    placeholder: 'OPRA ID',
                    label: 'OPRA ID',
                    required: true
                },

                {
                    name: 'LeadSeller',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'Lead Seller',
                    label: 'Lead Seller',
                    required: true
                },
                {
                    name: 'LeadCaseNumber',
                    type: 'text',
                    dealType: "BK",
                    required: true,
                    maxLength: 30,
                    placeholder: 'Lead Case Number',
                    label: 'Lead Case Number'
                },
                {
                    name: 'DealStage',
                    type: 'dropdown',
                    placeholder: 'Deal Stage',
                    "options": [],
                    label: 'Deal Stage',
                    required: true
                },
                {
                    name: 'DealStatus',
                    type: 'dropdown',
                    placeholder: 'Deal Status',
                    "options": [],
                    label: 'Deal Status',
                    required: true
                },

                {
                    name: 'CoreOpsTeam',
                    type: 'dropdown',
                    placeholder: 'CoreOps Team',
                    "options": [
                        { "label": "CoreOps 1", "value": 'CoreOps 1' },
                        { "label": "CoreOps 2", "value": 'CoreOps 2' },
                        { "label": "CoreOps 3", "value": 'CoreOps 3' },
                        { "label": "CoreOps 4", "value": 'CoreOps 4' },

                    ],
                    label: 'CoreOps Team',
                    required: true
                },

                // {
                //     name: 'SellerCompanyID',
                //     type: 'text',
                //     placeholder: 'Seller Company ID',
                //     label: 'Seller Company ID',
                //     required: true
                // },

            ],
            getConfidentialFields: [
                {
                    name: 'ChannelPartner',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'Channel Partner',
                    label: 'Channel Partner'
                },
                // {
                //     name: 'SupplementalAgreementType',
                //     type: 'text',
                //     dealType: "CR",
                //     placeholder: 'Supplemental Agreement Type',
                //     label: 'Supplemental Agreement Type'
                // },
                // {
                //     name: 'SupplementalAgreementDate',
                //     type: 'date',
                //     dealType: "CR",
                //     placeholder: 'Supplemental Agreement Date',
                //     label: 'Supplemental Agreement Date'
                // },

                // {
                //     name: 'SupplementalAgreementTerms',
                //     type: 'text',
                //     dealType: "CR",
                //     placeholder: 'Supplemental Agreement Terms',
                //     label: 'Supplemental Agreement Terms'
                // },
                // {
                //     name: 'DeclarationofUnclaimedProperty',
                //     type: 'date',
                //     placeholder: 'Declaration of Unclaimed Property',
                //     label: 'Declaration of Unclaimed Property'
                // },
                {
                    name: 'W9',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'W-9',
                    label: 'W-9'
                },

            ],
            getBusinessEntityFields: [
                // {
                //     name: 'businessEntity',
                //     type: 'text',
                //     placeholder: 'Main Business Entity',
                //     label: 'Main Business Entity'
                // },
                {
                    name: 'Industries',
                    type: 'text',
                    maxLength: 100,
                    placeholder: 'Industries',
                    label: 'Industries'
                },
                {
                    name: 'CompanyDescription',
                    type: 'text',
                    maxLength: 6000,
                    placeholder: 'Company Description',
                    label: 'Company Description'
                },
                {
                    name: 'stateofIncorporationIncluded',
                    type: 'dropdown',
                    placeholder: 'State Excluded For State of Incorporation',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    label: 'State Excluded For State of Incorporation'
                },
                {
                    name: 'StateofIncorporation',
                    type: 'dropdown',
                    include: "true",
                    options: [
                        { label: "Alabama - AL", value: "Alabama - AL", show: false },
                        { label: "Alaska - AK", value: "Alaska - AK", show: false },
                        { label: "Arizona - AZ", value: "Arizona - AZ", show: false },
                        { label: "Arkansas - AR", value: "Arkansas - AR", show: false },
                        { label: "California - CA", value: "California - CA", show: false },
                        { label: "Colorado - CO", value: "Colorado - CO", show: false },
                        { label: "Connecticut - CT", value: "Connecticut - CT", show: false },
                        { label: "Delaware - DE", value: "Delaware - DE", show: false },
                        { label: "Florida - FL", value: "Florida - FL", show: false },
                        { label: "Georgia - GA", value: "Georgia - GA", show: false },
                        { label: "Hawaii - HI", value: "Hawaii - HI", show: false },
                        { label: "Idaho - ID", value: "Idaho - ID", show: false },
                        { label: "Illinois - IL", value: "Illinois - IL", show: false },
                        { label: "Indiana - IN", value: "Indiana - IN", show: false },
                        { label: "Iowa - IA", value: "Iowa - IA", show: false },
                        { label: "Kansas - KS", value: "Kansas - KS", show: false },
                        { label: "Kentucky - KY", value: "Kentucky - KY", show: false },
                        { label: "Louisiana - LA", value: "Louisiana - LA", show: false },
                        { label: "Maine - ME", value: "Maine - ME", show: false },
                        { label: "Maryland - MD", value: "Maryland - MD", show: false },
                        { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false },
                        { label: "Michigan - MI", value: "Michigan - MI", show: false },
                        { label: "Minnesota - MN", value: "Minnesota - MN", show: false },
                        { label: "Mississippi - MS", value: "Mississippi - MS", show: false },
                        { label: "Missouri - MO", value: "Missouri - MO", show: false },
                        { label: "Montana - MT", value: "Montana - MT", show: false },
                        { label: "Nebraska - NE", value: "Nebraska - NE", show: false },
                        { label: "Nevada - NV", value: "Nevada - NV", show: false },
                        { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false },
                        { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false },
                        { label: "New Mexico - NM", value: "New Mexico - NM", show: false },
                        { label: "New York - NY", value: "New York - NY", show: false },
                        { label: "North Carolina - NC", value: "North Carolina - NC", show: false },
                        { label: "North Dakota - ND", value: "North Dakota - ND", show: false },
                        { label: "Ohio - OH", value: "Ohio - OH", show: false },
                        { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false },
                        { label: "Oregon - OR", value: "Oregon - OR", show: false },
                        { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false },
                        { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false },
                        { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false },
                        { label: "South Carolina - SC", value: "South Carolina - SC", show: false },
                        { label: "South Dakota - SD", value: "South Dakota - SD", show: false },
                        { label: "Tennessee - TN", value: "Tennessee - TN", show: false },
                        { label: "Texas - TX", value: "Texas - TX", show: false },
                        { label: "Utah - UT", value: "Utah - UT", show: false },
                        { label: "Vermont - VT", value: "Vermont - VT", show: false },
                        { label: "Virginia - VA", value: "Virginia - VA", show: false },
                        { label: "Washington - DC", value: "Washington - DC", show: false },
                        { label: "Washington - WA", value: "Washington - WA", show: false },
                        { label: "West Virginia - WV", value: "West Virginia - WV", show: false },
                        { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false },
                        { label: "Wyoming - WY", value: "Wyoming - WY", show: false },
                    ],
                    placeholder: 'State of Incorporation',
                    label: 'State of Incorporation'
                },
                {
                    name: 'StateofIncorporation',
                    type: 'text',
                    include: "false",
                    placeholder: 'State of Incorporation',
                    label: 'State of Incorporation'
                },
                {
                    name: 'HeadquartersAddress',
                    type: 'text',
                    maxLength: 255,
                    placeholder: 'Headquarters Address',
                    label: 'Headquarters Address'
                },
                {
                    name: 'headquartersStateExcluded',
                    type: 'dropdown',
                    placeholder: 'State Excluded For Headquarters State',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    label: 'State Excluded For Headquarters State'
                },
                {
                    name: 'HeadquartersState',
                    type: 'dropdown',
                    include1: "true",
                    options: [
                        { label: "Alabama - AL", value: "Alabama - AL", show: false },
                        { label: "Alaska - AK", value: "Alaska - AK", show: false },
                        { label: "Arizona - AZ", value: "Arizona - AZ", show: false },
                        { label: "Arkansas - AR", value: "Arkansas - AR", show: false },
                        { label: "California - CA", value: "California - CA", show: false },
                        { label: "Colorado - CO", value: "Colorado - CO", show: false },
                        { label: "Connecticut - CT", value: "Connecticut - CT", show: false },
                        { label: "Delaware - DE", value: "Delaware - DE", show: false },
                        { label: "Florida - FL", value: "Florida - FL", show: false },
                        { label: "Georgia - GA", value: "Georgia - GA", show: false },
                        { label: "Hawaii - HI", value: "Hawaii - HI", show: false },
                        { label: "Idaho - ID", value: "Idaho - ID", show: false },
                        { label: "Illinois - IL", value: "Illinois - IL", show: false },
                        { label: "Indiana - IN", value: "Indiana - IN", show: false },
                        { label: "Iowa - IA", value: "Iowa - IA", show: false },
                        { label: "Kansas - KS", value: "Kansas - KS", show: false },
                        { label: "Kentucky - KY", value: "Kentucky - KY", show: false },
                        { label: "Louisiana - LA", value: "Louisiana - LA", show: false },
                        { label: "Maine - ME", value: "Maine - ME", show: false },
                        { label: "Maryland - MD", value: "Maryland - MD", show: false },
                        { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false },
                        { label: "Michigan - MI", value: "Michigan - MI", show: false },
                        { label: "Minnesota - MN", value: "Minnesota - MN", show: false },
                        { label: "Mississippi - MS", value: "Mississippi - MS", show: false },
                        { label: "Missouri - MO", value: "Missouri - MO", show: false },
                        { label: "Montana - MT", value: "Montana - MT", show: false },
                        { label: "Nebraska - NE", value: "Nebraska - NE", show: false },
                        { label: "Nevada - NV", value: "Nevada - NV", show: false },
                        { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false },
                        { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false },
                        { label: "New Mexico - NM", value: "New Mexico - NM", show: false },
                        { label: "New York - NY", value: "New York - NY", show: false },
                        { label: "North Carolina - NC", value: "North Carolina - NC", show: false },
                        { label: "North Dakota - ND", value: "North Dakota - ND", show: false },
                        { label: "Ohio - OH", value: "Ohio - OH", show: false },
                        { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false },
                        { label: "Oregon - OR", value: "Oregon - OR", show: false },
                        { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false },
                        { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false },
                        { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false },
                        { label: "South Carolina - SC", value: "South Carolina - SC", show: false },
                        { label: "South Dakota - SD", value: "South Dakota - SD", show: false },
                        { label: "Tennessee - TN", value: "Tennessee - TN", show: false },
                        { label: "Texas - TX", value: "Texas - TX", show: false },
                        { label: "Utah - UT", value: "Utah - UT", show: false },
                        { label: "Vermont - VT", value: "Vermont - VT", show: false },
                        { label: "Virginia - VA", value: "Virginia - VA", show: false },
                        { label: "Washington - DC", value: "Washington - DC", show: false },
                        { label: "Washington - WA", value: "Washington - WA", show: false },
                        { label: "West Virginia - WV", value: "West Virginia - WV", show: false },
                        { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false },
                        { label: "Wyoming - WY", value: "Wyoming - WY", show: false },
                    ],
                    placeholder: 'Headquarters State',
                    label: 'Headquarters State'
                },
                {
                    name: 'HeadquartersState',
                    type: 'text',
                    include1: "false",
                    placeholder: 'Headquarters State',
                    label: 'Headquarters State'
                },
                // {
                //     name: 'keyAdresses',
                //     type: 'text',
                //     placeholder: 'Key Addresses',
                //     label: 'Key Addresses'
                // },
                {
                    name: 'UsLocations',
                    type: 'multiselect',
                    options: [
                        { label: "Alabama - AL", value: "AL", show: false },
                        { label: "Alaska - AK", value: "AK", show: false },
                        { label: "Arizona - AZ", value: "AZ", show: false },
                        { label: "Arkansas - AR", value: "AR", show: false },
                        { label: "California - CA", value: "CA", show: false },
                        { label: "Colorado - CO", value: "CO", show: false },
                        { label: "Connecticut - CT", value: "CT", show: false },
                        { label: "Delaware - DE", value: "DE", show: false },
                        { label: "Florida - FL", value: "FL", show: false },
                        { label: "Georgia - GA", value: "GA", show: false },
                        { label: "Hawaii - HI", value: "HI", show: false },
                        { label: "Idaho - ID", value: "ID", show: false },
                        { label: "Illinois - IL", value: "IL", show: false },
                        { label: "Indiana - IN", value: "IN", show: false },
                        { label: "Iowa - IA", value: "IA", show: false },
                        { label: "Kansas - KS", value: "KS", show: false },
                        { label: "Kentucky - KY", value: "KY", show: false },
                        { label: "Louisiana - LA", value: "LA", show: false },
                        { label: "Maine - ME", value: "ME", show: false },
                        { label: "Maryland - MD", value: "MD", show: false },
                        { label: "Massachusetts - MA", value: "MA", show: false },
                        { label: "Michigan - MI", value: "MI", show: false },
                        { label: "Minnesota - MN", value: "MN", show: false },
                        { label: "Mississippi - MS", value: "MS", show: false },
                        { label: "Missouri - MO", value: "MO", show: false },
                        { label: "Montana - MT", value: "MT", show: false },
                        { label: "Nebraska - NE", value: "NE", show: false },
                        { label: "Nevada - NV", value: "NV", show: false },
                        { label: "New Hampshire - NH", value: "NH", show: false },
                        { label: "New Jersey - NJ", value: "NJ", show: false },
                        { label: "New Mexico - NM", value: "NM", show: false },
                        { label: "New York - NY", value: "NY", show: false },
                        { label: "North Carolina - NC", value: "NC", show: false },
                        { label: "North Dakota - ND", value: "ND", show: false },
                        { label: "Ohio - OH", value: "OH", show: false },
                        { label: "Oklahoma - OK", value: "OK", show: false },
                        { label: "Oregon - OR", value: "OR", show: false },
                        { label: "Pennsylvania - PA", value: "PA", show: false },
                        { label: "Puerto Rico - PR", value: "PR", show: false },
                        { label: "Rhode Island - RI", value: "RI", show: false },
                        { label: "South Carolina - SC", value: "SC", show: false },
                        { label: "South Dakota - SD", value: "SD", show: false },
                        { label: "Tennessee - TN", value: "TN", show: false },
                        { label: "Texas - TX", value: "TX", show: false },
                        { label: "Utah - UT", value: "UT", show: false },
                        { label: "Vermont - VT", value: "VT", show: false },
                        { label: "Virginia - VA", value: "VA", show: false },
                        { label: "Washington - DC", value: "DC", show: false },
                        { label: "Washington - WA", value: "WA", show: false },
                        { label: "West Virginia - WV", value: "WV", show: false },
                        { label: "Wisconsin - WI", value: "WI", show: false },
                        { label: "Wyoming - WY", value: "WY", show: false },
                    ],
                    placeholder: 'U.S. Locations',
                    label: 'U.S. Locations'
                },
                // {
                //     name: 'keyEntityNames',
                //     type: 'text',
                //     placeholder: 'Key Entity Names',
                //     label: 'Key Entity Names'
                // },
                {
                    name: 'InternationalLocations',
                    type: 'text',
                    placeholder: 'International Locations',
                    label: 'International Locations'
                },
                {
                    name: 'PublicCompany',
                    type: 'dropdown',
                    placeholder: 'Public Company?',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    label: 'Public Company?'
                },

                {
                    name: 'NumberofLocations',
                    type: 'number',
                    placeholder: 'Number of Locations',
                    label: 'Number of Locations'
                },
                {
                    name: 'CIKCode',
                    type: 'number',
                    placeholder: 'CIK Code',
                    label: 'CIK Code'
                },
                {
                    name: 'LocationNotes',
                    type: 'text',
                    maxLength: 100,
                    placeholder: 'Location Notes',
                    label: 'Location Notes'
                },
                {
                    name: 'SICCode',
                    type: 'multiselect',
                    // options: this.state.sicCodes,
                    placeholder: 'SIC Code',
                    label: 'SIC Code'
                },
                {
                    name: 'Revenue',
                    type: 'number',
                    placeholder: 'Revenue',
                    label: 'Revenue'
                },
                {
                    name: 'NAICSCode',
                    type: 'number',
                    placeholder: 'NAICS Code',
                    label: 'NAICS Code'
                },
                {
                    name: 'RevenueYear',
                    type: 'number',
                    placeholder: 'Revenue Year',
                    label: 'Revenue Year'
                },
                {
                    name: 'DUNS',
                    maxLength: 30,
                    type: 'text',
                    placeholder: 'DUNS',
                    label: 'DUNS'
                },
                {
                    name: 'EmployeeCount',
                    type: 'number',
                    placeholder: 'Employee Count',
                    label: 'Employee Count'
                },
                {
                    name: 'EmployeeCountYear',
                    type: 'number',
                    placeholder: 'Employee Count Year',
                    label: 'Employee Count Year'
                },


                // {
                //     name: 'nameSource',
                //     type: 'text',
                //     placeholder: 'Name Source',
                //     label: 'Name Source'
                // },
                // {
                //     name: 'connectedEntity',
                //     type: 'text',
                //     placeholder: 'Connected Entity',
                //     label: 'Connected Entity'
                // },
                // {
                //     name: 'connectionType',
                //     type: 'text',
                //     placeholder: 'Connection Type',
                //     label: 'Connection Type'
                // },
                {
                    name: 'APAPurchasePrice',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'APA Purchase Price',
                    label: 'APA Purchase Price'
                },

                {
                    name: 'category',
                    type: 'text',
                    dealType: "BK",
                    placeholder: 'Category',
                    label: 'Category'
                },


                {
                    name: 'EIN1',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'EIN 1',
                    label: 'EIN 1'
                },
                {
                    name: 'EIN1Source',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'EIN 1 Source',
                    label: 'EIN 1 Source'
                },
                {
                    name: 'EIN2',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'EIN 2',
                    label: 'EIN 2'
                },
                {
                    name: 'EIN2Source',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 50,
                    placeholder: 'EIN 2 Source',
                    label: 'EIN 2 Source'
                },
                {
                    name: 'EIN3',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'EIN 3',
                    label: 'EIN 3'
                },
                {
                    name: 'EIN3Source',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 50,
                    placeholder: 'EIN 3 Source',
                    label: 'EIN 3 Source'
                },
                // {
                //     name: 'generalNotes',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'General Notes',
                //     label: 'General Notes'
                // },
                {
                    name: 'GeneralRestrictions',
                    type: 'text',
                    maxLength: 255,
                    dealType: "BK",
                    placeholder: 'General Restrictions',
                    label: 'General Restrictions'
                },
                {
                    name: 'category1Notes',
                    type: 'text',
                    dealType: "BK",
                    placeholder: 'Category 1 Notes',
                    label: 'Category 1 Notes'
                },
                // {
                //     name: 'category2Notes',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Category 2 Notes',
                //     label: 'Category 2 Notes'
                // },
                {
                    name: 'JudgmentFees',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'Judgment-Fees',
                    label: 'Judgment-Fees'
                },
                {
                    name: 'judgmentPreJudgmentInterest',
                    type: 'text',
                    dealType: "BK",
                    placeholder: 'Judgment-Pre Judgment Interest',
                    label: 'Judgment-Pre Judgment Interest'
                },
                {
                    name: 'ConnectionTypeYear',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'Connection Type Year',
                    label: 'Connection Type Year'
                },
                // {
                //     name: 'dateEntityNameAddedorEdited',
                //     type: 'date',
                //     dealType: "BK",
                //     placeholder: 'Date Entity Name Added or Edited',
                //     label: 'Date Entity Name Added or Edited'
                // },
                {
                    name: 'OperatingDates',
                    type: 'number',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'Operating Dates',
                    label: 'Operating Dates'
                },
                {
                    name: 'dateRestrictions',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Date Restrictions',
                    label: 'Date Restrictions'
                },
                {
                    name: 'DateEIN1Added',
                    type: 'date',
                    placeholder: 'Date EIN 1 Added',
                    label: 'Date EIN 1 Added'
                },
                {
                    name: 'DateEIN2Added',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Date EIN 2 Added',
                    label: 'Date EIN 2 Added'
                },
                {
                    name: 'DateEIN3Added',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Date EIN 3 Added',
                    label: 'Date EIN 3 Added'
                },
                {
                    name: 'JudgmentDateEntered',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Judgment - Date Entered',
                    label: 'Judgment - Date Entered'
                },
                {
                    name: 'JudgmentInterestAccrualStartDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Judgment-Interest Accrual Start Date',
                    label: 'Judgment-Interest Accrual Start Date'
                },
                {
                    name: 'JudgmentPreJudgmentInterestRate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Judgment-Pre Judgment Interest Rate',
                    label: 'Judgment-Pre Judgment Interest Rate'
                },
                {
                    name: 'JudgmentOtherInterestRate',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'Judgment-Other Interest Rate',
                    label: 'Judgment-Other Interest Rate'
                },
                {
                    name: 'JudgmentOutstandingClaimValue',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'Judgment-Outstanding Claim Value',
                    label: 'Judgment-Outstanding Claim Value'
                },
                {
                    name: 'JudgmentRemainingJudgmentValuetoClaim',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'Judgment-Remaining Judgment Value to Claim',
                    label: 'Judgment-Remaining Judgment Value to Claim'
                },
                {
                    name: 'JudgmentCourt',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 50,
                    placeholder: 'Judgment-Court',
                    label: 'Judgment-Court'
                },
                {
                    name: 'JudgmentInterestClaimed',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'Judgment-Interest Claimed',
                    label: 'Judgment-Interest Claimed'
                },
                {
                    name: 'JudgmentAccruedInterestatTimeofPayment',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'Judgment-Accrued Interest at Time of Payment',
                    label: 'Judgment-Accrued Interest at Time of Payment'
                },
                {
                    name: 'JudgmentCourtType',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'Judgment-Court Type',
                    label: 'Judgment-Court Type'
                },
                {
                    name: 'JudgmentDistrict',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 50,
                    placeholder: 'Judgment-District',
                    label: 'Judgment-District'
                },
                {
                    name: 'JudgmentDistrictType',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 50,
                    placeholder: 'Judgment-District Type',
                    label: 'Judgment-District Type'
                },
                {
                    name: 'JudgmentPlaceholder',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'Judgment-Placeholder',
                    label: 'Judgment-Placeholder'
                },
                {
                    name: 'JudgmentDistrictCounty',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'Judgment-District County',
                    label: 'Judgment-District County'
                },
                {
                    name: 'JudgmentCountofDebtorNames',
                    type: 'text',
                    maxLength: 255,
                    dealType: "BK",
                    placeholder: 'Judgment-Count of Debtor Names',
                    label: 'Judgment-Count of Debtor Names'
                },
                {
                    name: 'JudgmentMiscCaseNumbers',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'Judgment-Misc Case Numbers',
                    label: 'Judgment-Misc Case Numbers'
                },
                {
                    name: 'JudgmentRegistrationStates',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'Judgment-Registration States',
                    label: 'Judgment-Registration States'
                },
            ],
            getThirdPartyFields: [
                // {
                //     name: 'hilcoStreamBankNoticed',
                //     type: 'date',
                //     placeholder: 'Hilco Stream Bank Noticed Date',
                //     label: 'Hilco Stream Bank Noticed Date'
                // },
                {
                    name: 'HilcoStreamBankPortfolio',
                    type: 'dropdown',
                    "options": [{ "label": "Yes", "value": true }, { "label": "No", "value": false }],
                    placeholder: 'Hilco Stream Bank Portfolio',
                    label: 'Hilco Stream Bank Portfolio'
                },
                // {
                //     name: 'NRSNoticed',
                //     type: 'date',
                //     placeholder: 'NRS Noticed Date',
                //     label: 'NRS Noticed Date'
                // },
                {
                    name: 'NRSPortfolio',
                    type: 'dropdown',
                    placeholder: 'NRS Portfolio',
                    "options": [{ "label": "Yes", "value": true }, { "label": "No", "value": false }],
                    label: 'NRS Portfolio'
                },
                // {
                //     name: 'ACRSNoticed',
                //     type: 'date',
                //     placeholder: 'ACRS Noticed Date',
                //     label: 'ACRS Noticed Date'
                // },
                {
                    name: 'ACRSPortfolio',
                    type: 'dropdown',
                    placeholder: 'ACRS Portfolio',
                    "options": [{ "label": "Yes", "value": true }, { "label": "No", "value": false }],
                    label: 'ACRS Portfolio'
                },
                // {
                //     name: 'CIGNANoticed',
                //     type: 'date',
                //     placeholder: 'CIGNA Noticed Date',
                //     label: 'CIGNA Noticed Date'
                // },
                {
                    name: 'CIGNAPortfolio',
                    type: 'dropdown',
                    placeholder: 'CIGNA Portfolio',
                    "options": [{ "label": "Yes", "value": true }, { "label": "No", "value": false }],
                    label: 'CIGNA Portfolio'
                },
                // {
                //     name: 'AETNANoticed',
                //     type: 'date',
                //     placeholder: 'AETNA Noticed Date',
                //     label: 'AETNA Noticed Date'
                // },
                {
                    name: 'AETNAPortfolio',
                    type: 'dropdown',
                    placeholder: 'AETNA Portfolio',
                    "options": [{ "label": "Yes", "value": true }, { "label": "No", "value": false }],
                    label: 'AETNA Portfolio'
                },
                {
                    name: 'ArgentPortfolio',
                    type: 'dropdown',
                    placeholder: 'ARGENT Portfolio',
                    "options": [{ "label": "Yes", "value": true }, { "label": "No", "value": false }],
                    label: 'ARGENT Portfolio'
                },
                {
                    name: 'CCBPortfolio',
                    type: 'dropdown',
                    placeholder: 'CCB Portfolio',
                    "options": [{ "label": "Yes", "value": true }, { "label": "No", "value": false }],
                    label: 'CCB Portfolio'
                },


            ],
            getAPATabFields: [
                // {
                //     name: 'CorporateAPAType',
                //     type: 'text',
                //     placeholder: 'Corporate APA Type',
                //     label: 'Corporate APA Type'
                // },
                // {
                //     name: 'CRMostRecentAPAEntityNamesListDate',
                //     type: 'date',
                //     placeholder: 'CR - Date of Most Recent APA Entity Names List',
                //     label: 'CR - Date of Most Recent APA Entity Names List'
                // },
                // {
                //     name: 'distressedDealAPA',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Distressed Deal APA',
                //     label: 'Distressed Deal APA'
                // },
                {
                    name: 'OPPApaDate',
                    type: 'date',
                    placeholder: 'Oak Point APA Date',
                    label: 'Oak Point APA Date',
                    required: true
                },


                // {
                //     name: 'APADate',
                //     type: 'date',
                //     placeholder: 'APA Date',
                //     label: 'APA Date'
                // },
                // {
                //     name: 'BidProceduresOverbidAmount',
                //     type: 'text',
                //     placeholder: 'Bid Procedures - Overbid Amnt',
                //     label: 'Bid Procedures - Overbid Amnt',

                // },
                {
                    name: 'RCS',
                    type: 'dropdown',
                    dealType: "BK",
                    placeholder: 'RCS',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    label: 'RCS'
                },
                {
                    name: 'RCSNotes',
                    type: 'text',
                    maxLength: 2000,
                    placeholder: 'RCS Notes',
                    label: 'RCS Notes'
                },
                {
                    name: 'RCSHoldingPeriod',
                    type: 'number',
                    placeholder: 'RCS Holding Period',
                    label: 'RCS Holding Period'
                },
                {
                    name: 'RCSClaimsDistributionDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'RCS Claims Distribution Date',
                    label: 'RCS Claims Distribution Date'
                },
                {
                    name: 'RCSOakPointAcquireDate',
                    type: 'date',
                    placeholder: 'RCS Oak Point Acquire Date',
                    label: 'RCS Oak Point Acquire Date'
                },
                {
                    name: 'RCSDistributionAmount',
                    type: 'number',
                    placeholder: 'RCS Distribution Amount',
                    label: 'RCS Distribution Amount'
                },
                {
                    name: 'RCSNoOfCreditors',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'RCS No of Creditors',
                    label: 'RCS No of Creditors'
                },
                {
                    name: 'RCSMotionFiled',
                    type: 'date',
                    placeholder: 'RCS Motion Filed',
                    label: 'RCS Motion Filed'
                },
                {
                    name: 'RCSBankContact',
                    type: 'text',
                    maxLength: 255,
                    placeholder: 'RCS Bank Contact',
                    label: 'RCS Bank Contact'
                },
                {
                    name: 'RCSStatus',
                    type: 'text',
                    maxLength: 30,
                    placeholder: 'RCS Status',
                    label: 'RCS Status'
                },
                // {
                //     name: 'AbbreviatedSellerName',
                //     type: 'text',
                //     placeholder: 'Abbreviated Seller Name',
                //     label: 'Abbreviated Seller Name'
                // },
                // {
                //     name: 'LeadSellerEntity',
                //     type: 'text',
                //     placeholder: 'Lead Seller Entity',
                //     label: 'Lead Seller Entity'
                // },
                {
                    name: 'SellerCompanyID',
                    type: 'text',
                    maxLength: 100,
                    placeholder: 'Seller Company Id',
                    label: 'Seller Company Id'
                },
                {
                    name: 'ExclusionsNotStatedinAPA',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'Exclusions Not Stated in APA',
                    label: 'Exclusions Not Stated in APA'
                },
                {
                    name: 'Buyer',
                    type: 'dropdown',
                    "options": [{ "label": "Oak Point Partners Inc", "value": 'Oak Point Partners Inc' }, { "label": "Oak Point Partners LLC", "value": 'Oak Point Partners LLC' }],
                    placeholder: 'Buyer',
                    label: 'Buyer'
                },

                // {
                //     name: 'SellerSignatory',
                //     type: 'Table',
                //     maxLength: 100,
                //     placeholder: 'Seller Signatory',
                //     label: 'Seller Signatory'
                // },
                // {
                //     name: 'SellerSignatoryTitle',
                //     type: 'table',
                //     maxLength: 1000,
                //     placeholder: 'Seller Signatory’s Title',
                //     label: 'Seller Signatory’s Title'
                // },
                // {
                //     name: 'APASellerDefinition',
                //     type: 'text',
                //     placeholder: 'APA Seller definition',
                //     label: 'APA Seller definition'
                // },

                {
                    name: 'PurchasePrice',
                    type: 'currency',
                    placeholder: 'Purchase Price',
                    label: 'Purchase Price'
                },

                {
                    name: 'Remnants',
                    type: 'dropdown',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    placeholder: 'Remnants',
                    label: 'Remnants'
                },
                {
                    name: 'Judgments',
                    type: 'dropdown',
                    dealType: "BK",
                    placeholder: 'Judgments',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    label: 'Judgments'
                },
                {
                    name: 'JudgmentsNotes',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 1000,
                    placeholder: 'Judgments – Notes',
                    label: 'Judgments – Notes'
                },
                {
                    name: 'APASellerdefinition',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'APA Seller definition',
                    label: 'APA Seller definition'
                },
                {
                    name: 'InclusionsNotStatedinAPA',
                    type: 'text',
                    maxLength: 1000,
                    placeholder: 'Inclusions Not Stated in APA',
                    label: 'Inclusions Not Stated in APA'
                },
                // {
                //     name: 'AdditionalExclusions',
                //     type: 'text',
                //     maxLength: 255,
                //     // dealType: "BK",
                //     placeholder: 'Exclusions not stated in APA',
                //     label: 'Exclusions not stated in APA'
                // },
                // {
                //     name: 'AdditionalExclusions',
                //     type: 'text',
                //     placeholder: 'Additional Exclusions not in APA',
                //     label: 'Additional Exclusions not in APA'
                // },
                {
                    name: 'IndemnificationProvision',
                    type: 'text',
                    maxLength: 2500,
                    placeholder: 'Indemnification Provision',
                    label: 'Indemnification Provision'
                },
                {
                    name: 'LimitedPowerOfAttorney',
                    type: 'dropdown',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    placeholder: 'Limited Power of Attorney',
                    label: 'Limited Power of Attorney'
                },
                {
                    name: 'ModifiedPowerOfAttorneyProvision',
                    type: 'text',
                    maxLength: 1700,
                    placeholder: 'Power of Attorney Provision',
                    label: 'Power of Attorney Provision'
                },
                {
                    name: 'FreeAndClearSale',
                    type: 'dropdown',
                    dealType: "BK",
                    "options": [{ "label": "N - Creditor Activity", "value": 'N - Creditor Activity' },
                    { "label": "N - Creditor Sale", "value": 'N - Creditor Sale' },
                    { "label": "N - Denied", "value": 'N - Denied' },
                    { "label": "N - Dissolution", "value": 'N - Dissolution' },
                    { "label": "N - Judge Position", "value": 'N - Judge Position' },
                    { "label": "N - No Legal Authority", "value": 'N - No Legal Authority' },
                    { "label": "N - Post-Dismissal", "value": 'N - Post-Dismissal' },
                    { "label": "N - Seller Position", "value": 'N - Seller Position' },
                    { "label": "N - but No Assumption Liabilities under APA", "value": 'N - but No Assumption Liabilities under APA' },
                    { "label": "Y - Under 1141", "value": 'Y - Under 1141' },
                    { "label": "Y - Under Plan", "value": 'Y - Under Plan' },
                    { "label": "Y - Under Plan but subject to Specific Lien Only", "value": 'Y - Under Plan but subject to Specific Lien Only' },
                    { "label": "Y", "value": 'Y' },

                    ],
                    placeholder: 'Free and Clear Sale',
                    label: 'Free and Clear Sale'
                },
                {
                    name: 'FreeAndClearNotes',
                    type: 'text',
                    maxLength: 1000,
                    dealType: "BK",
                    placeholder: 'Free and Clear Notes',
                    label: 'Free and Clear Notes'
                },
                {
                    name: 'MailForwardingProvision',
                    type: 'dropdown',
                    dealType: "BK",
                    "options": [{ "label": "Included", "value": 'Included' }, { "label": "Excluded", "value": 'Excluded' }],
                    placeholder: 'Mail Forwarding Provision',
                    label: 'Mail Forwarding Provision'
                },
                {
                    name: 'NotableLanguage',
                    type: 'text',
                    maxLength: 1600,
                    placeholder: 'Notable APA Language',
                    label: 'Notable APA Language'
                },
                {
                    name: 'APAAddendum',
                    type: 'dropdown',
                    "options": [{ "label": "Yes", "value": 'Y' },
                    { "label": "No", "value": 'N' },],
                    placeholder: 'APA Addendum',
                    label: 'APA Addendum'
                },
                {
                    name: 'AddendumDate',
                    type: 'date',
                    placeholder: 'APA Addendum Date',
                    label: 'APA Addendum Date'
                },
                {
                    name: 'APAAddendumDetails',
                    type: 'text',
                    maxLength: 1000,
                    placeholder: 'APA Addendum Details',
                    label: 'APA Addendum Details'
                },

                {
                    name: 'RemnantAssetLanguage',
                    type: 'textarea',
                    maxLength: 2500,
                    placeholder: 'Remnant Assets Language, Inclusions',
                    label: 'Remnant Assets Language, Inclusions'
                },
                {
                    name: 'APAExclusions',
                    type: 'textarea',
                    maxLength: 4000,
                    placeholder: 'APA Exclusions',
                    label: 'APA Exclusions'
                },
                {
                    name: 'SharingAgreement',
                    type: 'textarea',
                    maxLength: 2000,
                    placeholder: 'Price Caveat / Sharing Agreement',
                    label: 'Price Caveat / Sharing Agreement'
                },
            ],
            getCaseTabFields: [
                {
                    name: 'CaseFilingDate',
                    type: 'date',
                    placeholder: 'Case Filing Date',
                    label: 'Case Filing Date'
                },
                {
                    name: 'Jurisdiction',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'Jurisdiction',
                    label: 'Jurisdiction'
                },
                {
                    name: 'BankruptcyCaseType',
                    type: 'dropdown',
                    dealType: "BK",
                    "options": [{ "label": "Voluntary chapter 7", "value": 'Voluntary chapter 7' }, { "label": "Involuntary chapter 7", "value": 'Involuntary chapter 7' }, { "label": "Voluntary chapter 11", "value": 'Voluntary chapter 11' }, { "label": "Involuntary chapter 11", "value": 'Involuntary chapter 11' }, { "label": "ABC", "value": 'ABC' }, { "label": "Receivership", "value": 'Receivership' }],
                    placeholder: 'Case Type',
                    label: 'Case Type',
                    // required: true
                },
                {
                    name: 'Division',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 50,
                    placeholder: 'Division',
                    label: 'Division'
                },
                {
                    name: 'PertinentCaseNotes',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 1000,
                    placeholder: 'Pertinent Case Notes',
                    label: 'Pertinent Case Notes'
                },
                {
                    name: 'CaseTransfer',
                    type: 'dropdown',
                    options: [{ label: "Y", value: "Y" }, { label: "N", value: "N" }, { label: "Y – Inter", value: "Y – Inter" }, { label: "Y – Intra", value: "Y – Intra" }],
                    placeholder: 'Case Transfer',
                    label: 'Case Transfer'
                },

                {
                    name: 'CaseTransferDate',
                    type: 'date',
                    placeholder: 'Case Transfer Date',
                    label: 'Case Transfer Date'
                },
                {
                    name: 'OriginatingJurisdiction',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'Originating Jurisdiction',
                    label: 'Originating Jurisdiction'
                },
                {
                    name: 'CaseConversion',
                    type: 'dropdown',
                    options: [{ label: "Y-Involuntary chapter 7 to voluntary chapter 7", value: "Y-Involuntary chapter 7 to voluntary chapter 7" }, { label: "Y-Involuntary chapter 7 to voluntary chapter 11", value: "Y-Involuntary chapter 7 to voluntary chapter 11" }, { label: "Y-Involuntary chapter 11 to voluntary chapter 11", value: "Y-Involuntary chapter 11 to voluntary chapter 11" }, { label: "Y-Involuntary chapter 11 to voluntary chapter 7", value: "Y-Involuntary chapter 11 to voluntary chapter 7" }, { label: "Y-Voluntary chapter 11 to voluntary chapter 7", value: "Y-Voluntary chapter 11 to voluntary chapter 7" }, { label: "N", value: "N" }],
                    placeholder: 'Case Conversion',
                    label: 'Case Conversion'
                },

                {
                    name: 'CaseConversionDate',
                    type: 'date',
                    placeholder: 'Case Conversion Date',
                    label: 'Case Conversion Date'
                },
                {
                    name: 'CaseStatus',
                    type: 'dropdown',
                    dealType: "BK",
                    "options": [{ "label": "Closed", "value": 'Closed' }, { "label": "Open", "value": 'Open' }, { "label": "Closed – Dismissed", "value": "Closed – Dismissed" }],
                    placeholder: 'Case Status',
                    label: 'Case Status',
                    required: true
                },
                {
                    name: 'CaseStatusClosedDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Case Status Closed Date',
                    label: 'Case Status Closed Date',
                    required: true
                },
                {
                    name: 'CaseStatusDismissalDate',
                    type: 'date',
                    placeholder: 'Dismissal Date',
                    label: 'Dismissal Date'
                },
                {
                    name: 'ClosingDate',
                    type: 'date',
                    placeholder: 'Closing Date',
                    label: 'Closing Date',
                    // required: true
                },
                {
                    name: 'FinalDecreeDate',
                    type: 'date',
                    // dealType: "BK",
                    placeholder: 'Final Decree Date',
                    label: 'Final Decree Date',
                    // required: true

                },
                // {
                //     name: 'PertinentCaseNotes',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Pertinent Case Notes',
                //     label: 'Pertinent Case Notes'
                // },

                {
                    name: 'Judge',
                    type: 'text',
                    maxLength: 100,
                    // dealType: "BK",
                    placeholder: 'Judge at time of Oak Point APA',
                    label: 'Judge at time of Oak Point APA'
                },
                {
                    name: 'ClaimsAgent',
                    type: 'text',
                    maxLength: 100,
                    placeholder: 'Claims Agent',
                    label: 'Claims Agent'
                },
                {
                    name: 'CaseStatusDismissal',
                    type: 'text',
                    maxLength: 30,
                    placeholder: 'Case Status Dismissal',
                    label: 'Case Status Dismissal'
                },

                {
                    name: 'CaseStatusNotes',
                    type: 'text',
                    maxLength: 500,
                    placeholder: 'Case Status Notes',
                    label: 'Case Status Notes'
                },


                {
                    name: 'PlanConfirmationDate',
                    type: 'date',
                    placeholder: 'Plan Confirmation Date',
                    label: 'Plan Confirmation Date'
                },
                // {
                //     name: 'BankruptcyCaseType',
                //     type: 'text',
                //     placeholder: 'Bankruptcy CaseType',
                //     label: 'Bankruptcy CaseType'
                // },
                // {
                //     name: 'DRDealSubtype',
                //     type: 'dropdown',
                //     "options": [{ "label": "Bankruptcy Chapter 7", "value": 'Bankruptcy Chapter 7' },
                //     { "label": "Bankruptcy Chapter 11", "value": 'Bankruptcy Chapter 11' },
                //     { "label": "Assignment for the Benefit of Creditors", "value": 'Assignment for the Benefit of Creditors' },
                //     { "label": "Receivership", "value": 'Receivership' },
                //     { "label": "Out of Court Winddown", "value": 'Out of Court Winddown' },

                //     ],
                //     dealType: "BK",
                //     placeholder: 'Distressed Deal Subtype',
                //     label: 'Distressed Deal Subtype'
                // },
            ],
            getpostsalediligencefields: [{
                name: 'RingTheBellDate1',
                type: 'date1',
                isDisable: true,
                fixed: true,
                placeholder: 'Ring the Bell Date',
                label: 'Ring the Bell Date'
            },
            {
                name: 'FormsRequested',
                type: 'text',
                // isDisable: true,
                placeholder: 'Forms Requested',
                label: 'Forms Requested',
                // required: true
            },
            {
                name: 'OPPSalesRep1',
                type: 'text1',
                isDisable: true,
                fixed: true,
                placeholder: 'OPP Sourcing Lead',
                label: 'OPP Sourcing Lead',
                // required: true
            },
            {
                name: 'UPFormsCalifornia',
                type: 'text',
                maxLength: 1000,
                placeholder: 'UP Forms – California',
                label: 'UP Forms – California'
            },
            {
                name: 'DAApproval',
                type: 'text',
                // isDisable: true,
                placeholder: 'DA Approval (negative Notice)',
                label: 'DA Approval (negative Notice)',
                // required: true
            },
            {
                name: 'UPFormsTexas',
                type: 'text',
                maxLength: 100,
                placeholder: 'UP Forms – Texas',
                label: 'UP Forms – Texas'
            },

            {
                name: 'OkForWebsiteDate1',
                type: 'date1',
                isDisable: true,
                fixed: true,
                // dealType: "BK",
                placeholder: 'Ok for Website Y/N, Date',
                label: 'Ok for Website Y/N, Date'
            },
            {
                name: 'UPFormsGeneral',
                type: 'text',
                maxLength: 1000,
                placeholder: 'UP Forms – General',
                label: 'UP Forms – General'
            },
            // {
            //     name: 'OkForWebsite1',
            //     type: 'text1',
            //     maxLength: 100,
            //     // dealType: "BK",
            //     fixed: true,
            //     isDisable: true,
            //     placeholder: 'Ok for Website',
            //     label: 'Ok for Website'
            // },

            // {
            //     name: 'SignatorytoAgreement',
            //     type: 'text',
            //     // isDisable: true,
            //     placeholder: 'Signatory to Agreement',
            //     label: 'Signatory to Agreement',
            //     // required: true
            // },


            {
                name: 'FU1withSeller',
                type: 'text',
                // isDisable: true,
                placeholder: '1st F/u with Seller',
                label: '1st F/u with Seller',
                // required: true
            },
            {
                name: 'FU2withSeller',
                type: 'text',
                // isDisable: true,
                placeholder: '2nd F/u with Seller',
                label: '2nd F/u with Seller',
                // required: true
            },
            {
                name: 'XFRtoSourcing',
                type: 'text',
                // isDisable: true,
                placeholder: 'XFR to Sourcing',
                label: 'XFR to Sourcing',
                // required: true
            },
            {
                name: 'POCs',
                type: 'text',
                // isDisable: true,
                placeholder: 'POCs (Did not Request)',
                label: 'POCs (Did not Request)',
                // required: true
            },



            ],

            getDealTabFields: [
                {
                    name: 'APAType',
                    type: 'dropdown',
                    placeholder: 'APA Type',
                    label: 'APA Type',
                    "options": [{ "label": "10-Full Remnant,subject to Exclusions", "value": '10' },
                    { "label": "14-Full Remnant,includes RCS,subject to Exclusions", "value": '14' },
                    { "label": "20-Limited scope,see Inclusions,subject to Exclusions", "value": '20' },
                    { "label": "24-Limited scope,includes RCS,see Inclusions", "value": '24' },
                    { "label": "30-UP only", "value": '30' },
                    { "label": "40-RCS only", "value": '40' },
                    { "label": "50-Judgment only", "value": '50' },
                    { "label": "60-Other,limited to named inclusion(s)", "value": '60' },
                    { "label": "64-Other,limited to named inclusion(s) with RCS", "value": '64' },
                    { "label": "70-Full Remnant,Unable to pursue recoveries", "value": '70' },
                    { "label": "75-Full Remnant,PWC portfolio restriction", "value": '75' },
                    { "label": "80-Corporate Remnants", "value": '80' },
                    { "label": "82-Corporate Remnants,limited scope", "value": '82' },
                    { "label": "85-Corporate Remnants,NDA", "value": '85' },
                    { "label": "87-Corporate Remnants,Sold", "value": '87' },
                    ],
                    // required: true
                },
                {
                    name: 'RingTheBellDate',
                    type: 'date',
                    placeholder: 'Ring the Bell Date',
                    label: 'Ring the Bell Date'
                }, {
                    name: 'GeneralRestrictions',
                    type: 'text',
                    maxLength: 255,
                    dealType: "BK",
                    placeholder: 'General Restrictions',
                    label: 'General Restrictions'
                },
                // {
                //     name: 'DRDealSubtype',
                //     type: 'dropdown',
                //     dealType: "BK",
                //     "options": [{ "label": "Assignment for the Benefit of Creditors (ABC)", "value": 'Assignment for the Benefit of Creditors (ABC)' }, { "label": "Chapter 7 Bankruptcy", "value": 'Chapter 7 Bankruptcy' }, { "label": "Chapter 11 Bankruptcy", "value": 'Chapter 11 Bankruptcy' }, { "label": "Receivership", "value": 'Receivership' }, { "label": "Out of Court Winddown", "value": 'Out of Court Winddown' }],
                //     placeholder: 'DR Deal Subtype',
                //     label: 'DR Deal Subtype'
                // },
                {
                    name: 'NameExpansionDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Name Expansion Date',
                    label: 'Name Expansion Date'
                },
                {
                    name: 'OPPSalesRep',
                    type: 'text',
                    maxLength: 100,
                    placeholder: 'OPP Sourcing Lead',
                    label: 'OPP Sourcing Lead'
                },
                {
                    name: 'OpportunityScore',
                    type: 'dropdown',
                    "options": [{ "label": "None", "value": '0' },
                    { "label": "1-Very Low", "value": '1' },
                    { "label": "2-Low", "value": '2' },
                    { "label": "3-Med", "value": '3' },
                    { "label": "4-High", "value": '4' }
                    ],
                    placeholder: 'Opportunity Score',
                    label: 'Opportunity Score'
                },
                {
                    name: 'NDA',
                    type: 'dropdown',
                    dealType: "CR",
                    placeholder: 'NDA',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    label: 'NDA'
                },
                {
                    name: 'OPPSaleMotionBidProcedures',
                    type: 'dropdown',
                    dealType: "BK",
                    placeholder: 'OPP Sale Motion – Bid Procedures',
                    "options": [{ "label": "Y ", "value": 'Y' }, { "label": "N", "value": 'N' }],
                    label: 'OPP Sale Motion – Bid Procedures'
                },
                {
                    name: 'BidProceduresOverbidAmount',
                    type: 'text',
                    placeholder: 'Overbid Amount',
                    label: 'Overbid Amount'
                },
                {
                    name: 'OverBidAmountNotes',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 1000,
                    placeholder: 'Overbid Amount – Notes',
                    label: 'Overbid Amount – Notes'
                },
                {
                    name: 'PEFirm',
                    type: 'text',
                    dealType: "CR",
                    maxLength: 50,
                    placeholder: 'PE Firm',
                    label: 'PE Firm'
                },
                {
                    name: 'NDANotes',
                    type: 'text',
                    maxLength: 255,
                    dealType: "CR",
                    placeholder: 'NDA Notes',
                    label: 'NDA Notes'
                },
                {
                    name: 'PEFirmNotes',
                    type: 'text',
                    dealType: "CR",
                    maxLength: 1000,
                    placeholder: 'PE Firm Notes',
                    label: 'PE Firm Notes'
                },
                {
                    name: 'EntityNamesAffidavit',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 1000,
                    placeholder: 'Entity Names Affidavit',
                    label: 'Entity Names Affidavit'
                },

                {
                    name: 'AncillaryAgreement',
                    type: 'dropdown',
                    placeholder: 'Ancillary Agreement',
                    "options": [{ "label": "Y ", "value": 'Y' }, { "label": "N", "value": 'N' }],
                    label: 'Ancillary Agreement'
                },
                {
                    name: 'AncillaryAgreementDate',

                    type: 'date',
                    placeholder: 'Ancillary Agreement Date',
                    label: 'Ancillary Agreement Date'
                },
                {
                    name: 'AncillaryAgreementTerms',
                    type: 'textarea',
                    maxLength: 2500,
                    placeholder: 'Ancillary Agreement Terms',
                    label: 'Ancillary Agreement Terms'
                },
                {
                    name: 'AncillaryAgreementType',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'Ancillary Agreement Type',
                    label: 'Ancillary Agreement Type'
                },
                // {
                //     name: 'DateOfMostRecentAffidavitReEntityNames',
                //     type: 'date',
                //     dealType: "CR",
                //     placeholder: 'Date Of Most Recent Affidavit Re Entity Names',
                //     label: 'Date Of Most Recent Affidavit Re Entity Names'
                // },
                // {
                //     name: 'SupplementalAgreementTerms',
                //     type: 'text',
                //     dealType: "CR",
                //     placeholder: 'Supplemental Agreement Terms',
                //     label: 'Supplemental Agreement Terms'
                // },
                {
                    name: 'UPDeclaration',
                    type: 'text',
                    dealType: "CR",
                    maxLength: 1000,
                    placeholder: 'UP Declaration',
                    label: 'UP Declaration'
                },
                {
                    name: 'RequiredNotificationofIdentifiedRemnants',
                    type: 'dropdown',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    dealType: "CR",
                    placeholder: 'Required Notification of Identified Remnants',
                    label: 'Required Notification of Identified Remnants'
                },
                {
                    name: 'RequiredNotificationExpirationofRequirement',
                    type: 'text',
                    dealType: "CR",
                    maxLength: 1000,
                    placeholder: 'Required Notification - Expiration of Requirement',
                    label: 'Required Notification - Expiration of Requirement'
                },
                {
                    name: 'RequiredNotificationNoticeDates',
                    type: 'date',
                    dealType: "CR",
                    maxLength: 100,
                    placeholder: 'Required Notification - Notice Dates',
                    label: 'Required Notification - Notice Dates'
                },
                {
                    name: 'RequiredNotificationNoticePeriod',
                    type: 'number',
                    dealType: "CR",
                    placeholder: 'Required Notification - Notice Period',
                    label: 'Required Notification - Notice Period'
                },
                {
                    name: 'UPAuthorizationLetterDate',
                    type: 'date',
                    dealType: "CR",
                    placeholder: 'UP Authorization Letter',
                    label: 'UP Authorization Letter'
                },
                // {
                //     name: 'UPFormsCaliforniaDate',
                //     type: 'date',
                //     placeholder: 'UP Forms – California',
                //     label: 'UP Forms – California'
                // },
                // {
                //     name: 'UPFormsTexas',
                //     type: 'text',
                //     maxLength: 100,
                //     placeholder: 'UP Forms – Texas',
                //     label: 'UP Forms – Texas'
                // },
                // {
                //     name: 'UPFormsGeneral',
                //     type: 'text',
                //     dealType: "BK",
                //     maxLength: 1000,
                //     placeholder: 'UP Forms – General',
                //     label: 'UP Forms – General'
                // },

                {
                    name: 'PertinentDealNotes',
                    type: 'text',
                    maxLength: 1000,
                    placeholder: 'Pertinent Deal Notes',
                    label: 'Pertinent Deal Notes'
                },
                // {
                //     name: 'NameExpansionDate',
                //     type: 'date',
                //     placeholder: 'Name Expansion Date',
                //     label: 'Name Expansion Date'
                // },
                {
                    name: 'KnownAssetTracking',
                    type: 'dropdown',
                    "options": [{ "label": "Y - Added", "value": 'Y' }, { "label": "Y - Not Added", "value": 'N' }],
                    placeholder: 'Known Asset Tracking',
                    label: 'Known Asset Tracking'
                },



                {
                    name: 'UPFormsSignatoryIDDate',
                    type: 'text',
                    dealType: "CR",
                    maxLength: 100,
                    placeholder: "UP Forms – Signatory ID",
                    label: "UP Forms – Signatory ID"
                },
                {
                    name: 'SaleMotionDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Sale Motion Date',
                    label: 'Sale Motion Date'
                },
                {
                    name: 'SaleOrderDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Sale Order Date',
                    label: 'Sale Order Date'
                },
                {
                    name: 'SaleNoticeDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Sale Notice Date',
                    label: 'Sale Notice Date'
                },
                {
                    name: 'SaleReportDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Sale Report Date',
                    label: 'Sale Report Date'
                },

                {
                    name: 'CitationToSaleOrder',
                    type: 'text',
                    maxLength: 1000,
                    dealType: "BK",
                    placeholder: 'Citation to Sale Order',
                    label: 'Citation to Sale Order'
                },
                {
                    name: 'TentativeSaleOrder',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Tentative Sale Order',
                    label: 'Tentative Sale Order'
                },
                {
                    name: 'CitationToSaleNotice',
                    type: 'text',
                    maxLength: 1000,
                    dealType: "BK",
                    placeholder: 'Citation to Sale Notice',
                    label: 'Citation to Sale Notice'
                },
                {
                    name: 'CitationToSaleReport',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 1000,
                    placeholder: 'Citation to Sale Report',
                    label: 'Citation to Sale Report'
                },
                {
                    name: 'OPPSaleCompetition',
                    type: 'dropdown',
                    dealType: "BK",
                    placeholder: 'OPP Sale Competition',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    label: 'OPP Sale Competition'
                },
                {
                    name: 'OPPSaleCompetitionNotes',
                    type: 'text',
                    maxLength: 1600,
                    dealType: "BK",
                    placeholder: 'OPP Sale Competition Notes',
                    label: 'OPP Sale Competition Notes'
                },
                {
                    name: 'OPPSaleCompetitor',
                    type: 'text',
                    maxLength: 100,
                    dealType: "BK",
                    placeholder: 'OPP Sale Competitor',
                    label: 'OPP Sale Competitor'
                },
                {
                    name: 'OPPSaleObjection',
                    type: 'dropdown',
                    dealType: "BK",
                    placeholder: 'OPP Sale Objection',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    label: 'OPP Sale Objection'
                },
                {
                    name: 'OPPSaleObjector',
                    type: 'text',
                    maxLength: 100,
                    dealType: "BK",
                    placeholder: 'OPP Sale Objector',
                    label: 'OPP Sale Objector'
                },
                {
                    name: 'OPPSaleObjectionNotes',
                    type: 'text',
                    maxLength: 1000,
                    dealType: "BK",
                    placeholder: 'OPP Sale Objection Notes',
                    label: 'OPP Sale Objection Notes'
                },

                {
                    name: 'FormerCWRecoveryClient',
                    type: 'dropdown',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    placeholder: 'Former CW Recovery Client',
                    label: 'Former CW Recovery Client'
                },
                {
                    name: 'DealNotes1',
                    type: 'text',
                    maxLength: 255,
                    placeholder: 'Pertinent Deal Notes',
                    label: 'Pertinent Deal Notes'
                },
                // {
                //     name: 'DRDealAPAType',
                //     type: 'text',
                //     dealType: "BK",
                //     maxLength: 50,
                //     placeholder: 'DR Deal APA Type',
                //     label: 'DR Deal APA Type'
                // },
                // {
                //     name: 'DealNotes2',
                //     type: 'text',
                //     placeholder: 'Deal Notes 2',
                //     label: 'Deal Notes 2'
                // },
                {
                    name: 'DealAnnouncementApproval',
                    type: 'dropdown',
                    options: [{ label: "yes", value: "Y" }, { label: "No", value: "N" }, { label: "Pending", value: "P" }],
                    dealType: "BK",
                    placeholder: 'Deal Announcement Approval',
                    label: 'Deal Announcement Approval'
                },
                {
                    name: 'DeaAnnouncementApprovalDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Deal Announcement Approval Date',
                    label: 'Deal Announcement Approval Date'
                },
                {
                    name: 'DealAnnouncementNote',
                    type: 'text',
                    maxLength: 255,
                    dealType: "BK",
                    placeholder: 'Deal Announcement Note',
                    label: 'Deal Announcement Note'
                },
                {
                    name: 'OkForWebsite',
                    type: 'text',
                    maxLength: 100,
                    dealType: "BK",
                    placeholder: 'Ok for Website',
                    label: 'Ok for Website'
                },
                {
                    name: 'OkForWebsiteDate',
                    type: 'date',
                    dealType: "BK",
                    placeholder: 'Ok for Website Date',
                    label: 'Ok for Website Date'
                },
                {
                    name: 'OkForWebsiteSource',
                    type: 'text',
                    maxLength: 100,
                    dealType: "BK",
                    placeholder: 'Ok for Website Source',
                    label: 'Ok for Website Source'
                },
                {
                    name: 'ClassActionRestrictions',
                    type: 'text',
                    maxLength: 100,
                    placeholder: 'Class Action Restrictions',
                    label: 'Class Action Restrictions'
                },
                {
                    name: 'AuthorityReviewNotes_DismissalsOtherTypes',
                    type: 'text',
                    maxLength: 255,
                    dealType: "BK",
                    placeholder: 'Authority Review Notes Dismissals Other Types',
                    label: 'Authority Review Notes Dismissals Other Types'
                },
                {
                    name: 'AuthorityReviewNotes_ApplicablePlanProvisions',
                    type: 'text',
                    dealType: "BK",
                    maxLength: 255,
                    placeholder: 'Authority Review Notes Applicable Plan Provisions',
                    label: 'Authority Review Notes Applicable Plan Provisions'
                },
                {
                    name: 'ClaimabilityCode',
                    type: 'text',
                    maxLength: 10,
                    placeholder: 'Claimability Code',
                    label: 'Claimability Code'
                },
                {
                    name: 'ClaimabilityNotes',
                    type: 'text',
                    maxLength: 255,
                    placeholder: 'Claimability Notes',
                    label: 'Claimability Notes'
                },
            ],
            getOverviewTabFields: [
                // {
                //     name: 'EntityName',
                //     type: 'text',
                //     placeholder: 'Entity Name',
                //     maxLength: 255,
                //     label: 'Entity Name',
                //     required: true
                // },


                // {
                //     name: 'District',
                //     type: 'text',
                //     placeholder: 'Jurisdiction',
                //     dealType: "BK",
                //     label: 'Jurisdiction',
                //     // required: true
                // },
                // {
                //     name: 'Division',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Division',
                //     label: 'Division',
                //     // required: true
                // },
                // {
                //     name: 'BankruptcyChapter',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'BK Chapter',
                //     label: 'BK Chapter',
                //     required: true
                // },
                // {
                //     name: 'BankruptcyChapterConversion',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Bankruptcy Chapter Conversion',
                //     label: 'Bankruptcy Chapter Conversion',

                // },
                // {
                //     name: 'BankruptcyChapterConversionDate',
                //     type: 'date',
                //     dealType: "BK",
                //     placeholder: 'Bankruptcy Chapter Conversion Date',
                //     label: 'Bankruptcy Chapter Conversion Date',
                //     required: true
                // },

                // {
                //     name: 'BankruptcyFilingDate',
                //     type: 'date',
                //     dealType: "BK",
                //     placeholder: 'BK Filing Date',
                //     label: 'BK Filing Date',
                //     required: true
                // },




                // {
                //     name: 'SellerCompanyID',
                //     type: 'text',
                //     placeholder: 'Seller Company ID',
                //     label: 'Seller Company ID',
                //     required: true
                // },

                // {
                //     name: 'OPPSalesRep',
                //     type: 'text',
                //     placeholder: 'OPP Sourcing Lead',
                //     label: 'OPP Sourcing Lead',
                //     required: true
                // },
                {
                    name: 'OPPSalesRep1',
                    type: 'text1',
                    isDisable: true,
                    fixed: true,
                    placeholder: 'OPP Sourcing Lead',
                    label: 'OPP Sourcing Lead',
                    // required: true
                },

                // {
                //     name: 'CaseNumber',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Case Number',
                //     label: 'Case Number',
                //     required: true
                // },

                // {
                //     name: 'businessEntity',
                //     type: 'text',
                //     placeholder: 'Main Business Entity',
                //     label: 'Main Business Entity'
                // },
                {
                    name: 'Industries',
                    type: 'text',
                    maxLength: 100,
                    placeholder: 'Industries',
                    label: 'Industries'
                },
                {
                    name: 'CompanyWebsite',
                    type: 'text',
                    maxLength: 500,
                    placeholder: 'Company Website',
                    label: 'Company Website'
                },

                {
                    name: 'CompanyDescription',
                    type: 'text',
                    maxLength: 6000,
                    placeholder: 'Company Description',
                    label: 'Company Description'
                },
                // {
                //     name: 'stateofIncorporationIncluded',
                //     type: 'dropdown',
                //     placeholder: 'State Excluded For State of Incorporation',
                //     "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                //     label: 'State Excluded For State of Incorporation'
                // },
                {
                    name: 'StateofIncorporation',
                    type: 'dropdown',
                    include: "true",
                    options: [
                        { label: "Alabama - AL", value: "Alabama - AL", show: false },
                        { label: "Alaska - AK", value: "Alaska - AK", show: false },
                        { label: "Arizona - AZ", value: "Arizona - AZ", show: false },
                        { label: "Arkansas - AR", value: "Arkansas - AR", show: false },
                        { label: "California - CA", value: "California - CA", show: false },
                        { label: "Colorado - CO", value: "Colorado - CO", show: false },
                        { label: "Connecticut - CT", value: "Connecticut - CT", show: false },
                        { label: "Delaware - DE", value: "Delaware - DE", show: false },
                        { label: "Florida - FL", value: "Florida - FL", show: false },
                        { label: "Georgia - GA", value: "Georgia - GA", show: false },
                        { label: "Hawaii - HI", value: "Hawaii - HI", show: false },
                        { label: "Idaho - ID", value: "Idaho - ID", show: false },
                        { label: "Illinois - IL", value: "Illinois - IL", show: false },
                        { label: "Indiana - IN", value: "Indiana - IN", show: false },
                        { label: "Iowa - IA", value: "Iowa - IA", show: false },
                        { label: "Kansas - KS", value: "Kansas - KS", show: false },
                        { label: "Kentucky - KY", value: "Kentucky - KY", show: false },
                        { label: "Louisiana - LA", value: "Louisiana - LA", show: false },
                        { label: "Maine - ME", value: "Maine - ME", show: false },
                        { label: "Maryland - MD", value: "Maryland - MD", show: false },
                        { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false },
                        { label: "Michigan - MI", value: "Michigan - MI", show: false },
                        { label: "Minnesota - MN", value: "Minnesota - MN", show: false },
                        { label: "Mississippi - MS", value: "Mississippi - MS", show: false },
                        { label: "Missouri - MO", value: "Missouri - MO", show: false },
                        { label: "Montana - MT", value: "Montana - MT", show: false },
                        { label: "Nebraska - NE", value: "Nebraska - NE", show: false },
                        { label: "Nevada - NV", value: "Nevada - NV", show: false },
                        { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false },
                        { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false },
                        { label: "New Mexico - NM", value: "New Mexico - NM", show: false },
                        { label: "New York - NY", value: "New York - NY", show: false },
                        { label: "North Carolina - NC", value: "North Carolina - NC", show: false },
                        { label: "North Dakota - ND", value: "North Dakota - ND", show: false },
                        { label: "Ohio - OH", value: "Ohio - OH", show: false },
                        { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false },
                        { label: "Oregon - OR", value: "Oregon - OR", show: false },
                        { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false },
                        { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false },
                        { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false },
                        { label: "South Carolina - SC", value: "South Carolina - SC", show: false },
                        { label: "South Dakota - SD", value: "South Dakota - SD", show: false },
                        { label: "Tennessee - TN", value: "Tennessee - TN", show: false },
                        { label: "Texas - TX", value: "Texas - TX", show: false },
                        { label: "Utah - UT", value: "Utah - UT", show: false },
                        { label: "Vermont - VT", value: "Vermont - VT", show: false },
                        { label: "Virginia - VA", value: "Virginia - VA", show: false },
                        { label: "Washington - DC", value: "Washington - DC", show: false },
                        { label: "Washington - WA", value: "Washington - WA", show: false },
                        { label: "West Virginia - WV", value: "West Virginia - WV", show: false },
                        { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false },
                        { label: "Wyoming - WY", value: "Wyoming - WY", show: false },
                    ],
                    placeholder: 'State of Incorporation',
                    label: 'State of Incorporation'
                },
                {
                    name: 'StateofIncorporation',
                    type: 'text',
                    include: "false",
                    placeholder: 'State of Incorporation',
                    label: 'State of Incorporation'
                },
                {
                    name: 'HeadquartersAddress',
                    type: 'text',
                    maxLength: 255,
                    placeholder: 'Headquarters Address',
                    label: 'Headquarters Address'
                },
                // {
                //     name: 'headquartersStateExcluded',
                //     type: 'dropdown',
                //     placeholder: 'State Excluded For Headquarters State',
                //     "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                //     label: 'State Excluded For Headquarters State'
                // },
                {
                    name: 'HeadquartersState',
                    type: 'dropdown',
                    include1: "true",
                    options: [
                        { label: "Alabama - AL", value: "Alabama - AL", show: false },
                        { label: "Alaska - AK", value: "Alaska - AK", show: false },
                        { label: "Arizona - AZ", value: "Arizona - AZ", show: false },
                        { label: "Arkansas - AR", value: "Arkansas - AR", show: false },
                        { label: "California - CA", value: "California - CA", show: false },
                        { label: "Colorado - CO", value: "Colorado - CO", show: false },
                        { label: "Connecticut - CT", value: "Connecticut - CT", show: false },
                        { label: "Delaware - DE", value: "Delaware - DE", show: false },
                        { label: "Florida - FL", value: "Florida - FL", show: false },
                        { label: "Georgia - GA", value: "Georgia - GA", show: false },
                        { label: "Hawaii - HI", value: "Hawaii - HI", show: false },
                        { label: "Idaho - ID", value: "Idaho - ID", show: false },
                        { label: "Illinois - IL", value: "Illinois - IL", show: false },
                        { label: "Indiana - IN", value: "Indiana - IN", show: false },
                        { label: "Iowa - IA", value: "Iowa - IA", show: false },
                        { label: "Kansas - KS", value: "Kansas - KS", show: false },
                        { label: "Kentucky - KY", value: "Kentucky - KY", show: false },
                        { label: "Louisiana - LA", value: "Louisiana - LA", show: false },
                        { label: "Maine - ME", value: "Maine - ME", show: false },
                        { label: "Maryland - MD", value: "Maryland - MD", show: false },
                        { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false },
                        { label: "Michigan - MI", value: "Michigan - MI", show: false },
                        { label: "Minnesota - MN", value: "Minnesota - MN", show: false },
                        { label: "Mississippi - MS", value: "Mississippi - MS", show: false },
                        { label: "Missouri - MO", value: "Missouri - MO", show: false },
                        { label: "Montana - MT", value: "Montana - MT", show: false },
                        { label: "Nebraska - NE", value: "Nebraska - NE", show: false },
                        { label: "Nevada - NV", value: "Nevada - NV", show: false },
                        { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false },
                        { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false },
                        { label: "New Mexico - NM", value: "New Mexico - NM", show: false },
                        { label: "New York - NY", value: "New York - NY", show: false },
                        { label: "North Carolina - NC", value: "North Carolina - NC", show: false },
                        { label: "North Dakota - ND", value: "North Dakota - ND", show: false },
                        { label: "Ohio - OH", value: "Ohio - OH", show: false },
                        { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false },
                        { label: "Oregon - OR", value: "Oregon - OR", show: false },
                        { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false },
                        { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false },
                        { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false },
                        { label: "South Carolina - SC", value: "South Carolina - SC", show: false },
                        { label: "South Dakota - SD", value: "South Dakota - SD", show: false },
                        { label: "Tennessee - TN", value: "Tennessee - TN", show: false },
                        { label: "Texas - TX", value: "Texas - TX", show: false },
                        { label: "Utah - UT", value: "Utah - UT", show: false },
                        { label: "Vermont - VT", value: "Vermont - VT", show: false },
                        { label: "Virginia - VA", value: "Virginia - VA", show: false },
                        { label: "Washington - DC", value: "Washington - DC", show: false },
                        { label: "Washington - WA", value: "Washington - WA", show: false },
                        { label: "West Virginia - WV", value: "West Virginia - WV", show: false },
                        { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false },
                        { label: "Wyoming - WY", value: "Wyoming - WY", show: false },
                    ],
                    placeholder: 'Headquarters State',
                    label: 'Headquarters State'
                },
                {
                    name: 'HeadquartersState',
                    type: 'text',
                    include1: "false",
                    placeholder: 'Headquarters State',
                    label: 'Headquarters State'
                },
                // {
                //     name: 'keyAdresses',
                //     type: 'text',
                //     placeholder: 'Key Addresses',
                //     label: 'Key Addresses'
                // },
                {
                    name: 'UsLocations',
                    type: 'multiselect',
                    options: [
                        { label: "Alabama - AL", value: "AL", show: false },
                        { label: "Alaska - AK", value: "AK", show: false },
                        { label: "Arizona - AZ", value: "AZ", show: false },
                        { label: "Arkansas - AR", value: "AR", show: false },
                        { label: "California - CA", value: "CA", show: false },
                        { label: "Colorado - CO", value: "CO", show: false },
                        { label: "Connecticut - CT", value: "CT", show: false },
                        { label: "Delaware - DE", value: "DE", show: false },
                        { label: "Florida - FL", value: "FL", show: false },
                        { label: "Georgia - GA", value: "GA", show: false },
                        { label: "Hawaii - HI", value: "HI", show: false },
                        { label: "Idaho - ID", value: "ID", show: false },
                        { label: "Illinois - IL", value: "IL", show: false },
                        { label: "Indiana - IN", value: "IN", show: false },
                        { label: "Iowa - IA", value: "IA", show: false },
                        { label: "Kansas - KS", value: "KS", show: false },
                        { label: "Kentucky - KY", value: "KY", show: false },
                        { label: "Louisiana - LA", value: "LA", show: false },
                        { label: "Maine - ME", value: "ME", show: false },
                        { label: "Maryland - MD", value: "MD", show: false },
                        { label: "Massachusetts - MA", value: "MA", show: false },
                        { label: "Michigan - MI", value: "MI", show: false },
                        { label: "Minnesota - MN", value: "MN", show: false },
                        { label: "Mississippi - MS", value: "MS", show: false },
                        { label: "Missouri - MO", value: "MO", show: false },
                        { label: "Montana - MT", value: "MT", show: false },
                        { label: "Nebraska - NE", value: "NE", show: false },
                        { label: "Nevada - NV", value: "NV", show: false },
                        { label: "New Hampshire - NH", value: "NH", show: false },
                        { label: "New Jersey - NJ", value: "NJ", show: false },
                        { label: "New Mexico - NM", value: "NM", show: false },
                        { label: "New York - NY", value: "NY", show: false },
                        { label: "North Carolina - NC", value: "NC", show: false },
                        { label: "North Dakota - ND", value: "ND", show: false },
                        { label: "Ohio - OH", value: "OH", show: false },
                        { label: "Oklahoma - OK", value: "OK", show: false },
                        { label: "Oregon - OR", value: "OR", show: false },
                        { label: "Pennsylvania - PA", value: "PA", show: false },
                        { label: "Puerto Rico - PR", value: "PR", show: false },
                        { label: "Rhode Island - RI", value: "RI", show: false },
                        { label: "South Carolina - SC", value: "SC", show: false },
                        { label: "South Dakota - SD", value: "SD", show: false },
                        { label: "Tennessee - TN", value: "TN", show: false },
                        { label: "Texas - TX", value: "TX", show: false },
                        { label: "Utah - UT", value: "UT", show: false },
                        { label: "Vermont - VT", value: "VT", show: false },
                        { label: "Virginia - VA", value: "VA", show: false },
                        { label: "Washington - DC", value: "DC", show: false },
                        { label: "Washington - WA", value: "WA", show: false },
                        { label: "West Virginia - WV", value: "WV", show: false },
                        { label: "Wisconsin - WI", value: "WI", show: false },
                        { label: "Wyoming - WY", value: "WY", show: false },
                    ],
                    placeholder: 'U.S. Locations',
                    label: 'U.S. Locations'
                },
                // {
                //     name: 'keyEntityNames',
                //     type: 'text',
                //     placeholder: 'Key Entity Names',
                //     label: 'Key Entity Names'
                // },
                {
                    name: 'InternationalLocations',
                    type: 'text',
                    placeholder: 'International Locations',
                    label: 'International Locations'
                },
                {
                    name: 'PublicCompany',
                    type: 'dropdown',
                    placeholder: 'Public Company?',
                    "options": [{ "label": "Yes", "value": 'Y' }, { "label": "No", "value": 'N' }],
                    label: 'Public Company?'
                },

                {
                    name: 'NumberofLocations',
                    type: 'number',
                    placeholder: 'Number of Locations',
                    label: 'Number of Locations'
                },
                {
                    name: 'CIKCode',
                    type: 'number',
                    placeholder: 'CIK Code',
                    label: 'CIK Code'
                },
                {
                    name: 'LocationNotes',
                    type: 'text',
                    maxLength: 100,
                    placeholder: 'Location Notes',
                    label: 'Location Notes'
                },
                {
                    name: 'SICCode',
                    type: 'multiselect',
                    // options: this.state.sicCodes,
                    placeholder: 'SIC Code',
                    label: 'SIC Code'
                },
                {
                    name: 'Revenue',
                    type: 'number',
                    placeholder: 'Revenue',
                    label: 'Revenue'
                },
                {
                    name: 'NAICSCode',
                    type: 'number',
                    placeholder: 'NAICS Code',
                    label: 'NAICS Code'
                },
                {
                    name: 'RevenueYear',
                    type: 'number',
                    placeholder: 'Revenue Year',
                    label: 'Revenue Year'
                },
                {
                    name: 'DUNS',
                    maxLength: 30,
                    type: 'text',
                    placeholder: 'DUNS',
                    label: 'DUNS'
                },
                {
                    name: 'EmployeeCount',
                    type: 'number',
                    placeholder: 'Employee Count',
                    label: 'Employee Count'
                },
                {
                    name: 'EmployeeCountYear',
                    type: 'number',
                    placeholder: 'Employee Count Year',
                    label: 'Employee Count Year'
                },


                // {
                //     name: 'nameSource',
                //     type: 'text',
                //     placeholder: 'Name Source',
                //     label: 'Name Source'
                // },
                // {
                //     name: 'connectedEntity',
                //     type: 'text',
                //     placeholder: 'Connected Entity',
                //     label: 'Connected Entity'
                // },
                // {
                //     name: 'connectionType',
                //     type: 'text',
                //     placeholder: 'Connection Type',
                //     label: 'Connection Type'
                // },
                {
                    name: 'ApaPurchasePrice',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'APA Purchase Price',
                    label: 'APA Purchase Price'
                },

                // {
                //     name: 'category',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Category',
                //     label: 'Category'
                // },


                {
                    name: 'EIN1',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'EIN 1',
                    label: 'EIN 1'
                },
                {
                    name: 'EIN1Source',
                    type: 'text',
                    maxLength: 50,
                    placeholder: 'EIN 1 Source',
                    label: 'EIN 1 Source'
                },
                {
                    name: 'DateEIN1Added',
                    type: 'date',
                    placeholder: 'Date EIN 1 Added',
                    label: 'Date EIN 1 Added'
                },
                // {
                //     name: 'EIN2',
                //     type: 'text',
                //     maxLength: 50,
                //     dealType: "BK",
                //     placeholder: 'EIN 2',
                //     label: 'EIN 2'
                // },
                // {
                //     name: 'EIN2Source',
                //     type: 'text',
                //     dealType: "BK",
                //     maxLength: 50,
                //     placeholder: 'EIN 2 Source',
                //     label: 'EIN 2 Source'
                // },
                // {
                //     name: 'EIN3',
                //     type: 'text',
                //     maxLength: 50,
                //     dealType: "BK",
                //     placeholder: 'EIN 3',
                //     label: 'EIN 3'
                // },
                // {
                //     name: 'EIN3Source',
                //     type: 'text',
                //     dealType: "BK",
                //     maxLength: 50,
                //     placeholder: 'EIN 3 Source',
                //     label: 'EIN 3 Source'
                // },
                // {
                //     name: 'generalNotes',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'General Notes',
                //     label: 'General Notes'
                // },

                // {
                //     name: 'category1Notes',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Category 1 Notes',
                //     label: 'Category 1 Notes'
                // },
                // {
                //     name: 'category2Notes',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Category 2 Notes',
                //     label: 'Category 2 Notes'
                // },
                {
                    name: 'JudgmentFees',
                    type: 'number',
                    dealType: "BK",
                    placeholder: 'Judgment-Fees',
                    label: 'Judgment-Fees'
                },
                // {
                //     name: 'judgmentPreJudgmentInterest',
                //     type: 'text',
                //     dealType: "BK",
                //     placeholder: 'Judgment-Pre Judgment Interest',
                //     label: 'Judgment-Pre Judgment Interest'
                // },
                {
                    name: 'ConnectionTypeYear',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'Connection Type Year',
                    label: 'Connection Type Year'
                },
                // {
                //     name: 'dateEntityNameAddedorEdited',
                //     type: 'date',
                //     dealType: "BK",
                //     placeholder: 'Date Entity Name Added or Edited',
                //     label: 'Date Entity Name Added or Edited'
                // },
                {
                    name: 'OperatingDates',
                    type: 'text',
                    maxLength: 50,
                    dealType: "BK",
                    placeholder: 'Operating Dates',
                    label: 'Operating Dates'
                },
                // {
                //     name: 'dateRestrictions',
                //     type: 'date',
                //     dealType: "BK",
                //     placeholder: 'Date Restrictions',
                //     label: 'Date Restrictions'
                // },

                // {
                //     name: 'DateEIN2Added',
                //     type: 'date',
                //     dealType: "BK",
                //     placeholder: 'Date EIN 2 Added',
                //     label: 'Date EIN 2 Added'
                // },
                // {
                //     name: 'DateEIN3Added',
                //     type: 'date',
                //     dealType: "BK",
                //     placeholder: 'Date EIN 3 Added',
                //     label: 'Date EIN 3 Added'
                // },
                // {
                //     name: 'JudgmentDateEntered',
                //     type: 'date',
                //     dealType: "BK",
                //     placeholder: 'Judgment - Date Entered',
                //     label: 'Judgment - Date Entered'
                // },
                // {
                //     name: 'JudgmentInterestAccrualStartDate',
                //     type: 'date',
                //     dealType: "BK",
                //     placeholder: 'Judgment-Interest Accrual Start Date',
                //     label: 'Judgment-Interest Accrual Start Date'
                // },
                // {
                //     name: 'JudgmentPreJudgmentInterestRate',
                //     type: 'number',
                //     dealType: "BK",
                //     placeholder: 'Judgment-Pre Judgment Interest Rate',
                //     label: 'Judgment-Pre Judgment Interest Rate'
                // },
                // {
                //     name: 'JudgmentOtherInterestRate',
                //     type: 'number',
                //     dealType: "BK",
                //     placeholder: 'Judgment-Other Interest Rate',
                //     label: 'Judgment-Other Interest Rate'
                // },
                // {
                //     name: 'JudgmentOutstandingClaimValue',
                //     type: 'number',
                //     dealType: "BK",
                //     placeholder: 'Judgment-Outstanding Claim Value',
                //     label: 'Judgment-Outstanding Claim Value'
                // },
                // {
                //     name: 'JudgmentRemainingJudgmentValuetoClaim',
                //     type: 'number',
                //     dealType: "BK",
                //     placeholder: 'Judgment-Remaining Judgment Value to Claim',
                //     label: 'Judgment-Remaining Judgment Value to Claim'
                // },
                // {
                //     name: 'JudgmentCourt',
                //     type: 'text',
                //     dealType: "BK",
                //     maxLength: 50,
                //     placeholder: 'Judgment-Court',
                //     label: 'Judgment-Court'
                // },
                // {
                //     name: 'JudgmentInterestClaimed',
                //     type: 'number',
                //     dealType: "BK",
                //     placeholder: 'Judgment-Interest Claimed',
                //     label: 'Judgment-Interest Claimed'
                // },
                // {
                //     name: 'JudgmentAccruedInterestatTimeofPayment',
                //     type: 'number',
                //     dealType: "BK",
                //     placeholder: 'Judgment-Accrued Interest at Time of Payment',
                //     label: 'Judgment-Accrued Interest at Time of Payment'
                // },
                // {
                //     name: 'JudgmentCourtType',
                //     type: 'text',
                //     maxLength: 50,
                //     dealType: "BK",
                //     placeholder: 'Judgment-Court Type',
                //     label: 'Judgment-Court Type'
                // },
                // {
                //     name: 'JudgmentDistrict',
                //     type: 'text',
                //     dealType: "BK",
                //     maxLength: 50,
                //     placeholder: 'Judgment-District',
                //     label: 'Judgment-District'
                // },
                // {
                //     name: 'JudgmentDistrictType',
                //     type: 'text',
                //     dealType: "BK",
                //     maxLength: 50,
                //     placeholder: 'Judgment-District Type',
                //     label: 'Judgment-District Type'
                // },
                // {
                //     name: 'JudgmentPlaceholder',
                //     type: 'text',
                //     maxLength: 50,
                //     dealType: "BK",
                //     placeholder: 'Judgment-Placeholder',
                //     label: 'Judgment-Placeholder'
                // },
                // {
                //     name: 'JudgmentDistrictCounty',
                //     type: 'text',
                //     maxLength: 50,
                //     dealType: "BK",
                //     placeholder: 'Judgment-District County',
                //     label: 'Judgment-District County'
                // },
                // {
                //     name: 'JudgmentCountofDebtorNames',
                //     type: 'text',
                //     maxLength: 255,
                //     dealType: "BK",
                //     placeholder: 'Judgment-Count of Debtor Names',
                //     label: 'Judgment-Count of Debtor Names'
                // },
                // {
                //     name: 'JudgmentMiscCaseNumbers',
                //     type: 'text',
                //     maxLength: 50,
                //     dealType: "BK",
                //     placeholder: 'Judgment-Misc Case Numbers',
                //     label: 'Judgment-Misc Case Numbers'
                // },
                // {
                //     name: 'JudgmentRegistrationStates',
                //     type: 'text',
                //     maxLength: 50,
                //     dealType: "BK",
                //     placeholder: 'Judgment-Registration States',
                //     label: 'Judgment-Registration States'
                // },
            ],
            filterCriteria: { criteria: [] },
            checklists: [],
            key: 0,
            selectedStatus: [],
            tempCheckList: [],
            isSelectedData: [],
            activeTab: "1",
            showNames: true,
            errorResponse: "",
            isShowChecklistError: false,
            isSplittedStates: [],
            ismoveAll: false,
            additionalselectedStates: [],
            statestab2Data: [],
            selectedValues2: [],
            downtoleft: [],
            getValues1: [],
            getValues: [],
            ismoveAlltoleft: false,
            upCount: 0,
            downCount: 0,
            leftCount: 0,
            rightCount: 0,
            actions: [],
            isSelectAll: false,
            nameConnection: [
                { label: "Acquisition", value: "Acquisition" },
                { label: "Conversion", value: "Conversion" },
                { label: "Division", value: "Division" },
                { label: "Former Name", value: "Former Name" },
                { label: "Joint Debtor", value: "Joint Debtor" },
                { label: "Judgment Debtor", value: "Judgment Debtor" },
                { label: "Lead Debtor", value: "Lead Debtor" },
                { label: "Name Variation", value: "Name Variation" },
                { label: "Oak Point APA Lead Seller", value: "Oak Point APA Lead Seller" },
                { label: "Oak Point APA Seller Firm", value: "Oak Point APA Seller Firm" },
                { label: "Oak Point APA Seller Signatory", value: "Oak Point APA Seller Signatory" },
                { label: "Parent Company", value: "Parent Company" },
                { label: "Subsidiary", value: "Subsidiary" },
                { label: "Trade Name", value: "Trade Name" },
                { label: "Unconfirmed", value: "Unconfirmed" },
                { label: "Unrelated", value: "Unrelated" },
            ],
            issues: [
                { label: "Unknown", value: "Unknown" },
                { label: "0-No Issues Identified", value: "0-No Issues Identified" },
                { label: "1-Issue Identified", value: "1-Issue Identified" },
                { label: "2-Unable to Pursue", value: "2-Unable to Pursue" },
                { label: "3-Connection to case", value: "3-Connection to case" },
                { label: "7-Escalate if Assets", value: "7-Escalate if Assets" },

            ],
            priority: [
                { label: "Critical", value: "Critical" },
                { label: "High", value: "High" },
                { label: "Medium", value: "Medium" },
                { label: "Low", value: "Low" }],
            states: [
                { label: "Alabama - AL", value: "Alabama - AL", show: false },
                { label: "Alaska - AK", value: "Alaska - AK", show: false },
                { label: "Arizona - AZ", value: "Arizona - AZ", show: false },
                { label: "Arkansas - AR", value: "Arkansas - AR", show: false },
                { label: "California - CA", value: "California - CA", show: false },
                { label: "Colorado - CO", value: "Colorado - CO", show: false },
                { label: "Connecticut - CT", value: "Connecticut - CT", show: false },
                { label: "Delaware - DE", value: "Delaware - DE", show: false },
                { label: "Florida - FL", value: "Florida - FL", show: false },
                { label: "Georgia - GA", value: "Georgia - GA", show: false },
                { label: "Hawaii - HI", value: "Hawaii - HI", show: false },
                { label: "Idaho - ID", value: "Idaho - ID", show: false },
                { label: "Illinois - IL", value: "Illinois - IL", show: false },
                { label: "Indiana - IN", value: "Indiana - IN", show: false },
                { label: "Iowa - IA", value: "Iowa - IA", show: false },
                { label: "Kansas - KS", value: "Kansas - KS", show: false },
                { label: "Kentucky - KY", value: "Kentucky - KY", show: false },
                { label: "Louisiana - LA", value: "Louisiana - LA", show: false },
                { label: "Maine - ME", value: "Maine - ME", show: false },
                { label: "Maryland - MD", value: "Maryland - MD", show: false },
                { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false },
                { label: "Michigan - MI", value: "Michigan - MI", show: false },
                { label: "Minnesota - MN", value: "Minnesota - MN", show: false },
                { label: "Mississippi - MS", value: "Mississippi - MS", show: false },
                { label: "Missouri - MO", value: "Missouri - MO", show: false },
                { label: "Montana - MT", value: "Montana - MT", show: false },
                { label: "Nebraska - NE", value: "Nebraska - NE", show: false },
                { label: "Nevada - NV", value: "Nevada - NV", show: false },
                { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false },
                { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false },
                { label: "New Mexico - NM", value: "New Mexico - NM", show: false },
                { label: "New York - NY", value: "New York - NY", show: false },
                { label: "North Carolina - NC", value: "North Carolina - NC", show: false },
                { label: "North Dakota - ND", value: "North Dakota - ND", show: false },
                { label: "Ohio - OH", value: "Ohio - OH", show: false },
                { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false },
                { label: "Oregon - OR", value: "Oregon - OR", show: false },
                { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false },
                { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false },
                { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false },
                { label: "South Carolina - SC", value: "South Carolina - SC", show: false },
                { label: "south Dakota - SD", value: "South Dakota - SD", show: false },
                { label: "tennessee - TN", value: "Tennessee - TN", show: false },
                { label: "Texas - TX", value: "Texas - TX", show: false },
                { label: "Utah - UT", value: "Utah - UT", show: false },
                { label: "Vermont - VT", value: "Vermont - VT", show: false },
                { label: "Virginia - VA", value: "Virginia - VA", show: false },
                { label: "Washington - DC", value: "Washington - DCPuerto Rico", show: false },
                { label: "Washington - WA", value: "Washington - WA", show: false },
                { label: "West Virginia - WV", value: "West Virginia - WV", show: false },
                { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false },
                { label: "Wyoming - WY", value: "Wyoming - WY", show: false },
            ],
            comments: [],
            isSplitData: [],
            lengthNote: 0,
            taskNote: "",
            last2Notes: [],
            sicCodes: [],
            showFields: [],
            otherStates: [],
            issplitAll: false,
            tableFields: '',
            selectedValues: [],
            totalFields: [],
            exportData: [],
            apierrorResponse: false,
            disabledField: false,
            assignedValue: false,
            issplitbyStatus: false,
        };
    }

    componentDidUpdate() {
        if (this.state.apierrorResponse) {
            setTimeout(() => this.setState({ apierrorResponse: false, apierrorMessage: '' }), 10000);
        }
        if (this.state.successResponse) {
            setTimeout(() => this.setState({ successResponse: false, successMessage: '' }), 10000);
        }
        if (this.state.apisuccResponse) {
            setTimeout(() => this.setState({ apisuccResponse: false, apisuccMessage: '' }), 10000);
        }
        if (this.state.copiedA) {
            setTimeout(() => this.setState({ copiedA: false }), 10000);
        }
        if (this.state.moveExhibitA) {
            setTimeout(() => this.setState({ moveExhibitA: false }), 10000);
        }
        if (this.state.moveExhibitB) {
            setTimeout(() => this.setState({ moveExhibitB: false }), 10000);
        }
        if (this.state.copiedB) {
            setTimeout(() => this.setState({ copiedB: false }), 10000);
        }
        // if (localStorage.getItem('loggedTime')) {
        //     let loggedTime = localStorage.getItem('loggedTime')
        //     if (timeout) {
        //         clearTimeout(timeout)
        //     }
        //     timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
        // }
        // this.getTab2Body()
    }

    /**
     * 
     * @param {*} tab 
     * @param {*} type 
     * setting the tab to which the user changes
     */
    toggle = async (tab, type) => {
        if (this.state.activeTab !== tab || type) {
            await this.setState({
                activeTab: tab,
                apierrorResponse: false,
                Category: "",
                apisuccResponse: false,
                SubCategory: "",
                selectedRows: "",
                EnteredBy: "",
                EnteredByFilter: false,
                CategoryFilter: false,
                SubCategoryFilter: false,
                addressnames: [],
                addresses: [],
                // isLoading: true
            });
        }
    }
    /**
     * 
     * @returns Getting the deal type list from server and assigning to state
     */
    getDealType = async () => {
        let StatusArray = [];
        let filterCriteria = {}
        filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
        await fetchMethodRequest('GET', `${apiCalls.dealType}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
            if (response && response["dealTypes"] && response["dealTypes"].length > 0) {
                let propertyStatuses = response["dealTypes"]
                for (let status of propertyStatuses) {
                    StatusArray.push({ label: status.dealTypeName, value: status.shortCode })
                }
                this.setState({ dealTypeArray: StatusArray })
            }
        })
        return this.state.settingsData;
    }
    /**
     * 
     * @returns Getting the deal stage array to set dropdown values
     */
    getDealStage = async () => {
        let StatusArray = [];
        let filterCriteria = {}
        filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
        await fetchMethodRequest('GET', `${apiCalls.DealStage}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
            if (response && response["dealStages"] && response["dealStages"].length > 0) {
                let propertyStatuses = response["dealStages"]
                for (let status of propertyStatuses) {
                    StatusArray.push({ label: status.dealStageName, value: status.dealStageName })
                }
                this.setState({ dealStageArray: StatusArray })
            }
        })
        return this.state.settingsData;
    }
    /**
     * 
     * @returns Getting deal status array to set the drop down values
     */
    getDealStatus = async () => {
        let StatusArray = [];
        let filterCriteria = {}
        filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
        await fetchMethodRequest('GET', `${apiCalls.dealStatus}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
            if (response && response["dealStatuss"] && response["dealStatuss"].length > 0) {
                let propertyStatuses = response["dealStatuss"]
                for (let status of propertyStatuses) {
                    StatusArray.push({ label: status.name, value: status.name })
                }
                this.setState({ dealStatusrray: StatusArray })
            }
        })
        return this.state.settingsData;
    }

    getCoreOpsDepartment = () => {
        let apiUrl;
        let filterCriteria = {}
        filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }, { key: 'activeIndicator', value: true, type: 'eq' }]
        return fetchMethodRequest('GET', `departments?filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response && response.departments) {
                    if (response && response.departments) {
                        let labels = response.departments;
                        let modifiedLabels = [];
                        for (let label of labels) {
                            modifiedLabels.push({
                                label: label.departmentName,
                                value: label.departmentName,
                                departmentId: label._id

                            })
                        } const sorted = modifiedLabels.sort((a, b) => {
                            let fa = a.label.toLowerCase(),
                                fb = b.label.toLowerCase();
                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        });
                        await this.setState({
                            taskDepartmentItems: sorted
                        })
                    }
                } else {
                    this.setState({
                        taskDepartmentItems: []
                    });
                    // let perObj = this.state.perObj
                    if (response) {
                        if (response['errorMessage'] === configMessages.warningMessage) {
                            this.setState({
                                sessionWarning: true
                            })
                        } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
                            this.setState({
                                sessionExpiryModal: true
                            })

                        } else if (localStorage.getItem('sessionexpired') === true) {
                            let sessionexpired = await localStorage.getItem('sessionexpired')
                            if (sessionexpired === "true") {
                                await this.setState({ sessionExpiryModal: true })
                            }
                        }
                    }
                }
            }).catch((err) => {
                return err;
            });
    }
    /**
    * 
    * @returns Getting APA type array to set the drop down values
    */
    getApaType = async () => {
        let ApaTypeArray = [], crTypeArray = [];
        let filterCriteria = {}
        filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
        fetchMethodRequest('GET', `apaTypeConfiguration?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
            if (response && response["apaTypeConfigurations"] && response["apaTypeConfigurations"].length > 0) {
                let apaTypeConfigurations = response["apaTypeConfigurations"]
                for (let apaTypeConfiguration of apaTypeConfigurations) {
                    if (apaTypeConfiguration.dealType === "BK" || apaTypeConfiguration.dealType !== "CR") {
                        ApaTypeArray.push({ label: apaTypeConfiguration.apaTypeID + "-" + apaTypeConfiguration.apaTypeName, value: apaTypeConfiguration.apaTypeID })
                    } else {
                        crTypeArray.push({ label: apaTypeConfiguration.apaTypeID + "-" + apaTypeConfiguration.apaTypeName, value: apaTypeConfiguration.apaTypeID })

                    }
                }
                this.setState({ ApaTypeArray: ApaTypeArray, crTypeArray: crTypeArray })
            }
        })
        return this.state.ApaTypeArray;
    }


    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    componentDidMount = async () => {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        let sessionexpired = await localStorage.getItem('sessionexpired')
        let role = await JSON.parse(localStorage.getItem('loginCredentials'))
        if (role && role.roleName) {
            await this.setState({ role: role.roleName })
        }

        if (window.location.pathname.split("/")[2] === "/business") {
            this.setState({ activeTab: "2" });
        } else {
            this.setState({ activeTab: "1" });
        }
        if (sessionexpired === "true") {
            await this.setState({ sessionExpiryModal: true })
        }
        this.getApaType()
        this.getDealType()
        this.getDealStage()
        this.getCoreOpsDepartment()
        this.getDealStatus()
        if (window.location.href.includes("sellersAdd")) {
            this.props.load({})
        }
        if (window.location.href.includes("sellersView")) {
            await this.setState({ sellerViewType: true })
        }
        if (window.location.href.includes("isRedirect")) {
            this.props.load({})
            await this.setState({ redirectToCRMatrix: true, key2: "CR Matrix" })
        }
        var url
        if (window.location.pathname.split("/").length === 4 || window.location.href.includes("sellersView")) {
            var url1split = await this.removeLastDirectoryPartOf(window.location.href)
            // var url1 = window.location.href
            // var x = url1split.split("/")
            var dealID = window.location.pathname.split("/")[3]
            await this.setState({ dimSellerId: dealID })
            url = `${apiCalls.Seller}/${dealID}`;
        }

        if (url && url.length > 0 && dealID !== null) {
            if (window.location.href.includes("sellersEdit") || window.location.href.includes("sellersView") || window.location.pathname.includes("deal")) {
                this.setState({ formType: "Edit" })
                return fetch("GET", url)
                    .then(async (response) => {
                        if (response && response.details) {
                            this.handleLoadforTabsasScreens(response.details)
                        } else if (response && response.errorMessage) {
                            // display message
                            // showToasterMessage(response.errorMessage, 'error');
                            this.getErrorMessage(response.errorMessage)
                        }
                        await this.setState({
                            isLoading: false,
                        })
                    }).catch((err) => {
                        return err;
                    });
            }
        }
        if (!window.location.pathname.includes("names") && !window.location.pathname.includes("Address")) {
            this.sicCodes()
        }
        this.setState({ formType: "Add" })

    }

    static getDerivedStateFromProps(props, state) {
        if (window.location.pathname.split("/")[2] === "/deal" && state.activeTab !== "1") {
            return { activeTab: "1" };
        } else if (window.location.pathname.split("/")[2] === "/business" && state.activeTab !== "2") {
            return { activeTab: "2" };
        }
    }

    /**
     * 
     * @param {*} data 
     * @param {*} fromSidebar 
     * Getting the required values and deal id and setting for state to get all other values
     */
    handleLoadforTabsasScreens = async (data, fromSidebar) => {
        if (data && Object.keys(data).length > 0) {
            this.props.load({})
            let str = []; let strArray;
            await this.setState({ key2: "main" })

            var url
            await this.setState({ isLoading: true })
            this.props.load({})
            this.handlePercentage('1')
            if (fromSidebar) {
                var y = data.DealID
                url = `${apiCalls.Seller}/${data.DealID}`;
            }

            if (!window.location.pathname.includes("sellersAdd") && !window.location.pathname.includes("deal")) {
                var url1split = await this.removeLastDirectoryPartOf(window.location.href)
                // var url1 = window.location.href
                var x = url1split.split("/")
                var y = url1split && x ? JSON.parse(x[x.length - 1]) : ""
                await this.setState({ dimSellerId: y })
                url = `${apiCalls.Seller}/${y}`;
            }
            await this.setState({ dealType: data.DealType })
            this.props.change("dealType", data.DealType)
            let z = this.state.Apa.find(elem => elem.value === data.APAType)
            if (z) {
                //with z values hit the api call
                this.props.change("APAType", z.label)
                this.setState({ APAType: z.label })
            }
            await this.setState({ showEntityName: data.EntityName, selectedDeal: data.EntityName })
            await this.setState({ headDimSellerID: data.DealID, activeTab: "1", key2: "main" })
            setTimeout(() => {
                this.props.load(data)
                this.props.change("dealType", data.dealType)
                this.setDynamicEdit(data)

                this.setState({ dealType: data.DealType })
                let z = this.state.Apa.find(elem => elem.value === data.APAType)
                if (z) {
                    //with z values hit the api call
                    this.props.change("APAType", z.label)
                    this.setState({ APAType: z.label })
                }
            }, 200);
            await this.getCompanies(y)
            this.getCrmatrix()
            this.getUpdateCrMatrix(y)
            this.getSellerApaNames(data, y)
            this.sicCodes()


            this.getHierarchyData()

        }
    }

    /**
     * 
     * @param {*} data 
     * @param {*} y 
     * Get Seller Apa names to get the exhibit values
     */
    getSellerApaNames = async (data, y) => {
        let url5 = data.DealType === "BK" || data.DealType !== "CR" ? `${apiCalls.Debtor}?sellerID=${y}` : `${apiCalls.SellerAPAName}?sellerID=${y}`;
        fetch("GET", url5)
            .then(async (response) => {
                if (response && response.sellerApaNames && response.sellerApaNames.length > 0) {
                    let exhibit1Array1 = [], exhibit2Array2 = [], exhibit1String = [], exhibit2String = []
                    for (let sellerApa of response.sellerApaNames) {
                        // if (sellerApa.ExhibitType === "Exhibit-A") {
                        exhibit1Array1.push({ label: sellerApa.SellerAPAName, value: sellerApa.SellerAPAName, SellerAPANamesID: sellerApa.SellerAPANamesID, ExhibitType: sellerApa.ExhibitType })
                        exhibit1String.push(sellerApa.SellerAPAName)
                        // } else {
                        //     exhibit2Array2.push({ label: sellerApa.SellerAPAName, value: sellerApa.SellerAPAName, SellerAPANamesID: sellerApa.SellerAPANamesID, ExhibitType: sellerApa.ExhibitType })
                        //     exhibit2String.push(sellerApa.SellerAPAName)
                        // }
                    }
                    await this.setState({ exhibit1Array: exhibit1Array1, exhibit2Array: exhibit2Array2, exhibit2String: exhibit2String, exhibit1String: exhibit1String })
                }
            }).catch((err) => {
                return err;
            });
    }
    /**
     * 
     * @param {*} y 
     * Returns Cr matrix values and sets the default cr matrix version values
     */
    getUpdateCrMatrix = async (y) => {
        let url4 = `${apiCalls.updateCrMatrix}?sellerID=${y}`;
        fetch("GET", url4)
            .then(async (response) => {

                if (response && response.sellerCrMatrices && response.sellerCrMatrices.length > 0) {
                    await this.setState({ sellerAnswers: response.sellerCrMatrices })
                    this.props.change("crMatrixVersion", response.version[0])
                    this.setMatrixVersion(response.version[0])
                    for (let SellerCrMatrice of response.sellerCrMatrices) {
                        this.props.change(`matrixId_${SellerCrMatrice.CrMatrixID}`, SellerCrMatrice.Answer)
                    }
                }
            }).catch((err) => {
                return err;
            });
    }
    /**
    * 
    * @param {*} y 
    * Returns Cr matrix values and sets the default cr matrix version values
    */
    getCrmatrix = async () => {
        let url2 = `${apiCalls.SellerCRMatrix}`;
        fetch("GET", url2)
            .then(async (response) => {

                if (response && response.distinctCrMatrixVersions) {
                    let matrixArray = []
                    for (let distinctCrMatrixVersion of response.distinctCrMatrixVersions) {
                        matrixArray.push({ label: distinctCrMatrixVersion, value: distinctCrMatrixVersion })
                    }
                    await this.setState({ matrixVersionArray: matrixArray })
                    if (!window.location.href.includes("isRedirect")) {
                        this.props.change("crMatrixVersion", response.distinctCrMatrixVersions[0])
                        this.setMatrixVersion(response.distinctCrMatrixVersions[0])
                    }
                    // this.props.change("crMatrixVersion", response.distinctCrMatrixVersions[0])
                    // this.setMatrixVersion(response.distinctCrMatrixVersions[0])
                }
            }).catch((err) => {
                return err;
            });
    }
    /**
    * 
    * @param {*} y 
    * Returns associated companies for the selected deal
    */
    getCompanies = async (y) => {
        if (y) {
            let str = []; let strArray;
            let filterCriteria = {}
            filterCriteria['criteria'] = [
                { key: "DealID", value: `${y}`, type: "eq" }
            ];
            let url1 = `${apiCalls.Companies}?filter=${JSON.stringify(filterCriteria)}`;

            await fetch("GET", url1)
                .then(async (response) => {
                    let arr = []
                    if (response && response.companies) {

                        for (let comp of response.companies) {
                            arr.push({ label: comp.CompanyName, value: comp.CompanyID, show: true, BusinessID: comp.BusinessID })
                        }
                        await this.setState({ isSelectedData: arr, hideSplitBtn: true, hideUpdatebtn: false })
                        for (let ar of this.state.isSelectedData) {
                            str.push(ar.value)
                        }

                        strArray = str.join(",")
                        let filter = {}
                        filter['sortfield'] = "BusinessName"
                        filter['direction'] = "asc"
                        await this.setState({ strArray: strArray })
                        let urlNames = `${apiCalls.AddressNames}?filter=${JSON.stringify(filter)}&str=${strArray}`
                        await this.setState({ exportNamesUrl: urlNames })
                        if (this.state.isSelectedData && this.state.isSelectedData.length > 0) {
                            let filtersign = {}
                            filtersign['sortfield'] = "companyID"
                            filtersign['direction'] = "asc"
                            let sellersignUrl = `${apiCalls.signatories}?filter=${JSON.stringify(filtersign)}&str=${this.state.strArray}`


                            fetch("GET", sellersignUrl)
                                .then(async (response) => {
                                    if (response && response.signatories) {
                                        await this.setState({ sellerSignatories: response.signatories })
                                        // await this.setState({ sellerSignatories: [{ companyID: "123", personName: "tej", fullTitle: "tejjj" }] })
                                    }
                                }).catch((err) => {
                                    return err;
                                });
                        }

                    }
                }).catch((err) => {
                    return err;
                });
        }
    }
    setValueInGrid = async (e) => {
        await this.setState({ editedField: e })
        let allrecords = this.state.addressnames && this.state.addressnames.length > 0 ? this.state.addressnames : []
        allrecords[this.state.editedRowIndex][this.state.editedRowField] = e;
        this.setState({ addressnames: allrecords })


    }
    cancelSessionWarningModal = async () => {
        await this.setState({
            sessionWarning: false
        })
    }
    /**
     * Setting the values dynamically for the fields 
     */
    setDynamicEdit = async (details) => {
        let OpportunityScoreOptions = [{ "label": "0-None", "value": '0' },
        { "label": "1-Very Low", "value": '1' },
        { "label": "2-Low", "value": '2' },
        { "label": "3-Med", "value": '3' },
        { "label": "4-High", "value": '4' }
        ]

        let usArray = [], intLArr = [], sicArr = []
        if (details && details.UsLocations && details.UsLocations.length > 0 && details.UsLocations != null) {
            usArray = details.UsLocations.split(',');
            // this.props.change("UsLocations", usArray)
            this.setState({ usL: usArray, usLSub: details.UsLocations })
        }
        if (details && details.InternationalLocations && details.InternationalLocations.length > 0 && details.InternationalLocations != null) {
            intLArr = details.InternationalLocations.split(',');
            // this.props.change("UsLocations", usArray)
            this.setState({ intL: intLArr, intLSub: details.InternationalLocations })
        }
        if (details && details.SICCode && details.SICCode.length > 0 && details.SICCode != null) {
            sicArr = details.SICCode.split(',');
            // this.props.change("UsLocations", usArray)
            this.setState({ sic: sicArr, sicSub: details.SICCode })
        }
        if (details && details.RingTheBellDate) {
            this.setState({ RingTheBellDate1: dateFormats.formatDate(details.RingTheBellDate, "MM/DD/YY") })
        }
        if (details && details.OPPSalesRep) {
            this.setState({ OPPSalesRep1: details.OPPSalesRep })
        }
        if (details && details.OkForWebsiteDate) {
            this.setState({ OkForWebsiteDate1: dateFormats.formatDate(details.OkForWebsiteDate, "MM/DD/YY") })
        }
        if (details && details.PurchasePrice) {
            await this.setState({ PurchasePrice: details.PurchasePrice })
            this.props.change("PurchasePrice", details.PurchasePrice)


        }
        // if (details.CaseTransfer) {
        //     this.props.change("CaseTransfer", "Y")
        // } else {
        //     this.props.change("CaseTransfer", "N")
        // }
        if (details.HilcoStreamBankPortfolio) {
            this.props.change("HilcoStreamBankPortfolio", "Yes")
        } else {
            this.props.change("HilcoStreamBankPortfolio", "No")
        }
        if (details.OpportunityScore) {
            let ind = OpportunityScoreOptions.findIndex(a1 => a1.value === details.OpportunityScore.toString())
            this.props.change("OpportunityScore", OpportunityScoreOptions[ind].label)
        }

        if (details.RequiredNotificationofIdentifiedRemnants) {
            this.props.change("RequiredNotificationofIdentifiedRemnants", "Yes")
        } else {
            this.props.change("RequiredNotificationofIdentifiedRemnants", "No")
        }
        if (details.NRSPortfolio) {
            this.props.change("NRSPortfolio", "Yes")
        } else {
            this.props.change("NRSPortfolio", "No")
        }
        if (details.ACRSPortfolio) {
            this.props.change("ACRSPortfolio", "Yes")
        } else {
            this.props.change("ACRSPortfolio", "No")
        }
        if (details.CIGNAPortfolio) {
            this.props.change("CIGNAPortfolio", "Yes")
        } else {
            this.props.change("CIGNAPortfolio", "No")
        }
        if (details.AETNAPortfolio) {
            this.props.change("AETNAPortfolio", "Yes")
        } else {
            this.props.change("AETNAPortfolio", "No")
        }
        if (details.ArgentPortfolio) {
            this.props.change("ArgentPortfolio", "Yes")
        } else {
            this.props.change("ArgentPortfolio", "No")
        }
        if (details.CCBPortfolio) {
            this.props.change("CCBPortfolio", "Yes")
        } else {
            this.props.change("CCBPortfolio", "No")
        }
        if (details.DealAnnouncementApproval === "Y") {
            this.props.change("DealAnnouncementApproval", "Yes")
        } else if (details.DealAnnouncementApproval === "P") {
            this.props.change("DealAnnouncementApproval", "Pending")
        } else {
            this.props.change("DealAnnouncementApproval", "No")
        }
        if (details.APAAddendum === "Y") {
            this.setState({ APAAddendumDate1: true })
        } else {
            this.setState({ APAAddendumDate1: false })
        }
        if (details.LimitedPowerOfAttorney === "Y") {
            this.setState({ LimitedPowerOfAttorney: true })
        } else {
            this.setState({ LimitedPowerOfAttorney: false })
        }
        if (details.AncillaryAgreementDate || details.AncillaryAgreementTerms || details.AncillaryAgreementType) {
            this.setState({ AncillaryAgreement: true })
            this.props.change("AncillaryAgreement", "Y")
        } else {
            this.setState({ AncillaryAgreement: false })
            this.props.change("AncillaryAgreement", "N")
        }
        if (details.CaseTransferDate || details.OriginatingJurisdiction) {
            this.setState({ CaseTransfer1: true })
        } else {
            this.setState({ CaseTransfer1: false })
        }
        if (details.CaseConversionDate) {
            this.setState({ CaseConversion1: true })
        } else {
            this.setState({ CaseConversion1: false })
        }
        if (details.CaseStatusClosedDate) {
            this.setState({ showCaseClosedDate: true })
        } else {
            this.setState({ showCaseClosedDate: false })
        }
        if (details.RequiredNotificationExpirationofRequirement || details.RequiredNotificationNoticeDates || details.RequiredNotificationNoticePeriod) {
            this.setState({ RequiredNotificationofIdentifiedRemnants: true })
        } else {
            this.setState({ RequiredNotificationofIdentifiedRemnants: false })
        }
        if (details.EntityName) {
            this.setState({ EntityName: details.EntityName })
        }
        if (details.SellerNickName) {
            this.setState({ SellerNickName: details.SellerNickName })
        }
        if (details.AzureStorageDirectory) {
            this.setState({ AzureStorageDirectory: details.AzureStorageDirectory })
        }
        if (details.OpraId) {
            this.setState({ OpraId: details.OpraId })
        }
        if (details.LeadSeller) {
            this.setState({ LeadSeller: details.LeadSeller })
        }
        if (details.LeadCaseNumber) {
            this.setState({ LeadCaseNumber: details.LeadCaseNumber })
        }
        if (details.StateofIncorporation) {
            let z = this.state.loc.find(elem => elem.label === details.StateofIncorporation)
            if (z) {
                this.setState({ stateofIncorporationIncluded: false, keyNameChecked: false })

            } else {
                this.setState({ stateofIncorporationIncluded: true, keyNameChecked: true })
                this.props.change("stateofIncorporationIncluded", "Y")
            }
        } else {
            this.setState({ stateofIncorporationIncluded: false, keyNameChecked: false })
            this.props.change("stateofIncorporationIncluded", "N")
        }
        if (details.HeadquartersState) {
            let z = this.state.loc.find(elem => elem.label === details.HeadquartersState)
            if (z) {
                this.setState({ headquartersStateExcluded: false, keyNameCheckedState: false })
                this.props.change("headquartersStateExcluded", "N")
            } else {
                this.setState({ headquartersStateExcluded: true, keyNameCheckedState: true })
                this.props.change("headquartersStateExcluded", "Y")
            }
        } else {
            this.setState({ headquartersStateExcluded: false, keyNameCheckedState: false })
            this.props.change("headquartersStateExcluded", "N")
        }

        if (details.BidProceduresOverbidAmount || details.OverbidAmountNotes) {
            this.setState({ OPPSaleMotionBidProcedures: true })
        } else {
            this.setState({ OPPSaleMotionBidProcedures: false })
        }
        if (details.APAAddendumDetails || details.AddendumDate) {
            this.setState({ APAAddendumDate1: true })
        } else {
            this.setState({ APAAddendumDate1: false })
        }
        if (details.LimitedPowerOfAttorney) {
            this.setState({ LimitedPowerOfAttorney: true })
        } else {
            this.setState({ LimitedPowerOfAttorney: false })
        }
        if (details.RCS || details.RCSNotes || details.RCSHoldingPeriod || details.RCSClaimsDistributionDate || details.RCSNoOfCreditors || details.RCSApproxClaimsDistributionDate || details.RCSOakPointAcquireDate || details.RCSDistributionAmount || details.RCSOfCreditors || details.RCSMotionFiled || details.RCSBankContact || details.RCSStatus) {
            this.setState({ RCS: true })
        } else {
            this.setState({ RCS: false })
        }



    }
    /**
     * Getting the sic code values for dropdown
     */
    sicCodes = async () => {
        let sicUrl = `${apiCalls.sicCodes}`;
        let sicArr = []
        fetch("GET", sicUrl)
            .then(async (response) => {
                if (response && response.sicCodes && response.sicCodes.length > 0) {
                    for (let sic of response.sicCodes) {
                        sicArr.push({ label: sic.SicCode + "-" + sic.SicDescription, value: sic.SicCode })
                    }
                }

                await this.setState({ sicCodes: sicArr })
            }).catch((err) => {
                return err;
            });
    }


    //stay signed in Modal
    getSessionWraningModal() {
        return (
            <SessionWarningModal
                openConfirmationModal={this.state.sessionWarning}
                cancelSessionWarningModal={this.cancelSessionWarningModal}
                getData={this.getKanbanCards}
                filterCriteria={this.state.filterCriteria}

            />
        )
    }
    cancelexpiredSessionModal = async () => {
        await this.setState({
            expiredSessionModal: false
        })
    }
    expiredSessionModal() {
        return (
            <ExpiredSessionModal
                openConfirmationModal={this.state.expiredSessionModal}
                cancelSessionWarningModal={this.cancelexpiredSessionModal}
                getData={this.getDataFromServer}

            />
        )
    }
    /**
     * Getting the heirarachy company values 
     */
    getHierarchyData = async () => {

        let namesArray = []
        let urlNames = `${apiCalls.HeirarchyCompany}?str=${this.state.dimSellerId}`
        fetch("GET", urlNames)
            .then(async (response) => {
                if (response && response.companyIDs) {

                    for (let name of response.companyIDs) {
                        namesArray.push({ label: name.CompanyName, value: name.CompanyID, BusinessID: name.BusinessID })
                    }
                    await this.setState({ namesArray: namesArray })
                } else {
                    this.setState({ namesArray: [], })
                }

            }).catch((err) => {
                return err;
            });

        this.getIssueNameCode()

    }
    getIssueNameCode = async () => {
        let issueNameCodes = []
        let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }] }
        let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
        await fetch("GET", urlNames)
            .then(async (response) => {
                if (response && response.businessNameIssueCodeConfigurations) {
                    for (let name of response.businessNameIssueCodeConfigurations) {
                        issueNameCodes.push({ label: name.BusinessNameIssueLabel, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
                    }
                    this.setState({ issueNameOptions: issueNameCodes })
                } else {
                    this.setState({ issueNameOptions: [] })
                }
            }).catch((err) => {
                return err;
            });
        // let url1 = `${apiCalls.execute}?query=Select Distinct NameType From mbd.BusinessNames`;
        // fetch("GET", url1)
        //     .then(async (response) => {
        //         if (response && response.results) {
        //             // for (let result of response.results) {
        //             //     nameTypeArray.push({ label: result.NameType, value: result.NameType })
        //             nameTypeArray = response.results.map(u => u.NameType).join(",")

        //             // }
        //             this.setState({ nameTypeArray: nameTypeArray, isLoading: false })
        //         } else {
        //             this.setState({ nameTypeArray: "", isLoading: false })
        //         }

        //     }).catch((err) => {
        //         return err;
        //     });

        // this.sendColumnDataToServer()
    }
    /**
     * Closing Names model 
     */
    closeAddNamesModel = () => {
        this.setState({
            isOpenFormModal: false, itemAvail: false
        })
    }
    /**
 * Closing Address model 
 */
    closeAddAddressModel = () => {
        this.setState({
            isOpenAddressModal: false, itemAvail: false
        })
    }
    /**
     * 
     * @param {*} message 
     * @param {*} type 
     * @param {*} color 
     * Setting the response message to show
     */
    setResponse = async (message, type, color) => {
        await this.setState({ apierrorMessage: message, apierrorResponse: true, apierrorcolor: color === "error" ? "red" : "green" })
    }
    /**
     * 
     * @returns Opening the bulk upload modal 
     */
    bulkUpload = () => {
        return (
            <BulkUploadModal
                type={"seller/names"}
                sample={this.state.activeTab === "3" ? configImages.namesSampleXL : this.state.activeTab === "4" ? configImages.addressSampleXL : this.state.key2 === "debtors" ? configImages.DebtorsSampleXL : configImages.ApaNamesSampleXL}
                toggleNames={(this.state.activeTab === "3" || this.state.activeTab === "4") ? this.toggleNames : this.refreshApaNames}
                activeTab={this.state.key2 === "debtors" ? "debtors" : this.state.activeTab}
                dimSellerID={this.state.dimSellerId}
                namesArray={this.state.namesArray}
                nameTypeArray={this.state.nameTypeArray}
                IssueCodeArray={this.state.IssueCodeArray}
                setResponse={this.setResponse}
                openBulkUploadModal={this.state.openBulkUploadModal}
                closeBulkModal={this.closeBulkModal} />
        )
    }
    /**
     * 
     * @returns open signatory model
     */
    AddSignatoryModal = () => {

        return (
            <SellerSignatoryModal
                isOpenAddModal={this.state.openSignatoryModal}
                isCloseModal={this.cancelSignatoryModal}
                // activeTab={this.state.activeTab}
                strArray={this.state.strArray ? this.state.strArray : null}
                // dataSourceId={this.state.dataSourceId ? this.state.dataSourceId : null}
                editsignatoryData={this.state.editsignatoryData ? this.state.editsignatoryData : {}}
                itemDetails={this.state.itemAvail ? this.state.itemDetails : null}
                connectedBusiness={this.state.isSelectedData && this.state.isSelectedData.length > 0 ? this.state.isSelectedData : []}
                toggleNames={this.getSellerSignatories}
                dimSellerID={this.state.dimSellerId}
            />
        )

    }
    /**
     * Setting the exhibit names 
     */
    setExhibitNames = async () => {
        let url5 = `${apiCalls.SellerAPAName}?sellerID=${this.state.dimSellerId}`;
        await this.setState({ isLoading: true })
        fetch("GET", url5)
            .then(async (response) => {
                if (response && response.sellerApaNames && response.sellerApaNames.length > 0) {
                    let exhibit1Array1 = [], exhibit2Array2 = [], exhibit1String = [], exhibit2String = [], count = 0
                    for (let sellerApa of response.sellerApaNames) {
                        // if (sellerApa.ExhibitType === "Exhibit-A") {
                        exhibit1Array1.push({ label: sellerApa.SellerAPAName, value: sellerApa.SellerAPAName, SellerAPANamesID: sellerApa.SellerAPANamesID, ExhibitType: sellerApa.ExhibitType })
                        exhibit1String.push(sellerApa.SellerAPAName)
                        // } else {
                        //     exhibit2Array2.push({ label: sellerApa.SellerAPAName, value: sellerApa.SellerAPAName, SellerAPANamesID: sellerApa.SellerAPANamesID, ExhibitType: sellerApa.ExhibitType })
                        //     exhibit2String.push(sellerApa.SellerAPAName)
                        // }
                    }
                    await this.setState({ exhibit1Array: exhibit1Array1, exhibit2Array: exhibit2Array2, exhibit2String: exhibit2String, exhibit1String: exhibit1String })
                }
                await this.setState({ isLoading: false })
            }).catch((err) => {
                return err;
            });
    }

    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }
    /**
     * 
     * @param {*} values 
     * Sending Data to Server to Save deal fields
     */
    submit = (values) => {
        this.setState({
            isDisableSubmitButton: true
        })
        let OpportunityScoreOptions = [{ "label": "0-None", "value": '0' },
        { "label": "1-Very Low", "value": '1' },
        { "label": "2-Low", "value": '2' },
        { "label": "3-Med", "value": '3' },
        { "label": "4-High", "value": '4' }
        ]
        if (values.OpportunityScore) {
            let ind = OpportunityScoreOptions.findIndex(a1 => a1.label === values.OpportunityScore)
            if (ind !== -1) {
                values.OpportunityScore = OpportunityScoreOptions[ind].value
                this.props.change("OpportunityScore", OpportunityScoreOptions[ind].label)
            }
        }
        if (values.CoreOpsTeam) {
            let z = this.state.taskDepartmentItems.find(elem => elem.value === values.CoreOpsTeam)
            if (z) {
                values.CoreOpsTeamId = z.departmentId

            }
        }
        let method, apiUrl
        if (window.location.href.includes("sellersEdit") || this.state.stayInAdd) {
            method = 'PUT'
            apiUrl = `${apiCalls.Seller}/${this.state.dimSellerId}`;
        } else {
            method = 'POST'
            apiUrl = apiCalls.Seller
        }
        if (window.location.href.includes("sellersEdit") || this.state.stayInAdd) {
            values.dealID = this.state.dimSellerId
        }
        let valuearr = []
        for (let value in values) {
            if (value.includes("matrixId_")) {
                let answerValue
                let matrixId = value.split("_")
                if (this.state.sellerAnswers && this.state.sellerAnswers.length > 0) {
                    let answer = this.state.sellerAnswers.find(o =>
                        o.CrMatrixID === JSON.parse(matrixId[1]));
                    answerValue = answer && answer.SellerCrMatrixID ? answer.SellerCrMatrixID : undefined
                    if (answer && answer.Answer === values[value]) {

                    } else {
                        valuearr.push({
                            "sellerCrMatrixID": answerValue,
                            "answer": values[value],
                            "dealID": this.state.dimSellerId,
                            "crMatrixID": matrixId[1],
                        })
                    }
                } else {
                    valuearr.push({
                        "answer": values[value],
                        "dealID": this.state.dimSellerId,
                        "crMatrixID": matrixId[1],
                    })
                }
            }
        }
        values.answers = valuearr
        this.sendDataToServer(method, apiUrl, values)
    }

    /**
     * 
     * @param {*} method 
     * @param {*} apiUrl 
     * @param {*} userBody 
     * hitting the submit button to send data to server
     */
    successSubmit = async (method, apiUrl, userBody) => {
        await this.setState({
            issplitbyStatus: false,
            isShowChecklistError: this.props.type === "add" ? true : false,

        })
        this.sendDataToServer(method, apiUrl, userBody)
    }
    /**
     * Setting the field to state dynamically
     */
    setrequiredstateFiels = async (value, name) => {
        await this.setState({ [name]: value })
    }
    /**
     * 
     * @param {*} e 
     * @param {*} field 
     * Setting us loc field dynamically and join into string
     */
    changeUsLoc = async (e, field) => {
        let intL, usL, sic
        if (field === "InternationalLocations") {
            intL = e.target.value.join(",")
            await this.setState({ intL: e.value, intLSub: intL, })
        }

        if (field === "UsLocations") {
            usL = e.target.value.join(",")
            await this.setState({ usL: e.value, usLSub: usL })

        }
        if (field === "SICCode") {
            sic = e.target.value.join(",")
            await this.setState({ sic: e.value, sicSub: sic, })
        }

    }
    /**
     * 
     * @param {*} method 
     * @param {*} url 
     * @param {*} body 
     * @returns Hit api call to save details 
     */
    sendDataToServer = async (method, url, body) => {
        let fields = ["HilcoStreamBankPortfolio", "NRSPortfolio", "ACRSPortfolio", "CIGNAPortfolio", "AETNAPortfolio", "ArgentPortfolio", "CCBPortfolio"]
        for (let field of fields) {
            if (body[field] === "Yes") {
                body[field] = true
            } else if (body[field] === "No") {
                body[field] = false
            }
        }
        let Remnants = ["RequiredNotificationofIdentifiedRemnants"]
        for (let Remnant of Remnants) {
            if (body[Remnant] === "Y" || body[Remnant] === "Yes") {
                body[Remnant] = true
            } else if (body[Remnant] === "N" || body[Remnant] === "No") {
                body[Remnant] = false
            }
        }
        if (this.state.intLSub && this.state.intLSub.length > 0) {
            body.InternationalLocations = this.state.intLSub
        }
        if (this.state.PurchasePrice && this.state.PurchasePrice.length > 0) {
            body.PurchasePrice = this.state.PurchasePrice
        }

        if (this.state.usLSub && this.state.usLSub.length > 0) {
            body.UsLocations = this.state.usLSub
        }
        if (body.DealAnnouncementApproval === "Yes") {
            body.DealAnnouncementApproval = "Y"
        } else if (body.DealAnnouncementApproval === "No") {
            body.DealAnnouncementApproval = "N"
        } else if (body.DealAnnouncementApproval === "Pending") {
            body.DealAnnouncementApproval = "P"
        }
        if (this.state.sicSub && this.state.sicSub.length > 0) {
            body.SICCode = this.state.sicSub
        }
        if (method === "PUT" && body.APAType) {

            let z = this.state.Apa.find(elem => elem.label === body.APAType)
            if (z) {
                body.APAType = z.value
            }
        }

        await this.setState({
            // isLoading: true,
            method: method,
            apiUrl: url,
            userBody: body
        })

        await this.setState({
            isLoading: true,

        })
        this.handlePercentage('1')
        return fetch(method, url, body)
            .then(async (response) => {
                if (response && response.respCode && (response.respCode === 204 || response.respCode === 200 || response.respCode === 205)) {
                    // await localStorage.setItem('succ', response.respMessage);
                    if (method !== "PUT" && response.outResponse && response.outResponse.sellerId) {
                        this.setState({ stayInAdd: true, dimSellerId: response.outResponse.sellerId, successResponse: true, successMessage: response.respMessage, ShowEntityName: body.EntityName, isRedirecttoEdit: true })
                    } else {
                        this.setState({ successResponse: true, successMessage: response.respMessage })
                    }
                    if (body && body.RingTheBellDate) {
                        this.setState({ RingTheBellDate1: dateFormats.formatDate(body.RingTheBellDate, "MM/DD/YY") })
                    }
                    if (body && body.OPPSalesRep) {
                        this.setState({ OPPSalesRep1: body.OPPSalesRep })
                    }
                    if (body && body.OkForWebsiteDate) {
                        this.setState({ OkForWebsiteDate1: dateFormats.formatDate(body.OkForWebsiteDate, "MM/DD/YY") })
                    }
                    this.getErrorMessage(response.respMessage)

                } else if (response && response.errorMessage) {
                    this.getErrorMessage(response.errorMessage, '', 'error')
                } else if (response && response.title) {
                    this.getErrorMessage(response.title)
                }
                await this.setState({
                    isLoading: false,
                    isDisableSubmitButton: false

                })


            }).catch((err) => {
                return err;
            });

    }
    /**
     * 
     * @param {*} e 
     * @returns Setting matrix version
     */
    setMatrixVersion = async (e) => {
        await this.setState({ isLoading: true })
        this.handlePercentage('1')
        let filterCriteria = {}
        filterCriteria['criteria'] = [
            { key: "Version", value: `${e}`, type: "eq" }
        ];
        let url1 = `${apiCalls.crMatrix}?filter=${JSON.stringify(filterCriteria)}`;
        return fetch("GET", url1)
            .then(async (response) => {
                let qstnsArray = []
                if (response && response.crMatrices) {
                    for (let crMatrice of response.crMatrices) {
                        qstnsArray.push({ qstn: crMatrice.Question, qstnId: crMatrice.CrMatrixID, CrMatrixRule: crMatrice.CrMatrixRule })
                    }
                    await this.setState({ matrixQstns: qstnsArray, isLoading: false })
                }
            }).catch((err) => {
                return err;
            });

    }
    /**
     * 
     * @param {*} type 
     * closing the model for debtors
     */
    cancelReset = async (type) => {
        if (!type || type !== "save") {
            await this.setState({ isopenColumnModal: true })
        } else {
            this.closeShowHideColumnsModal('close')
        }
    }
    /**
    * 
    * closing the model for signatory model
    */
    cancelSignatoryModal = async () => {
        await this.setState({ openSignatoryModal: false })
    }
    /**
     * Getting the seller signatories based on companies
     */
    getSellerSignatories = async () => {
        let filtersign = {}
        filtersign['sortfield'] = "companyID"
        filtersign['direction'] = "asc"
        let sellersignUrl = `${apiCalls.signatories}?filter=${JSON.stringify(filtersign)}&str=${this.state.strArray}`
        fetch("GET", sellersignUrl)
            .then(async (response) => {
                if (response && response.signatories) {
                    await this.setState({ sellerSignatories: response.signatories })
                }
            }).catch((err) => {
                return err;
            });
    }
    /**
     * 
     * @returns Opening the confirmation model
     */
    getConfirmaionModel() {
        return (
            <ConfirmationModal
                openConfirmationModal={this.state.isopenColumnModal}
                closeConfirmationModal={this.closeShowHideColumnsModal}
                shownText={"Are you sure you want to close before Saving?"}
            />
        )
    }
    /**
     * 
     * @param {*} type 
     * Clsoing the show hide model after confirmation
     */
    closeShowHideColumnsModal = async (type) => {
        await this.setState({
            isopenColumnModal: false
        })
        if (type === "close") {
            if (this.state.updatedRecordslength > 0 && this.state.isDownload) {
                this.getExcelDownload()
                document.getElementsByClassName("right_buttons")[0].children[2].children[0].click()
                this.setState({ isDownload: false })
            } else if (this.state.updatedRecordslength > 0 && this.state.isRefresh) {
                this.getremoveFilters()
                this.toggle("3", "refresh")
                this.setState({ isRefresh: false })

            } else {
                if (this.state.isOpenFormModal || this.state.isOpenAddressModal) {
                    this.closeAddNamesModel()
                    this.closeAddAddressModel()
                } else {
                    this.props.load({})
                    await this.props.reset();
                    await this.setState({ isKanbanModelClose: true })
                }
            }
            // this.props.closeShowHideColumnsModal('close', null)
        }

    }

    /**
     * 
     * @param {*} dealType 
     * setting the deal type in deal tab
     */
    handleCheck = async (dealType) => {
        this.props.reset()
        await this.setState({
            dealType: dealType
        })
    }
    /**
     * 
     * @returns Connecting business through the business tab
     */
    connectBussiness = async () => {
        let companyIdArray = [], unSelectedBusinessIds = []
        if (this.state.isSelectedData.length > 0) {
            for (let companyId of this.state.isSelectedData) {
                if (companyId.show === true) {
                    companyIdArray.push(companyId.value)
                } else if (companyId.show === false) {
                    unSelectedBusinessIds.push(companyId.value)
                }
            }
            let strArray = this.state.isSelectedData.map(u => u.value).join(",")

            let body = {
                businessIDs: companyIdArray,
                unSelectedBusinessIDs: unSelectedBusinessIds,
                sellerID: JSON.stringify(this.state.dimSellerId)
            }
            return fetch('POST', apiCalls.connectBusiness, body)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        await localStorage.setItem('succ', response.respMessage);
                        this.cancelReset('save')
                        this.setState({ apierrorResponse: false, apierrorMessage: `Please select any business under Selected Business Names before clicking to connect` })

                    } else if (response && response.errorMessage) {
                        this.setState({ apierrorResponse: true, apierrorMessage: response.errorMessage })

                    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                        await this.setState({
                            sessionWarning: true,
                        })
                    }
                    await this.setState({ isLoading: false })
                }).catch((err) => {
                    return err;
                });
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any business under Selected Business Names before clicking to connect` })

        }

    }

    /**
     * 
     * @param {*} errorMessage 
     * @param {*} type 
     * @param {*} color 
     * Throing the error message and setting the color
     */
    getErrorMessage = async (errorMessage, type, color) => {
        await this.setState({
            apierrorMessage: errorMessage.replace("\r\n", ""),
            apierrorResponse: true,
            apierrorcolor: color === "error" ? "red" : "green"
        })

    }
    /**
     * 
     * @param {*} e 
     * @param {*} name 
     * setting the purchase price value
     */
    setValue1 = async (e, name) => {
        await this.setState({ PurchasePrice: e })
    }
    /**
     * 
     * @param {*} e 
     * @param {*} name 
     * Setting the values for the bit fileds
     */
    APAAddendumDate = (e, name) => {
        if (name === "APAAddendum") {
            if (e === "Y") {
                this.setState({ APAAddendumDate1: true })
            } else {
                this.setState({ APAAddendumDate1: false })
            }
        }
        if (name === "RCS") {
            if (e === "Y") {
                this.setState({ RCS: true })
            } else {
                this.setState({ RCS: false })
            }
        }
        if (name === "LimitedPowerOfAttorney") {
            if (e === "Y") {
                this.setState({ LimitedPowerOfAttorney: true })
            } else {
                this.setState({ LimitedPowerOfAttorney: false })
            }
        }
    }
    /**
 * 
 * @param {*} e 
 * @param {*} name 
 * Setting the values for the bit fileds
 */
    caseTransferDate = (e, name) => {
        if (name === "CaseTransfer") {
            if (e === "N") {
                this.setState({ CaseTransfer1: false })
            } else {
                this.setState({ CaseTransfer1: true })
            }
        }
        if (name === "CaseConversion") {
            if (e === "N") {
                this.setState({ CaseConversion1: false })
            } else {
                this.setState({ CaseConversion1: true })
            }
        }
        if (name === "CaseStatus") {
            if (e === "Closed") {
                this.setState({ showCaseClosedDate: true })
            } else {
                this.setState({ showCaseClosedDate: false })
            }
        }
        if (name === "RequiredNotificationofIdentifiedRemnants") {
            if (e === "Y") {
                this.setState({ RequiredNotificationofIdentifiedRemnants: true })
            } else {
                this.setState({ RequiredNotificationofIdentifiedRemnants: false })
            }
        }
        if (name === "stateofIncorporationIncluded") {
            if (e === "Y") {
                this.setState({ stateofIncorporationIncluded: true })
            } else {
                this.setState({ stateofIncorporationIncluded: false })
            }
        }
        if (name === "headquartersStateExcluded") {
            if (e === "Y") {
                this.setState({ headquartersStateExcluded: true })
            } else {
                this.setState({ headquartersStateExcluded: false })
            }
        }
        if (name === "OPPSaleMotionBidProcedures") {
            if (e === "Y") {
                this.setState({ OPPSaleMotionBidProcedures: true })
            } else {
                this.setState({ OPPSaleMotionBidProcedures: false })
            }
        }
        if (name === "AncillaryAgreement") {
            if (e === "Y") {
                this.setState({ AncillaryAgreement: true })
            } else {
                this.setState({ AncillaryAgreement: false })
            }
        }

        if (name === "DealStatus") {
            if (e === "Pre-Sales") {
                this.setState({ dealStatusflag: true })
            } else {
                this.setState({ dealStatusflag: false })
            }
        }
    }

    /**
     * 
     * @param {*} name 
     * @param {*} exhibit 
     * Editing the exhibit names
     */
    editNames = (name, exhibit) => {
        this.setState({ editNames: true, exhibit: exhibit, oldName: name })
        this.props.change("Editname", name)

    }
    /**
     * 
     * @param {*} e 
     * Handling the names based on deal
     */
    handleCompanyNames = async (e) => {
        // this.props.chan({ entityName: e.CompanyName })
        this.props.change('entityName', e.CompanyName)
        this.props.change('companyName', e.CompanyName)
        this.props.change('LeadSeller', e.CompanyName)

    }
    /**
 * 
 * @param {*} e 
 * Cancel new names model
 */
    cancelNewName = async () => {
        await this.setState({ addName: false })
        this.props.change("Addname", "")
        this.props.change("exhibit", "")
    }
    /**
* 
* @param {*} e 
* Cancel  names model
*/
    cancelName = async () => {
        await this.setState({ editNames: false })
    }
    /**
* 
* @param {*} e 
* Set new names model
*/
    setNewName = async (e) => {
        await this.setState({ newName: e })
    }
    /**
* 
* @param {*} e 
* Cancel new add names model
*/
    setNewAddName = async (e) => {
        await this.setState({ addnewName: e })
    }
    handleNewChange = async (e) => {
        await this.setState({ newExhibitType: e })
    }
    removeLastDirectoryPartOf(the_url) {
        if (the_url && the_url.includes("isRedirect")) {
            var the_arr = the_url.split('/');
            the_arr.pop();
            return (the_arr.join('/'));
        } else {
            return the_url
        }
    }
    /**
     * 
     * @param {*} e 
     * Exhibit api hitting after creating
     */
    refreshApaNames = async (e) => {
        let url5
        await this.setState({ isLoading: true })
        if (e === "debtors") {
            url5 = `${apiCalls.Debtor}?sellerID=${this.state.dimSellerId}`;
        } else {
            url5 = `${apiCalls.SellerAPAName}?sellerID=${this.state.dimSellerId}`;
        }
        fetch("GET", url5)
            .then(async (response) => {
                if (response && response.sellerApaNames && response.sellerApaNames.length > 0) {
                    let exhibit1Array1 = [], exhibit2Array2 = [], exhibit1String = [], exhibit2String = [], count = 0
                    for (let sellerApa of response.sellerApaNames) {
                        // if (sellerApa.ExhibitType === "Exhibit-A") {
                        exhibit1Array1.push({ label: sellerApa.SellerAPAName, value: sellerApa.SellerAPAName, SellerAPANamesID: sellerApa.SellerAPANamesID, ExhibitType: sellerApa.ExhibitType })
                        exhibit1String.push(sellerApa.SellerAPAName)
                        // } else {
                        //     exhibit2Array2.push({ label: sellerApa.SellerAPAName, value: sellerApa.SellerAPAName, SellerAPANamesID: sellerApa.SellerAPANamesID, ExhibitType: sellerApa.ExhibitType })
                        //     exhibit2String.push(sellerApa.SellerAPAName)
                        // }
                    }
                    await this.setState({ exhibit1Array: exhibit1Array1, exhibit2Array: exhibit2Array2, exhibit2String: exhibit2String, exhibit1String: exhibit1String })
                }
                await this.setState({ isLoading: false })
            }).catch((err) => {
                return err;
            });

    }
    /**
     * Saving the new exhibit names 
     */
    saveNewName = async (e) => {
        let body
        await this.setState({ isLoading: true, addName: false })
        if (e === "debtors") {
            body = {
                sellerAPAName: this.state.addnewName,
                // debtorName: this.state.addnewName,
                dealID: JSON.stringify(this.state.dimSellerId),

            }
        } else {
            body = {
                sellerAPAName: this.state.addnewName,
                // debtorName: this.state.addnewName,
                dealID: JSON.stringify(this.state.dimSellerId),

                exhibitType: this.state.newExhibitType
            }
        }
        return fetch('POST', e === "debtors" ? apiCalls.Debtor : apiCalls.SellerAPAName, body)
            .then(async (response) => {
                if (response && response.respMessage) {
                    // this.cancelReset()
                    this.refreshApaNames(e)
                } else if (response && response.errorMessage) {
                    await this.setState({ apierrorMessage: response.errorMessage, apierrorResponse: true, color: "error" })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                    })
                }
                await this.setState({ isLoading: false, Addname: "", exhibit: "" })
                this.props.change("Addname", "")
                this.props.change("exhibit", "")
                this.setState({ newExhibitType: "", addnewName: "" })

            }).catch((err) => {
                return err;
            });
    }
    /**
     * setting the state for adding the apa name 
     */
    addApaNames = async () => {
        await this.setState({ addName: true })
    }
    /**
     * Setting the exhibit type value 
     */
    setExhibitType = async (e) => {
        await this.setState({ setExhibitType: e.value })
    }
    /**
     * 
     * @param {*} exhibit 
     * @returns Saving the api name functionality
     */
    saveName = async (exhibit) => {
        let sellerAPANamesID, body
        // if (exhibit === "Exhibit-A") {
        let z = this.state.exhibit1Array.find(elem => elem.value === this.state.oldName)
        if (z) {
            //with z values hit the api call
            sellerAPANamesID = z.SellerAPANamesID
        }
        await this.setState({ isLoading: true, addName: false, editNames: false })
        if (exhibit === "debtors") {
            body = {
                sellerAPAName: this.state.newName,
                dealID: JSON.stringify(this.state.dimSellerId),
                sellerAPANamesID: sellerAPANamesID
            }
        } else {
            body = {
                sellerAPAName: this.state.newName,
                dealID: JSON.stringify(this.state.dimSellerId),
                exhibitType: exhibit,
                sellerAPANamesID: sellerAPANamesID
            }
        }
        return fetch('PUT', exhibit === "debtors" ? apiCalls.Debtor : apiCalls.SellerAPAName, body)
            .then(async (response) => {
                if (response && response.respMessage) {
                    // this.cancelReset()
                    this.refreshApaNames(exhibit)
                } else if (response && response.errorMessage) {

                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                    })
                }
                await this.setState({ isLoading: false })
            }).catch((err) => {
                return err;
            });

    }
    checkDefaultValues = async (e) => {

        if (this.state.EntityName === null || this.state.SellerNickName === null || this.state.LeadSeller === null || this.state.AzureStorageDirectory === null || this.state.OpraId === null) {
            this.setState({ key2: "main" })
        }
        if ((this.state.dealType === "BK" || this.state.dealType !== "CR") && this.state.LeadCaseNumber === null) {
            this.setState({ key2: "main" })
        }

    }

    /**
     * 
     * @param {*} e 
     * Changing the check box to dropdown for soi and headquarter state
     */
    keyNameChecked = (e) => {
        if (e === "StateofIncorporation") {
            if (this.state.keyNameChecked) {
                this.setState({ stateofIncorporationIncluded: false })
            } else {
                this.setState({ stateofIncorporationIncluded: true })
            }
            this.setState({ keyNameChecked: this.state.keyNameChecked ? false : true })

        }
        if (e === "HeadquartersState") {
            if (this.state.keyNameCheckedState) {
                this.setState({ headquartersStateExcluded: false })
            } else {
                this.setState({ headquartersStateExcluded: true })
            }
            this.setState({ keyNameCheckedState: this.state.keyNameCheckedState ? false : true })

        }
    }

    /**
     * Copy functionality for exhibits
     */
    copyToClipboard = async (type) => {
        if (type && type === "exhibit-A") {
            navigator.clipboard.writeText(this.state.exhibit1String.join("\n"))
            await this.setState({ copiedA: true })
        } else {
            navigator.clipboard.writeText(this.state.exhibit2String.join("\n"))
            await this.setState({ copiedB: true })
        }

        return true
    }
    /**
     * 
     * @param {*} rowData 
     * @param {*} column 
     * @returns Using to assign the values to grid for seller signatories
     */
    hoverTemplate = (rowData, column) => {

        return <span className='' data-toggle="tool-tip"
            title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
            {rowData[column.field] ? rowData[column.field] : ""}
        </span >

    }
    /**
     * 
     * @param {*} rowData 
     * @returns For editing the seller signatories
     */
    editTemplate = (rowData) => {
        return <span >
            <Button color="primary"
                style={{ padding: "7px 25px" }}
                outline
                className="mb-0 ml-0 mr-2"
                onClick={() => this.openSignatoryModal(rowData)}
            >
                Edit
            </Button>
        </span >

    }
    /**
     * 
     * @param {*} rowData 
     * Open signatory model for the signatory adding
     */
    openSignatoryModal = async (rowData) => {
        await this.setState({ openSignatoryModal: true, editsignatoryData: rowData ? rowData : {} })
    }

    /**
     * 
     * @param {*} handleSubmit 
     * @param {*} dealtype 
     * @returns the view for all names and tabs for the deal screen
     */

    getModalBody(handleSubmit, dealtype) {
        const { type, view, rowData, preSalePermission } = this.props
        return (
            <div style={{ width: "100%" }}>
                <form className="form w-100" onSubmit={handleSubmit(this.submit)}>
                    <ModalBody className={"modal__body edit_modal"} style={this.state.key2 === "main" ? { height: "630px" } : { height: "100%" }}>
                        <Card className='pb-0 '>
                            {/* <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" /> */}
                            <CardBody className=' p-0'
                            >
                                <div className='row mx-1 mt-3 col-sm-12'>
                                    <div className='row ml-0 col-sm-6  mr-0'
                                        style={this.state.nameChild ? { display: "block" } : { display: "none" }}
                                    >
                                        <label className="form__form-group-label">Subsidiary Estates</label>
                                        <div className='usermessagesText subsidary d-block' style={{ height: "90px", overflow: "auto", border: "1px solid lightgray" }}>
                                            <ul style={{ borderBottom: "none" }} className="mr-0 pl-0">
                                                {this.props.rowData && this.props.rowData.subsidiaryEstates && this.props.rowData.subsidiaryEstates.map(function (name, index) {
                                                    return <li style={{ listStyle: "none" }}>{name}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <span className="formError m-2 mt-4" style={{ bottom: 0, position: "relative" }}>{this.state.apierrorResponse ? <span style={{ height: 30, color: this.state.apierrorcolor }}>{this.state.apierrorMessage}</span> : null}</span>
                                    <span className="formError m-2 mt-4" style={{ bottom: 0, position: "relative" }}>{this.state.apisuccResponse ? <span style={{ height: 30, color: "green" }}>{this.state.apisuccMessage}</span> : null}</span>

                                    {this.state.key2 === "apanames" ? null : <div className='ml-auto'>
                                        {this.state.sellerViewType ? null :
                                            <Button
                                                // style={{ float: "right" }}
                                                color='primary'
                                                className={type === "edit" ? "mb-0 mt-0" : "mb-0 "}
                                                outline
                                                onClick={(e) => this.checkDefaultValues(e)}
                                                disabled={(type === 'edit' && preSalePermission === "Edit") || type !== 'edit' ? false : true}
                                                type="submit">

                                                <FontAwesomeIcon
                                                    style={{ width: "15px" }}
                                                    icon={faSave}
                                                />
                                                {type === 'edit'
                                                    ?
                                                    'Update'
                                                    : 'Save'
                                                }
                                            </Button>
                                        }
                                        <Button color='danger' outline type="button" className={type === 'edit' ? " ml-1 mb-0 mt-0" : "mr-auto ml-1 mb-0 "} disabled={(type === 'edit' && this.props.preSalePermission === "Edit") || type !== 'edit' ? false : true} onClick={this.cancelReset}>
                                            <FontAwesomeIcon
                                                style={{ width: "15px" }}
                                                icon={faArrowCircleLeft}

                                            />
                                            {('Back') ? ('Cancel') : 'Cancel'} </Button>
                                    </div>}

                                    <div style={{ width: "100%" }}>
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={this.state.key2}
                                            onSelect={(k) => this.setState({ key2: k })}
                                            className="mb-3"
                                        >

                                            <Tab eventKey="main" title="Lead Seller">
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    {this.state.getMainTabFields && this.state.getMainTabFields.length > 0 ? this.state.getMainTabFields.map((item, index) => (
                                                        < div className={"col-sm-6 "}
                                                            style={((this.state.dealType === "CR" && (item.dealType === "BK"))) || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || ((item.include === "false" && !this.state.stateofIncorporationIncluded)) || ((item.include === "true" && this.state.stateofIncorporationIncluded)) || ((item.include1 === "false" && !this.state.headquartersStateExcluded)) || ((item.include1 === "true" && this.state.headquartersStateExcluded)) ? { display: "none" } : { display: "block" }}
                                                        >
                                                            <div className="form__form-group pb-1">
                                                                <label className="form__form-group-label">{item.label} {item.type === "number" ? <span className="info-color ml-1">{" (Numerical value only )"}</span> : null}{!((item.name === "AzureStorageDirectory" || item.name === "LeadSeller" || item.name === "DealStage" || item.name === "CoreOpsTeam") && this.state.dealStatusflag) && required && item.required ? <FontAwesomeIcon icon={faStarOfLife}
                                                                    className='pl-1'
                                                                    color={item.name === "District" || item.name === "Division" ? "white" : 'red'}
                                                                    data-toggle="tool-tip"
                                                                    title="Mandotary Field"
                                                                    style={{ width: 8 }}
                                                                /> : ''}{item.name === "StateofIncorporation" || item.name === "HeadquartersState" ?
                                                                    <input
                                                                        style={{ marginTop: 3, height: 16 }}
                                                                        className='col-1 mr-0 pl-0'
                                                                        onChange={(e) => this.keyNameChecked(item.name)}
                                                                        component={CheckBox}
                                                                        label={"Key"}
                                                                        type="checkbox"
                                                                        checked={item.name === "StateofIncorporation" ? this.state.keyNameChecked : this.state.keyNameCheckedState}
                                                                    /> : null

                                                                    }</label>

                                                                <div className='form__form-group-field kanban_notesfield'>
                                                                    {(item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                                                                        <Field
                                                                            type={item.type}
                                                                            name={item.name}
                                                                            component={DefaultInput}
                                                                            maxLength={item.maxLength}
                                                                            id={item.name}
                                                                            onChange={(e) => this.setrequiredstateFiels(e, item.name)}
                                                                            validate={((item.name === "AzureStorageDirectory" || item.name === "LeadSeller") && this.state.dealStatusflag) ? null : (this.state.dealType === "CR" && (item.dealType === "BK" || item.dealType !== "CR")) || (item.name === "District" || item.name === "Division") || !item.required ? null : [required]}
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                        : item.type === 'date' ?
                                                                            <Field
                                                                                id={true}
                                                                                name={item.name}
                                                                                component={DatePicker}
                                                                                type={"text"}
                                                                                validate={(this.state.dealType === "CR" && (item.dealType === "BK" || item.dealType !== "CR")) || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                                                placeholder={item.placeholder}
                                                                                style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                                            /> : item.type === 'dropdownDeal' ?

                                                                                < Field
                                                                                    name={item.name}
                                                                                    component={Select}
                                                                                    style={this.state.formType && this.state.formType === "Edit" ? { display: "block" } : { display: "none" }}
                                                                                    options={item.name === "APAType" && (this.state.dealType === "BK" || this.stata.dealType !== "CR") ? this.state.bkAPA : item.name === "APAType" && this.state.dealType === "CR" ? this.state.crAPA : item.name === "DealStage" ? this.state.dealStageArray : item.name === "DealStatus" ? this.state.dealStatusrray : item.name === "DealType" && this.state.dealTypeArray ? this.state.dealTypeArray : item.options}
                                                                                    onChange={(e) => this.caseTransferDate(e, item.name)}                                                                                    // onChange={(e) => this.handleChange(e)}
                                                                                    placeholder={item.placeholder}
                                                                                    validate={!item.required ? null : [required]}

                                                                                // isDisable={this.state.formType && this.state.formType === "Edit" ? false : true}
                                                                                /> : item.type === 'dropdown' ?
                                                                                    <Field
                                                                                        name={item.name}
                                                                                        component={Select}
                                                                                        validate={((item.name === "DealStage" || item.name === "CoreOpsTeam") && this.state.dealStatusflag) ? null : item.required ? [required] : null}
                                                                                        style={item.include === "true" || item.include1 === "true" ? { display: "none" } : { display: "block" }}
                                                                                        options={item.name === "APAType" && (this.state.dealType === "BK" || this.stata.dealType !== "CR") ? this.state.bkAPA : item.name === "APAType" && this.state.dealType === "CR" ? this.state.crAPA : item.name === "DealStage" ? this.state.dealStageArray : item.name === "DealStatus" ? this.state.dealStatusrray : item.name === "CoreOpsTeam" ? this.state.taskDepartmentItems : item.options}
                                                                                        onChange={(e) => this.caseTransferDate(e, item.name)}                                                                                    // onChange={(e) => this.handleChange(e)}
                                                                                        placeholder={item.placeholder}
                                                                                        isDisable={item.disable ? item.disable : false}
                                                                                    /> : item.type === "multiselect" ?

                                                                                        <MultiSelect
                                                                                            name={item.name}
                                                                                            value={item.name === "UsLocations" ? this.state.usL : item.name === "InternationalLocations" ? this.state.intL : item.name === "SICCode" ? this.state.sic : null}
                                                                                            style={{ width: "100%", height: 32 }} placeholder={item.placeholder}
                                                                                            maxSelectedLabels={1}
                                                                                            options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                            onChange={(e) => this.changeUsLoc(e, item.name)}

                                                                                        /> : item.type === "number" ?
                                                                                            <Field
                                                                                                name={item.name}
                                                                                                component={DefaultInput}
                                                                                                type={"number"}
                                                                                                onChange={(e) => this.setrequiredstateFiels(e, item.name)}
                                                                                                options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                                placeholder={item.placeholder}
                                                                                                validate={!item.required ? null : [required]}
                                                                                                isDisable={item.name === "OpraId" && this.state.formType === "Edit" ? true : false}
                                                                                            />
                                                                                            : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}

                                                </div>
                                            </Tab>

                                            {this.state.role && this.state.role === "Admin" ?
                                                <Tab eventKey="confidential" title="Confidential">
                                                    <div className="row mx-1 mt-3 col-sm-12">
                                                        {this.state.getConfidentialFields && this.state.getConfidentialFields.length > 0 ? this.state.getConfidentialFields.map((item, index) => (

                                                            < div className={"col-sm-6 "} style={((this.state.dealType === "CR" && (item.dealType === "BK"))) || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? { display: "none" } : { display: "block" }} >
                                                                <div className="form__form-group pb-1">
                                                                    <label className="form__form-group-label">{item.label}</label>
                                                                    <div className='form__form-group-field kanban_notesfield'>
                                                                        {(item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                                                                            <Field
                                                                                type={item.type}
                                                                                name={item.name}
                                                                                component={DefaultInput}
                                                                                maxLength={item.maxLength}
                                                                                id={item.name}
                                                                                placeholder={item.placeholder}
                                                                            />
                                                                            : item.type === 'date' ?
                                                                                <Field
                                                                                    id={true}
                                                                                    name={item.name}
                                                                                    component={DatePicker}
                                                                                    type={"text"}
                                                                                    placeholder={item.placeholder}
                                                                                    style={item.name === "CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                                                /> : item.type === 'dropdown' ?
                                                                                    <Field
                                                                                        name={item.name}
                                                                                        component={Select}
                                                                                        options={item.options}
                                                                                        onChange={(e) => this.changeCaseStatus(e, item.name)}
                                                                                        placeholder={item.placeholder}
                                                                                        isDisable={item.disable ? item.disable : false}
                                                                                    /> : item.type === "number" ?
                                                                                        <Field
                                                                                            name={item.name}
                                                                                            component={DefaultInput}
                                                                                            type={"number"}
                                                                                            options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                            placeholder={item.placeholder}
                                                                                        />
                                                                                        : null}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )) : null}


                                                    </div>
                                                </Tab> : null}

                                            <Tab eventKey="apa" title="APA">
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    {this.state.getAPATabFields && this.state.getAPATabFields.length > 0 ? this.state.getAPATabFields.map((item, index) => (

                                                        < div className={"col-sm-6 "} style={(this.state.dealType === "CR" && (item.dealType === "BK")) || ((item.name === "AddendumDate" || item.name === "APAAddendumDetails") && !this.state.APAAddendumDate1) || ((item.name === "RCSNotes" || item.name === "RCSHoldingPeriod" || item.name === "RCSClaimsDistributionDate" || item.name === "RCSNoOfCreditors" || item.name === "RCSApproxClaimsDistributionDate" || item.name === "RCSOakPointAcquireDate" || item.name === "RCSDistributionAmount" || item.name === "RCSOfCreditors" || item.name === "RCSMotionFiled" || item.name === "RCSBankContact" || item.name === "RCSStatus") && !this.state.RCS) || (item.name === "ModifiedPowerOfAttorneyProvision" && !this.state.LimitedPowerOfAttorney) ? { display: "none" } : { display: "block" }} >

                                                            <div className="form__form-group pb-1">
                                                                <label className="form__form-group-label">{item.label}{item.type === "number" ? <span className="info-color ml-1">{"(Numerical value only )"}</span> : null}</label>
                                                                <div className='form__form-group-field kanban_notesfield'>


                                                                    {(item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                                                                        <Field
                                                                            type={item.type}
                                                                            name={item.name}
                                                                            component={item.type === "textarea" ? DefaultTextArea : DefaultInput}
                                                                            id={item.name}
                                                                            maxLength={item.maxLength}
                                                                            // validate={[required]}
                                                                            // validate={(item.name==="APAAddendumDate" && !this.state.APAAddendumDate1) ? null : [required]}
                                                                            placeholder={item.placeholder}
                                                                            style={item.name === "APAAddendumDetails" || item.name === "RCSNotes" || item.name === "RCSHoldingPeriod" || item.name === "RCSDistributionAmount" || item.name === "RCSOfCreditors" || item.name === "RCSMotionFiled" || item.name === "RCSBankContact" || item.name === "RCSStatus" ? { display: "none" } : { display: "block" }}

                                                                        />
                                                                        : item.type === 'date' ?
                                                                            <Field
                                                                                id={true}
                                                                                name={item.name}
                                                                                component={DatePicker}
                                                                                // validate={(item.name==="APAAddendumDate" && !this.state.APAAddendumDate1) ? null : [required]}
                                                                                type={"text"}
                                                                                placeholder={item.placeholder}
                                                                                style={item.name === "AddendumDate" || item.name === "RCSApproxClaimsDistributionDate" || item.name === "RCSOakPointAcquireDate" ? { display: "none" } : { display: "block" }}

                                                                            // isDisable={item.isDisable ? item.isDisable : false}
                                                                            />
                                                                            : item.type === 'dropdown' ?
                                                                                <Field

                                                                                    name={item.name}
                                                                                    component={Select}
                                                                                    options={item.options}
                                                                                    onChange={(e) => this.APAAddendumDate(e, item.name)}
                                                                                    // onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
                                                                                    // onChange={(e) => this.handleChange(e)}
                                                                                    // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                                                    placeholder={item.placeholder}
                                                                                    isDisable={item.disable ? item.disable : false}
                                                                                /> : item.type === "number" ?
                                                                                    <Field
                                                                                        name={item.name}
                                                                                        component={DefaultInput}
                                                                                        type={"number"}
                                                                                        options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                        // onChange={(e) => this.APAAddendumDate(e, item.name)}
                                                                                        placeholder={item.placeholder}
                                                                                    // id={item.id}
                                                                                    // value={item.value}

                                                                                    /> : item.type === "currency" ?
                                                                                        <CurrencyInput
                                                                                            // id="input-example"
                                                                                            name={item.name}
                                                                                            prefix="$"
                                                                                            allowDecimals={true}
                                                                                            value={this.state.PurchasePrice ? this.state.PurchasePrice : 0}
                                                                                            placeholder="Purchase Price"
                                                                                            intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                                                                                            // defaultValue={this.state.PurchasePrice ? this.state.PurchasePrice : 0}
                                                                                            decimalsLimit={2}
                                                                                            onValueChange={(value) => this.setValue1(value, item.name)}
                                                                                        />


                                                                                        : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}
                                                    {this.state.sellerSignatories ?
                                                        <div>
                                                            {/* <div style={{ marginLeft: "633px" }}>
                                                                {/* <Button color='primary' className="btnRadius" onClick={() => this.openSignatoryModal()}>
                                                                    <FontAwesomeIcon
                                                                        icon={faPlus}
                                                                        className='mt-0 mr-0' size='lg'
                                                                        data-toggle="tool-tip" title="List"
                                                                    />
                                                                </Button> */}

                                                            {/* </div> */}
                                                            <div className='d-flex'>

                                                                <DataTable value={this.state.sellerSignatories} scrollable={true} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" style={{ width: "612px", marginLeft: 15 }}
                                                                >
                                                                    <Column field="CompanyID" header="Company ID" bodyStyle={bodyStyle} headerStyle={{
                                                                        width: 120,
                                                                        textAlign: "center",
                                                                    }} body={this.hoverTemplate}
                                                                    />
                                                                    <Column field="PersonName" header="Seller Signatory" bodyStyle={bodyStyleRight} body={this.hoverTemplate} headerStyle={headerStyle} />
                                                                    <Column field="FullTitle" header="Title" bodyStyle={bodyStyleRight} body={this.hoverTemplate} headerStyle={{
                                                                        width: 250,
                                                                        textAlign: "center",
                                                                    }} />
                                                                    <Column field="" header="Edit" bodyStyle={bodyStyleRight} body={this.editTemplate} headerStyle={{
                                                                        width: 90,
                                                                        textAlign: "center",
                                                                    }} />

                                                                </DataTable>
                                                                <Button color="primary"
                                                                    style={{ padding: "7px 25px", height: "35px" }}
                                                                    outline
                                                                    className="mb-0 ml-1"
                                                                    disabled={this.props.preSalePermission === "Edit" ? false : true}
                                                                    onClick={() => this.openSignatoryModal()}                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon='plus'
                                                                        className='Addbtn' size='sm'
                                                                        data-toggle="tool-tip" title={("Add")}
                                                                    // onClick={(e) => this.openFormModal('add')}
                                                                    />
                                                                </Button>

                                                            </div>

                                                        </div> : "No Companies connected for creating seller signatory"}


                                                </div>
                                            </Tab>


                                            <Tab eventKey={this.state.dealType === "CR" ? "apanames" : "debtors"} title={this.state.dealType === "CR" ? "APA/Affidavit Names" : "Debtors"}>
                                                <div>
                                                    <ModalBody className='modal__body edit_modal' style={{
                                                        padding: 10,
                                                        // overflowX: "hidden",
                                                        // overflowY: "clip",
                                                        height: 580
                                                    }}>
                                                        {this.state.dealType === "CR" ?
                                                            <Card className='pb-0 ml-4'>
                                                                <CardBody className='row p-0 d-block'>

                                                                    {this.state.sellerViewType ? null : <Button color="primary"
                                                                        className="mb-0 mt-2 float-right"
                                                                        disabled={this.props.preSalePermission === "Edit" ? false : true}
                                                                        outline
                                                                        onClick={this.bulkUploadMoadal}
                                                                    >
                                                                        <FontAwesomeIcon icon='upload'
                                                                            onClick={this.bulkUploadMoadal}
                                                                        />Bulk Upload
                                                                    </Button>}


                                                                    {/* <Loader loader={this.state.isLoading} progress={this.state.progress} /> */}
                                                                    {this.state.sellerViewType ? null :
                                                                        <Button
                                                                            color='primary'
                                                                            outline
                                                                            type="button"
                                                                            disabled={this.props.preSalePermission === "Edit" ? false : true}
                                                                            className="mb-0 mt-2 float-right" onClick={() => this.addApaNames()}>Add APA Names
                                                                        </Button>
                                                                    }
                                                                    {this.state.addName || this.state.editNames ? null : <label style={{ marginLeft: "332px" }}>Select Exhibit Type</label>}
                                                                    {this.state.addName || this.state.editNames ? null :
                                                                        <Dropdown
                                                                            style={{ marginLeft: "12px", lineHeight: 2.1 }}
                                                                            // placeholder={"Exhibit Type"}
                                                                            // appendTo={document.body}
                                                                            name={this.state.setExhibitType}
                                                                            value={this.state.setExhibitType}
                                                                            options={[{ label: "APA", value: "APA" }, { label: "APA Exhibit A - Sellers", value: "APA Exhibit A - Sellers" }, { label: "APA Exhibit B - Inactive Entities", value: "APA Exhibit B - Inactive Entities" }, { label: "APA Exhibit A.1 - Sellers", value: "APA Exhibit A.1 - Sellers" }, { label: "APA Exhibit A.2 - Inactive Entities", value: "APA Exhibit A.2 - Inactive Entities" }, { label: "APA Exhibit A - Related Entities", value: "APA Exhibit A - Related Entities" }, { label: "APA Exhibit A - Dormant Entities", value: "APA Exhibit A - Dormant Entities" }, { label: "Affidavit Exhibit A - Dormant Entities", value: "Affidavit Exhibit A - Dormant Entities" }, { label: "Affidavit Exhibit A - Related Entities", value: "Affidavit Exhibit A - Related Entities" },]
                                                                            }
                                                                            onChange={(e) => this.setExhibitType(e)}
                                                                        />

                                                                    }
                                                                    {this.state.addName || this.state.editNames ? null :
                                                                        <Button
                                                                            style={{ marginLeft: "5px" }}
                                                                            color='primary'
                                                                            onClick={() => { this.setNewExhibit() }}
                                                                            className={"mb-0 "}
                                                                            disabled={this.props.preSalePermission === "Edit" ? false : true}
                                                                            outline
                                                                        // type="submit"
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                style={{ width: "15px" }}
                                                                                icon={faSave}
                                                                            />
                                                                            {'Save'}
                                                                        </Button>
                                                                    }

                                                                    {this.state.editNames ? <div className="col-sm-5 ml-0">
                                                                        <label className="form__form-group-label">APA Name{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                                            className='pl-1'
                                                                            color='red'
                                                                            data-toggle="tool-tip"
                                                                            title="Mandotary Field"
                                                                            style={{ width: 8 }}
                                                                        /> : ''}</label>
                                                                        <Field
                                                                            // type={item.type}
                                                                            name={"Editname"}
                                                                            component={DefaultInput}
                                                                            validate={required}
                                                                            onChange={(e) => this.setNewName(e)}

                                                                        // id={item.name}
                                                                        // validate={this.state.dealType==="CR" && item.dealType==="BK" ? null : [required]}
                                                                        // placeholder={}
                                                                        />
                                                                        <Button color='danger' outline type="button" className="mr-auto ml-1 mb-0 " onClick={this.cancelName}>
                                                                            <FontAwesomeIcon
                                                                                style={{ width: "15px" }}
                                                                                // color="red"
                                                                                icon={faArrowCircleLeft}
                                                                            // data-toggle="tool-tip"
                                                                            // title={"save"}
                                                                            />
                                                                            {('Back') ? ('Cancel') : 'Cancel'} </Button>
                                                                        {this.state.sellerViewType ? null :
                                                                            <Button
                                                                                style={{ float: "right" }}
                                                                                color='primary'
                                                                                onClick={() => { this.saveName(this.state.exhibit) }}
                                                                                className={"mb-0 "}
                                                                                outline
                                                                            // type="submit"
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    style={{ width: "15px" }}
                                                                                    icon={faSave}
                                                                                />
                                                                                {'Update'}
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                        : null}
                                                                    {this.state.addName ?
                                                                        <div>
                                                                            <div className="col-sm-5 ml-0">
                                                                                <div className="d-flex" >
                                                                                    <div className="col-sm-12 px-3">
                                                                                        <label className="form__form-group-label">Exhibit{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                                                            className='pl-1'
                                                                                            color='red'
                                                                                            data-toggle="tool-tip"
                                                                                            title="Mandotary Field"
                                                                                            style={{ width: 8 }}
                                                                                        /> : ''}</label>
                                                                                        <Field
                                                                                            name={"exhibit"}
                                                                                            component={Select}
                                                                                            validate={[required]}
                                                                                            options={[{ label: "APA", value: "APA" }, { label: "APA Exhibit A - Sellers", value: "APA Exhibit A - Sellers" }, { label: "APA Exhibit B - Inactive Entities", value: "APA Exhibit B - Inactive Entities" }, { label: "APA Exhibit A.1 - Sellers", value: "APA Exhibit A.1 - Sellers" }, { label: "APA Exhibit A.2 - Inactive Entities", value: "APA Exhibit A.2 - Inactive Entities" }, { label: "APA Exhibit A - Related Entities", value: "APA Exhibit A - Related Entities" }, { label: "APA Exhibit A - Dormant Entities", value: "APA Exhibit A - Dormant Entities" }, { label: "Affidavit Exhibit A - Dormant Entities", value: "Affidavit Exhibit A - Dormant Entities" }, { label: "Affidavit Exhibit A - Related Entities", value: "Affidavit Exhibit A - Related Entities" },]}
                                                                                            // onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
                                                                                            onChange={(e) => this.handleNewChange(e)}
                                                                                            // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                                                            placeholder={"Exhibit"}
                                                                                        // isDisable={item.disable ? item.disable : false}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-11 px-3">
                                                                                        <label className="form__form-group-label">APA Name{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                                                            className='pl-1'
                                                                                            color='red'
                                                                                            data-toggle="tool-tip"
                                                                                            title="Mandatory Field"
                                                                                            style={{ width: 8 }}
                                                                                        /> : ''}</label>
                                                                                        <Field
                                                                                            // type={item.type}
                                                                                            name={"Addname"}
                                                                                            component={DefaultInput}
                                                                                            onChange={(e) => this.setNewAddName(e)}
                                                                                            validate={[required]}
                                                                                            // id={item.name}
                                                                                            // validate={this.state.dealType==="CR" && item.dealType==="BK" ? null : [required]}
                                                                                            placeholder={"APA Name"}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: "80%" }}>
                                                                                <div className=" float-right">
                                                                                    {/* <div className="px-3 float-right"> */}
                                                                                    <Button color='danger' outline type="button" className="mr-2 ml-1 mb-0 " onClick={this.cancelNewName}>
                                                                                        <FontAwesomeIcon
                                                                                            style={{ width: "15px" }}
                                                                                            // color="red"
                                                                                            icon={faArrowCircleLeft}
                                                                                        // data-toggle="tool-tip"
                                                                                        // title={"save"}
                                                                                        />
                                                                                        {('Back') ? ('Cancel') : 'Cancel'} </Button>
                                                                                    {this.state.sellerViewType ? null :
                                                                                        <Button
                                                                                            // style={{ float: "right" }}
                                                                                            color='primary'
                                                                                            onClick={this.state.newExhibitType && this.state.newExhibitType.length > 0 && this.state.addnewName && this.state.addnewName.length > 0 ? () => { this.saveNewName() } : null}
                                                                                            className={"mb-0 "}
                                                                                            outline
                                                                                            // type="submit"
                                                                                            disable={this.state.isDisableSubmitButton}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                style={{ width: "15px" }}
                                                                                                icon={faSave}
                                                                                            />
                                                                                            {'Save'}
                                                                                        </Button>
                                                                                    }
                                                                                    {/* </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        : null}

                                                                    <div >
                                                                        <Row>
                                                                            <div className="col-sm-12 row" style={{ width: "100%" }}>


                                                                                <div className="col-sm-8 pl-0 pr-0">
                                                                                    <Row className="ml-0 mr-0" >
                                                                                        <label><b>{"Exhibit"}</b></label>
                                                                                        <FontAwesomeIcon icon={faCopy}
                                                                                            className='ml-2'
                                                                                            color='grey'
                                                                                            data-toggle="tool-tip"
                                                                                            // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
                                                                                            onClick={() => { this.copyToClipboard('exhibit-A') }}
                                                                                            title={"Copy"}
                                                                                            style={{ width: 18 }}
                                                                                        />
                                                                                        <span className="topbar__centerresponse topBarImageAlignment" style={{ color: this.state.apierrorcolor }}>{this.state.apierrorResponse ? this.state.apierrorMessage : null}</span>
                                                                                        <span className="topbar__centerresponse topBarImageAlignment" >{this.state.apisuccResponse ? this.state.apisuccMessage : null}</span>

                                                                                        <span className="topbar__centerresponse topBarImageAlignment" >{this.state.copiedA ? "Copied Successfully." : null}</span>
                                                                                        <span className="topbar__centerresponse topBarImageAlignment" >{this.state.moveExhibitA ? "APA Names moved from Exhitbit A to B" : null}</span>

                                                                                        <Card className="pb-0" style={{ minHeight: "160px", height: '550px', overflow: 'auto', border: "1px solid grey" }}>

                                                                                            {this.state.exhibit1Array && this.state.exhibit1Array.length > 0 ? this.state.exhibit1Array.map((item, index) => {
                                                                                                return <div className='d-flex m-1'>
                                                                                                    <input key={`up-${index}`}
                                                                                                        style={{ marginTop: 3, height: 16 }}
                                                                                                        className='col-1 mr-0 pl-0'
                                                                                                        onChange={(e) => this.getExhi1("existing", item, item.label)}
                                                                                                        name={`up-${this.state.upCount}-${index}`}
                                                                                                        id={`up-${this.state.upCount}-${index}`}
                                                                                                        component={CheckBox}
                                                                                                        label={item.label}
                                                                                                        type="checkbox"
                                                                                                        //value={item.show}
                                                                                                        checked={item.show}
                                                                                                        defaultChecked={item.show}
                                                                                                    />
                                                                                                    <span
                                                                                                        onClick={() => { this.editNames(item.label, item.ExhibitType) }} style={{ cursor: "pointer" }}>

                                                                                                        {item.label + " -- " + item.ExhibitType}</span>
                                                                                                    {/* <label className='mb-0'>{item.label}</label> */}
                                                                                                </div>
                                                                                            }) : "No Exhibits Found"}
                                                                                        </Card>
                                                                                    </Row>




                                                                                </div>


                                                                            </div>
                                                                        </Row>

                                                                    </div>
                                                                </CardBody>
                                                            </Card> :
                                                            <Card className='pb-0 ml-4'>
                                                                <CardBody className='row p-0 d-block'>

                                                                    {this.state.sellerViewType ? null : <Button color="primary"
                                                                        className="mb-0 mt-2 float-right"
                                                                        outline
                                                                        onClick={this.bulkUploadMoadal}
                                                                    >
                                                                        <FontAwesomeIcon icon='upload'
                                                                            onClick={this.bulkUploadMoadal}
                                                                        />Bulk Upload
                                                                    </Button>}


                                                                    {/* <Loader loader={this.state.isLoading} progress={this.state.progress} /> */}
                                                                    {this.state.sellerViewType ? null :
                                                                        <Button
                                                                            color='primary'
                                                                            outline
                                                                            type="button"

                                                                            className="mb-0 mt-2 float-right" onClick={() => this.addApaNames()}>Add Debtors
                                                                        </Button>
                                                                    }


                                                                    {this.state.editNames ? <div className="col-sm-5 ml-0">
                                                                        <label className="form__form-group-label">Debtor Name{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                                            className='pl-1'
                                                                            color='red'
                                                                            data-toggle="tool-tip"
                                                                            title="Mandotary Field"
                                                                            style={{ width: 8 }}
                                                                        /> : ''}</label>
                                                                        <Field
                                                                            // type={item.type}
                                                                            name={"Editname"}
                                                                            component={DefaultInput}
                                                                            validate={required}
                                                                            onChange={(e) => this.setNewName(e)}
                                                                        />
                                                                        <Button color='danger' outline type="button" className="mr-auto ml-1 mb-0 " onClick={this.cancelName}>
                                                                            <FontAwesomeIcon
                                                                                style={{ width: "15px" }}
                                                                                // color="red"
                                                                                icon={faArrowCircleLeft}
                                                                            // data-toggle="tool-tip"
                                                                            // title={"save"}
                                                                            />
                                                                            {('Back') ? ('Cancel') : 'Cancel'} </Button>
                                                                        {this.state.sellerViewType ? null :
                                                                            <Button
                                                                                style={{ float: "right" }}
                                                                                color='primary'
                                                                                onClick={() => { this.saveName("debtors") }}
                                                                                className={"mb-0 "}
                                                                                outline
                                                                            // type="submit"
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    style={{ width: "15px" }}
                                                                                    icon={faSave}
                                                                                />
                                                                                {'Update'}
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                        : null}
                                                                    {this.state.addName ?
                                                                        <div>
                                                                            <div className="col-sm-5 ml-0">
                                                                                <div className="d-flex" >

                                                                                    <div className="col-sm-11 px-3">
                                                                                        <label className="form__form-group-label">Debtor Name{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                                                            className='pl-1'
                                                                                            color='red'
                                                                                            data-toggle="tool-tip"
                                                                                            title="Mandatory Field"
                                                                                            style={{ width: 8 }}
                                                                                        /> : ''}</label>
                                                                                        <Field
                                                                                            // type={item.type}
                                                                                            name={"Addname"}
                                                                                            component={DefaultInput}
                                                                                            onChange={(e) => this.setNewAddName(e)}
                                                                                            validate={[required]}
                                                                                            // id={item.name}
                                                                                            // validate={this.state.dealType==="CR" && item.dealType==="BK" ? null : [required]}
                                                                                            placeholder={"Debtor Name"}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: "80%" }}>
                                                                                <div className=" float-right">
                                                                                    {/* <div className="px-3 float-right"> */}
                                                                                    <Button color='danger' outline type="button" className="mr-2 ml-1 mb-0 " onClick={this.cancelNewName}>
                                                                                        <FontAwesomeIcon
                                                                                            style={{ width: "15px" }}
                                                                                            // color="red"
                                                                                            icon={faArrowCircleLeft}
                                                                                        // data-toggle="tool-tip"
                                                                                        // title={"save"}
                                                                                        />
                                                                                        {('Back') ? ('Cancel') : 'Cancel'} </Button>
                                                                                    {this.state.sellerViewType ? null :
                                                                                        <Button
                                                                                            // style={{ float: "right" }}
                                                                                            color='primary'
                                                                                            onClick={this.state.addnewName && this.state.addnewName.length > 0 ? () => { this.saveNewName("debtors") } : null}
                                                                                            className={"mb-0 "}
                                                                                            outline
                                                                                        // type="submit"
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                style={{ width: "15px" }}
                                                                                                icon={faSave}
                                                                                            />
                                                                                            {'Save'}
                                                                                        </Button>
                                                                                    }
                                                                                    {/* </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        : null}

                                                                    <div >
                                                                        <Row>
                                                                            <div className="col-sm-12 row" style={{ width: "100%" }}>


                                                                                <div className="col-sm-8 pl-0 pr-0">
                                                                                    <Row className="ml-0 mr-0" >
                                                                                        <label><b>{"Debtors"}</b></label>
                                                                                        <FontAwesomeIcon icon={faCopy}
                                                                                            className='ml-2'
                                                                                            color='grey'
                                                                                            data-toggle="tool-tip"
                                                                                            // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
                                                                                            onClick={() => { this.copyToClipboard('exhibit-A') }}
                                                                                            title={"Copy"}
                                                                                            style={{ width: 18 }}
                                                                                        />
                                                                                        <span className="topbar__centerresponse topBarImageAlignment" >{this.state.apierrorResponse ? this.state.apierrorMessage : null}</span>
                                                                                        <span className="topbar__centerresponse topBarImageAlignment" >{this.state.apisuccResponse ? this.state.apisuccMessage : null}</span>

                                                                                        <span className="topbar__centerresponse topBarImageAlignment" >{this.state.copiedA ? "Copied Successfully." : null}</span>
                                                                                        <span className="topbar__centerresponse topBarImageAlignment" >{this.state.moveExhibitA ? "APA Names moved from Exhitbit A to B" : null}</span>

                                                                                        <Card className="pb-0" style={{ minHeight: "160px", height: '550px', overflow: 'auto', border: "1px solid grey" }}>

                                                                                            {this.state.exhibit1Array && this.state.exhibit1Array.length > 0 ? this.state.exhibit1Array.map((item, index) => {
                                                                                                return <div className='d-flex m-1'>

                                                                                                    <span
                                                                                                        onClick={() => { this.editNames(item.label, item.ExhibitType) }} style={{ cursor: "pointer" }}>

                                                                                                        {item.label}</span>
                                                                                                    {/* <label className='mb-0'>{item.label}</label> */}
                                                                                                </div>
                                                                                            }) : "No Debtors Found"}
                                                                                        </Card>
                                                                                    </Row>




                                                                                </div>


                                                                            </div>
                                                                        </Row>

                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        }


                                                    </ModalBody>
                                                </div >

                                            </Tab>

                                            <Tab eventKey="deal" title="Deal">
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    {this.state.getDealTabFields && this.state.getDealTabFields.length > 0 ? this.state.getDealTabFields.map((item, index) => (
                                                        <div className={"col-sm-6 "} style={((this.state.dealType === "BK") && item.dealType === "CR") || (this.state.dealType === "CR" && (item.dealType === "BK")) || (((item.name === "RequiredNotificationExpirationofRequirement" || item.name === "RequiredNotificationNoticeDates" || item.name === "RequiredNotificationNoticePeriod") && !this.state.RequiredNotificationofIdentifiedRemnants)) || (((item.name === "BidProceduresOverbidAmount" || item.name === "OverbidAmountNotes") && !this.state.OPPSaleMotionBidProcedures)) || (((item.name === "AncillaryAgreementTerms" || item.name === "AncillaryAgreementDate" || item.name === "AncillaryAgreementType") && !this.state.AncillaryAgreement)) ? { display: "none" } : { display: "block" }} >
                                                            <div className="form__form-group pb-1">
                                                                <label className="form__form-group-label">{item.label}</label>
                                                                <div className='form__form-group-field kanban_notesfield'>
                                                                    {(item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                                                                        <Field
                                                                            type={item.type}
                                                                            name={item.name}
                                                                            component={DefaultInput}
                                                                            maxLength={item.maxLength}
                                                                            id={item.name}
                                                                            style={item.name === "RequiredNotificationExpirationofRequirement" || item.name === "RequiredNotificationNoticePeriod" || item.name === "BidProceduresOverbidAmount" || item.name === "OverbidAmountNotes" || item.name === "AncillaryAgreementTerms" || item.name === "AncillaryAgreementType" ? { display: "none" } : { display: "block" }}

                                                                            // validate={[required]}
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                        : item.type === 'date' ?
                                                                            <Field
                                                                                id={true}
                                                                                name={item.name}
                                                                                component={DatePicker}
                                                                                type={"text"}
                                                                                style={item.name === "RequiredNotificationNoticeDates" || item.name === "AncillaryAgreementDate" ? { display: "none" } : { display: "block" }}

                                                                                placeholder={item.placeholder}
                                                                            // isDisable={item.isDisable ? item.isDisable : false}
                                                                            />
                                                                            : item.type === 'dropdown' ?
                                                                                <Field

                                                                                    name={item.name}
                                                                                    component={Select}
                                                                                    options={item.name === "APAType" && (this.state.dealType === "BK") ? this.state.ApaTypeArray : item.name === "APAType" && this.state.dealType === "CR" ? this.state.crTypeArray : item.options}
                                                                                    onChange={(e) => this.caseTransferDate(e, item.name)}
                                                                                    // onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
                                                                                    // onChange={(e) => this.handleChange(e)}
                                                                                    // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                                                    placeholder={item.placeholder}
                                                                                    isDisable={item.disable ? item.disable : false}
                                                                                /> : item.type === "number" ?
                                                                                    <Field
                                                                                        name={item.name}
                                                                                        component={DefaultInput}
                                                                                        type={"number"}
                                                                                        options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                        placeholder={item.placeholder}
                                                                                    // id={item.id}
                                                                                    // value={item.value}

                                                                                    />

                                                                                    : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}
                                                </div>
                                            </Tab>

                                            <Tab eventKey="Overview" title="Company Overview">
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    {this.state.getOverviewTabFields && this.state.getOverviewTabFields.length > 0 ? this.state.getOverviewTabFields.map((item, index) => (
                                                        < div className={"col-sm-6 "} style={((this.state.dealType === "CR" && (item.dealType === "BK"))) || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || ((item.include === "false" && !this.state.stateofIncorporationIncluded)) || ((item.include === "true" && this.state.stateofIncorporationIncluded)) || ((item.include1 === "false" && !this.state.headquartersStateExcluded)) || ((item.include1 === "true" && this.state.headquartersStateExcluded)) ? { display: "none" } : { display: "block" }} >
                                                            <div className="form__form-group pb-1">
                                                                <label className="form__form-group-label">{item.label}{item.name === "StateofIncorporation" || item.name === "HeadquartersState" ?
                                                                    <input
                                                                        style={{ marginTop: 3, height: 16 }}
                                                                        className='col-1 mr-0 pl-0'
                                                                        onChange={(e) => this.keyNameChecked(item.name)}
                                                                        component={CheckBox}
                                                                        label={"Key"}
                                                                        type="checkbox"
                                                                        checked={item.name === "StateofIncorporation" ? this.state.keyNameChecked : this.state.keyNameCheckedState}
                                                                    // defaultChecked={this.state.keyNameChecked}
                                                                    /> : null

                                                                }</label>
                                                                <div className='form__form-group-field kanban_notesfield'>
                                                                    {item.fixed ?
                                                                        <Field
                                                                            type={item.type}
                                                                            name={item.name}
                                                                            component={DefaultInput}
                                                                            maxLength={item.maxLength}
                                                                            isDisable={item.isDisable ? item.isDisable : false}
                                                                            id={item.name}
                                                                            def_value={item.name === "OPPSalesRep1" ? this.state.OPPSalesRep1 : ""}
                                                                            // validate={[required]}
                                                                            placeholder={item.placeholder}
                                                                        /> :
                                                                        (item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                                                                            <Field
                                                                                type={item.type}
                                                                                name={item.name}
                                                                                component={DefaultInput}
                                                                                maxLength={item.maxLength}
                                                                                id={item.name}
                                                                                style={item.name === "RequiredNotificationExpirationofRequirement" || item.name === "RequiredNotificationNoticePeriod" || item.name === "BidProceduresOverbidAmount" || item.name === "OverbidAmountNotes" || item.name === "AncillaryAgreementTerms" || item.name === "AncillaryAgreementType" ? { display: "none" } : { display: "block" }}

                                                                                // validate={[required]}
                                                                                placeholder={item.placeholder}
                                                                            />
                                                                            : item.type === 'date' ?
                                                                                <Field
                                                                                    id={true}
                                                                                    name={item.name}
                                                                                    component={DatePicker}
                                                                                    type={"text"}
                                                                                    style={item.name === "RequiredNotificationNoticeDates" || item.name === "AncillaryAgreementDate" ? { display: "none" } : { display: "block" }}

                                                                                    placeholder={item.placeholder}
                                                                                // isDisable={item.isDisable ? item.isDisable : false}
                                                                                />
                                                                                : item.type === 'dropdown' ?
                                                                                    <Field

                                                                                        name={item.name}
                                                                                        component={Select}
                                                                                        options={item.options}
                                                                                        onChange={(e) => this.caseTransferDate(e, item.name)}
                                                                                        // onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
                                                                                        // onChange={(e) => this.handleChange(e)}
                                                                                        // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                                                        placeholder={item.placeholder}
                                                                                        isDisable={item.disable ? item.disable : false}
                                                                                    /> : item.type === "number" ?
                                                                                        <Field
                                                                                            name={item.name}
                                                                                            component={DefaultInput}
                                                                                            type={"number"}
                                                                                            options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                            placeholder={item.placeholder}
                                                                                        // id={item.id}
                                                                                        // value={item.value}

                                                                                        /> : item.type === "multiselect" ?
                                                                                            // <Field
                                                                                            //     name={item.name}
                                                                                            //     component={MultiSelectDropDown}
                                                                                            //     options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                            //     placeholder={item.placeholder}
                                                                                            //     // id={item.id}
                                                                                            //     value={item.name === "UsLocations" ? this.state.usArray : null}

                                                                                            // />
                                                                                            <MultiSelect
                                                                                                name={item.name}
                                                                                                value={item.name === "UsLocations" ? this.state.usL : item.name === "InternationalLocations" ? this.state.intL : item.name === "SICCode" ? this.state.sic : null}
                                                                                                style={{ width: "100%", height: 32 }}
                                                                                                // appendTo={document.body}
                                                                                                // disabled={this.state.selectedSeller ? false : true}
                                                                                                // maxSelectedLabels={1}
                                                                                                placeholder={item.placeholder}
                                                                                                maxSelectedLabels={1}
                                                                                                options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                                onChange={(e) => this.changeUsLoc(e, item.name)}
                                                                                            />

                                                                                            : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}
                                                </div>
                                            </Tab>
                                            {this.state.dealType === "BK" || this.state.dealType !== "CR" ? <Tab eventKey="case" title="Case" >
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    {this.state.getCaseTabFields && this.state.getCaseTabFields.length > 0 ? this.state.getCaseTabFields.map((item, index) => (
                                                        <div className={"col-sm-6 "} style={(this.state.dealType === "CR" && (item.dealType === "BK" || item.dealType !== "CR")) || (((item.name === "CaseTransferDate" || item.name === "OriginatingJurisdiction") && !this.state.CaseTransfer1)) || (item.name === "CaseConversionDate" && !this.state.CaseConversion1) || ((item.name === "CaseStatusClosedDate" || item.name === "CaseStatusDismissalDate" || item.name === "ClosingDate") && !this.state.showCaseClosedDate) ? { display: "none" } : { display: "block" }} >
                                                            <div className="form__form-group pb-1">
                                                                <label className="form__form-group-label">{item.label}</label>
                                                                <div className='form__form-group-field kanban_notesfield'>
                                                                    {(item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                                                                        <Field
                                                                            type={item.type}
                                                                            name={item.name}
                                                                            component={DefaultInput}
                                                                            maxLength={item.maxLength}
                                                                            id={item.name}
                                                                            // validate={[required]}
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                        : item.type === 'date' ?
                                                                            <Field
                                                                                id={true}
                                                                                name={item.name}
                                                                                component={DatePicker}
                                                                                type={"text"}
                                                                                placeholder={item.placeholder}
                                                                                style={item.name === "CaseTransferDate" || item.name === "CaseConversionDate" || item.name === "CaseStatusClosedDate" || item.name === "CaseStatusDismissalDate" || item.name === "ClosingDate" || item.name === "OriginatingJurisdiction" ? { display: "none" } : { display: "block" }}
                                                                            // isDisable={item.isDisable ? item.isDisable : false}
                                                                            />
                                                                            : item.type === 'dropdown' ?
                                                                                <Field

                                                                                    name={item.name}
                                                                                    component={Select}
                                                                                    options={item.options}
                                                                                    onChange={(e) => this.caseTransferDate(e, item.name)}
                                                                                    // onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
                                                                                    // onChange={(e) => this.handleChange(e)}
                                                                                    // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                                                    placeholder={item.placeholder}
                                                                                    isDisable={item.disable ? item.disable : false}
                                                                                /> : item.type === "number" ?
                                                                                    <Field
                                                                                        name={item.name}
                                                                                        component={DefaultInput}
                                                                                        type={"number"}
                                                                                        options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                        placeholder={item.placeholder}
                                                                                    // id={item.id}
                                                                                    // value={item.value}

                                                                                    />

                                                                                    : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}


                                                </div>

                                            </Tab> : null}

                                            {/* <Tab eventKey="internal" title="Internal" >

                                            </Tab> */}
                                            {/* <Tab eventKey="businessEntity" title="Business Entity">
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    {this.state.getBusinessEntityFields && this.state.getBusinessEntityFields.length > 0 ? this.state.getBusinessEntityFields.map((item, index) => (

                                                        <div className={"col-sm-6 "} style={(this.state.dealType === "CR" && item.dealType === "BK") || ((item.include === "false" && !this.state.stateofIncorporationIncluded)) || ((item.include === "true" && this.state.stateofIncorporationIncluded)) || ((item.include1 === "false" && !this.state.headquartersStateExcluded)) || ((item.include1 === "true" && this.state.headquartersStateExcluded)) ? { display: "none" } : { display: "block" }} >
                                                            <div className="form__form-group pb-1">
                                                                <label className="form__form-group-label">{item.label}{item.type === "number" ? <span className="form__form-group-error">{"( Please Numerical value only )"}</span> : null}</label>
                                                                <div className='form__form-group-field kanban_notesfield'>
                                                                    {(item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                                                                        <Field
                                                                            type={item.type}
                                                                            name={item.name}
                                                                            component={DefaultInput}
                                                                            maxLength={item.maxLength}

                                                                            id={item.name}
                                                                            // validate={[required]}
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                        : item.type === 'date' ?
                                                                            <Field
                                                                                id={true}
                                                                                name={item.name}
                                                                                component={DatePicker}
                                                                                type={"text"}
                                                                                style={item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "block" }}

                                                                                placeholder={item.placeholder}
                                                                            // isDisable={item.isDisable ? item.isDisable : false}
                                                                            />
                                                                            : item.type === 'dropdown' ?
                                                                                <Field
                                                                                    name={item.name}
                                                                                    component={Select}
                                                                                    options={item.options}
                                                                                    onChange={(e) => this.caseTransferDate(e, item.name)}
                                                                                    style={item.include === "true" || item.include1 === "true" ? { display: "none" } : { display: "block" }}

                                                                                    // onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
                                                                                    // onChange={(e) => this.handleChange(e)}
                                                                                    // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                                                    placeholder={item.placeholder}
                                                                                    isDisable={item.disable ? item.disable : false}
                                                                                /> : item.type === "multiselect" ?
                                                                                    // <Field
                                                                                    //     name={item.name}
                                                                                    //     component={MultiSelectDropDown}
                                                                                    //     options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                    //     placeholder={item.placeholder}
                                                                                    //     // id={item.id}
                                                                                    //     value={item.name === "UsLocations" ? this.state.usArray : null}

                                                                                    // />
                                                                                    <MultiSelect
                                                                                        name={item.name}
                                                                                        value={item.name === "UsLocations" ? this.state.usL : item.name === "InternationalLocations" ? this.state.intL : item.name === "SICCode" ? this.state.sic : null}
                                                                                        style={{ width: "100%", height: 32 }}
                                                                                        // appendTo={document.body}
                                                                                        // disabled={this.state.selectedSeller ? false : true}
                                                                                        // maxSelectedLabels={1}
                                                                                        placeholder={item.placeholder}
                                                                                        options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                        onChange={(e) => this.changeUsLoc(e, item.name)}

                                                                                    />
                                                                                    : item.type === "number" ?
                                                                                        <Field
                                                                                            name={item.name}
                                                                                            component={DefaultInput}
                                                                                            type={"number"}
                                                                                            // options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                            placeholder={item.placeholder}
                                                                                        // id={item.id}
                                                                                        // value={item.value}

                                                                                        />

                                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}

                                                </div>
                                            </Tab> */}
                                            <Tab eventKey="complexRecoveries" title="Complex Recoveries">
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    {this.state.getThirdPartyFields && this.state.getThirdPartyFields.length > 0 ? this.state.getThirdPartyFields.map((item, index) => (
                                                        <div className={"col-sm-6 "} style={(this.state.dealType === "CR" && (item.dealType === "BK")) ? { display: "none" } : { display: "block" }} >
                                                            <div className="form__form-group pb-1">
                                                                <label className="form__form-group-label">{item.label}</label>
                                                                <div className='form__form-group-field kanban_notesfield'>
                                                                    {(item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                                                                        <Field
                                                                            type={item.type}
                                                                            name={item.name}
                                                                            component={DefaultInput}
                                                                            maxLength={item.maxLength}
                                                                            id={item.name}
                                                                            // validate={[required]}
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                        : item.type === 'date' ?
                                                                            <Field
                                                                                id={true}
                                                                                name={item.name}
                                                                                component={DatePicker}
                                                                                type={"text"}
                                                                                placeholder={item.placeholder}
                                                                            // isDisable={item.isDisable ? item.isDisable : false}
                                                                            />
                                                                            : item.type === 'dropdown' ?
                                                                                <Field

                                                                                    name={item.name}
                                                                                    component={Select}
                                                                                    options={item.options}
                                                                                    // onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
                                                                                    // onChange={(e) => this.handleChange(e)}
                                                                                    // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                                                    placeholder={item.placeholder}
                                                                                    isDisable={item.disable ? item.disable : false}
                                                                                />
                                                                                : item.type === "number" ?
                                                                                    <Field
                                                                                        name={item.name}
                                                                                        component={DefaultInput}
                                                                                        type={"number"}
                                                                                        options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                        placeholder={item.placeholder}
                                                                                    // id={item.id}
                                                                                    // value={item.value}

                                                                                    />
                                                                                    : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}

                                                </div>
                                            </Tab>
                                            <Tab eventKey="Post Sale Diligence" title="Post Sale Diligence">
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    {this.state.getpostsalediligencefields && this.state.getpostsalediligencefields.length > 0 ? this.state.getpostsalediligencefields.map((item, index) => (
                                                        < div className={"col-sm-6 "} style={(this.state.dealType === "CR" && (item.dealType === "BK")) ? { display: "none" } : { display: "block" }} >
                                                            <div className="form__form-group pb-1">
                                                                <label className="form__form-group-label">{item.label}</label>
                                                                <div className='form__form-group-field kanban_notesfield'>
                                                                    {item.fixed ?
                                                                        <Field
                                                                            type={item.type}
                                                                            name={item.name}
                                                                            component={DefaultInput}
                                                                            maxLength={item.maxLength}
                                                                            isDisable={item.isDisable ? item.isDisable : false}
                                                                            id={item.name}
                                                                            def_value={item.name === "RingTheBellDate1" ? this.state.RingTheBellDate1 : item.name === "OPPSalesRep1" ? this.state.OPPSalesRep1 : item.name === "OkForWebsiteDate1" ? this.state.OkForWebsiteDate1 : ""}
                                                                            // validate={[required]}
                                                                            placeholder={item.placeholder}
                                                                        /> :
                                                                        (item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                                                                            <Field
                                                                                type={item.type}
                                                                                name={item.name}
                                                                                component={DefaultInput}
                                                                                maxLength={item.maxLength}
                                                                                isDisable={item.isDisable ? item.isDisable : false}
                                                                                id={item.name}
                                                                                // validate={[required]}
                                                                                placeholder={item.placeholder}
                                                                            />
                                                                            : item.type === 'date' ?
                                                                                <Field
                                                                                    id={true}
                                                                                    name={item.name}
                                                                                    component={DatePicker}
                                                                                    type={"text"}
                                                                                    placeholder={item.placeholder}
                                                                                    isDisabled={item.isDisable ? item.isDisable : false}

                                                                                // isDisable={item.isDisable ? item.isDisable : false}
                                                                                />
                                                                                : item.type === 'dropdown' ?
                                                                                    <Field

                                                                                        name={item.name}
                                                                                        component={Select}
                                                                                        isDisable={item.isDisable ? item.isDisable : false}

                                                                                        options={item.options}

                                                                                        // onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
                                                                                        // onChange={(e) => this.handleChange(e)}
                                                                                        // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                                                        placeholder={item.placeholder}
                                                                                    // isDisable={item.disable ? item.disable : false}
                                                                                    />
                                                                                    : item.type === "number" ?
                                                                                        <Field
                                                                                            name={item.name}
                                                                                            component={DefaultInput}
                                                                                            isDisable={item.isDisable ? item.isDisable : false}

                                                                                            type={"number"}
                                                                                            options={item.name === "SICCode" ? this.state.sicCodes : item.options}
                                                                                            placeholder={item.placeholder}
                                                                                        // id={item.id}
                                                                                        // value={item.value}

                                                                                        />
                                                                                        : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}
                                                    {this.state.sellerSignatories ?
                                                        <div>
                                                            {/* <div style={{ marginLeft: "633px" }}>
                                                                {/* <Button color='primary' className="btnRadius" onClick={() => this.openSignatoryModal()}>
                                                                    <FontAwesomeIcon
                                                                        icon={faPlus}
                                                                        className='mt-0 mr-0' size='lg'
                                                                        data-toggle="tool-tip" title="List"
                                                                    />
                                                                </Button> */}

                                                            {/* </div> */}
                                                            <div className='d-flex'>

                                                                <DataTable value={this.state.sellerSignatories} scrollable={true} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" style={{ width: "612px", marginLeft: 15 }}
                                                                >
                                                                    <Column field="CompanyID" header="Company ID" bodyStyle={bodyStyle} headerStyle={{
                                                                        width: 120,
                                                                        textAlign: "center",
                                                                    }} body={this.hoverTemplate}
                                                                    />
                                                                    <Column field="PersonName" header="Seller Signatory" bodyStyle={bodyStyleRight} body={this.hoverTemplate} headerStyle={headerStyle} />
                                                                    <Column field="FullTitle" header="Title" bodyStyle={bodyStyleRight} body={this.hoverTemplate} headerStyle={{
                                                                        width: 250,
                                                                        textAlign: "center",
                                                                    }} />


                                                                </DataTable>


                                                            </div>

                                                        </div> : "No Companies connected for creating seller signatory"}

                                                </div>
                                            </Tab>
                                            {this.state.dealType === "CR" && this.state.dimSellerId ? <Tab eventKey="CR Matrix" title="CR Matrix" >
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    <div className={"col-sm-8"}   >
                                                        <div className="form__form-group pb-1">
                                                            <div className='form__form-group-field kanban_notesfield' style={{ float: "right" }}>
                                                                <label className="form__form-group-label" >{"CR Matrix Version"} </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"col-sm-4"} >
                                                        <Field
                                                            name={"crMatrixVersion"}
                                                            component={Select}
                                                            options={this.state.matrixVersionArray}
                                                            onChange={(e) => this.setMatrixVersion(e)}
                                                            // onChange={(e) => this.handleChange(e)}
                                                            // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                            placeholder={"crMatrixVersion"}
                                                        // isDisable={item.disable ? item.disable : false}
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.matrixQstns && this.state.matrixQstns.length > 0 ? this.state.matrixQstns.map((item, index) => (
                                                    <div className="row mx-1 mt-3 col-sm-12">
                                                        <div className={"col-sm-8"}   >
                                                            <div className="form__form-group pb-1">
                                                                <div className='form__form-group-field kanban_notesfield' style={{ float: "right" }}>
                                                                    <label className="form__form-group-label" >{item.qstn} </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"col-sm-4 d-flex"} >
                                                            <Field
                                                                name={"matrixId_" + item.qstnId}
                                                                component={DefaultInput}
                                                                id={item.qstnId}

                                                            // validate={[required]}
                                                            // placeholder={item.placeholder}
                                                            />

                                                            <FontAwesomeIcon icon={faQuestion}
                                                                className='ml-2'
                                                                color='red'
                                                                data-toggle="tool-tip"
                                                                title={item.CrMatrixRule ? item.CrMatrixRule : "No Rule For This Question"}
                                                                style={{ width: 8 }}
                                                            />
                                                        </div>
                                                    </div>
                                                )) : null}

                                            </Tab> : null}
                                        </Tabs>
                                    </div>


                                    <div className="row" style={{ width: "100%" }}>
                                        {type === 'edit' ?
                                            <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} style={!this.state.showTimer ? {} : { pointerEvents: "none" }}>

                                            </div>
                                            : null}
                                    </div>

                                </div>
                            </CardBody>
                        </Card>

                    </ModalBody>
                </form >
            </div >);
    }


    /**
     * 
     * @param {*} cardtype 
     * @param {*} value 
     * setting up the values for busineess search drop down 
     */
    getValues1 = async (cardtype, value) => {
        let select_States = this.state.setBussinessNames
        if (value && value.label && select_States && select_States.length > 0) {
            select_States.map(col => {
                if (col.label === value.label) {
                    col.show = !col.show
                }
                return col
            })
        }
        let index = select_States.findIndex(filterarr => filterarr.show === true && filterarr.label.includes("--"))
        if (index != -1) {
            await this.setState({ showErrorMessage: true })
        } else {
            await this.setState({ showErrorMessage: false })
        }
        this.setState({
            setBussinessNames: select_States,
            apierrorResponse: false,
            apierrorMessage: ""
        })
    }
    /**
     * Showing of the exhibits based on the selection
     */
    getExhi1 = async (cardtype, value) => {
        let select_States = this.state.exhibit1Array
        if (value && value.label && select_States && select_States.length > 0) {
            select_States.map(col => {
                if (col.label === value.label) {
                    col.show = !col.show
                }
                return col
            })
        }

        this.setState({
            exhibit1Array: select_States,
            apierrorResponse: false,
            apierrorMessage: ""
        })
    }
    /**
     * 
     * @param {*} cardtype 
     * @param {*} value 
     * @param {*} data 
     * @param {*} dataType 
     * showing the new exhibit values
     */
    getnewExistingValues = async (cardtype, value, data, dataType) => {
        let isSplitt_States = this.state.isSelectedData
        if (value && value.label && isSplitt_States && isSplitt_States.length > 0) {
            isSplitt_States.map(col => {
                if (col.label === value.label) {
                    col.show = !col.show
                }
                return col
            })
        }
        this.setState({
            isSelectedData: isSplitt_States,
            apierrorResponse: false,
            apierrorMessage: ""
        })

    }


    moveLeftToRight = async () => {
        this.setState({
            leftCount: this.state.leftCount + 1
        })
        if (this.state.setBussinessNames) {
            let selectt = this.state.setBussinessNames;
            let selectt1 = this.state.setBussinessNames ? JSON.parse(JSON.stringify(this.state.setBussinessNames)) : null;
            let split_arr = this.state.isSelectedData;
            var newArray = selectt.filter(function (el) {
                return el.show === true
            });
            if (newArray && newArray.length > 0) {
                for (var obj of selectt) {
                    var obj1 = Object.assign({}, obj)
                    if (obj.show === true) {
                        obj1.show = true
                        split_arr.push(obj1)
                        selectt1.splice(selectt1.findIndex(a => a.label === obj.label), 1)
                    }
                }
                this.setState({ setBussinessNames: selectt1, isSelectedData: split_arr, hideSplitBtn: true })
            } else {
                this.setState({ apierrorResponse: true, apierrorMessage: `Please select any business under Business Names before clicking` })
            }
            if (this.state.ismoveAlltoleft) {
                await this.setState({ ismoveAlltoleft: false });
            }
            this.setState({
                showErrorMessage: false
            })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any business under Business Names before clicking` })

        }
    }
    /**
     * 
     * @returns Setting new exhibit
     */
    setNewExhibit = async () => {
        let exhiArr = []

        if (this.state.exhibit1Array) {
            let selectt = this.state.exhibit1Array;
            let selectt1 = this.state.exhibit1Array ? JSON.parse(JSON.stringify(this.state.exhibit1Array)) : null;
            // let split_arr = this.state.isSelectedData;
            var newArray = selectt.filter(function (el) {
                return el.show === true
            });
            if (newArray && newArray.length > 0) {
                for (let newArr of newArray) {
                    exhiArr.push({ sellerAPANamesID: newArr.SellerAPANamesID, dimSellerID: JSON.stringify(this.state.dimSellerId), sellerAPAName: newArr.value, exhibitType: newArr.ExhibitType })
                }
                let body = {
                    sellerApaNames: exhiArr,
                    dealID: JSON.stringify(this.state.dimSellerId),
                    // existingExhibitType: "Exhibit-A",
                    newExhibitType: this.state.setExhibitType
                }
                await this.setState({ isLoading: true })
                return fetch('PUT', apiCalls.SellerApaExhibit, body)
                    .then(async (response) => {
                        if (response && response.respMessage) {
                            // this.cancelReset()
                            await this.setState({ setExhibitType: "", apierrorResponse: true, apierrorMessage: response.respMessage })
                            this.setExhibitNames()
                            // await this.setState({ moveExhibitA: true })
                        } else if (response && response.errorMessage) {
                            await this.setState({ apierrorResponse: true, apierrorMessage: response.errorMessage, apierrorcolor: "error" })
                        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                            await this.setState({
                                sessionWarning: true,
                            })
                        }
                        await this.setState({ isLoading: false })
                    }).catch((err) => {
                        return err;
                    });
            }
        }
    }


    /**
     * Moving business names from right to left
     */
    moveRighttoLeft = async () => {
        await this.setState({
            upCount: this.state.upCount + 1,
            isLoading: true
        })
        if (this.state.isSelectedData) {
            let selectt2 = this.state.isSelectedData && this.state.isSelectedData.length > 0 ? this.state.isSelectedData : [];
            let selectt3 = JSON.parse(JSON.stringify(this.state.isSelectedData));
            let split_arr1 = this.state.setBussinessNames && this.state.setBussinessNames.length > 0 ? this.state.setBussinessNames : [];
            var newArray = selectt2.filter(function (el) {
                return el.show === true
            });
            if (newArray && newArray.length > 0) {
                for (var obj2 of selectt2) {
                    var obj3 = Object.assign({}, obj2)
                    if (obj2.show === true) {
                        obj3.show = false
                        split_arr1.push(obj3)
                        selectt3.splice(selectt3.findIndex(a1 => a1.label === obj2.label), 1)
                    }
                }
                this.setState({ setBussinessNames: split_arr1, isLoading: false, isSelectedData: selectt3, hideSplitBtn: selectt3.length > 0 ? true : false })
            } else {
                this.setState({ apierrorResponse: true, isLoading: false, apierrorMessage: `Please select any business under Selected Business Names before clicking` })
            }
            if (this.state.isSelectAll) {
                await this.setState({ isSelectAll: false });
            }
        } else {
            this.setState({ apierrorResponse: true, isLoading: false, apierrorMessage: `Please select any business under Selected Business Names before clicking` })

        }
    }

    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    isSelectNo = () => {
        if (this.props.type === "add" || this.props.type === "edit") {
            this.setState({
                issplitbyStatus: false,
                // hideSplitBtn: true
            })
        }
        this.setState({
            splitbyeachCard: false,
            hideSplitBtn: true
        })
    }

    flattenArray = (arrayVal) => {
        let val = '';
        if (arrayVal) {
            val = JSON.stringify(arrayVal);
            val = val.replace(/"/g, '')
                .replace(/\[/g, '')
                .replace(/]/g, '');
        }
        return val;
    }
    /**
     * Moving business names to left
     */
    onChangeMoveAlltoleft = () => {
        if (this.state.ismoveAlltoleft) {
            this.setState({ ismoveAlltoleft: false });
        } else {
            this.setState({ ismoveAlltoleft: true, apierrorMessage: "", apierrorResponse: false });
        }
        if (!this.state.ismoveAlltoleft) {
            let select_list = this.state.setBussinessNames
            for (var obj of select_list) {
                obj.show = true
            }
            this.setState({ setBussinessNames: select_list })
        } else {
            let select_list = this.state.setBussinessNames
            for (var obj2 of select_list) {
                obj2.show = false
            }
            this.setState({ setBussinessNames: select_list })
        }
    }
    /**
     * Change all to select
     */
    onChangeselectAll() {
        if (this.state.isSelectAll) {
            this.setState({ isSelectAll: false });
        } else {
            this.setState({ isSelectAll: true, apierrorMessage: "", apierrorResponse: false });
        }
        if (!this.state.isSelectAll) {
            let select_list = this.state.isSelectedData
            for (var obj of select_list) {
                obj.show = true
            }
            this.setState({ isSelectedData: select_list })
        } else {
            let select_list = this.state.isSelectedData
            for (var obj2 of select_list) {
                obj2.show = false
            }
            this.setState({ isSelectedData: select_list })
        }
    }
    /**
     * 
     * @param {*} e 
     * get business names on search
     */
    async getBussiness(e) {
        let bussinessArray = []
        this.setState({ setBusinesssearched: e.target.value, setPage: 1 })
        let filterCriteria = `{"limit":20,"page":"1","criteria":[],"sortfield":"CompanyName","direction":"asc","globalSearch":{"value":"${encodeURIComponent(e.target.value)}","type":"user"}}`
        if (e.key === "Enter") {
            e.preventDefault();
            await this.setState({ isLoading: true })
            this.handlePercentage('1')
            fetch('GET', `${apiCalls.BusinessSeller}?filter=${filterCriteria}`)
                .then(async (response) => {
                    if (response && response.companies) {
                        for (let comp of response.companies) {
                            bussinessArray.push({ label: comp.CompanyName + (comp.EntityName ? `  --  ${comp.EntityName}` : "") + (comp.Cnt > 0 ? `   (${comp.Cnt} entities)` : "") + (comp.RootCompanyID ? ` -(${comp.RootCompanyID})` : "") + (comp.HQCity ? `-(${comp.HQCity}${comp.HQState_Province})` : ""), value: comp.CompanyID })
                        }
                        await this.setState({ setBussinessNames: bussinessArray })
                        await this.setState({ isLoading: false })


                    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                        await this.setState({
                            sessionWarning: true,
                            isLoading: false
                        })
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }
    /**
   * 
   * @param {*} e 
   * get business names on search on clicking view more
   */
    getMoreBussiness = async () => {
        let bussinessArray = this.state.setBussinessNames
        let setPage = this.state.setPage

        let filterCriteria = `{"limit":20,"page":"${setPage + 1}","criteria":[],"sortfield":"CompanyName","direction":"asc","globalSearch":{"value":"${this.state.setBusinesssearched}","type":"user"}}`

        await this.setState({ isLoading: true })
        this.handlePercentage("1")
        fetch('GET', `${apiCalls.BusinessSeller}?filter=${filterCriteria}`)
            .then(async (response) => {
                if (response && response.companies) {
                    for (let comp of response.companies) {
                        bussinessArray.push({ label: comp.CompanyName + (comp.EntityName ? `  --  ${comp.EntityName}` : "") + (comp.Cnt > 0 ? `   (${comp.Cnt} entities)` : "") + (comp.RootCompanyID ? `   (${comp.RootCompanyID})` : "") + (comp.HQCity ? `   (${comp.HQCity}${comp.HQState_Province})` : ""), value: comp.CompanyID })
                    }
                    await this.setState({ setBussinessNames: bussinessArray, isLoading: false })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                        isLoading: false
                    })
                }
            }).catch((err) => {
                return err;
            });
        await this.setState({ setPage: setPage + 1 })

    }
    setValuesNames(e) {
        this.setState({ selectedStatus: e.value })
    }
    /**
     * Selecting business name and assigning to deal
     */
    getTab2Body() {
        return (
            <div style={{ width: "100%" }}>
                <ModalBody className='modal__body edit_modal' style={{
                    padding: 10,
                    // overflowX: "hidden",
                    // overflowY: "clip",
                    height: 580
                }}>
                    <Card className='pb-0 ml-4'>
                        <CardBody className='row p-0 d-block'>
                            {/* <Loader loader={this.state.isLoading} progress={this.state.progress} /> */}

                            <div >
                                <div className="col-sm-12 row" style={{ width: "100%" }}>
                                    <div className="col-sm-5 pl-0 pr-0">
                                        <Row className=" ml-0 mr-0" >
                                            <label><b>{"Business Name"}</b></label>
                                            <span className="ml-1">{this.state.showErrorMessage ? <span style={{ height: 30, color: "red" }}>{"There are some companies already connected to another seller."}</span> : null}</span>

                                            <span className='d-flex float-right split ml-auto' >
                                                <label className='d-flex float-right' ><input type="checkbox"
                                                    checked={this.state.ismoveAlltoleft}
                                                    onChange={(e) => this.onChangeMoveAlltoleft("ismoveAlltoleft")}
                                                /> Select All</label>

                                            </span>
                                            <InputText
                                                type="search"
                                                name='bussinessName'
                                                id='bussinessName'
                                                value={this.state.setBusinesssearched}
                                                style={{ height: 37, paddingLeft: "2.5rem" }}
                                                onKeyPress={(e) => this.getBussiness(e)}
                                                onChange={(e) => this.getBussiness(e)}
                                                placeholder={"Search Business Name"}
                                                size={"20"} />

                                            <Card className="pb-0" style={{ minHeight: "160px", height: '375px', overflow: 'auto', border: "1px solid grey" }}>
                                                {this.state.setBussinessNames && this.state.setBussinessNames.length > 0 ? this.state.setBussinessNames.map((item, index) => {
                                                    return <div className='d-flex m-1'>
                                                        <input key={`up-${index}`}
                                                            style={{ marginTop: 3, height: 16 }}
                                                            className='col-1 mr-0 pl-0'
                                                            onChange={(e) => this.getValues1("existing", item, item.label)}
                                                            name={`up-${this.state.upCount}-${index}`}
                                                            id={`up-${this.state.upCount}-${index}`}
                                                            component={CheckBox}
                                                            label={item.label}
                                                            type="checkbox"
                                                            //value={item.show}
                                                            checked={item.show}
                                                            defaultChecked={item.show}
                                                        /><label className='mb-0'>{item.label}</label>
                                                    </div>
                                                }) : null}

                                            </Card>
                                            <span style={this.state.setBussinessNames && this.state.setBussinessNames.length > 0 ? { display: "block" } : { display: "none" }} >
                                                <a onClick={() => this.getMoreBussiness()} style={{ float: "right" }}><u>View More</u></a></span>

                                        </Row>


                                    </div>
                                    <div className="col-sm-2 mt-5">
                                        {this.state.sellerViewType ? null :
                                            <Row className="">

                                                <span style={{ width: "100%", marginLeft: "43%" }}>
                                                    <p className="ml-auto mr-auto"><img
                                                        src={configImages.leftIcon}
                                                        alt=""
                                                        className="mt-2 mb-2"
                                                        style={{ width: "15px", height: "15px" }}
                                                        data-toggle="tool-tip"
                                                        title="click to move selected business to right"
                                                        onClick={() => this.moveLeftToRight()} /></p>
                                                    <p className="ml-auto mr-auto mt-0"><img
                                                        src={configImages.rightIcon}
                                                        alt=""
                                                        className="mb-2"
                                                        style={{ width: "15px", height: "15px" }}
                                                        data-toggle="tool-tip"
                                                        title="click to move unselected states to left"
                                                        onClick={() => this.moveRighttoLeft()} /></p>
                                                </span>
                                            </Row>
                                        }
                                    </div>
                                    <div className="col-sm-5 pl-0 pr-0">
                                        <div className="d-flex">
                                            <label className="split_label mb-0"><b>{"Selected Business Names"}</b></label>
                                            <span className='d-flex float-right split ml-auto' >
                                                <label className='d-flex float-right' ><input type="checkbox"
                                                    checked={this.state.isSelectAll}
                                                    onChange={() => this.onChangeselectAll('remember')}
                                                /> Select All</label>

                                            </span>
                                        </div>
                                        <Card style={{ minHeight: "160px", height: '427px', overflow: 'auto', border: "1px solid grey" }}>
                                            {this.state.isSelectedData && this.state.isSelectedData.length > 0 ? this.state.isSelectedData.map((item2, index2) => {
                                                return <div className='d-flex m-1'>

                                                    <input key={`left-${index2}`}
                                                        className='col-1 mr-0 pl-0'
                                                        style={{ marginTop: 3, height: 16 }}
                                                        // name={`left-${this.state.leftCount}-${index2}`}
                                                        id={`left-${this.state.leftCount}-${index2}`}
                                                        type="checkbox"
                                                        component={CheckBox}
                                                        label={item2.label}
                                                        //value={item2.show}
                                                        checked={item2.show}
                                                        defaultChecked={item2.show}
                                                        onChange={(e) => this.getnewExistingValues("newExisting", item2, this.state.isSelectedData)}

                                                    /><label className='mb-0'>{item2.label}</label>

                                                </div>

                                            }) : null}
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8, color: this.state.apierrorcolor }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span>
                    <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8, color: "green" }}>{this.state.apisuccResponse ? <span style={{ height: 30 }}>{this.state.apisuccMessage}</span> : null}</span>
                    <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8, color: "red" }}>{this.state.splitbyeachCard ?
                        <span>
                            {this.state.isSplitBy === "States" ?
                                <span>Are you sure you want to split task, one task per state?</span>
                                : <span>Are you sure you want to split task, one task per Name?</span>}
                            <p> <Button
                                color='primary'
                                outline
                                type="button"
                                className=" ml-1 mb-0 mt-0" onClick={() => this.issplitSucces()}>Yes
                            </Button><Button
                                color='primary'
                                outline
                                type="button"
                                className=" ml-1 mb-0 mt-0" onClick={() => this.isSelectNo()}>No
                                </Button></p>
                        </span> : null}</span>

                </ModalBody>
            </div >
        );


    }
    /**
     * setting the state to open add names modal
     */
    addNames() {
        this.setState({ showNames: false })
    }


    /**
     * 
     * @param {*} e 
     * open form modal to add
     */
    openFormModal = (e) => {
        if (this.state.activeTab === "3") {
            this.setState({
                isOpenFormModal: true
            })
        } else if (this.state.activeTab === "4" || this.state.activeTab === "5") {
            this.setState({ isOpenAddressModal: true })
        }
    }
    /**
     * setting state to open bulk upload modal
     */
    bulkUploadMoadal = () => {
        this.setState({ openBulkUploadModal: true })
    }

    /**
    * setting state to close bulk upload modal
    */
    closeBulkModal = () => {
        this.setState({ openBulkUploadModal: false })
    }
    /**
        * setting state to open notes upload modal
        */
    openNotesModel = (item, type) => {
        if (this.state.activeTab === "3") {
            this.setState({ itemAvail: true, isOpenFormModal: true, itemDetails: item })

        } else {
            this.setState({ itemAvail: true, isOpenAddressModal: true, itemDetails: item })
        }
    }
    /**
     * 
     * @param {*} e 
     * @param {*} tab 
     * Setting values and hitting apis based on deal
     */

    handleGetDeal = async (e, tab) => {
        if (e && e.value) {
            await this.setState({
                selectedDeal: e.value,
                selectedDealID: e.value.DealID,
                dimSellerId: e.value.DealID,
                // activeTab:tab
            })
        } else {
            await this.setState({
                selectedDeal: null,
                selectedDealID: null,
                dimSellerId: null,
                sellerViewType: null
            })
            this.props.load({})
        }
        if (e && e.value && e.value.DealID) {
            if (tab === "1") {
                await this.handleLoadforTabsasScreens(e.value, 'sidebar')
            } else {
                this.toggle(tab)
            }
        }
        // this.props.load(e.value)
    }

    render() {
        const { handleSubmit, t, type, rowData } = this.props;
        const { activeTab } = this.state;
        return (
            <Card className="cardForListMargin m-0 card">
                <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />

                <CardBody className="tableCardBody">
                    <form className="form" >
                        {window.location.href.includes("sellersView") || window.location.href.includes("sellersAdd") ? <Row className="col-sm-12">

                            <h4><span className='postionRelative pt-2 ml-1'>
                                <FontAwesomeIcon
                                    className={'tableheadericon'}
                                    color="#354f6e"
                                    icon={faUsers} />
                                <b
                                ><a to={"#"} className="tableType pr-0" >
                                        {window.location.href.includes("sellersEdit") ? `Edit Deal` : window.location.href.includes("sellersView") ? "View Deal" : "Add Deal"}
                                    </a>{this.state.selectedDealID ? ` - ${this.state.selectedDeal.EntityName} - ${this.state.selectedDealID}` : null}</b>

                            </span>
                            </h4>

                        </Row> : null}
                        {!window.location.pathname.includes("sellersAdd") && !window.location.pathname.includes("deal") && !window.location.pathname.includes("name") && !window.location.pathname.includes("address") && !window.location.href.includes("sellersEdit") ?

                            <div className={"col-sm-6"} >
                                <label className="form__form-group-label">Deal Type{required ? <FontAwesomeIcon icon={faStarOfLife}
                                    className='pl-1'
                                    color='red'
                                    data-toggle="tool-tip"
                                    title="Mandotary Field"
                                    style={{ width: 8 }}
                                /> : ''}</label>
                                <Row>

                                    <div className=" col-sm-4 d-flex pl-0 ml-2">
                                        <Field

                                            name={"DealType"}
                                            component={Select}
                                            options={this.state.dealTypeArray ? this.state.dealTypeArray : [{ label: "BK", value: "BK" }, { label: "CR", value: "CR" }]}
                                            onChange={(e) => this.handleCheck(e)}
                                            // value={this.state.dealType ? this.state.dealType : null}
                                            // onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
                                            // onChange={(e) => this.handleChange(e)}
                                            // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                            placeholder={"Deal Type"}
                                        // isDisable={item.disable ? item.disable : false}
                                        />

                                    </div>
                                </Row>
                            </div>
                            : null}
                        {this.state.selectedDealID &&window.location.href.includes("sellersEdit")||window.location.href.includes("sellersAdd")?
                            this.state.activeTab === "1" && (this.state.selectedDealID || this.state.sellerViewType || window.location.pathname.includes("sellersAdd")) && !window.location.pathname.includes("business") ?
                                this.getModalBody(handleSubmit)
                                : this.state.activeTab === "2" || window.location.pathname.includes("business") || this.state.sellerViewType ?
                                    this.getTab2Body()
                                    : null : null}
                        {this.state.selectedDealID ?
                            < CardFooter className={`${this.state.issplitbyStatus && type === "add" ? `modalFooter showAssignedError` : 'modalFooter'}`} style={window.location.pathname.includes("business") ? { width: "100%", padding: "10px" } : activeTab === "1" ? { display: "none" } : type === 'edit' || this.state.issplitbyStatus ? { width: "100%", padding: "16px", paddingRight: "40px", backgroundColor: "white", borderTop: "none" } :
                                { width: "100%", padding: "10px" }}>
                                {!this.state.issplitbyStatus ?
                                    <>
                                        {!this.state.splitbyeachCard ? <Button color='danger' outline type="button" className={type === 'edit' ? " ml-1 mb-0 mt-0" : "mr-auto ml-1 mb-0 "} disabled={this.props.preSalePermission === "Edit" ? false : true} onClick={this.cancelReset}>
                                            <FontAwesomeIcon
                                                style={{ width: "15px" }}
                                                icon={faArrowCircleLeft}
                                            />
                                            {t('Back') ? t('Cancel') : 'Cancel'} </Button> : null}
                                        {/* {this.state.activeTab==="2" ? <Button color='success' outline type="button" className={type === 'edit' ? " ml-1 mb-0 mt-0" : "mr-auto ml-1 mb-1 "} onClick={this.cancelReset}>
                                        <FontAwesomeIcon
                                            style={{ width: "15px" }}
                                            icon={faArrowCircleLeft}
                                        />
                                        {t('Back') ? t('Connect') : 'Connect'} </Button> : null} */}
                                        {activeTab !== "1" && activeTab !== "2" && activeTab !== "3" && activeTab !== "4" && activeTab !== "5" && !this.state.hideUpdatebtn ?
                                            <Button
                                                style={{ float: "right" }}
                                                color='primary'
                                                className={type === "edit" ? "mb-0 mt-0" : "mb-0 "}
                                                outline
                                                type="submit">
                                                <FontAwesomeIcon
                                                    style={{ width: "15px" }}
                                                    icon={activeTab === "3" || activeTab === "4" || activeTab === "5" ? faProjectDiagram : faSave}
                                                />
                                                {type === 'edit' && activeTab === "1"
                                                    ?
                                                    'Update'
                                                    : 'Save 12'
                                                }
                                            </Button> :
                                            window.location.pathname.includes("business") && this.state.hideSplitBtn && !this.state.hideUpdatebtn && !this.state.sellerViewType ?

                                                <Button
                                                    color='primary'
                                                    outline
                                                    type="button"
                                                    disabled={this.props.preSalePermission === "Edit" ? false : true}
                                                    className=" ml-1 mb-0 mt-0" onClick={this.connectBussiness}>
                                                    <FontAwesomeIcon
                                                        style={{ width: "15px" }}
                                                        icon={faLink}
                                                    />
                                                    {'Connect'}
                                                </Button> : null
                                        }
                                    </>
                                    : <span className=" mt-2 mb-2 ml-auto mr-auto" style={{ textAlign: "center", bottom: 0, color: "red" }}>{this.state.issplitbyStatus ? <span>{this.state.apierrorMessage}
                                        <p>
                                            {this.state.sellerViewType ? null :
                                                <Button
                                                    color='primary'
                                                    outline
                                                    type="button"
                                                    className=" ml-1 mb-0 mt-0" onClick={() => this.successSubmit(this.state.method, this.state.apiUrl, this.state.userBody)}>Yes
                                                </Button>
                                            }
                                            <Button
                                                color='primary'
                                                outline
                                                type="button"
                                                className=" ml-1 mb-0 mt-0" onClick={() => this.isSelectNo()}>No
                                            </Button>


                                        </p>
                                    </span> : null}</span>}
                            </CardFooter > : null}
                    </form >
                    {this.state.isopenColumnModal ?
                        this.getConfirmaionModel() : null
                    }
                    {this.state.isnotesModal ? this.getNotesModal() : null}
                    {
                        this.state.sessionExpiryModal ?
                            <Redirect to="/log_in" />
                            : null
                    }
                    {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
                    {this.state.forceLogout ? <Redirect to="/log_in" /> : ""}
                    {this.state.isKanbanModelClose ? <Redirect to="/sellers" /> : ""}
                    {this.state.openBulkUploadModal ? this.bulkUpload() : null}
                    {this.state.sessionWarning ? this.getSessionWraningModal() : null}
                    {this.state.isOpenFormModal ? this.AddNamesModal() : null}
                    {this.state.openSignatoryModal ? this.AddSignatoryModal() : null}
                    {this.state.isOpenAddressModal ? this.AddAddressModal() : null}
                    {this.state.saveOrderModal ? this.showOrders() : null}
                    {this.state.showOrderModal ? this.showavailableOrders() : null}
                    {/* {this.state.openDeleteModal ? this.getDeleteRowModal() : null} */}

                    {this.state.isRedirecttoEdit ? <Redirect to={`/sellersEdit/deal/${this.state.dimSellerId}`} /> : null}
                </CardBody >
            </Card >
        )
    }

}

SellerModal = reduxForm({
    form: "User Reset Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(SellerModal);

// You have to connect() to any reducers that you wish to connect to yourself
SellerModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(SellerModal);

export default withTranslation('common')(SellerModal);