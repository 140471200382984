import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import DatePicker from 'react-datepicker';
import { Button } from "reactstrap";
import Loader from '../../App/Loader';

interface props {
    isOpenNewMailPopUp: boolean,
    mailForwardsData: Object,   
    closeNewMailPopUp: Function,
    newNotes: string,    
    noteCategoryTypes: Array<any>,
    mailReceivedCategories: Array<any>,    
    showLoader:boolean,
    opType:string | undefined,    
    errorMessage:any,
    createOrUpdateMailRecdRecord:Function
}


export const NewMailPopUpModal = ({
    isOpenNewMailPopUp,
    mailForwardsData, closeNewMailPopUp, mailReceivedCategories,showLoader,opType,errorMessage,createOrUpdateMailRecdRecord
}: props) => {
    const [mailForwardsDetails, setMailForwardsDetails] = useState<any>(mailForwardsData);

    useEffect(() => {
        setMailForwardsDetails(mailForwardsDetails)
    }, [mailForwardsDetails]);

    const onChangeRecordData = (type: any, value: any) => {
        setMailForwardsDetails((prevDetails: any) => ({ ...prevDetails, [type]: value }));
    }

    const onClickSave = () => {
        let body = {
            MailFwdTrackingID:mailForwardsDetails.MailFwdTrackingID,
            MailRecvdDate:mailForwardsDetails.MailRecvdDate,
            IsValueableFlag:mailForwardsDetails.IsValueableFlag,
            MailRecvdValue:mailForwardsDetails.MailRecvdValue,
            MailRecvdCategory:mailForwardsDetails.MailRecvdCategory,
            MailRecvdPayorSender:mailForwardsDetails.MailRecvdPayorSender,
            MailRecvdDescription:mailForwardsDetails.MailRecvdDescription,
            RecvdPiecesOfMail:mailForwardsDetails.RecvdPiecesOfMail,
            MailRecvdOwner:mailForwardsDetails.MailRecvdOwner,
            RowIsCurrent:1,
         }
         if(opType === 'edit'){
            createOrUpdateMailRecdRecord(body,'edit')
         }else {
            createOrUpdateMailRecdRecord(body,'add');
         }
        
    }

    return (
        <>    
            
            <Dialog
                header={""}
                visible={isOpenNewMailPopUp}
                style={{ width: "70vw" }}
                closable={false}
                onHide={() => closeNewMailPopUp()}
            >
                
                <div className="row d-flex">
                {errorMessage && <div className="col-12 text-center text-danger"> {errorMessage} </div>}
                    <Loader loader={showLoader} />
                    <div className="col-sm-6 d-flex flex-column">
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">
                                Mail Fwd ID
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    disabled
                                    value={mailForwardsDetails.MailFwdTrackingID}
                                    placeholder={
                                        mailForwardsDetails.MailFwdTrackingID
                                            ? ""
                                            : "System will auto assign"
                                    }
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">
                                Deal Type
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    disabled
                                    value={mailForwardsDetails.DealType}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">
                                Deal Name
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    disabled
                                    value={mailForwardsDetails.SellerNickName}
                                />
                            </div>
                        </div>

                        <div className="row my-2 mt-4">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">
                                Name to Forward
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    disabled
                                    value={mailForwardsDetails.LocationName}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0  label-mailF-details label-mailF-colour d-flex justify-content-end align-items-center">
                                Key Address
                            </label>
                            <div className="col-sm-8 d-flex">
                                <div className="mr-2">
                                    <RadioButton
                                        inputId="rb1"
                                        name="city"
                                        value={true}
                                        onChange={(e) => {
                                            onChangeRecordData("IsValueableFlag", true);
                                        }}
                                        // onChange={(e) => onChangeInputValues("KeyAddressFlag", true)}
                                        checked={mailForwardsDetails.IsValueableFlag === true}
                                    />
                                    <label htmlFor="rb1" className="p-radiobutton-label">
                                        Yes
                                    </label>
                                </div>
                                <div>
                                    <RadioButton
                                        inputId="rb2"
                                        name="city"
                                        value={false}
                                        onChange={(e) => {
                                            onChangeRecordData("IsValueableFlag", false);
                                        }}
                                        checked={mailForwardsDetails.IsValueableFlag === false}
                                    />
                                    <label htmlFor="rb2" className="p-radiobutton-label">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">
                                Address Line1
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    disabled
                                    value={mailForwardsDetails.Line1}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">
                                City
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    disabled
                                    value={mailForwardsDetails.City}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">
                                State
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    disabled
                                    value={mailForwardsDetails.StateProvince}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">
                                Zip
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    disabled
                                    value={mailForwardsDetails.Zip}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 d-flex flex-column">
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details label-mailF-colour d-flex justify-content-end align-items-center">
                                Category
                            </label>
                            <div className="col-sm-8">
                                <Dropdown value={mailForwardsDetails.MailRecvdCategory} options={mailReceivedCategories} onChange={(e) => {
                                    onChangeRecordData("MailRecvdCategory", e.value);
                                }} style={{ minWidth: "100%" }} filter={true}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details label-mailF-colour d-flex justify-content-end align-items-center">
                                Payor/Sender
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    onChange={(e) => {
                                        onChangeRecordData("MailRecvdPayorSender", (e.target as HTMLInputElement).value);
                                    }}
                                    value={mailForwardsDetails.MailRecvdPayorSender}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details label-mailF-colour d-flex justify-content-end align-items-center">
                                Description
                            </label>
                            <div className="col-sm-8">
                                <InputText
                                    className="w-100"
                                    onChange={(e) => {
                                        onChangeRecordData("MailRecvdDescription", (e.target as HTMLInputElement).value);
                                    }}
                                    value={mailForwardsDetails.MailRecvdDescription}
                                />
                            </div>
                        </div>

                        <div className="row my-2 mt-4">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details label-mailF-colour d-flex justify-content-end align-items-center">
                                Value
                            </label>
                            <div className="col-sm-8">
                                <InputText type="number" keyfilter="int" className="w-100" onChange={(e) => { onChangeRecordData("MailRecvdValue", (e.target as HTMLInputElement).value); }} value={mailForwardsDetails.MailRecvdValue} />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details label-mailF-colour d-flex justify-content-end align-items-center">Mail Recveived Date</label>
                            <div className="col-sm-8">
                                <DatePicker selected={mailForwardsDetails.MailRecvdDate} wrapperClassName="w-100" onChange={(date: Date) => onChangeRecordData('MailRecvdDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" /></div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details label-mailF-colour d-flex justify-content-end align-items-center">
                                Pieces of Mail
                            </label>
                            <div className="col-sm-8">
                                <InputText type="number" keyfilter="int"  className="w-100" onChange={(e) => { onChangeRecordData("RecvdPiecesOfMail", (e.target as HTMLInputElement).value); }} value={mailForwardsDetails.RecvdPiecesOfMail}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-3 mb-0 pr-0 label-mailF-details label-mailF-colour d-flex justify-content-end align-items-center">
                                Owner
                            </label>
                            <div className="col-sm-8">
                                <InputText className="w-100" onChange={(e) => { onChangeRecordData("MailRecvdOwner", (e.target as HTMLInputElement).value); }} value={mailForwardsDetails.MailRecvdOwner}
                                />
                            </div>
                        </div>
                        
                        <div className="text-center pt-3">
                            <Button color="primary" onClick={() => onClickSave()} disabled={showLoader}>
                                { opType === 'edit' ? 'Update' : 'Save' }
                            </Button>
                            <Button color="secondary" onClick={() => closeNewMailPopUp()}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>                
            </Dialog>
            
        </>
    );
};
