import React, { useEffect, useRef, useState } from "react";
import DataTables from '../CommonDataTable/DataTable';
import fields from './MappedProportySearchReportFields.json';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { InputSwitch } from 'primereact/inputswitch';
import DealDropdown from "../../../shared/commonFields/DealDropdown";
import dateFormats from '../../UI/FormatDate/formatDate'
import states from '../../../shared/states.json';
import * as commonApis from "../../../shared/commonApis";
import MultiSortModal from "../PropertyEditorV2/components/MultisortModal";
import fetchMethodRequest from "../../../config/service";
import Loader from '../../App/Loader';

const MappedProportySearchReport = () => {
    const [topFilters, setTopFilters] = useState<any>({});
    const [propertyStatus, setPropertyStatus] = useState<Array<any>>([]);
    const [propertyReasons, setPropertyReasons] = useState<Array<any>>([]);
    const [propStatusDropDownValues, setPropStatusDropDownValues] = useState<Array<any>>([]);
    const [propReasonsDropDownvalues, setPropReasonsDropDownsValues] = useState<Array<any>>([]);
    const [disableGoButton, setDisableGoButton] = useState<boolean>(true);
    const [isOpenMultiSortModal, setIsOpenMultiSortModal] = useState<boolean>(false);
    const [formattedTopFilters, setFormattedTopFilters] = useState<Array<any>>([]);
    const [sellerID, setSellerID] = useState<string>();
    const [multiSortFilters, setMultiSortFilters] = useState<Array<any>>([]);
    const [multiSortFields, setMultiSortFields] = useState<Array<any>>([]);
    const [selectedfieldsforSort, setSelectedfieldsforSort] = useState<Array<any>>([]);
    const [showDataTable, setShowDataTable] = useState<boolean>(false);
    const [fromColReOrder, setFromColReOrder] = useState<boolean>(false);
    const [showClaimAttributes, setShowClaimAttributes] = useState<boolean>(false);
    const [naupaCategories, setNaupaCategories] = useState<any>([]);
    const [oppNaupaCategories, setOPPNaupaCategories] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [doSearch, setDoSearch] = useState<boolean>(false);


    const rolesPermission = localStorage.getItem('rolePermissions') ? JSON.parse(localStorage.getItem('rolePermissions') || '') : '';
    const hideInMultiSort = ['claimFiledDate', 'claimStatus', 'claimReason', 'NAUPA_Code', 'NAUPA_Code_Category', 'NAUPA_Code_Description'];
    const doNotRemoveSpecialCharFields = ['connectedClaimID', 'propertyMinValueAmount', 'propertyMaxValueAmount', "updatedDate", "claimLastUpdatedDate", "stateClaimID"];

    const claimAttributesColumns = [
        {
            "show": true,
            "textAlign": "left",
            "width": 200,
            "field": "claimFiledDate",
            "fieldType": "Time",
            "exportFieldType": "dateWithTime",
            "mobile": true,
            "header": "Claim Filed Date",
            "filter": true,
            "sortable": false
        },
        {
            "show": true,
            "textAlign": "left",
            "width": 130,
            "field": "claimStatus",
            "mobile": true,
            "header": "Claim Status",
            "displayInSettings": true,
            "filter": false,
            "sortable": false
        },
        {
            "show": true,
            "textAlign": "left",
            "width": 120,
            "field": "claimReason",
            "mobile": true,
            "header": "Claim Status Reason",
            "displayInSettings": true,
            "filter": false,
            "sortable": false
        }
    ]
    useEffect(() => {
        setIsLoading(true);
        getPropertyStatus();
        getNAUPACategories();
        getOPPNAUPACategories();
    }, []);

    useEffect(() => {
        enableDisableLoadButton();
        return () => {
            setDisableGoButton(true);
        }
    }, [topFilters]);

    /**
     * Function to get NAUPA details for NAUPA category top filter
     */
    const getNAUPACategories = async () => {
        const res = await fetchMethodRequest('GET', 'NAUPACodes/distinctCodes', '', '', '', '');
        if (res.naupaCodes) {
            setNaupaCategories(res.naupaCodes.map((e: any) => { return { ...e, combinedLabel: `${e.NAUPA_Code_Category}-${e.NAUPA_Code_Description}` } }));
        }
        if (res && res.length > 0) {
            setNaupaCategories(res.reverse());
        }
        setIsLoading(false);
    }
    /**
  * Function to get NAUPA details for NAUPA category top filter
  */
    const getOPPNAUPACategories = async () => {
        const res = await fetchMethodRequest('GET', 'NAUPACodes/distinctOPPCodes', '', '', '', '');
        // if (res.naupaCodes) {
        //     setOPPNaupaCategories(res.naupaCodes.map((e: any) => { return { ...e, combinedLabel: `${e.OPP_NAUPA_Category}-${e.NAUPA_Code_Description}` } }));
        // }
        if (res && res.length > 0) {
            var newArr = res.filter((e: any) => {
                return e.OPP_NAUPA_Category !== "";
            });
            setOPPNaupaCategories(newArr.reverse());
        }
        setIsLoading(false);
    }

    /**
     * To get table fields in data table
     * @returns table fields array 
     */
    const getTableFields = () => {
        let tableFields = JSON.parse(JSON.stringify(fields));
        for (let i = 0; i < tableFields.length; i++) {
            if (tableFields[i].field === 'propertyStatus') {
                tableFields[i].filterType = "multiSelect";
                tableFields[i].filterOptions = propStatusDropDownValues;
            } else if (tableFields[i].field === 'propertyStatusReason') {
                tableFields[i].filterType = "multiSelect";
                tableFields[i].filterOptions = propReasonsDropDownvalues;
            }
        }
        let multiSortArray = tableFields.filter((e: any) => !hideInMultiSort.includes(e.field)).map((e: any, i: number) => {
            return { label: e.header, value: e.field, type: e.fieldType ? e.fieldType : "string", index: i.toString() };
        });
        setMultiSortFilters(multiSortArray);
        return tableFields;
    }

    /**
     * Setting user selected deal into state.     
     */
    const onSelectDeal = (deal: any) => {
        setSellerID(deal.DealID);
        onSelectFormValues('selDeal', deal);
    }

    /**
     * Updating user selecting top filter values into state.   
     */
    const onSelectFormValues = (field: string, value: any) => {
        setTopFilters((prevData: Object) => ({ ...prevData, [field]: value }));
        if (field === 'propertyStatus' && (!value || value.length === 0)) {
            onSelectFormValues('propertyStatusReason', '');
            setPropertyReasons([]);
        }
    }

    /**
     * Evaluates enable/disable Go button.
     */
    const enableDisableLoadButton = () => {
        let disabled = true;
        for (let field in topFilters) {
            if ((Array.isArray(topFilters[field]) && topFilters[field].length > 0) || (!Array.isArray(topFilters[field]) && topFilters[field])) {
                disabled = false;
            }
        }
        if (disabled && formattedTopFilters.length > 0) {
            setFormattedTopFilters([]);
        }
        setDisableGoButton(disabled);
    }

    const getCloseIcon = (field: string) => {
        return <FontAwesomeIcon icon={faTimes}
            className='pl-1 mr-2'
            color={topFilters[field] ? 'red' : 'lightgrey'}
            data-toggle="tool-tip"
            title="Clear Filter"
            id="clear stClaimId"
            onClick={() => onSelectFormValues(field, '')}
            style={{ width: 'unset', cursor: "pointer", float: "right", pointerEvents: `${topFilters[field] ? 'auto' : 'none'}` }}
        />
    }

    /**
     * Function that shows labels for top filters.
     */
    const getLabel = (label: string, field: string) => {
        return <label className='d-flex justify-content-between mb-0'>
            <span>{label}</span>
            {getCloseIcon(field)}
        </label>
    }

    /**
     * Funtion to get Property status & reasons for filter dropdowns.
     */
    const getPropertyStatus = async () => {
        const [results, reasons] = await Promise.all([commonApis.getPropertyStatuses('Property Editor', 'stageSequence, statusSequence, reasonSequence'), commonApis.getPropertyStatusReasons([], 'Property Editor', 'stageSequence, statusSequence, reasonSequence', true)]);
        setPropertyStatus(results.filter((e: any) => e.positiveValidation));
        let statusArray: Array<any> = [];
        let reasonsArray: Array<any> = [];
        if (reasons && reasons.length > 0) {
            reasons.forEach((reason: any) => {
                if (reason.positiveValidation) {
                    const statusIndex = statusArray.findIndex(obj => obj.label === reason.status);
                    if (statusIndex === -1) {
                        statusArray.push({ label: reason.status, value: reason.status })
                    }
                    const reasonIndex = reasonsArray.findIndex(obj => obj.label === reason.reason);
                    if (reasonIndex === -1) {
                        reasonsArray.push({ label: reason.reason, value: reason.reason })
                    }
                }
            });
        }
        setPropStatusDropDownValues(results.map((e: any) => { return { label: e.status, value: e.status } }));
        setPropReasonsDropDownsValues(reasonsArray);
        setShowDataTable(true);
    }

    /**
     * Function to get property reasons based on the options selected in property status dropdown. 
     * @param selStatus
     */
    const getPropertyStatusReasons = async (selStatus: Array<any>) => {
        let statuses = selStatus.map((e) => e.status);
        const results = await commonApis.getPropertyStatusReasons(statuses, 'Property Editor', 'stageSequence, statusSequence, reasonSequence');
        setPropertyReasons(results);
        if (topFilters.propertyStatusReason && topFilters.propertyStatusReason.length > 0) {
            let reasons = results.map((e: any) => e.reason);
            let availReasons = topFilters.propertyStatusReason.filter((e: any) => reasons.includes(e.reason));
            onSelectFormValues('propertyStatusReason', availReasons);
        }
    }

    /**
     * Callback function to close muti sort dialog.
     */
    const closeMultiSort = () => {
        setIsOpenMultiSortModal(false);
    }

    /**
     * Callback function to run on click Sort in mutli sort dialog. 
     * @param {Array} elem 
     */
    const applyMultiSort = (elem: Array<any>) => {
        setMultiSortFields(elem);
        let fieldsObj: any = {};
        multiSortFilters.forEach((e: any, i) => { fieldsObj[e.value] = i });

        setSelectedfieldsforSort(elem.map((obj: any, i: number) => {
            if (obj.key && fieldsObj.hasOwnProperty(obj.key)) {
                const fIndex = fieldsObj[obj.key];
                return {
                    "value": {
                        "label": multiSortFilters[fIndex].label,
                        "value": multiSortFilters[fIndex].value,
                        "type": multiSortFilters[fIndex].type
                    },
                    "fieldType": multiSortFilters[fIndex].type,
                    "type": "",
                    "index": fIndex.toString()

                }
            }
        }));
    }

    /**
     * Function to execute On Click 'X' button clearing top filters & multi sorting.
     */
    const onClickClearAllFilters = () => {
        setTopFilters({ propertyCity: '', propertyAddressLine1: '', propertyMinValueAmount: '', propertyMaxValueAmount: '', holderName: '', propertyDescription: '' });
        setFormattedTopFilters([]);
        clearSortingItems();
    }

    /**
     * Function to execute On Click Go button - formatting the filters for API.
     */
    const onClickGoButton = () => {
        const tempFormattedTopFilters: Array<any> = [];
        for (let field in topFilters) {
            if (Array.isArray(topFilters[field])) {
                if (topFilters[field].length > 0) {
                    let value = topFilters[field];
                    if (field === 'propertyState') {
                        value = topFilters[field].map((e) => e.shortCode);
                    } else if (field === 'propertyStatus') {
                        value = topFilters[field].map((e) => e.status);
                    } else if (field === 'propertyStatusReason') {
                        value = topFilters[field].map((e) => e.reason);
                    } else if (field === 'NAUPA_Categories') {
                        value = topFilters[field].map((e) => e.NAUPA_Code_Category);
                    }
                    else if (field === 'OPP_NAUPA_Category') {
                        value = topFilters[field].map((e) => e.OPP_NAUPA_Category);
                    }
                    tempFormattedTopFilters.push({ key: field, value: value, type: field === 'NAUPA_Code_Id' ? 'inObject' : 'in', add_Filter: true });
                }
            } else if (topFilters[field]) {
                let type = 'regexOr';
                let value = topFilters[field];
                if (typeof value === 'string' && doNotRemoveSpecialCharFields.includes(field) === false) {
                    value = value.replace(/[^\w\s]/gi, ' ');
                }
                if (field === 'selDeal') {
                    value = topFilters[field].DealID;
                    type = 'eq';
                } else if (field === 'propertyMinValueAmount') {
                    type = 'gte';
                } else if (field === "updatedDate" || field === "claimLastUpdatedDate") {
                    value = dateFormats.formatDate(value, "MM-DD-yyyy")
                    type = "dategte"
                }
                else if (field === 'propertyMaxValueAmount') {
                    type = 'lte';
                }
                tempFormattedTopFilters.push({ key: field === 'selDeal' ? 'CompanyID' : field, value: value, type: type, add_Filter: true });
            }
        }

        if (JSON.stringify(tempFormattedTopFilters) === JSON.stringify(formattedTopFilters)) {
            setDoSearch(!doSearch);
        } else {
            setFormattedTopFilters(tempFormattedTopFilters);
            clearSortingItems();
        }


    }

    const clearSortingItems = (fromColReOrder?: boolean) => {
        if (fromColReOrder) {
            setFromColReOrder(true);
        }
        setMultiSortFields([]);
        setSelectedfieldsforSort([]);
    }

    const updateMultiSortFields = (tableFields: Array<any>) => {
        let multiSortArray = tableFields.map((e: any, i: number) => {
            return { label: e.header, value: e.field, type: e.fieldType ? e.fieldType : "string", index: i.toString() };
        });
        setMultiSortFilters(multiSortArray);
    }

    const getDataTable = React.useMemo(() => {
        return <DataTables
            getTableFields={getTableFields}
            formFields={getTableFields}
            exportRequried={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            sortField={"BusinessName, PropertyState, NameMatchScore"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            filterRequired={true}
            gridRequried={false}
            sample={false}
            scrollHeight={"580px"}
            datakey={"_id"}
            globalSearch={'Search'}
            type={"Mapped Property Search Report"}
            displayright={true}
            icon='faCity'
            fontAwesome={true}
            displayViewOfForm='modal'
            hideHeader={true}
            apiResponseKey='ns_scored_positively_mapped_properties'
            className={true}
            apiUrl={"positivelyMappedProperties"}
            entityType='employee'
            hideProgress={true}
            paginatorPosition={'top'}
            style={{ marginTop: 10 }}
            openMultiSort={() => { setIsOpenMultiSortModal(true) }}
            donotIncludeParamsInFilter={true}
            topSectionFilters={formattedTopFilters}
            sellerID={sellerID}
            multiSortFields={multiSortFields}
            propertyStatusArray={propStatusDropDownValues}
            propertyStatusReasonArray={propReasonsDropDownvalues}
            screenPermissionsRoute={"Mapped Property Search"}
            clearSortingItems={clearSortingItems}
            updateMultiSortFields={updateMultiSortFields}
            fromColReOrder={fromColReOrder}
            resetColReorderFlag={() => setFromColReOrder(false)}
            optionalColumns={claimAttributesColumns}
            showOptionalColumns={showClaimAttributes}
            enableOptionalColumnsOption={() => setShowClaimAttributes(true)}
            doSearch={doSearch}
            doNotRemoveSpecialCharFields={doNotRemoveSpecialCharFields}
        />
    }, [multiSortFields, formattedTopFilters, sellerID, fromColReOrder, showClaimAttributes, doSearch])

    return <>
        <div className="card pb-0 mt-1 mapped-prop-search-top-filters">
            <div className="card-body pt-2 pb-1">
                {(rolesPermission && rolesPermission['Mapped Property Search'] && (rolesPermission['Mapped Property Search'] === 'Edit' || rolesPermission['Mapped Property Search'] === 'View')) &&
                    <div className="row">
                        <div className="col-sm-11">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-1">
                                    {getLabel('Deal', 'selDeal')}
                                    <div className="w-100">
                                        <DealDropdown onSelectDeal={onSelectDeal} selectedDeal={topFilters.selDeal} />
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    {getLabel('Prop. Addr Line 1', 'propertyAddressLine1')}
                                    <InputText value={topFilters.propertyAddressLine1} onChange={(e) => { onSelectFormValues('propertyAddressLine1', (e.target as HTMLInputElement).value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {getLabel('Prop. City', 'propertyCity')}
                                            <InputText value={topFilters.propertyCity} onChange={(e) => { onSelectFormValues('propertyCity', (e.target as HTMLInputElement).value) }}
                                                style={{ minWidth: '100%' }} />

                                        </div>
                                        <div className="col-md-6 mb-1">
                                            {getLabel('Prop. State', 'propertyState')}
                                            <MultiSelect options={states} filter={true} optionLabel="label" dataKey="shortCode" value={topFilters.propertyState} onChange={(e) => { onSelectFormValues('propertyState', e.value) }}
                                                style={{ minWidth: '100%' }} maxSelectedLabels={2} />
                                        </div>
                                    </div>

                                </div>


                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {getLabel(`Prop. Min Value ${'>='}`, 'propertyMinValueAmount')}
                                            <InputText value={topFilters.propertyMinValueAmount} type="number" onChange={(e) => { onSelectFormValues('propertyMinValueAmount', (e.target as HTMLInputElement).value) }}
                                                style={{ minWidth: '100%' }} />
                                        </div>
                                        <div className="col-md-6">
                                            {getLabel(`Prop. Max Value ${'<='}`, 'propertyMaxValueAmount')}
                                            <InputText value={topFilters.propertyMaxValueAmount} type="number" onChange={(e) => { onSelectFormValues('propertyMaxValueAmount', (e.target as HTMLInputElement).value) }}
                                                style={{ minWidth: '100%' }} />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    {getLabel('Prop. Status', 'propertyStatus')}
                                    <MultiSelect value={topFilters.propertyStatus} filter={true} options={propertyStatus} optionLabel="status" dataKey="_id" onChange={(e) => { onSelectFormValues('propertyStatus', e.value); getPropertyStatusReasons(e.value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    {getLabel('Prop. Reason', 'propertyStatusReason')}
                                    <MultiSelect value={topFilters.propertyStatusReason} filter={true} options={propertyReasons} optionLabel="reason" dataKey="_id" onChange={(e) => { onSelectFormValues('propertyStatusReason', e.value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    {getLabel('Holder Name', 'holderName')}
                                    <InputText value={topFilters.holderName} onChange={(e) => { onSelectFormValues('holderName', (e.target as HTMLInputElement).value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    {getLabel('Property Desc', 'propertyDescription')}
                                    <InputText value={topFilters.propertyDescription} onChange={(e) => { onSelectFormValues('propertyDescription', (e.target as HTMLInputElement).value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    {getLabel('NAUPA Category', 'NAUPA_Categories')}
                                    <MultiSelect value={topFilters.NAUPA_Categories} maxSelectedLabels={1} filter={true} options={naupaCategories} optionLabel="NAUPA_Code_Category" dataKey="_id" onChange={(e) => { onSelectFormValues('NAUPA_Categories', e.value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    {getLabel('OPP NAUPA Category', 'OPP_NAUPA_Category')}
                                    <MultiSelect value={topFilters.OPP_NAUPA_Category} maxSelectedLabels={1} filter={true} options={oppNaupaCategories} optionLabel="OPP_NAUPA_Category" dataKey="_id" onChange={(e) => { onSelectFormValues('OPP_NAUPA_Category', e.value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-1">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {getLabel(`Property Last Update Dt ${'>='}`, 'updatedDate')}
                                            <Calendar
                                                style={{ zindex: 1004, width: "100%", lineHeight: 1.15, height: '25px' }}
                                                appendTo={document.body}
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                hourFormat="24"
                                                // minDate={new Date()}
                                                yearRange="1940:2530"
                                                name={"calendar1"}
                                                placeholder={"Select Date"}
                                                // placeholder='mm/dd/yyyy'
                                                dateFormat="mm/dd/yy"
                                                value={topFilters.updatedDate}
                                                onChange={(e) => { onSelectFormValues('updatedDate', e.value) }}
                                            >
                                            </Calendar>
                                        </div>
                                        <div className="col-md-6 mb-1">
                                            {getLabel(`Claim Last Update Dt ${'>='}`, 'claimLastUpdatedDate')}
                                            <Calendar
                                                style={{ zindex: 1004, width: "100%", lineHeight: 1.15, height: '25px' }}
                                                appendTo={document.body}
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                hourFormat="24"
                                                disabled={disableGoButton}
                                                // minDate={new Date()}
                                                yearRange="1940:2530"
                                                name={"calendar1"}
                                                placeholder={"Select Date"}
                                                // placeholder='mm/dd/yyyy'
                                                dateFormat="mm/dd/yy"
                                                value={topFilters.claimLastUpdatedDate}
                                                onChange={(e) => { onSelectFormValues('claimLastUpdatedDate', e.value) }}
                                            >
                                            </Calendar>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 d-flex">
                                    <div className="mr-2 d-flex align-items-center">Claim Attributes</div>
                                    <div className="mr-2 d-flex align-items-center">
                                        <InputSwitch checked={showClaimAttributes} onChange={(e) => setShowClaimAttributes(e.value)} />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-1 d-flex mt-3 align-items-center flex-column ">
                            <div className="filter-buttons">
                                <Button color="success" type='submit' className='go-button mr-0 w-100' disabled={disableGoButton} onClick={onClickGoButton}>
                                    GO
                                </Button>
                                <Button color="primary" outline className="w-100 mt-3" onClick={onClickClearAllFilters}>
                                    X
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div >
            {showDataTable &&
                <>{getDataTable}</>
            }

        </div>
        {isOpenMultiSortModal &&
            <MultiSortModal
                selectedfieldsforSort={selectedfieldsforSort}
                isOpenMultiSortModal={isOpenMultiSortModal}
                multiSortFields={multiSortFilters}
                cancelMultiSortModel={closeMultiSort}
                applyMultisort={applyMultiSort}
                restrictNumber={5}
            />
        }
        <Loader loader={isLoading} />
    </>
}

export default MappedProportySearchReport;