import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'reactstrap';

const AddressEvidSort = ({selectedDocs, showSortModal, updateSelectedFilesInParent}) => {

    const [sortItems, setSortItems] = useState([]);
    const sortedFileRef = useRef(selectedDocs);

    useEffect(() => {
      setSortItems(JSON.parse(JSON.stringify(selectedDocs)));
    
      return () => {
        setSortItems([]);
      }
    }, [selectedDocs])
    

    /**
     * Drag and drop
     * @param {Object} result 
     *
     */
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            sortItems,
            result.source.index,
            result.destination.index
        );
        sortedFileRef.current = items;
        setSortItems(items);
        
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    const onSave = () => {
        updateSelectedFilesInParent(sortedFileRef.current, true);
    }

    return (
        <div>
            {showSortModal && 
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} style={{ textAlign: 'left' }}>
                            {
                                sortItems.map((item, index) => (
                                    <Draggable key={`${item._id}${index}`} draggableId={`${item._id}${index}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className='document-name address-evid-drag-drop'
                                            >
                                                
                                                {(item.addresses && item.addresses.length && item.addresses.length > 0) ?
                                                    <div className='d-flex justify-content-between w-100'>
                                                        <div className='d-flex align-items-center' style={{width: '50%'}}>                                                            
                                                            <div>{item.fileName}</div>
                                                        </div>
                                                        <div className='d-flex address-evid-wrapper' >
                                                            {item.addresses.map((address, index) => <div className='d-flex flex-column mx-2 border-right border-info address-names' key={index}>
                                                                    <div>{address.bsAddress}{(address.bsCity || address.bsState) && ','}</div>
                                                                    <div>{address.bsCity}{address.bsState && ','} {address.bsState}</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='d-flex w-100'>
                                                        <div>{item.fileName}</div>
                                                    </div>
                                                   
                                                }
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            }
                        </div>
                    )}
                </Droppable>

            </DragDropContext>
            }

            <div className='mt-4 text-center'>
                <Button color='primary' onClick={onSave}> Save & Close</Button>
            </div>
        </div>
    );
};

export default AddressEvidSort;