// import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, Card } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import * as commonApi from "../../../shared/commonApi"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import CheckBox from '../../../shared/components/form/CheckBox';
import config from '../../../config/config';
import fetch from "../../../config/service";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import configImages from '../../../config/configImages';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import ConfirmaionModel from './ConfirmationModal';
import Loader from '../../App/Loader';

const Container1 = styled.div`
  display: flex;
`
const Container = styled.div`
  border: 1px solid white;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDragging
            ? 'skyblue' : 'lightgrey'};
`
const Container5 = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;


  display: flex;
  flex-direction: column;
`

const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDraggingOver ? 'skyblue' : 'white'}
  flex-grow: 1;
  min-height: 100px;
`

class ColumnOrderModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableFields: [],
            changedTableFields: '',
            selectTableFields: [],
            incomingPreferences: JSON.parse(JSON.stringify(this.props.tableFields)),
            notSelectedTableFields: [],
            openConfirmationModal: false,
            name: this.props.edited ? this.props.columnOrderPreferenceDetailsObj.label : "",
            hideFields: [],
            columnOrderById: {},
            updateClaim: this.props.edited,
            newClaim: !this.props.edited,
            columns: {
                'column-1': { id: 'column-1', title: '', taskIds: [] },
            },
            columnOrder: ['column-1', 'column-2', 'column-3'],
            finalArr: [],
            permissions: {},
            views: config.viewTypes,
            selectedViewType: "",
            isLoading: false
        };
    }

    componentDidMount = async () => {
        await this.getScreenFieldsData();
        if (this.props.edited) {
            await this.getColumnOrderDetailsById()
        }
    }
    getColumnOrderDetailsById = async (id) => {
        const results = await commonApi.getColumnOrderById(id ? id : this.props.columnOrderPreferenceDetailsObj.value);
        await this.setState({ columnOrderById: results })
    }

    /**
     * 
     * @param {String} type 
     * closing of model for show hide columns
     */

    closeShowHideColumnsModalForDeal = async (type) => {
        await this.setState({
            isopenColumnModal: false
        })
        if (type === "close") {
            this.props.closeShowHideColumnsModalForDeal('close', null, this.state.incomingPreferences)
        }
    }


    /**
     * 
     * @returns opening the confirmation model for Showing message
     */
    getConfirmaionModel() {
        return (
            <ConfirmaionModel
                openConfirmationModal={this.state.isopenColumnModal}
                closeConfirmationModal={this.closeShowHideColumnsModalForDeal}
                shownText={"Are you sure you want to close before Saving?"}
                confirm={this.submit}
            />
        )
    }
    /**
     * 
     * @param {String} viewType 
     * rearranging the data in order to show as columns
     */
    getScreenFieldsData = async (viewType) => {
        // if (viewType) {
        let columnOrder = this.state.columnOrder
        let originalTableFields = this.props.originalTableFields
        let columns = this.state.columns;
        let colOrder = this.props.tableFields;
        // let localcolumns = await JSON.parse(colOrder);
        let tempTableFields = colOrder;
        let tablenotKnownFields = originalTableFields.filter(function (el) {
            let index = tempTableFields.findIndex(obj => obj.field === el.field)

            if (index == -1) {
                return el
            }
        });
        if (tablenotKnownFields && tablenotKnownFields.length > 1) {
            tempTableFields = [...tempTableFields, ...tablenotKnownFields]
        }
        for (var column of tempTableFields) {
            let index = originalTableFields.findIndex(obj => obj.field === column.field)
            if (index !== -1) {
                if (column.field === originalTableFields[index].field) {
                    column.header = originalTableFields[index].header
                }
            }
        }
        if (tempTableFields) {
            let index = tempTableFields.findIndex(obj => obj.field === null)
            if (index !== -1) tempTableFields.splice(index, 1)
        }

        if (originalTableFields) {
            for (var column of tempTableFields) {
                let isPresentinlocal = originalTableFields.find(item => item.field === column.field);
                if (isPresentinlocal === undefined) {
                    let deleteindex = tempTableFields.findIndex(obj => obj.field === column.field)
                    tempTableFields.splice(deleteindex, 1)
                }
                tempTableFields = tempTableFields
            }
        }
        if (tempTableFields.length > 23) {
            columns['column-1'].taskIds = tempTableFields
        } else if (tempTableFields.length > 12) {
            columns['column-1'].taskIds = tempTableFields
            columnOrder = ['column-1', 'column-2']
        } else {
            columns['column-1'].taskIds = tempTableFields
            columnOrder = ['column-1']
        }
        this.setState({
            tableFields: tempTableFields,
            changedTableFields: tempTableFields,
            hideFields: this.props.columnOrderPreferenceDetailsObj ? this.props.columnOrderPreferenceDetailsObj.hideFields : [],
            columns: columns,
            columnOrder: columnOrder
        })
        // }
    }

    /**
     * 
     * @param {Object} newState 
     * Dividing the array based on length
     */
    divideArray = async (newState) => {
        let tempFields = [...newState.columns['column-1'].taskIds, ...newState.columns['column-2'].taskIds, ...newState.columns['column-3'].taskIds,]
        let columns = this.state.columns
        await this.setState({
            columns: columns
        })

    }
    /**
     * 
     * @param {Array} result 
     * @returns On drag and drop of column fields arraning the order
     */

    // onDragEnd = result => {
    //     const { destination, source } = result
    //     if (!destination) {
    //         return
    //     }
    //     if (
    //         destination.droppableId === source.droppableId &&
    //         destination.index === source.index
    //     ) {
    //         return
    //     }

    //     const start = this.state.columns[source.droppableId]
    //     const finish = this.state.columns[destination.droppableId]

    //     if (start === finish) {
    //         const newTaskIds = Array.from(start.taskIds)
    //         let changedParam = start.taskIds[source.index]
    //         newTaskIds.splice(source.index, 1)
    //         newTaskIds.splice(destination.index, 0, changedParam)

    //         const newColumn = {
    //             ...start,
    //             taskIds: newTaskIds
    //         }

    //         const newState = {
    //             ...this.state,
    //             columns: {
    //                 ...this.state.columns,
    //                 [newColumn.id]: newColumn
    //             }
    //         }

    //         this.setState(newState)
    //         return
    //     }

    //     // Moving from one list to another
    //     const startTaskIds = Array.from(start.taskIds)
    //     let ChangedParam = startTaskIds[source.index]
    //     startTaskIds.splice(source.index, 1)
    //     const newStart = {
    //         ...start,
    //         taskIds: startTaskIds
    //     }

    //     const finishTaskIds = Array.from(finish.taskIds)
    //     finishTaskIds.splice(destination.index, 0, ChangedParam)
    //     const newFinish = {
    //         ...finish,
    //         taskIds: finishTaskIds
    //     }
    //     const newState = {
    //         ...this.state,
    //         columns: {
    //             ...this.state.columns,
    //             [newStart.id]: newStart,
    //             [newFinish.id]: newFinish
    //         }
    //     }

    //     this.setState(newState)
    //     this.divideArray(newState)

    // }

    getGlobalIndex = (columnId, columnIndex) => {
        if (columnId === 'column-1') {
            return columnIndex;
        } else if (columnId === 'column-2') {
            return (12 + columnIndex);
        } else if (columnId === 'column-3') {
            return (23 + columnIndex);
        }
        return columnIndex;
    };

    onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const fullTaskIds = this.state.columns['column-1'].taskIds;

        const sourceGlobalIndex = this.getGlobalIndex(source.droppableId, source.index);
        const destinationGlobalIndex = this.getGlobalIndex(destination.droppableId, destination.index);

        const newTaskIds = Array.from(fullTaskIds);
        const removed = newTaskIds.splice(sourceGlobalIndex, 1)[0];

        newTaskIds.splice(destinationGlobalIndex, 0, removed);

        const newState = {
            ...this.state,
            columns: {
                ...this.state.columns,
                'column-1': {
                    ...this.state.columns['column-1'],
                    taskIds: newTaskIds,
                },
            },
        };

        this.setState(newState);
    };



    /**
     * 
     * @param {Object} event 
     * @param {String} column 
     *  Onchange checkbox
     */
    onChange = async (event, column) => {
        let columns = this.state.columns
        let columnOrd = 'column-1'
        if (event && event.target.name && columns[columnOrd] && columns[columnOrd].taskIds && columns[columnOrd].taskIds.length > 0) {
            columns[columnOrd].taskIds.map(col => {
                if (col.field === event.target.name) {
                    col.checked = !col.checked
                }
                return col
            })
        }
        this.setState({
            columns: columns,
        })
    }
    /**
    * 
    * @param {*} cardtype 
    * @param {*} value 
    * @param {*} data 
    * @param {*} dataType 
    * showing the new exhibit values
    */
    getnewExistingValues = async (cardtype, value, data, dataType) => {
        let isSplitt_States = this.state.hideFields
        if (value && value.field && isSplitt_States && isSplitt_States.length > 0) {
            isSplitt_States.map(col => {
                if (col.field === value.field) {
                    col.checked = !col.checked
                }
                return col
            })
        }
        this.setState({
            hideFields: isSplitt_States,
        })

    }
    /**
     * 
     * @param {Object} event 
     * @param {String} column 
     * @param {String} changedField 
     *  Onchange of width
     */
    onChangeWidth = async (event, column, changedField) => {
        let columns = this.state.columns
        if (event && columns[column] && columns[column].taskIds && columns[column].taskIds.length > 0) {
            columns[column].taskIds.map(col => {
                if (col.field === changedField) {
                    if (parseInt(event.target.value)) {
                        col.width = parseInt(event.target.value)
                    } else {
                        col.width = ""
                    }
                }

                return col
            })
        }
        this.setState({
            columns: columns,
        })
    }

    /**
     * closing of models
     */
    getCloseData = () => {

        this.closeShowHideColumnsModalForDeal()

    }
    /**
     * 
     * @param {Array} values 
     * submit function on click of submit 
     */
    submit = async (values) => {
        this.setState({ disabledSaveButton: true, isLoading: true });
        let method, url, body = {}
        let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
        // let  allTaskIds = Object.values(this.state.columns).flatMap(column => column.taskIds);
        let allTaskIds = this.state.columns['column-1'].taskIds

        if (this.state.updateClaim) {
            body = this.state.columnOrderById
            body.userPreference = allTaskIds
            // this.state.columns["column-1"].taskIds
            body.hideFields = this.state.hideFields
            body.columnOrderName = this.state.name
            method = "PUT"
            url = `columnOrderPreference/${this.props.columnOrderPreferenceDetailsObj.value}`

        } else {
            method = "POST"
            body = { columnOrderName: this.state.name, screen: this.props.type, userPreference: allTaskIds, hideFields: this.state.hideFields, userId: loginCredentials._id }
            url = 'columnOrderPreference'
        }
        return fetch(method, url, body)
            .then(async (response) => {
                if (response && response.respCode) {
                    await this.getColumnOrderDetailsById(response.columnOrderPreferenceId)
                    await this.props.closeShowHideColumnsModalForDeal(this.state.columnOrderById, response)
                }
                this.setState({ disabledSaveButton: false, isLoading: false });
            })
            .catch((err) => {
                return err;
            });


    }
    /**
   * Moving Columns from left to right
   */
    moveColumnsFromLeftToRight = async () => {
        if (this.state.columns) {
            let selectedColOrder = 'column-1';
            let originalColumns = this.state.columns;
            let originalColumnsForComparing = this.state.columns ? JSON.parse(JSON.stringify(this.state.columns)) : null;
            let originalArray = this.state.hideFields ? this.state.hideFields : [];
            var newArray = originalColumns[selectedColOrder].taskIds.filter(function (el) {
                return el.checked === true
            });
            if (newArray && newArray.length > 0) {
                for (var obj of originalColumns[selectedColOrder].taskIds) {
                    var combinedObj = Object.assign({}, obj)
                    if (obj.checked === true) {
                        combinedObj.checked = true
                        combinedObj.show = false
                        originalArray.push(combinedObj)
                        originalColumnsForComparing[selectedColOrder].taskIds.splice(originalColumnsForComparing[selectedColOrder].taskIds.findIndex(a => a.field === obj.field), 1)
                    }
                }
                await originalArray.sort((a, b) => {
                    let fa = a.header.toLowerCase(),
                        fb = b.header.toLowerCase();
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                await this.setState({ columns: originalColumnsForComparing, hideFields: originalArray, })
            } else {
                this.setState({ apierrorResponse: true, apierrorMessage: `Please select any Fields before clicking` })
            }
            this.setState({
                showErrorMessage: false
            })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any Fields before clicking` })

        }
    }
    /**
   * Moving Columns from right to left
   */
    moveColumnsFromRightToLeft = async () => {
        // await this.setState({
        //     isLoading: true
        // })
        if (this.state.hideFields) {
            let hiddenFields = this.state.hideFields;
            let hiddenFieldsForComparing = JSON.parse(JSON.stringify(this.state.hideFields));
            let originalColumns = this.state.columns;
            var newArray = hiddenFields.filter(function (el) {
                return el.checked === true
            });
            if (newArray && newArray.length > 0) {
                for (var obj of hiddenFields) {
                    var combinedObj = Object.assign({}, obj)
                    if (obj.checked === true) {
                        combinedObj.checked = true
                        combinedObj.show = true
                        originalColumns["column-1"].taskIds.push(combinedObj)
                        hiddenFieldsForComparing.splice(hiddenFieldsForComparing.findIndex(e => e.field === obj.field), 1)
                    }
                }
                await this.setState({ columns: originalColumns, hideFields: hiddenFieldsForComparing })
            } else {
                this.setState({ apierrorResponse: true, apierrorMessage: `Please select any Fields before clicking` })
            }
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any Fields before clicking` })

        }
    }
    onNameChange = async (e) => {
        await this.setState({ name: e.target.value })
    }
    selectViewType = async (e) => {
        await this.setState({ selectedViewType: e })
        await this.getScreenFieldsData(this.state.selectedViewType);

    }
    setType = async (key) => {
        key === "updateClaim" ? this.setState({ updateClaim: true, newClaim: false, name: this.props.columnOrderPreferenceDetailsObj.label }) : this.setState({ updateClaim: false, newClaim: true, name: "" })
    }

    renderTaskContent = (task, columnId, viewType, t) => {
        return (
            <div className="draggable-item">
                <Field
                    className='mr-0 my-0 mt-0'
                    key={task.field}
                    name={task.field ? task.field : null}
                    component={CheckBox}
                    checked={viewType === 'grid' ? task.mobile : task.checked}
                    value={viewType === 'grid' ? task.mobile : task.checked}
                    onChange={(e) => this.onChange(e, columnId)}
                    label={task.header ? `${t(task.header)}` : "Selection"}
                />
                <span className="ml-auto" style={{ width: "23%" }}>
                    <input
                        className='ml-auto'
                        type="text"
                        style={{ height: 24, width: "71%" }}
                        key={`${task.field}+width`}
                        name={task.field ? `${task.field}+width` : null}
                        component={DefaultInput}
                        defaultValue={task.width}
                        value={task.width}
                        onChange={(e) => this.onChangeWidth(e, columnId, task.field)}
                        label={task.header ? `${(task.header)}--${t(task.width)}px` : "Selection"}
                    /></span>
            </div>
        )
    }

    getTasksByColumn = (columnId, column) => {
        const allTasks = this.state.columns['column-1'].taskIds;
        if (columnId === 'column-1') {
            return allTasks.slice(0, 12);
        } else if (columnId === 'column-2') {
            return allTasks.slice(12, 23);
        }
        return allTasks.slice(23);
    };

    render() {
        const { t, handleSubmit, viewType, isShownnFieldType } = this.props;
        const { columnOrder } = this.state;
        return (
            <div className='p-1'>
                <Modal isOpen={this.props.isOpenShowHideColumnsModal} fade={false}
                    className={`modal-dialog-centered modal-dialog--primary modal-dialog--header`}
                    style={{ maxWidth: "1500px" }}
                >
                    <ModalHeader className="modal__header" style={{ padding: 16, justifyContent: "center" }}>
                        <button className="lnr lnr-cross modal__close-btn" type="button"
                            style={{ color: "black" }}
                            onClick={() => this.props.closeShowHideColumnsModalForDeal()}
                        />

                        <b>Manage Columns Order </b>
                    </ModalHeader>
                    <ModalBody className='deleteModalBody' style={isShownnFieldType ? { margin: "auto" } : {}}>
                        <Loader loader={this.state.isLoading} className="loadPageProp" />

                        <form onSubmit={handleSubmit(this.submit)} >
                            <div >
                                <div className="row ml-1 justify-content-lg-center">
                                    {this.props.edited ? <div className="row ml-1 justify-content-lg-center">
                                        <RadioButton inputId="updateClaim" name="updateClaim" value={this.state.updateClaim} onChange={(e) => this.setType("updateClaim")} checked={this.state.updateClaim} />
                                        <label className="ml-2">Update Existing Column Order</label>
                                        <RadioButton className='ml-5' inputId="newClaim" name="newClaim" value={this.state.newClaim} onChange={(e) => this.setType("newClaim")} checked={this.state.newClaim} />
                                        <label className="ml-2">Create New Column Order</label>
                                    </div> : null}
                                </div>
                                <div className='mt-1 mb-2 d-flex'>
                                    <p className='mr-2 mt-2'> {"Name :"}</p>
                                    <InputText
                                        type="Name"
                                        name='Name'
                                        id='Name'
                                        value={this.state.name}
                                        style={{
                                            height: 37,
                                            width: "90%"
                                        }}
                                        onChange={(e) => this.onNameChange(e)}
                                        placeholder={'Enter Column Order Name'}
                                    />
                                </div>

                                <>
                                    <div className='row pr-1'>

                                        <div
                                            className={Object.keys(columnOrder).length === 1 ? "col-md-4" : Object.keys(columnOrder).length === 2 ? "col-md-6" : "col-md-8"}
                                            style={{ height: "70vh", overflow: "hidden" }}
                                        >
                                            <div className='text-center mb-1'><b>Current Column Order</b></div>
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                {/* Wrapper for Columns */}
                                                <Container1 className="list-wrapper" style={{ display: "flex", gap: "10px", height: '95%', justifyContent: Object.keys(columnOrder).length === 1 ? 'center' : 'start' }}>
                                                    {this.state.columnOrder.map((columnId) => {
                                                        const column = this.state.columns[columnId];

                                                        const tasks = this.getTasksByColumn(columnId, column);

                                                        return (
                                                            <Container5
                                                                className="column-container">
                                                                {/* List View for Column */}

                                                                <div
                                                                    className={`list-view-for-Draging ${columnId === "column-3" ? "scrollable-content" : ""}`}
                                                                    style={{
                                                                        overflowY: columnId === "column-3" ? "auto" : "hidden",
                                                                        maxHeight: columnId === "column-3" ? "70vh" : "auto"
                                                                    }}
                                                                >
                                                                    <Droppable droppableId={columnId} type="TASK">
                                                                        {(provided, snapshot) => (
                                                                            <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                                                                                {tasks.length > 0 && tasks.map((task, index) =>
                                                                                    task.header && task.field ? (
                                                                                        <Draggable key={task.field} draggableId={task.field} index={index}>
                                                                                            {(provided, snapshot) => (

                                                                                                <Container
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    ref={provided.innerRef}
                                                                                                    isDragging={snapshot.isDragging}
                                                                                                    className="field-container"
                                                                                                >
                                                                                                    {this.renderTaskContent(task, columnId, viewType, t)}
                                                                                                </Container>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ) : null
                                                                                )}
                                                                                {provided.placeholder}
                                                                            </TaskList>
                                                                        )}
                                                                    </Droppable>
                                                                </div>
                                                            </Container5>
                                                        );
                                                    })}
                                                </Container1>
                                            </DragDropContext>
                                        </div>

                                        <div className={Object.keys(columnOrder).length === 3 ? "col-md-1" : 'col-sm-2'} style={{ placeItems: "center", top: "200px" }}>

                                            <span className="ml-1" style={{ textAlign: "center", display: "grid", gap: "20px" }}>
                                                <div style={{ textAlign: "center" }}>
                                                    <p style={{ margin: "0", fontWeight: "bold" }}>Remove</p>
                                                    <img
                                                        src={configImages.rightArrowRed}
                                                        alt="Remove"
                                                        style={{ width: "50px", cursor: "pointer", height: "50px" }}
                                                        onClick={() => this.moveColumnsFromLeftToRight()}
                                                    />
                                                </div>

                                                <div style={{ textAlign: "center" }}>
                                                    <p style={{ margin: "0", fontWeight: "bold" }}>Add</p>
                                                    <img
                                                        src={configImages.leftArrowGreen}
                                                        alt="Add"
                                                        style={{ width: "50px", cursor: "pointer", height: "45px" }}
                                                        onClick={() => this.moveColumnsFromRightToLeft()}
                                                    />
                                                </div>
                                            </span>


                                        </div>
                                        <div className='col-md-3' style={{ height: "70vh", overflow: "auto" }}>
                                            <div className='text-center mb-1 pb-2'><b>Available Columns</b></div>
                                            <Card className="pb-0" style={{ overflow: 'auto', border: "1px solid grey", height: "94%" }}>
                                                {this.state.hideFields && this.state.hideFields.length > 0 ? this.state.hideFields.map((notShowPreference, index2) => {
                                                    return <div className='d-flex m-1'>
                                                        <Field
                                                            className='mr-0 my-0 mt-0'
                                                            key={notShowPreference.field}
                                                            name={notShowPreference.field ? notShowPreference.field : null}
                                                            component={CheckBox}
                                                            checked={viewType === 'grid' ? notShowPreference.mobile : notShowPreference.checked}
                                                            value={viewType === 'grid' ? notShowPreference.mobile : notShowPreference.checked}
                                                            onChange={(e) => this.getnewExistingValues("newExisting", notShowPreference, this.state.hideFields)}
                                                            label={notShowPreference.header ? `${t(notShowPreference.header)}` : "Selection"}
                                                        />
                                                    </div>

                                                }) : null}

                                            </Card>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 text-center mt-2'>
                                        <Button
                                            color="primary"
                                            outline
                                            type="button"
                                            onClick={() => this.props.closeShowHideColumnsModalForDeal()}
                                            className='deleteModalBtn marginRight'
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color='primary'
                                            outline
                                            type="submit"
                                            disabled={(!this.state.name || (this.state.name && this.state.disabledSaveButton)) ? true : false}
                                            className='deleteModalBtn'
                                        >
                                            Confirm
                                        </Button>
                                    </div>
                                </>

                            </div>


                        </form>
                        {this.state.isopenColumnModal ?
                            this.getConfirmaionModel() : null
                        }
                    </ModalBody>
                </Modal>
            </div >
        )
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ColumnOrderModal = reduxForm({
    form: "ColumnOrderModal Form", // a unique identifier for this form
    enableReinitialize: true,
})(ColumnOrderModal);

export default withTranslation('common')(ColumnOrderModal);