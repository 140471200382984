import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import classNames from 'classnames';
// fecth method from service.js file
import fetch from '../../../config/service';
import { withTranslation } from 'react-i18next';
import HistoryTabData from "../../Cruds/CommonModals/TaskModal/HistoryTab"
import ConfirmationForRedirection from '../../Cruds/CommonDataTable/ConfirmationToRedirection'
import RFIForm from './RFIForm'
import DeleteRowModal from './DeleteRowModal';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';


//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class RFIModal extends React.Component {
  constructor(props) {
    super(props);
    this.buttonActionType = null;
    this.state = {
      activeTab: "1",
      selectedObj: {}
    };
  }


  // clear input data
  clearInputFields = () => {
    this.props.reset();
  }

  submit = (values) => {



  }


  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }

  noteSavedStatusFunc = async (status) => {
    await this.setState({ noteSavedStatus: status })

  }

  checkNoteStatus = async () => {
    console.log("this.props.ttyyyyy", this.props.type)
    if (this.state.noteSavedStatus && this.props.type != "add") {
      await this.setState({ openUnsaveModal: true })
      return
    }
    this.props.isCloseRFIModal("")
  }


  componentDidMount = async () => {
    let getDetailsFromTaskId = this.props.selectedObj && this.props.selectedObj._id ? await this.getDetailsFromTaskId(this.props.selectedObj._id) : this.props.selectedObj
    await this.setState({
      selectedObj: getDetailsFromTaskId
    })
  }

  getDetailsFromTaskId = async (taskId) => {
    this.setState({
      isLoading: true,
    });
    this.handlePercentage("1");
    return fetch("GET", `tasks/${taskId}`)
      .then(async (response) => {
        if (response && response.details) {
          this.setState({ isLoading: false });
          return response.details
        } else if (response && response.errorMessage) {
          this.getErrorMessage(response.errorMessage);
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        return err;
      });
  }
  closeSaveModelPopUp = async () => {
    await this.setState({ openUnsaveModal: false })
    if (this.rfiFormRef.getForm) {
      await this.rfiFormRef.getForm()
    }
    // this.props.isCloseRFIModal("")

  }
  closeTaskAddModel = async () => {
    this.props.isCloseRFIModal("")
  }
  // Delete Modal
  getDeleteRowModal() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openUnsaveModal}
        closeDeleteModal={this.closeSaveModelPopUp}
        deleteSelectedRow={this.closeTaskAddModel}
        unSaved={true}
        notesUnsaved={true}
        confirmModalText={"You have unsaved notes."}
        type={this.props.type}
      />
    )
  }

  confirmMationModelforRedirection = () => {
    console.log("closePopUpScreen", this.state.confirmationForRedirection)
    return (
      <ConfirmationForRedirection
        openConfirmationModal={this.state.confirmationForRedirection}
        closeConfirmationModal={this.closeConfirmationModalForRedirection}
        setFlagforredirection={this.state.setFlagforredirection}
        closePopUpScreen={this.closePopUpScreen}

      />
    )
  }

  confirmtheredirection = (screen, item, clickedField) => {
    console.log("closePopUpScreen", this.state.confirmationForRedirection)
    let obj = {

    }
    this.setState({
      // confirmationForRedirection: true,
      setFlagforredirection: obj,
      propUrl: ""
    })
    this.closeConfirmationModalForRedirection(obj, "propertyEditorV2")

  }

  closeConfirmationModalForRedirection = async (obj, screen) => {
    const { selectedObj } = this.state
    let state = selectedObj.states && selectedObj.states[0] ? selectedObj.states[0].slice(-2) : selectedObj.states
    let url = `${window.location.protocol}//${window.location.host}/${screen}?sellerName=${encodeURIComponent(selectedObj.company)}&sellerId=${selectedObj.opraEstateID}&bucket=Green${selectedObj.connectedProperties.length > 0 ? `&propertyId=` + encodeURIComponent(selectedObj.connectedProperties) : ""}&state=${state}&taskID=${selectedObj.taskID}`
    await this.setState({
      confirmationForRedirection: false,
      setFlagforredirection: "",
      selectedScreen: screen,
      propUrl: url
    })
    await document.getElementsByClassName("redirectToProperties")[0].click();
    this.url = ""
  }

  closePopUpScreen = () => {
    console.log("closePopUpScreen", this.state.confirmationForRedirection)
    this.setState({
      confirmationForRedirection: false,
    })
  }

  toggle(tab) {
    const { selectedObj } = this.state
    if (tab !== "2" && tab !== "4") {
      this.setState({
        activeTab: tab
      })
    }
    if (tab === "2") {
      document.getElementsByClassName("redirectToClaims")[0].click();
    }
    if (tab === "4") {
      let propertySeller = {
        EstateName: selectedObj.company,
        EstateID: selectedObj.opraEstateID,
      };

      let TaskPropertiesFilters = {
        PropertiesSellerName: propertySeller,
        States: selectedObj.states,
        Property_Id: selectedObj.connectedProperties,
        taskID: selectedObj,
      };

      // localStorage.setItem(
      //   "TaskProperties",
      //   JSON.stringify(TaskPropertiesFilters)
      // );
      this.closeConfirmationModalForRedirection("obj", "propertyEditorV2")

      this.setState({ selectedScreen: "", })
    }

  }
  TabModal() {
    return (
      < div className="claimTab" style={{ width: "100%", overflow: "hidden", display: "inline" }}>
        <Nav tabs style={{ backgroundColor: "#354f6e" }}>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Edit RFI
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Claim Manager
            </NavLink>
          </NavItem>

          <NavItem >
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            // disabled={true}
            >
              History
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            // disabled={true}
            >
              Properties
            </NavLink>
          </NavItem>
          <span className="topbar__centerresponse topBarImageAlignment" >{this.state.successResponse ? this.state.successMessage : null}</span>
          <button className="lnr lnr-cross modal__close-btn ml-auto text-white" type="button" style={{ right: 40 }}
            onClick={() => this.checkNoteStatus()} />
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={"1"} >
            <RFIForm
              onRef={(ref) => (this.rfiFormRef = ref)}
              selectedObj={this.props.selectedObj}
              screen={this.props.screen ? this.props.screen : null}
              isCloseRFIModal={this.props.isCloseRFIModal}
              fromTaskScreen={true}
              noteSavedStatusFunc={this.noteSavedStatusFunc}
              formType={"edit"}
              getErrorResponsefromServer={this.props.getErrorResponsefromServer}
            />
          </TabPane>


          <TabPane tabId={"3"} className="deal_managements" >
            <HistoryTabData
              onRef={(ref) => (this.HistoryTabDataref = ref)}
              rowData={this.props.selectedObj}
              getErrorMessage={this.getErrorMessage}
            />
          </TabPane>



        </TabContent>

      </div>
    )
  }

  render() {
    const { handleSubmit, t, formType } = this.props;
    const modalClass = classNames({
      'modal-dialog--colored': this.state.colored,
      'modal-dialog--header': this.state.header,
    });
    return (
      <>
        <Modal
          fade={false}
          isOpen={this.props.isOpenRFIModal}
          style={{ maxWidth: "1600px" }}
          className={`p-0 modal-dialog modal-dialog--primary modalContent  ${modalClass}`}
        >
          {formType === "add" ? <ModalHeader className={"modal__header kanbanScreen p-0"}>
            <div className="d-flex">
              <button className="lnr lnr-cross modal__close-btn ml-auto" type="button" style={{ color: "white", right: 40 }}
                onClick={() => this.checkNoteStatus()} />
              <span className={`modallnrIcon pt-1 lnr lnr-list`} />
              <p className="bold-text  modal__title ml-2"> {this.props.formType === "add" ? "Create" : "Edit"} RFI Task </p>

            </div>

          </ModalHeader> : null}
          <ModalBody className={"modal__body mb-0 pt-0 p-0"}>
            {formType !== "add" ? this.TabModal() :
              <RFIForm
                selectedObj={this.props.selectedObj}
                dealType={this.props.dealType}
                noteSavedStatusFunc={this.noteSavedStatusFunc}
                isCloseRFIModal={this.props.isCloseRFIModal}
                getErrorResponsefromServer={this.props.getErrorResponsefromServer}
                formType={"add"}
              />}
          </ModalBody>
          {/* <ModalFooter className="modalFooter " style={{ width: "100%", padding: "10px" }}>
          <Button color='danger' outline type="button" className="mr-auto ml-1 mb-0 " onClick={this.cancelReset}>
            <FontAwesomeIcon
              style={{ width: "15px" }}
              // color="red"
              icon={faArrowCircleLeft}
            // data-toggle="tool-tip"
            // title={"save"}
            />
            {t('Back') ? t('Cancel') : 'Cancel'} </Button>
          <Button
            style={{ float: "right" }}
            color='primary'
            className={"mb-0 "}
            outline
            type="submit">
            <FontAwesomeIcon
              style={{ width: "15px" }}
              icon={faSave}
            />
            {type === 'edit' ? 'Update' : 'Save'}
          </Button>
        </ModalFooter> */}

        </Modal >
        {this.state.openUnsaveModal ? this.getDeleteRowModal() : null}
        {/* {this.state.confirmationForRedirection ? this.confirmMationModelforRedirection() : null} */}
        {
          Object.keys(this.state.selectedObj).length > 0 ?
            <a
              className="redirectToClaims"
              href={`/claims?companyID=${this.state.selectedObj.opraEstateID
                }&oppClaimID=${this.state.selectedObj.claimID
                }&estateName=${encodeURIComponent(this.state.selectedObj.company)}`}
              target="_blank"
            >
              {" "}
            </a>
            : null
        }
        <a
          className="redirectToProperties"
          href={this.state.propUrl}
          target="_blank"
        >
          {" "}
        </a>
      </>

    );

  }
}

export default withTranslation('common')(RFIModal);
